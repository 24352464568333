import { CardActions } from "@mui/material";
import React from "react";
import AuthenticationSplitter from "../AuthenticationSplitter";
import AuthenticatedActions from "./AuthenticatedActions";
import CreateAFreeAccount from "../CreateAFreeAccountButton";

const EditorToolCardActions = () => {
  return (
    <CardActions className="flex align-i-center justify-flex-end">
      <AuthenticationSplitter
        authenticatedComponent={<AuthenticatedActions />}
        externalComponent={
          <CreateAFreeAccount variant="contained" className="m-l-auto" />
        }
      />
    </CardActions>
  );
};

export default EditorToolCardActions;
