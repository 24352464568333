import React, { useEffect, useRef, useState } from "react";
import type { JSONContent } from "@tiptap/core";
import { useRunAssistantMutation } from "../../../../../ToolflowAPI/rtkRoutes/assistantApi";
import { useWebsocketContext } from "../../../../../contexts/useWebsocketContext";
import { FormHelperText, IconButton } from "@mui/material";
import { Send, StopCircleOutlined } from "@mui/icons-material";
import { ReadyState } from "react-use-websocket";
import { useDispatch, useSelector } from "react-redux";
import TipTapTextField from "../../../../../toolBuilder/tipTapTextField/TipTapTextField";
import { getTipTapValue } from "../../../../../workspace/hooks/useGetTipTapValue";
import { Editor } from "@tiptap/react";
import useGetCurrentChatThreadId from "./hooks/useGetCurrentChatThreadId";
import { useCanRunAssistant } from "./hooks/useCanRunAssistant";
import {
  setThreadMessagesLoading,
  updateMessagesWithNewUserMessage
} from "./chatSlice";
import { v4 } from "uuid";
import useSetThreadId from "./hooks/useSetThreadId";
import { useAbortThreadMutation } from "../../../../../ToolflowAPI/rtkRoutes/threadsApi";
import { RootState } from "../../../../../stores/store";

const useThreadRef = () => {
  const currentThreadId = useGetCurrentChatThreadId();
  const currentThreadIdRef = useRef<string | null>(currentThreadId);
  useEffect(() => {
    currentThreadIdRef.current = currentThreadId;
  }, [currentThreadId]);
  return currentThreadIdRef;
};

const useMessageLoadingRef = () => {
  const isMessageLoading = useSelector(
    (store: RootState) => store.chat.isLoading
  );
  const isMessageLoadingRef = useRef<boolean | null>(isMessageLoading);
  useEffect(() => {
    isMessageLoadingRef.current = isMessageLoading;
  }, [isMessageLoading]);
  return isMessageLoadingRef;
};

function SendThreadTextField() {
  const [runAssistant] = useRunAssistantMutation();
  const currentMessageLoadingRef = useMessageLoadingRef();
  const { canRunAssistant, isFetching } = useCanRunAssistant();
  const [abortThread] = useAbortThreadMutation();
  const dispatch = useDispatch();
  const { readyState } = useWebsocketContext();
  const currentThreadIdRef = useThreadRef();
  const [userTextMessage, setUserTextMessage] = useState("");
  const setThreadId = useSetThreadId();
  const isMessageLoading = currentMessageLoadingRef.current;

  const handleChange = (value: string | JSONContent) => {
    if (typeof value === "string") {
      setUserTextMessage(value);
    }
  };
  const handleRunAssistant = async ({
    threadId,
    message
  }: {
    threadId: string;
    message: string;
  }) => {
    const userMessageId = v4();
    dispatch(setThreadMessagesLoading(true));
    dispatch(
      updateMessagesWithNewUserMessage({
        id: userMessageId,
        message,
        role: "user"
      })
    );
    setUserTextMessage("");
    await runAssistant({
      threadId,
      message
    })
      .unwrap()
      .then((resp) => {
        if (currentThreadIdRef.current !== resp.result.dbThreadId) {
          setThreadId(resp.result.dbThreadId);
        }
      });
  };

  const { resetButton } = useWebsocketContext();

  const handleKeyDown = (event: KeyboardEvent, editor?: Editor) => {
    if (editor) {
      if (event.shiftKey === false) {
        const value = getTipTapValue({ editor, saveWorkspaceType: "text" });
        if (event.key === "Enter" && !currentMessageLoadingRef.current) {
          event.preventDefault();
          event.stopPropagation();
          // we get the value when enter is pressed. Usually we update the value on blur, so we need
          // to get the value directly from the input here
          handleRunAssistant({
            threadId: currentThreadIdRef?.current ?? "",
            message: value as string
          });
          editor.commands.focus();
        }
        if (!currentMessageLoadingRef.current) {
          // the userTextMessage might still be "" because onBlur hasn't been called
          // in which case, initialValue won't have changed, so this wont rerender. In which case
          // clear the content
          editor.commands.clearContent();
        }
      }
    }
  };

  return (
    <>
      <div className="flex align-i-center p-v-8px">
        <TipTapTextField
          initialValue={userTextMessage}
          richTextFieldClassName="tiptap-textfield-p"
          onChange={handleChange}
          autoFocus={false}
          // enableHover={!isMessageLoading}
          disableTools
          disableFormatting
          preventDefaultEnter
          overrideKeydown={handleKeyDown}
          // disableInputs={isMessageLoading}
        />
        {!isMessageLoading ? (
          <IconButton
            size="small"
            color="primary"
            onClick={() =>
              handleRunAssistant({
                threadId: currentThreadIdRef?.current ?? "",
                message: userTextMessage
              })
            }
            className="m-l-8px"
            disabled={
              readyState !== ReadyState.OPEN || !canRunAssistant || isFetching
            }
          >
            <Send fontSize="small" />
          </IconButton>
        ) : (
          <IconButton
            size="small"
            color="primary"
            onClick={() => abortThread(currentThreadIdRef?.current ?? "")}
            className="m-l-8px"
          >
            <StopCircleOutlined fontSize="small" />
          </IconButton>
        )}
      </div>
      {!canRunAssistant && (
        <FormHelperText className="p-h-4px" error>
          Your message limit has been reached. Please upgrade your plan to
          continue.
        </FormHelperText>
      )}
      {resetButton}
    </>
  );
}

export default SendThreadTextField;
