import { CardHeader, Typography } from "@mui/material";
import React from "react";
import { useWorkflowContext } from "./contexts/WorkflowContext";
import EditWorkflowButton from "./EditWorkflowButton";
import useCurrentUserIsWorkflowOwner from "./editorToolCard/hooks/useCurrentUserIsWorkflowOwner";

function WorkflowInfo({ action }: { action?: React.ReactNode }) {
  const { workflow } = useWorkflowContext();
  const currentUserIsWorkflowOwner = useCurrentUserIsWorkflowOwner();
  return (
    <>
      <CardHeader
        title={workflow.name}
        titleTypographyProps={{ variant: "h6" }}
        action={
          <>
            {currentUserIsWorkflowOwner && (
              <EditWorkflowButton
                workflowId={workflow.id}
                className="m-r-8px"
                icon
              />
            )}

            {action}
          </>
        }
      />
      {workflow.description && (
        <Typography className="m-h-16px m-b-16px" variant="caption">
          {workflow.description}
        </Typography>
      )}
    </>
  );
}

export default WorkflowInfo;
