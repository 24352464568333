import React from "react";
import {
  RiFunctionLine,
  RiFunctionFill,
  RiBriefcase4Line,
  RiBriefcase4Fill,
  RiFocus2Line,
  RiFocus2Fill,
  RiStarLine,
  RiStarFill,
  RiMegaphoneLine,
  RiMegaphoneFill,
  RiToolsLine,
  RiToolsFill,
  RiFundsLine,
  RiFundsFill
} from "@remixicon/react";
export type TUseCaseType = "useCase";
export type TCategoryType = "category";
export type TTabType = TUseCaseType | TCategoryType;
export type TMarketplaceTab = {
  icon: JSX.Element;
  selectedIcon: JSX.Element;
  label: string;
  value: string;
  indexValue: number;
  RemixIconRaw: React.ElementType;
  key: string;
  type: TTabType;
  disabled?: boolean;
  title: string;
};
const iconSize = 20;
let tabId = 0;
export const marketplaceCategoryTabs: TMarketplaceTab[] = [
  {
    label: "Popular",
    disabled: false,
    title: "Tools people love",
    type: "category",
    key: "popular",
    icon: <RiFundsLine size={iconSize} />,
    selectedIcon: <RiFundsFill size={iconSize} />,
    RemixIconRaw: RiStarLine,
    value: "popular",
    indexValue: tabId++
  },
  {
    label: "All",
    title: "Tools",
    disabled: false,
    type: "category",
    key: "All",
    icon: <RiFunctionLine size={iconSize} />,
    selectedIcon: <RiFunctionFill size={iconSize} />,
    RemixIconRaw: RiFunctionLine,
    value: "all",
    indexValue: tabId++
  },
  {
    label: "Starred",
    disabled: false,
    title: "Tools you starred",
    type: "category",
    key: "starred",
    icon: <RiStarLine size={iconSize} />,
    selectedIcon: <RiStarFill size={iconSize} />,
    RemixIconRaw: RiStarLine,
    value: "starred",
    indexValue: tabId++
  },
  {
    label: "Utilities",
    title: "Tools for general use",
    disabled: false,
    type: "category",
    key: "Utilities",
    icon: <RiToolsLine size={iconSize} />,
    selectedIcon: <RiToolsFill size={iconSize} />,
    RemixIconRaw: RiToolsLine,
    value: "utilities",
    indexValue: tabId++
  },
  {
    label: "Sales",
    disabled: false,
    type: "category",
    title: "Tools for sales",
    key: "Sales",
    icon: <RiBriefcase4Line size={iconSize} />,
    selectedIcon: <RiBriefcase4Fill size={iconSize} />,
    RemixIconRaw: RiBriefcase4Line,
    value: "sales",
    indexValue: tabId++
  },
  {
    label: "Marketing",
    title: "Tools for marketing",
    disabled: false,
    type: "category",
    key: "Marketing",
    icon: <RiMegaphoneLine size={iconSize} />,
    selectedIcon: <RiMegaphoneFill size={iconSize} />,
    RemixIconRaw: RiMegaphoneLine,
    value: "marketing",
    indexValue: tabId++
  },
  {
    label: "Growth",
    title: "Tools for growth",
    disabled: false,
    type: "category",
    key: "Growth",
    icon: <RiFocus2Line size={iconSize} />,
    selectedIcon: <RiFocus2Fill size={iconSize} />,
    RemixIconRaw: RiFocus2Line,
    value: "growth",
    indexValue: tabId++
  }

  // {
  //   label: "Operations",
  //   disabled: true,
  //   type: "category",
  //   key: "Operations",
  //   icon: <RiBarChart2Line size={iconSize} />,
  //   selectedIcon: <RiBarChart2Fill size={iconSize} />,
  //   RemixIconRaw: RiBarChart2Line,
  //   value: "operations",
  //   indexValue: tabId++
  // },
  // {
  //   label: "Customer Success",
  //   disabled: true,
  //   type: "category",
  //   key: "Customer Success",
  //   icon: <RiAwardLine size={iconSize} />,
  //   selectedIcon: <RiAwardFill size={iconSize} />,
  //   RemixIconRaw: RiAwardLine,
  //   value: "customer success",
  //   indexValue: tabId++
  // },
  // {
  //   label: "Support",
  //   disabled: true,
  //   type: "category",
  //   key: "Support",
  //   icon: <RiChatSmile2Line size={iconSize} />,
  //   selectedIcon: <RiChatSmile2Fill size={iconSize} />,
  //   RemixIconRaw: RiChatSmile2Line,
  //   value: "support",
  //   indexValue: tabId++
  // },
  // {
  //   label: "Finance",
  //   disabled: true,
  //   type: "category",
  //   key: "Finance",
  //   icon: <RiCashLine size={iconSize} />,
  //   selectedIcon: <RiCashFill size={iconSize} />,
  //   RemixIconRaw: RiCashLine,
  //   value: "finance",
  //   indexValue: tabId++
  // }
];
