import { useToolbuilderContext } from "../../context/ToolBuilderContext";

const useDeleteToolInputFieldToolbuilder = () => {
  const { dispatch } = useToolbuilderContext();
  const handleDeleteToolInputField = (index: number) => {
    dispatch({
      type: "DELETE_INPUT_FIELD",
      index
    });
  };
  return handleDeleteToolInputField;
};

export default useDeleteToolInputFieldToolbuilder;
