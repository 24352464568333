import React, { useState } from "react";
import useSheetData from "./utils/hooksWithoutContext/useSheetData";
import SheetContainer from "./SheetContainer";
import { useGetToolQuery } from "../../../../../../ToolflowAPI/rtkRoutes/toolsApi";

const useSheetSelectedTool = () => {
  const [selectedToolId, setSelectedToolId] = useState<string | null>(null);
  const { data } = useGetToolQuery(selectedToolId || "", {
    skip: !selectedToolId
  });

  return {
    selectedTool: selectedToolId ? data?.tool || null : null,
    setSelectedTool: setSelectedToolId
  };
};

const SheetOriginalContainer = () => {
  // TODO -> make sure that all column[0].columns.map work when
  // having multiple selectedTools.
  const { selectedTool, setSelectedTool } = useSheetSelectedTool();

  const { sheetData, setSheetData, sheetDataRef } = useSheetData(selectedTool);

  return (
    <SheetContainer
      selectedTool={selectedTool}
      setSelectedTool={setSelectedTool}
      sheetData={sheetData}
      setSheetData={setSheetData}
      sheetDataRef={sheetDataRef}
    />
  );
};

export default SheetOriginalContainer;
