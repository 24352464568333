import { useDispatch } from "react-redux";
import { CloseIcon } from "../../../icons/icons";
import RemixButton from "../../../../../layout/icons/RemixButton";
import { setAssetListBig } from "../../../../workspaceSlice";
import React from "react";

function CondenseAssetListButton() {
  const dispatch = useDispatch();
  const makeSmall = () => {
    dispatch(setAssetListBig(false));
  };
  return <RemixButton icon={CloseIcon} text="Condense" onClick={makeSmall} />;
}

export default CondenseAssetListButton;
