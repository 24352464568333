import { Divider, Typography } from "@mui/material";
import React from "react";
import LLMKeysTableContainer from "./LLMKeysTableContainer";

const LLMKeys = () => {
  return (
    <div className="m-t-32px">
      <Typography variant="h7">Enter your API keys</Typography>
      <Typography
        variant="body1"
        className="m-t-12px"
        sx={{ color: (theme) => theme.palette.text.disabled }}
      >
        {`Want to use your own API keys? Just add them here! \n If you don't have
        one yet, sign up with your provider, and make sure you have enough
        credits.`}
      </Typography>
      <LLMKeysTableContainer />
      <Divider className="m-v-24px" />
    </div>
  );
};

export default LLMKeys;
