import React from "react";
import type { LeanFileDocument } from "@sharedTypes";
import DeleteFileButton from "./DeleteFileButton";
import {
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  Table
} from "@mui/material";
import { DateTime } from "luxon";

export function FileRow({
  file,
  customVal = "",
  noDelete
}: {
  file: LeanFileDocument;
  customVal?: string;
  noDelete?: boolean;
}) {
  if (!file) return null;
  const formattedDate =
    typeof file.updatedAt === "number"
      ? DateTime.fromSeconds(file.updatedAt).toLocaleString(
          DateTime.DATETIME_MED
        )
      : "Invalid Date";
  const formattedFileSize =
    typeof file.fileSizeInBytes === "number"
      ? `${(file.fileSizeInBytes / 1000 / 1000).toFixed(2)}MB`
      : "Invalid Size";
  return (
    <TableRow key={file._id}>
      {customVal && <TableCell>{customVal}</TableCell>}
      <TableCell>{file.fileName}</TableCell>
      <TableCell>{formattedDate}</TableCell>
      <TableCell>{formattedFileSize}</TableCell>
      {!noDelete && (
        <TableCell>
          <DeleteFileButton id={file._id} />
        </TableCell>
      )}
    </TableRow>
  );
}

function FileTable({
  files,
  customHeaderCell = "",
  className,
  customVals = [],
  noDelete
}: {
  files: LeanFileDocument[];
  customHeaderCell?: string;
  className?: string;
  customVals?: string[];
  noDelete?: boolean;
}) {
  return (
    <TableContainer className={className}>
      <Table>
        <TableHead>
          <TableRow>
            {customHeaderCell && <TableCell>{customHeaderCell}</TableCell>}
            <TableCell>Name</TableCell>
            <TableCell>Upload Date</TableCell>
            <TableCell>Size</TableCell>
            {!noDelete && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {files &&
            files.map((file, idx) => (
              <FileRow
                key={idx}
                file={file}
                customVal={customVals[idx]}
                noDelete={noDelete}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default FileTable;
