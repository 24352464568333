import React from "react";
import { InfoOutlined } from "@mui/icons-material";
import type { ExternalToolFE } from "@sharedTypes";
import { useSettingsDropdownContext } from "../../../utilities/dropdowns/generic/useSettingsDropownContext";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import SettingsMenuItem from "../../../utilities/dropdowns/generic/SettingsMenuItem";
import { updateToolInInfoDrawer } from "../../../utilities/toolInfoDrawerSlice";
import { useDispatch } from "react-redux";

function MoreToolInfoMenuItem({ tool }: Readonly<{ tool: ExternalToolFE }>) {
  const { handleClose } = useSettingsDropdownContext();
  const dispatch = useDispatch();
  const setTool = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(updateToolInInfoDrawer(tool));

    handleClose(e);
  };

  return (
    <SettingsMenuItem
      text="More info"
      hotkey="Meta+I"
      icon={<InfoOutlined fontSize="small" />}
      action={setTool}
    />
  );
}

export function EditMoreToolInfoMenuItem() {
  const { dispatch } = useToolbuilderContext();
  const { handleClose } = useSettingsDropdownContext();
  const setTool = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch({ type: "SET_SHOW_INFO_DRAWER", showInfoDrawer: true });

    handleClose(e);
  };

  return (
    <SettingsMenuItem
      text="More info"
      hotkey="Meta+I"
      icon={<InfoOutlined fontSize="small" />}
      action={setTool}
    />
  );
}

export default MoreToolInfoMenuItem;
