import React, { FC } from "react";
import NavigateToBuilderMenuItem from "../../../../utilities/dropdowns/menuItems/NavigateToBuilderMenuItemInHome";
import CopyLinkMenuItemInHome from "../../../../workspace/dropdowns/menuItems/CopyLinkMenuItemInHome";
import { BoldTableCell, StyledTableCell } from "../../layout/TableLayouts";
import { ToolsTableData } from "../../utlils/tableTypes";
import { convertRowToEntity } from "../../utlils/tableUtils";
import EntityRow from "../EntityRow";
import HomePageMenuEntityDropdownMenu from "../HomePageMenuEntityDropdownMenu";

interface IToolBlockEntityTableRowProps {
  entity: ToolsTableData;
  removeLastRowBorder?: boolean;
}
const ToolBlockEntityTableRow: FC<IToolBlockEntityTableRowProps> = ({
  entity,
  removeLastRowBorder
}) => {
  return (
    <EntityRow
      key={entity.id}
      entityId={entity.id}
      entityType={entity.type}
      removeLastRowBorder={removeLastRowBorder}
    >
      <BoldTableCell>{entity.name}</BoldTableCell>
      <StyledTableCell align="right">
        <CopyLinkMenuItemInHome objectId={entity.id} type={entity.type} />
        {entity.doesUserOwnEntity && (
          <NavigateToBuilderMenuItem entityId={entity.id} type={entity.type} />
        )}
        <HomePageMenuEntityDropdownMenu
          homPageEntity={convertRowToEntity(entity)}
        />
      </StyledTableCell>
    </EntityRow>
  );
};

export default ToolBlockEntityTableRow;
