import { Box } from "@mui/material";
import React from "react";
import AuthenticationSplitter from "../../../../../utilities/AuthenticationSplitter";
import LoggedOutCTA from "../../../../../utilities/LoggedOutCTA";
import ChatThread from "./ChatThread";
import ResetThreadButton from "./ResetThreadButton";
import SendThreadTextField from "./SendThreadTextField";
import useChatSocket from "./hooks/useChatSocket";
import useThreadMessages from "./hooks/useThreadMessages";

function ChatBoxPage() {
  const messages = useThreadMessages();
  useChatSocket();
  return (
    <Box className="flex flex-column h-100-percent justify-space-between">
      {messages.length > 0 && (
        <div className="flex">
          <ResetThreadButton />
        </div>
      )}
      <ChatThread />
      <AuthenticationSplitter
        authenticatedComponent={<SendThreadTextField />}
        externalComponent={<LoggedOutCTA />}
      />
    </Box>
  );
}

export default ChatBoxPage;
