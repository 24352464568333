import React from "react";
import CircularLoading from "./CircularLoading";
import useLoginRedirectToMarketplace from "../hooks/useLoginRedirectToMarketplace";

const LoginRoute = ({ signUp }: { signUp?: boolean }) => {
  useLoginRedirectToMarketplace({ signUp });

  return <CircularLoading className="h-100vh" />;
};

export default LoginRoute;
