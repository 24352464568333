import { Box } from "@mui/material";
import React from "react";
import { parsedClassNames } from "../../../utils/parsedClassNames";
import styles from "./dot.module.css";
import useDot from "./hooks/useDot";

// https://www.npmjs.com/package/classnames#alternate-bind-version-for-css-modules
const cx = parsedClassNames.bind(styles);

function Dot({ assetId }: { assetId: string }) {
  const { selected, isLastDot } = useDot(assetId);
  const calculatedClassName = cx(styles.dot, {
    "bg-color-black": selected,
    "m-b-6px": !isLastDot
  });
  return (
    <Box
      className={calculatedClassName}
      sx={{ backgroundColor: (theme) => theme.palette.text.disabled }}
    />
  );
}

export default Dot;
