import type { RowUserInputDictType, TSelectedTool } from "@sharedTypes";
import { useEffect, useState } from "react";
import useGetInitialData from "./useGetInitialData";
import useSheetDataRef from "./useSheetDataRef";

const useSheetData = (
  selectedTool: TSelectedTool,
  savedData?: RowUserInputDictType[]
) => {
  const initialData = useGetInitialData({ selectedTool, savedData });
  const [sheetData, setSheetData] =
    useState<RowUserInputDictType[]>(initialData);
  useEffect(() => {
    setSheetData(initialData);
  }, [initialData]);

  const sheetDataRef = useSheetDataRef(sheetData);

  return { sheetData, setSheetData, sheetDataRef };
};

export default useSheetData;
