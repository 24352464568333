// DescriptionEditor.js
import React, { useState, useEffect } from "react";
import { FormHelperText } from "@mui/material";
import TipTapTextField from "./tipTapTextField/TipTapTextField";
import { type JSONContent } from "@tiptap/core";
import { useToolbuilderContext } from "./context/ToolBuilderContext";

const DescriptionEditor = ({
  container
}: {
  container?: React.RefObject<HTMLDivElement>;
}) => {
  const { state, dispatch } = useToolbuilderContext();
  const { description } = state.currentState;
  const [text, setText] = useState(description);

  const onTextChange = (value: string) => {
    dispatch({
      type: "UPDATE_DESCRIPTION",
      description: value
    });
  };

  const updateText = (val: string) => {
    let vToSend = val;
    if (val === "<p></p>") {
      vToSend = "";
    }
    return vToSend;
  };

  const handleChange = (value: string | JSONContent) => {
    if (typeof value === "string") {
      const vToSend = updateText(value);
      onTextChange(vToSend);
    }
  };

  useEffect(() => {
    if (text !== description) {
      setText(description);
    }
  }, []);

  return (
    <div className="oflow-y-flex">
      <TipTapTextField
        initialValue={text}
        richTextFieldClassName="tiptap-textfield-p"
        onChange={handleChange}
        autoFocus={false}
        container={container}
        saveWorkspaceType="html"
        disableInputs
        inputType="node"
      />
      <FormHelperText variant="outlined">
        Enter what this tool does and how people should use it.
      </FormHelperText>
    </div>
  );
};

export default DescriptionEditor;
