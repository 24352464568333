import type { WorkflowInputDallE } from "@sharedTypes";
import React from "react";
import CopyableImage from "../../../../../utilities/CopyableImage";
import { useInputContext } from "../../../../../utilities/contexts/InputContext";
import AssetName from "../asset/AssetName";
import AssetTableOfContents from "../assetTableOfContents/AssetTableOfContents";
import DallEAssetIcons from "./DallEAssetIcons";

const DallEAsset = () => {
  const { input } = useInputContext<WorkflowInputDallE>();
  return (
    <>
      <div>
        <div className="flex align-i-center w-100-percent p-24px parent-hover">
          <AssetName text={input.name} />
          <DallEAssetIcons />
        </div>
        <div className="p-24px">
          <CopyableImage value={input.value} showLoading={input.loading} />
        </div>
      </div>
      <AssetTableOfContents />
    </>
  );
};

export default DallEAsset;
