import React, { useMemo, useState } from "react";
import Optimizations from "../../Optimizations";
import type {
  PromptBlockProps,
  DallEOutputType,
  RunBlockToolData,
  UserInputDictType,
  CustomToolInputField,
  TAnthropicBlockData,
  ChatGPTBlockData,
  DallEBlockData
} from "@sharedTypes";
import DallEOptimizations from "./dalle/DallEOptimizations";
import CopyableImage from "../../../utilities/CopyableImage";
import CopyableLargeTextField from "../../../utilities/CopyableLargeTextField";
import useShouldWrap from "../../useShouldWrap";
import { TextField } from "@mui/material";
import OutputField from "../../OutputField";
import { v4 as uuidv4 } from "uuid";
import AccordionWrapper from "../../../utilities/AccordionWrapper";
import Grid from "@mui/material/Unstable_Grid2";
import {
  getErrorMessage,
  getIsOutOfCreditsError
} from "../../../utilities/apiHandlerWithAuth";
import useHandleSocketMessage from "../../hooks/useHandleSocketMessage";
import useInnerBlockState from "../../hooks/useInnerBlockState";
import { getPromptWithUserInput } from "../../common/utils";
import TestToolRun from "../../common/TestToolRun";
import { DALL_E_2_MODEL, DALL_E_3_MODEL } from "../../context/initialStates";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../globalTheme/muiUtils/appTheme";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../features/layout/alerts/alertMessageSlice";
import { useRunBlockMutation } from "../../../ToolflowAPI/rtkRoutes/blockApi";
import SelectTextGenerationTech from "./SelectTextGenerationTech";
import AnthropicOptimizations from "../../optimizations/AnthropicOptimizations";
import { TEXTFIELD } from "../../../utilities/Inputs/inputConstants";
import {
  COPYABLE_IMAGE,
  COPYABLE_LARGE_TEXTFIELD
} from "../../../utilities/Inputs/outputConstants";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import PromptEditorContainer from "../../promptEditor/PromptEditorContainer";

const dallECharObject = {
  [DALL_E_2_MODEL]: 1000,
  [DALL_E_3_MODEL]: 4000
};

function PromptBlockInner({ data, id }: Omit<PromptBlockProps, "selected">) {
  const { label, prompt, type } = data;
  const { dispatch, state } = useToolbuilderContext();
  const { toolOutput } = state;
  const [shouldWrap, wrapperRef] = useShouldWrap();
  const [showUpgrade, setShowUpgrade] = useState(false);

  const { loading, output, setLoading } = useHandleSocketMessage();
  const { abort, componentId, useFields } = useInnerBlockState();
  const fields = useFields(data.prompt, id);
  const [runBlock] = useRunBlockMutation();
  const { toolId, toolVersionId } = state;
  const reduxDispatch = useDispatch();

  function arrayToObject(): { [key: string]: string } {
    const obj: { [key: string]: string } = {};

    for (const field of fields) {
      obj[field] = "";
    }

    return obj;
  }
  const [uI, setUI] = useState<UserInputDictType>(arrayToObject());

  function transformList(input: string[]): CustomToolInputField[] {
    const o: CustomToolInputField[] = input.map((name) => ({
      name,
      id: uuidv4(),
      type: TEXTFIELD
    }));
    return o;
  }

  const handleRunBlock = async () => {
    setLoading(true);
    dispatch({
      type: "UPDATE_TOOL_OUTPUT",
      response: null,
      percentCompleted: 0
    });

    const toolInputFields = transformList(fields);
    const toolData: RunBlockToolData = {
      componentId,
      userInput: uI,
      blockType: "promptBlockNode",
      toolInputFields,
      blockData: data,
      trackingMetrics: {
        toolId,
        toolVersionId,
        blockId: id
      }
    };

    const handleError = (e: unknown) => {
      if (getIsOutOfCreditsError(e)) {
        setShowUpgrade(true);
      }
      reduxDispatch(setErrorMessage(getErrorMessage(e, "Error running block")));
      setLoading(false);
    };

    try {
      await runBlock(toolData).unwrap();
    } catch (err) {
      handleError(err);
    }
  };

  const handleUpdate = (
    field: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newUI = { ...uI, [field]: e.target.value };
    setUI(newUI);
  };

  const runToolOutput = (
    <AccordionWrapper
      elevation={0}
      title="Test Output"
      startsExpanded={!!output}
    >
      {output && (
        <OutputField
          toolOutput={output ? { [data.label]: output } : null}
          toolOutputField={{
            name: data.label,
            type:
              data.type === "Dall-E2"
                ? COPYABLE_IMAGE
                : COPYABLE_LARGE_TEXTFIELD
          }}
          noEditor
        />
      )}
    </AccordionWrapper>
  );

  const filledOutPrompt = useMemo(
    () => getPromptWithUserInput(prompt, uI),
    [prompt, uI]
  );

  const runToolCard = (
    <AccordionWrapper title="Test" elevation={0} startsExpanded>
      {fields.map((field, idx) => (
        <TextField
          name={field}
          key={idx}
          label={field}
          value={uI[field]}
          onChange={(e) => handleUpdate(field, e)}
          multiline
          fullWidth
          margin="normal"
          maxRows={MAX_ROWS_LARGE_TEXTFIELD}
        />
      ))}
      <TextField
        label="Prompt to test"
        margin="normal"
        value={filledOutPrompt}
        disabled
        multiline
        fullWidth
        maxRows={MAX_ROWS_LARGE_TEXTFIELD}
        helperText={`${filledOutPrompt.length} characters`}
      />
      <TestToolRun
        abort={abort}
        handleRunBlock={handleRunBlock}
        loading={loading}
        blockData={data}
        showUpgrade={showUpgrade}
        blockForm={uI}
      />
    </AccordionWrapper>
  );

  const dallECharCount =
    type === "Dall-E2"
      ? dallECharObject[(data as DallEBlockData).optimizations.model]
      : undefined;

  const promptInner = (
    <>
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <>
            {type === "Dall-E2" ? (
              <CopyableImage
                value={(toolOutput[label] as DallEOutputType) || []}
                name={label}
              />
            ) : (
              <CopyableLargeTextField
                value={(toolOutput[label] as string) || ""}
                name={label}
              />
            )}
          </>
        </AccordionWrapper>
      )}

      {(type === "ChatGPT" || type === "Anthropic") && (
        <AccordionWrapper title="Model" elevation={0}>
          <SelectTextGenerationTech id={id} blockType={type} />
          {type === "ChatGPT" && (
            <Optimizations
              id={id}
              optimizations={(data as ChatGPTBlockData).optimizations}
            />
          )}
          {type === "Anthropic" && (
            <AnthropicOptimizations
              id={id}
              anthropicOptimizations={
                (data as TAnthropicBlockData).anthropicOptimizations
              }
            />
          )}
        </AccordionWrapper>
      )}
      {type === "Dall-E2" && (
        <DallEOptimizations
          id={id}
          optimizations={(data as DallEBlockData).optimizations}
        />
      )}
      <AccordionWrapper elevation={0} title="Prompt" startsExpanded>
        <PromptEditorContainer
          id={id}
          prompt={prompt}
          maxLength={dallECharCount}
          helperText={
            dallECharCount
              ? `${prompt.length} / ${dallECharCount} characters. If you are piping in a
          response from another prompt, you may want to summarize the response to be
          fewer than {dallECharCount} characters.`
              : undefined
          }
        />
      </AccordionWrapper>
    </>
  );

  return (
    <div ref={wrapperRef} className="m-v-16px">
      {shouldWrap ? (
        <Grid container>
          <Grid xs={6}>
            {promptInner}
            {runToolCard}
          </Grid>
          <Grid xs={6}>{runToolOutput}</Grid>
        </Grid>
      ) : (
        <>
          {promptInner}
          {runToolCard}
          {runToolOutput}
        </>
      )}
    </div>
  );
}

export default PromptBlockInner;
