import React from "react";
import DeleteCurrentAssetButton from "../deleteAssetButtons/DeleteCurrentAssetButton";

const DallEAssetIcons = () => {
  return (
    <div className="flex align-i-center show-on-hover">
      <DeleteCurrentAssetButton />
    </div>
  );
};

export default DallEAssetIcons;
