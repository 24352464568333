import React from "react";
import Box from "@mui/material/Box";
import { FormHelperText } from "@mui/material";
import AddInputButton from "./AddInputButton";
import DraggableToolInputField from "./DraggableToolInputField";
import { useToolInputFieldsContext } from "./contexts/ToolInputFieldsContext";
import DragToolInputMonitor from "./DragToolInputMonitor";

const CustomToolInputFields = ({ className = "" }: { className?: string }) => {
  const { toolInputFields } = useToolInputFieldsContext();
  return (
    <Box className={className}>
      <FormHelperText>Drag input to reorganize</FormHelperText>
      <DragToolInputMonitor>
        {toolInputFields.map((toolInputField, index) => {
          return (
            <DraggableToolInputField
              key={toolInputField.id}
              index={index}
              toolInputField={toolInputField}
            />
          );
        })}
      </DragToolInputMonitor>
      <div className="flex justify-flex-end">
        <AddInputButton />
      </div>
    </Box>
  );
};

export default CustomToolInputFields;
