import type {
  GetMarketplaceToolsResponse,
  TMarketplaceEntity
} from "@sharedTypes";
import { authenticatedApi } from "../authenticatedAPI";

import { type EntityState } from "@reduxjs/toolkit";
import { marketplaceEntityAdapter } from "../../stores/entityAdapters";
import { MARKETPLACE_TAG_TYPE } from "../tagConstants";
import invalidateToolTags from "./helpers/invalidateToolTags";
import { RootState } from "../../stores/store";
import { setErrorMessage, setSuccessMessage } from "../../stores/actions";
import invalidateWorkflowTags from "./helpers/invalidateWorkflowTags";

export const marketplaceApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getMarketplaceEntities: builder.query<
      EntityState<TMarketplaceEntity, string>,
      void
    >({
      query: () => ({
        url: "marketplace",
        method: "GET"
      }),
      transformResponse: (response: GetMarketplaceToolsResponse) => {
        return marketplaceEntityAdapter.upsertMany(
          marketplaceEntityAdapter.getInitialState(),
          response.entities
        );
      },
      providesTags: [MARKETPLACE_TAG_TYPE]
    }),
    updateMarketplaceFeaturedTool: builder.mutation({
      query: ({
        objectId,
        type,
        popular,
        isFeatured
      }: {
        objectId: string;
        isFeatured?: boolean;
        type: "tool" | "workflow";
        popular?: boolean;
        successMessage: string;
      }) => ({
        url: `internal/marketplace/${objectId}`,
        method: "POST",
        body: {
          type,
          popular,
          featured: isFeatured
        }
      }),
      async onQueryStarted(
        { successMessage, type },
        { dispatch, queryFulfilled, getState }
      ) {
        try {
          await queryFulfilled;
          if (type === "tool") {
            invalidateToolTags(dispatch, getState() as RootState);
          } else {
            invalidateWorkflowTags(dispatch, getState() as RootState);
          }
          dispatch(setSuccessMessage(successMessage));
          dispatch(marketplaceApi.util.invalidateTags([MARKETPLACE_TAG_TYPE]));
        } catch {
          dispatch(setErrorMessage(`Error toggling marketplace ${type}`));
        }
      }
    })
  })
});

export const {
  useGetMarketplaceEntitiesQuery,
  useUpdateMarketplaceFeaturedToolMutation
} = marketplaceApi;
