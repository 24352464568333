import type { TBlock, OriginalToolState } from "@sharedTypes";
import deepEqualAndDiff from "../../../utilities/functions/deepEqualAndDiff";
import _ from "lodash";

const useIsToolDirty = ({
  blocks,
  currentState,
  originalState
}: {
  blocks: TBlock[];
  currentState: OriginalToolState;
  originalState: OriginalToolState;
}) => {
  function isToolDirty() {
    const updatedBlocks = _.map(blocks, (block) =>
      _.omit(block, [
        "width",
        "height",
        "dragging",
        "selected",
        "positionAbsolute", // this may be unecessary after renaming selector to pos-absolute
        "pos-absolute"
      ])
    );

    const objToCheck = {
      ...currentState,
      blocks: updatedBlocks,
      estimatedCreditCost: originalState.estimatedCreditCost,
      tag: originalState.tag
        ? originalState.tag
        : { categories: [], useCases: [] } // seems like this value bugs out, if we don't compare, should not trigger navigation dirty
    };
    const { equal } = deepEqualAndDiff(originalState, objToCheck);

    return !equal;
  }
  return isToolDirty;
};

export default useIsToolDirty;
