import type { SecretReference, TSecretType } from "@sharedTypes";
import React, { FC } from "react";
import {
  MenuItem,
  TextField,
  InputLabel,
  Select,
  FormControl,
  Typography
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import isEmpty from "lodash/isEmpty";
import AddNewDeepgramKeysInstructions from "./AddNewDeepgramKeysInstructions";

interface IManageDeepgramSecretProps {
  secrets: SecretReference[];
  handleChange: (value: string) => void;
  handleDelete: (secretId: string) => void;
  loading: boolean;
  handleSaveSecret: (secretType: TSecretType) => void;
  val: string;
}

const ManageDeepgramSecret: FC<IManageDeepgramSecretProps> = (props) => {
  const {
    secrets,
    handleChange,
    handleDelete,
    loading,
    handleSaveSecret,
    val
  } = props;
  return (
    <div>
      <Typography variant="subtitle2" className="m-b-8px">
        Deepgram Keys
      </Typography>
      {secrets &&
        secrets.map((secret) => (
          <div key={secret._id} className="flex align-i-center">
            <FormControl className="m-r-16px" style={{ width: 150 }}>
              <InputLabel id="prompt-apiKey-label">Integration</InputLabel>
              <Select
                labelId="apiKey-label"
                id="apiKey"
                value="deepgram"
                label="Integration"
                size="small"
                notched
                // disabled
              >
                <MenuItem value="deepgram" key="deepgram">
                  Deepgram
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="API Key"
              value={"...".concat(secret.keyRef)}
              size="small"
              onChange={(e) => handleChange(e.target.value)}
              className="m-r-8px"
              // disabled
            />
            <LoadingButton
              size="small"
              loading={loading}
              onClick={() => handleDelete(secret._id)}
            >
              Delete
            </LoadingButton>
          </div>
        ))}
      {isEmpty(secrets) && (
        <AddNewDeepgramKeysInstructions
          handleChange={handleChange}
          val={val}
          handleSaveSecret={handleSaveSecret}
          isDisabled={secrets?.length > 1}
          loading={loading}
        />
      )}
    </div>
  );
};

export default ManageDeepgramSecret;
