import type { TBlock, Edge } from "@sharedTypes";
import getPrecedingNodesRecursive from "./getPrecedingNodesRecursive";
import { Action } from "../toolBuilderReducer";

const useUpdateAvailableFields = (
  blocks: TBlock[],
  dispatch: React.Dispatch<Action>
) => {
  const updateAvailableFields = (blockId: string, blockEdges: Edge[]) => {
    const precedingNodes: TBlock[] = [];
    const currentFields = new Set<string>();

    getPrecedingNodesRecursive(
      blocks,
      blockId,
      blockEdges,
      new Set<string>(),
      precedingNodes,
      currentFields
    );

    dispatch({
      type: "SET_AVAILABLE_FIELDS",
      blockId: blockId,
      fields: Array.from(currentFields)
    });
  };
  return updateAvailableFields;
};

export default useUpdateAvailableFields;
