import type { UpdateSnippet } from "@sharedTypes";

import { type SubmitHandler, useFormContext } from "react-hook-form";
import { useSaveSnippetMutation } from "../../../ToolflowAPI/rtkRoutes/snippetsApi";
import { KNOWLEDGE_ROUTE_PATH } from "../../../navigation/routePaths";
import useToolflowNavigate from "../../../utilities/hooks/useToolflowNavigate";

const useSaveSnippet = () => {
  const [saveWorkflow, { isLoading }] = useSaveSnippetMutation();

  const { handleSubmit } = useFormContext<UpdateSnippet>();

  const navigate = useToolflowNavigate();

  const onSubmit: SubmitHandler<UpdateSnippet> = async (body) => {
    try {
      await saveWorkflow({
        body
      });
      navigate(KNOWLEDGE_ROUTE_PATH);
    } catch (error) {
      console.error("Failed to save snippet", error);
    }
  };

  const saveSnippet = () => {
    handleSubmit(onSubmit)();
  };

  return {
    saveSnippet,
    loading: isLoading
  };
};

export default useSaveSnippet;
