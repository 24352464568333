import React from "react";
import Button from "@mui/material/Button";
import useNavigateToTVRWorkspaceById from "../hooks/useNavigateToTVRWorkspaceById";

function ToolResponseButton({
  tvrId,
  className
}: {
  tvrId?: string;
  className?: string;
}) {
  const navigate = useNavigateToTVRWorkspaceById(tvrId);

  return (
    <Button
      onClick={navigate}
      className={className}
      size="small"
      variant="contained"
    >
      Use in Workspace Editor
    </Button>
  );
}

export default ToolResponseButton;
