import type { ProfileWorkflow, WorkflowVersion } from "@sharedTypes";
import React, { createContext, useContext } from "react";

interface WorkflowContextType {
  workflow: ProfileWorkflow;
  workflowVersion: WorkflowVersion;
}

const WorkflowContext = createContext<WorkflowContextType | undefined>(
  undefined
);

export function WorkflowContextComponent({
  children,
  workflow,
  workflowVersion
}: Readonly<{
  children: React.ReactNode;
  workflow?: ProfileWorkflow;
  workflowVersion?: WorkflowVersion;
}>) {
  if (!workflow || !workflowVersion) {
    return null;
  }
  return (
    <WorkflowContext.Provider value={{ workflow, workflowVersion }}>
      {children}
    </WorkflowContext.Provider>
  );
}

export const useWorkflowContext = () => {
  const context = useContext(WorkflowContext);
  if (context === undefined) {
    throw new Error(
      "useWorkflowContext must be used within a WorkflowProvider"
    );
  }
  return context;
};

export default WorkflowContext;
