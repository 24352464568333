import React from "react";
import { Typography } from "@mui/material";
import TollIcon from "@mui/icons-material/Toll";
import { UtiltityTooltip } from "./ToolflowInfo";

const CreditCostDetails = ({
  estimatedCreditCost,
  toolTipText = "Max credit cost",
  className = ""
}: {
  estimatedCreditCost?: number;
  toolTipText?: string;
  className?: string;
}) => {
  if (!estimatedCreditCost) return null;
  return (
    <UtiltityTooltip title={toolTipText}>
      <div className={`w-64px flex align-i-center ${className}`}>
        {estimatedCreditCost > 0 && (
          <>
            <TollIcon />
            <Typography className="m-l-8px" color="GrayText">
              {Math.round(estimatedCreditCost)}
            </Typography>
          </>
        )}
      </div>
    </UtiltityTooltip>
  );
};

export default CreditCostDetails;
