import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

import { useUpdateToolMutation } from "../ToolflowAPI/rtkRoutes/toolsApi";
import { useToolbuilderContext } from "./context/ToolBuilderContext";

// when close editing go back to main
// save main

function MakeLiveButton() {
  const { dispatch, state } = useToolbuilderContext();
  const { toolId, toolVersionId } = state;
  const [updateTool, { isLoading }] = useUpdateToolMutation();

  if (!toolId || toolVersionId === state.main?._id) {
    return null;
  }

  // LOOK HERE
  const executeUpdateMain = async () => {
    await updateTool({ body: { main: toolVersionId }, toolId })
      .unwrap()
      .then((resp) => {
        dispatch({
          type: "UPDATE_MAIN",
          main: resp.tool.main
        });
      });
  };

  return (
    <LoadingButton
      loading={isLoading}
      size="small"
      onClick={executeUpdateMain}
      className="m-r-16px"
    >
      Publish version
    </LoadingButton>
  );
}

export default MakeLiveButton;
