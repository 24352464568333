import type { CustomToolInputField } from "@sharedTypes";
import { isInputWithOptions } from "../../../utilities/Inputs/constants";
import { getToolInputFieldIndexById } from "../../../utilities/Inputs/inputBuilders/hooks/useGetToolInputFieldIndexById";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";

const useAddInputOptionToolbuilder = () => {
  const { dispatch, state } = useToolbuilderContext();
  const { toolInputFields } = state.currentState;

  const handleAddOption = (toolInputField: CustomToolInputField) => {
    if (!isInputWithOptions(toolInputField)) {
      console.log("Input field does not support options.");
      return;
    }

    const options = toolInputField.options || [];
    const updatedToolInputField = {
      ...toolInputField,
      options: [...options, ""]
    };
    const index = getToolInputFieldIndexById(
      toolInputFields,
      toolInputField.id
    );
    const updatedToolInputFields = [...toolInputFields];
    updatedToolInputFields[index] = updatedToolInputField;

    dispatch({
      type: "SET_TOOL_INPUT_FIELDS",
      toolInputFields: updatedToolInputFields
    });
  };

  return handleAddOption;
};

export default useAddInputOptionToolbuilder;
