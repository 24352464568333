import { useSelector } from "react-redux";
import { RootState } from "../../../../../../stores/store";
import { getTransformedMessages } from "../utils/getTransformedMessages";
import type { TMessageByThreadId } from "@sharedTypes";

const useChatMessages = () => {
  const messages = useSelector((store: RootState) => store.chat.messages);
  const hasSocketsFinished = messages[messages.length - 1]?.isLastMessage;

  let socketMessages: TMessageByThreadId[] = [];
  let nonSocketMessages: TMessageByThreadId[] = [];
  if (hasSocketsFinished === undefined || hasSocketsFinished) {
    nonSocketMessages = messages;
    socketMessages = [];
  } else {
    nonSocketMessages = messages.slice(0, messages.length - 1);
    const messagesWithoutLastSocket = messages.filter(
      (message) => !message.isLastMessage
    );
    socketMessages = messagesWithoutLastSocket.slice(-1);
  }

  const transformedSocketMessages = getTransformedMessages(socketMessages);
  const socketMessagesContent = transformedSocketMessages[0]?.content || "";
  const threadMessages = getTransformedMessages(
    nonSocketMessages.filter((message) => !message.isLastMessage)
  );
  return {
    messages,
    threadMessages,
    socketMessagesContent
  };
};
export default useChatMessages;
