import { Drawer, styled } from "@mui/material";
import { SMALL_DRAWER_WIDTH } from "../utilities/functions/formatHelpers";
import { closedMixin, openedMixin } from "./builderDrawerStyles";

const StyledBuilderDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: SMALL_DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

export default StyledBuilderDrawer;
