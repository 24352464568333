import { ListItem, ListItemText } from "@mui/material";
import React from "react";
import type { MarketplaceEntityAutocomplete } from "@sharedTypes";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { getTruncatedWords } from "../../features/marketplace/utils/getTruncatedWords";

function ToolSelectorOption({
  props,
  option,
  inputValue
}: {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: MarketplaceEntityAutocomplete;
  inputValue: string;
}) {
  const matches = match(option.primary, inputValue, { insideWords: true });
  const secondaryMatches = match(option.secondary, inputValue, {
    insideWords: true
  });
  const parts = parse(option.primary, matches);
  const secondaryParts = parse(option.secondary, secondaryMatches);

  return (
    <ListItem
      {...props} //need this before the key prop because props has a key param we want to overwrite
      key={option.id}
      data-id={option.id}
      dense
      disableGutters
      disablePadding
      id="toolMenuListItem"
    >
      <ListItemText
        primary={
          <div className="flex align-i-center">
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </div>
        }
        secondary={secondaryParts.map((part, index) => (
          <span
            key={index}
            style={{
              fontWeight: part.highlight ? 700 : 400
            }}
          >
            {getTruncatedWords(part.text, 25)}
          </span>
        ))}
      />
    </ListItem>
  );
}

export default ToolSelectorOption;
