import { v4 as uuidv4 } from "uuid";
import { TChipTag, TUseCaseTag } from "./hooks/useCategoryTags";

export const useCaseTags: TUseCaseTag[] = [
  { value: "prospecting", selected: false, id: uuidv4() },
  { value: "call notes", selected: false, id: uuidv4() },
  { value: "content creation", selected: false, id: uuidv4() },
  { value: "research", selected: false, id: uuidv4() },
  { value: "web scraping", selected: false, id: uuidv4() },
  { value: "other", selected: false, id: uuidv4() }
];

export const categoriesTags: TChipTag[] = [
  { value: "sales", selected: false, id: uuidv4() },
  { value: "marketing", selected: false, id: uuidv4() },
  { value: "growth", selected: false, id: uuidv4() },
  { value: "utilities", selected: false, id: uuidv4() }
];
