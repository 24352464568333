import { MenuItem } from "@mui/material";
import React from "react";
const visibilityMenuItemsArray = [
  <MenuItem value="private" key="private">
    Private - Only you can use
  </MenuItem>,
  <MenuItem value="restricted" key="restricted">
    Restricted - Anyone with the link can use
  </MenuItem>,
  <MenuItem value="public" key="public">
    Public - On your profile
  </MenuItem>
];

export default visibilityMenuItemsArray;
