import React from "react";
import { useUtilityDraggableContext } from "./UtilityDraggableContext";
import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box";
import ReactDOM from "react-dom";

function DraggingWrapper({
  preview,
  children,
  className = "",
  gap,
  disableDropIndicator
}: {
  preview: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  disableDropIndicator?: boolean;
  gap?: string;
}) {
  const { ref, closestEdge, draggableState } = useUtilityDraggableContext();

  return (
    <div className={`pos-relative ${className}`} ref={ref}>
      {children}
      {closestEdge && !disableDropIndicator && (
        <DropIndicator edge={closestEdge} gap={gap} />
      )}
      {draggableState.type === "preview" &&
        ReactDOM.createPortal(preview, draggableState.container)}
    </div>
  );
}

export default DraggingWrapper;
