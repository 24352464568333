import React from "react";
import { Link } from "@mui/icons-material";
import type { ValidateType } from "@sharedTypes";
import { useHandleCopy } from "../../hooks/useHandleCopy";
import { useSettingsDropdownContext } from "../generic/useSettingsDropownContext";
import SettingsMenuItem from "../generic/SettingsMenuItem";

function CopyLinkMenuItem({
  objectId,
  text,
  type
}: {
  text: string;
  objectId: string;
  type: ValidateType;
}) {
  const handleCopy = useHandleCopy(objectId, type);
  const { handleClose } = useSettingsDropdownContext();

  const copy = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleCopy();
    handleClose(e);
  };

  return (
    <SettingsMenuItem
      action={copy}
      text={text}
      icon={<Link fontSize="small" />}
      hotkey="Meta+C"
      hide={!objectId}
    />
  );
}

export default CopyLinkMenuItem;
