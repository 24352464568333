import type { Content } from "@tiptap/core";
import { useRichTextEditorContext } from "mui-tiptap";
import { useNodeViewContext } from "../../../utilities/contexts/NodeViewContext";
import type {
  AttributesType,
  UserInputDictType,
  WorkflowInput
} from "@sharedTypes";
import { useState } from "react";
import { getOutputNodes } from "../../functions/getToolNodes";

const useInsertContentBeforeNode = () => {
  const editor = useRichTextEditorContext();
  const { getPos } = useNodeViewContext<AttributesType>();
  function insertContentBeforeNode(content: Content) {
    // Calculate the position right before the node
    const positionBeforeNode = Math.max(getPos(), 0);

    // Use the `insertContentAt` method of the editor to insert the content
    editor
      ?.chain()
      .focus()
      .insertContentAt(positionBeforeNode, content, {
        parseOptions: { preserveWhitespace: false }
      })
      .run();
  }

  return insertContentBeforeNode;
};

const useSetInputInWorkspace = ({
  componentId,
  currentInputs
}: {
  componentId: string;
  currentInputs: React.MutableRefObject<WorkflowInput[]>;
}) => {
  const [inputSet, setInputSet] = useState<Set<string>>(new Set());
  const insertContentBeforeNode = useInsertContentBeforeNode();
  const setInputInWorkspace = (output: UserInputDictType | null) => {
    const savedInputs = [...currentInputs.current];
    if (output) {
      savedInputs.map((input) => {
        if (
          !inputSet.has(`toolOutput-${componentId}-${input.name}`) &&
          (input.data?.outputId || "") in output
        ) {
          insertContentBeforeNode(getOutputNodes(input.id));
          setInputSet((prevInputSet: Set<string>) =>
            prevInputSet.add(`toolOutput-${componentId}-${input.name}`)
          );
        }
      });
    }
  };
  return setInputInWorkspace;
};

export default useSetInputInWorkspace;
