import React from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import type { ButtonSizeTypes, ButtonVariantTypes } from "@sharedTypes";
import useNavigateToToolById from "../hooks/useNavigateToToolById";

function VisitSiteButton({
  responsive,
  toolId,
  className,
  variant = "contained",
  text = "Open",
  size = "medium"
}: {
  responsive?: boolean;
  toolId?: string;
  className?: string;
  variant?: ButtonVariantTypes;
  text?: string;
  size?: ButtonSizeTypes;
}) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const handleNavigate = useNavigateToToolById(toolId);

  if (!toolId) {
    return null;
  }

  return (
    <>
      {responsive && isLargeScreen ? (
        <IconButton
          onClick={handleNavigate}
          color="primary"
          className={className}
        >
          <PlayCircleIcon />
        </IconButton>
      ) : (
        <Button
          size={size}
          onClick={handleNavigate}
          variant={variant}
          className={className}
        >
          {text}
        </Button>
      )}
    </>
  );
}

export default VisitSiteButton;
