import type { TMarketplaceEntity } from "@sharedTypes";
import useMarketplaceEntities from "../../../features/marketplace/hooks/useMarketplaceEntities";

const useGetMarketplaceSelectorOptions = () => {
  const { filteredMarketplaceEntities } = useMarketplaceEntities({
    fromSearch: true
  });
  const options: Array<TMarketplaceEntity> = filteredMarketplaceEntities.filter(
    (
      (seenIds) => (item: TMarketplaceEntity) =>
        !seenIds.has(item.id) && seenIds.add(item.id)
    )(new Set<string>())
  );
  return options;
};

export default useGetMarketplaceSelectorOptions;
