import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigateViaSidebar } from "./useNavigateViaSidebar";
import SidebarButton from "./SidebarButton"; // Assuming SidebarButton is now the presentational component
import { useSelector } from "react-redux";
import { selectSidebarOpen } from "./sidebarSlice";
import type { Match } from "path-to-regexp";

interface SidebarButtonContainerProps {
  text: string;
  route?: string;
  icon: React.ReactNode;
  selectedIcon: React.ReactNode;
  externalLink?: boolean;
  callback?: () => void;
  matcher?: (path: string) => Match<object> | null;
}

const SidebarButtonContainer: React.FC<SidebarButtonContainerProps> = ({
  text,
  route,
  icon,
  externalLink,
  selectedIcon,
  matcher
}) => {
  const location = useLocation();
  const showSelected = matcher ? !!matcher(location.pathname) : false;
  const { navigateTo } = useNavigateViaSidebar(route, externalLink);
  const open = useSelector(selectSidebarOpen);

  const onClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (externalLink || route) {
      navigateTo(e);
    }
  };

  return (
    <SidebarButton
      text={text}
      icon={showSelected ? selectedIcon : icon}
      open={open}
      showSelected={showSelected}
      onClick={onClick}
    />
  );
};

export default SidebarButtonContainer;
