import type { TSetSelectedTool, ToolAutocomplete } from "@sharedTypes";
import { isAddNewTool } from "./useToolSelectorHighlight";
import { useNavigate } from "react-router-dom";
import { TOOLBUILDER_BASE_ROUTE_PATH } from "../../../navigation/routePaths";

const useHandleOptionClick = ({
  setSelectedTool,
  setSelectedWorkflow
}: {
  setSelectedTool: TSetSelectedTool;
  setSelectedWorkflow?: TSetSelectedTool;
}) => {
  const navigate = useNavigate();
  const navigateToToolbuilder = () => {
    navigate(TOOLBUILDER_BASE_ROUTE_PATH);
  };

  const handleClick = (optionSelected: ToolAutocomplete) => {
    if (isAddNewTool(optionSelected)) {
      navigateToToolbuilder();
    } else if (optionSelected?.id && optionSelected.group === "Tool") {
      setSelectedTool(optionSelected.id);
    } else if (
      optionSelected?.id &&
      optionSelected.group === "Workflow" &&
      setSelectedWorkflow
    ) {
      setSelectedWorkflow(optionSelected.id);
    }
  };
  return handleClick;
};

export default useHandleOptionClick;
