import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import ExternalPageWrapper from "./externalPageWrapper/ExternalPageWrapper";
import useValidateObject from "../workspace/hooks/useValidateObject";
import CircularLoading from "./CircularLoading";
import useNavigateToWorkspaceWithDefaultObject from "./hooks/useNavigateToWorkspaceWithDefaultObject";

function ReroutePage() {
  const { isError, isSuccess, type, externalId } = useValidateObject();
  const navigateToWorkspace = useNavigateToWorkspaceWithDefaultObject({
    type,
    id: externalId
  });

  useEffect(() => {
    if (isSuccess) {
      navigateToWorkspace();
    }
  }, [isSuccess]);

  if (isError) {
    return <Navigate to="/" replace />;
  }

  return (
    <ExternalPageWrapper enableFullHeight>
      <CircularLoading />
    </ExternalPageWrapper>
  );
}

export default ReroutePage;
