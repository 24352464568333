import type { WorkflowInput } from "@sharedTypes";
import { generateHTML } from "@tiptap/core";
import useConvertTextToTipTap from "../../features/workstation/newDesigns/components/sheetAsset/sheet/utils/hooks/useConvertTextToTipTap";
import useExtensions from "./useExtension";

const useGetInputHtml = () => {
  const convertTextToTipTap = useConvertTextToTipTap();
  const extensions = useExtensions();
  const getInputHtml = (input: WorkflowInput | null) => {
    let jsonToConvert;
    if (!input) return "";
    if (typeof input.value === "string") {
      jsonToConvert = convertTextToTipTap(input.value);
    } else {
      jsonToConvert = input.value;
    }
    return generateHTML(jsonToConvert, extensions);
  };
  return getInputHtml;
};

export default useGetInputHtml;
