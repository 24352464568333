import type { PopulatedToolVersionResponseFE } from "@sharedTypes";
import { Duration } from "luxon";
import {
  EXTERNAL_TOOL_BASE_ROUTE_PATH,
  EXTERNAL_TOOL_VERSION_RESPONSE_BASE_ROUTE_PATH,
  EXTERNAL_WORKFLOW_BASE_ROUTE_PATH,
  EXTERNAL_WORKSPACE_BASE_ROUTE_PATH
} from "../../navigation/routePaths";
export function formatDuration(duration: Duration) {
  // Rounding off the seconds
  const seconds = Math.round(duration.seconds);

  // You can use the toFormat function to format the duration
  // This will display years, months, days, hours, minutes, and seconds, but only if their value is greater than 0
  let durationStr = "";
  if (duration.years > 0) {
    durationStr += `${duration.years} year${duration.years === 1 ? "" : "s"}, `;
  }
  if (duration.months > 0) {
    durationStr += `${duration.months} month${
      duration.months === 1 ? "" : "s"
    }, `;
  }
  if (duration.days > 0) {
    durationStr += `${duration.days} day${duration.days === 1 ? "" : "s"}, `;
  }
  if (duration.hours > 0) {
    durationStr += `${duration.hours} hour${duration.hours === 1 ? "" : "s"}, `;
  }
  if (duration.minutes > 0) {
    durationStr += `${duration.minutes} minute${
      duration.minutes === 1 ? "" : "s"
    }, `;
  }
  if (seconds > 0) {
    durationStr += `${seconds} second${seconds === 1 ? "" : "s"}, `;
  }

  // Remove the trailing comma and space
  durationStr = durationStr.trimEnd().slice(0, -1);

  return durationStr;
}

export const getTVRLink = (id: string) =>
  `${process.env.REACT_APP_FE_URL}${EXTERNAL_TOOL_VERSION_RESPONSE_BASE_ROUTE_PATH}${id}`;

export const getToolLink = (id: string) =>
  `${process.env.REACT_APP_FE_URL}${EXTERNAL_TOOL_BASE_ROUTE_PATH}${id}`;

export const getWorkspaceLink = (id: string) =>
  `${process.env.REACT_APP_FE_URL}${EXTERNAL_WORKSPACE_BASE_ROUTE_PATH}${id}`;

export const getWorkflowLink = (id: string) =>
  `${process.env.REACT_APP_FE_URL}${EXTERNAL_WORKFLOW_BASE_ROUTE_PATH}${id}`;

export const getToolVersionNameWithError = (
  tvr?: PopulatedToolVersionResponseFE
) => {
  return tvr?.toolId?.name || "Tool Error";
};

export const SMALL_DRAWER_WIDTH = 256;
export const CLOSED_DRAWER_WIDTH = 0;
export const MEDIUM_DRAWER_WIDTH = 300;
export const LARGE_DRAWER_WIDTH = 490;

export const SNIPPET_CHAR_LENGTH = 40;
export const TOOL_TIP_CHAR_LENGTH = 80;

export const getBaseText = (s: string, stringLength: number) => {
  return `${s.substring(0, stringLength)}${
    s.length > stringLength ? "..." : ""
  }`;
};

export const getToolTipText = (s: string) => {
  return getBaseText(s, TOOL_TIP_CHAR_LENGTH);
};

export const getSnippetText = (s: string) => {
  return getBaseText(s, SNIPPET_CHAR_LENGTH);
};
