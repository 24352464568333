import { Button } from "@mui/material";
import React, { FC } from "react";
import useToolflowNavigate from "../hooks/useToolflowNavigate";
import { MARKETPLACE_ROUTE_PATH } from "../../navigation/routePaths";

interface INavigateToMarketplaceButtonProps {
  className?: string;
  variant?: "contained" | "outlined" | "text";
  buttonText?: string;
}
const NavigateToMarketplaceButton: FC<INavigateToMarketplaceButtonProps> = ({
  className = "",
  variant = "contained",
  buttonText = "Visit marketplace"
}) => {
  const navigate = useToolflowNavigate();
  const navigateToMarketplace = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(MARKETPLACE_ROUTE_PATH);
  };
  return (
    <Button
      variant={variant}
      className={`${className}`}
      onClick={navigateToMarketplace}
    >
      {buttonText}
    </Button>
  );
};

export default NavigateToMarketplaceButton;
