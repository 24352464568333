import { useFormContext } from "react-hook-form";
import { COPYABLE_LARGE_TEXTFIELD } from "../../Inputs/outputConstants";
import useCurrentInputsRef from "./useCurrentInputsRef";
import type {
  CustomToolOutputField,
  ExternalToolFE,
  TWorkspaceDataToSave,
  WorkflowToolInputs
} from "@sharedTypes";
import { v4 as uuidv4 } from "uuid";
import { WORKSPACE_INPUTS_FIELD_LABEL } from "../../../features/workstation/workspaceConstants";

const useAddToolInputsToWorkspace = () => {
  const currentInputs = useCurrentInputsRef();
  const { setValue } = useFormContext<TWorkspaceDataToSave>();

  const addToolInputsToWorkspace = ({
    componentId,
    tool,
    addInputsCallback
  }: {
    componentId: string;
    tool: ExternalToolFE;
    addInputsCallback?: (
      id: string,
      outputField: CustomToolOutputField
    ) => void;
  }) => {
    const { _id: toolId, main, creator } = tool;
    const { toolOutputFields } = main;
    const oldInputs = currentInputs.current.filter(
      (i) => i.sourceIdentifier !== componentId
    );
    const savedInputs = currentInputs.current.filter(
      (i) => i.sourceIdentifier === componentId
    );

    const updatedInputs: WorkflowToolInputs[] = toolOutputFields.map(
      (outputField) => {
        // Check for a matching saved input
        // this will only be dalle or string
        const existingInput: WorkflowToolInputs | undefined = savedInputs.find(
          (i) =>
            i.data?.outputId === outputField.id &&
            i.sourceIdentifier === componentId &&
            i.valueType
        ) as WorkflowToolInputs | undefined;

        if (existingInput) {
          // Update existing input
          return {
            ...existingInput,
            name: outputField.name, // Updated name
            value: existingInput.value, // Keep existing value or modify as needed
            valueType:
              outputField.type === COPYABLE_LARGE_TEXTFIELD
                ? "string"
                : "dallE2"
          } as WorkflowToolInputs;
        } else {
          const id = uuidv4();
          if (addInputsCallback) {
            addInputsCallback(id, outputField);
          }
          // Create new input
          return {
            id,
            name: outputField.name,
            value: "",
            valueType:
              outputField.type === COPYABLE_LARGE_TEXTFIELD
                ? "string"
                : "dallE2",
            source: "toolOutputPlaceholder",
            sourceIdentifier: componentId,
            data: {
              toolId,
              creatorId: creator._id,
              outputId: outputField.id
            },
            loading: false
          } as WorkflowToolInputs;
        }
      }
    );

    const replacedArray = [...oldInputs, ...updatedInputs];
    // we can't use replace, because we can't dirty it so that it doesn't update the backend
    queueMicrotask(() => {
      setValue(WORKSPACE_INPUTS_FIELD_LABEL, replacedArray, {
        shouldDirty: false,
        shouldTouch: false,
        shouldValidate: false
      });
    });
  };
  return addToolInputsToWorkspace;
};

export default useAddToolInputsToWorkspace;
