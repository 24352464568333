import React from "react";
import SnippetFormProvider from "./SnippetFormProvider";
import { Card } from "@mui/material";
import SnippetNameTextField from "./SnippetNameTextField";
import SnippetValueTextField from "./SnippetValueTextField";
import SnippetField from "./SnippetField";
import SaveOrUpdateSnippetButton from "./Buttons/SaveOrUpdateSnippetButton";

const SnippetFormCard = () => {
  return (
    <SnippetFormProvider>
      <Card sx={{ pt: 4, pb: 4, pr: 5, pl: 5, mt: 3 }} variant="outlined">
        <SnippetField
          title="Name"
          subheader="Choose a unique, descriptive name that captures the essence of the snippet."
          textField={<SnippetNameTextField />}
        />
        <SnippetField
          title="Text"
          subheader="Enter or paste the content for your snippet here. You can type it manually or copy it from another source."
          textField={<SnippetValueTextField />}
        />
        <SaveOrUpdateSnippetButton />
      </Card>
    </SnippetFormProvider>
  );
};

export default SnippetFormCard;
