export const HOME_ROUTE_PATH_BASE = "/home";
export const HOME_ROUTE_PATH_DEV = `${HOME_ROUTE_PATH_BASE}/:nestedRoute?`;
export const HOME_NESTED_MAIN_ROUTE_PATH = `${HOME_ROUTE_PATH_BASE}/main`;
export const HOME_NESTED_TOOLS_ROUTE_PATH = `${HOME_ROUTE_PATH_BASE}/tools`;
export const HOME_NESTED_HISTORY_ROUTE_PATH = `${HOME_ROUTE_PATH_BASE}/history`;
export const BASE_ROUTE_PATH = "/";

export const TOOLBUILDER_BASE_ROUTE_PATH = "/t/";
export const TOOLBUILDER_ROUTE_PATH = `${TOOLBUILDER_BASE_ROUTE_PATH}:toolId?`;

export const SELECTBUILDER_ROUTE_PATH = "/builder";

export const KNOWLEDGE_ROUTE_PATH = "/knowledge";
export const SNIPPET_FORM_SUBPATH = "/snippet";
export const SNIPPET_FORM_PATH = `${KNOWLEDGE_ROUTE_PATH}${SNIPPET_FORM_SUBPATH}/`;

export const SNIPPET_ROUTE_PATH = `${SNIPPET_FORM_PATH}:snippetId?`;

export const WORKFLOWBUILDER_BASE_ROUTE_PATH = "/w/";
export const WORKFLOWBUILDER_ROUTE_PATH = `${WORKFLOWBUILDER_BASE_ROUTE_PATH}:workflowId?`;

export const LOGIN_ROUTE_PATH = "/login";
export const SIGNUP_ROUTE_PATH = "/signUp";

export const PROFILE_BASE_ROUTE_PATH = "/profile";
export const PROFILE_ROUTE_PATH = `${PROFILE_BASE_ROUTE_PATH}/:profileNestedRoute?`;
export const PROFILE_NESTED_MAIN_ROUTE_PATH = `${PROFILE_BASE_ROUTE_PATH}/main`;
export const PROFILE_NESTED_BILLING_ROUTE_PATH = `${PROFILE_BASE_ROUTE_PATH}/billing`;
export const PROFILE_NESTED_SETTINGS_ROUTE_PATH = "/profile/settings";

export const SETTINGS_BASE_ROUTE_PATH = "/settings/";
export const SETTINGS_ROUTE_PATH = `${SETTINGS_BASE_ROUTE_PATH}:tab?`;

export const MARKETPLACE_ROUTE_PATH = "/marketplace";
export const WORKSPACE_ROUTE_PATH = "/workspace";

export const EXTERNAL_TOOL_BASE_ROUTE_PATH = "/tool/";
export const EXTERNAL_TOOL_ROUTE_PATH = `${EXTERNAL_TOOL_BASE_ROUTE_PATH}:externalId`;

export const EXTERNAL_WORKFLOW_BASE_ROUTE_PATH = "/workflow/";
export const EXTERNAL_WORKFLOW_ROUTE_PATH = `${EXTERNAL_WORKFLOW_BASE_ROUTE_PATH}:externalId`;

export const EXTERNAL_WORKSPACE_BASE_ROUTE_PATH = "/workspace/document/";
export const EXTERNAL_WORKSPACE_ROUTE_PATH = `${EXTERNAL_WORKSPACE_BASE_ROUTE_PATH}:externalId`;

export const EXTERNAL_TOOL_VERSION_RESPONSE_BASE_ROUTE_PATH = "/tvr/";
export const EXTERNAL_TOOL_VERSION_RESPONSE_ROUTE_PATH = `${EXTERNAL_TOOL_VERSION_RESPONSE_BASE_ROUTE_PATH}:externalId`;
