import React, { useEffect } from "react";
import { marketplaceCategoryTabs } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";
import type { TCategory } from "@sharedTypes";
import { setCategory } from "../../slice/marketplaceSlice";

const useMarketplaceCategory = () => {
  const reduxCategory = useSelector(
    (state: RootState) => state.marketplace.category
  );
  const currentTabCategory = marketplaceCategoryTabs.find(
    (tab) => tab.value === reduxCategory
  );
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(
    currentTabCategory?.indexValue || 0
  );

  useEffect(() => {
    const tabIndex =
      marketplaceCategoryTabs.find((tab) => tab.value === reduxCategory)
        ?.indexValue || 0;
    setSelectedTabIndex(tabIndex);
  }, [reduxCategory]);

  const dispatch = useDispatch();
  const handleChangeTabIndex = (
    event: React.SyntheticEvent,
    newSelectedTabIndex: number
  ) => {
    const selectedCategory = marketplaceCategoryTabs.find(
      (tab) => tab.indexValue === newSelectedTabIndex
    );
    if (!selectedCategory) return;
    const selectedCategoryValue = selectedCategory?.value as TCategory;
    dispatch(setCategory(selectedCategoryValue));
  };
  return {
    selectedTabIndex,
    handleChangeTabIndex,
    marketplaceCategoryTabs
  };
};

export default useMarketplaceCategory;
