import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { security } from "./security";
import { setErrorMessage, setSuccessMessage } from "../stores/actions";

export const AUTH0_REDUCER_NAME: TAuth0ReducerName = "auth0API";
type TAuth0ReducerName = "auth0API";

export const auth0API = createApi({
  reducerPath: AUTH0_REDUCER_NAME,
  baseQuery: fetchBaseQuery({
    baseUrl: `https://${process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN}`,
    prepareHeaders: async (headers) => {
      const tokenFunc = await security.getAccessTokenSilently();
      if (tokenFunc) {
        const accessToken = await tokenFunc();
        if (accessToken) {
          headers.set("Content-Type", "application/json");
          headers.set("Authorization", `Bearer ${accessToken}`);
        }
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    passwordReset: builder.query({
      query: (email: string) => ({
        url: "dbconnections/change_password",
        method: "POST",
        responseHandler: "text",
        body: {
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          email: email,
          connection: "Username-Password-Authentication"
        }
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // // `onStart` side-effect
        // dispatch(messageCreated("Fetching post..."));
        try {
          // const {data} = queryFulfilled
          await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(setSuccessMessage("Password reset email sent"));
        } catch (err) {
          // `onError` side-effect
          dispatch(setErrorMessage("Error resetting password"));
        }
      }
    })
  })
});

export const { useLazyPasswordResetQuery } = auth0API;
