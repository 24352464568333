import React from "react";
import SettingsMenuItem from "../../../utilities/dropdowns/generic/SettingsMenuItem";
import { Delete } from "@mui/icons-material";
import useHandleCloseNode from "../../hooks/editorToolCardHooks/useHandleCloseNode";

const DeleteEditorToolNodeMenuItem = () => {
  const handleClose = useHandleCloseNode();
  return (
    <SettingsMenuItem
      text="Delete"
      icon={<Delete fontSize="small" />}
      action={handleClose}
      hotkey="Del"
    />
  );
};

export default DeleteEditorToolNodeMenuItem;
