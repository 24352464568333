import React from "react";
import SettingsMenuItemWithClose from "../../../utilities/dropdowns/generic/SettingsMenuItemWithClose";
import { RiLogoutBoxRLine } from "@remixicon/react";
import { useHandleLogout } from "../../layout/sidebar/useHandleLogout";

const LogoutMenuItem = () => {
  const handleLogout = useHandleLogout();

  return (
    <SettingsMenuItemWithClose
      icon={<RiLogoutBoxRLine size={18} />}
      text="Log out"
      action={handleLogout}
    />
  );
};

export default LogoutMenuItem;
