import { NodeViewWrapper, type NodeViewProps } from "@tiptap/react";
import React, { useEffect, useMemo, useRef } from "react";
import ToolflowNodeWrapper from "../../utilities/ToolflowNodeWrapper";
import OutputSettingsDropdown from "../dropdowns/OutputSettingsDropdown";
import SettingsMenuItem from "../../utilities/dropdowns/generic/SettingsMenuItem";
import { Close, Delete, Replay } from "@mui/icons-material";
import UpdateInputValueTextField from "../UpdateInputValueTextfield";
import useDeleteInput from "../../utilities/hooks/useDeleteInput";
import { GenericRichHtml } from "../../utilities/GenericRichText";
import { getHtmlFromText } from "../../utilities/functions/htmlHelpers";
import getToolNodes from "../functions/getToolNodes";
import useWorkspaceInputs from "../../features/workstation/hooks/useWorkspaceInputs";
import { STRING_INPUT } from "../../features/workstation/workspaceConstants";
import type { WorkflowInputString } from "@sharedTypes";
import ToggleUpdateInputNameTextField from "../ToggleUpdateInputNameTextfield";

const TextOutputViewerComponent = (props: NodeViewProps) => {
  const inputs = useWorkspaceInputs();

  const input = useMemo(() => {
    return inputs.filter((i) => i.id === props.node.attrs.inputId)[0];
  }, [props.node.attrs.inputId, inputs]);

  useEffect(() => {
    // switched from timer to microtask because it is cleaner
    queueMicrotask(() => {
      try {
        props.updateAttributes({
          textValue: input?.value,
          name: input?.name,
          componentId: input?.sourceIdentifier,
          loading: input?.loading
        });
      } catch (e) {
        console.error(`error updating attributes with input ${input}`, e);
      }
    });
  }, [input?.name, input?.value, input?.loading]);
  const { deleteNode: handleClose } = props;
  const deleteInput = useDeleteInput();

  const handleDeleteAndRemove = () => {
    deleteInput(props.node.attrs.inputId);
    handleClose();
  };

  const rerun = () => {
    const { getPos } = props;
    const newPos = Math.max(getPos(), 0);
    const toolId = input.data?.toolId || "";
    const userInput = input.data?.userInput;
    const sourceIdentifier = input.sourceIdentifier;
    queueMicrotask(() => {
      handleClose();
    });
    queueMicrotask(() => {
      props.editor
        .chain()
        .insertContentAt(
          newPos,
          getToolNodes(toolId, "tool", true, userInput, sourceIdentifier),
          { parseOptions: { preserveWhitespace: false } }
        )
        .run(); // we can't useEditorInsertion, because we don't want focus to happen. Focus is required for adding inputs to textfields
    });
  };

  const divRef = useRef<HTMLDivElement>();

  useEffect(() => {
    // this is so that people can press enter to focus on the next node
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        const editor = props.editor;
        if (editor) {
          const { to } = editor.state.selection;
          const pos = to;

          // Check if the position is within the document
          if (pos <= editor.state.doc.content.size) {
            event.preventDefault();
            event.stopPropagation();
            editor.commands.focus(pos);
          }
        }
      }
    };

    if (divRef.current) {
      divRef.current.addEventListener("keydown", handleKeyDown);
    }

    // Clean up the event listener when the component is unmounted
    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [divRef.current]);

  if (input.value !== STRING_INPUT) return null;

  const menuItems = [
    <SettingsMenuItem
      text="Rerun tool"
      icon={<Replay fontSize="small" />}
      action={rerun}
      hotkey="Meta+R"
      key="rerun"
    />,
    <SettingsMenuItem
      text="Hide"
      icon={<Close fontSize="small" />}
      action={handleClose}
      hotkey="Meta+H"
      key="hide"
    />,
    <SettingsMenuItem
      text="Delete"
      icon={<Delete fontSize="small" />}
      action={handleDeleteAndRemove}
      hotkey="Del"
      key="delete"
    />
  ];

  return (
    <NodeViewWrapper
      className="flex tip-tap-text-output-component negative-horizontal-margin-8"
      ref={divRef}
    >
      {input && (
        <ToolflowNodeWrapper
          title={
            <ToggleUpdateInputNameTextField
              input={input}
              variant="caption"
              color="GrayText"
              className="m-l-16px"
              wrapperClassName="m-b-8px"
            />
          }
          contentEditable="false"
          alwaysShowOutline={input.loading}
          settings={
            <OutputSettingsDropdown input={input} menuItems={menuItems} />
          }
        >
          {/* we don't want people to edit outputs while they are streaming,
          note flickering happens because of strictmode */}
          {input.loading ? (
            <GenericRichHtml
              html={getHtmlFromText(input?.value as string)}
              rerender
            />
          ) : (
            <UpdateInputValueTextField input={input as WorkflowInputString} />
          )}
        </ToolflowNodeWrapper>
      )}
    </NodeViewWrapper>
  );
};

export default TextOutputViewerComponent;
