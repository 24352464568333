import { useFormContext } from "react-hook-form";
import type { TWorkspaceDataToSave } from "@sharedTypes";
import { WORKSPACE_WORKFLOW_ID_FIELD_LABEL } from "../workspaceConstants";
import useGetWorkflowFromQuery from "../../../utilities/toolSelector/hooks/useGetWorkflowFromQuery";

const useGetSelectedWorkspaceWorkflow = () => {
  const { watch } = useFormContext<TWorkspaceDataToSave>();
  const workflowId = watch(WORKSPACE_WORKFLOW_ID_FIELD_LABEL) || undefined;
  return useGetWorkflowFromQuery(workflowId);
};

export default useGetSelectedWorkspaceWorkflow;
