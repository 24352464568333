import React from "react";
import { CardContent, CardHeader, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomToolOutputFields from "./customToolOutputFields/CustomToolOutputFields";

function ToolOutputDrawer({ handleClose }: { handleClose: () => void }) {
  return (
    <>
      <CardHeader
        title="Outputs"
        action={
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <CustomToolOutputFields />
      </CardContent>
    </>
  );
}

export default ToolOutputDrawer;
