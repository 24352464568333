import React from "react";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import ListIcon from "@mui/icons-material/List";
import TuneIcon from "@mui/icons-material/Tune";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import type {
  CustomToolOutputFieldTypes,
  ValidatedInputTypes
} from "@sharedTypes";
import ImageIcon from "@mui/icons-material/Image";
import {
  BLOCK_OUTPUT,
  CHECKBOX,
  CSV_FILE_UPLOAD,
  DOUBLE_TEXTFIELD,
  FILE_UPLOAD,
  LARGE_TEXTFIELD,
  MULTI_SELECT,
  SELECT,
  TAGS_INPUT,
  TEXTFIELD
} from "../Inputs/inputConstants";

const iconDict: { [K in ValidatedInputTypes]: React.ReactElement } = {
  [FILE_UPLOAD]: <FilePresentIcon />,
  [SELECT]: <ListIcon />,
  [CHECKBOX]: <CheckBoxOutlinedIcon />,
  [TEXTFIELD]: <TextFieldsIcon />,
  [LARGE_TEXTFIELD]: <TextFieldsIcon />,
  [CSV_FILE_UPLOAD]: <FilePresentIcon />,
  [MULTI_SELECT]: <ListIcon />,
  [BLOCK_OUTPUT]: <TextFieldsIcon />,
  [TAGS_INPUT]: <TuneIcon />,
  [DOUBLE_TEXTFIELD]: <FindReplaceIcon />
};

export const labelDict: { [K in ValidatedInputTypes]: string } = {
  [FILE_UPLOAD]: "File Upload",
  [SELECT]: "Dropdown",
  [CHECKBOX]: "Checkbox",
  [TEXTFIELD]: "Text Field",
  [LARGE_TEXTFIELD]: "Large Text Field",
  [CSV_FILE_UPLOAD]: "CSV File Upload",
  [MULTI_SELECT]: "Multiselect Dropdown",
  [BLOCK_OUTPUT]: "Block Output",
  [TAGS_INPUT]: "Tags Input",
  [DOUBLE_TEXTFIELD]: "Double TextField"
};

export const outputIconDict: {
  [key in CustomToolOutputFieldTypes]: React.ReactElement;
} = {
  copyableImage: <ImageIcon />,
  copyableLargeTextField: <TextFieldsIcon />
};

export default iconDict;
