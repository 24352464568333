import React, { createContext, useContext, useRef, useState } from "react";
import getFilteredFields from "../functions/getFilteredFields";
import useToolInputFieldsAndAvailableFields from "../../useToolInputFieldsAndAvailableFields.ts";
import type { ValidatedInput } from "@sharedTypes";

interface PromptEditorContextType {
  textFieldRef: React.RefObject<HTMLInputElement>;
  openInputModal: boolean;
  setOpenInputModal: React.Dispatch<React.SetStateAction<boolean>>;
  typedChars: string;
  setTypedChars: React.Dispatch<React.SetStateAction<string>>;
  contextMenu: {
    mouseX: number;
    mouseY: number;
  } | null;
  setContextMenu: React.Dispatch<
    React.SetStateAction<{
      mouseX: number;
      mouseY: number;
    } | null>
  >;
  menuIndex: number;
  setMenuIndex: React.Dispatch<React.SetStateAction<number>>;
  handleMenuClick: (textToInsert: string) => void;
  handleToolInputFieldClick: (stringValue: string) => void;
  handleUpdatePrompt: (newPrompt: string) => void;
  id: string;
  prompt: string;
  menuFilteredFields: ValidatedInput[];
  helperText?: string;
  maxLength?: number;
}

const PromptEditorContext = createContext<PromptEditorContextType | undefined>(
  undefined
);

export function PromptEditorContextComponent({
  children,
  handleUpdatePrompt,
  prompt,
  id,
  helperText,
  maxLength
}: Readonly<{
  children: React.ReactNode;
  handleUpdatePrompt: (newPrompt: string) => void;
  prompt: string;
  id: string;
  helperText?: string;
  maxLength?: number;
}>) {
  const textFieldRef = useRef<HTMLInputElement>(null);

  const [openInputModal, setOpenInputModal] = useState(false);
  const [typedChars, setTypedChars] = useState("");
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [menuIndex, setMenuIndex] = React.useState(0);

  const handleMenuClick = (textToInsert: string) => {
    const originalScrollTop = textFieldRef.current?.scrollTop ?? 0;
    let addLength = 0;
    if (!!contextMenu || openInputModal) {
      addLength = typedChars.length + 1;
    }
    const startPos = (textFieldRef.current?.selectionStart ?? 0) - addLength;
    const endPos = textFieldRef.current?.selectionEnd ?? 0;
    const newValue =
      prompt.slice(0, startPos) + textToInsert + prompt.slice(endPos);
    handleUpdatePrompt(newValue);

    // Delay re-setting selection because state update is asynchronous
    setTimeout(() => {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
        textFieldRef.current.selectionStart = startPos + textToInsert.length;
        textFieldRef.current.selectionEnd = startPos + textToInsert.length;

        textFieldRef.current.scrollTop = originalScrollTop;
      }
    }, 0);
    setContextMenu(null);
  };

  const handleToolInputFieldClick = (stringValue: string) => {
    handleMenuClick(`{{${stringValue}}}`);
  };

  const toolInputFields = useToolInputFieldsAndAvailableFields(id);
  const filteredFields = getFilteredFields(toolInputFields);

  const menuFilteredFields = filteredFields.filter((field) =>
    field.name.toLowerCase().startsWith(typedChars.toLowerCase())
  );

  return (
    <PromptEditorContext.Provider
      value={{
        textFieldRef,
        openInputModal,
        setOpenInputModal,
        typedChars,
        setTypedChars,
        contextMenu,
        setContextMenu,
        menuIndex,
        setMenuIndex,
        handleMenuClick,
        handleToolInputFieldClick,
        handleUpdatePrompt,
        prompt,
        id,
        menuFilteredFields,
        helperText,
        maxLength
      }}
    >
      {children}
    </PromptEditorContext.Provider>
  );
}

export const usePromptEditorContext = () => {
  const context = useContext(PromptEditorContext);
  if (context === undefined) {
    throw new Error(
      "usePromptEditorContext must be used within a QuadrantProvider"
    );
  }
  return context;
};

export default PromptEditorContext;
