import { useLocation, useParams } from "react-router-dom";
import { useAuth0Store } from "../../hooks/useAuth0Store";
import { useGetValidateQuery } from "../../ToolflowAPI/rtkRoutes/validateApi";
import {
  externalToolMatcher,
  externalWorkflowMatcher,
  tvrMatcher
} from "../../navigation/matchers";
import type { ValidateType } from "@sharedTypes";

const useValidateObject = () => {
  const { isLoading } = useAuth0Store();
  const { externalId = "" } = useParams();
  const location = useLocation();
  const pathname = location.pathname;
  let type: ValidateType = "workspace";

  if (externalToolMatcher(pathname)) {
    type = "tool";
  } else if (tvrMatcher(pathname)) {
    type = "toolVersionResponse";
  } else if (externalWorkflowMatcher(pathname)) {
    type = "workflow";
  }

  const methods = useGetValidateQuery(
    { id: externalId, type },
    { skip: isLoading || !externalId }
  );
  return { ...methods, type, externalId };
};

export default useValidateObject;
