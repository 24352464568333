import React from "react";
import ToolRunningActions from "./ToolRunningActions";
import ToolActions from "./ToolActions";
import useToolStillLoading from "./hooks/useToolStillLoading";
import { useWebsocketContext } from "../../contexts/useWebsocketContext";
import useSendInput from "./hooks/useSendInputAndAbort";

const AuthenticatedActions = () => {
  const { resetButton } = useWebsocketContext();
  const toolStillLoading = useToolStillLoading();
  const { sendInput, abort } = useSendInput();
  return (
    <>
      {toolStillLoading ? (
        <ToolRunningActions abort={abort} />
      ) : (
        <ToolActions sendInput={sendInput} />
      )}
      {resetButton}
    </>
  );
};

export default AuthenticatedActions;
