import { Button } from "@mui/material";
import React from "react";
import {
  FREE_PLAN_FE,
  useAuthorizedTier
} from "../../../../../../../settings/BillingCard";
import { useSheetContext } from "../SheetContext";
import { TMaterialRow, TMaterialTable } from "../sheetTypes";

const RunAllRowsButton = ({
  handleRunMultipleRows,
  table
}: {
  table: TMaterialTable;
  handleRunMultipleRows: (rows: TMaterialRow[]) => void;
}) => {
  const { selectedTool } = useSheetContext();
  const authorizedTier = useAuthorizedTier();
  return (
    <Button
      onClick={() =>
        handleRunMultipleRows(table.getPrePaginationRowModel().rows)
      }
      variant="contained"
      disabled={authorizedTier === FREE_PLAN_FE || !selectedTool}
    >
      Run all rows
    </Button>
  );
};

export default RunAllRowsButton;
