import { useEffect } from "react";
import useGetInitialWorkspace from "../../../workspace/hooks/useGetInitialWorkspace";
import useAddToolInputsToWorkspace from "./useAddToolInputsToWorkspace";
import { useEditorToolCardContext } from "../EditorToolCardContext";
import { useToolContext } from "../../contexts/ToolContext";

const useWaitForWorkspaceToLoad = () => {
  const { isLoading } = useGetInitialWorkspace();
  const addToolInputsToWorkspace = useAddToolInputsToWorkspace();
  const { hasLoaded, setHasLoaded, componentId } = useEditorToolCardContext();
  const { tool } = useToolContext();

  useEffect(() => {
    // we only want to load inputs after the workspace has loaded,
    // because this will overwrite any inputs that have been saved in the workspace
    // this is the case when someone updates the name of the output after
    // having saved it in a workflow already.
    if (!isLoading && !hasLoaded) {
      setTimeout(() => {
        setHasLoaded(true);
      }, 0);
      addToolInputsToWorkspace({ componentId, tool });
    }
  }, [isLoading]);
};

export default useWaitForWorkspaceToLoad;
