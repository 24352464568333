import React from "react";
import type { ToolWithinToolBlockProps } from "@sharedTypes";
import ToolWithinToolOutputs from "../../../utilities/ToolOutputsCard";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import useShouldWrap from "./../../useShouldWrap";
import OutputMapper from "../../OutputMapper";
import ToolInfo from "../../../utilities/ToolInfo";
import ToolVersionCard from "../../../utilities/ToolVersionCard";
import { ToolContextComponent } from "../../../utilities/contexts/ToolContext";
import { Card } from "@mui/material";
import VisitSiteButton from "../../../utilities/VisitSiteButton";

function ToolWithinToolBlockInner({
  data,
  id
}: Omit<ToolWithinToolBlockProps, "selected">) {
  const { tool } = data;
  const [shouldWrap, wrapperRef] = useShouldWrap();

  return (
    <ToolContextComponent tool={tool}>
      <Grid container spacing={2} className="m-v-16px" ref={wrapperRef}>
        <Grid xs={12}>
          <Card variant="elevation">
            <ToolInfo
              action={
                <VisitSiteButton
                  toolId={tool._id}
                  size="small"
                  variant="outlined"
                />
              }
            />
          </Card>
        </Grid>
        <Grid xs={shouldWrap ? 6 : 12}>
          <OutputMapper data={data} id={id} />
        </Grid>
        <Grid xs={shouldWrap ? 6 : 12}>
          <ToolWithinToolOutputs toolOutputs={tool.main.toolOutputFields} />
        </Grid>
        <Grid xs={12}>
          <ToolVersionCard tool={tool} blockId={id} inputMap={data.inputMap} />
        </Grid>
      </Grid>
    </ToolContextComponent>
  );
}

export default ToolWithinToolBlockInner;
