import { InfoOutlined } from "@mui/icons-material";
import { Paper, Tooltip, Typography } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import { getToolTipText } from "./functions/formatHelpers";
import {
  toolflowDarkBackground,
  toolflowWhiteColor
} from "../globalTheme/muiUtils/appTheme";

export const UtiltityTooltip = ({
  children,
  title,
  placement = "top",
  className = ""
}: {
  children: ReactElement;
  title: ReactNode;
  placement?: "top" | "bottom" | "left" | "right";
  className?: string;
}) => {
  if (!title) return <>{children}</>;
  return (
    <Tooltip
      arrow
      placement={placement}
      classes={{
        popper: `p-0px ${className}`,
        tooltip: `p-0px ${className}`
      }}
      title={
        <Paper className={`p-16px ${className}`}>
          {typeof title === "string" ? <Typography>{title}</Typography> : title}
        </Paper>
      }
    >
      {children}
    </Tooltip>
  );
};

export const DarkUtilityTooltip = ({
  children,
  title,
  placement = "top",
  className = ""
}: {
  children: ReactElement;
  title: ReactNode;
  placement?: "top" | "bottom" | "left" | "right";
  className?: string;
}) => {
  if (!title) return <>{children}</>;
  return (
    <Tooltip
      arrow
      placement={placement}
      classes={{
        popper: `p-0px ${className} border-radius-8px`,
        tooltip: `p-0px ${className} border-radius-8px`
      }}
      title={
        <Paper
          className={`p-h-8px p-v-4px ${className}`}
          sx={{
            backgroundColor: toolflowDarkBackground,
            borderRadius: "8px",
            border: `1px solid ${toolflowDarkBackground}`,
            color: toolflowWhiteColor
          }}
        >
          {typeof title === "string" ? (
            <Typography variant="caption" fontWeight={500}>
              {title}
            </Typography>
          ) : (
            title
          )}
        </Paper>
      }
    >
      {/* We add span so that disabled buttons dont throw console errors */}
      <span>{children}</span>
    </Tooltip>
  );
};

// ToolflowInfo with specific logic
interface ToolflowInfoProps {
  text?: string | ReactNode;
  className?: string;
  noCharLimit?: boolean;
  placement?: "top" | "bottom" | "left" | "right";
}

const ToolflowInfo = ({
  text,
  className = "",
  noCharLimit,
  placement = "top"
}: ToolflowInfoProps) => {
  if (!text) return null;

  const noCharLimitClass =
    typeof text !== "string" || noCharLimit ? "max-w-600px" : "maxWidth300px";
  const finalClassName = `${noCharLimitClass} ${className}`;

  const content =
    typeof text === "string" ? (
      <Typography>{noCharLimit ? text : getToolTipText(text)}</Typography>
    ) : (
      <>{text}</>
    );

  return (
    <UtiltityTooltip
      title={content}
      placement={placement}
      className={finalClassName}
    >
      <InfoOutlined
        fontSize="small"
        sx={{ color: (theme) => theme.palette.text.disabled }}
      />
    </UtiltityTooltip>
  );
};

export default ToolflowInfo;
