import type { ExternalToolFE, ToolAutocomplete } from "@sharedTypes";

function formatToolsForAutocomplete(
  tools: ExternalToolFE[],
  ownTools?: boolean
): ToolAutocomplete[] {
  return tools.map((ft) => {
    const favoriteType = ownTools ? "pin" : "star";
    return {
      primary: ft.name || "Tool",
      id: ft._id,
      secondary: ft.about ?? "",
      group: "Tool",
      favoriteType: ft.userFavorited ? favoriteType : undefined
    };
  });
}

export default formatToolsForAutocomplete;
