import { createTheme } from "@mui/material";
import toolflowTypography from "../../../globalTheme/muiUtils/toolflowTypography";
import palette from "../../../globalTheme/muiUtils/palette";

const marketplaceTheme = createTheme({
  palette,
  typography: toolflowTypography,
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          color: "#121729DE",
          border: "1px solid #E4DFD4",
          boxShadow: "0px 6px 8px 0px #655B470F"
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          height: "60px",
          paddingBottom: 0
        }
      }
    }
  }
});

export default marketplaceTheme;
