import { Dialog } from "@mui/material";
import React from "react";
import AssetDialogInner from "./AssetDialogInner";
import styles from "./assetDialog.module.css";
import useAssetDialogOpen from "./hooks/useAssetDialogOpen";

const AssetDialog = () => {
  const { open, setClosed } = useAssetDialogOpen();
  return (
    <Dialog
      open={open}
      onClose={setClosed}
      className="w-100-percent"
      PaperProps={{
        className: `w-100-percent oflow-y-hidden ${styles["asset-expanded-paper"]}`
      }}
    >
      <AssetDialogInner />
    </Dialog>
  );
};

export default AssetDialog;
