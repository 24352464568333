import React, { createContext } from "react";
import useToolflowExternalWebsocket from "../utilities/hooks/useToolflowExternalWebsocket";

export interface WebsocketContextType {
  sendMessage?: (message: string) => void;
  resetButton?: React.ReactNode;
  readyState?: number;
}

const WebsocketContext = createContext<WebsocketContextType | undefined>(
  undefined
);

export function WebsocketContextComponent({
  children
}: {
  children: React.ReactNode;
}) {
  // we want to have one websocket connection per application, so we allow people to access it here
  const { sendMessage, resetButton, readyState } =
    useToolflowExternalWebsocket();

  return (
    <WebsocketContext.Provider value={{ sendMessage, resetButton, readyState }}>
      {children}
    </WebsocketContext.Provider>
  );
}

export default WebsocketContext;
