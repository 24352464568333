import React, { useState } from "react";
import Workspace from "./Workspace";
import { Container, Paper, Tab, Tabs } from "@mui/material";
import useGetInitialContent from "./hooks/useGetInitialContent";
import type { ValidateType } from "@sharedTypes";
import TabPanel, { a11yProps } from "../utilities/TabPanel";
import { GridOn, Title } from "@mui/icons-material";
import SheetPage from "../features/workstation/newDesigns/components/sheetAsset/sheet/SheetPage";
import useToolflowNavigate from "../utilities/hooks/useToolflowNavigate";
import { useParams } from "react-router-dom";
import { useAuth0Store } from "../hooks/useAuth0Store";

const TAB_HEIGHT = "48px";

type TTabs = "document" | "sheet" | "chatBox";
type TTabValues = 0 | 1 | 2;

const tabDict: {
  [key in TTabs]: {
    value: TTabValues;
    navigateLocation: string;
  };
} = {
  document: {
    value: 0,
    navigateLocation: "/workspace/document"
  },
  sheet: {
    value: 1,
    navigateLocation: "/workspace/sheet"
  },
  chatBox: {
    value: 2,
    navigateLocation: "/workspace/chatBox"
  }
};

function WorkspacePage({ routeType }: { routeType?: ValidateType }) {
  const initialStringContent = useGetInitialContent();
  const { tab: paramTab } = useParams<{ tab?: TTabs }>();
  const [tabValue, setTabValue] = useState(
    paramTab ? tabDict[paramTab]?.value : 0
  );
  const navigate = useToolflowNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: TTabValues) => {
    const targetTab = Object.values(tabDict).find(
      (tab) => tab.value === newValue
    );
    if (targetTab) {
      setTabValue(newValue);
      navigate(targetTab.navigateLocation, event);
    }
  };
  const { isAuthenticated } = useAuth0Store();

  return (
    <>
      {isAuthenticated && (
        <Paper
          variant="outlined"
          className="flex align-i-center justify-space-between width100"
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            sx={{ minHeight: TAB_HEIGHT, height: TAB_HEIGHT }}
          >
            <Tab
              label="Document"
              {...a11yProps(0)}
              iconPosition="start"
              sx={{ minHeight: TAB_HEIGHT, height: TAB_HEIGHT }}
              icon={<Title fontSize="small" />}
            />
            <Tab
              label="Sheet"
              {...a11yProps(1)}
              iconPosition="start"
              sx={{ minHeight: TAB_HEIGHT, height: TAB_HEIGHT }}
              icon={<GridOn fontSize="small" />}
            />
          </Tabs>
        </Paper>
      )}

      <TabPanel
        value={tabValue}
        index={0}
        className="h-100-percent"
        boxClassName="h-100-percent"
      >
        <Container
          maxWidth="md"
          className="oflow-y-flex h-100-percent"
          id="dontTakeFocus"
        >
          <div className="p-16px h-100-percent">
            <Workspace
              initialContent={initialStringContent}
              page
              routeType={routeType}
            />
          </div>
        </Container>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <SheetPage />
      </TabPanel>
    </>
  );
}

export default WorkspacePage;
