import React, { useState } from "react";
import type {
  TToolVersion,
  PopulatedToolVersionResponseFE,
  TBlockResponse
} from "@sharedTypes";
import { DateTime, Duration } from "luxon";
import {
  Card,
  TableCell,
  Table,
  TableRow,
  Typography,
  TableHead,
  TableBody,
  TableContainer,
  Collapse,
  IconButton,
  FormHelperText
} from "@mui/material";
import DownloadButton from "./DownloadToolButton";
import FileTable from "./FileTable";
import CopyableLargeTextField from "./CopyableLargeTextField";
import {
  formatDuration,
  getToolVersionNameWithError
} from "./functions/formatHelpers";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import WebsiteOutput from "../toolBuilder/WebsiteOutput";
import { Close, DragIndicator } from "@mui/icons-material";
import ToolVersionResponseSettingsDropdown from "./dropdowns/generic/ToolVersionResponseSettingsDropdown";
import ToolResponseButton from "./ToolResponseButton";
import CopyLinkButton from "./CopyLinkButton";
import { FILE_UPLOAD } from "./Inputs/inputConstants";

function calculateDuration(
  tvr: PopulatedToolVersionResponseFE | TBlockResponse
): Duration {
  // Parse the createdAt and updatedAt values to DateTime instances
  const createdAt = DateTime.fromSeconds(tvr.createdAt);
  const updatedAt = DateTime.fromSeconds(tvr.updatedAt);

  // Calculate the duration
  return updatedAt.diff(createdAt, [
    "years",
    "months",
    "days",
    "hours",
    "minutes",
    "seconds"
  ]);
}

function getValuesByKey(
  arr: { name: string }[],
  dict: { [key: string]: $TSFixMe }
) {
  return arr.map((obj) => dict[obj?.name]);
}

function CustomFilesTable({ tvr }: { tvr: PopulatedToolVersionResponseFE }) {
  if (!tvr.toolVersionId) return null;
  const fields = tvr.toolVersionId.toolInputFields.filter(
    (field) => field.type === FILE_UPLOAD
  );
  let files = [];
  try {
    files = getValuesByKey(fields, tvr.responseDict);
  } catch {
    files = [];
  }
  const customVals = fields.map((f) => f.name);
  if (fields.length === 0) return null;
  try {
    return (
      <FileTable
        className="m-v-16px"
        customHeaderCell="File"
        customVals={customVals}
        files={files}
        noDelete
      />
    );
  } catch {
    return null;
  }
}

function DurationLine({
  title,
  duration
}: {
  title: string;
  duration: Duration;
}) {
  const durStr = formatDuration(duration);
  if (!durStr) return null;
  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell>{durStr}</TableCell>
    </TableRow>
  );
}

function CompletionTable({ tvr }: { tvr: PopulatedToolVersionResponseFE }) {
  return (
    <>
      <TableContainer className="m-v-16px">
        <Table>
          <caption>Hidden when sharing output</caption>
          <TableHead>
            <TableRow>
              <TableCell>Stage</TableCell>
              <TableCell>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <DurationLine title="Total" duration={calculateDuration(tvr)} />
            {tvr.blockResponseIds.map((blockResponse) => (
              <DurationLine
                key={blockResponse._id}
                title={blockResponse.label}
                duration={calculateDuration(blockResponse)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function ToolVersionResponseCard({
  tvr,
  noDelete,
  handleClose,
  noEditor
}: {
  tvr: PopulatedToolVersionResponseFE;
  noDelete?: boolean;
  handleClose?: () => void;
  noEditor?: boolean;
}) {
  const [inputsExpanded, setInputsExpanded] = useState(false);
  try {
    const toolName = getToolVersionNameWithError(tvr);
    return (
      <Card
        variant="outlined"
        className="p-8px m-v-16px"
        onClick={(e) => e.stopPropagation()}
        draggable="true"
        contentEditable="false"
        suppressContentEditableWarning={true}
        sx={{
          "&:hover": (theme) => {
            return {
              borderColor: theme.palette.text.primary,
              borderWidth: 1
            };
          }
        }}
      >
        <div className="flex align-i-center justify-space-between">
          <div className="flex align-i-center">
            <div className="flex align-i-center">
              <DragIndicator
                data-drag-handle
                fontSize="small"
                sx={{
                  ...(noEditor
                    ? {
                        color: (theme) => theme.palette.action.disabled
                      }
                    : { cursor: "grab" })
                }}
              />
              <Typography className="m-h-8px">{toolName}</Typography>
              {inputsExpanded ? (
                <IconButton onClick={() => setInputsExpanded(false)}>
                  <KeyboardArrowUp fontSize="small" />
                </IconButton>
              ) : (
                <IconButton onClick={() => setInputsExpanded(true)}>
                  <KeyboardArrowDown fontSize="small" />
                </IconButton>
              )}
            </div>
          </div>
          <div>
            <ToolVersionResponseSettingsDropdown
              tvr={tvr}
              noDelete={noDelete}
            />
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </div>
        {
          <Collapse in={inputsExpanded}>
            <FormHelperText>
              Version -{" "}
              {tvr.toolVersionId &&
                tvr.toolVersionId.createdAt &&
                DateTime.fromSeconds(
                  (tvr.toolVersionId && tvr.toolVersionId.createdAt) || 0
                ).toLocaleString(DateTime.DATETIME_MED)}
            </FormHelperText>
            {!noDelete && <CompletionTable tvr={tvr} />}
            <CustomFilesTable tvr={tvr} />
            {tvr.toolVersionId &&
              (tvr.toolVersionId as TToolVersion).toolInputFields.map(
                (field, idx) => {
                  if (field.type !== FILE_UPLOAD) {
                    return (
                      <CopyableLargeTextField
                        key={idx}
                        value={tvr.responseDict[field.name] as string}
                        name={field.name}
                        tvrId={tvr._id}
                      />
                    );
                  }
                }
              )}
          </Collapse>
        }
        <div className="flex align-i-center justify-space-between w-100-percent p-t-16px">
          {noEditor ? <ToolResponseButton tvrId={tvr?._id} /> : <div></div>}
          <div className="flex align-i-center">
            <CopyLinkButton id={tvr?._id} type="toolVersionResponse" />
            {tvr.toolVersionId && (
              <DownloadButton
                tvrId={tvr._id}
                toolOutput={tvr.responseDict}
                name={toolName}
                outputFields={
                  (tvr.toolVersionId as TToolVersion).toolOutputFields
                }
              />
            )}
          </div>
        </div>
        {!tvr.responseDict ? (
          <Typography>Still processing</Typography>
        ) : (
          <>
            <WebsiteOutput
              toolOutputFields={
                (tvr.toolVersionId &&
                  (tvr.toolVersionId as TToolVersion).toolOutputFields) ||
                []
              }
              response={tvr.responseDict}
              toolPercentCompleted={100}
              tvrId={tvr._id}
              noEditor={noEditor}
            />
          </>
        )}
      </Card>
    );
  } catch {
    return null;
  }
}

export default ToolVersionResponseCard;
