import React, { useState } from "react";
import { InfoOutlined } from "@mui/icons-material";
import { useSettingsDropdownContext } from "../generic/useSettingsDropownContext";
import SettingsMenuItem from "../generic/SettingsMenuItem";
import AssignCategoriesDialog from "../../toolCategorization/AssignCategoriesDialog";
import AssignCategoriesWorkflowContainer from "../../toolCategorization/AssignCategoriesWorkflowContainer";
import AssignUseCasesWorkflowContainer from "../../toolCategorization/AssignUseCasesWorkflowContainer";

function AddWorkflowCategoriesMenuItem({
  workflowId
}: Readonly<{
  workflowId: string;
}>) {
  const { handleClose } = useSettingsDropdownContext();

  const [open, setOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const close = (e: React.SyntheticEvent<Element, Event>) => {
    e.stopPropagation();
    handleClose(e);
    setOpen(false);
  };

  return (
    <>
      <SettingsMenuItem
        text={`Assign categories`}
        hotkey="Meta+I"
        icon={<InfoOutlined fontSize="small" />}
        action={handleOpen}
      />
      <AssignCategoriesDialog
        title="Assign categories"
        onClose={close}
        open={open}
      >
        <>
          <AssignCategoriesWorkflowContainer
            workflowId={workflowId}
            key={`${workflowId}-categories`}
          />
          <AssignUseCasesWorkflowContainer
            workflowId={workflowId}
            key={`${workflowId}-usecases`}
            className="m-t-8px"
          />
        </>
      </AssignCategoriesDialog>
    </>
  );
}

export default AddWorkflowCategoriesMenuItem;
