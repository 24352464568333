import type { AttributesType } from "@sharedTypes";
import { useNodeViewContext } from "../../../utilities/contexts/NodeViewContext";
import useWorkspaceInputs from "../../../features/workstation/hooks/useWorkspaceInputs";
import { useWorkspaceInputsFieldArrayContext } from "../../../features/workstation/WorkspaceInputsContext";

const useHandleCloseNode = () => {
  const { deleteNode, node } = useNodeViewContext<AttributesType>();
  const inputs = useWorkspaceInputs();
  const { componentId } = node.attrs;
  const { remove } = useWorkspaceInputsFieldArrayContext();
  const handleClose = () => {
    // we want to delete all the inputs that have been preloaded, but haven't been executed
    inputs.map((input, index) => {
      if (
        input.source === "toolOutputPlaceholder" &&
        input.sourceIdentifier === componentId
      ) {
        remove(index);
      }
    });
    deleteNode();
  };

  return handleClose;
};

export default useHandleCloseNode;
