import { useContext } from "react";
import WebsocketContext, { WebsocketContextType } from "./WebsocketContext";

export const useWebsocketContext = (): WebsocketContextType => {
  const context = useContext(WebsocketContext);

  if (context === undefined) {
    throw new Error(
      "useWebsocketContext must be used within a WebsocketContextProvider"
    );
  }

  return context;
};
