import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { authenticatedApi } from "../../../ToolflowAPI/authenticatedAPI";

export const useHandleLogout = () => {
  const { logout } = useAuth0();
  const dispatchRedux = useDispatch();

  const handleLogout = () => {
    try {
      dispatchRedux(authenticatedApi.util.resetApiState());
      window.Intercom("shutdown");
    } catch (e) {
      console.error(e); // Logging the error might be helpful for debugging
    }

    localStorage.setItem("logoutRedirect", window.location.pathname);

    logout({
      logoutParams: { returnTo: `${window.location.origin}/callback` }
    });
  };

  return handleLogout;
};
