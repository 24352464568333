import React from "react";
import useGreeting from "./hooks/useGreeting";
import { Typography } from "@mui/material";

const Greetings = () => {
  const message = useGreeting();
  return <Typography variant="h6">{message}</Typography>;
};

export default Greetings;
