import { Box } from "@mui/material";
import type { WorkflowInputBaseIdsBySourceIdentifier } from "@sharedTypes";
import React from "react";
import useTransformWorkflowInputs from "../hooks/useTransformWorkflowInputs";
import CondenseAssetListButton from "./CondenseAssetListButton";
import AssetGroup from "./assetGroup/AssetGroup";
import styles from "./expandedAssetList.module.css";

import AddAssetButton from "../../../../leftSideDrawer/assetsTab/AddAssetButton";
import { parsedClassNames } from "../../../utils/parsedClassNames";

const cx = parsedClassNames.bind(styles);

function AssetList({
  transformedWorkflowInputs
}: {
  transformedWorkflowInputs: WorkflowInputBaseIdsBySourceIdentifier;
}) {
  return (
    <div
      className={cx(
        "scrollable-content",
        styles["asset-padding"],
        styles["list-height"]
      )}
    >
      {Object.entries(transformedWorkflowInputs).map(
        ([sourceIdentifier, objectValue]) => (
          <AssetGroup key={sourceIdentifier} objectValue={objectValue} />
        )
      )}
    </div>
  );
}

function AssetListContainer() {
  const transformedWorkflowInputs = useTransformWorkflowInputs();
  return <AssetList transformedWorkflowInputs={transformedWorkflowInputs} />;
}

function ExpandedAssetList() {
  return (
    <Box
      className={cx(styles["expanded-list-outer"], "asset-toc-box-shadow")}
      sx={{
        transform: {
          xs: "translateX(calc(-100% + 50px))",
          lg: "translateX(0px)"
        }
      }}
    >
      <div
        className={cx(
          "align-i-center flex m-b-12px justify-space-between",
          styles["asset-padding"]
        )}
      >
        <AddAssetButton />
        <CondenseAssetListButton />
      </div>
      <AssetListContainer />
    </Box>
  );
}

export default ExpandedAssetList;
