import React from "react";
import {
  Divider,
  FormHelperText,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from "@mui/material";
import iconDict from "../../../../../utilities/constants/iconDict";
import type { CustomToolInputField } from "@sharedTypes";
import SelectActionForInput from "./SelectActionForInput";
import AdditionalActionSettings from "./AdditionalActionSettings";
import { CustomToolInputFieldContextComponent } from "../../../../../utilities/contexts/CustomToolInputFieldContext";

function ToolInputMapper({
  inputField,
  isLast
}: {
  inputField: CustomToolInputField;
  isLast?: boolean;
}) {
  return (
    <CustomToolInputFieldContextComponent customToolInputField={inputField}>
      <ListItem className="p-v-16px">
        <ListItemIcon>{iconDict[inputField.type]}</ListItemIcon>
        <ListItemText primary={inputField.name} />
        <ListItemSecondaryAction>
          <SelectActionForInput />
        </ListItemSecondaryAction>
      </ListItem>
      {inputField.description && (
        <FormHelperText>{inputField.description}</FormHelperText>
      )}
      <AdditionalActionSettings />
      {!isLast && <Divider />}
    </CustomToolInputFieldContextComponent>
  );
}

export default ToolInputMapper;
