import type { Workspace } from "@sharedTypes";
import type { UseFormReturn } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import { useEffect, useState } from "react";
import { DEFAULT_WORKSPACE } from "../workspaceConstants";

// when a workspace gets reset, we set the workspaceId to a null value
// in which case, we want to reset the form to the default workspace
// this is most useful when on a workspace and clicking new session
const useReset = (
  methods: UseFormReturn<Workspace, $TSAllowedAny, undefined>
) => {
  const workspaceId = useSelector(
    (state: RootState) => state.workspace.workspaceId
  );

  const [originalWorkspaceId, setWorkspaceId] = useState(workspaceId);

  useEffect(() => {
    if (originalWorkspaceId && !workspaceId) {
      methods.reset(DEFAULT_WORKSPACE);
    }
    setWorkspaceId(workspaceId);
  }, [workspaceId]);
};

export default useReset;
