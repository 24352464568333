import {
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from "@mui/material";
import type { VisibilityTypes } from "@sharedTypes";
import React from "react";
import VisibilityChip from "./VisibilityChip";
interface BaseListItemButtonProps {
  name: string;
  about?: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  icon?: React.ReactNode;
  secondaryAction?: React.ReactNode;
  avatar?: React.ReactNode;
  visibility: VisibilityTypes;
  children?: React.ReactNode; // For additional type-specific details
  disableMaxWidth?: boolean;
}

const BaseListItemButton: React.FC<BaseListItemButtonProps> = ({
  visibility,
  name,
  about,
  onClick,
  disabled = false,
  icon,
  secondaryAction,
  disableMaxWidth,
  avatar,
  children // Could be tool or workspace specific details
}) => (
  <ListItemButton onClick={onClick} disableTouchRipple disabled={disabled}>
    {icon && <ListItemIcon>{icon}</ListItemIcon>}
    <ListItemText
      primary={name}
      secondary={about}
      primaryTypographyProps={{
        variant: "subtitle2",
        noWrap: true,
        className: !disableMaxWidth ? "max-w-55-percent" : ""
      }}
      secondaryTypographyProps={{
        noWrap: true,
        className: !disableMaxWidth ? "max-w-55-percent" : ""
      }}
    />
    <ListItemSecondaryAction className="flex align-i-center">
      <VisibilityChip visibility={visibility} />
      {children}
      {avatar}
      {secondaryAction}
    </ListItemSecondaryAction>
  </ListItemButton>
);

export default BaseListItemButton;
