import { useSelector } from "react-redux";
import { useGetProfileToolsQuery } from "../../../ToolflowAPI/rtkRoutes/toolsApi";
import { useAuth0Store } from "../../../hooks/useAuth0Store";
import useGetCurrentUser from "../../../hooks/useGetCurrentUser";
import { selectAllMarketplaceEntities } from "../../../ToolflowAPI/rtkRoutes/selectors/toolsSelectors";
import { selectCurrentUserProfileTools } from "../../../ToolflowAPI/rtkRoutes/selectors/dynamicSelectors";
import type { TMarketplaceEntity } from "@sharedTypes";
import { useGetMarketplaceEntitiesQuery } from "../../../ToolflowAPI/rtkRoutes/marketplaceApi";
import convertExternalToolFEToMarketplaceEntity from "../../../features/workstation/leftSideDrawer/toolsTab/functions/convertExternalToolFEToMarketplaceEntity";
import { useMemo } from "react";

const useGetAvailableTools = () => {
  const { isAuthenticated, isLoading } = useAuth0Store();
  useGetMarketplaceEntitiesQuery(undefined, {
    skip: isLoading
  });
  const marketplaceEntities = useSelector(selectAllMarketplaceEntities);
  const user = useGetCurrentUser();
  const profileId = user?._id || "";
  // this was having issues with websockets and bson validation errors when no profileId
  useGetProfileToolsQuery(profileId, {
    skip: isLoading || !isAuthenticated || !profileId
  }); // we need to subscribe to the query to update it when things are saved
  const { tools } = useSelector(selectCurrentUserProfileTools);
  const entities = useMemo(() => {
    return tools.map((tool) => convertExternalToolFEToMarketplaceEntity(tool));
  }, [tools]);
  const uniqueTools = [
    ...marketplaceEntities.filter((entity) => entity.type === "tool"),
    ...entities
  ].reduce((acc, entity) => {
    acc.set(entity.id, entity);
    return acc;
  }, new Map<string, TMarketplaceEntity>());

  return Array.from(uniqueTools.values());
};

export default useGetAvailableTools;
