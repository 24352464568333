import { Box } from "@mui/material";
import type { WorkflowInputSheet } from "@sharedTypes";
import React from "react";
import { useInputContext } from "../../../../../../utilities/contexts/InputContext";
import AssetName from "../../asset/AssetName";
import SheetAssetIcons from "../SheetAssetIcons";

function SheetAssetHeader() {
  const { input } = useInputContext<WorkflowInputSheet>();
  return (
    <Box className="flex align-i-center w-100-percent p-24px">
      <AssetName text={input.name} />
      <SheetAssetIcons />
    </Box>
  );
}

export default SheetAssetHeader;
