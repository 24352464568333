import React from "react";
import useIsCurrentUser from "../../utilities/hooks/useIsCurrentUser";
import MoreToolInfoMenuItem from "../../toolBuilder/dropdowns/menuItems/MoreToolInfoMenuItem";
import NavigateToToolBuilderMenuItem from "../../utilities/dropdowns/menuItems/NavigateToToolBuilderMenuItem";
import CopyToolLinkMenuItem from "../../utilities/dropdowns/menuItems/CopyToolLinkMenuItem";
import DuplicateToolMenuItem from "../../toolBuilder/dropdowns/menuItems/DuplicateToolMenuItem";
import { useToolContext } from "../../utilities/contexts/ToolContext";
import DeleteEditorToolNodeMenuItem from "./menuItems/DeleteEditorToolNodeMenuItem";
import EditorToolSettingsWrapper from "./EditorToolSettingsWrapper";

function EditorToolSettingsDropdown() {
  const { tool } = useToolContext();
  const { _id: toolId, creator } = tool;
  const currentUserIsToolOwner = useIsCurrentUser(creator?._id);
  const menuItems = [
    <MoreToolInfoMenuItem tool={tool} key="moreToolInfo" />,
    currentUserIsToolOwner ? (
      <NavigateToToolBuilderMenuItem key="navigate" toolId={toolId} />
    ) : null,
    <CopyToolLinkMenuItem toolId={toolId} key="copy" />,
    <DuplicateToolMenuItem id={toolId} key="duplicate" />,
    <DeleteEditorToolNodeMenuItem key="delete" />
  ];

  return <EditorToolSettingsWrapper menuItems={menuItems} />;
}

export default EditorToolSettingsDropdown;
