import { MenuButton } from "mui-tiptap";
import React from "react";
import { useSettingsDropdownContext } from "../useSettingsDropownContext";
import { IMenuButtonProps } from "./settingsTypes";

const WrappedMenuButton = ({
  children,
  MenuButtonProps
}: {
  MenuButtonProps?: IMenuButtonProps;
  children: React.ReactNode;
}) => {
  const { handleClick, disableToolTip } = useSettingsDropdownContext();
  const tooltipLabel = disableToolTip
    ? ""
    : MenuButtonProps?.tooltipLabel || "More";
  return (
    <MenuButton onClick={handleClick} tooltipLabel={tooltipLabel}>
      {children}
    </MenuButton>
  );
};

export default WrappedMenuButton;
