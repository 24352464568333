import React from "react";
import useSendMessageOnDirty from "./hooks/useSendMessageOnDirty";

function SendMessageOnDirtyProvider({
  children
}: {
  children: React.ReactNode;
}) {
  useSendMessageOnDirty();

  return <>{children}</>;
}
export default SendMessageOnDirtyProvider;
