import type { TMarketplaceEntity } from "@sharedTypes";
import React, { FC } from "react";
import { getCategoryIconAndLabel } from "../utils/getCategoryIconAndLabel";
import { IconButton, Typography, useTheme } from "@mui/material";
import RemixWrapperIconWithTheme from "../../layout/icons/RemixWrapperIconWithTheme";

interface IMarketplaceCardSubHeaderProps {
  entity: TMarketplaceEntity;
}
const MarketplaceCardSubHeader: FC<IMarketplaceCardSubHeaderProps> = ({
  entity
}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        position: "relative",
        height: 40,
        top: 0,
        left: -8
      }}
    >
      {entity?.tag?.categories?.map((category) => {
        const categoryDict = getCategoryIconAndLabel(category);
        return (
          <React.Fragment key={categoryDict.label}>
            <IconButton className="p-r-2px">
              <RemixWrapperIconWithTheme
                Icon={categoryDict.icon}
                fill={theme.palette.primary.main}
                size={16}
              />
            </IconButton>
            <Typography variant="caption" color="primary">
              {categoryDict.label}
            </Typography>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default MarketplaceCardSubHeader;
