import React, { FC } from "react";
import type { Content } from "@tiptap/core";
import { Paper } from "@mui/material";
import ToolflowLogo from "../../../../../utilities/ToolflowLogo";
import { GenericRichContent } from "../../../../../utilities/GenericRichContent";
interface IAssistantMessageProps {
  messageContent: Content;
}

const AssistantMessage: FC<IAssistantMessageProps> = (props) => {
  const { messageContent } = props;
  return (
    <div className="flex p-h-8px">
      <div className="m-r-8px m-t-8px">
        <ToolflowLogo width={22} />
      </div>
      <Paper variant="outlined" className="w-100-percent">
        <GenericRichContent
          content={messageContent}
          className="text-align-left p-h-16px"
          rerender={true}
          key="assistant-message"
        />
      </Paper>
    </div>
  );
};

export default AssistantMessage;
