import { blockDictConstants } from "../../blocks/types/blockDictTypes";
import type { TScraperSettings } from "@sharedTypes";

export const scraperSettingsInitialState: {
  settings: TScraperSettings;
} = {
  settings: {
    urlFieldInputKey: "",
    preferredScraperResultType: "markdown"
  }
};
export const scraperInitialState = {
  type: blockDictConstants.WebScraper.type,
  label: blockDictConstants.WebScraper.label
};
