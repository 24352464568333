import React from "react";
import ExportCSVButton from "../../../../../../../utilities/ExportToCSVButton";
import { DateTime } from "luxon";
import useHandleExportRows from "./hooks/useHandleExportRows";
import { useSheetContext } from "../SheetContext";
import { TMaterialTable } from "../sheetTypes";

const ExportAllRowsButton = ({ table }: { table: TMaterialTable }) => {
  const { selectedTool } = useSheetContext();
  const handleExportRows = useHandleExportRows();
  return (
    <ExportCSVButton
      variant="icon"
      dynamicData={() =>
        handleExportRows(table.getPrePaginationRowModel().rows)
      }
      fileName={`${
        selectedTool?.name || "Tool"
      } - ${DateTime.now().toISODate()}`}
    />
  );
};

export default ExportAllRowsButton;
