import React, { useState, FC } from "react";

import type {
  TScraperSettings,
  CustomToolInputField,
  CustomToolInputFieldTypes
} from "@sharedTypes";
import useUpdateField from "../../useUpdateField";
import AddInputDialog from "../../AddInputDialog";
import useSyncCurrentAndPrevSettings from "../../hooks/useSyncCurrentAndPrevSettings";
import GetUrlFromUser from "./GetUrlFromUser";
import type { SelectChangeEvent } from "@mui/material/Select";
import SelectPreferredScraperResultType from "./SelectPreferredScraperResultType";
import { TEXTFIELD } from "../../../utilities/Inputs/inputConstants";

interface IScraperSettingsProps {
  id: string;
  settings: TScraperSettings;
}
const ScraperSettings: FC<IScraperSettingsProps> = (props) => {
  const { id, settings } = props;
  const updateField = useUpdateField(id);
  const [openInputModal, setOpenInputModal] = useState(false);
  const [initialFieldType, setInitialFieldType] =
    useState<CustomToolInputFieldTypes>();

  const handleChangeInitialFieldType = (field: CustomToolInputFieldTypes) => {
    setInitialFieldType(field);
  };
  useSyncCurrentAndPrevSettings(settings);

  const updateFromAdd = (field: CustomToolInputField) => {
    if (field.type === TEXTFIELD) {
      updateField(field.name, "settings.urlFieldInputKey");
    }
  };

  const openInput = () => {
    setOpenInputModal(true);
  };

  const updateStateValue =
    (kString: keyof TScraperSettings) => (event: SelectChangeEvent<string>) => {
      updateField(event.target.value, `settings.${kString}`);
    };

  return (
    <>
      <div className="p-h-16px">
        <GetUrlFromUser
          id={id}
          openInput={openInput}
          settings={settings}
          updateFieldKey="settings.urlFieldInputKey"
          handleChangeInitialFieldType={handleChangeInitialFieldType}
        />
        <SelectPreferredScraperResultType
          settings={settings}
          handleChange={updateStateValue("preferredScraperResultType")}
        />
        <AddInputDialog
          openModal={openInputModal}
          setOpenModal={setOpenInputModal}
          callback={updateFromAdd}
          hideTypeDropdown
          initialType={initialFieldType}
        />
      </div>
    </>
  );
};

export default ScraperSettings;
