import { createTheme, responsiveFontSizes } from "@mui/material";
import getCSSVariable from "./getCSSVariables";
import toolflowTypography from "./toolflowTypography";

export const rightSideContentBackground = "#faf8f7";
export const toolflowPrimaryColor = getCSSVariable("--primary-color");
export const toolflowErrorColor = "#d32f2f";
export const toolflowTextDisabledColor = "#12172961";
export const toolflowDarkBackground = "#616161e5";
export const toolflowWhiteColor = "white";
export const toolflowTextSecondaryColor = getCSSVariable(
  "--secondary-text-color"
);
export const toolflowTextColorOrange = "#ff5722";
export const toolflowTextColorPrimary = "#000";
export const toolflowSecondaryMain = "#d016ff";
export const toolflowAppBackground = getCSSVariable("--app-background-color");

const theme = createTheme({
  typography: toolflowTypography,
  palette: {
    mode: "light",
    primary: {
      main: toolflowPrimaryColor
    },
    error: {
      main: toolflowErrorColor
    },
    secondary: {
      main: toolflowSecondaryMain
    },
    textColor: {
      main: toolflowTextColorOrange
    },
    text: {
      primary: toolflowTextColorPrimary,
      secondary: toolflowTextSecondaryColor,
      disabled: toolflowTextDisabledColor
    }
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true // If we disable ripple update .Mui-focusVisible
      }
    },
    MuiButton: {
      defaultProps: {
        size: "small"
      },
      styleOverrides: {
        root: {
          textTransform: "inherit",
          fontWeight: 500,
          padding: "6px 16px"
        }
      },
      variants: [
        {
          props: { variant: "text" },
          style: {
            backgroundColor: "rgba(138, 45, 255, 0.04)"
          }
        }
      ]
    },
    MuiMenu: {
      defaultProps: {
        anchorOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "8px !important"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 8
        }
      }
    },
    MuiIconButton: {
      defaultProps: {
        size: "small"
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: "subtitle1" }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          caption: "p"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "inherit"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.notSelected": {
            "&.Mui-selected": {
              backgroundColor: "inherit"
              // fontWeight: "normal"
            },
            "&.Mui-selected:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.08)"
            }
          }
        }
      }
    }
  }
});

export const MAX_ROWS_LARGE_TEXTFIELD = 8;

const appTheme = responsiveFontSizes(theme);

export default appTheme;
