// toolInfoDrawerSlice.ts
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { ExternalToolFE } from "@sharedTypes";

interface IToolInfoDrawerInitialStateBase {
  tool: ExternalToolFE | null;
}

const initialState: IToolInfoDrawerInitialStateBase = {
  tool: null
};

export type TToolInfoDrawerReducerName = "toolInfoDrawer";
export const TOOL_INFO_DRAWER_REDUCER_NAME: TToolInfoDrawerReducerName =
  "toolInfoDrawer";

const toolInfoDrawerSlice = createSlice({
  name: TOOL_INFO_DRAWER_REDUCER_NAME,
  initialState,
  reducers: {
    updateToolInInfoDrawer(
      state,
      action: PayloadAction<ExternalToolFE | null>
    ) {
      state.tool = action.payload;
    }
  }
});

export const { updateToolInInfoDrawer } = toolInfoDrawerSlice.actions;

export default toolInfoDrawerSlice.reducer;
