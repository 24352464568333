import React from "react";
import { ListItem, ListItemText } from "@mui/material";
import { ADD_TOOL_TEXT } from "./toolSelectorConstants";

function AddSelectionOption({
  props
}: {
  props: React.HTMLAttributes<HTMLLIElement>;
}) {
  return (
    <ListItem
      {...props}
      dense
      disableGutters
      disablePadding
      id="toolMenuListItem"
    >
      <ListItemText primary={ADD_TOOL_TEXT} />
    </ListItem>
  );
}

export default AddSelectionOption;
