import {
  getIsOutOfCreditsError,
  getIsOutOfToolRunsError
} from "../../apiHandlerWithAuth";
import { useEditorToolCardContext } from "../EditorToolCardContext";

const useHandleThrownError = () => {
  const { setShowUpgrade } = useEditorToolCardContext();

  const handleThrownError = (e: unknown) => {
    if (getIsOutOfCreditsError(e) || getIsOutOfToolRunsError(e)) {
      setShowUpgrade(true);
    }
  };
  return handleThrownError;
};

export default useHandleThrownError;
