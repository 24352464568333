import { useSelector } from "react-redux";
import { useGetWorkspaceQuery } from "../../../ToolflowAPI/rtkRoutes/workspaceApi";
import { RootState } from "../../../stores/store";

const useGetDefaultWorkspaceFields = () => {
  const currentWorkspaceId = useSelector(
    (state: RootState) => state.workspace.workspaceId
  );
  return useGetWorkspaceQuery(currentWorkspaceId, {
    skip: !currentWorkspaceId
  });
};

export default useGetDefaultWorkspaceFields;
