import type { RowUserInputDictType } from "@sharedTypes";
import updateSheetDataWithSingleValue from "../functions/updateSheetDataWithSingleValue";
import type { MRT_Row } from "material-react-table";
import { useSheetContext } from "../../SheetContext";

const useSetRowLoading = () => {
  const { sheetDataRef, setSheetData } = useSheetContext();
  const setRowLoadingByIndex = (index: number, rowLoading: boolean) => {
    updateSheetDataWithSingleValue(
      index,
      "rowLoading",
      rowLoading,
      sheetDataRef,
      setSheetData
    );
  };

  const setRowLoading = (
    row: MRT_Row<RowUserInputDictType>,
    rowLoading: boolean
  ) => {
    setRowLoadingByIndex(row.index, rowLoading);
  };

  return setRowLoading;
};

export default useSetRowLoading;
