import React from "react";
import ChatBoxPage from "./chatbox/ChatBoxPage";
import { Container } from "@mui/material";

const ChatTab = () => {
  return (
    <Container maxWidth={"md"} disableGutters className="h-100-percent">
      <ChatBoxPage />
    </Container>
  );
};

export default ChatTab;
