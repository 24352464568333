import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

function GenerateOpenAIAPIKey({
  className = "",
  showSave
}: {
  className?: string;
  showSave?: boolean;
}) {
  return (
    <div className={className}>
      <Link
        to="https://platform.openai.com/account/api-keys"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Button className="m-r-8px">Get your API key</Button>
      </Link>
      {showSave && (
        <Link to="/settings/integrations">
          <Button>Save Key</Button>
        </Link>
      )}
    </div>
  );
}

export default GenerateOpenAIAPIKey;
