import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import { CardHeader } from "@mui/material";
import ManageSecret from "./ManageSecret";

function SecretsCard() {
  return (
    <Box mt={4}>
      <Card variant="outlined">
        <CardHeader
          title="Integrations"
          subheader="If you would prefer to use your own keys, add your API keys here"
        />
        <CardContent>
          <ManageSecret />
        </CardContent>
      </Card>
    </Box>
  );
}

export default SecretsCard;
