import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { FC } from "react";
import { TSetSelectValFunction } from "./types";
import { DEFAULT_FUNCTION, DEFAULT_RESPONSIBILITY } from "./constants";

interface IRoleInformationFormProps {
  employeeFunction?: string;
  changeSelectField: TSetSelectValFunction;
  employeeResponsibility?: string;
}
const RoleInformationForm: FC<IRoleInformationFormProps> = ({
  employeeFunction = DEFAULT_FUNCTION,
  changeSelectField,
  employeeResponsibility = DEFAULT_RESPONSIBILITY
}) => {
  return (
    <>
      <Grid xs={12} md={6} item>
        <FormControl
          margin="normal"
          size="medium"
          className="m-r-8px"
          fullWidth
        >
          <InputLabel>{"Function"}</InputLabel>
          <Select
            name={"Function"}
            label={"Function"}
            id={"Function"}
            value={employeeFunction}
            onChange={changeSelectField("employeeFunction")}
          >
            {[
              "Sales",
              "Marketing",
              "Customer Success/Account Management",
              "Operations",
              "HR",
              "Finance",
              "Leadership / Exec",
              "Engineering",
              "Product Management"
            ].map((o, idx) => (
              <MenuItem value={o} key={idx}>
                {o}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={12} md={6} item>
        <FormControl
          margin="normal"
          size="medium"
          className="m-r-8px"
          fullWidth
        >
          <InputLabel>{"Responsibility"}</InputLabel>
          <Select
            name={"Responsibility"}
            label={"Responsibility"}
            id={"Responsibility"}
            value={employeeResponsibility}
            onChange={changeSelectField("employeeResponsibility")}
          >
            {["Individual Contributor", "Manager", "VP", "Exec/C-Suite"].map(
              (o, idx) => (
                <MenuItem value={o} key={idx}>
                  {o}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

export default RoleInformationForm;
