import type { TEnrichedPreviousInputField } from "@sharedTypes";
import useGetPreviousToolsAndInputs from "./useGetPreviousToolsAndInputs";

const useGetPreviousInputFields = () => {
  const enrichedPreviousSteps = useGetPreviousToolsAndInputs();
  return enrichedPreviousSteps.reduce((acc, previousStep) => {
    if (previousStep.tool) {
      return acc.concat({
        step: previousStep.step,
        inputs: previousStep.tool.main.toolInputFields,
        outputs: previousStep.tool.main.toolOutputFields
      });
    } else if (previousStep.inputs) {
      return acc.concat({
        step: previousStep.step,
        inputs: previousStep.inputs,
        outputs: []
      });
    } else {
      return acc;
    }
  }, [] as TEnrichedPreviousInputField[]);
};

export default useGetPreviousInputFields;
