import { useCallback } from "react";
import { Action } from "../toolBuilderReducer";
import type { TBlock } from "@sharedTypes";

const useHandleConfirm = ({
  dispatch,
  onNodesDelete,
  blocksToDelete,
  setIsConfirmationDialogOpen,
  setBlocksToDelete,
  handleNodeDelete
}: {
  dispatch: React.Dispatch<Action>;
  onNodesDelete: (deleted: TBlock[]) => void;
  blocksToDelete: TBlock[];
  setIsConfirmationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setBlocksToDelete: React.Dispatch<React.SetStateAction<TBlock[]>>;
  handleNodeDelete: (id: string) => void;
}) => {
  return useCallback(() => {
    dispatch({
      type: "UPDATE_OPEN_NODE",
      nodeId: ""
    });
    onNodesDelete(blocksToDelete); // manage edges before deleting blocks
    blocksToDelete.forEach((block) => handleNodeDelete(block.id));
    setIsConfirmationDialogOpen(false);
    setBlocksToDelete([]);
  }, [onNodesDelete, blocksToDelete]);
};

export default useHandleConfirm;
