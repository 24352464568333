import React, { FC } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import type { TSecretType } from "@sharedTypes";
import DeepgramPricingLink from "./DeepgramPricingLink";
import { Link } from "react-router-dom";
interface IAddNewDeepgramKeysInstructionsProps {
  handleChange: (value: string) => void;
  handleSaveSecret: (secretType: TSecretType) => void;
  val: string;
  isDisabled: boolean;
  loading: boolean;
}

const AddNewDeepgramKeysInstructions: FC<
  IAddNewDeepgramKeysInstructionsProps
> = (props) => {
  const { isDisabled, loading, handleChange, handleSaveSecret, val } = props;
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>
          Step by step instructions to connect with Deepgram
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="subtitle1" className="m-t-16px">
          1. Create an account or sign in if you have one
          <Link
            to="https://deepgram.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className={`m-l-8px`}>Create account</Button>
          </Link>
        </Typography>
        <Typography variant="caption" className="m-b-8px">
          You will be provided with free credits to try out the API. Your inital
          plan will be pay as you go.
        </Typography>
        <Typography variant="subtitle1">2. Generate API Key</Typography>
        <Typography variant="caption" className="m-b-8px">
          API keys allow you to connect with other applications on the internet.
          Either create a new API key or use an existing one to connect your
          Deepgram account with Toolflow.
        </Typography>
        <DeepgramPricingLink />
        <div className="flex align-i-center m-t-16px">
          <FormControl className="m-r-16px">
            <InputLabel id="prompt-apiKey-label">Integration</InputLabel>
            <Select
              labelId="apiKey-label"
              id="apiKey"
              value="deepgram"
              label="Integration"
              size="small"
              notched
              disabled
            >
              <MenuItem value="deepgram">Deepgram</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="API Key"
            value={val}
            size="small"
            onChange={(e) => handleChange(e.target.value)}
            className="m-r-8px"
            disabled={isDisabled}
          />
          <LoadingButton
            size="small"
            loading={loading}
            onClick={() => handleSaveSecret("deepgram")}
          >
            Save
          </LoadingButton>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AddNewDeepgramKeysInstructions;
