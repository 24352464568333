import { type UseFormReset } from "react-hook-form";
import useDefaultWorkflowFormValues from "./useDefaultWorkflowFormValues";
import type { TWorkflowVersionDataToSave } from "@sharedTypes";

const useWorkflowResetDefaultNeedsReset = (
  reset: UseFormReset<TWorkflowVersionDataToSave>
) => {
  const defaultValues = useDefaultWorkflowFormValues();
  const workflowResetDefault = () => {
    reset(defaultValues);
  };

  return workflowResetDefault;
};

export default useWorkflowResetDefaultNeedsReset;
