import { ContentCopy } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import { useDuplicateObject } from "./hooks/useDuplicateObject";
import { TOOL } from "./constants/constants";

function DuplicateToolButton({
  id,
  className = ""
}: {
  id: string;
  className?: string;
}) {
  const { duplicateObject } = useDuplicateObject();

  const duplicate = () => {
    duplicateObject(id, TOOL);
  };
  return (
    <IconButton size="small" onClick={duplicate} className={className}>
      <ContentCopy fontSize="small" />
    </IconButton>
  );
}

export default DuplicateToolButton;
