import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import type { TWorkspaceDataToSave, Workspace } from "@sharedTypes";

import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import useDefaultWorkspaceFormValues from "./hooks/useDefaultWorkspaceFormValues";
import {
  CHAT_TAB_BASE,
  DEFAULT_WORKSPACE,
  INPUT_SOURCES,
  INPUT_TYPES,
  TAB_ARRAY,
  TOOL_TAB_BASE,
  WORKFLOW_USE_BASE,
  WORKSPACE_ABOUT_FIELD_LABEL,
  WORKSPACE_ID_FIELD_LABEL,
  WORKSPACE_INPUTS_FIELD_LABEL,
  WORKSPACE_LEFT_TAB,
  WORKSPACE_NAME_FIELD_LABEL,
  WORKSPACE_VISIBILITY_FIELD_LABEL,
  WORKSPACE_VISIBLE_ASSET_ID
} from "./workspaceConstants";
import { WorkspaceInputsFormProvider } from "./WorkspaceInputsContext";
import useSetDefaultValues from "./hooks/useSetDefaultValues";
import getDefaultValues from "./functions/getDefaultValues";
import useGetDefaultWorkspaceFields from "./hooks/useGetDefaultWorkspaceFields";
import useReset from "./hooks/useReset";

const WorkflowInputZodSchema = z.object({
  id: z.string(),
  name: z.string(),
  source: z.enum(INPUT_SOURCES),
  sourceIdentifier: z.string().optional(),
  loading: z.boolean().optional(),
  valueType: z.enum(INPUT_TYPES).optional(),
  data: z
    .object({
      toolId: z.string().optional(),
      tvrId: z.string().optional(),
      creatorId: z.string().optional(),
      userInput: z.record(z.string(), z.any()).optional(),
      outputId: z.string().optional()
    })
    .optional()
});

const schema = z.object({
  [WORKSPACE_VISIBLE_ASSET_ID]: z.string(),
  [WORKSPACE_INPUTS_FIELD_LABEL]: z.array(WorkflowInputZodSchema),
  [WORKSPACE_LEFT_TAB]: z.enum(TAB_ARRAY),
  [WORKSPACE_ID_FIELD_LABEL]: z.string(),
  [WORKSPACE_NAME_FIELD_LABEL]: z
    .string()
    .min(1, { message: "Workspace name is required" }),
  [WORKSPACE_ABOUT_FIELD_LABEL]: z.string().min(0),
  [WORKSPACE_VISIBILITY_FIELD_LABEL]: z.enum([
    "public",
    "private",
    "restricted"
  ]),
  [CHAT_TAB_BASE]: z.object({ currentThreadId: z.string() }),
  [WORKFLOW_USE_BASE]: z.object({
    workflowId: z.string(),
    componentId: z.string(),
    activeStep: z.number(),
    workflowProgress: z.record(
      z.object({
        inputs: z.record(z.string(), z.any()),
        outputs: z.record(z.string(), z.string()),
        componentId: z.string()
      })
    )
  }),
  [TOOL_TAB_BASE]: z.object({
    toolId: z.string(),
    componentId: z.string(),
    userInput: z.record(z.string(), z.any())
  })
});

const WorkspaceInner = ({
  children,
  workspace
}: {
  children: React.ReactNode;
  workspace: TWorkspaceDataToSave;
}) => {
  const { parsedObject, type } = useDefaultWorkspaceFormValues();

  const methods = useForm<Workspace>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(workspace),
    mode: "onChange"
  });

  useSetDefaultValues({ type, methods, parsedObject });
  useReset(methods);

  return (
    <FormProvider {...methods}>
      <WorkspaceInputsFormProvider>{children}</WorkspaceInputsFormProvider>
    </FormProvider>
  );
};

const WorkspaceFormProvider = ({ children }: { children: React.ReactNode }) => {
  const { data, isLoading } = useGetDefaultWorkspaceFields();

  if (isLoading) {
    return null;
  }
  return (
    <WorkspaceInner workspace={data?.workspace || DEFAULT_WORKSPACE}>
      {children}
    </WorkspaceInner>
  );
};

export default WorkspaceFormProvider;
