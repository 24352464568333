import type { TWorkflowBuilderForm } from "@sharedTypes";
import { useSaveWorkflowMutation } from "../../../../../ToolflowAPI/rtkRoutes/workflowApi";
import useHandleWorkflowSaveSuccess from "./useHandleWorkflowSaveSuccess";
import useWorkflowQueriesFetching from "./useWorkflowQueriesFetching";

import { useState } from "react";
import { useParams } from "react-router-dom";
import { type SubmitHandler, useFormContext } from "react-hook-form";
import useNavigateToNewWorkflow from "./useNavigateToNewWorkflow";
import useShowValidationErrors from "./useShowValidationErrors";

const useGetWorkflowId = () => {
  const { workflowId: workflowIdParams } = useParams();
  const [workflowId, setWorkflowId] = useState<string | undefined>(
    workflowIdParams
  );
  return { workflowId, setWorkflowId };
};
const useSaveAndClose = () => {
  const [saveWorkflow, { isLoading, isSuccess }] = useSaveWorkflowMutation();

  const workflowQueriesLoading = useWorkflowQueriesFetching();
  const loading = isLoading || workflowQueriesLoading;
  const { workflowId, setWorkflowId } = useGetWorkflowId();
  const hasSaved = !loading && isSuccess;

  useNavigateToNewWorkflow(hasSaved, workflowId);
  useHandleWorkflowSaveSuccess(hasSaved);
  useShowValidationErrors();

  const { handleSubmit } = useFormContext<TWorkflowBuilderForm>();

  const onSubmit: SubmitHandler<TWorkflowBuilderForm> = async (
    workflowBuilderForm
  ) => {
    try {
      const { visibility, tag, ...rest } = workflowBuilderForm;
      const workflowVersionDataToSave = rest;
      const workflowDataToSave = { visibility, tag };
      await saveWorkflow({
        body: { workflowVersionDataToSave, workflowDataToSave },
        workflowId
      })
        .unwrap()
        .then((resp) => {
          if (!workflowId) {
            setWorkflowId(resp.workflowVersion.workflowId);
          }
        });
    } catch (error) {
      console.error("Failed to save workflow", error);
    }
  };

  const saveAndClose = () => {
    handleSubmit(onSubmit)();
  };

  return {
    saveAndClose,
    loading
  };
};

export default useSaveAndClose;
