import React, { useState, useEffect } from "react";
import ConstantBlockInner from "./ConstantBlockInner";
import BlockWrapper from "../BlockWrapper";
import { isEqual } from "lodash";
import type { ConstantBlockProps } from "@sharedTypes";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";

const ConstantBlock: React.FC<ConstantBlockProps> = ({
  data,
  selected,
  id
}) => {
  const { state } = useToolbuilderContext();
  const { blocks: blocks } = state.currentState;
  const [prevBlocks, setPrevBlocks] = useState(blocks);
  useEffect(() => {
    // Perform deep equality check
    if (!isEqual(blocks, prevBlocks)) {
      // Nodes have changed, trigger re-render
      // Update the previous blocks value
      setPrevBlocks(blocks);
    }
  }, [blocks]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BlockWrapper id={id} data={data} selected={selected}>
      <ConstantBlockInner id={id} data={data} />
    </BlockWrapper>
  );
};

export default ConstantBlock;
