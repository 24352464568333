import { Paper, Tab, Tabs, type TabsActions } from "@mui/material";
import type { TTabValues } from "@sharedTypes";
import React from "react";
import TabPanel, { a11yProps } from "../../../utilities/TabPanel";

function WorkstationDrawer({
  tabValue,
  disableTabChange = false,
  handleChangeTabs,
  tabsRef,
  objValues
}: {
  tabValue: TTabValues;
  disableTabChange?: boolean;
  handleChangeTabs: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: TTabValues
  ) => void;
  tabsRef: React.RefObject<TabsActions>;
  objValues: {
    value: TTabValues;
    component: () => React.ReactNode;
    label: string;
  }[];
}) {
  return (
    <Paper variant="workspaceWrapper" className="w-100-percent h-100-percent">
      <Tabs
        value={tabValue}
        onChange={handleChangeTabs}
        action={tabsRef}
        variant="fullWidth"
      >
        {objValues.map((tab) => {
          return (
            <Tab
              component="div"
              key={tab.value}
              label={tab.label}
              {...a11yProps(tab.value)}
              disabled={disableTabChange}
            />
          );
        })}
      </Tabs>
      {objValues.map((tab) => (
        <TabPanel
          value={tabValue}
          key={tab.value}
          index={tab.value}
          className="w-100-percent h-100-percent scrollable-content oflow-x-hidden"
          boxClassName="h-100-percent"
          style={{ height: "calc(100% - 48px)" }}
        >
          {tab.component()}
        </TabPanel>
      ))}
    </Paper>
  );
}

export default WorkstationDrawer;
