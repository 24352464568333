import type {
  ToolVersionDict,
  CustomToolOutputField,
  AvailableFieldsType,
  ToolReducerState,
  ToolOutputOption,
  UserInputDictType,
  TToolVersion,
  LogicItem,
  MapperObject,
  CustomToolInputField,
  CustomToolInputFieldTypes,
  VisibilityTypes,
  SanitizedUserType,
  TBlock,
  AvailableFieldsValue,
  IntegrationPrice,
  ExternalToolFE,
  TCategory,
  TUseCase,
  TTagObject,
  PopulatedToolVersionResponseFE
} from "@sharedTypes";
import { v4 as uuidv4 } from "uuid";
import type { Viewport } from "reactflow";
import { createNewToolState, defaultToolName } from "./generateToolHelpers";
import { getUniqueOutputNames } from "../helpers/uniqueNameHelpers";
import { generateInitialLogicFromToolInput } from "../helpers/logicBlockHelpers";
import { calculateToolCost } from "../blocks/useBlockCost";
import {
  CHECKBOX,
  FILE_UPLOAD,
  LARGE_TEXTFIELD,
  SELECT,
  TEXTFIELD
} from "../../utilities/Inputs/inputConstants";
import {
  COPYABLE_IMAGE,
  COPYABLE_LARGE_TEXTFIELD
} from "../../utilities/Inputs/outputConstants";
import { updateInputType } from "../../utilities/Inputs/constants";
import { LabelComparison } from "../blocks/toolWithinTool/functions/separateOutputChanges";

export const createToolReducerInitialState = (): ToolReducerState => {
  const initialCurrentState = {
    toolName: defaultToolName,
    toolAbout: "",
    toolOutputFields: [],
    toolInputFields: [],
    description: "",
    blocks: [],
    edges: [],
    availableFields: {},
    visibility: "public" as VisibilityTypes,
    tag: { categories: [], useCases: [] },
    toolOutputOptions: { inputs: [], blocks: [] },
    estimatedCreditCost: 0
  };
  return {
    toolLoading: false,
    reducerId: uuidv4(),
    creator: null,
    toolVersions: {},
    main: null,
    toolId: "",
    toolVersionId: "",
    toolOutput: null,
    toolPercentCompleted: null,
    openNodeId: "",
    userInput: {},
    toolVersionResponse: null,
    tempViewport: null,
    currentState: initialCurrentState,
    originalState: initialCurrentState,
    showInfoDrawer: false,
    showTagsDialog: false
  };
};

interface UpdateToolVersionId {
  type: "UPDATE_TOOL_VERSION_ID";
  toolVersionId: string;
}
interface SetShowInfoDrawer {
  type: "SET_SHOW_INFO_DRAWER";
  showInfoDrawer: boolean;
}
interface SetToggleTagsDialog {
  type: "SET_TOGGLE_TAGS_DIALOG";
  showTagsDialog: boolean;
}

interface UpdateUserInput {
  type: "UPDATE_USER_INPUT";
  userInput: UserInputDictType;
}

interface UpdateDescription {
  type: "UPDATE_DESCRIPTION";
  description: string;
}

interface NewTool {
  type: "NEW_TOOL";
}

interface DeleteLogicOption {
  type: "DELETE_LOGIC_OPTION";
  input: string;
  parameterValue: string;
}

interface ReplaceLogicOption {
  type: "REPLACE_LOGIC_OPTION";
  input: string;
  newValue: string;
  parameterValue: string;
}

interface ResetTool {
  type: "RESET_TOOL";
}

interface UpdateOutputsWithNewToolVersion {
  type: "UPDATE_OUTPUTS_WITH_NEW_TOOL_VERSION";
  labelComparison: LabelComparison[];
}

interface UpdateToolOutput {
  type: "UPDATE_TOOL_OUTPUT";
  response: UserInputDictType | null;
  percentCompleted: number;
}

interface DeleteToolOutputField {
  type: "DELETE_TOOL_OUTPUT_FIELD";
  index: number;
}

interface UpdateOpenNode {
  type: "UPDATE_OPEN_NODE";
  nodeId: string;
}

interface SaveVisibility {
  type: "SAVE_VISIBILITY";
  visibility: VisibilityTypes;
}
interface SaveToolCategories {
  type: "SAVE_TOOL_CATEGORIES";
  categories: TCategory[];
}
interface SaveToolUseCases {
  type: "SAVE_TOOL_USE_CASES";
  useCases: TUseCase[];
}

interface ExternalReceiveTool {
  type: "EXTERNAL_RECEIVE_TOOL";
  toolInputFields: CustomToolInputField[];
  toolOutputFields: CustomToolOutputField[];
  creator: SanitizedUserType;
  description: string;
  toolAbout: string;
  toolName: string;
  toolId: string;
}

interface UpdateToolOutputFieldName {
  type: "UPDATE_TOOL_OUTPUT_FIELD_NAME";
  index: number;
  toolOutputField: ToolOutputOption;
}

interface UpdateToolInputFieldType {
  type: "UPDATE_TOOL_INPUT_FIELD_TYPE";
  index: number;
  value: CustomToolInputFieldTypes;
}

interface UpdateToolName {
  type: "UPDATE_TOOL_NAME";
  toolName: string;
}

interface UpdateToolVersionResponse {
  type: "UPDATE_TOOL_VERSION_RESPONSE";
  toolVersionResponse: PopulatedToolVersionResponseFE;
}

interface UpdateToolAbout {
  type: "UPDATE_TOOL_ABOUT";
  toolAbout: string;
}

interface SetTempViewport {
  type: "SET_TEMP_VIEWPORT";
  viewport: Viewport | null;
}

interface ReceiveToolVersions {
  type: "RECEIVE_TOOL_VERSIONS";
  toolVersions: ToolVersionDict;
  toolAbout: string;
  main: TToolVersion;
  creator: SanitizedUserType;
  toolName: string;
  toolId: string;
  tempViewport: Viewport | null;
  visibility?: VisibilityTypes;
  openNodeLabel?: string;
  tag: TTagObject;
}

interface UpdateMain {
  type: "UPDATE_MAIN";
  main: TToolVersion;
}

interface SetToolInputFields {
  type: "SET_TOOL_INPUT_FIELDS";
  toolInputFields: CustomToolInputField[];
}
interface DeleteToolInputFieldsByIds {
  type: "DELETE_TOOL_INPUT_FIELDS_BY_ID";
  toolInputFieldsIds: string[];
}

interface UpdateToolInputFieldDescription {
  type: "UPDATE_TOOL_INPUT_FIELD_DESCRIPTION";
  description: string;
  index: number;
}

interface SetToolOutputFields {
  type: "SET_TOOL_OUTPUT_FIELDS";
  toolOutputFields: CustomToolOutputField[];
}

interface SetToolLoadingTrue {
  type: "SET_TOOL_LOADING_TRUE";
}
interface SetToolLoadingFalse {
  type: "SET_TOOL_LOADING_FALSE";
}

interface SetStateAction {
  type: "SET_STATE";
  key: "blocks" | "edges";
  value: $TSFixMe;
  changedNode?: TBlock | boolean;
  payload?: {
    integrationPrices: IntegrationPrice[];
  };
}

interface SetAvailableFields {
  type: "SET_AVAILABLE_FIELDS";
  blockId: string;
  fields: AvailableFieldsValue;
}

interface DeleteAvailableFields {
  type: "DELETE_AVAILABLE_FIELDS";
  id: string;
}

interface UpdateOutputLabelInAvailableFields {
  type: "UPDATE_OUTPUT_LABEL_IN_AVAILABLE_FIELDS";
  oldLabel: string;
  newLabel: string;
  toolWithinTool?: ExternalToolFE;
}

interface UpdateBlockInputLabel {
  type: "UPDATE_BLOCK_INPUT_LABEL";
  oldLabel: string;
  newLabel: string;
}

interface UpdateInputFieldName {
  type: "UPDATE_INPUT_FIELD_NAME";
  index: number;
  newName?: string;
}
interface ResetOpenNode {
  type: "RESET_OPEN_NODE";
}
interface DeleteInputField {
  type: "DELETE_INPUT_FIELD";
  index: number;
}
interface ResetToolOutput {
  type: "RESET_TOOL_OUTPUT";
}

export type Action =
  | SetToolLoadingTrue
  | UpdateOutputsWithNewToolVersion
  | UpdateBlockInputLabel
  | ResetToolOutput
  | DeleteInputField
  | SetToolLoadingFalse
  | UpdateToolName
  | UpdateToolAbout
  | ReceiveToolVersions
  | UpdateMain
  | NewTool
  | SetShowInfoDrawer
  | SaveVisibility
  | UpdateUserInput
  | UpdateOpenNode
  | SetToolInputFields
  | UpdateToolInputFieldType
  | UpdateToolVersionId
  | ExternalReceiveTool
  | DeleteToolOutputField
  | SetToolOutputFields
  | UpdateToolOutputFieldName
  | SetStateAction
  | UpdateOutputLabelInAvailableFields
  | UpdateInputFieldName
  | SetAvailableFields
  | ResetTool
  | ResetOpenNode
  | UpdateToolOutput
  | DeleteAvailableFields
  | UpdateToolVersionResponse
  | ReplaceLogicOption
  | UpdateToolInputFieldDescription
  | DeleteLogicOption
  | SetTempViewport
  | UpdateDescription
  | SaveToolCategories
  | SaveToolUseCases
  | SetToggleTagsDialog
  | DeleteToolInputFieldsByIds;

function returnNewInputMap(
  inputMap: MapperObject,
  oldName: string,
  newName: string
) {
  if (oldName === "") return inputMap;
  const newInputMap = { ...inputMap };
  Object.entries(newInputMap).map(([k, v]) => {
    if (v === oldName) {
      newInputMap[k] = newName;
    }
  });
  return newInputMap;
}

function updateBlockLabel(nodes: TBlock[], oldLabel: string, newLabel: string) {
  return nodes.map((node) => {
    return {
      ...node,
      data: {
        ...node.data,
        label: node.data.label === oldLabel ? newLabel : node.data.label
      }
    };
  });
}

const updateStringWithNewLabel = ({
  stringToUpdate,
  deleteBrackets,
  deletedToolInputFieldName,
  newName = ""
}: {
  stringToUpdate: string;
  deleteBrackets?: boolean;
  deletedToolInputFieldName: string;
  newName?: string;
}) => {
  let updatedPrompt = stringToUpdate;
  if (deleteBrackets) {
    updatedPrompt = updatedPrompt.replaceAll(
      `{{${deletedToolInputFieldName}}}`,
      ""
    );
  } else {
    updatedPrompt = updatedPrompt.replaceAll(
      `{{${deletedToolInputFieldName}}}`,
      `{{${newName}}}`
    );
  }
  return updatedPrompt;
};

// To Update -> if someone writes a new ToolInputField,
// that is the same name as an old ToolInputField,
// then updates the new ToolInputField to something else
// it will rewrite the old ToolInputField with the new tool input
function updatePrompts(
  nodes: TBlock[],
  deletedToolInputFieldName: string,
  newName = "",
  deleteBrackets = false
): TBlock[] {
  return nodes.map((node) => {
    if (node.data && typeof node.data.prompt === "string") {
      const updatedPrompt = updateStringWithNewLabel({
        deletedToolInputFieldName,
        newName,
        deleteBrackets,
        stringToUpdate: node.data.prompt
      });
      const newData = { ...node.data };
      // we don't want to have empty strings update the mapped files

      return {
        ...node,
        data: {
          ...newData,
          prompt: updatedPrompt
        }
      };
    } else if (node.type === "constantBlockNode") {
      const updatedConstant = updateStringWithNewLabel({
        deletedToolInputFieldName,
        newName,
        deleteBrackets,
        stringToUpdate: node.data.constant
      });
      const newData = { ...node.data };
      // we don't want to have empty strings update the mapped files

      return {
        ...node,
        data: {
          ...newData,
          constant: updatedConstant
        }
      };
    } else if (node.type === "scraperBlockNode") {
      const newData = { ...node.data };
      if (newData.settings.urlFieldInputKey === deletedToolInputFieldName) {
        newData.settings.urlFieldInputKey = newName;
      }
      return { ...node, data: newData };
    } else if (
      node.type === "deepgramBlockNode" &&
      deletedToolInputFieldName !== ""
    ) {
      const newData = { ...node.data };
      if (newData.settings.file === deletedToolInputFieldName) {
        newData.settings.file = newName;
      } else if (
        newData.settings.userKeywordsFieldKey === deletedToolInputFieldName
      ) {
        newData.settings.userKeywordsFieldKey = newName;
      } else if (
        newData.settings.userWordsToReplaceFieldKey ===
        deletedToolInputFieldName
      ) {
        newData.settings.userWordsToReplaceFieldKey = newName;
      }
      return { ...node, data: newData };
    } else if (node.type === "logicBlockNode") {
      if (deleteBrackets) {
        return {
          ...node,
          data: {
            ...node.data,
            logicArray: node.data.logicArray.filter(
              (logic: LogicItem) => logic.input !== deletedToolInputFieldName
            )
          }
        };
      } else {
        return {
          ...node,
          data: {
            ...node.data,
            logicArray: node.data.logicArray.map((logic: LogicItem) =>
              logic.input === deletedToolInputFieldName
                ? { ...logic, input: newName }
                : logic
            )
          }
        };
      }
    } else if (node.type === "toolWithinToolBlockNode") {
      const newInputMap = returnNewInputMap(
        node.data.inputMap,
        deletedToolInputFieldName,
        newName
      );
      return {
        ...node,
        data: {
          ...node.data,
          inputMap: newInputMap
        }
      };
    } else {
      return node;
    }
  });
}

function updateAllLabelsInAvailableFields(
  availableFields: AvailableFieldsType,
  oldName: string,
  newName: string
) {
  for (const key in availableFields) {
    if (availableFields.hasOwnProperty(key)) {
      const arr = availableFields[key];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === oldName) {
          arr[i] = newName;
        }
      }
    }
  }
  return availableFields;
}

const getToolVersion = (
  toolId: string,
  toolVersions: ToolVersionDict,
  toolName: string,
  toolAbout: string,
  tags: TTagObject,
  visibility = "public" as VisibilityTypes
) => {
  const toolInputFields = toolVersions[toolId]?.toolInputFields || [];
  const nodes = toolVersions[toolId]?.blocks;
  const uniqueNames = getUniqueOutputNames(toolInputFields, nodes);
  return {
    toolName,
    toolAbout,
    visibility,
    tags,
    blocks: toolVersions[toolId]?.blocks,
    edges: toolVersions[toolId]?.edges,
    availableFields: toolVersions[toolId]?.availableFields,
    description: toolVersions[toolId]?.description,
    toolOutputOptions: uniqueNames,
    toolInputFields: toolVersions[toolId]?.toolInputFields,
    toolOutputFields: toolVersions[toolId]?.toolOutputFields,
    estimatedCreditCost: toolVersions[toolId]?.estimatedCreditCost
  };
};

function compareArrays(
  oldArray: ToolOutputOption[],
  newArray: ToolOutputOption[]
): { [key: string]: "delete" | "add" } {
  const changes: { [key: string]: "delete" | "add" } = {};

  const oldSet = new Set(oldArray.map((item) => item.value));
  const newSet = new Set(newArray.map((item) => item.value));

  oldArray.forEach((element) => {
    if (!newSet.has(element.value)) {
      changes[element.value] = "delete";
    }
  });

  newArray.forEach((element) => {
    if (!oldSet.has(element.value)) {
      changes[element.value] = "add";
    }
  });

  return changes;
}

function toolBuilderReducer(
  state: ToolReducerState,
  action: Action
): ToolReducerState {
  const reducerId = state.reducerId || uuidv4();
  let newState: ToolReducerState;
  if (process.env.REACT_APP_ENVIRONMENT === "development") {
    console.log(action);
  }
  switch (action.type) {
    case "SET_STATE":
      if (typeof action.value === "function") {
        const updateFunction = action.value as (
          currentState: $TSFixMe
        ) => $TSFixMe;
        newState = {
          ...state,
          currentState: {
            ...state.currentState,
            [action.key]: updateFunction(state.currentState[action.key])
          }
        };
      } else {
        newState = {
          ...state,
          currentState: {
            ...state.currentState,
            [action.key]: action.value
          }
        };
      }
      if (action.key === "blocks") {
        const outputNames = getUniqueOutputNames(
          newState.currentState.toolInputFields,
          newState.currentState.blocks
        );

        // we send a changedNode to setNodes when adding a node
        // so that we can determine what type of tooloutputfield
        // we should add

        // for deletedNodes, we need to also send boolean changedNode
        // because otherwise if you update a label, there is an
        // add / delete on compareArray which messes up the new name change

        // we handle the updateLabel change in a different action
        if (!!action.changedNode) {
          const { blocks } = outputNames;

          const comparedArray = compareArrays(
            state.currentState.toolOutputOptions.blocks,
            blocks
          );
          let newToolOutputFields = state.currentState.toolOutputFields;
          Object.entries(comparedArray).map(([k, v]) => {
            if (v === "delete") {
              newToolOutputFields = newToolOutputFields.filter(
                (f) => f.name !== k
              );
            } else if (
              typeof action.changedNode !== "boolean" &&
              action.changedNode
            ) {
              if (action.changedNode.type === "toolWithinToolBlockNode") {
                const label = k.replace(
                  `${action.changedNode.data.label} - `,
                  ""
                );
                const matchedField =
                  action.changedNode.data.tool.main.toolOutputFields.filter(
                    (field: CustomToolOutputField) => field.name === label
                  )[0];

                const fieldType = matchedField ? matchedField.type : undefined;

                if (k && fieldType) {
                  newToolOutputFields = newToolOutputFields.concat([
                    {
                      name: k,
                      id: uuidv4(),
                      type: fieldType
                    }
                  ]);
                }
              } else {
                newToolOutputFields = newToolOutputFields.concat([
                  {
                    name: action.changedNode.data.label,
                    id: uuidv4(),
                    type:
                      action.changedNode.data.type === "Dall-E2"
                        ? COPYABLE_IMAGE
                        : COPYABLE_LARGE_TEXTFIELD
                  }
                ]);
              }
            }
          });
          newState.currentState.toolOutputFields = newToolOutputFields;
        }
        const { cost } = calculateToolCost(
          newState.currentState.blocks,
          action.payload
        );
        newState.currentState.estimatedCreditCost = cost;

        newState.currentState.toolOutputOptions = outputNames;
      }
      break;

    case "UPDATE_OUTPUTS_WITH_NEW_TOOL_VERSION":
      const { labelComparison } = action;
      const newUpdatedBlocks = labelComparison.reduce(
        (acc, { oldLabel, newLabel }) => {
          return updatePrompts(acc, oldLabel, newLabel, !newLabel);
        },
        state.currentState.blocks
      );

      const newUpdatedAvailableFields = labelComparison.reduce(
        (acc, { oldLabel, newLabel }) => {
          return updateAllLabelsInAvailableFields(acc, oldLabel, newLabel);
        },
        state.currentState.availableFields
      );

      function updateToolOutputFields(
        toolOutputFields: CustomToolOutputField[],
        labelComparisons: LabelComparison[]
      ): CustomToolOutputField[] {
        return toolOutputFields.map((toolOutputField) => {
          const found = labelComparisons.find(
            (comparison) => comparison.oldLabel === toolOutputField.name
          );

          if (found) {
            return {
              ...toolOutputField,
              name: found.newLabel
            };
          }

          return toolOutputField;
        });
      }

      const newOutputFields = updateToolOutputFields(
        state.currentState.toolOutputFields,
        labelComparison
      );

      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          blocks: newUpdatedBlocks,
          toolOutputFields: newOutputFields,
          toolOutputOptions: getUniqueOutputNames(
            state.currentState.toolInputFields,
            newUpdatedBlocks
          ),
          availableFields: newUpdatedAvailableFields
        }
      };
      break;
    case "UPDATE_OUTPUT_LABEL_IN_AVAILABLE_FIELDS":
      const updatedBlocks = updatePrompts(
        state.currentState.blocks,
        action.oldLabel,
        action.newLabel,
        false
      );

      let toolOutputFields = JSON.parse(
        JSON.stringify(
          state.currentState.toolOutputFields.map((f) => {
            if (f.name === action.oldLabel) {
              f.name = action.newLabel;
            }
            return f;
          })
        )
      );
      let updatedAllAvailableFields = state.currentState.availableFields;

      if (action.toolWithinTool) {
        toolOutputFields = JSON.parse(
          JSON.stringify(
            state.currentState.toolOutputFields.map((f) => {
              if (action.toolWithinTool) {
                // Iterate over toolWithinTool.main.toolOutputFields to find matching names
                action.toolWithinTool.main.toolOutputFields.forEach((field) => {
                  // Construct the oldLabel from node.data.label and field.name
                  const oldLabel = `${action.oldLabel} - ${field.name}`;
                  // If the current field name matches oldLabel, replace it with the newLabel
                  if (f.name === oldLabel) {
                    const newLabel = `${action.newLabel} - ${field.name}`;
                    f.name = newLabel;
                    updatedAllAvailableFields =
                      updateAllLabelsInAvailableFields(
                        updatedAllAvailableFields,
                        oldLabel,
                        newLabel
                      );
                  }
                });
              }
              return f;
            })
          )
        );
      } else {
        updatedAllAvailableFields = updateAllLabelsInAvailableFields(
          state.currentState.availableFields,
          action.oldLabel,
          action.newLabel
        );
      }

      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          blocks: updatedBlocks,
          toolOutputFields,
          toolOutputOptions: getUniqueOutputNames(
            state.currentState.toolInputFields,
            updatedBlocks
          ),
          availableFields: updatedAllAvailableFields
        }
      };
      break;
    case "SET_AVAILABLE_FIELDS":
      const newAvailableFields = {
        ...state.currentState.availableFields,
        [action.blockId]: action.fields
      };
      if (action.fields.length === 0) {
        delete newAvailableFields[action.blockId];
      }

      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          availableFields: newAvailableFields
        }
      };
      break;
    case "DELETE_AVAILABLE_FIELDS":
      const updatedAvailableFields = { ...state.currentState.availableFields };
      delete updatedAvailableFields[action.id];

      // find the deleted node
      const labelToDelete = state.currentState.blocks.filter(
        (block) => block.id === action.id
      )[0];

      const strToDelete = labelToDelete.data.label;

      // Loop through each key in the object
      for (const key in updatedAvailableFields) {
        // Check if the value of the key is an array
        if (Array.isArray(updatedAvailableFields[key])) {
          // Use filter to create a new array that does not include 'abc'
          updatedAvailableFields[key] = updatedAvailableFields[key].filter(
            (item) => item !== strToDelete
          );
        }
      }

      // update all availablefields tags in prompts
      const updatedBlocksDelete = updatePrompts(
        state.currentState.blocks,
        labelToDelete.data.label,
        "",
        true
      );
      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          blocks: updatedBlocksDelete,
          toolOutputOptions: getUniqueOutputNames(
            state.currentState.toolInputFields,
            updatedBlocksDelete
          ),
          availableFields: updatedAvailableFields
        }
      };
      break;
    case "UPDATE_INPUT_FIELD_NAME":
      const oldName = [...state.currentState.toolInputFields][action.index]
        .name;
      let newToolInputFields = [...state.currentState.toolInputFields];
      let newToolOutputFields = [...state.currentState.toolOutputFields];
      {
        newToolInputFields = newToolInputFields.map((field, i) =>
          i === action.index ? { ...field, name: action.newName } : field
        ) as CustomToolInputField[]; // need to deep copy so that the reference to the object changes in memory for originalState purposes

        newToolOutputFields = newToolOutputFields.map((field) =>
          field.name === oldName ? { ...field, name: action.newName } : field
        ) as CustomToolOutputField[];
      }

      const newBlocksName = updatePrompts(
        state.currentState.blocks,
        oldName,
        action.newName,
        false
      );

      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          toolOutputFields: newToolOutputFields,
          toolOutputOptions: getUniqueOutputNames(
            newToolInputFields,
            state.currentState.blocks
          ),
          toolInputFields: newToolInputFields,
          blocks: newBlocksName
        }
      };
      break;
    case "UPDATE_TOOL_INPUT_FIELD_DESCRIPTION":
      const newToolInputFieldsDescription = [
        ...state.currentState.toolInputFields
      ];
      const newFields = newToolInputFieldsDescription.map((field, i) =>
        i === action.index
          ? { ...field, description: action.description }
          : field
      ) as CustomToolInputField[]; // need to deep copy so that the reference to the object changes in memory for originalState purposes

      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          toolInputFields: newFields
        }
      };
      break;

    case "DELETE_INPUT_FIELD":
      const deletedToolInputFields = [...state.currentState.toolInputFields];
      const deletedOldName = [...state.currentState.toolInputFields][
        action.index
      ].name;

      const newInputFields = deletedToolInputFields.filter(
        (_, i) => i !== action.index
      );
      const newBlocks = updatePrompts(
        state.currentState.blocks,
        deletedOldName,
        "",
        true
      );
      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          toolInputFields: newInputFields,
          blocks: newBlocks,
          toolOutputFields: JSON.parse(
            JSON.stringify(
              state.currentState.toolOutputFields.filter(
                (f) => f.name !== deletedOldName
              )
            )
          ),
          toolOutputOptions: getUniqueOutputNames(newInputFields, newBlocks)
        }
      };
      break;
    case "NEW_TOOL":
      newState = {
        ...state,
        ...createNewToolState(),
        toolLoading: false
      };
      break;
    case "SAVE_VISIBILITY":
      newState = {
        ...state,
        currentState: { ...state.currentState, visibility: action.visibility },
        originalState: { ...state.originalState, visibility: action.visibility }
      };
      break;
    case "SAVE_TOOL_CATEGORIES":
      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          tag: { ...state.currentState.tag, categories: action.categories }
        },
        originalState: {
          ...state.originalState,
          tag: { ...state.originalState.tag, categories: action.categories }
        }
      };
      break;
    case "SAVE_TOOL_USE_CASES":
      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          tag: { ...state.currentState.tag, useCases: action.useCases }
        },
        originalState: {
          ...state.originalState,
          tag: { ...state.originalState.tag, useCases: action.useCases }
        }
      };
      break;
    case "UPDATE_BLOCK_INPUT_LABEL":
      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          blocks: updateBlockLabel(
            state.currentState.blocks,
            action.oldLabel,
            action.newLabel
          )
        }
      };
      break;
    case "UPDATE_TOOL_VERSION_RESPONSE":
      newState = {
        ...state,
        toolVersionResponse: action.toolVersionResponse,
        toolOutput: action.toolVersionResponse.responseDict || state.toolOutput
      };
      break;
    // we only use this to save the viewport between saves
    case "SET_TEMP_VIEWPORT":
      newState = {
        ...state,
        tempViewport: action.viewport
      };
      break;
    case "SET_SHOW_INFO_DRAWER":
      newState = {
        ...state,
        showInfoDrawer: action.showInfoDrawer,
        openNodeId: ""
      };
      break;
    case "SET_TOGGLE_TAGS_DIALOG":
      newState = {
        ...state,
        showTagsDialog: action.showTagsDialog
      };
      break;
    case "UPDATE_OPEN_NODE":
      newState = {
        ...state,
        openNodeId: action.nodeId,
        showInfoDrawer: false
      };
      break;
    case "UPDATE_USER_INPUT":
      newState = {
        ...state,
        userInput: action.userInput
      };
      break;
    case "RESET_OPEN_NODE":
      newState = {
        ...state,
        openNodeId: ""
      };
      break;
    case "UPDATE_TOOL_OUTPUT":
      newState = {
        ...state,
        toolPercentCompleted:
          typeof action.percentCompleted === "number"
            ? action.percentCompleted
            : state.toolPercentCompleted,
        toolOutput: {
          ...state.toolOutput,
          ...action.response
        }
      };
      break;
    case "RESET_TOOL_OUTPUT":
      newState = {
        ...state,
        toolOutput: null,
        toolPercentCompleted: null,
        toolVersionResponse: null
      };
      break;
    case "RECEIVE_TOOL_VERSIONS":
      const originalTool = getToolVersion(
        action.main._id,
        action.toolVersions,
        action.toolName,
        action.toolAbout,
        action.tag,
        action.visibility
      );
      // hacky -> the ids change on save, so we need to get the old openNodeId
      // however, labels need to be unique too, so we get the returned block label and get the new id
      let newOpenNodeId = state.openNodeId;
      if (state.openNodeId && action.openNodeLabel) {
        newOpenNodeId = originalTool.blocks.filter(
          (b) => b.data.label === action.openNodeLabel
        )[0].id;
      }

      newState = {
        ...state,
        toolVersions: action.toolVersions,
        main: action.main,
        creator: action.creator,
        toolLoading: false,
        tempViewport: action.tempViewport,
        toolId: action.toolId,
        toolVersionId: action.main._id,
        originalState: { ...state.originalState, ...originalTool },
        openNodeId: newOpenNodeId,
        currentState: JSON.parse(JSON.stringify(originalTool)), // need to deep copy so that the reference to the object changes in memory for originalState purposes
        toolOutput: null,
        toolPercentCompleted: null,
        toolVersionResponse: null,
        userInput: {}
      };
      break;
    case "SET_TOOL_INPUT_FIELDS":
      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          toolOutputOptions: getUniqueOutputNames(
            action.toolInputFields,
            state.currentState.blocks
          ),
          toolInputFields: action.toolInputFields
        }
      };
      break;

    case "DELETE_TOOL_INPUT_FIELDS_BY_ID":
      const deletedToolInputFieldsIds = action.toolInputFieldsIds;
      const filteredToolInputFields = state.currentState.toolInputFields.filter(
        (field) => !deletedToolInputFieldsIds.includes(field.id)
      );
      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          toolInputFields: filteredToolInputFields
        }
      };
      break;
    case "SET_TOOL_OUTPUT_FIELDS":
      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          toolOutputFields: action.toolOutputFields
        }
      };
      break;
    case "EXTERNAL_RECEIVE_TOOL":
      newState = {
        ...state,
        toolLoading: false,
        toolId: action.toolId,
        creator: action.creator,
        toolOutput: null,
        toolPercentCompleted: null,
        toolVersionResponse: null,
        userInput: {},
        currentState: {
          ...state.currentState,
          toolAbout: action.toolAbout,
          toolName: action.toolName,
          toolInputFields: action.toolInputFields,
          toolOutputFields: action.toolOutputFields,
          description: action.description
        },
        originalState: {
          ...state.originalState,
          toolAbout: action.toolAbout,
          toolName: action.toolName,
          toolOutputFields: action.toolOutputFields,
          toolInputFields: action.toolInputFields,
          description: action.description
        }
      };
      break;
    case "UPDATE_TOOL_VERSION_ID":
      const updatedToolVersion = getToolVersion(
        action.toolVersionId,
        state.toolVersions,
        state.currentState.toolName,
        state.currentState.toolAbout,
        state.currentState.tag,
        state.currentState.visibility
      );
      newState = {
        ...state,
        toolVersionId: action.toolVersionId,
        currentState: { ...state.currentState, ...updatedToolVersion },
        originalState: JSON.parse(JSON.stringify(updatedToolVersion)), // need to deep copy so that the reference to the object changes in memory for originalState purposes
        toolOutput: null,
        toolPercentCompleted: null,
        userInput: {}
      };
      break;
    case "RESET_TOOL":
      newState = {
        ...state,
        currentState: JSON.parse(JSON.stringify(state.originalState))
      };
      break;
    case "UPDATE_TOOL_NAME":
      newState = {
        ...state,
        currentState: { ...state.currentState, toolName: action.toolName }
      };
      break;
    case "UPDATE_TOOL_ABOUT":
      newState = {
        ...state,
        currentState: { ...state.currentState, toolAbout: action.toolAbout }
      };
      break;
    case "SET_TOOL_LOADING_TRUE":
      newState = {
        ...state,
        toolLoading: true
      };
      break;
    case "SET_TOOL_LOADING_FALSE":
      newState = {
        ...state,
        toolLoading: false
      };
      break;
    case "UPDATE_DESCRIPTION":
      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          description: action.description
        }
      };
      break;
    case "DELETE_TOOL_OUTPUT_FIELD":
      const newToolOutputFieldsDeleted =
        state.currentState.toolOutputFields.filter(
          (_, i) => i !== action.index
        );
      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          toolOutputFields: newToolOutputFieldsDeleted
        }
      };
      break;
    case "UPDATE_MAIN":
      newState = {
        ...state,
        main: action.main
      };
      break;

    case "UPDATE_TOOL_OUTPUT_FIELD_NAME":
      const updatedToolOutputFieldsName =
        state.currentState.toolOutputFields.map((field, i) =>
          i === action.index
            ? {
                ...field,
                name: action.toolOutputField.value,
                type: action.toolOutputField.type
              }
            : field
        ); // need to deep copy so that the reference to the object changes in memory for originalState purposes
      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          toolOutputFields: updatedToolOutputFieldsName
        }
      };
      break;

    case "UPDATE_TOOL_INPUT_FIELD_TYPE":
      let deleteMapping = false;
      const updatedToolInputFieldsType = state.currentState.toolInputFields.map(
        (field, i) => {
          if (i !== action.index) return field;

          const newField = updateInputType(action.value, field);

          if (
            field.type !== action.value &&
            (([CHECKBOX, FILE_UPLOAD] as CustomToolInputFieldTypes[]).includes(
              action.value
            ) ||
              ((
                [CHECKBOX, FILE_UPLOAD] as CustomToolInputFieldTypes[]
              ).includes(field.type) &&
                (
                  [
                    LARGE_TEXTFIELD,
                    TEXTFIELD,
                    SELECT
                  ] as CustomToolInputFieldTypes[]
                ).includes(action.value)))
          ) {
            deleteMapping = true;
          }

          return newField;
        }
      );

      const updatedField = updatedToolInputFieldsType[action.index];

      // Create new logic item for the updated field
      // we do this because the type changes, and so the logic dropdown should change too

      const newLogicItem = generateInitialLogicFromToolInput(updatedField);

      // Iterate through the blocks and update the logic items
      // if the newLogicItem is null -> for instance if the updatedField turned into
      // a filtered out field like a file, delete the logic item
      let uBlocks = state.currentState.blocks.map((block) => {
        if (block.type === "logicBlockNode" && block.data?.logicArray) {
          let updatedLogicArray;

          if (newLogicItem === null) {
            updatedLogicArray = block.data.logicArray.filter(
              (logicItem: LogicItem) => logicItem.input !== updatedField.name
            );
          } else {
            updatedLogicArray = block.data.logicArray.map(
              (logicItem: LogicItem) =>
                logicItem.input === updatedField.name ? newLogicItem : logicItem
            );
          }

          return {
            ...block,
            data: {
              ...block.data,
              logicArray: updatedLogicArray
            }
          };
        } else if (block.type === "toolWithinToolBlockNode" && deleteMapping) {
          const newInputMap = returnNewInputMap(
            block.data.inputMap,
            updatedField.name,
            ""
          );
          return {
            ...block,
            data: {
              ...block.data,
              inputMap: newInputMap
            }
          };
        }
        return block;
      });

      if (deleteMapping) {
        uBlocks = updatePrompts(uBlocks, updatedField.name, "", true);
      }

      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          blocks: uBlocks,
          toolInputFields: updatedToolInputFieldsType,
          toolOutputOptions: getUniqueOutputNames(
            updatedToolInputFieldsType,
            state.currentState.blocks
          )
        }
      };
      break;
    case "DELETE_LOGIC_OPTION":
      const dBlocks = state.currentState.blocks.map((block) => {
        if (block.type === "logicBlockNode" && block.data?.logicArray) {
          const updatedLogicArray = block.data.logicArray.filter(
            (logicItem: LogicItem) =>
              !(
                logicItem.input === action.input &&
                logicItem.parameterValue === action.parameterValue
              )
          );

          return {
            ...block,
            data: {
              ...block.data,
              logicArray: updatedLogicArray
            }
          };
        }
        return block;
      });

      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          blocks: dBlocks
        }
      };
      break;
    case "REPLACE_LOGIC_OPTION":
      const rBlocks = state.currentState.blocks.map((block) => {
        if (block.type === "logicBlockNode" && block.data?.logicArray) {
          const updatedLogicArray = block.data.logicArray.map(
            (logicItem: LogicItem) => {
              if (
                logicItem.input === action.input &&
                logicItem.parameterValue === action.parameterValue
              ) {
                return { ...logicItem, parameterValue: action.newValue };
              } else return logicItem;
            }
          );

          return {
            ...block,
            data: {
              ...block.data,
              logicArray: updatedLogicArray
            }
          };
        }
        return block;
      });

      newState = {
        ...state,
        currentState: {
          ...state.currentState,
          blocks: rBlocks
        }
      };
      break;
    default:
      throw new Error(`Unhandled action type`);
  }

  // We do this so that we can view reducers on the FE
  if (
    ["development", "staging"].includes(process.env.REACT_APP_ENVIRONMENT || "")
  ) {
    // Update the global object with the current state for the given reducerId
    window.reducerStates[`Tool Reducer`] = newState;
  }

  if (!state.reducerId) {
    newState.reducerId = reducerId;
  }
  return newState;
}

export default toolBuilderReducer;
