import type {
  CustomToolInputFieldTypes,
  TBlockOutput,
  TCSVFileUpload,
  TCheckbox,
  TDoubleTextfield,
  TFileUpload,
  TLargeTextField,
  TMultiSelect,
  TSelect,
  TTagsInput,
  TTextField
} from "@sharedTypes";

export const TEXTFIELD: TTextField = "textField";
export const LARGE_TEXTFIELD: TLargeTextField = "largeTextField";
export const SELECT: TSelect = "select";
export const CHECKBOX: TCheckbox = "checkbox";
export const FILE_UPLOAD: TFileUpload = "fileUpload";
export const MULTI_SELECT: TMultiSelect = "multiSelect";
export const TAGS_INPUT: TTagsInput = "tagsInput";
export const DOUBLE_TEXTFIELD: TDoubleTextfield = "doubleTextfield";
export const CSV_FILE_UPLOAD: TCSVFileUpload = "csvFileUpload";
export const BLOCK_OUTPUT: TBlockOutput = "blockOutput";

export const INPUT_TEXTFIELD_ARRAY: CustomToolInputFieldTypes[] = [
  TEXTFIELD,
  LARGE_TEXTFIELD
];

export const isSimilarInputType = (
  firstType: CustomToolInputFieldTypes,
  secondType: CustomToolInputFieldTypes
) => {
  return (
    firstType === secondType || // is it the same type
    (INPUT_TEXTFIELD_ARRAY.includes(secondType) && // or are they both of textfield types
      INPUT_TEXTFIELD_ARRAY.includes(firstType))
  );
};
