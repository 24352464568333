import type { WorkflowInputString } from "@sharedTypes";
import { useEffect, useState } from "react";
import { useInputContext } from "../../../../../../utilities/contexts/InputContext";

/* When a component initially mounts (opening dialog, switching assets), we don't want to have scroll,
however we want to have the scroll to bottom to work after it has mounted / things are streaming.

Scroll is disabled when the scroller returns a function that returns 0*/
const useScroller = () => {
  const [hasMounted, setHasMounted] = useState(false);
  const { input } = useInputContext<WorkflowInputString>();

  useEffect(() => {
    setHasMounted(false);
  }, [input.id]);

  useEffect(() => {
    setTimeout(() => {
      setHasMounted(true);
    }, 1000);
  }, [hasMounted]);

  // If we pass a () => number, this will send the initial scroll to that location
  // If we pass it 0, it will not scroll initially.
  // We pass it 0 when the asset gets expanded in the dialog, otherwise
  // We use the default scroll behavior
  const disableScroll = () => 0;

  return !hasMounted ? disableScroll : undefined;
};

export default useScroller;
