import { GridOn } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useToolContext } from "../../../../utilities/contexts/ToolContext";
import useAddNewSheet from "../../hooks/useAddNewSheet";

function RunInBulkButton() {
  const { tool } = useToolContext();
  const addNewSheet = useAddNewSheet();
  const onClick = () => {
    addNewSheet(tool._id);
  };

  return (
    <Button
      variant="text"
      onClick={onClick}
      startIcon={<GridOn fontSize="small" />}
    >
      Run in bulk
    </Button>
  );
}

export default RunInBulkButton;
