import React from "react";
import CloseAssetDialogButton from "./CloseAssetDialogButton";

import { parsedClassNames } from "../../utils/parsedClassNames";
import { TextAssetSizeSwitch } from "../textAsset/TextAssetSizeSwitch";
import styles from "./assetDialogInner.module.css";

const cx = parsedClassNames.bind(styles);

function AssetDialogInner() {
  return (
    <div className="p-v-24px max-h-100-percent h-100-percent">
      <div className="justify-flex-end flex p-r-24px">
        <CloseAssetDialogButton />
      </div>
      <TextAssetSizeSwitch
        className={cx(styles["asset-dialog-height"], "h-100-percent")}
      />
    </div>
  );
}
export default AssetDialogInner;
