import { useEffect, useState } from "react";
import useGetAssetId from "../../../../../hooks/useGetAssetId";

const useAssetGroup = ({ assets }: { assets: string[] }) => {
  const selectedAssetId = useGetAssetId();

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (selectedAssetId in assets) {
      setIsOpen(true);
    }
  }, [selectedAssetId, assets]);

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  return { isOpen, onClick };
};

export default useAssetGroup;
