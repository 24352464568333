import type { UpdateSnippet } from "@sharedTypes";
import { useEffect } from "react";
import type { UseFormReturn } from "react-hook-form";

const useResetDefaultValuesAndRerender = (
  defaultValues: UpdateSnippet,
  methods: UseFormReturn<UpdateSnippet, $TSAllowedAny, undefined>
) => {
  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues, methods]);
};

export default useResetDefaultValuesAndRerender;
