import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import {
  draggable,
  dropTargetForElements
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { useEffect, useRef, useState } from "react";
import invariant from "tiny-invariant";
import { DRAGGING, DraggableState, IDLE, VERTICAL } from "./draggableConstants";
import type { TOrientation } from "@sharedTypes";

const idleState: DraggableState = { type: IDLE };
const draggingState: DraggableState = { type: DRAGGING };

function useDraggable({
  disabledDrag,
  type,
  id,
  index,
  disableDragHandle,
  orientation = VERTICAL,
  data = {}
}: {
  disabledDrag?: boolean;
  type: string;
  id: string;
  index: number;
  disableDragHandle?: boolean;
  orientation?: TOrientation;
  data?: Record<string, $TSAllowedAny>;
}) {
  const initialData = { type, id, index, ...data };
  const ref = useRef<HTMLDivElement>(null);
  const dragHandleRef = useRef<Element>(null);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [draggableState, setDraggableState] =
    useState<DraggableState>(idleState);
  useEffect(() => {
    invariant(ref.current);
    if (!disableDragHandle) {
      invariant(dragHandleRef.current);
    }

    const element = ref.current;

    return combine(
      draggable({
        element,
        dragHandle: disableDragHandle
          ? undefined
          : dragHandleRef.current ?? undefined,
        getInitialData: () => initialData,
        canDrag: () => !disabledDrag,
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "16px",
              y: "16px"
            }),
            render({ container }) {
              setDraggableState({ type: "preview", container });

              return () => setDraggableState(draggingState);
            }
          });
        },
        onDragStart() {
          setDraggableState(draggingState);
        },
        onDrop() {
          setDraggableState(idleState);
        }
      }),
      dropTargetForElements({
        element,
        getIsSticky: () => true,
        getData: ({ input, element: e }) => {
          return attachClosestEdge(initialData, {
            input,
            element: e,
            allowedEdges:
              orientation === VERTICAL ? ["top", "bottom"] : ["left", "right"]
          });
        },
        onDrag: ({ source, self }) => {
          const isSource =
            source.element === element || source.data.id === self.data.id;

          if (isSource) {
            setClosestEdge(null);
            return;
          }
          const sourceIndex = source.data.index;
          invariant(typeof sourceIndex === "number");

          const isItemBeforeSource = index === sourceIndex - 1;
          const isItemAfterSource = index === sourceIndex + 1;

          const closestE = extractClosestEdge(self.data);

          const isDropIndicatorHidden =
            orientation === VERTICAL
              ? (isItemBeforeSource && closestE === "bottom") ||
                (isItemAfterSource && closestE === "top")
              : (isItemBeforeSource && closestE === "right") ||
                (isItemAfterSource && closestE === "left");

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }
          setClosestEdge(closestE);
        },
        onDragLeave: () => {
          setClosestEdge(null);
        },
        onDrop: () => {
          setClosestEdge(null);
        }
      })
    );
  }, [disabledDrag, JSON.stringify(initialData)]);
  return { ref, dragHandleRef, closestEdge, draggableState };
}

export default useDraggable;
