import type {
  TransformedInputValue,
  WorkflowInputBaseIdsBySourceIdentifier
} from "@sharedTypes";
import useWorkspaceInputs from "../../../../hooks/useWorkspaceInputs";
import { useMemo } from "react";

const useTransformWorkflowInputs =
  (): WorkflowInputBaseIdsBySourceIdentifier => {
    const inputs = useWorkspaceInputs();
    return useMemo(
      () =>
        inputs.reduce<WorkflowInputBaseIdsBySourceIdentifier>((acc, input) => {
          if (input.sourceIdentifier) {
            if (!acc[input.sourceIdentifier]) {
              let valueToSet: TransformedInputValue;
              if (input.source === "manual") {
                valueToSet = {
                  sourceType: "manual",
                  assets: []
                };
              } else {
                const toolId = input.data?.toolId || "";

                valueToSet = {
                  sourceType: input.source,
                  toolCount: input.data?.toolCount || 0,
                  assets: [],
                  toolId: toolId // this should never resolve to ""
                };
              }
              acc[input.sourceIdentifier] = valueToSet;
            }
            acc[input.sourceIdentifier].assets.push(input.id);
          } else if (input.source === "manual") {
            if (!acc.manual) {
              acc.manual = {
                sourceType: "manual",
                assets: [input.id]
              };
            } else {
              acc.manual.assets.push(input.id);
            }
          }
          return acc;
        }, {}),
      [inputs.length]
    );
  };

export default useTransformWorkflowInputs;
