import React from "react";
import useValidateStepsOnMove from "./runTool/hooks/useValidateStepsOnMove";
import useStepDrop from "./hooks/useStepDrop";
import useMonitor from "../../../../utilities/draggables/useMonitor";

const DragStepMonitor = ({ children }: { children: React.ReactNode }) => {
  useValidateStepsOnMove();
  const onDrop = useStepDrop();
  useMonitor({ onDrop });

  return <>{children}</>;
};

export default DragStepMonitor;
