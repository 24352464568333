import { marked } from "marked";
import sanitizeHtml from "sanitize-html";

export const getPlainTextFromHtml = (s: string) => {
  // const withSpacing = s.replace(/<\/?p[^>]*>/g, "\n");
  return sanitizeHtml(s, {
    allowedTags: [], // no tags allowed, stripping all
    allowedAttributes: {} // no attributes allowed
  });
};

export const getPlainTextWithStrippedParagraphs = (s: string) => {
  const withSpacing = s.replace(/<\/?p[^>]*>/g, "\n");
  return sanitizeHtml(withSpacing, {
    allowedTags: [], // no tags allowed, stripping all
    allowedAttributes: {} // no attributes allowed
  });
};

export const getHtmlFromText = (s: string) => {
  if (!(typeof s === "string")) return "";
  // Regex to detect HTML tags
  const htmlTagRegex = /<\/?[a-z][\s\S]*>/i;
  let text = s;

  text = s.replace(/\n/g, "<br>");
  if (!htmlTagRegex.test(s)) {
    text = marked(s, { headerIds: false, mangle: false, breaks: true });
  }

  // Detect custom tags
  // we need these tags so that we can output instructions of anthropic in the prompt
  // later, we may want to add an input type that can allow html directly that isn't
  // formatted in the editor
  const customTagRegex = /<\/?([a-zA-Z0-9]+)[^>]*>/g;
  const customTags = new Set<string>();
  text = text.replace(customTagRegex, (m, p1) => {
    if (!sanitizeHtml.defaults.allowedTags.includes(p1.toLowerCase())) {
      customTags.add(p1);
      return m.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    }
    return m;
  });

  // Sanitize while allowing custom tags
  const sanitized = sanitizeHtml(text, {
    allowedTags: sanitizeHtml.defaults.allowedTags
      .concat(Array.from(customTags))
      .concat(["br", "p", "img"])
  });

  // console.log("wrapped", wrapped);

  return sanitized;
};
