import { useMemo } from "react";

import useToolInputFieldsAndAvailableFields from "../useToolInputFieldsAndAvailableFields.ts";
import createInputTypeMap from "../createInputTypeMap";
import type { CustomToolInputField, ExternalToolFE } from "@sharedTypes";

const useCompatibilityMap = ({
  id,
  tool
}: {
  id: string;
  tool: ExternalToolFE;
}) => {
  const availableFieldsAndInputs = useToolInputFieldsAndAvailableFields(id);

  const inputTypeMap = useMemo(
    () =>
      tool.main.toolInputFields.reduce<{
        [key: string]: CustomToolInputField;
      }>((accumulator, currentField) => {
        accumulator[currentField.name] = currentField;
        return accumulator;
      }, {}),
    [tool.main.toolInputFields]
  );
  console.log(inputTypeMap, "inputTypeMap");

  const compatibilityMap = useMemo(() => {
    return createInputTypeMap(inputTypeMap, availableFieldsAndInputs);
  }, [inputTypeMap, availableFieldsAndInputs]);
  return compatibilityMap;
};

export default useCompatibilityMap;
