import React from "react";
import NoWrapCardHeader from "../../utilities/NoWrapCardHeader";
import ToolflowSkeletonWrapper, {
  ToolsLoading
} from "../../utilities/ToolflowSkeleton";

const MarketplaceLoading = () => {
  return (
    <>
      <NoWrapCardHeader
        title={
          <ToolflowSkeletonWrapper sx={{ maxWidth: "80%" }}>
            Welcome to the Marketplace!
          </ToolflowSkeletonWrapper>
        }
        subheader={
          <ToolflowSkeletonWrapper sx={{ maxWidth: "80%" }}>
            The place to find and discover new Toolflow tools
          </ToolflowSkeletonWrapper>
        }
      />
      <ToolsLoading />
    </>
  );
};

export default MarketplaceLoading;
