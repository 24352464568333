import React from "react";
import { DragIndicator } from "@mui/icons-material";
import type { WorkflowInput } from "@sharedTypes";
import useIsCurrentUser from "../../utilities/hooks/useIsCurrentUser";
import SettingsDropdown from "../../utilities/dropdowns/generic/SettingsDropdown";
import DragToMoveTooltip from "../../utilities/DragToMoveTooltip";
import NavigateToToolBuilderMenuItem from "../../utilities/dropdowns/menuItems/NavigateToToolBuilderMenuItem";
import CopyTextMenuItem from "../../utilities/dropdowns/menuItems/CopyTextMenuItem";

function OutputSettingsDropdown({
  menuItems = [],
  input
}: {
  menuItems: React.ReactNode[];
  input: WorkflowInput;
}) {
  const currentUserIsToolOwner = useIsCurrentUser(input.data?.creatorId);
  const allMenuItems = menuItems.concat([
    currentUserIsToolOwner && input?.data?.toolId ? (
      <NavigateToToolBuilderMenuItem
        key="navigate"
        toolId={input.data.toolId}
      />
    ) : null,
    typeof input.value === "string" ? (
      <CopyTextMenuItem value={input.value} key="copy" />
    ) : null
  ]);

  return (
    <SettingsDropdown
      IconButtonProps={{
        draggable: true,
        "data-drag-handle": true,
        sx: { color: (theme) => theme.palette.grey["400"] },
        className: "cursor-grab"
      }}
      TooltipProps={{
        title: <DragToMoveTooltip />,
        arrow: true
      }}
      icon={<DragIndicator />}
      menuItems={allMenuItems}
    />
  );
}

export default OutputSettingsDropdown;
