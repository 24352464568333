import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type {
  TMarketplaceFilterByCategoriesFE,
  TMarketplaceFiltersByUseCasesFE
} from "@sharedTypes";

interface IMarketplaceInitialStateBase {
  useCases: TMarketplaceFiltersByUseCasesFE;
  category: TMarketplaceFilterByCategoriesFE;
}

const initialState: IMarketplaceInitialStateBase = {
  useCases: [],
  category: "popular"
};
export type TMarketplaceReducerName = "marketplace";
export const MARKETPLACE_REDUCER_NAME: TMarketplaceReducerName = "marketplace";

const marketplaceSlice = createSlice({
  name: MARKETPLACE_REDUCER_NAME,
  initialState,
  reducers: {
    setUseCases(state, action: PayloadAction<TMarketplaceFiltersByUseCasesFE>) {
      state.useCases = action.payload;
    },
    setCategory(
      state,
      action: PayloadAction<TMarketplaceFilterByCategoriesFE>
    ) {
      const selectedCategory = action.payload;
      state.category = selectedCategory;
      state.useCases = [];
    },
    setCategoryWithUseCases(
      state,
      action: PayloadAction<{
        category: TMarketplaceFilterByCategoriesFE;
        useCases: TMarketplaceFiltersByUseCasesFE;
      }>
    ) {
      const selectedCategory = action.payload.category;
      const selectedUseCases = action.payload.useCases;
      state.category = selectedCategory;
      state.useCases = selectedUseCases;
    }
  }
});

export const { setCategory, setUseCases, setCategoryWithUseCases } =
  marketplaceSlice.actions;

export default marketplaceSlice.reducer;
