import { useEffect } from "react";
import { useEditorToolCardContext } from "../EditorToolCardContext";

const useUpdateHighlightsFromDefaultUI = () => {
  const {
    userInput,
    highlightedText,
    setHighlightedText,
    setUserInput,
    defaultUI
  } = useEditorToolCardContext();

  useEffect(() => {
    const timerId = setTimeout(() => {
      const newUserInput = { ...defaultUI, ...userInput };
      setUserInput(newUserInput);
      // once we've gotten the tool, we can update the userInput default fields
      // we need to overwrite and set the defaults on originalState so that navigation works correctly
      // setWorkspaceContent(updateNodeInput(content, componentId, newUserInput));
      // we only want to trigger a run when something is highlighted for the first time
      // we should get rid of highlightedText after we've added it to the userInput
      if (highlightedText) {
        setHighlightedText("");
      }
    }, 0);
    return () => clearTimeout(timerId);
  }, [defaultUI]);

  return defaultUI;
};

export default useUpdateHighlightsFromDefaultUI;
