import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useBlockContext } from "../BlockContext";
import React from "react";
import { useToolbuilderContext } from "../context/ToolBuilderContext";

function DeleteNodeButton() {
  const { id } = useBlockContext();
  const { reactflowUtility } = useToolbuilderContext();
  const { onDeleteNode } = reactflowUtility;
  const deleteNode = () => {
    onDeleteNode(id);
  };
  return (
    <IconButton onClick={deleteNode}>
      <Delete />
    </IconButton>
  );
}

export default DeleteNodeButton;
