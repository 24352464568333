import React from "react";
import ToolInfo from "../../../utilities/ToolInfo";
import useGetSelectedStepTool from "./steps/runTool/hooks/useGetSelectedStepTool";
import DeselectToolButton from "./steps/runTool/DeselectToolButton";
import { ToolContextComponent } from "../../../utilities/contexts/ToolContext";
import { Card } from "@mui/material";
import VisitSiteButton from "../../../utilities/VisitSiteButton";

function WorkflowToolInfoCard() {
  const tool = useGetSelectedStepTool();
  if (!tool) return null;
  return (
    <ToolContextComponent tool={tool}>
      <Card variant="outlined">
        <ToolInfo
          action={
            <>
              <VisitSiteButton
                toolId={tool._id}
                size="small"
                variant="outlined"
              />
              <DeselectToolButton className="m-l-8px" />
            </>
          }
        />
      </Card>
    </ToolContextComponent>
  );
}
export default WorkflowToolInfoCard;
