import type { TMarketplaceEntity } from "@sharedTypes";
import useGetAvailableWorkflows from "./useGetAvailableWorkflows";

const useGetWorkflowSelectorOptions = () => {
  const availableWorkflows = useGetAvailableWorkflows();

  const options: Array<TMarketplaceEntity> = availableWorkflows.filter(
    (
      (seenIds) => (item: TMarketplaceEntity) =>
        !seenIds.has(item.id) && seenIds.add(item.id)
    )(new Set<string>())
  );
  return options;
};

export default useGetWorkflowSelectorOptions;
