import React from "react";
import { Icon } from "@mui/material";
import Logo from "../../assets/images/Slack.svg";

function SlackLogo({
  className = "",
  width = 16,
  borderRadius = 3
}: {
  width?: number;
  className?: string;
  borderRadius?: number;
}) {
  return (
    <div
      className={`flex align-i-center justify-center ${className}`}
      style={{
        background: "transparent",
        borderRadius: borderRadius,
        padding: 1,
        height: width,
        width: width
      }}
    >
      <Icon className="flex h-100-percent w-100-percent align-i-center justify-center">
        <img src={Logo} height={width - 2} />
      </Icon>
    </div>
  );
}

export default SlackLogo;
