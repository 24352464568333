import React from "react";
import CommonRightSideContainer from "../marketplace/CommonTopHeader";
import LearnMoreAboutKnowledge from "./LearnMoreAboutKnowledge/LearnMoreAboutKnowledge";
import SnippetsCard from "./snippetsCard/SnippetsCard";
import CenterContentContainer from "../../utilities/layout/CenterContentContainer";

function KnowledgePageInner() {
  return (
    <CommonRightSideContainer title="Knowledge">
      <CenterContentContainer>
        <div className="p-h-16px m-t-16px">
          <LearnMoreAboutKnowledge />
        </div>
        <div className="p-h-16px m-t-16px">
          <SnippetsCard />
        </div>
      </CenterContentContainer>
    </CommonRightSideContainer>
  );
}

export default KnowledgePageInner;
