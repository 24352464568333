import { createSelector } from "@reduxjs/toolkit";
import { favoritesApi } from "../favoritesApi";
import {
  externalToolAdapter,
  externalToolsInitialState
} from "../../../stores/entityAdapters";
import { RootState } from "../../../stores/store";

const selectProfileFavoriteToolResults = (profileId: string) =>
  favoritesApi.endpoints.getProfileFavoriteTools.select(profileId);

const selectProfileFavoriteToolData = (profileId: string) =>
  createSelector(
    selectProfileFavoriteToolResults(profileId),
    (favoriteToolResult) => favoriteToolResult.data ?? externalToolsInitialState
  );

export const selectAllProfileFavoriteTools = (profileId: string) =>
  createSelector(
    (state: RootState) => selectProfileFavoriteToolData(profileId)(state),
    (toolData) => externalToolAdapter.getSelectors().selectAll(toolData)
  );

export const selectProfileFavoriteToolById = (
  profileId: string,
  favoriteToolId: string
) =>
  createSelector(
    (state: RootState) => selectProfileFavoriteToolData(profileId)(state),
    (toolData) =>
      externalToolAdapter.getSelectors().selectById(toolData, favoriteToolId)
  );
