import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import React, { FC } from "react";
import { TMarketplaceTab } from "./constants";
import styles from "./marketplaceTabs.module.css";

interface IMarketplaceTabsProps {
  tabs: TMarketplaceTab[];
  selectedTabIndex: number;
  handleChangeTabIndex: (
    event: React.SyntheticEvent,
    newSelectedTabIndex: number
  ) => void;
  className?: string;
}
const MarketplaceTabs: FC<IMarketplaceTabsProps> = (props) => {
  const { tabs, className, handleChangeTabIndex, selectedTabIndex } = props;

  return (
    <Box
      sx={{
        flexGrow: 1
      }}
      className={className}
    >
      <Tabs
        value={selectedTabIndex}
        onChange={handleChangeTabIndex}
        variant="scrollable"
        scrollButtons={"auto"}
        aria-label="visible arrows tabs example"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 }
          }
        }}
        allowScrollButtonsMobile={true}
      >
        {tabs.map((tab) => {
          const isTabSelected = selectedTabIndex === tab.indexValue;
          return (
            <Tab
              key={tab.key}
              icon={isTabSelected ? tab.selectedIcon : tab.icon}
              label={
                <span
                  className={`capitalize ${
                    isTabSelected ? styles.selected : ""
                  }`}
                >
                  {tab.label}
                </span>
              }
              disabled={tab.disabled}
            />
          );
        })}
      </Tabs>
      <Divider light={true} />
    </Box>
  );
};

export default MarketplaceTabs;
