import React from "react";
import EditorToolCard from "../../../../utilities/editorToolCard/EditorToolCard";
import { EditorToolCardContextComponent } from "../../../../utilities/editorToolCard/EditorToolCardContext";
import type { CustomToolOutputField, UserInputDictType } from "@sharedTypes";

import useSetToolOutput from "../../../../workspace/hooks/editorToolCardHooks/useSetToolOutput";
import useCurrentInputsRef from "../../../../utilities/editorToolCard/hooks/useCurrentInputsRef";
import useUpdateInputsOnToolOutputChange from "../../hooks/useUpdateInputsOnToolOutputChange";

function EditorToolCardContainerInnerWorkspace({
  componentId,
  userInput,
  handleClose,
  setUserInput,
  additionalActions,
  fieldsToHide = [],
  addInputsCallback
}: {
  componentId: string;
  userInput: UserInputDictType;
  handleClose: () => void;
  setUserInput: (input: UserInputDictType) => void;
  additionalActions?: React.ReactNode;
  fieldsToHide?: string[];
  addInputsCallback?: (id: string, outputField: CustomToolOutputField) => void;
}) {
  const [highlightedText, setHighlightedText] = React.useState("");
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [tvrId, setTVRId] = React.useState("");
  const [percentCompleted, setPercentCompleted] = React.useState(0);
  const [toolOutput, setToolOutputBase] =
    React.useState<UserInputDictType | null>(null);
  const currentInputs = useCurrentInputsRef();

  const setToolOutput = useSetToolOutput({
    currentInputs,
    userInput,
    componentId,
    tvrId,
    setToolOutputBase
  });

  useUpdateInputsOnToolOutputChange({
    currentInputs,
    toolOutput,
    componentId,
    addInputsCallback
  });

  return (
    <EditorToolCardContextComponent
      userInput={userInput}
      highlightedText={highlightedText}
      componentId={componentId}
      tvrId={tvrId}
      percentCompleted={percentCompleted}
      toolOutput={toolOutput}
      hasLoaded={hasLoaded}
      handleClose={handleClose}
      setToolOutput={setToolOutput}
      setPercentCompleted={setPercentCompleted}
      setHighlightedText={setHighlightedText}
      setHasLoaded={setHasLoaded}
      setUserInput={setUserInput}
      setTVRId={setTVRId}
      additionalActions={additionalActions}
      fieldsToHide={fieldsToHide}
    >
      <EditorToolCard />
    </EditorToolCardContextComponent>
  );
}

export default EditorToolCardContainerInnerWorkspace;
