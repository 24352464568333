import React from "react";
import type { ScraperBlockData } from "@sharedTypes";
import {
  scraperSettingsInitialState,
  scraperInitialState
} from "../../context/initialStates";
import DraggableBlockContainer from "../DraggableBlockContainer";
import useParentId from "../hooks/useParentId";

function ScraperBlockDraggable({
  open,
  title = "Scrape Data"
}: {
  open: boolean;
  title?: string;
}) {
  const parentId = useParentId();
  return (
    <DraggableBlockContainer
      open={open}
      item={{
        type: "scraperBlockNode",
        data: {
          ...scraperSettingsInitialState,
          ...scraperInitialState
        } as ScraperBlockData,
        parentNode: parentId
      }}
      title={title}
    />
  );
}

export default ScraperBlockDraggable;
