import { useAuth0 } from "@auth0/auth0-react";
import useGetCurrentUser from "../hooks/useGetCurrentUser";

interface AuthError {
  error: string;
}

// Type guard to check if the error is an AuthError
function isAuthError(error: unknown): error is AuthError {
  return typeof error === "object" && error !== null && "error" in error;
}

function isLoginOrConsentRequiredError(error: unknown): boolean {
  return (
    isAuthError(error) &&
    (error.error === "login_required" ||
      error.error === "interaction_required" ||
      error.error === "consent_required")
  );
}

const useLoginBackToPage = () => {
  const { loginWithRedirect } = useAuth0();
  return {
    loginBackToPage: () => {
      loginWithRedirect({
        appState: { returnTo: window.location.pathname }
      });
    }
  };
};

const useIsAuth0DatabaseConnection = () => {
  const user = useGetCurrentUser();

  const isAuth0DatabaseConnection = () => {
    return user?.auth0UserId?.includes("auth0");
  };

  return {
    isAuth0DatabaseConnection
  };
};

export {
  useIsAuth0DatabaseConnection,
  useLoginBackToPage,
  isAuthError,
  isLoginOrConsentRequiredError
};
