// chatSlice.ts
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { TMessageByThreadId } from "@sharedTypes";

interface IChatInitialState {
  userMessage: TMessageByThreadId;
  messages: TMessageByThreadId[];
  isLoading: boolean;
}

const initialState: IChatInitialState = {
  userMessage: { id: "", message: "", role: "user" },
  messages: [],
  isLoading: false
};

export type TChatReducerName = "chat";
export const CHAT_REDUCER_NAME: TChatReducerName = "chat";

const chatSlice = createSlice({
  name: CHAT_REDUCER_NAME,
  initialState,
  reducers: {
    resetChatState() {
      return initialState;
    },
    setMessages(state, action: PayloadAction<TMessageByThreadId[]>) {
      state.messages = action.payload;
    },
    updateMessagesWithNewSocketMessage(
      state,
      action: PayloadAction<TMessageByThreadId>
    ) {
      const lastAssistantMessage = state.messages[state.messages.length - 1];
      const isLastMessageSocket =
        lastAssistantMessage.id.startsWith("socket_fe") &&
        lastAssistantMessage.role === "assistant";
      const hasSocketFinished =
        lastAssistantMessage.id.startsWith("socket_fe_last_");

      if (hasSocketFinished) {
        const index = state.messages.findIndex(
          (message) => message.isLastMessage
        );
        if (index === -1) {
          state.messages.push(action.payload);
        }
        state.messages[state.messages.length - 1] = action.payload;
      } else {
        if (isLastMessageSocket) {
          if (action.payload.isLastMessage) {
            state.messages.push(action.payload);
          } else {
            state.messages[state.messages.length - 1] = action.payload;
          }
        } else {
          state.messages.push(action.payload);
        }
      }
    },
    updateMessagesWithNewUserMessage(
      state,
      action: PayloadAction<TMessageByThreadId>
    ) {
      state.messages.push(action.payload);
    },
    setThreadMessagesLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    }
  }
});

export const {
  resetChatState,
  setMessages,
  updateMessagesWithNewUserMessage,
  updateMessagesWithNewSocketMessage,
  setThreadMessagesLoading
} = chatSlice.actions;

export default chatSlice.reducer;
