import React, { useCallback, useEffect, useState } from "react";
import { useSidebarWidth } from "../../globalTheme/sidebarTheme";
import { Divider } from "@mui/material";
import { globalEventEmitterFE } from "../globalEventEmitterFE";

type UseResizeProps = {
  minWidth: number;
  anchorType?: "left" | "right";
  initialWidth?: number | string;
  callback?: () => void;
};

type TEnableResize = () => void;

type UseResizeReturn = {
  width: number;
  enableResize: TEnableResize;
};

const ANCHOR_RIGHT = "right";

const useResize = ({
  minWidth,
  anchorType = ANCHOR_RIGHT,
  initialWidth,
  callback
}: UseResizeProps): UseResizeReturn => {
  const resolveWidth = () => {
    if (typeof initialWidth === "string") {
      if (initialWidth.endsWith("%")) {
        const parsedWidth = parseFloat(initialWidth); // Parse the numeric part of the percentage
        return window.innerWidth * (parsedWidth / 100); // Convert percentage to pixels
      }
      // this is hacky, but if the string doesn't have a %, just use minWidth for now
      return minWidth;
    }
    return initialWidth ?? minWidth;
  };

  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(resolveWidth());
  const sidebarWidth = useSidebarWidth();

  const enableResize = useCallback(() => {
    setIsResizing(true);
  }, [setIsResizing]);

  const disableResize = useCallback(() => {
    setIsResizing(false);
  }, [setIsResizing]);

  const resize = useCallback(
    (e: MouseEvent) => {
      if (isResizing) {
        let newWidth = e.clientX - sidebarWidth;
        if (anchorType === ANCHOR_RIGHT) {
          newWidth = window.innerWidth - e.clientX; // Calculate width from the right edge of the window
        }
        if (newWidth >= minWidth) {
          setWidth(newWidth);
          globalEventEmitterFE.emit("resizing");
          if (callback) {
            callback();
          }
        }
      }
    },
    [minWidth, isResizing, setWidth]
  );

  useEffect(() => {
    document.addEventListener("mousemove", resize);
    document.addEventListener("mouseup", disableResize);

    return () => {
      document.removeEventListener("mousemove", resize);
      document.removeEventListener("mouseup", disableResize);
    };
  }, [disableResize, resize]);

  return { width, enableResize };
};

export const ResizableDiv = ({
  enableResize
}: {
  enableResize: TEnableResize;
}) => {
  return (
    <Divider
      orientation="vertical"
      style={{
        cursor: "col-resize",
        borderRightWidth: 0,
        borderLeftWidth: 1,
        width: 2
      }}
      flexItem
      onMouseDown={enableResize}
    />
  );
};

export const ResizableDivHorizontal = ({
  enableResize
}: {
  enableResize: TEnableResize;
}) => {
  return (
    <Divider
      orientation="horizontal"
      style={{
        cursor: "row-resize",
        borderTopWidth: 0,
        borderBottomWidth: 1,
        height: 2
      }}
      flexItem
      onMouseDown={enableResize}
    />
  );
};

export default useResize;
