import type {
  UserInputDictType,
  CustomToolInputField,
  CustomToolInputFieldTypes,
  CustomToolOutputFieldTypes,
  CustomToolOutputField
} from "@sharedTypes";
import useExtensions from "./useExtension";
import { type JSONContent, generateText } from "@tiptap/core";

type InputsAndOutputs = CustomToolInputField | CustomToolOutputField;

function useConvertTipTapToText() {
  const extensions = useExtensions();

  return function convertTipTapToText(
    jsonContent: JSONContent,
    name: string
  ): string {
    if (jsonContent === null || jsonContent === undefined) return "";
    let text = "";
    try {
      text = generateText(jsonContent, extensions, { blockSeparator: " " });
    } catch (e) {
      console.log("error generating text", jsonContent, name, e);
    }
    return text;
  };
}

function useValidateUserInput() {
  const convertTipTapToText = useConvertTipTapToText();

  function validateTextField(
    inputOrOutput: InputsAndOutputs,
    newUserInput: UserInputDictType
  ): string {
    const val = newUserInput[inputOrOutput.name];
    // if the value is empty, it will throw an error in convertTipTapToText
    // instead just return an empty string
    if (!val) return "";
    const text = convertTipTapToText(val as JSONContent, inputOrOutput.name);
    if (
      text.trim() === "" &&
      JSON.stringify(val || {}).includes("inputComponent")
    ) {
      throw new Error(`Input chip in field:${inputOrOutput.name} is empty`);
    }
    return text;
  }

  // New: Generic function for handling text field validation to avoid code duplication
  function handleTextFieldValidation(
    inputOrOutput: InputsAndOutputs,
    newUserInput: UserInputDictType
  ) {
    const text = validateTextField(inputOrOutput, newUserInput);
    newUserInput[inputOrOutput.name] = text;
  }

  // Validation functions dictionary
  const validationFunctions: Partial<{
    [key in CustomToolInputFieldTypes | CustomToolOutputFieldTypes]: (
      inputOrOutput: InputsAndOutputs,
      newUserInput: UserInputDictType
    ) => void;
  }> = {
    checkbox: (inputOrOutput, newUserInput) => {
      if (!(inputOrOutput.name in newUserInput)) {
        newUserInput[inputOrOutput.name] = false;
      }
    },
    largeTextField: handleTextFieldValidation,
    textField: handleTextFieldValidation,
    copyableLargeTextField: handleTextFieldValidation
  };

  return function validateUserInput(
    userInput: UserInputDictType,
    inputAndOutputs: Array<InputsAndOutputs>
  ): { error: string; newUserInput: UserInputDictType } {
    const newUserInput = { ...userInput };
    let error = "";
    try {
      inputAndOutputs.forEach((inputOrOutput) => {
        const validate = validationFunctions[inputOrOutput.type];
        if (validate) {
          validate(inputOrOutput, newUserInput);
        }
      });
    } catch (e) {
      if (e instanceof Error) {
        error = e.message;
      }
    }
    return { error, newUserInput };
  };
}

export default useValidateUserInput;
