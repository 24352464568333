import React from "react";
import ProfileAction from "../../../../settings/ProfileAction";
import UpdateProfileField from "../../../../settings/UpdateProfileField";
import ResetPasswordButton from "../../../../settings/ResetPasswordButton";
import { Divider, Typography } from "@mui/material";

const ChangePasswordOrEmail = () => {
  return (
    <div>
      <Typography variant="h7">Email</Typography>
      <ProfileAction isSocial className="m-t-8px">
        <UpdateProfileField
          type="email"
          label="Email"
          successMessage="Email updated and verification email sent"
          className="m-t-24px"
        />
      </ProfileAction>
      <Divider className="m-v-24px" />
      <Typography variant="h7">Password</Typography>
      <div className="m-t-16px">
        <ProfileAction isSocial>
          <ResetPasswordButton />
        </ProfileAction>
      </div>
    </div>
  );
};

export default ChangePasswordOrEmail;
