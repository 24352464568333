import type { TMessageByThreadId } from "@sharedTypes";
import { getMessageType } from "./getMessageType";
import { messageTypeDict } from "../messageTypeDict";

export const getTransformedMessages = (messages: TMessageByThreadId[]) => {
  return messages.map((message) => {
    const messageType = getMessageType(message.message);
    const messageString = message.message;
    const messageContent = messageTypeDict[messageType](messageString);
    return {
      role: message.role,
      content: messageContent,
      id: message.id
    };
  });
};
