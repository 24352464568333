import React from "react";
import ToolSelectorWithText from "../../../../../../../utilities/toolSelector/ToolSelectorWithText";
import { useSheetContext } from "../SheetContext";

// there is an error that if something is pinned and you deselect the cell, it will crash
// so reset the pinning when the tool changes
const SheetHeader = () => {
  const { selectedTool, tableInstanceRef, setSelectedTool } = useSheetContext();
  const handleResetPinning = (value: string | null) => {
    tableInstanceRef.current?.resetRowPinning();
    setSelectedTool(value);
  };

  return (
    <ToolSelectorWithText
      setSelectedTool={handleResetPinning}
      selectedTool={selectedTool}
      autocompleteClassName="w-300px m-t-8px max-w-600px"
      placeholder="Search for a tool to run in bulk"
    />
  );
};

export default SheetHeader;
