import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from "@mui/material";
import type { ToolAutocomplete } from "@sharedTypes";
import React from "react";
import { getPlainTextFromHtml } from "../utilities/functions/htmlHelpers";
import ToolflowInfo from "../utilities/ToolflowInfo";
import { PushPin, Star } from "@mui/icons-material";

export default function ToolMenuListOption({
  props,
  option
}: Readonly<{
  props: React.HTMLAttributes<HTMLLIElement>;
  option: ToolAutocomplete;
}>) {
  const toolTipText = getPlainTextFromHtml(option.secondary);
  return (
    <ListItem
      {...props} //need this before the key prop because props has a key param we want to overwrite
      key={option.id}
      data-id={option.id}
      dense
      disableGutters
      disablePadding
      id="toolMenuListItem"
    >
      <ListItemText
        primary={
          <div className="flex align-i-center">
            <span className="m-r-8px">{option.primary}</span>
            <ToolflowInfo text={toolTipText} />
          </div>
        }
        // secondary={option.secondary}
      />
      {!!option.favoriteType && (
        <ListItemSecondaryAction>
          <IconButton disabled className="m-r-8px">
            {option.favoriteType === "pin" ? <PushPin /> : <Star />}
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
