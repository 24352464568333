import React from "react";
import useSetMenuFocus from "./hooks/useSetMenuFocus";
import useHandleInputChange from "./hooks/useHandleInputChange";
import useHandleKeyDown from "./hooks/useHandleKeyDown";
import { usePromptEditorContext } from "./context/PromptEditorContext";
import { TextField } from "@mui/material";

const PromptTextField = () => {
  const { setContextMenu, maxLength, textFieldRef, prompt, helperText } =
    usePromptEditorContext();

  useSetMenuFocus();

  const handleInputChange = useHandleInputChange();

  const onKeyDown = useHandleKeyDown();

  const onClick = () => {
    setContextMenu(null);
  };
  return (
    <TextField
      label={"Prompt"}
      placeholder={`Press '/' for commands...`}
      className="w-100-percent nodrag"
      margin="normal"
      multiline
      rows={15}
      value={prompt}
      onClick={onClick}
      onChange={handleInputChange}
      onKeyDown={onKeyDown}
      variant="outlined"
      inputRef={textFieldRef}
      inputProps={maxLength ? { maxLength } : undefined}
      helperText={helperText}
    />
  );
};

export default PromptTextField;
