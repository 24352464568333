import { useFormContext } from "react-hook-form";
import type { TWorkspaceDataToSave } from "@sharedTypes";
import useGetToolFromQuery from "../../../utilities/toolSelector/hooks/useGetToolFromQuery";
import { TOOL_TAB_TOOL_ID_FIELD_LABEL } from "../workspaceConstants";

const useGetSelectedWorkspaceTool = (refetch = false) => {
  const { watch } = useFormContext<TWorkspaceDataToSave>();
  const toolId = watch(TOOL_TAB_TOOL_ID_FIELD_LABEL) || undefined;
  return useGetToolFromQuery(toolId, { refetchOnMountOrArgChange: refetch });
};

export default useGetSelectedWorkspaceTool;
