import { Controller } from "react-hook-form";
import React from "react";
import { FormControl, InputLabel, Select } from "@mui/material";
import visibilityMenuItemsArray from "./visibilityMenuItemsArray";
import { VISIBILITY_FIELD_LABEL } from "../features/builder/workflowBuilder/WorkflowBuilderConstants";

function UpdateVisibilitySelectForm({
  margin = "normal"
}: {
  margin: "none" | "normal";
}) {
  return (
    <FormControl margin={margin} size="small">
      <InputLabel>Privacy</InputLabel>
      <Controller
        render={({ field }) => (
          <Select {...field} label="Privacy" labelId="Privacy">
            {visibilityMenuItemsArray}
          </Select>
        )}
        name={VISIBILITY_FIELD_LABEL}
      />
    </FormControl>
  );
}

export default UpdateVisibilitySelectForm;
