import type { TWorkspaceDataToSave } from "@sharedTypes";
import { useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import {
  TOOL_TAB_TOOL_ID_FIELD_LABEL,
  TOOL_TAB_COMPONENT_ID_FIELD_LABEL
} from "../features/workstation/workspaceConstants";

const useSetSelectedWorkspaceToolById = () => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();

  const setSelectedToolById = (toolId: string) => {
    setValue(TOOL_TAB_TOOL_ID_FIELD_LABEL, toolId, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
    setValue(TOOL_TAB_COMPONENT_ID_FIELD_LABEL, uuidv4(), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };
  return setSelectedToolById;
};

export default useSetSelectedWorkspaceToolById;
