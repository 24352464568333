import React from "react";
import UpdateTextFieldBase from "../../../utilities/UpdateTextFieldBase";
import type { IUpdateTextFieldContainerProps } from "@sharedTypes";
import {
  WORKFLOW_NAME_FIELD_LABEL,
  WORKFLOW_NAME_REQUIRED_ERROR
} from "./WorkflowBuilderConstants";

const UpdateWorkflowNameTextField = ({
  margin = "none",
  size = "small",
  label,
  placeholder,
  showErrors
}: IUpdateTextFieldContainerProps) => {
  return (
    <UpdateTextFieldBase
      size={size}
      label={label}
      margin={margin}
      placeholder={placeholder}
      fieldLabel={WORKFLOW_NAME_FIELD_LABEL}
      registerOptions={{ required: WORKFLOW_NAME_REQUIRED_ERROR }}
      showErrors={showErrors}
    />
  );
};

export default UpdateWorkflowNameTextField;
