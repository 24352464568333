import React from "react";
import ToolInfo from "../../utilities/ToolInfo";
import useGetSelectedWorkspaceTool from "./hooks/useGetSelectedWorkspaceTool";
import DeselectWorkspaceToolButton from "./DeselectWorkspaceToolButton";
import { ToolContextComponent } from "../../utilities/contexts/ToolContext";

function WorkspaceToolInfoCard() {
  const tool = useGetSelectedWorkspaceTool();
  if (!tool) return null;
  return (
    <ToolContextComponent tool={tool}>
      <ToolInfo action={<DeselectWorkspaceToolButton />} />
    </ToolContextComponent>
  );
}
export default WorkspaceToolInfoCard;
