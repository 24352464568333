import { generateJSON } from "@tiptap/core";
import { getHtmlFromText } from "../../../../../../../../utilities/functions/htmlHelpers";
import useExtensions from "../../../../../../../../workspace/hooks/useExtension";

const useConvertTextToTipTap = () => {
  const extensions = useExtensions();
  const convertTextToTipTap = (value: string) => {
    return generateJSON(getHtmlFromText(value as string), extensions);
  };

  return convertTextToTipTap;
};

export default useConvertTextToTipTap;
