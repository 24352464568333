import type { BlockDataTypes, BlockDataTypesWithDefault } from "@sharedTypes";
import React, { createContext, useContext } from "react";

interface BlockContextType {
  blockData: BlockDataTypesWithDefault;
  id: string;
  selected: boolean;
}

export const BlockContext = createContext<BlockContextType | undefined>(
  undefined
);

export const useBlockContext = () => {
  const context = useContext(BlockContext);
  if (context === undefined) {
    throw new Error("useBlockContext must be used within a BlockProvider");
  }
  return context;
};

export const BlockContextProvider = ({
  children,
  id,
  data,
  selected
}: {
  children: React.ReactNode;
  id: string;
  data: BlockDataTypes;
  selected: boolean;
}) => {
  return (
    <BlockContext.Provider value={{ id, blockData: data, selected }}>
      {children}
    </BlockContext.Provider>
  );
};
