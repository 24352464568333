import MoreVertIcon from "@mui/icons-material/MoreVert";
import React from "react";
import BaseSettingsDropdown from "./base/BaseSettingsDropdown";
import SettingsMenuButtonComponent from "./base/SettingsMenuButtonComponent";
import { IMenuButtonProps } from "./base/settingsTypes";

function SettingsDropdownMenuButton({
  menuItems,
  icon = <MoreVertIcon />,
  MenuButtonProps,
  disableToolTip
}: {
  icon?: React.ReactNode;
  MenuButtonProps?: IMenuButtonProps;
  menuItems: React.ReactNode[];
  disableToolTip?: boolean;
}) {
  return (
    <BaseSettingsDropdown menuItems={menuItems} disableToolTip={disableToolTip}>
      <SettingsMenuButtonComponent
        MenuButtonProps={MenuButtonProps}
        icon={icon}
      />
    </BaseSettingsDropdown>
  );
}

export default SettingsDropdownMenuButton;
