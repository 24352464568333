import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CopyLinkButton from "../utilities/CopyLinkButton";
import UpdateVisibilitySelect from "./UpdateVisibilitySelect";
import { Button, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import type { ValidateType } from "@sharedTypes";

export const ShareDialogBase = ({
  id,
  open,
  closeDialog,
  children,
  name,
  loading,
  type
}: {
  id: string;
  open: boolean;
  children: React.ReactNode;
  name: string;
  loading?: boolean;
  type: ValidateType;
  closeDialog: (e: React.SyntheticEvent<Element, Event>) => void;
}) => {
  return (
    <>
      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm">
        <DialogTitle className="flex align-i-center justify-space-between">
          <Typography variant="inherit" className="flex-grow-1" noWrap>
            Share {name}
          </Typography>
          <IconButton onClick={closeDialog}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions className="flex align-i-center justify-space-between">
          <CopyLinkButton id={id} text type={type} />
          <LoadingButton
            variant="contained"
            size="small"
            onClick={closeDialog}
            loading={loading}
          >
            Done
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ShareDialog = ({
  id,
  name,
  type
}: {
  id: string;
  name: string;
  type: ValidateType;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ShareDialogBase
        id={id}
        open={open}
        closeDialog={() => setOpen(false)}
        name={name}
        type={type}
      >
        <UpdateVisibilitySelect toolId={id} formProps={{ fullWidth: true }} />
      </ShareDialogBase>
      <Button onClick={() => setOpen(true)}>Share</Button>
    </>
  );
};

export default ShareDialog;
