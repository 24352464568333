import { Button } from "@mui/material";
import React from "react";
import ButtonLinkToBilling from "../../toolBuilder/common/ButtonLinkToBilling";
import { useEditorToolCardContext } from "./EditorToolCardContext";
import { useWebsocketContext } from "../../contexts/useWebsocketContext";

function ToolActions({ sendInput }: { sendInput: () => Promise<void> }) {
  const { showUpgrade, additionalActions } = useEditorToolCardContext();
  const { readyState } = useWebsocketContext();

  return (
    <div className="flex align-i-center justify-space-between w-100-percent">
      {additionalActions ? additionalActions : <div />}
      <div className="flex align-i-center">
        {showUpgrade && <ButtonLinkToBilling />}
        <Button
          onClick={sendInput}
          variant="contained"
          disabled={readyState !== WebSocket.OPEN}
        >
          Run
        </Button>
      </div>
    </div>
  );
}
export default ToolActions;
