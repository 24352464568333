import { NodeViewWrapper, type NodeViewProps } from "@tiptap/react";
import React from "react";
import { Paper } from "@mui/material";
import CircularLoading from "../../utilities/CircularLoading";
import ToolVersionResponseCard from "../../utilities/ToolVersionResponseCard";
import { useGetToolVersionResponseQuery } from "../../ToolflowAPI/rtkRoutes/toolVersionResponseApi";

const ToolResponseViewerComponent = (props: NodeViewProps) => {
  const { data } = useGetToolVersionResponseQuery(props.node.attrs.tvrId);
  const tvr = data?.toolVersionResponse;

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  if (!tvr)
    return (
      <NodeViewWrapper className="tip-tap-tool-response-component flex dontTriggerBubble negative-horizontal-margin-8">
        <Paper
          className="p-16px w-100-percent"
          variant="outlined"
          onClick={handleClick}
          contentEditable={false}
          draggable="true"
          data-drag-handle
        >
          <CircularLoading />
        </Paper>
      </NodeViewWrapper>
    );
  return (
    <NodeViewWrapper className="tip-tap-tool-response-component flex dontTriggerBubble negative-horizontal-margin-8">
      <div onClick={handleClick} className="w-100-percent">
        <ToolVersionResponseCard
          tvr={tvr}
          noDelete
          handleClose={props.deleteNode}
        />
      </div>
    </NodeViewWrapper>
  );
};

export default ToolResponseViewerComponent;
