import React from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@mui/material";
import AddMemberDialog from "./AddMemberDialog";
import ToolflowSkeletonWrapper from "../utilities/ToolflowSkeleton";
import useGetCurrentUser from "../hooks/useGetCurrentUser";
import FullWidthCard from "../features/layout/cards/FullWidthCard";
import LoadingButton from "@mui/lab/LoadingButton";

const OrganizationMembersCard = () => {
  const user = useGetCurrentUser();

  const loading = !user;
  return (
    <FullWidthCard className="p-32px m-t-24px">
      <Typography variant="h7">Members</Typography>
      <List>
        <ListItem disablePadding disableGutters className="m-t-16px" disabled>
          <ListItemAvatar>
            <ToolflowSkeletonWrapper
              loading={loading}
              variant="circular"
              width={40}
              height={40}
            >
              <Avatar src={user?.picture} />
            </ToolflowSkeletonWrapper>
          </ListItemAvatar>
          <ListItemText
            primary={
              <ToolflowSkeletonWrapper loading={loading} width={200}>
                {user?.name}
              </ToolflowSkeletonWrapper>
            }
            secondary={
              <ToolflowSkeletonWrapper loading={loading} width={200}>
                {user?.email}
              </ToolflowSkeletonWrapper>
            }
          />
          <ListItemSecondaryAction>
            <ToolflowSkeletonWrapper
              loading={loading}
              variant="circular"
              width={30}
              height={30}
            >
              <LoadingButton
                variant="text"
                sx={{
                  color: (theme) => theme.palette.text.disabled,
                  cursor: "not-allowed"
                }}
              >
                Delete
              </LoadingButton>
            </ToolflowSkeletonWrapper>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <AddMemberDialog />
    </FullWidthCard>
  );
};

export default OrganizationMembersCard;
