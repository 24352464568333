import { createSelector } from "@reduxjs/toolkit";
import {
  externalToolAdapter,
  externalToolsInitialState,
  marketplaceEntityAdapter,
  marketplaceEntityAdapterInitialState
} from "../../../stores/entityAdapters";
import { RootState } from "../../../stores/store";
import { toolsApi } from "../toolsApi";
import { marketplaceApi } from "../marketplaceApi";
import type {
  TMarketplaceEntity,
  TMarketplaceFilterByCategoriesFE,
  TMarketplaceFiltersByUseCasesFE
} from "@sharedTypes";

const selectProfileToolResults = (profileId: string) =>
  toolsApi.endpoints.getProfileTools.select(profileId);

const selectProfileToolData = (profileId: string) =>
  createSelector(
    selectProfileToolResults(profileId),
    (profileToolResult) => profileToolResult.data ?? externalToolsInitialState
  );

export const selectAllProfileTools = (profileId: string) =>
  createSelector(
    (state: RootState) => selectProfileToolData(profileId)(state),
    (toolData) => externalToolAdapter.getSelectors().selectAll(toolData)
  );

export const selectProfileToolsById = (profileId: string, toolId: string) =>
  createSelector(
    (state: RootState) => selectProfileToolData(profileId)(state),
    (toolData) =>
      externalToolAdapter.getSelectors().selectById(toolData, toolId)
  );

const selectMarketplaceEntitiesResult =
  marketplaceApi.endpoints.getMarketplaceEntities.select();

const selectMarketplaceEntitiesData = createSelector(
  selectMarketplaceEntitiesResult,
  (marketplaceEntitiesResult) => marketplaceEntitiesResult.data
);

export const {
  selectAll: selectAllMarketplaceEntities,
  selectById: selectMarketplaceEntityById
} = marketplaceEntityAdapter.getSelectors(
  (state: RootState) =>
    selectMarketplaceEntitiesData(state) ?? marketplaceEntityAdapterInitialState
);

const getFilteredToolsByUseCases = (
  entities: TMarketplaceEntity[],
  useCases: TMarketplaceFiltersByUseCasesFE
) => {
  let filteredTools: TMarketplaceEntity[] = [];
  if (useCases.length === 0) {
    filteredTools = [...entities];
  } else {
    filteredTools = entities
      .filter(
        (entity) =>
          useCases.filter((useCase) =>
            (entity?.tag?.useCases || []).includes(useCase.toString())
          ).length > 0
      )
      .flat();
  }
  return filteredTools;
};

export const selectMarketplaceEntitiesByFilters = ({
  selectedCategory,
  selectedUseCases
}: {
  selectedCategory: TMarketplaceFilterByCategoriesFE;
  selectedUseCases: TMarketplaceFiltersByUseCasesFE;
}) =>
  createSelector([selectAllMarketplaceEntities], (tools) => {
    let filteredTools: TMarketplaceEntity[] = [...tools];

    if (selectedCategory === "starred") {
      filteredTools = tools.filter((entity) => entity.userFavorited);
    } else if (selectedCategory === "popular") {
      filteredTools = tools.filter((entity) => entity.isPopular);
    } else if (selectedCategory !== "all") {
      filteredTools = tools.filter((entity) =>
        (entity?.tag?.categories || []).includes(selectedCategory)
      );
    }

    return getFilteredToolsByUseCases(filteredTools, selectedUseCases);
  });
