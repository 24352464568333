import React from "react";

import ToolBuilderWrapper from "../toolBuilder/ToolBuilderWrapper";
import UserBody from "../settings/Settings";
import WorkspacePage from "../workspace/WorkspacePage";
import LoginRoute from "../utilities/LoginRoute";
import MarketplacePage from "../features/marketplace/MarketplacePage";
import SelectBuilder from "../features/builder/SelectBuilder";
import WorkflowBuilderPage from "../features/builder/workflowBuilder/WorkflowBuilderPage";

import {
  EXTERNAL_TOOL_ROUTE_PATH,
  EXTERNAL_TOOL_VERSION_RESPONSE_ROUTE_PATH,
  EXTERNAL_WORKFLOW_ROUTE_PATH,
  EXTERNAL_WORKSPACE_ROUTE_PATH,
  KNOWLEDGE_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  MARKETPLACE_ROUTE_PATH,
  SELECTBUILDER_ROUTE_PATH,
  SIGNUP_ROUTE_PATH,
  TOOLBUILDER_ROUTE_PATH,
  WORKFLOWBUILDER_ROUTE_PATH,
  WORKSPACE_ROUTE_PATH,
  SNIPPET_ROUTE_PATH,
  HOME_ROUTE_PATH_DEV,
  PROFILE_ROUTE_PATH,
  SETTINGS_ROUTE_PATH
} from "./routePaths";
import ReroutePage from "../utilities/ReroutePage";
import HomePage from "../features/home/HomePage";
import KnowledgePage from "../features/knowledge/KnowledgePage";
import SnippetFormPage from "../features/knowledge/SnippetForm/SnippetFormPage";
import ProfilePage from "../features/profile/ProfilePage";

const routeElementDict = {
  [TOOLBUILDER_ROUTE_PATH]: <ToolBuilderWrapper />,

  [SELECTBUILDER_ROUTE_PATH]: <SelectBuilder />,
  [SNIPPET_ROUTE_PATH]: <SnippetFormPage />,

  [WORKFLOWBUILDER_ROUTE_PATH]: <WorkflowBuilderPage />,
  [KNOWLEDGE_ROUTE_PATH]: <KnowledgePage />,

  [LOGIN_ROUTE_PATH]: <LoginRoute />,
  [SIGNUP_ROUTE_PATH]: <LoginRoute signUp />,

  [SETTINGS_ROUTE_PATH]: <UserBody />,

  [PROFILE_ROUTE_PATH]: <ProfilePage />,

  [MARKETPLACE_ROUTE_PATH]: <MarketplacePage />,
  [HOME_ROUTE_PATH_DEV]: <HomePage />,

  [WORKSPACE_ROUTE_PATH]: <WorkspacePage />,

  [EXTERNAL_TOOL_ROUTE_PATH]: <ReroutePage />,

  [EXTERNAL_WORKSPACE_ROUTE_PATH]: <ReroutePage />,

  [EXTERNAL_WORKFLOW_ROUTE_PATH]: <ReroutePage />,

  [EXTERNAL_TOOL_VERSION_RESPONSE_ROUTE_PATH]: <ReroutePage />
};

export default routeElementDict;
