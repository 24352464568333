import { NodeViewWrapper, type NodeViewProps } from "@tiptap/react";
import React from "react";
import ToolflowSkeletonWrapper from "../../utilities/ToolflowSkeleton";
import { useAuth0Store } from "../../hooks/useAuth0Store";
import { useGetToolQuery } from "../../ToolflowAPI/rtkRoutes/toolsApi";
import { ToolContextComponent } from "../../utilities/contexts/ToolContext";
import { NodeViewContextProvider } from "../../utilities/contexts/NodeViewContext";
import EditorToolCardContainer from "../EditorToolCardContainer";

const ToolViewerComponent = (props: NodeViewProps) => {
  const { isLoading } = useAuth0Store();

  // we need to set a tool instead of updating attributes
  // because of race conditions with the tool.
  // otherwise we get infinite loading issues when reloading

  const { data } = useGetToolQuery(props.node.attrs.toolId, {
    skip: isLoading || !props.node.attrs.toolId
  });

  // const tool = data?.tool;

  return (
    <NodeViewWrapper className="tip-tap-tool-component flex negative-horizontal-margin-8">
      <ToolflowSkeletonWrapper loading={!data?.tool} width="100%">
        <NodeViewContextProvider nodeViewProps={props}>
          <ToolContextComponent tool={data?.tool}>
            <EditorToolCardContainer />
          </ToolContextComponent>
        </NodeViewContextProvider>
      </ToolflowSkeletonWrapper>
    </NodeViewWrapper>
  );
};

export default ToolViewerComponent;
