import React from "react";
import useToolflowNavigate from "../../../utilities/hooks/useToolflowNavigate";
import { PROFILE_NESTED_BILLING_ROUTE_PATH } from "../../../navigation/routePaths";
import SettingsMenuItemWithClose from "../../../utilities/dropdowns/generic/SettingsMenuItemWithClose";
import { RiFileList3Line } from "@remixicon/react";

const NavigateToBillingMenuItem = () => {
  const navigate = useToolflowNavigate();

  const navigateToBilling = () => {
    navigate(PROFILE_NESTED_BILLING_ROUTE_PATH);
  };
  return (
    <SettingsMenuItemWithClose
      icon={<RiFileList3Line size={18} />}
      text="Billing"
      action={navigateToBilling}
    />
  );
};

export default NavigateToBillingMenuItem;
