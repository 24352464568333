import { Divider, Tab, Tabs } from "@mui/material";
import React from "react";
import { RiHistoryLine } from "@remixicon/react";
import RemixWrapperIconWithTheme from "../layout/icons/RemixWrapperIconWithTheme";
import { toolflowPrimaryColor } from "../../globalTheme/muiUtils/appTheme";
import { RootState } from "../../stores/store";
import { useSelector, useDispatch } from "react-redux";
import type { THomeTabType } from "@sharedTypes";
import { updateHomeTab } from "./slice/homePageSlice";
import TabPanel, { a11yProps } from "../../utilities/TabPanel";
import WorkspaceTableContainer from "./workspace/WorkspaceTableContainer";
import ToolsTableContainer from "./tools/ToolsTableContainer";
import NavigateToHistoryAction from "./NavigateToHistoryAction";
import FullWidthCard from "../layout/cards/FullWidthCard";
import useAllHomePageMainData from "./hooks/useAllHomePageMainData";
import NoRecentData from "./NoRecentData";

type THomeTabDict = {
  [key in THomeTabType]: {
    value: number;
    component: React.ReactNode;
    label: string;
  };
};

const homeTabDict: THomeTabDict = {
  workspace: {
    value: 0,
    component: <WorkspaceTableContainer />,
    label: "Sessions"
  },
  tool: {
    value: 1,
    component: <ToolsTableContainer />,
    label: "Tools"
  }
};
const RecentData = () => {
  const homeTab = useSelector((state: RootState) => state.home.tab);
  const dispatch = useDispatch();
  const { homePageDataLength, isLoading } = useAllHomePageMainData();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: THomeTabType
  ) => {
    dispatch(updateHomeTab(newValue));
  };

  const isDataEmpty = homePageDataLength === 0 && !isLoading;
  if (isDataEmpty) {
    return <NoRecentData />;
  }
  return (
    <FullWidthCard
      title="Recent"
      icon={null}
      className="p-h-8px"
      headerIcon={
        <RemixWrapperIconWithTheme
          // className doesn't work here
          style={{ marginTop: "5px", marginRight: "5px" }}
          Icon={RiHistoryLine}
          providedColor={toolflowPrimaryColor}
          size={20}
        />
      }
      action={<NavigateToHistoryAction />}
    >
      <Tabs value={homeTab} onChange={handleChange} textColor="secondary">
        {Object.entries(homeTabDict).map(([tabName, tabDetails]) => (
          <Tab
            label={homeTab === tabDetails.label ? "Hello" : tabDetails.label}
            key={tabName}
            value={tabName}
            {...a11yProps(tabDetails.value)}
            className="m-l-12px"
          />
        ))}
      </Tabs>
      <Divider />
      {Object.entries(homeTabDict).map(([tabName, tabDetails]) => (
        <TabPanel value={homeTab} key={tabName} index={tabName}>
          {tabDetails.component}
        </TabPanel>
      ))}
    </FullWidthCard>
  );
};

export default RecentData;
