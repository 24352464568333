import type { SaveWorkspaceType } from "@sharedTypes";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";
import type { Editor } from "@tiptap/core";

export const getTipTapValue = ({
  editor,
  saveWorkspaceType
}: {
  editor: Editor;
  saveWorkspaceType?: SaveWorkspaceType;
}) => {
  if (saveWorkspaceType === "text") {
    return editor.getText();
  } else if (saveWorkspaceType === "json") {
    return editor.getJSON();
  } else {
    return editor.getHTML();
  }
};

const useGetTipTapValue = () => {
  const { muiTipTapProps } = useMuiTipTapContext();
  const { saveWorkspaceType } = muiTipTapProps;

  const getValue = ({ editor }: { editor: Editor }) => {
    return getTipTapValue({ editor, saveWorkspaceType });
  };
  return getValue;
};

export default useGetTipTapValue;
