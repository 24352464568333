import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import type { TSecretType } from "@sharedTypes";
import ManageDeepgramSecret from "./DeepgramSecrets/ManageDeepgramSecret";
import ManageOpenAISecret from "./OpenAiSecrets/ManageOpenAISecret";
import {
  useDeleteSecretMutation,
  useGetSecretsQuery,
  useSaveSecretMutation
} from "../ToolflowAPI/rtkRoutes/secretApi";

function ManageSecret() {
  // right now you can only have one secret
  // const secrets = [];
  const [val, setVal] = useState("");
  const [saveSecret, { isLoading: saveLoading }] = useSaveSecretMutation();
  const [deleteSecret, { isLoading: deleteLoading }] =
    useDeleteSecretMutation();
  const { data = { openAI: [], deepgram: [] }, isLoading: getLoading } =
    useGetSecretsQuery();

  const handleSaveSecret = (secretType: TSecretType) => {
    saveSecret({ secretValue: val, type: secretType });
  };

  const handleDeleteSecret = (secretId: string) => {
    deleteSecret(secretId);
  };
  const loading = saveLoading || deleteLoading || getLoading;

  return (
    <>
      <Typography variant="subtitle2" className="m-b-8px">
        API Keys
      </Typography>
      <Box>
        <ManageOpenAISecret
          secrets={data.openAI}
          val={val}
          handleDelete={handleDeleteSecret}
          loading={loading}
          handleChange={setVal}
          handleSaveSecret={handleSaveSecret}
        />
      </Box>
      <Box className="m-t-16px">
        <ManageDeepgramSecret
          secrets={data.deepgram}
          val={val}
          handleDelete={handleDeleteSecret}
          loading={loading}
          handleChange={setVal}
          handleSaveSecret={handleSaveSecret}
        />
      </Box>
    </>
  );
}

export default ManageSecret;
