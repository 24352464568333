import React from "react";
import ToggleTextField from "./ToggleTextField";

function TogglePrimaryHeaderTextFieldBase({
  value,
  children,
  errorMessage,
  disabled
}: Readonly<{
  value: string;
  children: React.ReactNode;
  errorMessage?: React.ReactNode;
  disabled?: boolean;
}>) {
  return (
    <ToggleTextField
      value={value}
      className="max-w-40-percent m-r-8px"
      wrapperClassName="flex-grow-1 max-w-40-percent m-r-8px"
      typographyClassName="flex-grow-1"
      disabled={disabled}
      disableFlexGrow
      errorMessage={errorMessage}
    >
      {children}
    </ToggleTextField>
  );
}

export default TogglePrimaryHeaderTextFieldBase;
