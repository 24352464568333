import type { LogicGate, HandledChatStackError } from "@sharedTypes";

export function isLogicGate(object: $TSFixMe): object is LogicGate {
  return (
    object && object.type === "logic" && typeof object.passed === "boolean"
  );
}

export function isHandledChatStackError(
  obj: $TSFixMe
): obj is HandledChatStackError {
  return (
    obj &&
    obj.type === "error" &&
    obj.data &&
    typeof obj.data.name === "string" &&
    typeof obj.data.message === "string" &&
    (obj.data.stack === undefined || typeof obj.data.stack === "string") &&
    (obj.data.status === undefined || typeof obj.data.status === "number")
  );
}
