import React from "react";
import type { ExternalToolFE } from "@sharedTypes";
import useIsCurrentUser from "../hooks/useIsCurrentUser";
import SettingsDropdown from "./generic/SettingsDropdown";
import MoreToolInfoMenuItem from "../../toolBuilder/dropdowns/menuItems/MoreToolInfoMenuItem";
import NavigateToToolBuilderMenuItem from "./menuItems/NavigateToToolBuilderMenuItem";
import CopyToolLinkMenuItem from "./menuItems/CopyToolLinkMenuItem";
import DuplicateToolMenuItem from "../../toolBuilder/dropdowns/menuItems/DuplicateToolMenuItem";

function ToolSettingsDropdown({ tool }: { tool: ExternalToolFE }) {
  const { _id: toolId, creator } = tool;
  const currentUserIsToolOwner = useIsCurrentUser(creator?._id);

  const menuItems = [
    <MoreToolInfoMenuItem tool={tool} key="moreInfo" />,
    currentUserIsToolOwner ? (
      <NavigateToToolBuilderMenuItem toolId={toolId} key="navigateToTool" />
    ) : null,
    <CopyToolLinkMenuItem toolId={toolId} key="copyToolLink" />,
    <DuplicateToolMenuItem id={toolId} key="duplicateTool" />
  ];

  return <SettingsDropdown menuItems={menuItems} />;
}

export default ToolSettingsDropdown;
