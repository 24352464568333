import React, { FC } from "react";
import EntityNotFoundBase from "../../utilities/marketplaceToolSelector/EntityNotFoundBase";
import { RiListCheck2 } from "@remixicon/react";
import NavigateToMarketplaceButton from "../../utilities/navigation/NavigateToMarketplaceButton";
import IconWithCircle from "../../utilities/IconLogos/IconWithCircle";
import RemixWrapperIconWithTheme from "../layout/icons/RemixWrapperIconWithTheme";
import { toolflowPrimaryColor } from "../../globalTheme/muiUtils/appTheme";

interface INoRecentDataProps {
  header?: string;
  subHeader?: string;
}
const NoRecentData: FC<INoRecentDataProps> = ({
  header = "Your tools and recent sessions will be stored here",
  subHeader = "Ready to start? Easily find your first tool in our marketplace and begin exploring the possibilities."
}) => {
  return (
    <EntityNotFoundBase
      className="flex text-align-center align-i-center  justify-center flex-column h-450px m-h-auto max-w-350px"
      icon={
        <IconWithCircle
          icon={
            <RemixWrapperIconWithTheme
              Icon={RiListCheck2}
              size={40}
              providedColor={toolflowPrimaryColor}
            />
          }
        />
      }
      header={header}
      subHeader={subHeader}
      actionButton={
        <NavigateToMarketplaceButton className="border-radius-8px text-transform-none" />
      }
    />
  );
};

export default NoRecentData;
