import React, { FC } from "react";
import type { SvgIconProps } from "@mui/material/SvgIcon";
import RemixWrapperIconWithTheme from "../icons/RemixWrapperIconWithTheme";
interface ISidebarIconProps extends SvgIconProps {
  Icon: React.ElementType;
}
const SidebarIcon: FC<ISidebarIconProps> = ({ Icon, ...props }) => {
  const iconProps = { ...props, size: 24 };
  return <RemixWrapperIconWithTheme Icon={Icon} {...iconProps} />;
};

export default SidebarIcon;
