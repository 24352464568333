import React, { createContext, useContext } from "react";
import type { ExternalToolFE } from "@sharedTypes";

interface ToolContextType {
  tool: ExternalToolFE;
}

const ToolContext = createContext<ToolContextType | undefined>(undefined);

export function ToolContextComponent({
  children,
  tool
}: Readonly<{
  children: React.ReactNode;
  tool?: ExternalToolFE;
}>) {
  if (!tool) {
    return null;
  }
  return (
    <ToolContext.Provider value={{ tool }}>{children}</ToolContext.Provider>
  );
}

export const useToolContext = () => {
  const context = useContext(ToolContext);
  if (context === undefined) {
    throw new Error("useToolContext must be used within a ToolProvider");
  }
  return context;
};

export default ToolContext;
