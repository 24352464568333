import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function PoweredByToolflow() {
  return (
    <Link to="https://www.toolflow.ai" rel="noopener noreferrer">
      <Button variant="text">Powered by Toolflow</Button>
    </Link>
  );
}

export default PoweredByToolflow;
