import type {
  RowUserInputDictType,
  UserInputResponseWithHandledError
} from "@sharedTypes";

const updateSingleValueInRow = (
  currentRow: RowUserInputDictType,
  fieldName: string,
  newValue: UserInputResponseWithHandledError
) => {
  return {
    ...currentRow,
    [fieldName]: newValue
  };
};
export default updateSingleValueInRow;
