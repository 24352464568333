import React from "react";
import { useParams } from "react-router-dom";
import useGetWorkflowName from "../../features/builder/workflowBuilder/hooks/useGetWorkflowName";
import useGetWorkflowVisibility from "../../features/builder/workflowBuilder/hooks/useGetWorkflowVisibility";
import { WORKFLOW } from "../../utilities/constants/constants";
import SettingsDropdown from "../../utilities/dropdowns/generic/SettingsDropdown";
import AddWorkflowCategoriesMenuItem from "../../utilities/dropdowns/menuItems/AddWorkflowCategoriesMenuItem";
import CopyWorkflowLinkMenuItem from "../../utilities/dropdowns/menuItems/CopyWorkflowLinkMenuItem";
import DeleteMenuItem from "../../utilities/dropdowns/menuItems/DeleteMenuItem";
import UpdateWorkflowVisibilityMenuItem from "../../utilities/dropdowns/menuItems/UpdateWorkflowVisibilityMenuItem";
import ResetWorkflowMenuItem from "./menuItems/ResetWorkflowMenuItem";

function WorkflowBuilderHeaderSettingsDropdown() {
  const { workflowId = "" } = useParams();
  const name = useGetWorkflowName();
  const visibility = useGetWorkflowVisibility();
  const menuItems = [
    <CopyWorkflowLinkMenuItem key="copyWorkflowLink" workflowId={workflowId} />,
    <UpdateWorkflowVisibilityMenuItem
      key="updateWorkflowVisibility"
      id={workflowId}
      name={name}
      visibility={visibility || "private"}
    />,
    <ResetWorkflowMenuItem key="resetWorkflow" />,
    <AddWorkflowCategoriesMenuItem
      key="addWorkflowCategories"
      workflowId={workflowId}
    />,
    <DeleteMenuItem
      id={workflowId}
      shouldNavigateHome
      type={WORKFLOW}
      key="delete"
    />
  ];

  if (!workflowId) return null;

  return <SettingsDropdown menuItems={menuItems} />;
}

export default WorkflowBuilderHeaderSettingsDropdown;
