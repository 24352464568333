import type {
  TAddToolOption,
  TToolOptionTypeWithNull,
  ToolAutocomplete
} from "@sharedTypes";
import { useState } from "react";
import { ADD_TOOL_TEXT } from "../toolSelectorConstants";

export const isAddNewTool = (
  option: ToolAutocomplete
): option is TAddToolOption => {
  return (option as TAddToolOption).id === ADD_TOOL_TEXT;
};

const useToolSelectorHighlight = () => {
  const [highlighted, setHighlighted] = useState<TToolOptionTypeWithNull>(null);

  const handleHighlightChange = (
    event: React.SyntheticEvent<Element, Event>,
    option: TToolOptionTypeWithNull
  ) => {
    setHighlighted(option);
  };
  return { highlighted, handleHighlightChange };
};

export default useToolSelectorHighlight;
