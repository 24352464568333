import React from "react";
import FullWidthCard from "../../../layout/cards/FullWidthCard";
import { Divider, Typography } from "@mui/material";
import useGetCurrentUser from "../../../../hooks/useGetCurrentUser";
import ImageCircle from "../../../../utilities/IconLogos/ImageCircle";
import ProfileInformationForm from "./ProfileInformationForm";
import ChangePasswordOrEmail from "./ChangePasswordOrEmail";

const ProfilePageContent = () => {
  const user = useGetCurrentUser();
  const picture = user?.picture || "";
  return (
    <>
      <FullWidthCard className="p-32px">
        <Typography variant="h7" className="m-b-16px">
          Profile photo
        </Typography>
        <ImageCircle imageSrc={picture} width={150} />
        <Divider className="m-v-24px" />
        <ProfileInformationForm className="m-t-8px" />
      </FullWidthCard>
      <FullWidthCard className="p-32px m-t-16px m-b-32px">
        <ChangePasswordOrEmail />
      </FullWidthCard>
    </>
  );
};

export default ProfilePageContent;
