import { Skeleton, Typography } from "@mui/material";
import React, { FC } from "react";
import CenterContentContainer from "../../../utilities/layout/CenterContentContainer";
import FullWidthCard from "../../layout/cards/FullWidthCard";
import CommonRightSideContainer from "../../marketplace/CommonTopHeader";
import TableSkeleton from "../layout/TableSkeleton";
import styles from "./homePageLoading.module.css";

interface IHomePageLoadingProps {
  action?: React.ReactNode;
}

const HomePageLoading: FC<IHomePageLoadingProps> = ({ action = null }) => {
  return (
    <CommonRightSideContainer title={"Home"} action={action}>
      <CenterContentContainer className="m-t-16px p-h-16px">
        <Typography variant="h6" sx={{ width: 300 }}>
          <Skeleton height={28} />
        </Typography>
        <FullWidthCard className={`m-t-8px ${styles["no-border"]}`}>
          <Skeleton variant="rectangular" height={100} />
        </FullWidthCard>
        <Typography className="m-t-8px" variant="h6" sx={{ width: 150 }}>
          <Skeleton height={28} />
        </Typography>
        <TableSkeleton numOfRows={5} />
        <Typography className="m-t-8px" variant="h6" sx={{ width: 150 }}>
          <Skeleton height={28} />
        </Typography>
        <TableSkeleton numOfRows={5} />
      </CenterContentContainer>
    </CommonRightSideContainer>
  );
};

export default HomePageLoading;
