import React from "react";
import AssetDialog from "../assetDialog/AssetDialog";
import { TextAssetSizeSwitch } from "./TextAssetSizeSwitch";

// We need to have the OpenAssetDialog be separate from the Text
// tab so we don't have infinite dialogs when expanding TextTabs
function TextAsset() {
  return (
    <>
      <TextAssetSizeSwitch />
      <AssetDialog />
    </>
  );
}

export default TextAsset;
