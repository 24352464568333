import React from "react";
import useGetSelectedWorkspaceWorkflow from "./hooks/useGetSelectedWorkspaceWorkflow";
import { WorkflowContextComponent } from "../../utilities/contexts/WorkflowContext";
import DeselectWorkspaceWorkflowButton from "./DeselectWorkspaceWorkflowButton";
import WorkflowInfo from "../../utilities/WorkflowInfo";
import useGetWorkspacePopulatedWorkflow from "./hooks/useGetSelectedPopulatedWorkflow";

function WorkspaceWorkflowInfoCard() {
  const workflow = useGetSelectedWorkspaceWorkflow();
  const workflowVersion = useGetWorkspacePopulatedWorkflow();
  if (!workflow) return null;
  return (
    <WorkflowContextComponent
      workflow={workflow}
      workflowVersion={workflowVersion}
    >
      <WorkflowInfo action={<DeselectWorkspaceWorkflowButton />} />
    </WorkflowContextComponent>
  );
}
export default WorkspaceWorkflowInfoCard;
