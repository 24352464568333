// FormattedTextDisplay.js
import React, { useEffect, useRef } from "react";
import { CssBaseline, useTheme } from "@mui/material";
import useExtension from "../workspace/hooks/useExtension";
import { RichTextEditor } from "mui-tiptap";
import type { RichTextEditorRef } from "mui-tiptap";

import { ThemeProvider } from "@emotion/react";
import { useLocation } from "react-router-dom";
import { getLocationTrackProperties } from "./functions/getLocationTrackProperties";
import { useTrackEventMutation } from "../ToolflowAPI/rtkRoutes/trackEventApi";

export const GenericRichHtml = ({
  html,
  className = "",
  toolVersionResponseId,
  rerender
}: {
  html?: string;
  className?: string;
  toolVersionResponseId?: string;
  rerender?: boolean; // if we want the editor to be able to rerender, like the design page: https://github.com/sjdemartini/mui-tiptap#re-rendering-richtexteditor-when-content-changes
}) => {
  const [trackEvent] = useTrackEventMutation();
  const location = useLocation();
  const rteRef = useRef<RichTextEditorRef>(null);
  const extensions = useExtension({
    placeholder: "",
    shouldExcludeToolViewer: true
  });

  // i don't like doing this, but i've added tiptap
  // styles to app.css with !important to space out the nodes a bit
  // having trouble with precedence of themes right now and
  // styling the mui-tiptap
  const theme = useTheme();

  const editor = rteRef.current?.editor;
  useEffect(() => {
    if (!editor || editor.isDestroyed || !rerender) {
      return;
    }
    if (!editor.isFocused || !editor.isEditable) {
      // Use queueMicrotask per https://github.com/ueberdosis/tiptap/issues/3764#issuecomment-1546854730
      queueMicrotask(() => {
        const currentSelection = editor.state.selection;
        editor
          .chain()
          .setContent(html ?? "")
          .setTextSelection(currentSelection)
          .run();
      });
    }
  }, [html, editor, editor?.isEditable, editor?.isFocused, rerender]);

  if (html === "") return null;

  return (
    <div className={className}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* We use RichTextEditor because we need to allow people to highlight the text
        RichTextReadOnly makes contentEditable="false" which doesnt allow people to select text
        we then handle keydown events to not allow people to type within the text , 
        but we allow copying with copy-c*/}
        <RichTextEditor
          ref={rteRef}
          content={html}
          extensions={extensions}
          RichTextFieldProps={{ variant: "standard" }}
          editorProps={{
            handleDOMEvents: {
              keydown: (view, event) => {
                if ((event.ctrlKey || event.metaKey) && event.key === "c") {
                  // we only care to capture copy events if they happen from a tool version response
                  if (toolVersionResponseId) {
                    trackEvent({
                      eventString: "Used output",
                      properties: {
                        action_outcome_type: "copy_tool_output",
                        output_type: "text",
                        ...getLocationTrackProperties(location.pathname)
                      },
                      toolVersionResponseId
                    });
                  }
                } else {
                  event.preventDefault();
                }
                return true;
              }
            }
          }}
        />
      </ThemeProvider>
    </div>
  );
};

const GenericRichText = ({
  text,
  className = "",
  rerender = false
}: {
  text?: string;
  className?: string;
  rerender?: boolean;
}) => {
  return (
    <GenericRichHtml html={text} className={className} rerender={rerender} />
  );
};

export default GenericRichText;
