import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import getTitleByPath from "./getTitleByPath";

export const useDocumentTitle = () => {
  const location = useLocation();

  useEffect(() => {
    let title = "Toolflow";
    const routeTitle = getTitleByPath(location.pathname);
    if (routeTitle) {
      title = `${routeTitle} | ${title}`;
    }
    document.title = title;
  }, [location.pathname]);
};
