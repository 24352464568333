import { useDispatch } from "react-redux";
import { setDisableTabChange } from "../../../workspaceSlice";
import useGetWorkflowActiveStep from "./useGetWorkflowActiveStep";
import useSetActiveStep from "./useSetActiveStep";

const useHandleCloseForWorkflow = () => {
  const setActiveStep = useSetActiveStep();
  const activeStep = useGetWorkflowActiveStep();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setDisableTabChange(false));
    setActiveStep(activeStep + 1);
  };
  return handleClose;
};

export default useHandleCloseForWorkflow;
