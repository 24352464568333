import type { CustomToolInputField } from "@sharedTypes";
import { isInputWithOptions } from "../../../../../../utilities/Inputs/constants";
import useSetToolInputFieldsWorkflow from "./useSetToolInputFieldsWorkflow";
import useGetSelectedStepInputsToCollect from "./useGetSelectedStepInputToCollect";

const useChangeOptionNameWorkflow = () => {
  const setToolInputFields = useSetToolInputFieldsWorkflow();
  const inputsToCollect = useGetSelectedStepInputsToCollect();

  const handleOptionChange = (
    toolInputField: CustomToolInputField | null,
    optionIndex: number,
    value: string,
    index: number
  ) => {
    if (!isInputWithOptions(toolInputField)) {
      return;
    }
    const options = toolInputField.options || [];
    options[optionIndex] = value;
    const updatedToolInputField = {
      ...toolInputField,
      options
    };
    const updatedToolInputFields = [...inputsToCollect];
    updatedToolInputFields[index] = updatedToolInputField;
    setToolInputFields(updatedToolInputFields);
  };

  return handleOptionChange;
};

export default useChangeOptionNameWorkflow;
