import React from "react";
import FullWidthCard from "../../layout/cards/FullWidthCard";
import { RiFileTextLine } from "@remixicon/react";
import RemixWrapperIconWithTheme from "../../layout/icons/RemixWrapperIconWithTheme";
import { toolflowPrimaryColor } from "../../../globalTheme/muiUtils/appTheme";
import AddSnippetButton from "./AddSnippetButton";
import SnippetTable from "./SnippetTable";

const SnippetsCard = () => {
  return (
    <FullWidthCard
      title="Snippets"
      action={<AddSnippetButton />}
      headerIcon={
        <RemixWrapperIconWithTheme
          style={{ marginTop: "5px", marginRight: "5px" }}
          Icon={RiFileTextLine}
          providedColor={toolflowPrimaryColor}
          size={20}
        />
      }
    >
      <SnippetTable />
    </FullWidthCard>
  );
};

export default SnippetsCard;
