import React from "react";
import PoweredByToolflow from "./PoweredByToolflow";
import LoginButton from "./LoginButton";
import { useAuth0Store } from "../hooks/useAuth0Store";

function LoggedOutCTA() {
  const { isAuthenticated, isLoading } = useAuth0Store();
  if (isAuthenticated || isLoading) return null;
  return (
    <>
      <PoweredByToolflow />
      <LoginButton variant="outlined" size="small" />
    </>
  );
}
export default LoggedOutCTA;
