import { combineSlices, configureStore } from "@reduxjs/toolkit";
import homeReducer, {
  HOME_REDUCER_NAME
} from "../features/home/slice/homePageSlice";
import sidebarReducer, {
  SIDEBAR_REDUCER_NAME
} from "../features/layout/sidebar/sidebarSlice";
import alertMessageReducer, {
  ALERT_MESSAGE_REDUCER_NAME
} from "../features/layout/alerts/alertMessageSlice";
import { auth0API } from "../ToolflowAPI/auth0API";
import {
  rtkQueryErrorLogger,
  rtkQueryErrorNavigation
} from "./rtkQueryErrorLogger";
import authReducer, { AUTH_REDUCER_NAME } from "../features/auth/authSlice";
import { authenticatedApi } from "../ToolflowAPI/authenticatedAPI";
import { externalApi } from "../ToolflowAPI/externalAPI";
import toolInfoDrawerReducer, {
  TOOL_INFO_DRAWER_REDUCER_NAME
} from "../utilities/toolInfoDrawerSlice";
import appWarningReducer, {
  APP_WARNING_REDUCER_NAME
} from "../features/layout/sidebar/appWarningSlice";
import workflowBuilderReducer, {
  WORKFLOW_BUILDER_REDUCER_NAME
} from "../features/builder/workflowBuilder/workflowBuilderSlice";

import navigationReducer from "../navigation/navigationSlice";
import workspaceReducer, {
  WORKSPACE_REDUCER_NAME
} from "../features/workstation/workspaceSlice";
import chatReducer, {
  CHAT_REDUCER_NAME
} from "../features/workstation/leftSideDrawer/chatTab/chatbox/chatSlice";
import marketplaceReducer, {
  MARKETPLACE_REDUCER_NAME
} from "../features/marketplace/slice/marketplaceSlice";
import knowledgeReducer, {
  KNOWLEDGE_REDUCER_NAME
} from "../features/knowledge/slice/knowledgeSlice";

const layoutReducer = combineSlices({
  [SIDEBAR_REDUCER_NAME]: sidebarReducer,
  [ALERT_MESSAGE_REDUCER_NAME]: alertMessageReducer,
  [APP_WARNING_REDUCER_NAME]: appWarningReducer
});

const store = configureStore({
  reducer: {
    [HOME_REDUCER_NAME]: homeReducer,
    [WORKFLOW_BUILDER_REDUCER_NAME]: workflowBuilderReducer,
    [WORKSPACE_REDUCER_NAME]: workspaceReducer,
    [AUTH_REDUCER_NAME]: authReducer,
    [TOOL_INFO_DRAWER_REDUCER_NAME]: toolInfoDrawerReducer,
    [auth0API.reducerPath]: auth0API.reducer,
    [authenticatedApi.reducerPath]: authenticatedApi.reducer,
    [externalApi.reducerPath]: externalApi.reducer,
    [CHAT_REDUCER_NAME]: chatReducer,
    [MARKETPLACE_REDUCER_NAME]: marketplaceReducer,
    [KNOWLEDGE_REDUCER_NAME]: knowledgeReducer,
    layout: layoutReducer,
    navigation: navigationReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      auth0API.middleware,
      authenticatedApi.middleware,
      externalApi.middleware,
      rtkQueryErrorLogger,
      rtkQueryErrorNavigation
    )
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
