import React from "react";
import { ThemeProvider } from "@mui/material";
import CommonRightSideContainer from "../../marketplace/CommonTopHeader";
import SnippetFormCard from "./SnippetFormCard";
import SnippetBreadcrumbs from "./SnippetBreadcrumbs";
import CenterContentContainer from "../../../utilities/layout/CenterContentContainer";
import knowledgeTheme from "../theme/knowledgeTheme";

function SnippetFormPage() {
  return (
    <ThemeProvider theme={knowledgeTheme}>
      <CommonRightSideContainer title="Knowledge">
        <CenterContentContainer>
          <SnippetBreadcrumbs />
          <SnippetFormCard />
        </CenterContentContainer>
      </CommonRightSideContainer>
    </ThemeProvider>
  );
}

export default SnippetFormPage;
