import React from "react";
import ToolRunningIndicator from "./ToolRunningIndicator";
import { Button } from "@mui/material";

function ToolRunningActions({ abort }: { abort: () => void }) {
  return (
    <>
      <ToolRunningIndicator />
      <Button variant="outlined" onClick={abort}>
        Stop generating
      </Button>
    </>
  );
}

export default ToolRunningActions;
