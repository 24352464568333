import type { TWorkspaceDataToSave } from "@sharedTypes";
import { useFormContext } from "react-hook-form";
import {
  WORKSPACE_WORKFLOW_COMPONENT_ID_FIELD_LABEL,
  WORKSPACE_WORKFLOW_ID_FIELD_LABEL,
  WORKSPACE_WORKFLOW_PROGRESS_FIELD_LABEL
} from "../workspaceConstants";
import { v4 as uuidv4 } from "uuid";

const useSetSelectedWorkspaceWorkflow = () => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();

  const setSelectedWorkflow = (workflowId: string | null) => {
    setValue(WORKSPACE_WORKFLOW_ID_FIELD_LABEL, workflowId || "", {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
    setValue(
      WORKSPACE_WORKFLOW_COMPONENT_ID_FIELD_LABEL,
      workflowId ? uuidv4() : "",
      {
        // we want each tool instance to have a unique component id so on recalls, it doesn't put all the assets on the workspace
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      }
    );
    setValue(
      WORKSPACE_WORKFLOW_PROGRESS_FIELD_LABEL,
      {},
      {
        // we want each tool instance to have a unique component id so on recalls, it doesn't put all the assets on the workspace
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      }
    );
  };
  return setSelectedWorkflow;
};

export default useSetSelectedWorkspaceWorkflow;
