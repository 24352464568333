import type { TMarketplaceEntity } from "@sharedTypes";
import React, { FC } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import Typography from "@mui/material/Typography";
import { Box, Divider } from "@mui/material";

import MarketplaceCardHeader from "../card/MarketplaceCardHeader";
import MarketplaceCardSubHeader from "../card/MarketplaceCardSubHeader";

import CopyMarketplaceEntityLink from "../CopyMarketplaceEntityLink";
import DuplicateMarketplaceEntity from "../DuplicateMarketplaceEntity";
import MarketplaceBlockTags from "../card/MarketplaceBlockTags";
import styles from "./marketplaceCard.module.css";

interface IMarketplaceCardProps {
  entity: TMarketplaceEntity;
  onClick: (e: React.MouseEvent) => void;
}
const MarketplaceCard: FC<IMarketplaceCardProps> = ({ entity, onClick }) => {
  return (
    <Card className={styles["marketplace-card"]} onClick={onClick}>
      <CardHeader
        title={<MarketplaceCardHeader entity={entity} />}
        subheader={<MarketplaceCardSubHeader entity={entity} />}
      />
      <CardContent className="p-b-0px p-t-8px h-100px">
        <Box className="h-50px">
          <Typography
            variant="body2"
            color="text.secondary"
            paragraph
            className="m-0px oflow-two-lines"
          >
            {entity.description}
          </Typography>
        </Box>
        <MarketplaceBlockTags
          blockLabels={entity.tag?.blocks || []}
          className="h-40px"
        />
        <Divider className="m-t-10px" />
      </CardContent>

      <CardActions
        disableSpacing
        className="flex justify-space-between"
        sx={{ color: "text.disabled" }}
      >
        <div />
        <div>
          <CopyMarketplaceEntityLink
            entityId={entity.id}
            entityType={entity.type}
          />
          <DuplicateMarketplaceEntity
            entityId={entity.id}
            entityType={entity.type}
          />
        </div>
      </CardActions>
    </Card>
  );
};

export default MarketplaceCard;
