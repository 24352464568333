import React from "react";

import useGetSelectedWorkspaceToolComponentId from "../../hooks/useGetSelectedWorkspaceToolComponentId";
import useHandleCloseEditorToolCardWorkspace from "../../../../workspace/hooks/useHandleCloseEditorToolCardWorkspace";
import useGetUserInput from "../../hooks/useGetUserInput";
import EditorToolCardContainerInnerWorkspace from "./EditorToolCardContainerInnerWorkspace";
import RunInBulkButton from "./RunInBulkButton";

function EditorToolCardContainerWorkspace() {
  const { userInput, setUserInput } = useGetUserInput();

  // this will mess up if we call the same tool again
  // right now it overwrites the asset
  const componentId = useGetSelectedWorkspaceToolComponentId();

  const handleClose = useHandleCloseEditorToolCardWorkspace();

  return (
    <EditorToolCardContainerInnerWorkspace
      componentId={componentId}
      userInput={userInput}
      handleClose={handleClose}
      setUserInput={setUserInput}
      additionalActions={<RunInBulkButton />}
    />
  );
}

export default EditorToolCardContainerWorkspace;
