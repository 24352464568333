import { useEffect, useRef, useState } from "react";

function useGetElementHeight() {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const updateHeight = () => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  };

  useEffect(() => {
    updateHeight(); // Set the initial height

    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  useEffect(() => {
    updateHeight();
  }, [ref.current]);

  return { ref, height };
}

export default useGetElementHeight;
