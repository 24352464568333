import { useEffect } from "react";
import useGetSelectedStepToolId from "../runTool/hooks/useGetSelectedStepToolId";
import useGetToolFromQuery from "../../../../../utilities/toolSelector/hooks/useGetToolFromQuery";
import useGetSelectedStep from "./useGetSelectedStep";
import useGetWorkflowStepNameFieldById from "./useGetWorkflowStepNameFieldById";
import { useFormContext } from "react-hook-form";
import type { TWorkflowBuilderForm } from "@sharedTypes";

const useSetDefaultNameOnToolChange = () => {
  const toolId = useGetSelectedStepToolId();
  const tool = useGetToolFromQuery(toolId);
  const step = useGetSelectedStep();
  const fieldLabel = useGetWorkflowStepNameFieldById(step?.id || "");
  const { setValue, getFieldState, register } =
    useFormContext<TWorkflowBuilderForm>();

  useEffect(() => {
    // we need the && step because sometimes the step is undefined which
    // messes things up
    if (!getFieldState(fieldLabel).isTouched && step) {
      register(fieldLabel);
      setValue(fieldLabel, step?.name || tool?.name || "", {
        shouldValidate: true,
        shouldDirty: false
      });
    }
  }, [tool?._id]);
};

export default useSetDefaultNameOnToolChange;
