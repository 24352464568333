import React from "react";
import { useDuplicateObject } from "../../../utilities/hooks/useDuplicateObject";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SettingsMenuItemWithClose from "../../../utilities/dropdowns/generic/SettingsMenuItemWithClose";
import { WORKSPACE } from "../../../utilities/constants/constants";

// Note - only use this for personal tools
// because we are passing the isPersonal flag as true
// to useDuplicateObject so that you can copy private
// tools
function DuplicateWorkspaceMenuItem({
  id,
  useLastSaveText
}: {
  id: string;
  useLastSaveText?: boolean;
}) {
  const { loading, duplicateObject } = useDuplicateObject();

  // this is hack for now -> we should save and duplicate
  const duplicate = () => {
    duplicateObject(id, WORKSPACE);
  };

  return (
    <SettingsMenuItemWithClose
      disabled={loading}
      hide={!id}
      action={duplicate}
      hotkey="Meta+D"
      text={`Duplicate${useLastSaveText ? " last save" : ""}`}
      icon={<FileCopyIcon fontSize="small" />}
    />
  );
}

export default DuplicateWorkspaceMenuItem;
