import type { RowUserInputDictType } from "@sharedTypes";
import type { MRT_Row } from "material-react-table";
import { useSheetContext } from "../../SheetContext";
import { TColumnMap } from "../../sheetTypes";
import useRunToolFromSheet from "./useRunToolFromSheet";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../../../../../../stores/actions";

const MAX_RUN_NUMBER = 100;

const useHandleRunMultipleRows = ({ columnMap }: { columnMap: TColumnMap }) => {
  const runToolFromSheet = useRunToolFromSheet({ columnMap });
  const { setSheetData, selectedTool, sheetDataRef, setRowsRunning } =
    useSheetContext();
  const reduxDispatch = useDispatch();
  const handleRunMultipleRows = (rows: MRT_Row<RowUserInputDictType>[]) => {
    if (!selectedTool) {
      return;
    }
    if (rows.length > MAX_RUN_NUMBER) {
      reduxDispatch(
        setErrorMessage(`You can only run ${MAX_RUN_NUMBER} rows at a time.`)
      );
      return;
    }
    setRowsRunning(true);
    setTimeout(() => {
      const currentSheetData = [...sheetDataRef.current];
      rows.map((row) => (currentSheetData[row.index].rowLoading = true));
      setSheetData(currentSheetData);

      rows.map((row) => {
        runToolFromSheet(row);
      });
    }, 0);
  };
  return handleRunMultipleRows;
};

export default useHandleRunMultipleRows;
