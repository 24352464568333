import { IconButton, type SxProps } from "@mui/material";
import React from "react";
import { useRichTextEditorContext } from "mui-tiptap";
import { Add as AddIcon } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { getLocationTrackProperties } from "./functions/getLocationTrackProperties";
import { useTrackEventMutation } from "../ToolflowAPI/rtkRoutes/trackEventApi";

function InsertImageBlobButton({
  sx,
  url,
  tvrId = ""
}: Readonly<{
  sx?: SxProps;
  url: string;
  tvrId?: string;
}>) {
  const editor = useRichTextEditorContext();
  const [trackEvent] = useTrackEventMutation();
  const location = useLocation();

  const handleCopyClick = async (imageUrl: string) => {
    if (editor) {
      const { selection } = editor.state;
      const position = selection.$to.pos; // get the current position

      // Create an image node
      const imageNode = editor.schema.nodes.image.create({ src: imageUrl });

      // Create a new transaction and add the image node at the specified position
      const tr = editor.state.tr.insert(position, imageNode);

      // Apply the transaction to move to the new state
      editor.view.dispatch(tr);
      trackEvent({
        eventString: "Used output",
        properties: {
          ...getLocationTrackProperties(location.pathname),
          output_type: "image",
          action_outcome_type: "add_tool_output_to_workspace"
        },
        toolVersionResponseId: tvrId
      });
    }
  };
  return (
    <IconButton sx={sx} onClick={() => handleCopyClick(url)}>
      <AddIcon />
    </IconButton>
  );
}

export default InsertImageBlobButton;
