import React from "react";
import { Typography } from "@mui/material";
import FileTable from "../utilities/FileTable";
import CircularLoading from "../utilities/CircularLoading";
import { useGetFilesQuery } from "../ToolflowAPI/rtkRoutes/fileApi";
import { useSelector } from "react-redux";
import { selectAllFiles } from "../ToolflowAPI/rtkRoutes/selectors/fileSelectors";

function ManageFiles() {
  const { isLoading } = useGetFilesQuery();
  const files = useSelector(selectAllFiles);

  if (isLoading) return <CircularLoading />;

  return (
    <>
      {files && files.length > 0 ? (
        <FileTable files={files} />
      ) : (
        <Typography>No files uploaded</Typography>
      )}
    </>
  );
}

export default ManageFiles;
