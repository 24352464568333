import { type AutocompleteRenderInputParams } from "@mui/material";
import React from "react";
import IconTextField from "../../features/layout/search/SearchBox";
import { RiSearchLine } from "@remixicon/react";

function ToolSelectorInput({
  params,
  placeholder,
  onClear
}: {
  params: AutocompleteRenderInputParams;
  placeholder: string;
  onClear?: () => void;
}) {
  const [focus, setFocus] = React.useState(false);
  return (
    <IconTextField
      iconStart={<RiSearchLine color="primary" />}
      focus={focus}
      setFocus={setFocus}
      placeholder={placeholder}
      params={{ ...params }}
      onClear={onClear}
    />
  );
}

export default ToolSelectorInput;
