import { blockDictConstants } from "../../blocks/types/blockDictTypes";
import type { TSerpOutputAllowedValuesDict, TSerpSettings } from "@sharedTypes";

export const serpBlockOutputAllowedValuesDict: TSerpOutputAllowedValuesDict = {
  peopleAlsoAsk: true,
  relatedQueries: true,
  organicResults: true,
  paidResults: true,
  resultOptions: {
    title: true,
    url: true,
    description: true,
    displayedUrl: true,
    emphasizedKeywords: true,
    siteLinks: true
  }
};
export const serpSettingsInitialState: {
  settings: TSerpSettings;
} = {
  settings: {
    countryCode: "us",
    languageCode: "en",
    maxPagesPerQuery: 1,
    queries: [],
    userQueriesFieldKey: "",
    resultsPerPage: 10,
    allowedOutputs: serpBlockOutputAllowedValuesDict
  }
};
export const serpInitialState = {
  type: blockDictConstants.Serp.type,
  label: blockDictConstants.Serp.label
};
