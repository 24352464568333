import { Close } from "@mui/icons-material";
import { Button, IconButton, TextField } from "@mui/material";
import type {
  CustomToolInputField,
  CustomToolInputFieldWithOptions
} from "@sharedTypes";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { isInputWithOptions } from "../utilities/Inputs/constants";

function DeleteOptionButton({
  setNewField,
  newField,
  index
}: {
  index: number;
  newField: CustomToolInputFieldWithOptions;
  setNewField: (value: React.SetStateAction<CustomToolInputField>) => void;
}) {
  const handleDeleteOption = () => {
    const options = newField.options;
    if (options) {
      const updatedOptions = [
        ...options.slice(0, index),
        ...options.slice(index + 1)
      ];
      newField.options = updatedOptions;
      setNewField({ ...newField });
    }
  };
  return (
    <IconButton onClick={handleDeleteOption}>
      <Close />
    </IconButton>
  );
}

function UpdateOptionsTextField({
  option,
  setNewField,
  newField,
  index
}: {
  option: string;
  index: number;
  newField: CustomToolInputFieldWithOptions;
  setNewField: (value: React.SetStateAction<CustomToolInputField>) => void;
}) {
  const handleOptionChange = (value: string) => {
    const options = newField.options;
    if (options) {
      const updatedOptions = [...options];
      updatedOptions[index] = value;
      newField.options = updatedOptions;
      setNewField({ ...newField });
    }
  };

  return (
    <TextField
      key={uuidv4()}
      fullWidth
      size="small"
      margin="dense"
      label="Option"
      value={option}
      InputProps={{
        endAdornment: newField.options && newField.options.length !== 1 && (
          <DeleteOptionButton
            setNewField={setNewField}
            newField={newField}
            index={index}
          />
        )
      }}
      onChange={(e) => handleOptionChange(e.target.value)}
    />
  );
}

function AddOptionButton({
  newField,
  setNewField
}: {
  newField: CustomToolInputFieldWithOptions;
  setNewField: (value: React.SetStateAction<CustomToolInputField>) => void;
}) {
  const handleAddOption = () => {
    const options = newField.options;
    if (options) {
      const updatedOptions = [...options, ""];
      newField.options = updatedOptions;
      setNewField({ ...newField });
    }
  };
  return <Button onClick={handleAddOption}>Add Option</Button>;
}

function InputOptionsFields({
  newField,
  setNewField
}: {
  newField: CustomToolInputField;
  setNewField: (value: React.SetStateAction<CustomToolInputField>) => void;
}) {
  if (!isInputWithOptions(newField)) {
    return null;
  }
  return (
    <>
      {newField.options.map((option, index) => (
        <UpdateOptionsTextField
          key={index}
          option={option}
          index={index}
          setNewField={setNewField}
          newField={newField}
        />
      ))}
      <AddOptionButton setNewField={setNewField} newField={newField} />
    </>
  );
}

export default InputOptionsFields;
