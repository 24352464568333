import { Box } from "@mui/material";
import React from "react";
import AssetIndicator from "./AssetIndicator";

import { parsedClassNames } from "../../../../utils/parsedClassNames";
import DeleteAssetButtonFromList from "../../../deleteAssetButtons/DeleteAssetButtonFromList";
import EditAssetNameButton from "./EditAssetNameButton";
import styles from "./assetListItem.module.css";
import AssetText from "./assetText";
import useAssetListItem from "./hooks/useAssetListItem";

const cx = parsedClassNames.bind(styles);

const AssetListItem = () => {
  const { isLastButton, isSelected, loading, assetText, assetId, onClick } =
    useAssetListItem();
  return (
    <Box
      className={cx(
        "parent-hover m-t-8px p-8px flex cursor-pointer pos-relative align-i-center",
        styles["asset-button-wrapper"],
        {
          "m-b-8px": !isLastButton,
          "bg-color-white": isSelected
        }
      )}
      onClick={onClick}
    >
      <div className={styles["asset-indicator-width"]}>
        <AssetIndicator loading={loading} />
      </div>
      <AssetText text={assetText} />
      <div className={cx("pos-absolute show-on-hover", styles.wrapper)}>
        <EditAssetNameButton id={assetId} className={styles["m-r-2px"]} />
        <DeleteAssetButtonFromList assetId={assetId} />
      </div>
    </Box>
  );
};

export default AssetListItem;
