import type {
  CustomToolOutputField,
  CustomToolInputField,
  TBlock
} from "@sharedTypes";
import { SELECT } from "../utilities/Inputs/inputConstants";

function validateSaveFields(
  tags: CustomToolInputField[],
  nodes: TBlock[],
  outputFields: CustomToolOutputField[]
): string | null {
  // nodes.length === 3 for input, output, parent blocks
  if (nodes.length === 3) {
    return "To save a tool, you need at least one block";
  }
  if (outputFields.length === 0) {
    return "You must have at least one output field";
  }

  const uniqueNames = new Set<string>();

  for (const tag of tags) {
    // Check if the name is empty
    if (tag.name === "") {
      return "Field name cannot be empty";
    }

    // Check if the name is unique
    if (uniqueNames.has(tag.name)) {
      return `The tag name ${tag.name} is already in use.`;
    } else {
      uniqueNames.add(tag.name);
    }

    // Check if the type is SELECT and the options array is of length at least one
    if (tag.type === SELECT && (!tag.options || tag.options.length < 1)) {
      return "Dropdowns must have at least one option";
    }
  }

  const seenLabels = new Set();

  for (let i = 0; i < nodes.length; i++) {
    const block = nodes[i];
    const blockDetails = `Check your ${block.data.label} block. `;
    if (
      block.data.blockMessage &&
      block.data.blockMessage.severity === "error"
    ) {
      return `${blockDetails}${block.data.blockMessage.message}`;
    }

    const label = block.data?.label;
    if (label) {
      if (seenLabels.has(label)) {
        return `Labels have to be unique. Check label: ${label}`;
      } else {
        if (block.type === "toolWithinToolBlockNode") {
          block.data.tool.main.toolOutputFields.forEach(
            (f: CustomToolOutputField) =>
              seenLabels.add(`${block.data.label} - ${f.name}`)
          );
        } else {
          seenLabels.add(label);
        }
      }
    }
  }

  const combinedSet = new Set([...seenLabels, ...uniqueNames]);

  if (!outputFields.every((field) => combinedSet.has(field.name))) {
    return `Check your outputs. All outputs have to be mapped`;
  }

  // If no errors were found, return null
  return null;
}

export default validateSaveFields;
