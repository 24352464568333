import { useCanRunAssistantQuery } from "../../../../../../ToolflowAPI/rtkRoutes/assistantApi";
import { useAuth0Store } from "../../../../../../hooks/useAuth0Store";

export const useCanRunAssistant = () => {
  const { isAuthenticated } = useAuth0Store();
  const { data, isFetching } = useCanRunAssistantQuery(undefined, {
    skip: !isAuthenticated
  });
  return {
    canRunAssistant: data?.result?.canRunAssistant ?? true,
    isFetching
  };
};
