import { useFormContext } from "react-hook-form";
import type { TWorkspaceDataToSave } from "@sharedTypes";
import { WORKSPACE_NAME_FIELD_LABEL } from "../workspaceConstants";

const useWorkspaceName = () => {
  const { watch } = useFormContext<TWorkspaceDataToSave>();
  return watch(WORKSPACE_NAME_FIELD_LABEL) || "";
};

export default useWorkspaceName;
