import React from "react";
import Guidelines from "../../../utilities/Guidelines";
import Privacy from "../../../utilities/Privacy";
import TermsOfService from "../../../utilities/TermsOfService";
import TermsSeperator from "./TermsSeparator";

function TermsOfServiceSidebar() {
  return (
    <div className="flex">
      <TermsOfService typographyClassName="grey" />
      <TermsSeperator />
      <Privacy typographyClassName="grey" />
      <TermsSeperator />
      <Guidelines typographyClassName="grey" />
    </div>
  );
}

export default TermsOfServiceSidebar;
