import { Typography } from "@mui/material";
import React from "react";

const DragToMoveTooltip = ({
  toolStillLoading
}: {
  toolStillLoading?: boolean;
}) => {
  return (
    <div>
      {!toolStillLoading && (
        <Typography variant="caption">
          <strong className="white">Drag</strong>
          <span style={{ opacity: 0.7 }}> to move</span>
        </Typography>
      )}
      <Typography variant="caption">
        <strong className="white" style={{ opacity: 1 }}>
          Click
        </strong>
        <span style={{ opacity: 0.7 }}> to open menu</span>
      </Typography>
    </div>
  );
};

export default DragToMoveTooltip;
