import type { TWorkflowBuilderForm } from "@sharedTypes";
import { useFormContext } from "react-hook-form";
import { WORKFLOW_NAME_FIELD_LABEL } from "../WorkflowBuilderConstants";

function useGetWorkflowName() {
  const { watch } = useFormContext<TWorkflowBuilderForm>();
  return watch(WORKFLOW_NAME_FIELD_LABEL);
}

export default useGetWorkflowName;
