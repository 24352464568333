import React from "react";
import ToolSelector from "../../../../utilities/toolSelector/ToolSelector";
import useSetSelectedWorkspaceTool from "../../hooks/useSetSelectedTool";
import useSetSelectedWorkspaceWorkflow from "../../hooks/useSetSelectedWorkflow";

function WorkspaceToolSelectorContainer() {
  const setSelectedTool = useSetSelectedWorkspaceTool();
  const setSelectedWorkflow = useSetSelectedWorkspaceWorkflow();
  return (
    <ToolSelector
      margin="normal"
      setSelectedTool={setSelectedTool}
      label="Tool or workflow"
      placeholder="Search for a tool or workflow"
      setSelectedWorkflow={setSelectedWorkflow}
    />
  );
}

export default WorkspaceToolSelectorContainer;
