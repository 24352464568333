import useFavoriteContext from "./useFavoriteContext";
import { useDeleteFavoriteObjectMutation } from "../../ToolflowAPI/rtkRoutes/favoritesApi";
import useGetInvalidateCacheIdForFavorites from "./useGetInvalidateCacheIdForFavorites";

const useUnfavoriteObject = () => {
  const [deleteFavoriteObject] = useDeleteFavoriteObjectMutation();
  const { id, type } = useFavoriteContext();
  const getInvalidateCacheId = useGetInvalidateCacheIdForFavorites(type);

  const unFavorite = (e: React.MouseEvent) => {
    // we do this so it doesn't trigger the navigation
    // on a link button
    const invalidateCacheId = getInvalidateCacheId();
    e.stopPropagation();
    if (!id) return;
    deleteFavoriteObject({
      id,
      type,
      invalidateCacheId
    });
  };

  return unFavorite;
};

export default useUnfavoriteObject;
