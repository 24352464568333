import React, { useEffect, useState } from "react";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import FormControl, { type FormControlProps } from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import type { TToolVersion } from "@sharedTypes";
import { DateTime } from "luxon";
import type { SelectChangeEvent } from "@mui/material/Select";

function ToolVersionDropdown({
  action,
  value,
  updatedLabel,
  margin = "none"
}: {
  action: (val: string) => void;
  value: string;
  updatedLabel?: string;
  margin?: FormControlProps["margin"];
}) {
  const { state } = useToolbuilderContext();
  const [options, setOptions] = useState<TToolVersion[]>([]);

  const handleEvent = (event: SelectChangeEvent<string>) => {
    action(event.target.value);
  };

  useEffect(() => {
    setOptions(Object.values(state.toolVersions));
  }, [state.toolVersions]);

  if (options.length === 0) {
    return <div></div>;
  }

  // Function to render the selected value without the caption typography
  const renderSelectedValue = (selectedValue: string) => {
    const selectedItem = options.find(
      (element) => element._id === selectedValue
    );
    if (selectedItem) {
      return (
        <Typography className="m-r-16px">
          {" "}
          {DateTime.fromSeconds(selectedItem.updatedAt).toLocaleString(
            DateTime.DATETIME_MED
          )}
        </Typography>
      );
    }
    return <Typography className="m-r-16px">Not found</Typography>;
  };

  return (
    <FormControl margin={margin}>
      <InputLabel shrink id="demo-simple-select-label">
        {updatedLabel ? updatedLabel : `Version`}
      </InputLabel>
      <Select
        labelId="tool-version-label"
        id="tool-version"
        value={value}
        label={updatedLabel ? updatedLabel : `Version`}
        onChange={handleEvent}
        size="small"
        notched
        renderValue={renderSelectedValue}
      >
        {options.map((element) => (
          <MenuItem
            value={element._id}
            key={element._id}
            className="justify-space-between flex"
          >
            <Typography variant="caption">
              {DateTime.fromSeconds(element.updatedAt).toLocaleString(
                DateTime.DATETIME_MED
              )}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ToolVersionDropdown;
