import type { TPreviousField } from "@sharedTypes";

const serializePreviousField = (
  previousField: TPreviousField | null
): string => {
  if (!previousField) return "";
  const { stepId, fieldId, type } = previousField;
  return `Step:${stepId}Id:${fieldId}Type:${type}`;
};

export default serializePreviousField;
