import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import TextOutputViewerComponent from "./TextOutputViewerComponent";

export default Node.create({
  name: "textOutputComponent",

  // group: "block",
  group: "inline",
  inline: true,
  content: "inline*",
  draggable: true,
  selectable: false, // needed so that we don't delete it when typing things if you click the tool

  atom: true,

  addAttributes() {
    return {
      inputId: {
        default: 0
      },
      loading: {
        default: false
      },
      componentId: {
        default: 0
      },
      tvrId: {
        default: ""
      },
      textValue: {
        default: ""
      },
      name: {
        default: ""
      }
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "tip-tap-text-output-component",
      mergeAttributes(HTMLAttributes, { "data-type": "draggable-item" })
    ];
  },
  renderText({ node }) {
    return node.attrs.textValue;
  },

  addNodeView() {
    return ReactNodeViewRenderer(TextOutputViewerComponent);
  }
});
