import React from "react";
import type { BlockDataTypes, TBlock } from "@sharedTypes";
import blockHeaderIconDict from "./blockHeaderIconDict";
import DraggableBlock from "./DraggableBlock";

type Item = Omit<TBlock, "id" | "position" | "data"> & { data: BlockDataTypes };

function DraggableBlockContainer({
  title,
  item,
  open
}: {
  title: string;
  item: Item;
  open: boolean;
}) {
  const onDragStart: React.DragEventHandler<HTMLDivElement> = (
    event: React.DragEvent<HTMLDivElement>
  ) => {
    event.dataTransfer.setData("application/reactflow", JSON.stringify(item));
    event.dataTransfer.effectAllowed = "move";
  };

  const icon = blockHeaderIconDict[item.data.type];

  return (
    <DraggableBlock
      title={title}
      open={open}
      onDragStart={onDragStart}
      icon={icon}
    />
  );
}
export default DraggableBlockContainer;
