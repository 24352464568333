import { useGetWorkflowQuery } from "../../../ToolflowAPI/rtkRoutes/workflowApi";
import { useAuth0Store } from "../../../hooks/useAuth0Store";

const useGetWorkflowFromQuery = (workflowId?: string) => {
  const { isLoading } = useAuth0Store();
  const { data } = useGetWorkflowQuery(workflowId || "", {
    skip: !workflowId || isLoading
  });
  if (!workflowId) return undefined;
  return data?.workflow;
};

export default useGetWorkflowFromQuery;
