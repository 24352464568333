import { useInputContext } from "../../../../../../../../utilities/contexts/InputContext";
import useSetVisibleAsset from "../../../../../../leftSideDrawer/assetsTab/hooks/useSetVisibleAsset";
import useGetAssetId from "../../../../../hooks/useGetAssetId";
import useIsLastAsset from "../../../hooks/useIsLastAsset";

const useAssetListItem = () => {
  const { input } = useInputContext();
  const setVisibleAsset = useSetVisibleAsset();
  const selectedAsset = useGetAssetId();
  const isLastButton = useIsLastAsset(input.id);
  return {
    isLastButton,
    isSelected: selectedAsset === input.id,
    loading: !!input.loading,
    assetText: input.name,
    assetId: input.id,
    onClick: () => setVisibleAsset(input.id)
  };
};

export default useAssetListItem;
