import { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import { useToolbuilderContext } from "../context/ToolBuilderContext";

const useCurrentAndPreviousBlocksSync = () => {
  const { state } = useToolbuilderContext();
  const { blocks: blocks } = state.currentState;
  const [prevBlocks, setPrevBlocks] = useState(blocks);
  useEffect(() => {
    // Perform deep equality check
    if (!isEqual(blocks, prevBlocks)) {
      // Nodes have changed, trigger re-render
      // Update the previous blocks value
      setPrevBlocks(blocks);
    }
  }, [blocks]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useCurrentAndPreviousBlocksSync;
