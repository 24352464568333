import React, { useEffect } from "react";
import useGetCurrentUser from "../../../../hooks/useGetCurrentUser";
import useToolflowNavigate from "../../../../utilities/hooks/useToolflowNavigate";
import { PROFILE_BASE_ROUTE_PATH } from "../../../../navigation/routePaths";
import TeamSettingsForm from "./TeamSettingsForm";
import OrganizationMembersCard from "../../../../settings/OrganizationMembersCard";

const TeamSettings = () => {
  const user = useGetCurrentUser();
  const navigate = useToolflowNavigate();
  useEffect(() => {
    if (user && !user?.organizationId) {
      navigate(PROFILE_BASE_ROUTE_PATH);
    }
  }, [user]);

  return (
    <div>
      <TeamSettingsForm />
      <OrganizationMembersCard />
    </div>
  );
};

export default TeamSettings;
