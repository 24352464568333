import React from "react";
import { Icon } from "@mui/material";
import Logo from "../assets/images/ToolflowIcon.png";

function ToolflowLogo({
  className = ""
}: {
  width?: number;
  className?: string;
}) {
  return (
    <div className={`flex align-i-center justify-center ${className}`}>
      <Icon className="flex h-100-percent w-100-percent">
        <img src={Logo} height={24} />
      </Icon>
    </div>
  );
}

export default ToolflowLogo;
