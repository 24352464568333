import React from "react";
import { useSetSecurityFunctions } from "../ToolflowAPI/security";
import { DndProvider } from "react-dnd";
import { Provider } from "react-redux";
import Auth0StoreWrapper from "./Auth0StoreWrapper";
import store from "../stores/store";
import { HTML5Backend } from "react-dnd-html5-backend";
import { WebsocketContextComponent } from "../contexts/WebsocketContext";
import appTheme from "../globalTheme/muiUtils/appTheme";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import router from "./router";

export const Providers = () => {
  useSetSecurityFunctions();

  return (
    <DndProvider backend={HTML5Backend}>
      <Provider store={store}>
        <Auth0StoreWrapper>
          <WebsocketContextComponent>
            <ThemeProvider theme={appTheme}>
              <RouterProvider router={router} />
            </ThemeProvider>
          </WebsocketContextComponent>
        </Auth0StoreWrapper>
      </Provider>
    </DndProvider>
  );
};
