import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import { selectMarketplaceEntitiesByFilters } from "../../../ToolflowAPI/rtkRoutes/selectors/toolsSelectors";
import type {
  TMarketplaceEntity,
  TMarketplaceFilterByCategoriesFE
} from "@sharedTypes";

import { memoize } from "lodash";

const memoizedSelectMarketplaceEntitiesByFilters = memoize(
  selectMarketplaceEntitiesByFilters
);

const useMarketplaceEntities = ({
  fromSearch = false
}: {
  fromSearch?: boolean;
}) => {
  const selectedCategory = useSelector(
    (state: RootState) => state.marketplace.category
  );
  const selectedUseCases = useSelector(
    (state: RootState) => state.marketplace.useCases
  );

  const memoizedParams = useMemo(
    () => ({ selectedCategory, selectedUseCases }),
    [selectedCategory, selectedUseCases]
  );

  const filteredMarketplaceEntities = useSelector((state: RootState) =>
    memoizedSelectMarketplaceEntitiesByFilters(memoizedParams)(state)
  );

  const memoizedFilters = useMemo(
    () => ({
      selectedCategory: "all" as TMarketplaceFilterByCategoriesFE,
      selectedUseCases: []
    }),
    []
  );

  const allMarketplaceEntities = useSelector(
    memoizedSelectMarketplaceEntitiesByFilters(memoizedFilters)
  );

  const memoizedEntities = useMemo<TMarketplaceEntity[]>(
    () => filteredMarketplaceEntities,
    [filteredMarketplaceEntities, selectedCategory, selectedUseCases]
  );
  return {
    filteredMarketplaceEntities: fromSearch
      ? allMarketplaceEntities
      : memoizedEntities
  };
};

export default useMarketplaceEntities;
