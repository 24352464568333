import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsMenuItem from "../generic/SettingsMenuItem";
import { useDeleteToolVersionResponseMutation } from "../../../ToolflowAPI/rtkRoutes/toolVersionResponseApi";

function DeleteToolVersionResponseMenuItem({ id }: { id: string }) {
  const [deleteToolVersionResponse, { isLoading }] =
    useDeleteToolVersionResponseMutation();

  return (
    <SettingsMenuItem
      action={() => deleteToolVersionResponse(id)}
      text="Delete Response"
      hide={!id}
      hotkey="Del"
      disabled={isLoading}
      icon={<DeleteIcon fontSize="small" />}
    />
  );
}

export default DeleteToolVersionResponseMenuItem;
