import type { CustomToolOutputField, TBlock, Edge } from "@sharedTypes";
import { COPYABLE_IMAGE } from "../../../utilities/Inputs/outputConstants";

const getPrecedingNodesRecursive = (
  nodes: TBlock[],
  nId: string,
  nodeEdges: Edge[],
  visitedNodes: Set<string>,
  precedingNodes: TBlock[],
  currentFields: Set<string>
) => {
  if (visitedNodes.has(nId)) {
    return;
  }

  visitedNodes.add(nId);

  const incomingEdges = nodeEdges.filter((edge) => edge.target === nId);
  incomingEdges.forEach((edge) => {
    const sourceNode = nodes.find((node) => node.id === edge.source);
    if (sourceNode) {
      precedingNodes.push(sourceNode);

      if (sourceNode.data && typeof sourceNode.data.label === "string") {
        if (sourceNode.data.type === "toolWithinTool") {
          sourceNode.data.tool.main.toolOutputFields.forEach(
            (field: CustomToolOutputField) => {
              if (field.type !== COPYABLE_IMAGE) {
                currentFields.add(`${sourceNode.data.label} - ${field.name}`);
              }
            }
          );
        } else {
          currentFields.add(sourceNode.data.label);
        }
      }

      getPrecedingNodesRecursive(
        nodes,
        sourceNode.id,
        nodeEdges,
        visitedNodes,
        precedingNodes,
        currentFields
      );
    }
  });
};

export default getPrecedingNodesRecursive;
