import React from "react";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/icons-material/Link";
import { useHandleCopy } from "./hooks/useHandleCopy";
import { Button } from "@mui/material";
import type { CopyTypes } from "@sharedTypes";

function CopyLinkButton({
  id,
  className = "",
  disabled,
  text,
  type,
  buttonText = "Copy Link"
}: {
  id?: string;
  className?: string;
  disabled?: boolean;
  text?: boolean;
  type: CopyTypes;
  buttonText?: string;
}) {
  const handleCopy = useHandleCopy(id || "", type);

  const copy = (e: React.MouseEvent) => {
    e.stopPropagation(); // necessary for ContentActionArea on ProfileToolCard
    handleCopy();
  };

  if (!id) {
    return null;
  }

  if (text) {
    return (
      <Button
        onClick={copy}
        className={className}
        disabled={disabled}
        size="small"
        variant="text"
        startIcon={<Link fontSize="small" />}
      >
        {buttonText}
      </Button>
    );
  }

  return (
    <IconButton onClick={copy} className={className} disabled={disabled}>
      <Link />
    </IconButton>
  );
}

export default CopyLinkButton;
