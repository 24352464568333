import React, { FC } from "react";
import CommonRightSideContainer from "../marketplace/CommonTopHeader";
import useGetHomePageContent from "./hooks/useGetHomePageContent";
import { Breadcrumbs } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import CenterContentContainer from "../../utilities/layout/CenterContentContainer";

interface IHomePageBaseProps {
  action?: React.ReactNode;
}
const HomePageBase: FC<IHomePageBaseProps> = ({ action }) => {
  const { content, nestedRouteLabel, nestedRouteBreadcrumb } =
    useGetHomePageContent();
  return (
    <CommonRightSideContainer title={nestedRouteLabel} action={action}>
      <CenterContentContainer className="m-t-16px">
        <Breadcrumbs
          separator={<ChevronRight fontSize="small" />}
          aria-label="breadcrumb"
          className="p-h-16px"
        >
          {nestedRouteBreadcrumb}
        </Breadcrumbs>
        {content}
      </CenterContentContainer>
    </CommonRightSideContainer>
  );
};

export default HomePageBase;
