import React from "react";
import { FormHelperText } from "@mui/material";
import {
  WORKSPACE_NAME_FIELD_LABEL,
  WORKSPACE_NAME_PLACEHOLDER
} from "./workspaceConstants";
import ErrorContainer from "../../utilities/ErrorContainer";
import TogglePrimaryHeaderTextFieldBase from "../../utilities/TogglePrimaryHeaderTextFieldBase";
import UpdateWorkspaceNameTextField from "./UpdateWorkspaceNameTextField";
import useWorkspaceName from "./hooks/useWorkspaceName";

const ToggleWorkspaceName = () => {
  const workspaceName = useWorkspaceName();

  return (
    <TogglePrimaryHeaderTextFieldBase
      value={workspaceName || WORKSPACE_NAME_PLACEHOLDER}
      errorMessage={
        <FormHelperText error>
          <ErrorContainer fieldName={WORKSPACE_NAME_FIELD_LABEL} />
        </FormHelperText>
      }
    >
      <UpdateWorkspaceNameTextField />
    </TogglePrimaryHeaderTextFieldBase>
  );
};

export default ToggleWorkspaceName;
