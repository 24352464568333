import React, { ReactElement, useState } from "react";
import ApiKeySettings from "./ApiKeySettings";
import {
  FREE_PLAN_FE,
  ORGANIZATION_PLAN_FE,
  useAuthorizedTier
} from "../../../../settings/BillingCard";
import TeamSettings from "./TeamSettings";
import type { TAvailableTiers } from "@sharedTypes";
import { Tab, Tabs } from "@mui/material";
import { useParams } from "react-router-dom";
import TabPanel, { a11yProps } from "../../../../utilities/TabPanel";
import { RiKeyFill, RiTeamLine } from "@remixicon/react";
import { toolflowPrimaryColor } from "../../../../globalTheme/muiUtils/appTheme";

type TTabs = "team" | "apiKeys";

type TTabValues = 0 | 1;

let tabId: TTabValues = 0;
const tabDict: {
  [key in TTabs]: {
    value: TTabValues;
    component: () => React.ReactNode;
    authorized?: (tier: TAvailableTiers) => boolean;
    label: string;
    icon: ReactElement;
    selectIcon: ReactElement;
  };
} = {
  apiKeys: {
    label: "API Keys",
    value: tabId++ as TTabValues,
    component: ApiKeySettings,
    authorized: (tier) => tier === ORGANIZATION_PLAN_FE,
    icon: <RiTeamLine size={16} />,
    selectIcon: <RiTeamLine size={16} color={toolflowPrimaryColor} />
  },
  team: {
    label: "Team",
    value: tabId++ as TTabValues,
    component: TeamSettings,
    authorized: (tier) => tier !== FREE_PLAN_FE,
    icon: <RiKeyFill size={16} />,
    selectIcon: <RiKeyFill size={16} color={toolflowPrimaryColor} />
  }
};
const SettingsPageContent = () => {
  const { tab: paramTab } = useParams<{ tab?: TTabs }>();
  const authorizedTier = useAuthorizedTier();
  const [value, setValue] = useState(paramTab ? tabDict[paramTab]?.value : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: TTabValues) => {
    const targetTab = Object.values(tabDict).find(
      (tab) => tab.value === newValue
    );
    if (
      targetTab &&
      (!targetTab.authorized || targetTab.authorized(authorizedTier))
    ) {
      setValue(newValue);
    }
  };

  const objValues = Object.values(tabDict);
  return (
    <>
      <Tabs value={value} onChange={handleChange} textColor="secondary">
        {objValues.map((tab, tabIndex) => {
          const disabled = tab.authorized && !tab.authorized(authorizedTier);
          return (
            <Tab
              component="div"
              key={tab.value}
              icon={tabIndex === value ? tab.selectIcon : tab.icon}
              label={tab.label}
              disabled={disabled}
              {...a11yProps(tab.value)}
            />
          );
        })}
      </Tabs>
      {objValues.map((tab) => (
        <TabPanel
          value={value}
          key={tab.value}
          index={tab.value}
          className="w-100-percent"
        >
          {tab.component()}
        </TabPanel>
      ))}
    </>
  );
};

export default SettingsPageContent;
