import React from "react";
import { Card, Chip, alpha } from "@mui/material";
import type { EmbeddedStep } from "@sharedTypes";
import NoWrapCardHeader from "../../../../utilities/NoWrapCardHeader";
import ToggleStepNameTextField from "./ToggleStepNameTextField";
import { useSelector } from "react-redux";
import { selectStepId } from "../workflowBuilderSlice";
import UtilityDraggable from "../../../../utilities/draggables/UtilityDraggable";
import UtilityDragHandle from "../../../../utilities/draggables/UtilityDragHandle";
import useGetSteps from "./hooks/useGetSteps";
import { useUtilityDraggableContext } from "../../../../utilities/draggables/UtilityDraggableContext";
import useIsDragging from "../../../../utilities/draggables/hooks/useIsDragging";
import DraggingWrapper from "../../../../utilities/draggables/DraggingWrapper";
import useOpenStepById from "./hooks/useOpenStepById";

function WorkflowDraggableStepInner({
  step,
  dragHandleDisabled
}: {
  step: EmbeddedStep;
  dragHandleDisabled?: boolean;
}) {
  const stepId = useSelector(selectStepId);
  const stepIsSelected = stepId === step?.id;
  const { ref } = useUtilityDraggableContext();
  const isDragging = useIsDragging();
  const openStep = useOpenStepById(step.id);

  return (
    <DraggingWrapper
      preview={<Chip label={step.name} variant="outlined" size="small" />}
      gap="16px"
    >
      <Card
        ref={ref}
        className="m-v-16px cursor-pointer"
        id={stepIsSelected ? "dontCloseOnClick" : undefined}
        sx={{
          backgroundColor: (theme) =>
            stepIsSelected ? alpha(theme.palette.primary.light, 0.3) : undefined
        }}
        onClick={openStep}
      >
        <NoWrapCardHeader
          title={
            <div className="flex align-i-center">
              <UtilityDragHandle
                className="m-r-8px"
                disabled={dragHandleDisabled}
              />
              <ToggleStepNameTextField stepId={step.id} disabled={isDragging} />
            </div>
          }
        />
      </Card>
    </DraggingWrapper>
  );
}

function WorkflowDraggableStep({
  step,
  index
}: {
  step: EmbeddedStep;
  index: number;
}) {
  const steps = useGetSteps();
  return (
    <>
      <UtilityDraggable
        id={step.id}
        index={index}
        type="step"
        isDragDisabled={steps.length === 1}
      >
        <WorkflowDraggableStepInner
          step={step}
          dragHandleDisabled={steps.length === 1}
        />
      </UtilityDraggable>
    </>
  );
}

export default WorkflowDraggableStep;
