import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { updateStepId } from "../workflowBuilderSlice";

function CloseStepButton({ className = "" }: { className?: string }) {
  const dispatch = useDispatch();

  const close = () => {
    dispatch(updateStepId(undefined));
  };

  return (
    <IconButton size="small" onClick={close} className={className}>
      <Close fontSize="small" />
    </IconButton>
  );
}

export default CloseStepButton;
