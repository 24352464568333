import React from "react";
import CopyLinkMenuItem from "./CopyLinkMenuItem";

function CopyWorkflowLinkMenuItem({ workflowId }: { workflowId: string }) {
  return (
    <CopyLinkMenuItem
      objectId={workflowId}
      text="Copy workflow link"
      type="workflow"
    />
  );
}

export default CopyWorkflowLinkMenuItem;
