import type { RichTextEditorRef } from "mui-tiptap";

const onBoxFocus =
  ({
    shouldRenderBubble,
    rteRef
  }: {
    shouldRenderBubble: boolean;
    rteRef: React.RefObject<RichTextEditorRef>;
  }) =>
  (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const editor = rteRef.current?.editor;
    if (editor && !editor.isFocused) {
      const target = event.target as Node;

      // for the dialogs and other textfields on the page
      // we don't want to focus back on the editor
      const dontFocusArray = Array.from(
        document.querySelectorAll('[id^="dontFocusOnClickId"]')
      );
      const dontFocus = dontFocusArray.some((focusElement) =>
        focusElement.contains(target)
      );

      // taking out empty selection check for now, it may break things,
      // so be prepared to revert
      // taking it out so you can click around and it will focus on the selection
      // when not much is present
      // if (!shouldRenderBubble && !dontFocus && !editor.state.selection.empty) {
      if (!shouldRenderBubble && !dontFocus) {
        editor.commands.focus();
      }
    }
  };

export default onBoxFocus;
