import React from "react";
import { FormControl, IconButton, Typography } from "@mui/material";
import ToolSettingsDropdown from "../../../../../../../utilities/dropdowns/ToolSettingsDropdown";
import { Close } from "@mui/icons-material";
import type {
  TMarginTypes,
  TSelectedTool,
  TSetSelectedTool
} from "@sharedTypes";

function ToolSelectedText({
  selectedTool,
  setSelectedTool,
  className = "",
  margin
}: {
  className?: string;
  selectedTool: TSelectedTool;
  setSelectedTool: TSetSelectedTool;
  margin?: TMarginTypes;
}) {
  if (!selectedTool) return null;
  return (
    <FormControl margin={margin} fullWidth>
      <div className={`flex align-i-center ${className}`}>
        <Typography variant="subtitle1">{selectedTool.name}</Typography>
        <ToolSettingsDropdown tool={selectedTool} />
        <IconButton size="small" onClick={() => setSelectedTool(null)}>
          <Close fontSize="small" />
        </IconButton>
      </div>
    </FormControl>
  );
}

export default ToolSelectedText;
