export const getTruncatedWords = (text: string, limit: number) => {
  if (text?.split(" ").length > limit) {
    const truncatedSentence = text
      ? text?.split(" ")?.slice(0, limit).join(" ").concat("...")
      : "";
    return truncatedSentence;
  }
  return text;
};

export const getTruncatedCharacters = (text: string, limit: number) => {
  if (text.length > limit) {
    return text.slice(0, limit).concat("...");
  } else {
    return text;
  }
};
