import React from "react";
import { ContentCopy } from "@mui/icons-material";
import SettingsMenuItemWithClose from "../generic/SettingsMenuItemWithClose";
import { useHandleCopyText } from "../../hooks/useHandleCopy";

function CopyTextMenuItem({
  value = "",
  text = "Copy value",
  hotkey = "Meta+C",
  icon = <ContentCopy fontSize="small" />
}: {
  value?: string;
  text?: string;
  hotkey?: string;
  icon?: React.ReactNode;
}) {
  const copy = useHandleCopyText();

  const handleCopy = () => {
    copy(value);
  };

  return (
    <SettingsMenuItemWithClose
      text={text}
      icon={icon}
      action={handleCopy}
      hotkey={hotkey}
    />
  );
}

export default CopyTextMenuItem;
