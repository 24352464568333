import { useSelector } from "react-redux";
import { RootState } from "../stores/store";

const useHasNoLastPage = () => {
  const previousPath = useSelector(
    (state: RootState) => state.navigation.previousPath
  );
  const currentPath = useSelector(
    (state: RootState) => state.navigation.currentPath
  );
  return previousPath.path === currentPath;
};

export default useHasNoLastPage;
