import {
  Button,
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from "@mui/material";
import type { ExternalToolFE, MapperObject } from "@sharedTypes";
import { DateTime } from "luxon";
import React from "react";
import useGetToolFromQuery from "./toolSelector/hooks/useGetToolFromQuery";
import useUpdateField from "../toolBuilder/useUpdateField";
import replaceInitialInputMap from "../toolBuilder/blocks/toolWithinTool/functions/replaceInitialInputMap";
import seperateOutputChanges from "../toolBuilder/blocks/toolWithinTool/functions/separateOutputChanges";
import { useToolbuilderContext } from "../toolBuilder/context/ToolBuilderContext";

function ToolVersionCard({
  tool,
  blockId,
  inputMap
}: {
  tool: ExternalToolFE;
  blockId: string;
  inputMap: MapperObject;
}) {
  const currentTool = useGetToolFromQuery(tool._id, {
    refetchOnMountOrArgChange: true
  });
  const { dispatch } = useToolbuilderContext();

  const notCurrentVersion =
    currentTool && currentTool.updatedAt !== tool.updatedAt;

  const updateField = useUpdateField(blockId);

  const updateVersion = () => {
    if (!currentTool) return;
    const newInputMap = replaceInitialInputMap(currentTool, tool, inputMap);
    updateField(currentTool, "tool");
    updateField(newInputMap, "inputMap");
    const labelComparison = seperateOutputChanges(currentTool, tool);
    dispatch({
      type: "UPDATE_OUTPUTS_WITH_NEW_TOOL_VERSION",
      labelComparison
    });

    // separate outputs that are the same
  };

  return (
    <Card>
      <CardHeader
        title="Current version"
        subheader={DateTime.fromSeconds(tool.updatedAt).toLocaleString(
          DateTime.DATETIME_MED
        )}
      />
      {notCurrentVersion && (
        <List>
          <ListItem>
            <ListItemText
              primary={DateTime.fromSeconds(
                currentTool.updatedAt
              ).toLocaleString(DateTime.DATETIME_MED)}
              secondary="A newer version is available"
            />
            <ListItemSecondaryAction>
              <Button variant="outlined" onClick={updateVersion}>
                Update
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      )}
    </Card>
  );
}

export default ToolVersionCard;
