import React from "react";
import useToolflowNavigate from "../../../utilities/hooks/useToolflowNavigate";
import { PROFILE_NESTED_SETTINGS_ROUTE_PATH } from "../../../navigation/routePaths";
import SettingsMenuItemWithClose from "../../../utilities/dropdowns/generic/SettingsMenuItemWithClose";
import { RiSettings5Line } from "@remixicon/react";

const NavigateToSettingsMenuItem = () => {
  const navigate = useToolflowNavigate();

  const navigateToSettings = () => {
    navigate(PROFILE_NESTED_SETTINGS_ROUTE_PATH);
  };
  return (
    <SettingsMenuItemWithClose
      icon={<RiSettings5Line size={18} />}
      text="Settings"
      action={navigateToSettings}
    />
  );
};

export default NavigateToSettingsMenuItem;
