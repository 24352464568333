import type { CustomToolInputField } from "@sharedTypes";
import useGetCurrentToolInputFieldIndex from "./useGetCurrentToolInputFieldIndex";
import { useToolInputFieldsContext } from "../contexts/ToolInputFieldsContext";

const useGetCurrentToolInputField = (): CustomToolInputField | null => {
  const currentToolInputFieldIndex = useGetCurrentToolInputFieldIndex();
  const { toolInputFields } = useToolInputFieldsContext();
  return toolInputFields[currentToolInputFieldIndex];
};

export default useGetCurrentToolInputField;
