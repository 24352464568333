import type { TWordPairsDict } from "@sharedTypes";
import useUpdateField from "../../useUpdateField";
import ReplaceWordsForm from "./ReplaceWordsForm";
import React from "react";

function ReplaceWordsFormToolbuilder({
  id,
  wordsToReplace
}: {
  id: string;
  wordsToReplace: TWordPairsDict;
}) {
  const updateField = useUpdateField(id);
  const handleChange = (newPairsDict: TWordPairsDict) => {
    updateField(newPairsDict, "settings.wordsToReplace");
  };
  return (
    <ReplaceWordsForm
      handleChange={handleChange}
      wordsToReplace={wordsToReplace}
    />
  );
}

export default ReplaceWordsFormToolbuilder;
