import { IconButton } from "@mui/material";
import { RiGitForkLine } from "@remixicon/react";
import type { ValidateType } from "@sharedTypes";
import React from "react";
import { WORKFLOW } from "../../utilities/constants/constants";
import { useDuplicateObject } from "../../utilities/hooks/useDuplicateObject";

const DuplicateMarketplaceEntity = ({
  entityId,
  entityType
}: {
  entityId: string;
  entityType: ValidateType;
}) => {
  const { duplicateObject } = useDuplicateObject();
  const duplicateEntity = (e: React.MouseEvent) => {
    e.stopPropagation();
    duplicateObject(entityId, entityType);
  };

  if (entityType === WORKFLOW) return null;

  return (
    <IconButton onClick={duplicateEntity}>
      <RiGitForkLine size={16} />
    </IconButton>
  );
};

export default DuplicateMarketplaceEntity;
