import React from "react";
import { IconButton } from "@mui/material";
import RemixWrapperIconWithTheme from "../../../features/layout/icons/RemixWrapperIconWithTheme";
import { type RemixiconComponentType } from "@remixicon/react";
import { toolflowTextDisabledColor } from "../../../globalTheme/muiUtils/appTheme";
import { DarkUtilityTooltip } from "../../../utilities/ToolflowInfo";

const RemixButton = ({
  text,
  icon,
  onClick,
  disabled,
  className = "",
  providedColor = toolflowTextDisabledColor
}: {
  text?: string;
  icon: RemixiconComponentType;
  onClick: (e: React.MouseEvent) => void;
  disabled?: boolean;
  className?: string;
  providedColor?: string;
}) => {
  return (
    <DarkUtilityTooltip title={text} className={className}>
      <IconButton onClick={onClick} disabled={disabled}>
        <RemixWrapperIconWithTheme
          Icon={icon}
          size={16}
          providedColor={providedColor}
        />
      </IconButton>
    </DarkUtilityTooltip>
  );
};

export default RemixButton;
