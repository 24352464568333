import React from "react";
import {
  promptInitialState,
  chatGPTInitialState
} from "../../../context/initialStates";
import type { PromptBlockData } from "@sharedTypes";
import useParentId from "../../hooks/useParentId";
import DraggableBlockContainer from "../../DraggableBlockContainer";

function ChatGPTBlockDraggable({ open }: { open: boolean; title?: string }) {
  const parentId = useParentId();
  const title = "Text";
  return (
    <DraggableBlockContainer
      open={open}
      item={{
        type: "promptBlockNode",
        data: {
          ...chatGPTInitialState,
          ...promptInitialState
        } as PromptBlockData,
        parentNode: parentId
      }}
      title={title}
    />
  );
}

export default ChatGPTBlockDraggable;
