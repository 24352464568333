import React from "react";
import { useToolContext } from "../utilities/contexts/ToolContext";
import ExternalToolButton from "../utilities/ExternalToolButton";
import useSetSelectedWorkspaceToolById from "../hooks/useSetSelectedWorkspaceToolById";
import useSetWorkspaceTab from "../features/workstation/hooks/useSetWorkspaceTab";
import { TOOLS_TAB } from "../features/workstation/workspaceConstants";

const ExternalToolCardContainer = () => {
  const { tool } = useToolContext();
  const setSelectedToolById = useSetSelectedWorkspaceToolById();
  const setWorkspaceTab = useSetWorkspaceTab();

  const onClickHandler = () => {
    setSelectedToolById(tool._id);
    setWorkspaceTab(TOOLS_TAB);
  };
  return (
    <ExternalToolButton
      tool={tool}
      onClickHandler={onClickHandler}
      isChatView={true}
    />
  );
};

export default ExternalToolCardContainer;
