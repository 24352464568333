import type { ValidateIDResponseFE, ValidateType } from "@sharedTypes";
import { authenticatedApi } from "../authenticatedAPI";
import { setErrorMessage } from "../../stores/actions";

export const validateApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getValidate: builder.query<
      ValidateIDResponseFE,
      { id: string; type: ValidateType }
    >({
      query: ({ id, type }) => ({
        url: `validate?objectId=${id}&objectType=${type}`,
        method: "GET"
      }),
      async onQueryStarted({ type }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          dispatch(setErrorMessage(`Invalid ${type}`));
        }
      }
    })
  })
});

export const validateApiEndpoints = validateApi.endpoints;

export const { useGetValidateQuery } = validateApi;
