import React from "react";
import useDeleteNodeWhenToolCompleted from "./hooks/editorToolCardHooks/useDeleteNodeWhenToolCompleted";
import ToolflowNodeWrapper from "../utilities/ToolflowNodeWrapper";
import EditorToolSettingsDropdown from "./dropdowns/EditorToolSettingsDropdown";
import useUpdateHighlightsFromDefaultUI from "../utilities/editorToolCard/hooks/useUpdateHighlightsFromDefaultUI";
import useWaitForWorkspaceToLoad from "../utilities/editorToolCard/hooks/useWaitForWorkspaceToLoad";
// import useWaitForWorkspaceToLoad from "../utilities/editorToolCard/hooks/useWaitForWorkspaceToLoad";

function EditorToolCardNodeWrapper({
  children
}: {
  children: React.ReactNode;
}) {
  useDeleteNodeWhenToolCompleted();
  useUpdateHighlightsFromDefaultUI();
  useWaitForWorkspaceToLoad();
  return (
    <ToolflowNodeWrapper hideOutline settings={<EditorToolSettingsDropdown />}>
      {children}
    </ToolflowNodeWrapper>
  );
}

export default EditorToolCardNodeWrapper;
