import React, { useState } from "react";
import { BlockerContext } from "./BlockerContext";
import ConfirmNavigationDialogBase, {
  BlockerTypes
} from "./ConfirmNavigationDialogBase";
import useBlockerFunctions from "./hooks/useBlockerFunction";
import type { unstable_Blocker as Blocker } from "react-router-dom";

interface NavigationProviderProps {
  blocker: Blocker;
  type: BlockerTypes;
  actions: React.ReactNode;
  children: React.ReactNode;
}

function NavigationProviderBlocker({
  blocker,
  children,
  actions,
  type
}: NavigationProviderProps) {
  const [isOpen, setIsOpen] = useState(false);

  const setOpen = () => {
    setIsOpen(true);
  };

  const setClose = () => {
    setIsOpen(false);
  };

  const { resetBlocker, continueNavigating } = useBlockerFunctions({
    setOpen,
    setClose,
    blocker
  });

  return (
    <BlockerContext.Provider
      value={{ resetBlocker, continueNavigating, isOpen }}
    >
      <ConfirmNavigationDialogBase
        open={isOpen}
        handleClose={resetBlocker}
        type={type}
      >
        {actions}
      </ConfirmNavigationDialogBase>
      {children}
    </BlockerContext.Provider>
  );
}

export default NavigationProviderBlocker;
