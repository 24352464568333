import React from "react";
import { ToolContextComponent } from "../../../../utilities/contexts/ToolContext";
import EditorToolCardContainerWorkspace from "./EditorToolCardContainerOuterWorkspace";
import useGetSelectedWorkspaceTool from "../../hooks/useGetSelectedWorkspaceTool";

const RunToolCard = () => {
  const tool = useGetSelectedWorkspaceTool();
  if (!tool) return null;
  return (
    <ToolContextComponent tool={tool}>
      <EditorToolCardContainerWorkspace />
    </ToolContextComponent>
  );
};

export default RunToolCard;
