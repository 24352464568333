import React from "react";
import UpdateTextFieldBase from "../../../utilities/UpdateTextFieldBase";
import { SNIPPET_VALUE_FIELD_LABEL } from "./snippetFields";
import { SNIPPET_VALUE_REQUIRED_ERROR } from "./SnippetFormProvider";

const SnippetValueTextField = () => {
  return (
    <UpdateTextFieldBase
      fullWidth
      size="medium"
      multiline
      rows={20}
      className="m-t-16px"
      fieldLabel={SNIPPET_VALUE_FIELD_LABEL}
      registerOptions={{ required: SNIPPET_VALUE_REQUIRED_ERROR }}
      showErrors
    />
  );
};

export default SnippetValueTextField;
