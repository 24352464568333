import type { CustomToolInputField } from "@sharedTypes";
import { TEXTFIELD } from "../../../utilities/Inputs/inputConstants";
import { v4 as uuidv4 } from "uuid";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";

const useHandleAddToolInputFieldToolbuilder = () => {
  const { dispatch, state } = useToolbuilderContext();
  const { toolInputFields } = state.currentState;

  const addToolInputFieldToolbuilder = () => {
    const updatedToolInputFields: CustomToolInputField[] = [
      ...toolInputFields,
      {
        name: "",
        type: TEXTFIELD,
        id: uuidv4()
      }
    ];
    dispatch({
      type: "SET_TOOL_INPUT_FIELDS",
      toolInputFields: updatedToolInputFields
    });
  };

  return addToolInputFieldToolbuilder;
};

export default useHandleAddToolInputFieldToolbuilder;
