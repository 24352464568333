import React from "react";
import {
  settingsMatcher,
  toolBuilderMatcher
} from "../../../navigation/matchers";
import { Collapse } from "@mui/material";
import StyledAlert from "./StyledAlert";
import { useSelector, useDispatch } from "react-redux";
import {
  closeAppWarning,
  selectAppWarningOpen
} from "../sidebar/appWarningSlice";

const CollapsibleAppWarning = () => {
  const isSettingsMatch = settingsMatcher(location.pathname);
  const isToolMatch = toolBuilderMatcher(location.pathname);
  const reduxDispatch = useDispatch();

  const appWarning = useSelector(selectAppWarningOpen);

  const closeAppAlert = () => {
    reduxDispatch(closeAppWarning());
  };

  return (
    <Collapse in={!!appWarning}>
      <div
        className={`p-16px ${
          isToolMatch || isSettingsMatch ? "bg-color-white" : ""
        }`}
      >
        <StyledAlert
          variant="outlined"
          severity="warning"
          sx={{ boxShadow: "none" }}
          className="bg-color-white"
          onClose={closeAppAlert}
        >
          {appWarning}
        </StyledAlert>
      </div>
    </Collapse>
  );
};

export default CollapsibleAppWarning;
