import { useGetCurrentUserQuery } from "../ToolflowAPI/rtkRoutes/userApi";
import { useAuth0Store } from "./useAuth0Store";

const useGetCurrentUser = () => {
  const { isAuthenticated, isLoading } = useAuth0Store();
  const { user } = useGetCurrentUserQuery(undefined, {
    skip: !isAuthenticated || isLoading,
    selectFromResult: ({ data }) => {
      return {
        user: data?.user ?? null
      };
    }
  });
  return user;
};

export default useGetCurrentUser;
