import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { getComparator, stableSort } from "../utlils/tableUtils";
import { HeadCell, Order, WorkspaceTableData } from "../utlils/tableTypes";
import { StyledTableCell, StyledTableRow } from "../layout/TableLayouts";
import BaseSettingsDropdown from "../../../utilities/dropdowns/generic/base/BaseSettingsDropdown";
import useTablePagination from "../hooks/useTablePagination";
import HomePagePaginatedTableHead from "../utlils/HomePagePaginatedTableHead";
import WorkspaceTableRow from "./WorkspaceTableRow";

function WorkspacePaginatedTable({
  rows,
  headCells
}: {
  rows: WorkspaceTableData[];
  headCells: readonly HeadCell[];
}) {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof WorkspaceTableData>("date");
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    rowsPerPageOptions
  } = useTablePagination();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof WorkspaceTableData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  return (
    <BaseSettingsDropdown>
      <div className="p-h-16px">
        <TableContainer>
          <Table aria-labelledby="tableTitle">
            <HomePagePaginatedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={(event, property) =>
                handleRequestSort(event, property as keyof WorkspaceTableData)
              }
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((row) => (
                <WorkspaceTableRow key={row.id} row={row} />
              ))}
              {emptyRows > 0 && (
                <StyledTableRow
                  style={{
                    height: 53 * emptyRows
                  }}
                >
                  <StyledTableCell colSpan={6} />
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </BaseSettingsDropdown>
  );
}

export default WorkspacePaginatedTable;
