import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import BlockDrawerHeader from "./BlockDrawerHeader";
import ToolbuilderDrawerToRefactor from "../ToolbuilderDrawerToRefactor";
import useHandleDrawerSizeAndOpen from "./useHandleDrawerSizeAndOpen";
import { useToolbuilderContext } from "../context/ToolBuilderContext";

const BlockDrawer = ({
  children,
  noPadding
}: {
  children: React.ReactNode;
  noPadding?: boolean;
}) => {
  const { dispatch } = useToolbuilderContext();
  const resetSelected = () => {
    dispatch({ type: "RESET_OPEN_NODE" });
  };
  const { open, large, setLarge } = useHandleDrawerSizeAndOpen();

  if (!open) return null;

  return (
    <ToolbuilderDrawerToRefactor
      open={open}
      large={large}
      noPadding={noPadding}
    >
      <BlockDrawerHeader
        className={`${noPadding ? "p-16px" : ""}`}
        actionNode={
          <div className="flex align-i-center m-l-8px">
            <IconButton onClick={() => setLarge(!large)}>
              {large ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
            </IconButton>
            <IconButton onClick={resetSelected}>
              <CloseIcon />
            </IconButton>
          </div>
        }
      />
      {children}
    </ToolbuilderDrawerToRefactor>
  );
};

export default BlockDrawer;
