import { useGetWorkflowVersionQuery } from "../../../ToolflowAPI/rtkRoutes/workflowVersionApi";
import useGetSelectedWorkspaceWorkflow from "./useGetSelectedWorkspaceWorkflow";

const useGetWorkspacePopulatedWorkflow = () => {
  const workflow = useGetSelectedWorkspaceWorkflow();
  const { data } = useGetWorkflowVersionQuery(
    {
      workflowId: workflow?.id || "",
      workflowVersionId: workflow?.mainId || ""
    },
    {
      skip: !workflow?.mainId || !workflow?.id
    }
  );
  return data?.workflowVersion;
};

export default useGetWorkspacePopulatedWorkflow;
