import type { CustomToolInputField } from "@sharedTypes";
import { useInputsToCollectFieldArrayContext } from "../InputsToCollectContext";

const useSetToolInputFieldsWorkflow = () => {
  const { replace } = useInputsToCollectFieldArrayContext();
  const setToolInputFields = (
    updatedToolInputFields: CustomToolInputField[]
  ) => {
    replace(updatedToolInputFields);
  };
  return setToolInputFields;
};

export default useSetToolInputFieldsWorkflow;
