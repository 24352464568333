function isOpenAIImageArray(input: $TSFixMe): boolean {
  return (
    Array.isArray(input) &&
    input.every(
      (item) =>
        typeof item === "object" &&
        item !== null &&
        typeof item.url === "string"
    )
  );
}

export default isOpenAIImageArray;
