import { Chip } from "@mui/material";
import type {
  TPrivateVisibility,
  TPublicVisibility,
  TRestrictedVisibility,
  VisibilityTypes
} from "@sharedTypes";
import React from "react";

export const PRIVATE_VISIBILITY: TPrivateVisibility = "private";
export const RESTRICTED_VISIBILITY: TRestrictedVisibility = "restricted";
export const PUBLIC_VISIBILITY: TPublicVisibility = "public";

const VisibilityChip: React.FC<{ visibility: VisibilityTypes }> = ({
  visibility
}) => {
  const isPublic = visibility === PUBLIC_VISIBILITY;
  if (isPublic) return null;

  return (
    <Chip
      label={visibility.charAt(0).toUpperCase() + visibility.slice(1)}
      variant="outlined"
      size="small"
      className="m-r-8px"
    />
  );
};

export default VisibilityChip;
