import React from "react";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";
import WorkspaceRenderControls from "../WorkspaceRenderControls";

const useRenderControls = () => {
  const { muiTipTapProps } = useMuiTipTapContext();
  const renderControls = () => {
    const { hideTopMenu } = muiTipTapProps;
    if (hideTopMenu) return null;
    return <WorkspaceRenderControls />;
  };
  return renderControls;
};

export default useRenderControls;
