import React from "react";
import type { RunToolStep } from "@sharedTypes";
import EditorToolCardContainerOuterWorkflow from "./EditorToolCardContainerOuterWorkflow";
import { ToolContextComponent } from "../../../../../utilities/contexts/ToolContext";
import { useGetToolQuery } from "../../../../../ToolflowAPI/rtkRoutes/toolsApi";
import { useWorkflowStepContext } from "../../../../../utilities/contexts/WorkflowStepContext";

const WorkflowRunStep = () => {
  const { step } = useWorkflowStepContext<RunToolStep>();
  const { data } = useGetToolQuery(step.settings.toolId, {
    skip: !step.settings.toolId
  });

  if (!data) {
    return null;
  }
  return (
    <ToolContextComponent tool={data.tool}>
      <EditorToolCardContainerOuterWorkflow />
    </ToolContextComponent>
  );
};

export default WorkflowRunStep;
