import React from "react";
import useGetHomePageEntities from "../../hooks/useGetHomePageEntities";
import ToolsBlockTable from "./ToolsBlockTable";

const EntitiesCreatedByUserContainer = () => {
  const { userCreatedEntities } = useGetHomePageEntities(undefined, 6);
  return <ToolsBlockTable toolsBlockEntities={userCreatedEntities} />;
};

export default EntitiesCreatedByUserContainer;
