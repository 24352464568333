import React, { useState } from "react";

import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import type {
  CustomToolInputField,
  RunBlockToolData,
  SerpBlockProps,
  TSerpSettings,
  UserInputDictType
} from "@sharedTypes";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useRunBlockMutation } from "../../../ToolflowAPI/rtkRoutes/blockApi";
import { setErrorMessage } from "../../../features/layout/alerts/alertMessageSlice";
import AccordionWrapper from "../../../utilities/AccordionWrapper";
import CopyableLargeTextField from "../../../utilities/CopyableLargeTextField";
import { TEXTFIELD } from "../../../utilities/Inputs/inputConstants";
import { COPYABLE_LARGE_TEXTFIELD } from "../../../utilities/Inputs/outputConstants";
import {
  getErrorMessage,
  getIsOutOfCreditsError
} from "../../../utilities/apiHandlerWithAuth";
import OutputField from "../../OutputField";
import TestToolRun from "../../common/TestToolRun";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import useHandleSocketMessage from "../../hooks/useHandleSocketMessage";
import useInnerBlockState from "../../hooks/useInnerBlockState";
import SerpBlockSettings from "../../settings/serp/SerpBlockSettings";
import useShouldWrap from "../../useShouldWrap";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../globalTheme/muiUtils/appTheme";

function SerpBlockInner({ data, id }: Omit<SerpBlockProps, "selected">) {
  const { label, settings } = data;
  const { dispatch, state } = useToolbuilderContext();
  const { toolOutput } = state;
  const [shouldWrap, wrapperRef] = useShouldWrap();
  const { loading, output, setLoading } = useHandleSocketMessage();
  const { abort, componentId, useFields } = useInnerBlockState();
  const fields = useFields("", id);
  const [runBlock] = useRunBlockMutation();
  const { toolId, toolVersionId } = state;
  const [showUpgrade, setShowUpgrade] = useState(false);
  function arrayToObject(): { [key: string]: string } {
    const obj: { [key: string]: string } = {};

    for (const field of fields) {
      obj[field] = "";
    }

    return obj;
  }
  const [userInput, setUserInput] = useState<UserInputDictType>(
    arrayToObject()
  );
  const reduxDispatch = useDispatch();

  function transformList(input: string[]): CustomToolInputField[] {
    let o = input.map((name) => ({
      name,
      id: uuidv4(),
      type: TEXTFIELD
    }));
    o = o.concat([
      {
        name: data.settings.userQueriesFieldKey,
        id: uuidv4(),
        type: TEXTFIELD
      }
    ]);
    return o;
  }

  const handleRunBlock = async () => {
    setLoading(true);
    dispatch({
      type: "UPDATE_TOOL_OUTPUT",
      response: null,
      percentCompleted: 0
    });

    const toolInputFields = transformList(fields);
    const toolData: RunBlockToolData = {
      componentId,
      userInput: userInput,
      blockType: "serpBlockNode",
      toolInputFields,
      blockData: data,
      trackingMetrics: {
        toolId,
        toolVersionId,
        blockId: id
      }
    };

    const handleError = (e: unknown) => {
      if (getIsOutOfCreditsError(e)) {
        setShowUpgrade(true);
      }
      reduxDispatch(setErrorMessage(getErrorMessage(e, "Error running block")));
      setLoading(false);
    };

    try {
      await runBlock(toolData).unwrap();
    } catch (err) {
      handleError(err);
    }
  };

  const handleUpdate = (
    field: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newUserInput = { ...userInput, [field]: e.target.value };
    setUserInput(newUserInput);
  };

  const runToolOutput = (
    <AccordionWrapper
      elevation={0}
      title="Test Output"
      startsExpanded={!!output}
    >
      {output && (
        <OutputField
          toolOutput={output ? { [data.label]: output } : null}
          toolOutputField={{
            name: data.label,
            type: COPYABLE_LARGE_TEXTFIELD
          }}
          noEditor
        />
      )}
    </AccordionWrapper>
  );

  const runToolCard = (
    <AccordionWrapper title="Test" elevation={0} startsExpanded>
      {fields.map((field, idx) => (
        <TextField
          name={field}
          key={idx}
          label={field}
          value={userInput[field]}
          onChange={(e) => handleUpdate(field, e)}
          multiline
          fullWidth
          margin="normal"
          maxRows={MAX_ROWS_LARGE_TEXTFIELD}
        />
      ))}
      <TestToolRun
        abort={abort}
        handleRunBlock={handleRunBlock}
        loading={loading}
        showUpgrade={showUpgrade}
        blockData={data}
        blockForm={userInput}
      />
    </AccordionWrapper>
  );

  const settingsInner = (
    <>
      <SerpBlockSettings id={id} settings={settings as TSerpSettings} />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </>
  );

  return (
    <div ref={wrapperRef} className="verticalMargin16">
      {shouldWrap ? (
        <Grid container>
          <Grid xs={6}>
            {settingsInner}
            {runToolCard}
          </Grid>
          <Grid xs={6}>{runToolOutput}</Grid>
        </Grid>
      ) : (
        <>
          {settingsInner}
          {runToolCard}
          {runToolOutput}
        </>
      )}
    </div>
  );
}

export default SerpBlockInner;
