import React, { ChangeEvent, FC } from "react";
import { TextField } from "@mui/material";
import type { TSetFormState, UserInputDictType } from "@sharedTypes";

interface IUrlFormProps {
  id: string;
  updateFieldKey?: string;
  handleUpdateUrl?: (url: string, toolInputName?: string) => void;
  toolInputName?: string;
  placeholder?: string;
  setFormState: TSetFormState;
  userInput: UserInputDictType;
}
const UrlForm: FC<IUrlFormProps> = (props) => {
  const {
    id,
    updateFieldKey,
    userInput,
    placeholder = "Add url",
    setFormState
  } = props;
  const userInputkey = updateFieldKey || "";
  const url = userInput[userInputkey] || "";
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputUrl = e.target.value;
    setFormState({ ...userInput, [userInputkey]: inputUrl });
  };
  return (
    <TextField
      key={id}
      label={placeholder}
      value={url}
      onChange={handleChange}
      fullWidth
      margin="normal"
    />
  );
};

export default UrlForm;
