import type { UserInputDictType, GetAssetsStep } from "@sharedTypes";
import React from "react";
import WebsiteForm from "../../../../../utilities/WebsiteForm";
import useSetWorkflowProgress from "../hooks/useSetWorkflowProgress";
import useGetWorkflowProgress from "../hooks/useGetWorkflowProgress";
import { useWorkflowStepContext } from "../../../../../utilities/contexts/WorkflowStepContext";

const WorkflowGetAssetsStep = () => {
  const { step } = useWorkflowStepContext<GetAssetsStep>();
  const setWorkflowProgress = useSetWorkflowProgress(step.id);
  const workflowProgress = useGetWorkflowProgress();

  const setFormState = (formState: UserInputDictType) => {
    setWorkflowProgress({
      inputs: formState,
      outputs: {}
    });
  };

  return (
    <WebsiteForm
      toolInputFields={step.settings.inputsToCollect}
      formState={workflowProgress[step.id]?.inputs || {}}
      setFormState={setFormState}
      defaultFormState={workflowProgress[step.id]?.inputs || {}}
      useIds
    />
  );
};

export default WorkflowGetAssetsStep;
