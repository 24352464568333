import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

function OpenAIPricingLink({ className = "" }: { className?: string }) {
  return (
    <Link
      to="https://openai.com/pricing"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Button className={`m-r-8px ${className}`}>Review Pricing</Button>
    </Link>
  );
}

export default OpenAIPricingLink;
