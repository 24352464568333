import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function AccordionWrapper({
  title,
  secondaryText,
  startsExpanded = false,
  children,
  noMaxWidth = false,
  elevation,
  className,
  customTitle
}: {
  title: string;
  secondaryText?: string;
  startsExpanded?: boolean;
  children: React.ReactNode;
  noMaxWidth?: boolean;
  elevation?: number;
  className?: string;
  customTitle?: React.ReactNode;
}) {
  const [expanded, setExpanded] = useState<boolean>(startsExpanded);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (startsExpanded !== expanded && startsExpanded) {
      setExpanded(startsExpanded);
    }
  }, [startsExpanded]);

  return (
    <Accordion
      onChange={handleChange}
      expanded={expanded}
      elevation={elevation}
      className={className}
      sx={elevation === 0 ? { boxShadow: 0 } : {}}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${title}-content`}
        id={`${title}-header`}
      >
        {customTitle ? (
          customTitle
        ) : (
          <Typography sx={noMaxWidth ? {} : { width: "33%", flexShrink: 0 }}>
            {title}
          </Typography>
        )}

        {secondaryText && (
          <div className="flex align-i-center">
            <Typography sx={{ color: "text.secondary" }}>
              {secondaryText}
            </Typography>
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

export default AccordionWrapper;
