import React, { useEffect, useState } from "react";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import Typography from "@mui/material/Typography";
import type { TToolVersion } from "@sharedTypes";
import { DateTime } from "luxon";

function CurrentToolVersion({ className = "" }: { className?: string }) {
  const { state } = useToolbuilderContext();
  const [selectedItem, setSelectedItem] = useState<TToolVersion | null>(null);

  useEffect(() => {
    const item = Object.values(state.toolVersions).find(
      (element: TToolVersion) => element._id === state.toolVersionId
    );
    setSelectedItem(item || null);
  }, [state.toolVersions, state.toolVersionId]);

  // Function to render the selected value without the caption typography
  if (selectedItem) {
    return (
      <Typography className={className} variant="caption">
        Viewing Version:{" "}
        {DateTime.fromSeconds(selectedItem.updatedAt).toLocaleString(
          DateTime.DATETIME_MED
        )}
      </Typography>
    );
  }
  return null;
}

export default CurrentToolVersion;
