import React from "react";
import { ToolsLoading } from "../../utilities/ToolflowSkeleton";
import type { TMarketplaceEntity } from "@sharedTypes";
import MarketplaceCategoriesContainer from "./categories/MarketplaceCategoriesContainer";
import MarketplaceUseCasesContainer from "./useCases/MarketplaceUseCasesContainer";
import MarketplaceGrid from "./MarketplaceGrid";
import MarketplaceUseCaseTitle from "./MarketplaceUseCaseTitle";
import EntityNotFound from "./EntityNotFound";
import CommonRightSideContainer from "./CommonTopHeader";

const MarketplacePageBase = ({
  loading,
  action,
  marketplaceEntities
}: {
  loading?: boolean;
  action?: React.ReactNode;
  marketplaceEntities: TMarketplaceEntity[];
}) => {
  const noMarketplaceEntities = marketplaceEntities?.length === 0;
  return (
    <CommonRightSideContainer title="Marketplace" action={action}>
      {loading ? (
        <ToolsLoading />
      ) : (
        <div className="p-h-16px m-b-page">
          <MarketplaceCategoriesContainer />
          <MarketplaceUseCaseTitle />
          <MarketplaceUseCasesContainer />
          {noMarketplaceEntities ? (
            <EntityNotFound />
          ) : (
            <MarketplaceGrid marketplaceEntities={marketplaceEntities} />
          )}
        </div>
      )}
    </CommonRightSideContainer>
  );
};

export default MarketplacePageBase;
