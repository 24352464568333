import React from "react";
import { Box, ThemeProvider, CssBaseline } from "@mui/material";
import { RichTextEditor } from "mui-tiptap";
import useExtension from "./hooks/useExtension";
import { useTheme } from "@mui/material/styles";
import useGetInitialWorkspace from "./hooks/useGetInitialWorkspace";
import useGetValidatedInitialContent from "./hooks/useGetValidatedInitialContent";
import useGetEditorProps from "./hooks/useGetEditorProps";
import useOnBlur from "./hooks/useOnBlur";
import useFocusEditorOnMount from "./hooks/useFocusEditorOnMount";
import onBoxFocus from "./functions/onBoxFocus";
import useResetSlash from "./hooks/useResetSlash";
import useRenderControls from "./hooks/useRenderControls";
import WorkspaceMenus from "./WorkspaceMenus";
import {
  MuiTipTapContextComponent,
  useMuiTipTapContext
} from "./context/MuiTipTapContext";
import useRteRef from "./hooks/useRteRef";
import { WorkspaceProps } from "./workspaceTypes";
import { useGetSnippetsQuery } from "../ToolflowAPI/rtkRoutes/snippetsApi";

function MuiTipTap() {
  const { muiTipTapProps, shouldRenderBubble } = useMuiTipTapContext();

  const theme = useTheme();

  const rteRef = useRteRef();

  const {
    disableWhite,
    enableHover,
    className,
    variant,
    richTextFieldClassName,
    disableAutofocus,
    id,
    disableEditing,
    placeholder,
    disableFormatting,
    preventDefaultEnter,
    overrideKeydown,
    BoxProps,
    disableInputs
  } = muiTipTapProps;

  const extensions = useExtension({
    placeholder,
    disableFormatting,
    preventDefaultEnter
  });

  useGetInitialWorkspace();
  useResetSlash();
  useFocusEditorOnMount({ rteRef });
  useGetSnippetsQuery();

  const validatedInitialContent = useGetValidatedInitialContent({
    rteRef,
    ref: BoxProps?.ref as React.RefObject<HTMLDivElement>
  });

  const onBlur = useOnBlur();
  const onFocus = onBoxFocus({ shouldRenderBubble, rteRef });
  const editorProps = useGetEditorProps({
    rteRef,
    overrideKeydown
  });
  const renderControls = useRenderControls();

  return (
    <Box
      onClick={onFocus}
      className={`pos-relative ${
        disableWhite ? "" : "bg-color-white"
      } flex-grow-1 ${BoxProps?.className ? BoxProps.className : ""}`}
      sx={{
        overflow: "auto",
        ...BoxProps?.sx
      }}
      id={id}
      {...BoxProps}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RichTextEditor
          className={`muiClass h-100-percent ${className} ${
            enableHover ? "updated-tiptap-hover" : "disable-tiptap-hover"
          }`}
          editable={!disableInputs || disableEditing}
          ref={rteRef}
          extensions={extensions}
          RichTextFieldProps={{
            variant,
            RichTextContentProps: {
              className: `p-0px ${richTextFieldClassName}`
            }
          }}
          onCreate={() =>
            rteRef.current?.editor?.commands.setContent(
              validatedInitialContent // we are doing this because workflow steps arent showing default values when you go back to that step
            )
          }
          // this used to be onTransaction, might need to be changed
          // i think on blur should be sufficient for most changes though
          onBlur={onBlur}
          parseOptions={{ preserveWhitespace: true }}
          renderControls={() => renderControls()}
          // this is so that toolmenu enter works appropriately
          editorProps={editorProps}
          autofocus={!disableAutofocus}
        >
          {() => <WorkspaceMenus />}
        </RichTextEditor>
      </ThemeProvider>
    </Box>
  );
}

function Workspace(props: Readonly<WorkspaceProps>) {
  return (
    <MuiTipTapContextComponent {...props}>
      <MuiTipTap />
    </MuiTipTapContextComponent>
  );
}

export default Workspace;
