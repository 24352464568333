import React from "react";
import ToolSelector from "../../../../../utilities/toolSelector/ToolSelector";
import useSetSelectedTool from "./hooks/useSetSelectedTool";

function WorkflowToolSelectorContainer() {
  const setSelectedTool = useSetSelectedTool();
  return (
    <ToolSelector
      margin="normal"
      setSelectedTool={setSelectedTool}
      disableWorkflowsOptions
    />
  );
}

export default WorkflowToolSelectorContainer;
