import React from "react";
import UndoIcon from "@mui/icons-material/Undo";
import { useRichTextEditorContext } from "mui-tiptap";
import SettingsMenuItemWithClose from "../utilities/dropdowns/generic/SettingsMenuItemWithClose";

export const useHideUndo = () => {
  const editor = useRichTextEditorContext();
  return !editor?.isEditable || !editor.can().undo();
};

export default function UndoWorkspaceMenuItem() {
  const editor = useRichTextEditorContext();
  const hide = useHideUndo();
  return (
    <SettingsMenuItemWithClose
      hide={hide}
      text="Undo"
      action={() => editor?.chain().focus().undo().run()}
      icon={<UndoIcon fontSize="small" />}
    />
  );
}
