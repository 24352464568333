import { CircularProgress } from "@mui/material";
import React from "react";
import RemixWrapperIconWithTheme from "../../../../../../layout/icons/RemixWrapperIconWithTheme";
import { FileIcon } from "../../../../icons/icons";
import { toolflowTextDisabledColor } from "../../../../../../../globalTheme/muiUtils/appTheme";

function AssetIndicator({ loading }: { loading?: boolean }) {
  if (loading) {
    return <CircularProgress size={14} />;
  } else {
    return (
      <RemixWrapperIconWithTheme
        Icon={FileIcon}
        providedColor={toolflowTextDisabledColor}
        size={14}
      />
    );
  }
}

export default AssetIndicator;
