import React from "react";
import useUnfavoriteObject from "./useUnfavoriteObject";
import { RiStarFill } from "@remixicon/react";
import type { ButtonSizeTypes } from "@sharedTypes";
import IconButtonWithBorder from "../../features/marketplace/card/IconButtonWithBorder";

function FavoritedObject({
  className = "",
  size
}: {
  className: string;
  size: ButtonSizeTypes;
}) {
  const unFavorite = useUnfavoriteObject();

  return (
    <IconButtonWithBorder
      onClick={unFavorite}
      className={className}
      size={size}
    >
      <RiStarFill size={20} color="#FFB400" />
    </IconButtonWithBorder>
  );
}

export default FavoritedObject;
