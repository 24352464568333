import React from "react";
import DraggableBlockContainer from "../DraggableBlockContainer";
import useParentId from "../hooks/useParentId";

function LogicBlockDraggable({ open }: { open: boolean }) {
  const parentId = useParentId();
  const title = "Logic";

  return (
    <DraggableBlockContainer
      item={{
        type: "logicBlockNode",
        data: {
          label: "Logic",
          type: "logic",
          logicArray: []
        },
        parentNode: parentId
      }}
      open={open}
      title={title}
    />
  );
}

export default LogicBlockDraggable;
