import React from "react";
import AddInputOptionButton from "./AddInputOptionButton";
import InputOptionNameTextField from "./InputOptionNameTextField";
import useGetCurrentToolInputField from "./hooks/useGetCurrentToolInputField";
import { isInputWithOptions } from "../constants";

function ToolInputOptions({ disabled }: { disabled?: boolean }) {
  const toolInputField = useGetCurrentToolInputField();

  if (!isInputWithOptions(toolInputField)) {
    return null;
  }

  return (
    <>
      {toolInputField.options.map((option, optionIndex) => (
        <InputOptionNameTextField
          key={optionIndex}
          option={option}
          optionIndex={optionIndex}
          disabled={disabled}
        />
      ))}
      <div>
        <AddInputOptionButton disabled={disabled} />
      </div>
    </>
  );
}

export default ToolInputOptions;
