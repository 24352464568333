import { Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { toolflowTextSecondaryColor } from "../../../globalTheme/muiUtils/appTheme";
import { HOME_NESTED_MAIN_ROUTE_PATH } from "../../../navigation/routePaths";
import TypograpyLink from "../../../utilities/TypograpyLink";
import HistoryContent from "../nestedRoutes/history/HistoryContent";
import HomePageContent from "../nestedRoutes/main/HomePageContent";
import ToolsPageContent from "../nestedRoutes/tools/ToolsPageContent";

type HomePageNestedPathsKeys = "main" | "history" | "tools";
const homePageNestedPathsDict: Record<
  HomePageNestedPathsKeys,
  { content: React.ReactNode; label: string; breadcrumb?: React.ReactNode }
> = {
  main: { content: <HomePageContent />, label: "Home", breadcrumb: null },
  history: {
    content: <HistoryContent />,
    label: "History",
    breadcrumb: [
      <TypograpyLink
        linkText="Home"
        color={toolflowTextSecondaryColor}
        to={HOME_NESTED_MAIN_ROUTE_PATH}
        key={"Home"}
      />,
      <Typography
        key="history"
        sx={{ color: (theme) => theme.palette.text.primary }}
      >
        History
      </Typography>
    ]
  },
  tools: {
    content: <ToolsPageContent />,
    label: "Tools",
    breadcrumb: [
      <TypograpyLink
        linkText="Home"
        color={toolflowTextSecondaryColor}
        to={HOME_NESTED_MAIN_ROUTE_PATH}
        key={"Home"}
      />,
      <Typography
        key="tools"
        sx={{ color: (theme) => theme.palette.text.primary }}
      >
        Tools
      </Typography>
    ]
  }
};
const useGetHomePageContent = () => {
  const { nestedRoute } = useParams<{
    nestedRoute?: HomePageNestedPathsKeys;
  }>();
  const content = homePageNestedPathsDict[nestedRoute || "main"].content;
  const nestedRouteLabel = homePageNestedPathsDict[nestedRoute || "main"].label;
  const nestedRouteBreadcrumb =
    homePageNestedPathsDict[nestedRoute || "main"].breadcrumb;
  return {
    content,
    nestedRouteLabel,
    nestedRouteBreadcrumb
  };
};

export default useGetHomePageContent;
