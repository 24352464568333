import { useMemo } from "react";
import { useGetToolHistoryQuery } from "../../../../../ToolflowAPI/rtkRoutes/toolVersionResponseApi";
import { useAuth0Store } from "../../../../../hooks/useAuth0Store";
import { useSelector } from "react-redux";
import { selectAllMarketplaceEntities } from "../../../../../ToolflowAPI/rtkRoutes/selectors/toolsSelectors";
import type { ExternalToolFE } from "@sharedTypes";
import { useGetMarketplaceEntitiesQuery } from "../../../../../ToolflowAPI/rtkRoutes/marketplaceApi";
import convertExternalToolFEToMarketplaceEntity from "../functions/convertExternalToolFEToMarketplaceEntity";

const useGetSuggestedEntities = () => {
  const { data, isLoading: toolHistoryLoading } = useGetToolHistoryQuery({
    page: 1,
    limit: 10
  });
  const { toolHistory: history } = data;
  const { isLoading } = useAuth0Store();
  useGetMarketplaceEntitiesQuery(undefined, {
    skip: isLoading
  });
  const fetchedMarketplaceEntities = useSelector(selectAllMarketplaceEntities);

  const uniqueTools = useMemo(() => {
    const toolIdSet = new Set();
    if (isLoading || toolHistoryLoading) return [];
    const filteredTools = history
      .filter((tvr) => {
        if (
          typeof tvr.toolId !== "string" &&
          tvr?.toolId?._id &&
          !toolIdSet.has(tvr.toolId._id) &&
          tvr.responseDict
        ) {
          toolIdSet.add(tvr.toolId._id);
          return true;
        }
        return false;
      })
      .map((tvr) =>
        convertExternalToolFEToMarketplaceEntity(tvr.toolId as ExternalToolFE)
      );

    const marketplaceTools = (fetchedMarketplaceEntities || []).filter(
      (tool) => {
        const toolId = tool.id;
        if (!toolIdSet.has(toolId) && tool.type === "tool") {
          toolIdSet.add(toolId);
          return true;
        }
        return false;
      }
    );

    const combinedTools = filteredTools.concat(marketplaceTools);

    return combinedTools.slice(0, 4);
  }, [history, fetchedMarketplaceEntities]);

  return uniqueTools;
};

export default useGetSuggestedEntities;
