import { Typography } from "@mui/material";
import React from "react";
import SettingsDropdownMenuButton from "../../../../utilities/dropdowns/generic/SettingsDropdownMenuButton";
import SettingsMenuItemWithClose from "../../../../utilities/dropdowns/generic/SettingsMenuItemWithClose";
import useAddNewInput from "../../hooks/useAddNewInput";
import useAddNewSheet from "../../hooks/useAddNewSheet";
import { AddIcon, FileIcon, GridIcon } from "../../newDesigns/icons/icons";

const AddSheetMenuItem = () => {
  const newSheet = useAddNewSheet();
  return (
    <SettingsMenuItemWithClose
      icon={<GridIcon size={18} />}
      text="New sheet"
      action={newSheet}
    />
  );
};

const AddAssetMenuItem = () => {
  const newInput = useAddNewInput();
  return (
    <SettingsMenuItemWithClose
      icon={<FileIcon size={18} />}
      text="New asset"
      action={newInput}
    />
  );
};

function AddAssetButton() {
  const options = [
    <AddSheetMenuItem key="newSheet" />,
    <AddAssetMenuItem key="newAsset" />
  ];
  return (
    <SettingsDropdownMenuButton
      menuItems={options}
      icon={
        <>
          <AddIcon size={14} />
          <Typography
            className="text-transform-none m-l-4px"
            variant="smallButton"
          >
            Add new asset
          </Typography>
        </>
      }
      disableToolTip
    />
  );
}

export default AddAssetButton;
