import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import type { SelectChangeEvent } from "@mui/material/Select";
import React, { FC } from "react";
import useUpdateField from "../../useUpdateField";
import type {
  CustomToolInputField,
  CustomToolInputFieldTypes
} from "@sharedTypes";
import isEmpty from "lodash/isEmpty";
import { CREATE_USER_INPUT_LABEL } from "../../../utilities/Inputs/constants";
import { TEXTFIELD } from "../../../utilities/Inputs/inputConstants";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";

interface IGetUrlFromUserProps {
  id: string;
  openInput: () => void;
  settings: { urlFieldInputKey: string };
  updateFieldKey: string;
  handleChangeInitialFieldType: (field: CustomToolInputFieldTypes) => void;
}

const GetUrlFromUser: FC<IGetUrlFromUserProps> = (props) => {
  const {
    id,
    openInput,
    updateFieldKey,
    handleChangeInitialFieldType,
    settings: { urlFieldInputKey }
  } = props;
  const getTextInputFields = (inputs: CustomToolInputField[]) =>
    inputs.filter((field) => field.type === TEXTFIELD);
  const updateField = useUpdateField(id);
  const { state } = useToolbuilderContext();
  const { currentState } = state;
  const { toolInputFields } = currentState;
  const inputTextFields = getTextInputFields(toolInputFields);

  const onFieldTypeChange = () => {
    handleChangeInitialFieldType(TEXTFIELD);
    openInput();
  };

  const updateUserInputUrlState = (event: SelectChangeEvent<string>) => {
    updateField(event.target.value, updateFieldKey);
  };

  const userInputUrlValue = inputTextFields
    .map((o) => o.name)
    .includes(urlFieldInputKey)
    ? urlFieldInputKey
    : "";

  const showError = userInputUrlValue === "";
  const showNoFile = inputTextFields.length === 0;

  return (
    <FormControl margin="normal" fullWidth error={showError}>
      <InputLabel id="user-input-url">Get url from user</InputLabel>
      <Select
        labelId="user-input-url"
        id="userKeywords"
        fullWidth
        label="Get url from user"
        onChange={updateUserInputUrlState}
        className="capitalize nowheel nodrag nopan"
        value={userInputUrlValue}
      >
        <MenuItem value="" onClick={onFieldTypeChange}>
          {CREATE_USER_INPUT_LABEL}
        </MenuItem>
        {inputTextFields.map((option: CustomToolInputField) => (
          <MenuItem value={option.name} key={option.id} className="capitalize">
            {option.name}
          </MenuItem>
        ))}
        {!isEmpty(inputTextFields) && (
          <MenuItem
            value=""
            key="unmap-input"
            onClick={() => updateField("", updateFieldKey)}
          >
            Unmap input
          </MenuItem>
        )}
      </Select>
      {(showNoFile || showError) && (
        <FormHelperText>
          {showError ? "No mapped url input to scrape." : ""}
          {showNoFile
            ? `${
                showError ? " " : ""
              }Create a text field input to use in this block.`
            : ""}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default GetUrlFromUser;
