import type { TCategory } from "@sharedTypes";
import { marketplaceCategoryTabs } from "../categories/constants";

export const getCategoryIconAndLabel = (category: TCategory) => {
  const categoryDict = marketplaceCategoryTabs.find(
    (tab) => tab.value === category
  );
  if (!categoryDict) {
    return { icon: null, label: "" };
  }
  return { icon: categoryDict.RemixIconRaw, label: categoryDict.label };
};
