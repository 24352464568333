import React, { FC } from "react";
import { Box, Chip } from "@mui/material";
import { RiCheckLine } from "@remixicon/react";
import { TUseCaseTag } from "../../../utilities/toolCategorization/hooks/useCategoryTags";
import styles from "./marketplaceUseCases.module.css";

interface IMarketplaceUseCasesProps {
  useCases: TUseCaseTag[];
  handleToggleUseCaseSelection: (useCase: TUseCaseTag) => void;
}
const MarketplaceUseCases: FC<IMarketplaceUseCasesProps> = (props) => {
  const { useCases, handleToggleUseCaseSelection } = props;
  return (
    <Box className="m-v-16px">
      {useCases.map((useCase) => (
        <Chip
          clickable
          className={`m-r-8px m-b-8px capitalize ${
            useCase.selected ? styles.selected : styles.unselected
          }`}
          label={<span style={{ color: "#121729DE" }}>{useCase.value}</span>}
          variant="outlined"
          icon={useCase.selected ? <RiCheckLine /> : undefined}
          key={useCase.value}
          color={useCase.selected ? "primary" : "default"}
          onClick={() => handleToggleUseCaseSelection(useCase)}
        />
      ))}
    </Box>
  );
};

export default MarketplaceUseCases;
