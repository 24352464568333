import { ListItemText, MenuItem } from "@mui/material";
import { useSettingsDropdownContext } from "./useSettingsDropownContext";
import React from "react";

const MenuItemWithClose = ({
  onClick,
  text
}: {
  onClick: () => void;
  text: string;
}) => {
  const { handleClose } = useSettingsDropdownContext();
  const handleClick = (e: React.SyntheticEvent<Element, Event>) => {
    onClick();
    handleClose(e);
  };

  return (
    <MenuItem onClick={handleClick}>
      <ListItemText>{text}</ListItemText>
    </MenuItem>
  );
};

export default MenuItemWithClose;
