import { useToolbuilderContext } from "../context/ToolBuilderContext";

const useDeleteToolInputField = () => {
  const { state, dispatch } = useToolbuilderContext();
  const currentToolInputFieldsIds = state.currentState.toolInputFields.map(
    ({ id }) => id
  );
  const deleteToolInputFieldsByIds = () => {
    if (currentToolInputFieldsIds.length === 0) return;
    dispatch({
      type: "DELETE_TOOL_INPUT_FIELDS_BY_ID",
      toolInputFieldsIds: currentToolInputFieldsIds
    });
  };
  return { deleteToolInputFieldsByIds };
};

export default useDeleteToolInputField;
