import React from "react";
import { Controller } from "react-hook-form";
import { TOOL_TAB_TOOL_ID_FIELD_LABEL } from "../../workspaceConstants";
import WorkspaceToolSelectorSplitter from "./WorkspaceToolSelectorSplitter";

function ToolSelectorForWorkspace() {
  return (
    <Controller
      key={TOOL_TAB_TOOL_ID_FIELD_LABEL}
      name={TOOL_TAB_TOOL_ID_FIELD_LABEL}
      render={() => <WorkspaceToolSelectorSplitter />}
    />
  );
}

export default ToolSelectorForWorkspace;
