import { useMemo } from "react";
import { useToolContext } from "../../contexts/ToolContext";
import getDefaultUserInput from "../functions/getDefaultUserInput";
import type { UserInputDictType } from "@sharedTypes";
import { RootState } from "../../../stores/store";
import { useSelector } from "react-redux";

const useGetDefaultUI = ({
  userInput,
  highlightedText
}: {
  userInput: UserInputDictType;
  highlightedText: string;
}) => {
  const resetKey = useSelector((store: RootState) => store.workspace.resetKey);
  const { tool } = useToolContext();
  const defaultUI = useMemo(() => {
    // if a tool has a userInput already, default to that (for instance after someone saves something)
    if (Object.keys(userInput).length > 0) return userInput;
    // otherwise get the default UserInput if someone has highlightedText
    return getDefaultUserInput(tool?.main.toolInputFields, highlightedText);
  }, [tool?.main.toolInputFields, highlightedText, resetKey]); // we need to have resetKey, so that the defaultUI gets updated when someone runs a tool so that when someone stops generating, the inputs come back
  // current resetKey isnt hooked up

  return defaultUI;
};

export default useGetDefaultUI;
