import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useToolInputFieldsContext } from "./contexts/ToolInputFieldsContext";

function AddInputButton() {
  const { addToolInputField } = useToolInputFieldsContext();

  return (
    <Button
      endIcon={<Add />}
      variant="contained"
      onClick={() => {
        addToolInputField();
      }}
    >
      Request new input
    </Button>
  );
}

export default AddInputButton;
