import type {
  TGetAssetsStep,
  THardCodeInputType,
  TMapToPreviousType,
  TMappedInputsKeyLabel,
  TPreviousFieldInput,
  TPreviousFieldOutput,
  TRequestInputFromUserType,
  TRunToolStep,
  TTagObject,
  TTextStep,
  TToolIdKeyLabel,
  TWorkflowStep
} from "@sharedTypes";
import { v4 as uuidv4 } from "uuid";

export const WORKFLOW_NAME_FIELD_LABEL = "name";
export const WORKFLOW_DESCRIPTION_FIELD_LABEL = "description";
export const DEFAULT_WORKFLOW_NAME = "New workflow";
export const WORKFLOW_NAME_REQUIRED_ERROR = "Please add a workflow name";

export const VISIBILITY_FIELD_LABEL = "visibility";
export const DEFAULT_VISIBILITY = "public";

export const WORKFLOW_TAG_FIELD_LABEL = "tag";
export const DEFAULT_WORKFLOW_TAG: TTagObject = {
  useCases: [],
  categories: []
};

export const WORKFLOW_TAG_USE_CASES_FIELD_LABEL =
  `${WORKFLOW_TAG_FIELD_LABEL}.useCases` as const;
export const WORKFLOW_TAG_CATEGORIES_FIELD_LABEL =
  `${WORKFLOW_TAG_FIELD_LABEL}.categories` as const;

export const WORKFLOW_STEP_NAME_FIELD_LABEL = "name";
export const WORKFLOW_STEP_DESCRIPTION_FIELD_LABEL = "description";
export const WORKFLOW_STEP_TYPE_FIELD_LABEL = "type";

export const WORKFLOW_STEP_NAME_REQUIRED_ERROR = "Please add a step name";

export const WORKFLOW_STEPS_FIELD_LABEL = "steps";

export const WORKFLOW_RUN_TOOL_STEP: TRunToolStep = "runTool";
export const WORKFLOW_TEXT_STEP: TTextStep = "text";
export const WORKFLOW_GET_ASSETS_STEP: TGetAssetsStep = "getAssets";

// tuple necesssary for zod
export const WORKFLOW_ARRAY_TYPES: [TWorkflowStep, ...TWorkflowStep[]] = [
  WORKFLOW_RUN_TOOL_STEP,
  WORKFLOW_TEXT_STEP,
  WORKFLOW_GET_ASSETS_STEP
  // "runInBulk"
];

export const INPUTS_TO_COLLECT_FIELD = "inputsToCollect";

export const TOOL_ID_FIELD: TToolIdKeyLabel = "toolId";
export const MAPPED_INPUTS_FIELD: TMappedInputsKeyLabel = "mappedInputs";

const DEFAULT_RUN_TOOL_SETTINGS = {
  [TOOL_ID_FIELD]: "",
  [MAPPED_INPUTS_FIELD]: {}
};

export const WORKFLOW_INPUTS_TO_COLLECT_FIELD_LABEL =
  `settings.${INPUTS_TO_COLLECT_FIELD}` as const;

export const WORKFLOW_STEP_TOOL_ID_FIELD_LABEL =
  `settings.${TOOL_ID_FIELD}` as const;
export const WORKFLOW_STEP_MAPPED_INPUTS_FIELD_LABEL =
  `settings.${MAPPED_INPUTS_FIELD}` as const;

const DEFAULT_TEXT_SETTINGS = {};
const DEFAULT_GET_ASSETS_SETTINGS = { inputsToCollect: [] };

export const settingsDefaults = {
  [WORKFLOW_RUN_TOOL_STEP]: DEFAULT_RUN_TOOL_SETTINGS,
  [WORKFLOW_TEXT_STEP]: DEFAULT_TEXT_SETTINGS,
  [WORKFLOW_GET_ASSETS_STEP]: DEFAULT_GET_ASSETS_SETTINGS
};

export const getWorkflowDefaultStep = (numberOfExistingSteps: number) => {
  return {
    id: uuidv4(),
    name: `Step ${numberOfExistingSteps + 1}`,
    description: "Description of the workflow step",
    type: WORKFLOW_RUN_TOOL_STEP,
    settings: DEFAULT_RUN_TOOL_SETTINGS
  };
};

export const HARDCODE_INPUT_TYPE: THardCodeInputType = "hardCode";
export const REQUEST_INPUT_TYPE: TRequestInputFromUserType = "requestFromUser";
export const MAP_TO_PREVIOUS_INPUT_TYPE: TMapToPreviousType =
  "mapToPreviousField";

export const WORKFLOW_PREVIOUS_FIELD_OUTPUT: TPreviousFieldOutput = "output";
export const WORKFLOW_PREVIOUS_FIELD_INPUT: TPreviousFieldInput = "input";
