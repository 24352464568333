import React, { useEffect } from "react";
import { categoriesTags } from "./constants";
import type { TCategory } from "@sharedTypes";
import useCategoryTags, { THandleChangeTags } from "./hooks/useCategoryTags";
import AssignTags from "./AssignTags";
const AssignCategoriesNoSaveBase = ({
  initialSelectedTags,
  handleUpdate
}: {
  initialSelectedTags: string[];
  handleUpdate: (s: TCategory[]) => void;
}) => {
  const { handleChange, selectedTags, chipTags, handleRemoveSelectedTag } =
    useCategoryTags({
      initialTags: categoriesTags,
      initialSelectedTags
    });

  const handleChangeTags: THandleChangeTags = ({ event, tagToBeRemoved }) => {
    if (tagToBeRemoved) {
      handleRemoveSelectedTag(tagToBeRemoved);
    } else if (event) {
      handleChange(event);
    }
  };
  useEffect(() => {
    handleUpdate(selectedTags as TCategory[]);
  }, [selectedTags]);

  return (
    <AssignTags
      initialTags={categoriesTags}
      selectedTags={selectedTags}
      chipTags={chipTags}
      handleChangeTags={handleChangeTags}
      disableHelperText={false}
      helperText="These categories will help users find your tool in marketplace."
      label="Add Categories to Tool"
      placeholder="Select Categories"
    />
  );
};

export default AssignCategoriesNoSaveBase;
