import RemixButton from "../../../../layout/icons/RemixButton";
import React from "react";
import { DeleteIcon } from "../../icons/icons";
import useDeleteAsset from "../../hooks/useDeleteAsset";

const DeleteAssetButton = ({
  className = "",
  assetId
}: {
  className?: string;
  assetId: string;
}) => {
  const deleteAsset = useDeleteAsset();

  return (
    <RemixButton
      text="Delete"
      icon={DeleteIcon}
      onClick={() => deleteAsset(assetId)}
      className={className}
    />
  );
};

export default DeleteAssetButton;
