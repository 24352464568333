import { useFormContext } from "react-hook-form";
import type { TWorkspaceDataToSave } from "@sharedTypes";
import { WORKSPACE_ID_FIELD_LABEL } from "../workspaceConstants";

const useWorkspaceId = () => {
  const { watch } = useFormContext<TWorkspaceDataToSave>();
  return watch(WORKSPACE_ID_FIELD_LABEL) || "";
};

export default useWorkspaceId;
