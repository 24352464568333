import type { TWorkflowBuilderForm } from "@sharedTypes";
import { useFormContext } from "react-hook-form";
import { WORKFLOW_TAG_FIELD_LABEL } from "../WorkflowBuilderConstants";

const useGetWorkflowUseCases = () => {
  const { watch } = useFormContext<TWorkflowBuilderForm>();
  return watch(WORKFLOW_TAG_FIELD_LABEL)?.useCases || [];
};

export default useGetWorkflowUseCases;
