import { useFormContext } from "react-hook-form";
import type { TWorkspaceDataToSave, UserInputDictType } from "@sharedTypes";
import { TOOL_TAB_USER_INPUT_FIELD_LABEL } from "../workspaceConstants";

const useGetUserInput = () => {
  const { watch, setValue } = useFormContext<TWorkspaceDataToSave>();
  const userInput = watch(TOOL_TAB_USER_INPUT_FIELD_LABEL) ?? {};
  const setUserInput = (uI: UserInputDictType) => {
    setValue(TOOL_TAB_USER_INPUT_FIELD_LABEL, uI, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    });
  };
  return { userInput, setUserInput };
};

export default useGetUserInput;
