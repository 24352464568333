import React, { useState } from "react";

import type {
  RunBlockToolData,
  UserInputDictType,
  CustomToolInputField,
  ScraperBlockProps,
  TScraperSettings
} from "@sharedTypes";
import CopyableLargeTextField from "../../../utilities/CopyableLargeTextField";
import useShouldWrap from "../../useShouldWrap";
import OutputField from "../../OutputField";
import { v4 as uuidv4 } from "uuid";
import AccordionWrapper from "../../../utilities/AccordionWrapper";
import Grid from "@mui/material/Unstable_Grid2";
import {
  getErrorMessage,
  getIsOutOfCreditsError
} from "../../../utilities/apiHandlerWithAuth";
import TestToolRun from "../../common/TestToolRun";
import useHandleSocketMessage from "../../hooks/useHandleSocketMessage";
import useInnerBlockState from "../../hooks/useInnerBlockState";
import ScraperSettings from "../../settings/scraper/ScraperSettings";
import UrlForm from "../../common/UrlForm";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../features/layout/alerts/alertMessageSlice";
import { useRunBlockMutation } from "../../../ToolflowAPI/rtkRoutes/blockApi";
import { TEXTFIELD } from "../../../utilities/Inputs/inputConstants";
import { COPYABLE_LARGE_TEXTFIELD } from "../../../utilities/Inputs/outputConstants";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";

function ScraperBlockInner({ data, id }: Omit<ScraperBlockProps, "selected">) {
  const { label, settings } = data;
  const { dispatch, state } = useToolbuilderContext();
  const { toolOutput } = state;
  const [shouldWrap, wrapperRef] = useShouldWrap();
  const { loading, output, setLoading } = useHandleSocketMessage();
  const { abort, componentId, useFields } = useInnerBlockState();
  const fields = useFields("", id);
  const [runBlock] = useRunBlockMutation();
  const { toolId, toolVersionId } = state;
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [userInput, setUserInput] = useState<UserInputDictType>({});
  const reduxDispatch = useDispatch();

  function transformList(input: string[]): CustomToolInputField[] {
    let o = input.map((name) => ({
      name,
      id: uuidv4(),
      type: TEXTFIELD
    }));
    o = o.concat([
      {
        name: data.settings.urlFieldInputKey,
        id: uuidv4(),
        type: TEXTFIELD
      }
    ]);
    return o;
  }

  const handleRunBlock = async () => {
    setLoading(true);
    dispatch({
      type: "UPDATE_TOOL_OUTPUT",
      response: null,
      percentCompleted: 0
    });

    const toolInputFields = transformList(fields);
    const toolData: RunBlockToolData = {
      componentId,
      userInput: userInput,
      blockType: "scraperBlockNode",
      toolInputFields,
      blockData: data,
      trackingMetrics: {
        toolId,
        toolVersionId,
        blockId: id
      }
    };

    const handleError = (e: unknown) => {
      if (getIsOutOfCreditsError(e)) {
        setShowUpgrade(true);
      }
      reduxDispatch(setErrorMessage(getErrorMessage(e, "Error running block")));
      setLoading(false);
    };

    try {
      await runBlock(toolData).unwrap();
    } catch (err) {
      handleError(err);
    }
  };

  const runToolOutput = (
    <AccordionWrapper
      elevation={0}
      title="Test Output"
      startsExpanded={!!output}
    >
      {output && (
        <OutputField
          toolOutput={output ? { [data.label]: output } : null}
          toolOutputField={{
            name: data.label,
            type: COPYABLE_LARGE_TEXTFIELD
          }}
          noEditor
        />
      )}
    </AccordionWrapper>
  );

  const runToolCard = (
    <AccordionWrapper title="Test" elevation={0} startsExpanded>
      <UrlForm
        id={id}
        userInput={userInput}
        setFormState={setUserInput}
        updateFieldKey={settings.urlFieldInputKey}
        placeholder="Add url"
      />
      <TestToolRun
        abort={abort}
        handleRunBlock={handleRunBlock}
        loading={loading}
        showUpgrade={showUpgrade}
        blockData={data}
        blockForm={userInput}
      />
    </AccordionWrapper>
  );

  const settingsInner = (
    <>
      <ScraperSettings id={id} settings={settings as TScraperSettings} />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </>
  );

  return (
    <div ref={wrapperRef} className="m-v-16px">
      {shouldWrap ? (
        <Grid container>
          <Grid xs={6}>
            {settingsInner}
            {runToolCard}
          </Grid>
          <Grid xs={6}>{runToolOutput}</Grid>
        </Grid>
      ) : (
        <>
          {settingsInner}
          {runToolCard}
          {runToolOutput}
        </>
      )}
    </div>
  );
}

export default ScraperBlockInner;
