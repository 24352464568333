import type { TWorkspaceDataToSave } from "@sharedTypes";
import useFormIsDirty from "../../../utilities/hooks/useIsDirty";
import { useEffect, useRef, useState } from "react";
import { useWebsocketContext } from "../../../contexts/useWebsocketContext";
import { DateTime } from "luxon";
import useGetCurrentUser from "../../../hooks/useGetCurrentUser";
import { useFormContext } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../stores/store";
import {
  useSaveWorkspaceMutation,
  workspaceApi
} from "../../../ToolflowAPI/rtkRoutes/workspaceApi";
import { selectCurrentUserWorkspaceById } from "../../../ToolflowAPI/rtkRoutes/selectors/workspaceSelectors";
import { debounce } from "lodash";
import { setWorkspaceId } from "../workspaceSlice";
import { globalEventEmitterFE } from "../../../utilities/globalEventEmitterFE";
import { WORKSPACE_TAG_TYPE } from "../../../ToolflowAPI/tagConstants";
import { selectCurrentUserId } from "../../../ToolflowAPI/rtkRoutes/selectors/dynamicSelectors";
import { authenticatedApi } from "../../../ToolflowAPI/authenticatedAPI";
import { WORKSPACE_NAME_FIELD_LABEL } from "../workspaceConstants";
import { ReadyState } from "react-use-websocket";

const useSendMessageOnDirty = () => {
  const [saveWorkspace] = useSaveWorkspaceMutation();
  const { isDirty, dirtyFields } = useFormIsDirty<TWorkspaceDataToSave>();
  const { sendMessage, readyState } = useWebsocketContext();
  const currentUser = useGetCurrentUser();
  const { getValues, reset } = useFormContext<TWorkspaceDataToSave>();
  const workspaceId = useSelector(
    (state: RootState) => state.workspace.workspaceId
  );
  const dispatch = useDispatch();
  const workspace = useSelector(selectCurrentUserWorkspaceById(workspaceId));
  const [initialMessageSent, setInitialMessageSent] = useState(false);
  const currentUserId = useSelector(selectCurrentUserId);

  const [currentUserIdState, setCurrentUserIdState] = useState(currentUserId); // eslint-disable-line
  const currentUserIdRef = useRef(currentUserId);

  useEffect(() => {
    if (currentUserId !== currentUserIdRef.current) {
      setCurrentUserIdState(currentUserId);
      currentUserIdRef.current = currentUserId;
    }
  }, [currentUserId]);

  useEffect(() => {
    const handleWsMessage = async (emittedString: string) => {
      const emittedMessage = JSON.parse(emittedString);
      if (emittedMessage.type === "savedNewWorkspace" && !workspaceId) {
        const newValues = {
          ...getValues(),
          [WORKSPACE_NAME_FIELD_LABEL]: emittedMessage.name
        };
        // we have to await the data being put in the cache before we can set the workspaceId
        // otherwise there is a flash because we fetch the endpoint in WorkspaceFormProvider
        await dispatch(
          workspaceApi.util.upsertQueryData(
            "getWorkspace",
            emittedMessage.workspaceId,
            {
              workspace: { ...workspace, ...newValues }
            }
          )
        );
        dispatch(setWorkspaceId(emittedMessage.workspaceId));
        reset(newValues);
        dispatch(
          authenticatedApi.util.invalidateTags([
            { type: WORKSPACE_TAG_TYPE, id: currentUserIdRef.current }
          ])
        );
      }
    };

    globalEventEmitterFE.on("ws_message", handleWsMessage);

    return () => {
      globalEventEmitterFE.off("ws_message", handleWsMessage);
    };
  }, [dispatch, authenticatedApi, workspaceId]);

  useEffect(() => {
    if (!workspaceId) {
      setInitialMessageSent(false);
    }
  }, [workspaceId]);

  useEffect(() => {
    const sendMessageDebounced = debounce(() => {
      if (sendMessage && currentUser) {
        const timeZone = DateTime.now().zoneName;
        const allValues = getValues();

        const propertiesToSave = Object.fromEntries(
          Object.entries(allValues).filter(([k]) => k in dirtyFields)
        );

        const message = {
          type: "saveWorkstation",
          payload: {
            userId: currentUser._id,
            propertiesToSave,
            timeZone,
            workspaceId
          }
        };

        if (workspaceId) {
          // console.log("Sending follow up message", message);
          // Send subsequent messages only if workspaceId is available
          sendMessage(JSON.stringify(message));
          reset(allValues);
          dispatch(
            workspaceApi.util.updateQueryData(
              "getWorkspace",
              workspaceId,
              () => {
                return { workspace: { ...workspace, ...allValues } };
              }
            )
          );
          if (allValues.name || allValues.about) {
            dispatch(
              authenticatedApi.util.invalidateTags([
                { type: WORKSPACE_TAG_TYPE, id: currentUserIdRef.current }
              ])
            );
          }
          // we don't want to send multiple messages without a workspaceId
          // because that would create a lot of new workspaces
          // instead, we hold off until we get the workspaceId from the backend
        } else if (!initialMessageSent) {
          if (!workspaceId) {
            // console.log("Sending initial message to create workspace", message);
            // Send initial message to create workspace
            sendMessage(JSON.stringify(message));
            setInitialMessageSent(true);
          }
        }
      }
    }, 300);

    // in the future we can deal with the situation in which websockets are totally closed
    // but in the short term, this should only send if the websocket is open
    // and resend when the ready state is good

    // maybe save via API when ReadyState is not open
    if (isDirty && readyState === ReadyState.OPEN) {
      sendMessageDebounced();
    }
    return () => {
      sendMessageDebounced.cancel();
    };
  }, [isDirty, readyState]);

  const unmountingComponent = useRef(false);

  useEffect(() => {
    return () => {
      unmountingComponent.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (workspaceId && unmountingComponent.current) {
        saveWorkspace({ workspaceId, body: getValues() });
      }
    };
  }, [workspaceId]);
};

export default useSendMessageOnDirty;
