import React from "react";
import TogglePrimaryHeaderTextFieldBase from "../../../../utilities/TogglePrimaryHeaderTextFieldBase";
import { FormHelperText } from "@mui/material";
import UpdateStepNameTextField from "./UpdateStepNameTextField";
import { useFormContext } from "react-hook-form";
import useGetWorkflowStepNameFieldById from "./hooks/useGetWorkflowStepNameFieldById";
import type { TWorkflowBuilderForm } from "@sharedTypes";
import ErrorContainer from "../../../../utilities/ErrorContainer";

const ToggleStepNameTextField = ({
  stepId,
  disabled
}: {
  stepId: string;
  disabled?: boolean;
}) => {
  const { watch } = useFormContext<TWorkflowBuilderForm>();
  const fieldLabel = useGetWorkflowStepNameFieldById(stepId);
  const stepName = watch(fieldLabel);

  return (
    <TogglePrimaryHeaderTextFieldBase
      value={stepName}
      disabled={disabled}
      errorMessage={
        <FormHelperText error>
          <ErrorContainer fieldName={stepName} />
        </FormHelperText>
      }
    >
      <UpdateStepNameTextField fieldLabel={fieldLabel} />
    </TogglePrimaryHeaderTextFieldBase>
  );
};

export default ToggleStepNameTextField;
