import type { CustomToolInputField, UserInputDictType } from "@sharedTypes";
import React from "react";
import {
  baseProps,
  inputComponentMap
} from "../../../../../../../utilities/WebsiteForm";
import setFormState from "./functions/setFormState";
import CellWrapper from "./CellWrapper";
import { useSheetContext } from "../SheetContext";
import { TMaterialCell } from "../sheetTypes";

interface InputCellProps {
  cell: TMaterialCell;
  toolInputField: CustomToolInputField;
}

const InputCell: React.FC<InputCellProps> = React.memo(
  ({ cell, toolInputField }: InputCellProps) => {
    const { sheetDataRef, setSheetData } = useSheetContext();
    const InputComponent = inputComponentMap[toolInputField.type].component;
    const formState: UserInputDictType = {};
    Object.entries(cell.row.original).forEach(([k, v]) => {
      if (!!v) {
        formState[k] = v;
      }
    });
    const basePropsObj = baseProps({
      toolInputField,
      setFormState: (e: UserInputDictType) => {
        setFormState(
          cell.row.original.componentId,
          toolInputField.name,
          e[toolInputField.name],
          sheetDataRef,
          setSheetData
        );
      },

      formState
    });

    return (
      <CellWrapper cell={cell}>
        <InputComponent
          {...basePropsObj}
          defaultFormState={cell.row.original}
          disableLabelAndInfo
          disableTools
        />
      </CellWrapper>
    );
  }
);

InputCell.displayName = "InputCell";

export default InputCell;
