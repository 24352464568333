import { useToolbuilderContext } from "../../toolBuilder/context/ToolBuilderContext";
import { TOOL } from "../constants/constants";
import { useDuplicateObject } from "./useDuplicateObject";

// this is hack for now -> we should save and duplicate

const useDuplicateObjectToolbuilderToolbuilder = (id: string) => {
  const { loading, duplicateObject } = useDuplicateObject();
  const { dispatch: tDispatch } = useToolbuilderContext();
  const duplicate = () => {
    tDispatch({ type: "RESET_TOOL" });
    duplicateObject(id, TOOL);
  };
  return { duplicate, loading };
};

export default useDuplicateObjectToolbuilderToolbuilder;
