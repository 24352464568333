import React from "react";
import {
  FormControl,
  MenuItem,
  Select,
  type SelectChangeEvent
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import useGetSelectedStepMappedInputsField from "./hooks/useGetSelectedStepMappedInputs";
import { useCustomToolInputFieldContext } from "../../../../../utilities/contexts/CustomToolInputFieldContext";
import {
  HARDCODE_INPUT_TYPE,
  MAP_TO_PREVIOUS_INPUT_TYPE,
  REQUEST_INPUT_TYPE
} from "../../WorkflowBuilderConstants";
import useGetSelectedStepIndex from "../hooks/useGetSelectedStepId";
import type {
  THandleInputTypeString,
  TWorkflowBuilderForm
} from "@sharedTypes";

function SelectActionForInput() {
  const { input } = useCustomToolInputFieldContext();
  const mappedInputFieldName = useGetSelectedStepMappedInputsField();
  const stepIndex = useGetSelectedStepIndex();
  const { resetField } = useFormContext<TWorkflowBuilderForm>();
  const { watch } = useFormContext<TWorkflowBuilderForm>();
  const mappedField = watch(`${mappedInputFieldName}.${input.id}.type`);
  if (!mappedField) return null;
  return (
    <FormControl size="small">
      <Controller
        name={`${mappedInputFieldName}.${input.id}.type`}
        render={({ field }) => {
          const onChange = (e: SelectChangeEvent) => {
            // we want to reset the fields when they switch
            const selectedStepType = e.target.value as THandleInputTypeString;
            resetField(`${mappedInputFieldName}.${input.id}`);
            field.onChange(selectedStepType);
          };
          return (
            <Select {...field} onChange={onChange}>
              {stepIndex !== 0 && (
                <MenuItem value={MAP_TO_PREVIOUS_INPUT_TYPE}>
                  Use existing field
                </MenuItem>
              )}
              <MenuItem value={HARDCODE_INPUT_TYPE}>Set default value</MenuItem>
              <MenuItem value={REQUEST_INPUT_TYPE}>Request from user</MenuItem>
            </Select>
          );
        }}
      />
    </FormControl>
  );
}

export default SelectActionForInput;
