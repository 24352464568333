import type { TTabValues, TWorkstationTab } from "@sharedTypes";
import { TWorkstationTabObject } from "../../../features/workstation/workstationTypes";
import useSetWorkspaceTab from "../../../features/workstation/hooks/useSetWorkspaceTab";

const useUpdateTabChange = (tabDict: TWorkstationTabObject) => {
  const setWorkspaceTab = useSetWorkspaceTab();
  const handleChange = (event: React.SyntheticEvent, newValue: TTabValues) => {
    const targetKey = (Object.keys(tabDict) as TWorkstationTab[]).find(
      (key) => tabDict[key].value === newValue
    );
    if (targetKey) {
      setWorkspaceTab(targetKey);
    }
  };
  return handleChange;
};

export default useUpdateTabChange;
