import React from "react";
import Box from "@mui/material/Box";

import { FormHelperText } from "@mui/material";
import DragToolOutputMonitor from "./DragToolOutputMonitor";
import DraggableToolOutputField from "./DraggableToolOutputField";
import AddOutputButton from "./AddOutputButton";
import { useToolbuilderContext } from "../context/ToolBuilderContext";

const CustomToolOutputFields = ({ className = "" }: { className?: string }) => {
  const { state } = useToolbuilderContext();
  const { toolOutputFields } = state.currentState;

  return (
    <Box className={`nowheel nodrag nopan ${className}`}>
      <FormHelperText>Drag output to reorganize</FormHelperText>
      <DragToolOutputMonitor>
        {toolOutputFields.map((toolOutputField, index) => (
          <DraggableToolOutputField
            key={toolOutputField.id}
            toolOutputField={toolOutputField}
            index={index}
          />
        ))}
      </DragToolOutputMonitor>
      <div className="flex justify-flex-end">
        <AddOutputButton />
      </div>
    </Box>
  );
};

export default CustomToolOutputFields;
