import React, { FC } from "react";
import type { TMessageRole } from "@sharedTypes";
import type { Content } from "@tiptap/core";
import UserMessage from "./UserMessage";
import AssistantMessage from "./AssistantMessage";

interface IThreadMessagePropsFE {
  role: TMessageRole;
  content: Content;
}
const ThreadMessage: FC<IThreadMessagePropsFE> = (props) => {
  const { role, content } = props;
  const isUserMessage = role === "user";
  const isAssistantMessage = role === "assistant";
  return (
    <>
      {isUserMessage && content !== "" ? (
        <UserMessage messageContent={content} />
      ) : null}
      {isAssistantMessage && content !== "" ? (
        <AssistantMessage messageContent={content} />
      ) : null}
    </>
  );
};

export default ThreadMessage;
