import React from "react";
import {
  deepgramTranscribePromptInitialState,
  deepgramTranscribeInitialState
} from "../../../context/initialStates";
import type { DeepgramBlockData } from "@sharedTypes";
import DraggableBlockContainer from "../../DraggableBlockContainer";
import useParentId from "../../hooks/useParentId";

function DeepgramBlockDraggable({
  open,
  title = "Speech-to-Text"
}: {
  open: boolean;
  title?: string;
}) {
  const parentId = useParentId();
  return (
    <DraggableBlockContainer
      open={open}
      item={{
        type: "deepgramBlockNode",
        data: {
          ...deepgramTranscribeInitialState,
          ...deepgramTranscribePromptInitialState
        } as DeepgramBlockData,
        parentNode: parentId
      }}
      title={title}
    />
  );
}

export default DeepgramBlockDraggable;
