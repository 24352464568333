import { useCallback } from "react";
import getToolNodes, {
  getInputNodes,
  getOutputNodes,
  getSnippetNodes
} from "../../functions/getToolNodes";
import useInsertText from "./useInsertText"; // Import the new hook
import { useRichTextEditorContext } from "mui-tiptap";
import type { Content } from "@tiptap/core";

const useEditorInsertion = () => {
  const editor = useRichTextEditorContext();
  const insertText = useInsertText();

  return useCallback(
    (
      id: string,
      type: "tool" | "input" | "output" | "snippet",
      renderedWithSlash?: boolean,
      userInput = {}
    ) => {
      if (editor) {
        const { from, to } = editor.state.selection;
        const text = editor.state.doc.textBetween(from, to, " ");
        const noParagraphTag = !!editor.state.doc.textBetween(to, to + 1);
        let focus = false;
        let newContent: Content = [];
        if (type === "tool") {
          newContent = getToolNodes(id, text, noParagraphTag, userInput);
        } else if (type === "input") {
          newContent = getInputNodes(id);
          focus = true;
        } else if (type === "output") {
          newContent = getOutputNodes(id);
        } else if (type === "snippet") {
          newContent = getSnippetNodes(id);
          focus = true;
        }
        insertText(newContent, renderedWithSlash, focus);
      }
    },
    [editor, insertText]
  );
};

export default useEditorInsertion;
