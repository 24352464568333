import { Card, CardHeader, Stack } from "@mui/material";
import React, { FC } from "react";
import IconWithCircle from "../../../utilities/IconLogos/IconWithCircle";

interface IFullWidthCardProps {
  icon?: React.ReactNode;
  title?: string;
  subHeader?: string;
  action?: React.ReactNode;
  headerIcon?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}
const FullWidthCard: FC<IFullWidthCardProps> = ({
  icon,
  title = "",
  subHeader,
  action,
  className,
  headerIcon,
  children
}) => {
  return (
    <Card variant="outlined" className={className}>
      {title && (
        <CardHeader
          avatar={icon ? <IconWithCircle icon={icon} width={40} /> : null}
          action={action}
          title={
            <Stack spacing={1} direction={"row"}>
              {headerIcon}
              {title}
            </Stack>
          }
          titleTypographyProps={{
            variant: "subtitle1",
            fontWeight: 500,
            fontFamily: "Sora",
            fontSize: "18px"
          }}
          subheader={subHeader}
          subheaderTypographyProps={{ className: "max-w-640px" }}
        />
      )}
      {children}
    </Card>
  );
};

export default FullWidthCard;
