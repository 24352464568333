import type { THomePageEntity } from "@sharedTypes";
import React, { FC } from "react";
import SettingsDropdownMenuButton from "../../../utilities/dropdowns/generic/SettingsDropdownMenuButton";
import UpdateWorkspaceVisibilityMenuItem from "../../../utilities/dropdowns/menuItems/UpdateWorkspaceVisibilityMenuItem";
import DeleteMenuItem from "../../../utilities/dropdowns/menuItems/DeleteMenuItem";
import { RiMore2Fill } from "@remixicon/react";
import { Divider } from "@mui/material";
import { toolflowTextDisabledColor } from "../../../globalTheme/muiUtils/appTheme";
import RenameHomePageEntityMenuItem from "../../../utilities/dropdowns/menuItems/RenameHomePageEntityMenuItem";
import DuplicateHomePageEntityMenuItem from "../../../toolBuilder/dropdowns/menuItems/DuplicateHomePageEntityMenuItem";
import TogglePopularObjectMenuItem from "../../../utilities/dropdowns/menuItems/TogglePopularObjectMenuItem";
import ToggleMarketplaceObjectMenuItem from "../../../utilities/dropdowns/menuItems/ToggleMarketplaceObjectMenuItem";

interface IHomePageMenuEntityDropdownMenuProps {
  homPageEntity: THomePageEntity;
}
const HomePageMenuEntityDropdownMenu: FC<
  IHomePageMenuEntityDropdownMenuProps
> = ({ homPageEntity }) => {
  const ownMenuItems = [
    <RenameHomePageEntityMenuItem
      key={"rename-entity"}
      entityId={homPageEntity._id}
      entityName={homPageEntity.name}
      entityType={homPageEntity.type}
      mainId={homPageEntity.mainId}
    />,
    <DuplicateHomePageEntityMenuItem
      key={"duplicate-entity"}
      id={homPageEntity._id}
      type={homPageEntity.type}
    />,
    <UpdateWorkspaceVisibilityMenuItem
      visibility={homPageEntity.visibility}
      id={homPageEntity._id}
      name={homPageEntity.name}
      key="updateVisibility"
    />,
    <TogglePopularObjectMenuItem
      key="makePopular"
      type={homPageEntity.type}
      objectId={homPageEntity._id}
    />,
    <ToggleMarketplaceObjectMenuItem
      key="feature"
      type={homPageEntity.type}
      objectId={homPageEntity._id}
    />,
    <Divider key={"divider"} />,
    <DeleteMenuItem
      id={homPageEntity._id}
      name={homPageEntity.name}
      setDisableCard={() => {}}
      type={homPageEntity.type}
      key="deleteMenuItem"
    />
  ];

  const externalMenuItems = [
    <UpdateWorkspaceVisibilityMenuItem
      visibility={homPageEntity.visibility}
      id={homPageEntity._id}
      name={homPageEntity.name}
      key="updateVisibility"
    />
  ];

  return (
    <SettingsDropdownMenuButton
      menuItems={
        homPageEntity.doesUserOwnEntity ? ownMenuItems : externalMenuItems
      }
      icon={<RiMore2Fill size={16} color={toolflowTextDisabledColor} />}
    />
  );
};

export default HomePageMenuEntityDropdownMenu;
