import type { THomePageEntity } from "@sharedTypes";
import { HeadCell, ToolsTableData } from "../utlils/tableTypes";
import { createToolsData } from "../utlils/tableUtils";
import { rowWidthDict } from "../tools/rowWidthDict";

const useToolsTableData = (homePageEntities: THomePageEntity[]) => {
  const rows: ToolsTableData[] = [
    ...homePageEntities.map((entity) =>
      createToolsData({
        entity
      })
    )
  ];
  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      align: "left",
      disablePadding: true,
      label: "Name",
      width: rowWidthDict.name,
      sortable: true
    },
    {
      id: "date",
      align: "left",
      disablePadding: true,
      label: "Last modified",
      width: rowWidthDict.updatedAt,
      sortable: true
    },
    {
      id: "useCases",
      align: "left",
      disablePadding: true,
      label: "Use case",
      width: rowWidthDict.useCases,
      sortable: false
    },
    {
      id: "blocks",
      align: "left",
      disablePadding: true,
      label: "Models used",
      width: rowWidthDict.blocks,
      sortable: false
    },
    {
      id: "actions",
      align: "right",
      disablePadding: true,
      label: "Actions",
      sortable: false
    }
  ];
  return {
    headCells,
    rows
  };
};

export default useToolsTableData;
