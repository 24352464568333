import { Box } from "@mui/material";
import React from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import BottomGradient from "../../../newDesigns/utilities/BottomGradient";
import AssistantMessage from "./AssistantMessage";
import ThreadMessage from "./ThreadMessage";
import useChatMessages from "./hooks/useChatMessages";

function ChatThread() {
  const { socketMessagesContent, threadMessages } = useChatMessages();
  return (
    <Box className="pos-relative oflow-auto h-100-percent">
      <ScrollToBottom
        className={"oflow-auto h-100-percent scroll-to-bottom"}
        followButtonClassName="arrow-button"
        initialScrollBehavior="smooth"
        scrollViewClassName="scrollable-content-important"
      >
        {threadMessages.map((message) => (
          <ThreadMessage key={message.id} {...message} />
        ))}
        {socketMessagesContent && (
          <AssistantMessage messageContent={socketMessagesContent} />
        )}
        <BottomGradient />
      </ScrollToBottom>
    </Box>
  );
}

export default ChatThread;
