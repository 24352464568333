import React from "react";

import useStopInputLoadingOnToolComplete from "./hooks/useStopInputLoadingOnToolComplete";
import { useEditorToolCardContext } from "./EditorToolCardContext";
import { Box } from "@mui/material";

function EditorToolCardWrapper({ children }: { children: React.ReactNode }) {
  const { componentId } = useEditorToolCardContext();

  useStopInputLoadingOnToolComplete();

  return (
    <Box
      className={"w-100-percent"}
      onClick={(e) => e.stopPropagation()}
      // variant="outlined"
      // draggable={!toolStillLoading}
      contentEditable="false"
      suppressContentEditableWarning={true}
      key={componentId}
      sx={{
        "&:hover": (theme) => {
          return {
            borderColor: theme.palette.text.primary,
            borderWidth: 1
          };
        }
      }}
    >
      {children}
    </Box>
  );
}

export default EditorToolCardWrapper;
