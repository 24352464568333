import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setSidebarOpen } from "../features/layout/sidebar/sidebarSlice";
import { homeMatcher, marketplaceMatcher } from "./matchers";
import { updatePaths } from "./navigationSlice";

const RouterListener = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updatePaths({
        path: location.pathname,
        replaced: location.state?.replaced
      })
    );
    const matches = [marketplaceMatcher, homeMatcher].some((matchFunc) =>
      matchFunc(location.pathname)
    );
    // this is temporary when we have only a couple of pages designed
    if (!matches) {
      dispatch(setSidebarOpen());
    }
  }, [location, dispatch]);

  return null;
};

export default RouterListener;
