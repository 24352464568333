import useGetCurrentUser from "../../../hooks/useGetCurrentUser";
import { selectAllProfileTools } from "../../../ToolflowAPI/rtkRoutes/selectors/toolsSelectors";
import { useSelector } from "react-redux";
import { useGetProfileToolsQuery } from "../../../ToolflowAPI/rtkRoutes/toolsApi";
import useIsCurrentUser from "../../../utilities/hooks/useIsCurrentUser";
import type { THomePageEntity } from "@sharedTypes";
import { selectAllProfileWorkflows } from "../../../ToolflowAPI/rtkRoutes/selectors/workflowSelectors";
import { useGetProfileWorkflowsQuery } from "../../../ToolflowAPI/rtkRoutes/workflowApi";

const useGetHomePageEntities = (limit?: number, toolsBlockLimit?: number) => {
  const user = useGetCurrentUser();
  const profileId = user?._id || "";
  const tools = useSelector(selectAllProfileTools(profileId));
  useGetProfileToolsQuery(profileId, { skip: !profileId }); // we need this to stay subscribed to the query
  const currentUserIsProfile = useIsCurrentUser(profileId);
  const workflows = useSelector(selectAllProfileWorkflows(profileId));
  const { isFetching, isLoading } = useGetProfileWorkflowsQuery(profileId, {
    skip: !profileId
  }); // we need this to stay subscribed to the query

  const homePageTools: THomePageEntity[] = tools.map((tool) => ({
    _id: tool._id,
    type: "tool",
    doesUserOwnEntity: currentUserIsProfile,
    name: tool.name,
    tag: tool.tag,
    updatedAt: tool.updatedAt,
    visibility: tool.visibility,
    runCount: tool.runCount
  }));

  const homePageWorkflows: THomePageEntity[] = workflows.map((workflow) => ({
    _id: workflow.id,
    type: "workflow",
    doesUserOwnEntity: currentUserIsProfile,
    name: workflow.name,
    tag: workflow.tag,
    updatedAt: workflow.updatedAt,
    visibility: workflow.visibility,
    mainId: workflow.mainId
  }));
  const homePageEntities: THomePageEntity[] = [
    ...homePageTools,
    ...homePageWorkflows
  ]
    .sort((a, b) => b.updatedAt - a.updatedAt)
    .slice(0, limit);

  const frequentlyUsedEntities: THomePageEntity[] = [...homePageTools]
    .sort((a, b) => {
      const aCount = a?.runCount || 0;
      const bCount = b?.runCount || 0;
      return bCount - aCount;
    })
    .slice(0, toolsBlockLimit);

  const allHomePageEntities: THomePageEntity[] = [
    ...homePageTools,
    ...homePageWorkflows
  ];

  const starredToolEntities: THomePageEntity[] =
    tools
      .filter((tool) => tool.userFavorited)
      .map((tool) => ({
        _id: tool._id,
        type: "tool",
        doesUserOwnEntity: currentUserIsProfile,
        name: tool.name,
        tag: tool.tag,
        updatedAt: tool.updatedAt,
        visibility: tool.visibility
      })) || [];

  const starredWorkflowEntities: THomePageEntity[] =
    workflows
      .filter((workflow) => workflow.userFavorited)
      .map((workflow) => ({
        _id: workflow.id,
        type: "workflow",
        doesUserOwnEntity: currentUserIsProfile,
        name: workflow.name,
        tag: workflow.tag,
        updatedAt: workflow.updatedAt,
        visibility: workflow.visibility,
        mainId: workflow.mainId
      })) || [];

  const starredEntities = [...starredToolEntities, ...starredWorkflowEntities];

  const userCreatedEntities = allHomePageEntities
    .filter((tool) => tool.doesUserOwnEntity)
    .sort((a, b) => b.updatedAt - a.updatedAt)
    .slice(0, toolsBlockLimit);

  return {
    homePageEntities,
    allHomePageEntities,
    isLoading: isFetching || isLoading,
    frequentlyUsedEntities,
    starredEntities,
    userCreatedEntities
  };
};

export default useGetHomePageEntities;
