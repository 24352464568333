import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { useStepsFieldArrayContext } from "../StepsContext";
import { TOnDrop } from "../../../../../utilities/draggables/draggableConstants";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";

const useStepDrop = () => {
  const { move } = useStepsFieldArrayContext();

  const handleDragEnd: TOnDrop = ({ source, location }) => {
    const target = location.current.dropTargets[0];
    if (!target || target.data.type !== "step") {
      return;
    }

    const sourceData = source.data;
    const startIndex = sourceData.index as number;
    const targetData = target.data;
    const indexOfTarget = targetData.index as number;

    const closestEdgeOfTarget = extractClosestEdge(targetData);
    const finishIndex = getReorderDestinationIndex({
      startIndex,
      closestEdgeOfTarget,
      indexOfTarget,
      axis: "vertical"
    });
    move(startIndex, finishIndex);
  };

  return handleDragEnd;
};

export default useStepDrop;
