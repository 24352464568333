import React from "react";
import TermsOfService from "../utilities/TermsOfService";
import Privacy from "../utilities/Privacy";
import Guidelines from "../utilities/Guidelines";

function LoggedOutLinks({}) {
  return (
    <div className="flex align-i-center justify-center m-t-8px">
      <TermsOfService typographyClassName="off-black" />
      <Privacy typographyClassName="m-h-16px off-black" />
      <Guidelines typographyClassName="off-black" />
    </div>
  );
}

export default LoggedOutLinks;
