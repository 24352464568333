// Handle dynamic paths by matching parts of the pathname
import { match } from "path-to-regexp";
import routeTitleDict from "./routeTitleDict";

const getTitleByPath = (path: string): string => {
  return Object.entries(routeTitleDict).reduce((acc, [routePath, title]) => {
    // Skip further checking if a match has already been found
    if (acc) return acc;

    const matcher = match(routePath, { decode: decodeURIComponent });
    if (matcher(path)) {
      if (title) {
        return title;
      }
    }

    return acc;
  }, "");
};

export default getTitleByPath;
