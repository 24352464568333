import React from "react";
import ToggleTextField from "../utilities/ToggleTextField";
import type { WorkflowInput } from "@sharedTypes";
import { type TypographyVariant, type TypographyProps } from "@mui/material";
import UpdateInputNameTextField from "./UpdateInputNameTextfield";

function ToggleUpdateInputNameTextField({
  input,
  tooltipText = "",
  variant = "body2",
  color,
  className = "",
  wrapperClassName = ""
}: {
  input: WorkflowInput;
  tooltipText?: string;
  variant?: TypographyVariant;
  color?: TypographyProps["color"];
  className?: string;
  wrapperClassName?: string;
}) {
  return (
    <ToggleTextField
      color={color}
      variant={variant}
      value={input.name}
      iconHelperText={tooltipText}
      id="dontFocusOnClickId"
      className={className}
      wrapperClassName={wrapperClassName}
      disabled={input.loading}
    >
      <UpdateInputNameTextField input={input} />
    </ToggleTextField>
  );
}
export default ToggleUpdateInputNameTextField;
