import React from "react";
import type {
  ExternalToolFE,
  PopulatedToolVersionResponseFE
} from "@sharedTypes";
import useIsCurrentUser from "../../hooks/useIsCurrentUser";
import SettingsDropdown from "./SettingsDropdown";
import DeleteToolVersionResponseMenuItem from "../menuItems/DeleteToolVersionResponseMenuItem";
import OpenToolToUseMenuItem from "../menuItems/ToolResponseMenuItem";
import NavigateToToolBuilderMenuItem from "../menuItems/NavigateToToolBuilderMenuItem";

function ToolVersionResponseSettingsDropdown({
  noDelete,
  tvr
}: {
  tvr: PopulatedToolVersionResponseFE;
  noDelete?: boolean;
}) {
  const currentUserIsToolOwner = useIsCurrentUser(
    (tvr?.toolId as ExternalToolFE)?.userId
  );
  const menuItems = [
    !noDelete ? (
      <DeleteToolVersionResponseMenuItem id={tvr?._id} key="deleteTVR" />
    ) : null,
    <OpenToolToUseMenuItem
      key="openToolToUse"
      id={(tvr?.toolId as ExternalToolFE)?._id}
      hasEditor={noDelete}
    />,
    currentUserIsToolOwner ? (
      <NavigateToToolBuilderMenuItem
        key="navigateToToolBuilder"
        toolId={tvr?.toolId?._id}
      />
    ) : null
  ];

  return <SettingsDropdown menuItems={menuItems}></SettingsDropdown>;
}

export default ToolVersionResponseSettingsDropdown;
