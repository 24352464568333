import { useMemo } from "react";
import type { Snippet } from "@sharedTypes";
import {
  DEFAULT_SNIPPET_NAME,
  DEFAULT_SNIPPET_VALUE,
  SNIPPET_NAME_FIELD_LABEL,
  SNIPPET_VALUE_FIELD_LABEL
} from "../snippetFields";
import useGetSnippetByParams from "../../hooks/useGetSnippetByParams";

export const SNIPPET_DEFAULT_VALUES = {};

const getDefaultSnippetValues = (snippet?: Snippet) => {
  return {
    [SNIPPET_NAME_FIELD_LABEL]: snippet?.name || DEFAULT_SNIPPET_NAME,
    [SNIPPET_VALUE_FIELD_LABEL]: snippet?.snippetValue || DEFAULT_SNIPPET_VALUE
  };
};

const useDefaultSnippetFormValues = () => {
  const { data } = useGetSnippetByParams();
  return useMemo(
    () => getDefaultSnippetValues(data?.result?.snippet),
    [data?.result?.snippet?._id]
  );
};

export default useDefaultSnippetFormValues;
