import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { setAuthContext } from "../features/auth/authSlice";

function serializeError(error: Error) {
  return {
    message: error.message,
    stack: error.stack
  };
}

const useSetAuthContent = () => {
  const auth0 = useAuth0();

  const { error, isAuthenticated, isLoading, user } = auth0;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setAuthContext({
        error: error ? serializeError(error) : undefined,
        isAuthenticated,
        isLoading,
        user
      })
    );
  }, [auth0]);
};

const Auth0StoreWrapper = ({ children }: { children: React.ReactNode }) => {
  useSetAuthContent();
  return <>{children}</>;
};

export default Auth0StoreWrapper;
