import React from "react";
import AssetSwitch from "./AssetSwitch";

import { Paper } from "@mui/material";
import ToolHistorySuggestions from "../../../leftSideDrawer/toolsTab/ToolHistorySuggestions";
import useGetAssetId from "../../hooks/useGetAssetId";

function AssetContent() {
  const assetId = useGetAssetId();

  if (!assetId) {
    return <ToolHistorySuggestions />;
  }

  return (
    <Paper
      variant="workspaceWrapper"
      className="w-100-percent h-100-percent pos-relative bg-color-white flex justify-space-between"
    >
      <AssetSwitch assetId={assetId} />
    </Paper>
  );
}

export default AssetContent;
