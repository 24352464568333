import type { TMarketplaceEntity } from "@sharedTypes";
import React, { FC } from "react";
import { Grid } from "@mui/material";
import MarketplaceCardContainer from "./marketplaceCard/MarketplaceCardContainer";

interface IMarketplaceCardProps {
  marketplaceEntities: TMarketplaceEntity[];
}
const MarketplaceGrid: FC<IMarketplaceCardProps> = ({
  marketplaceEntities
}) => {
  return (
    <Grid container spacing={3} className="m-b-32px">
      {marketplaceEntities.map((entity) => (
        <Grid item key={entity.id}>
          <MarketplaceCardContainer entity={entity} />
        </Grid>
      ))}
    </Grid>
  );
};

export default MarketplaceGrid;
