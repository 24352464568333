import { createSelector } from "@reduxjs/toolkit";
import { snippetApi } from "../snippetsApi";
import { RootState } from "../../../stores/store";
import {
  snippetAdapter,
  snippetInitialState
} from "../../../stores/entityAdapters";

const selectSnippetsResult = snippetApi.endpoints.getSnippets.select();

const selectSnippetsData = createSelector(
  selectSnippetsResult,
  (snippetResults) => snippetResults.data
);

export const {
  selectAll: selectAllSnippets,
  selectById: selectSnippetById,
  selectIds: selectSnippetIds
} = snippetAdapter.getSelectors(
  (state: RootState) => selectSnippetsData(state) ?? snippetInitialState
);
