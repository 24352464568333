import type { TMarketplaceEntityOptionTypeWithNull } from "@sharedTypes";
import React from "react";
import { useDispatch } from "react-redux";
import { useCaseTags } from "../../toolCategorization/constants";
import { setCategoryWithUseCases } from "../../../features/marketplace/slice/marketplaceSlice";

interface IUseMarketplaceSearchProps {
  handleNavigateMarketplaceEntity: (
    entity: TMarketplaceEntityOptionTypeWithNull
  ) => void;
}
const useMarketplaceSearch = (props: IUseMarketplaceSearchProps) => {
  const { handleNavigateMarketplaceEntity } = props;
  const dispatch = useDispatch();

  const [userSearchValue, setUserSearchValue] = React.useState<string>("");
  const [userSelectedValue, setUserSelectedValue] =
    React.useState<TMarketplaceEntityOptionTypeWithNull>(null);
  const [open, setOpen] = React.useState(false);

  const handleChangeUserSelectedValue = (
    event: React.SyntheticEvent,
    value: TMarketplaceEntityOptionTypeWithNull,
    reason: string
  ) => {
    if (reason === "selectOption" && value) {
      handleNavigateMarketplaceEntity(value);
    }
    const selectedUseCase = useCaseTags.find(
      (useCase) => useCase.value === value?.primary
    );
    if (selectedUseCase) {
      dispatch(
        setCategoryWithUseCases({
          category: "all",
          useCases: [selectedUseCase.value]
        })
      );
      setUserSelectedValue(null);
      setUserSearchValue("");
    } else {
      setUserSelectedValue(value);
    }
  };

  const handleClearSearch = () => {
    setUserSearchValue("");
    setUserSelectedValue(null);
    setOpen(false);
  };
  return {
    handleChangeUserSelectedValue,
    userSearchValue,
    setUserSearchValue,
    userSelectedValue,
    setUserSelectedValue,
    open,
    setOpen,
    handleClearSearch
  };
};

export default useMarketplaceSearch;
