import React from "react";
import useUpdateField from "../../useUpdateField";
import AddKeywordsSettings from "../deepgram/AddKeywordsSettings";
import useDeleteToolInputField from "../../hooks/useDeleteToolInputField";

function AddQueryKeywordsToolBuilder({
  id,
  tags
}: {
  id: string;
  tags: string[];
}) {
  const updateField = useUpdateField(id);
  const { deleteToolInputFieldsByIds } = useDeleteToolInputField();

  const unsavedTagPrefix = "unsaved:";
  const handleChange = (newTags: string[]) => {
    const savedTags = [...new Set(newTags)].filter(
      (tag) => !tag.includes(unsavedTagPrefix)
    );
    updateField(savedTags, "settings.queries");
    if (savedTags.length) {
      deleteToolInputFieldsByIds();
      updateField("", "settings.userQueriesFieldKey");
    }
  };

  return (
    <AddKeywordsSettings
      tags={tags}
      placeholder="Add hardcoded query keywords for tool"
      handleChange={handleChange}
    />
  );
}

export default AddQueryKeywordsToolBuilder;
