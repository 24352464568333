import React from "react";
import type { ButtonVariantTypes } from "@sharedTypes";
import {
  CURRENT_SUBSCRIPTION,
  DOWNGRADE,
  ORGANIZATION_UPGRADE,
  PRO_UPGRADE,
  TCurrentSubscription,
  TDowngrade,
  TUpgrade,
  TUpgradeToOrganization,
  TUpgradeToPro,
  UPGRADE
} from "../../../../settings/BillingCard";
import { RiCheckboxCircleFill } from "@remixicon/react";

export const tierStatusParameters: {
  [key in
    | TDowngrade
    | TUpgrade
    | TCurrentSubscription
    | TUpgradeToOrganization
    | TUpgradeToPro]: {
    text: string;
    disabled: boolean;
    variant: ButtonVariantTypes;
    icon?: React.ReactNode;
    btnLinkAction?: () => void;
  };
} = {
  [DOWNGRADE]: {
    text: "Downgrade",
    disabled: false,
    variant: "outlined",
    btnLinkAction: () => {
      window.open(
        "https://calendly.com/alfie-marsh-toolflow/contact-sales-clone",
        "rel=noopener noreferrer"
      );
    }
  },
  [UPGRADE]: {
    text: "Upgrade",
    disabled: false,
    variant: "contained"
  },
  [CURRENT_SUBSCRIPTION]: {
    text: "Current plan",
    disabled: true,
    variant: "text",
    icon: <RiCheckboxCircleFill color="green" />
  },
  [ORGANIZATION_UPGRADE]: {
    text: "Contact Sales",
    disabled: false,
    variant: "contained",
    btnLinkAction: () => {
      window.open(
        "https://calendly.com/alfie-marsh-toolflow/contact-sales",
        "rel=noopener noreferrer"
      );
    }
  },
  [PRO_UPGRADE]: {
    text: "🚀 Upgrade to Pro",
    disabled: false,
    variant: "contained"
  }
};
