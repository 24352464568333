import React from "react";
import { Box, ListSubheader, Paper } from "@mui/material";
import useGetSuggestedEntities from "./hooks/useGetSuggestedTools";
import SuggestedTool from "./SuggestedTool";
import useGetSelectedWorkspaceTool from "../../hooks/useGetSelectedWorkspaceTool";
import useGetSelectedWorkspaceWorkflow from "../../hooks/useGetSelectedWorkspaceWorkflow";

function ToolHistorySuggestions() {
  const uniqueEntities = useGetSuggestedEntities();
  const tool = useGetSelectedWorkspaceTool();
  const workflow = useGetSelectedWorkspaceWorkflow();

  if (!uniqueEntities.length || uniqueEntities.length === 0 || tool || workflow)
    return null;

  return (
    <Box className="flex flex-grow-1 align-i-center justify-center">
      <Box style={{ width: "100%", maxWidth: 600 }}>
        <Paper variant="outlined" className="width100%">
          <ListSubheader className="bg-color-white">
            Suggested tools
          </ListSubheader>
          {uniqueEntities.map((t) => (
            <SuggestedTool key={t.id} entity={t} />
          ))}
        </Paper>
      </Box>
    </Box>
  );
}

export default ToolHistorySuggestions;
