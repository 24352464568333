import type {
  CustomToolInputField,
  TSetFormState,
  UserInputDictType
} from "@sharedTypes";
import React from "react";
import FileUploadButton from "../FileUploadButton";

export const InputFileUpload = ({
  toolInputField,
  setFormState,
  formState,
  disabled,
  disableLabelAndInfo,
  useIds
}: {
  toolInputField: CustomToolInputField;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  disabled?: boolean;
  disableLabelAndInfo?: boolean;
  useIds?: boolean;
}) => {
  return (
    <FileUploadButton
      // fullWidth
      description={toolInputField.description}
      name={toolInputField.name}
      id={toolInputField.id}
      setFormState={setFormState}
      disabled={disabled}
      formState={formState}
      useIds={useIds}
      size="medium"
      disableLabelAndInfo={disableLabelAndInfo}
    />
  );
};
