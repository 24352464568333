import React from "react";
import type { WorkflowInputString } from "@sharedTypes";
import { getHtmlFromText } from "../utilities/functions/htmlHelpers";
import Workspace from "./Workspace";
import { type JSONContent } from "@tiptap/core";
import useWorkspaceInputs from "../features/workstation/hooks/useWorkspaceInputs";
import { useWorkspaceInputsFieldArrayContext } from "../features/workstation/WorkspaceInputsContext";

function UpdateInputValueTextField({ input }: { input: WorkflowInputString }) {
  const inputs = useWorkspaceInputs();
  const { update } = useWorkspaceInputsFieldArrayContext();
  const handleValueChange = (s: string | JSONContent) => {
    inputs.map((i, index) => {
      update(index, { ...i, value: s } as WorkflowInputString);
    });
  };
  return (
    <Workspace
      disableAutofocus
      enableHover
      focusOnMount={input.source === "manual"} // we want to have autofocus when creating a new input from the tool menu, but not otherwise
      hideTopMenu
      id="dontFocusOnClickId"
      initialContent={
        input.valueType === "string" && typeof input.value === "string"
          ? getHtmlFromText(input.value as string)
          : input.value
      }
      inputType="text"
      onUpdate={handleValueChange}
      preventDefaultEnter
      richTextFieldClassName="p-0px-imp"
      saveWorkspaceType="html"
      variant="standard"
    />
  );
}
export default UpdateInputValueTextField;
