import React from "react";
import useSaveSnippet from "../../hooks/useSaveSnippet";
import SaveSnippetButtonBase from "../../uI/SaveSnippetButtonBase";
import useIsDirty from "../../../../utilities/hooks/useIsDirty";

const SaveSnippetButton = () => {
  const { saveSnippet, loading } = useSaveSnippet();
  const { isDirty } = useIsDirty();
  return (
    <SaveSnippetButtonBase
      disabled={!isDirty}
      loading={loading}
      onClick={saveSnippet}
    />
  );
};

export default SaveSnippetButton;
