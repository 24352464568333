import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

const useLoginRedirectToMarketplace = ({ signUp }: { signUp?: boolean }) => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: signUp ? { screen_hint: "signup" } : undefined,
      appState: {
        returnTo: "/marketplace"
      }
    });
  }, [loginWithRedirect]);
};

export default useLoginRedirectToMarketplace;
