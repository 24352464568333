import React, { useMemo } from "react";
import {
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  Table
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import { useGetSnippetsQuery } from "../../../ToolflowAPI/rtkRoutes/snippetsApi";
import { StyledDisabledTableCell } from "../../home/layout/TableLayouts";
import SnippetRowContainer from "./SnippetRowContainer";
import NameHeaderCellContainer from "./NameHeaderCellContainer";

function SnippetTable({ className = "m-t-32px" }: { className?: string }) {
  const { data } = useGetSnippetsQuery();
  const isAToZ = useSelector(
    (state: RootState) => state.knowledge.snippetNameSortAToZ
  );

  const sortedData = useMemo(() => {
    const dataToSend = data?.ids || [];
    if (isAToZ) {
      return [...dataToSend].reverse();
    } else {
      return dataToSend;
    }
  }, [isAToZ, data]);

  return (
    <TableContainer className={className}>
      <Table>
        <TableHead>
          <TableRow>
            <NameHeaderCellContainer />
            <StyledDisabledTableCell align="right">
              Actions
            </StyledDisabledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((snippetId) => (
            <SnippetRowContainer key={snippetId} snippetId={snippetId} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SnippetTable;
