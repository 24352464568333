import { createFilterOptions, type FilterOptionsState } from "@mui/material";
import { ADD_TOOL_TEXT } from "../toolSelectorConstants";
import type { ToolAutocomplete } from "@sharedTypes";

export const AddToolOption: ToolAutocomplete = {
  primary: ADD_TOOL_TEXT,
  id: ADD_TOOL_TEXT,
  secondary: "",
  group: "Tool"
};

const filter = createFilterOptions<ToolAutocomplete>();

const addNewToolToOptions = (
  options: ToolAutocomplete[],
  params: FilterOptionsState<ToolAutocomplete>
) => {
  const filtered = filter(options, params);

  const { inputValue } = params;
  // we figure out if there is an existing tool with an id with "add", if not, add
  // ids won't impact actual tools
  const isExisting = options.some((option) => inputValue === option.id);
  if (!isExisting) {
    filtered.splice(0, 0, AddToolOption);
  }
  return filtered;
};

export default addNewToolToOptions;
