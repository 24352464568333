import { useSelector } from "react-redux";
import { useAuth0Store } from "../../../hooks/useAuth0Store";
import useGetCurrentUser from "../../../hooks/useGetCurrentUser";
import { selectCurrentUserProfileWorkflows } from "../../../ToolflowAPI/rtkRoutes/selectors/dynamicSelectors";
import { useGetProfileWorkflowsQuery } from "../../../ToolflowAPI/rtkRoutes/workflowApi";
import { useGetMarketplaceEntitiesQuery } from "../../../ToolflowAPI/rtkRoutes/marketplaceApi";
import { selectAllMarketplaceEntities } from "../../../ToolflowAPI/rtkRoutes/selectors/toolsSelectors";
import type { TMarketplaceEntity } from "@sharedTypes";
import { useMemo } from "react";
import convertProfileWorkflowToMarketplaceEntity from "../../../features/workstation/leftSideDrawer/toolsTab/functions/convertProfileWorkflowToMarketplaceEntity";

const useGetAvailableWorkflows = () => {
  const { isAuthenticated, isLoading } = useAuth0Store();
  useGetMarketplaceEntitiesQuery(undefined, {
    skip: isLoading
  });
  const marketplaceEntities = useSelector(selectAllMarketplaceEntities);
  const user = useGetCurrentUser();
  const profileId = user?._id || "";
  // this was having issues with websockets and bson validation errors when no profileId
  useGetProfileWorkflowsQuery(profileId, {
    skip: isLoading || !isAuthenticated || !profileId
  }); // we need to subscribe to the query to update it when things are saved
  const { workflows } = useSelector(selectCurrentUserProfileWorkflows);
  const entities = useMemo(() => {
    return workflows.map((entity) =>
      convertProfileWorkflowToMarketplaceEntity(entity)
    );
  }, [workflows]);
  const uniqueWorkflows = [
    ...marketplaceEntities.filter((entity) => entity.type === "workflow"),
    ...entities
  ].reduce((acc, entity) => {
    acc.set(entity.id, entity);
    return acc;
  }, new Map<string, TMarketplaceEntity>());
  return Array.from(uniqueWorkflows.values());
};

export default useGetAvailableWorkflows;
