import { useDispatch } from "react-redux";
import { resetChatState } from "../../features/workstation/leftSideDrawer/chatTab/chatbox/chatSlice";
import { resetWorkspaceState } from "../../features/workstation/workspaceSlice";

const useResetWorkstation = () => {
  const dispatch = useDispatch();

  const resetWorkstation = () => {
    dispatch(resetChatState());
    dispatch(resetWorkspaceState());
  };

  return resetWorkstation;
};

export default useResetWorkstation;
