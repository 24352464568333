import { useMemo } from "react";
import { useSelector } from "react-redux";
import formatSnippetsForAutocomplete from "../functions/formatSnippetsForAutocomplete";
import { selectAllSnippets } from "../../ToolflowAPI/rtkRoutes/selectors/snippetsSelectors";

const useAutocompleteSnippets = () => {
  const snippets = useSelector(selectAllSnippets);
  return useMemo(() => formatSnippetsForAutocomplete(snippets), [snippets]);
};

export default useAutocompleteSnippets;
