import React from "react";
import MainArea from "./MainArea";
import LoggedOut from "../external/LoggedOut";
import useRouteAuthentication from "../hooks/useRouteAuthentication";

function LoggedOutWrapper() {
  const shouldRenderMainArea = useRouteAuthentication();

  return <>{shouldRenderMainArea ? <MainArea /> : <LoggedOut />}</>;
}

export default LoggedOutWrapper;
