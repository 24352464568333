import type { WorkflowInput } from "@sharedTypes";
import { useWorkspaceInputsFieldArrayContext } from "../../features/workstation/WorkspaceInputsContext";
import useWorkspaceInputs from "../../features/workstation/hooks/useWorkspaceInputs";

const useHandleInputNameChange = ({ input }: { input: WorkflowInput }) => {
  const inputs = useWorkspaceInputs();
  const { update } = useWorkspaceInputsFieldArrayContext();
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    inputs.map((i, index) => {
      if (i.id === input.id) {
        update(index, {
          ...i,
          name: e.target.value
        });
      }
    });
  };
  return handleNameChange;
};

export default useHandleInputNameChange;
