import React from "react";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { useIsAuth0DatabaseConnection } from "../utilities/authHelpers";
import { FormControl } from "@mui/material";
import { useLazyPasswordResetQuery } from "../ToolflowAPI/auth0API";
import useGetCurrentUser from "../hooks/useGetCurrentUser";

function ResetPasswordButton() {
  const { isAuth0DatabaseConnection } = useIsAuth0DatabaseConnection();
  const user = useGetCurrentUser();
  const [passwordReset, { isFetching }] = useLazyPasswordResetQuery();

  const handlePassword = () => {
    if (user?.email) {
      passwordReset(user.email);
    }
  };

  if (!isAuth0DatabaseConnection()) return null;
  return (
    <FormControl margin="none">
      <LoadingButton
        loading={isFetching}
        onClick={handlePassword}
        disabled={isFetching}
        variant="contained"
        size="large"
      >
        Send Password Reset Email
      </LoadingButton>
    </FormControl>
  );
}
export default ResetPasswordButton;
