export const blockDictConstants = {
  DeepgramTranscribe: {
    type: "DeepgramTranscribe",
    label: "Deepgram transcript"
  },
  WebScraper: { type: "WebsiteContentCrawler", label: "Web Scraper" },
  Serp: { type: "serp", label: "Google Search Results" },
  ChatGPT: { type: "ChatGPT", label: "Text Output Name" },
  Anthropic: { type: "Anthropic", label: "Text Output Name" },
  toolWithinTool: { type: "toolWithinTool", label: "" },
  DallE: { type: "Dall-E2", label: "Image Output Name" },
  logic: { type: "logic", label: "" },
  logic2: { type: "logic2", label: "" },
  constant: { type: "constant", label: "" }
};
