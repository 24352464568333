import useWorkspaceInputs from "../../../../hooks/useWorkspaceInputs";
import useGetAssetIndexById from "./useGetAssetIndexById";

const useIsLastAsset = (assetId: string) => {
  const assetIndex = useGetAssetIndexById(assetId);
  const inputLength = useWorkspaceInputs().length;
  return assetIndex === inputLength - 1;
};

export default useIsLastAsset;
