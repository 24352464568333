import type { EditorProps, EditorView } from "@tiptap/pm/view";
import executeBackspace from "../keyboardEvents/executeBackspace";
import executeEnter from "../keyboardEvents/executeEnter";
import executeSlash from "../keyboardEvents/executeSlash";
import type { RichTextEditorRef } from "mui-tiptap";
import useIsLoadingRef from "./useIsLoadingRef";
import useIsSlashRef from "./useIsSlashRef";
import { TextSelection } from "@tiptap/pm/state";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";
import invariant from "tiny-invariant";
import { Editor } from "@tiptap/react";

const useGetEditorProps = ({
  rteRef,
  overrideKeydown
}: {
  rteRef: React.RefObject<RichTextEditorRef>;
  overrideKeydown?: (event: KeyboardEvent, e?: Editor) => void;
}): EditorProps => {
  const { muiTipTapProps, setIsSlash, isSlash, setShouldRenderBubble } =
    useMuiTipTapContext();
  const { preventDefaultEnter, disableFormatting } = muiTipTapProps;
  const isLoadingRef = useIsLoadingRef();
  const isSlashRef = useIsSlashRef(isSlash);
  return {
    handleDOMEvents: {
      keydown: (view: EditorView, event: KeyboardEvent) => {
        if (overrideKeydown && event.key === "Enter") {
          invariant(rteRef.current?.editor);
          overrideKeydown(event, rteRef.current.editor);
          return false;
        }
        if (event.key === "Backspace") {
          executeBackspace(view, event);
        }
        // on inputs, we want to press enter and focus outside the node,
        // we have a event handler helping to focus it in the textoutputfield
        if (preventDefaultEnter && event.key === "Enter") {
          executeEnter(view, event);
        }

        if (event.key === "/") {
          executeSlash({
            view,
            isLoadingRef,
            rteRef,
            setIsSlash,
            setShouldRenderBubble
          });
        }
        if (event.key === "Escape") {
          setShouldRenderBubble(false);
        }
        return false;
      },
      mousedown: () => {
        setShouldRenderBubble(false);
      },
      mouseup: (view, event) => {
        if (
          !(event.target as Element).closest(".dontTriggerBubble") &&
          !isSlashRef.current &&
          !rteRef.current?.editor?.state.selection.empty &&
          rteRef.current?.editor?.state.selection instanceof TextSelection
        ) {
          const editor = rteRef.current?.editor;
          const setValue = !editor?.state.selection.empty;

          // we need this so that this doesnt bubble to handleClickOutside
          // in editormenucontrols when clicking an autocomplete option
          if (setValue) {
            event.stopPropagation();
          }
          if (!disableFormatting) {
            setShouldRenderBubble(!editor?.state.selection.empty);
          }
        }
      }
    },
    handleClick: () => {
      setShouldRenderBubble(false);
    }
  };
};

export default useGetEditorProps;
