import type { TWorkflowBuilderForm } from "@sharedTypes";
import useGetSelectedStepToolIdField from "./useGetSelectedStepToolIdField";
import { useFormContext } from "react-hook-form";
import useGetSelectedWorkflowStepField from "../../hooks/useGetSelectedWorkflowStepField";
import {
  WORKFLOW_RUN_TOOL_STEP,
  settingsDefaults
} from "../../../WorkflowBuilderConstants";

const useSetSelectedTool = () => {
  const { setValue, resetField } = useFormContext<TWorkflowBuilderForm>();
  const fieldLabel = useGetSelectedStepToolIdField();
  const settingsFieldLabel = useGetSelectedWorkflowStepField();

  const setSelectedTool = async (toolId: string | null) => {
    if (toolId) {
      setValue(fieldLabel, toolId, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      });
    } else {
      resetField(`${settingsFieldLabel}.settings`, {
        defaultValue: settingsDefaults[WORKFLOW_RUN_TOOL_STEP]
      });
    }
  };
  return setSelectedTool;
};

export default useSetSelectedTool;
