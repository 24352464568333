import React, { FC } from "react";
import RightSideContainer from "../../utilities/layout/RightSideContainer";
import { Typography } from "@mui/material";
import MarketplaceSearchContainer from "./MarketplaceSearchContainer";
import useMarketplaceEntities from "./hooks/useMarketplaceEntities";
import { useGetMarketplaceEntitiesQuery } from "../../ToolflowAPI/rtkRoutes/marketplaceApi";
import ProfileDropdownMenu from "../profile/dropdown/ProfileDropdownMenu";

interface ICommonTopHeaderProps {
  title: string;
  children: React.ReactNode;
  action?: React.ReactNode;
}
const CommonRightSideContainer: FC<ICommonTopHeaderProps> = ({
  title,
  children,
  action
}) => {
  useGetMarketplaceEntitiesQuery();
  useMarketplaceEntities({
    fromSearch: false
  });
  return (
    <RightSideContainer
      topHeaderSection={
        <Typography className="m-16px" variant="h5">
          {title}
        </Typography>
      }
      headerMidSection={
        <div className="p-h-16px m-v-16px w-100-percent max-w-70-percent">
          <MarketplaceSearchContainer />
        </div>
      }
      actionSection={action ? action : <ProfileDropdownMenu />}
    >
      {children}
    </RightSideContainer>
  );
};

export default CommonRightSideContainer;
