import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import useToolflowNavigate from "./hooks/useToolflowNavigate";
import { WORKFLOWBUILDER_BASE_ROUTE_PATH } from "../navigation/routePaths";

function EditWorkflowButton({
  workflowId,
  className = "",
  icon,
  disabled
}: {
  workflowId: string;
  className?: string;
  icon?: boolean;
  disabled?: boolean;
}) {
  const navigate = useToolflowNavigate();

  if (!workflowId) return null;

  const handleNavigate = (e: React.MouseEvent) => {
    e.stopPropagation(); // necessary for ContentActionArea on ProfileToolCard
    navigate(`${WORKFLOWBUILDER_BASE_ROUTE_PATH}${workflowId}`, e);
  };

  if (icon) {
    return (
      <IconButton
        onClick={handleNavigate}
        className={className}
        disabled={disabled}
      >
        <EditIcon />
      </IconButton>
    );
  }

  return (
    <Button
      size="small"
      variant="text"
      onClick={handleNavigate}
      className={className}
      disabled={disabled}
    >
      Edit
    </Button>
  );
}

export default EditWorkflowButton;
