import React, { FC } from "react";
import ToolflowInfo from "../../../utilities/ToolflowInfo";

interface ILabelWithToolTipProps {
  label: string;
  toolTipContent: string;
}

const LabelWithToolTip: FC<ILabelWithToolTipProps> = (props) => {
  const { label, toolTipContent } = props;
  return (
    <div className="flex m-t-4px">
      {label}
      <span className="m-l-8px">
        <ToolflowInfo placement="bottom" noCharLimit text={toolTipContent} />
      </span>
    </div>
  );
};

export default LabelWithToolTip;
