import { useEffect } from "react";
import useGetSelectedStepToolId from "../runTool/hooks/useGetSelectedStepToolId";
import useGetToolFromQuery from "../../../../../utilities/toolSelector/hooks/useGetToolFromQuery";
import useGetSelectedStep from "./useGetSelectedStep";
import { useFormContext } from "react-hook-form";
import type { TWorkflowBuilderForm } from "@sharedTypes";
import useGetWorkflowStepDescriptionFieldById from "./useGetWorkflowStepDescriptionFieldById";

const useSetDefaultDescriptionOnToolChange = () => {
  const toolId = useGetSelectedStepToolId();
  const tool = useGetToolFromQuery(toolId);
  const step = useGetSelectedStep();
  const fieldLabel = useGetWorkflowStepDescriptionFieldById(step?.id || "");
  const { setValue, getFieldState, register } =
    useFormContext<TWorkflowBuilderForm>();

  useEffect(() => {
    // we need the && step because sometimes the step is undefined which
    // messes things up
    if (!getFieldState(fieldLabel).isTouched && step) {
      register(fieldLabel);
      setValue(fieldLabel, step?.description || tool?.about || "", {
        shouldValidate: true,
        shouldDirty: false
      });
    }
  }, [tool?._id]);
};

export default useSetDefaultDescriptionOnToolChange;
