import React, { useCallback, useRef } from "react";
import Reactflow, {
  Controls,
  Background,
  BackgroundVariant,
  MarkerType,
  applyNodeChanges,
  applyEdgeChanges,
  ReactFlowProvider,
  useReactFlow
} from "reactflow";
import type {
  EdgeChange,
  Node,
  NodeChange,
  OnEdgesChange,
  OnNodesChange,
  Edge as ReactflowEdge
} from "reactflow";
import "reactflow/dist/style.css";
import "reactflow/dist/base.css";
import PromptBlockDrawer from "./blocks/promptBlocks/PromptBlockDrawer";
import OutputBlock from "./blocks/output/OutputBlock";
import InputBlock from "./blocks/input/InputBlock";
import ParentBlock from "./ParentBlock";
import ConstantBlockDrawer from "./blocks/constant/ConstantBlockDrawer";
import LogicBlockDrawer from "./blocks/logic/LogicBlockDrawer";
import CustomEdge from "./CustomEdge";
import BuilderDrawer from "./BuilderDrawer";
import DeleteBlockConfirmationDialog from "./DeleteBlockConfirmationDialog";
import ToolWithinToolBlockDrawer from "./blocks/toolWithinTool/ToolWithinToolBlockDrawer";
import type { TBlock } from "@sharedTypes";
import Header from "./Header";
import { Divider } from "@mui/material";
import DeepgramBlockDrawer from "./blocks/promptBlocks/DeepgramBlockDrawer";
import ScraperBlockDrawer from "./blocks/scraperBlock/ScraperBlockDrawer";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import useOnConnect from "./hooks/useOnConnect";
import useViewports from "./hooks/useViewports";
import useOnDrop from "./hooks/useOnDrop";
import SerpBlockDrawer from "./blocks/serpBlock/SerpBlockDrawer";

const nodeTypes = {
  promptBlockNode: PromptBlockDrawer,
  deepgramBlockNode: DeepgramBlockDrawer,
  scraperBlockNode: ScraperBlockDrawer,
  toolWithinToolBlockNode: ToolWithinToolBlockDrawer,
  outputBlockNode: OutputBlock,
  inputBlockNode: InputBlock,
  parentBlockNode: ParentBlock,
  logicBlockNode: LogicBlockDrawer,
  constantBlockNode: ConstantBlockDrawer,
  serpBlockNode: SerpBlockDrawer
};

const edgeTypes = {
  buttonedge: CustomEdge
};

const markerInfo = {
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 20,
    height: 20
  },
  style: {
    strokeWidth: 3
  },
  type: "buttonedge"
};

const FlowBuilderInner: React.FC = () => {
  const { state, reactflowUtility, dispatch } = useToolbuilderContext();
  const reactFlowInstance = useReactFlow();
  const reactFlowWrapper: React.RefObject<HTMLDivElement> = useRef(null);
  const { tempViewport } = state;
  const { blocks: nodes, edges } = state.currentState;
  const { setEdges, setNodes, setDragParams } = reactflowUtility;

  const onConnect = useOnConnect();
  useViewports(reactFlowInstance);

  const onNodesChange: OnNodesChange = useCallback(
    (changes: NodeChange[]) =>
      setNodes((nds) => applyNodeChanges(changes, nds) as unknown as TBlock[]),
    [setNodes]
  );
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      return setEdges(
        (edgs) => applyEdgeChanges(changes, edgs as unknown as ReactflowEdge[]),
        true
      );
    },
    [setEdges]
  );

  /**
   * This example demonstrates how you can remove the attribution from the React Flow renderer.
   * Please only hide the attribution if you are subscribed to React Flow Pro: https://pro.reactflow.dev
   */
  const proOptions = { hideAttribution: true, account: "example" };

  const onDragOver: React.DragEventHandler<HTMLDivElement> = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    },
    []
  );

  const onNodeClick = (event: React.MouseEvent, n: Node) => {
    if (
      ["parentBlockNode", "inputBlockNode", "outputBlockNode"].includes(
        n.type || ""
      )
    ) {
      if (state.openNodeId !== n.id) {
        dispatch({
          type: "UPDATE_OPEN_NODE",
          nodeId: ""
        });
      }
    } else if (state.openNodeId !== n.id) {
      dispatch({
        type: "UPDATE_OPEN_NODE",
        nodeId: n.id
      });
    }
  };

  const onPaneClick = () => {
    if (!!state.openNodeId) {
      dispatch({
        type: "RESET_OPEN_NODE"
      });
    }
  };

  const onDrop = useOnDrop(reactFlowInstance, reactFlowWrapper);

  return (
    <div>
      <Header />
      <Divider />
      <BuilderDrawer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: `calc(100vh - var(--no-tool-header-height) - 1px)` // the 1px is needed or scroll will appear
          }}
        >
          <div style={{ flexGrow: 1 }} ref={reactFlowWrapper}>
            <Reactflow
              nodes={nodes}
              edges={edges as unknown as ReactflowEdge[]}
              minZoom={0.1}
              maxZoom={0.75}
              defaultViewport={tempViewport || undefined}
              edgeTypes={edgeTypes}
              nodeTypes={nodeTypes}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onDrop={onDrop}
              onDragOver={onDragOver}
              proOptions={proOptions}
              defaultEdgeOptions={markerInfo}
              onNodeClick={onNodeClick}
              onPaneClick={onPaneClick}
              onConnectStart={(e, params) => setDragParams(params)}
              onConnectEnd={() => setDragParams(null)}
              deleteKeyCode={null}
              nodesFocusable={false} // this is so that when menu pops on prompt editor, you can't move / delete nodes. Check deleteKeyCode=null for other options
              // fitView
            >
              <Controls />
              <Background
                id="1"
                gap={100}
                color="#f1f1f1"
                variant={BackgroundVariant.Lines}
              />
            </Reactflow>
            <DeleteBlockConfirmationDialog />
          </div>
        </div>
      </BuilderDrawer>
    </div>
  );
};

function FlowBuilder() {
  return (
    <ReactFlowProvider>
      <FlowBuilderInner />
    </ReactFlowProvider>
  );
}

export default FlowBuilder;
