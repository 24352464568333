import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import type { SelectChangeEvent } from "@mui/material/Select";
import type { LeanFileDocument } from "@sharedTypes";
import { useSelector } from "react-redux";
import { selectAllFiles } from "../ToolflowAPI/rtkRoutes/selectors/fileSelectors";

function FileDropdown({
  action,
  currentFile
}: {
  action: (s: LeanFileDocument | null) => void;
  currentFile: LeanFileDocument | null;
}) {
  const files = useSelector(selectAllFiles);
  const handleSelect = (e: SelectChangeEvent) => {
    if (files) {
      const selectedFile = files.find(
        (file: LeanFileDocument) => file._id === e.target.value
      );
      action(selectedFile || null);
    }
  };

  if (!files || files.length === 0) return null;

  return (
    <FormControl margin="normal" className="flex-grow-1">
      <InputLabel>Loaded Files</InputLabel>
      <Select
        label="Loaded Files"
        value={currentFile?._id || "Upload new file"}
        className="nowheel nodrag nopan"
        onChange={handleSelect}
      >
        <MenuItem value={"Upload new file"}>Upload new file</MenuItem>
        {files &&
          files.map((file: LeanFileDocument) => (
            <MenuItem value={file._id} key={file._id}>
              {file.fileName}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default FileDropdown;
