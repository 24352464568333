import type { ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import { RootState } from "../../../stores/store";
import invalidateTags from "./invalidateTags";
import { TOOL_TAG_TYPE } from "../../tagConstants";

const invalidateToolTags = (
  dispatch: ThunkDispatch<$TSAllowedAny, $TSAllowedAny, UnknownAction>,
  state: RootState
) => {
  invalidateTags(dispatch, state, TOOL_TAG_TYPE);
};

export default invalidateToolTags;
