import React from "react";
import type { ExternalToolFE } from "@sharedTypes";
import DraggableBlock from "../DraggableBlock";
import { useLazyGetToolQuery } from "../../../ToolflowAPI/rtkRoutes/toolsApi";
import useParentId from "../hooks/useParentId";
import DraggableBlockContainer from "../DraggableBlockContainer";
import blockHeaderIconDict from "../blockHeaderIconDict";
import getInitialInputMap from "./functions/getInitialInputMap";
// we do this so that the most recent node ids are present

function ToolWithinToolBlockDraggableInner({
  open,
  tool
}: {
  open: boolean;
  tool: ExternalToolFE;
}) {
  const inputMap = getInitialInputMap(tool);

  const parentId = useParentId();
  return (
    <DraggableBlockContainer
      open={open}
      item={{
        type: "toolWithinToolBlockNode",
        data: {
          label: tool.name,
          type: "toolWithinTool",
          tool,
          inputMap
        },
        parentNode: parentId
      }}
      title={tool.name}
    />
  );
}

function ToolWithinToolBlockDraggable({
  open,
  toolId,
  toolName
}: {
  open: boolean;
  toolId: string;
  toolName: string;
}) {
  const [getLazyTool, { isFetching }] = useLazyGetToolQuery();

  const [tool, setTool] = React.useState<ExternalToolFE | null>(null);

  const onHover = async (tId: string) => {
    if (toolId) {
      const { data } = await getLazyTool(tId, true);
      if (data?.tool) {
        setTool(data.tool);
      }
    }
  };

  return (
    <div onMouseEnter={() => onHover(toolId)} className="w-100-percent">
      {!tool || isFetching ? (
        <DraggableBlock
          title={toolName}
          open={open}
          icon={blockHeaderIconDict.toolWithinTool}
        />
      ) : (
        <ToolWithinToolBlockDraggableInner open={open} tool={tool} />
      )}
    </div>
  );
}

export default ToolWithinToolBlockDraggable;
