import React from "react";
import ToolsBlockTable from "./ToolsBlockTable";
import useGetHomePageEntities from "../../hooks/useGetHomePageEntities";

const StarredEntitiesContainer = () => {
  const { starredEntities } = useGetHomePageEntities(undefined, 6);
  return <ToolsBlockTable toolsBlockEntities={starredEntities} />;
};

export default StarredEntitiesContainer;
