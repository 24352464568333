import { v4 as uuidv4 } from "uuid";
import { useWorkspaceInputsFieldArrayContext } from "../WorkspaceInputsContext";
import { VARIABLE_NAME } from "../workspaceConstants";
import useSetVisibleAsset from "../leftSideDrawer/assetsTab/hooks/useSetVisibleAsset";

function useAddNewInput() {
  const { append } = useWorkspaceInputsFieldArrayContext();
  const setVisibleAsset = useSetVisibleAsset();

  return () => {
    const id = uuidv4();
    append({
      name: `New ${VARIABLE_NAME.toLowerCase()}`,
      source: "manual",
      value: "",
      valueType: "string",
      id
    });
    setVisibleAsset(id);

    return id;
  };
}

export default useAddNewInput;
