import type { TUseCase, TWorkflowBuilderForm } from "@sharedTypes";
import { useFormContext } from "react-hook-form";
import { WORKFLOW_TAG_USE_CASES_FIELD_LABEL } from "../WorkflowBuilderConstants";

const useSetWorkflowUseCases = () => {
  const { setValue, register } = useFormContext<TWorkflowBuilderForm>();
  const setWorkflowCategories = (useCases: TUseCase[]) => {
    register(WORKFLOW_TAG_USE_CASES_FIELD_LABEL);
    setValue(WORKFLOW_TAG_USE_CASES_FIELD_LABEL, useCases, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    });
  };
  return setWorkflowCategories;
};

export default useSetWorkflowUseCases;
