import type { TransformedInputValue } from "@sharedTypes";
import React from "react";
import isToolAsset from "../functions/isToolAsset";
import ToolAssetGroup from "./ToolAssetGroup";
import ManualAssetGroup from "./ManualAssetGroup";

function AssetGroup({ objectValue }: { objectValue: TransformedInputValue }) {
  {
    if (isToolAsset(objectValue)) {
      return <ToolAssetGroup value={objectValue} />;
    } else {
      return <ManualAssetGroup value={objectValue} />;
    }
  }
}

export default AssetGroup;
