import { useRichTextEditorContext } from "mui-tiptap";
import useEditorInsertion from "./useEditorInsertion";
import useInsertText from "./useInsertText";
import type { ToolAutocomplete } from "@sharedTypes";
import { useMuiTipTapContext } from "../../context/MuiTipTapContext";

const useHandleInsertConstant = ({
  slashHasRendered
}: {
  slashHasRendered: boolean;
}) => {
  const { setShouldRenderBubble, muiTipTapProps } = useMuiTipTapContext();
  const { inputType } = muiTipTapProps;
  const editor = useRichTextEditorContext();
  const insert = useEditorInsertion();
  const insertText = useInsertText();
  const handleInsertConstant = (autocomplete?: ToolAutocomplete) => {
    if (!autocomplete || setShouldRenderBubble) {
      setShouldRenderBubble(false);
    }
    if (editor && autocomplete) {
      if (inputType === "chip") {
        insert(autocomplete.id, "snippet", slashHasRendered);
      } else {
        insertText(
          [
            {
              type: "paragraph",
              content: [{ type: "text", text: autocomplete.secondary }]
            }
          ],
          slashHasRendered,
          true
        );
      }
    }
  };
  return handleInsertConstant;
};

export default useHandleInsertConstant;
