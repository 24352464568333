import React, { useRef, useState } from "react";
import {
  MaterialReactTable,
  type MRT_TableInstance
} from "material-react-table";
import type { RowUserInputDictType, TSelectedTool } from "@sharedTypes";
import { TSelectedCell } from "./sheetTypes";
import LoadingSheet from "./utils/LoadingSheet";
import useGetMaterialTable from "./utils/hooks/useGetMaterialTable";
import useRowsRunning from "./utils/hooksWithoutContext/useRowsRunning";
import { SheetContext, useSheetContext } from "./SheetContext";

const SheetInner = () => {
  const { rowsRunning } = useSheetContext();
  const materialTable = useGetMaterialTable();

  if (rowsRunning) {
    return <LoadingSheet />;
  }

  return <MaterialReactTable table={materialTable} />;
};

const SheetContainer = ({
  setSelectedTool,
  selectedTool,
  sheetData,
  setSheetData,
  sheetDataRef
}: {
  selectedTool: TSelectedTool;
  setSelectedTool: (t: string | null) => void;
  sheetData: RowUserInputDictType[];
  setSheetData: React.Dispatch<React.SetStateAction<RowUserInputDictType[]>>;
  sheetDataRef: React.MutableRefObject<RowUserInputDictType[]>;
}) => {
  // TODO -> make sure that all column[0].columns.map work when
  // having multiple selectedTools.
  const [selectedCell, setSelectedCell] = useState<TSelectedCell>(null);

  const { rowsRunning, setRowsRunning } = useRowsRunning({ sheetData });

  // we need the table ref in order to reset pinning
  const tableInstanceRef =
    useRef<MRT_TableInstance<RowUserInputDictType> | null>(null);

  const value = {
    selectedTool,
    setSelectedTool,
    selectedCell,
    setSelectedCell,
    sheetData,
    setSheetData,
    sheetDataRef,
    tableInstanceRef,
    rowsRunning,
    setRowsRunning
  };

  return (
    <SheetContext.Provider value={value}>
      <SheetInner />
    </SheetContext.Provider>
  );
};

export default SheetContainer;
