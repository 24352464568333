import React from "react";
import { Button } from "@mui/material";
import type { ButtonSizeTypes, ButtonVariantTypes } from "@sharedTypes";
import { useLoginBackToPage } from "./authHelpers";

function LoginButton({
  variant,
  size,
  text = "Login",
  className = ""
}: {
  variant?: ButtonVariantTypes;
  size?: ButtonSizeTypes;
  text?: string;
  className?: string;
}) {
  const { loginBackToPage } = useLoginBackToPage();
  return (
    <Button
      variant={variant}
      size={size}
      onClick={loginBackToPage}
      className={className}
    >
      {text}
    </Button>
  );
}

export default LoginButton;
