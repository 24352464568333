import React from "react";
import MarketplaceToolSelector from "../../utilities/marketplaceToolSelector/MarketplaceToolSelector";
import type { TMarketplaceEntityOptionTypeWithNull } from "@sharedTypes";
import { useNavigate } from "react-router-dom";
import { MARKETPLACE_ROUTE_PATH } from "../../navigation/routePaths";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/store";

const MarketplaceSearchContainer = () => {
  const navigate = useNavigate();
  const currentPath = useSelector(
    (state: RootState) => state.navigation.currentPath
  );

  const handleOpenMarketplaceEntity = (
    entity: TMarketplaceEntityOptionTypeWithNull
  ) => {
    const isCurrentPageMarketplace = currentPath === MARKETPLACE_ROUTE_PATH;
    if (entity?.group === "Tools") {
      navigate(`/tool/${entity.id}`);
    } else if (entity?.group === "Workflows") {
      navigate(`/workflow/${entity.id}`);
    } else if (entity?.group === "Use Cases" && !isCurrentPageMarketplace) {
      navigate(MARKETPLACE_ROUTE_PATH);
    }
  };
  return (
    <MarketplaceToolSelector
      handleNavigateMarketplaceEntity={handleOpenMarketplaceEntity}
    />
  );
};

export default MarketplaceSearchContainer;
