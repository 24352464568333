import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel, { a11yProps } from "../utilities/TabPanel";
import FilesPage from "./FilesPage";
import { useParams } from "react-router-dom";
import useToolflowNavigate from "../utilities/hooks/useToolflowNavigate";
import { Lock } from "@mui/icons-material";
import { ORGANIZATION_PLAN_FE, useAuthorizedTier } from "./BillingCard";
import IntegrationsPage from "./IntegrationsPage";
import type { TAvailableTiers } from "@sharedTypes";
import { SETTINGS_BASE_ROUTE_PATH } from "../navigation/routePaths";

type TTabs = "files" | "integrations";

type TTabValues = 0 | 1;

let tabId: TTabValues = 0;
const tabDict: {
  [key in TTabs]: {
    value: TTabValues;
    component: () => React.ReactNode;
    navigateLocation: string;
    authorized?: (tier: TAvailableTiers) => boolean;
    label: string;
  };
} = {
  files: {
    label: "Files",
    value: tabId++ as TTabValues,
    component: FilesPage,
    navigateLocation: SETTINGS_BASE_ROUTE_PATH
  },
  integrations: {
    label: "Integrations",
    value: tabId++ as TTabValues,
    component: IntegrationsPage,
    navigateLocation: "/settings/integrations",
    authorized: (tier) => tier === ORGANIZATION_PLAN_FE
  }
};

function Settings() {
  const { tab: paramTab } = useParams<{ tab?: TTabs }>();
  const navigate = useToolflowNavigate();
  const authorizedTier = useAuthorizedTier();
  const [value, setValue] = useState(paramTab ? tabDict[paramTab]?.value : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: TTabValues) => {
    const targetTab = Object.values(tabDict).find(
      (tab) => tab.value === newValue
    );
    if (
      targetTab &&
      (!targetTab.authorized || targetTab.authorized(authorizedTier))
    ) {
      setValue(newValue);
      navigate(targetTab.navigateLocation, event);
    }
  };

  const objValues = Object.values(tabDict);

  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex"
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        orientation="vertical"
        className="bg-color-white"
        variant="scrollable"
        style={{
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
          width: 160
        }}
      >
        {objValues.map((tab) => {
          const disabled = tab.authorized && !tab.authorized(authorizedTier);
          return (
            <Tab
              component="div"
              key={tab.value}
              label={
                disabled ? (
                  <span className="flex align-i-center justify-space-between w-100-percent">
                    {tab.label}
                    <Lock fontSize="small" />
                  </span>
                ) : (
                  tab.label
                )
              }
              disabled={disabled}
              {...a11yProps(tab.value)}
              classes={{ root: "align-i-flex-start" }}
            />
          );
        })}
      </Tabs>
      {objValues.map((tab) => (
        <TabPanel
          value={value}
          key={tab.value}
          index={tab.value}
          className="w-100-percent"
        >
          {tab.component()}
        </TabPanel>
      ))}
    </div>
  );
}

export default Settings;
