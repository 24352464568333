import type { RowUserInputDictType, WorkflowInputSheet } from "@sharedTypes";
import { useInputContext } from "../../../../../../../../utilities/contexts/InputContext";
import useGetWorkspaceInputIndexById from "../../../../../../hooks/useGetWorkspaceInputIndexById";
import { useWorkspaceInputsFieldArrayContext } from "../../../../../../WorkspaceInputsContext";
import { useEffect } from "react";

const useUpdateFormWithSheetData = ({
  sheetData
}: {
  sheetData: RowUserInputDictType[];
}) => {
  const { input } = useInputContext<WorkflowInputSheet>();
  const getInputIndex = useGetWorkspaceInputIndexById();
  const { update } = useWorkspaceInputsFieldArrayContext();
  const updateSheetDataInForm = (t: RowUserInputDictType[]) => {
    const newInput = {
      ...input,
      value: {
        ...input.value,
        sheetData: t
      }
    };
    update(getInputIndex(input.id), newInput);
  };

  useEffect(() => {
    updateSheetDataInForm(sheetData);
  }, [sheetData]);
};

export default useUpdateFormWithSheetData;
