import type { SelectChangeEvent } from "@mui/material";
import type { VisibilityTypes } from "@sharedTypes";
import React, { createContext } from "react";

export interface ISettingsDropdownContext {
  anchorEl: {
    mouseX: number;
    mouseY: number;
  } | null;
  open: boolean;
  handleClick: (
    event: React.MouseEvent<HTMLElement | HTMLButtonElement, MouseEvent>
  ) => void;
  handleClose: (
    e: SelectChangeEvent<VisibilityTypes> | React.SyntheticEvent<Element, Event>
  ) => void;
  disableToolTip?: boolean;
}

const SettingsDropdownContext = createContext<
  ISettingsDropdownContext | undefined
>(undefined);

export default SettingsDropdownContext;
