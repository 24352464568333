import { useFormContext } from "react-hook-form";
import type { TWorkspaceDataToSave } from "@sharedTypes";
import { TOOL_TAB_COMPONENT_ID_FIELD_LABEL } from "../workspaceConstants";

const useGetSelectedWorkspaceToolComponentId = () => {
  const { watch } = useFormContext<TWorkspaceDataToSave>();
  return watch(TOOL_TAB_COMPONENT_ID_FIELD_LABEL);
};

export default useGetSelectedWorkspaceToolComponentId;
