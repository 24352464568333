import { type SelectChangeEvent } from "@mui/material";
import type { TCategory, TUseCase } from "@sharedTypes";
import { useEffect, useState } from "react";

export type TChipTag = TCategoryTag | TUseCaseTag;
export type TCategoryTag = {
  value: TCategory;
  selected: boolean;
  id: string;
};
export type TUseCaseTag = {
  value: TUseCase;
  selected: boolean;
  id: string;
};
export type THandleChangeTags = ({
  event,
  tagToBeRemoved
}: {
  event?: SelectChangeEvent<string[]>;
  tagToBeRemoved?: TChipTag;
}) => void;
const useCategoryTags = ({
  initialTags,
  initialSelectedTags = []
}: {
  initialTags: TChipTag[];
  initialSelectedTags?: string[];
}) => {
  const [selectedTags, setSelectedTags] =
    useState<string[]>(initialSelectedTags);
  const handleChange = (event: SelectChangeEvent<typeof selectedTags>) => {
    const value = event.target.value;
    // On autofill we get a stringified value.

    setSelectedTags(typeof value === "string" ? value.split(",") : value);
  };

  const handleRemoveSelectedTag = (tag: TChipTag) => {
    setSelectedTags(selectedTags.filter((t) => t !== tag.value));
  };

  useEffect(() => {
    setSelectedTags(initialSelectedTags);
  }, [JSON.stringify(initialSelectedTags)]);
  return {
    selectedTags,
    initialTags,
    handleChange,
    handleRemoveSelectedTag,
    chipTags: initialTags
      .filter((tag) => selectedTags.includes(tag.value))
      .map((tag) => ({ ...tag, selected: true }))
  };
};

export default useCategoryTags;
