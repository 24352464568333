import type { TBlocksTagsLabels } from "@sharedTypes";
import React from "react";
import DalleLogo from "../../../utilities/IconLogos/DalleLogo";
import OpenAILogo from "../../../utilities/IconLogos/OpenAILogo";
import DeepgramLogo from "../../../utilities/IconLogos/DeepgramLogo";
import AnthropicLogo from "../../../utilities/IconLogos/AnthropicLogo";
import WebScraperLogo from "../../../utilities/IconLogos/WebScraperLogo";
import { RiErrorWarningLine, RiStarFill } from "@remixicon/react";
import DalleLogoSvg from "../../../assets/images/openai-logomark.svg";
import WebScraperLogoSvg from "../../../assets/images/Web_scraping_fill.svg";
import AnthropicLogoSvg from "../../../assets/images/Anthropic.svg";
import DeepgramLogoSvg from "../../../assets/images/Deepgram.svg";
import OpenAILogoSvg from "../../../assets/images/ChatGptLogo.svg";

const blockLabelAndIconsDict: Record<TBlocksTagsLabels, JSX.Element> = {
  "Dall-E": <DalleLogo />,
  Serp: <DalleLogo />,
  "Web Scraper": <WebScraperLogo />,
  Anthropic: <AnthropicLogo />,
  Deepgram: <DeepgramLogo />,
  OpenAI: <OpenAILogo />
};
const blockLabelImageDict: Record<TBlocksTagsLabels, string> = {
  "Dall-E": DalleLogoSvg,
  Serp: DalleLogoSvg,
  "Web Scraper": WebScraperLogoSvg,
  Anthropic: AnthropicLogoSvg,
  Deepgram: DeepgramLogoSvg,
  OpenAI: OpenAILogoSvg
};

const getBlockIcon = (blockLabel: TBlocksTagsLabels) => {
  return blockLabelAndIconsDict[blockLabel];
};

export const getBlockIconImageSvg = (blockLabel: TBlocksTagsLabels) => {
  return blockLabelImageDict[blockLabel];
};

export default getBlockIcon;

export const tabsContentDict: Record<string, $TSFixMe> = {
  starred: {
    header: "All your starred tools will appear here",
    subheader: `Check out the "Popular" section to discover some of the best tools`,
    icon: RiStarFill,
    actionButtonText: "Explore popular tools",
    className: "primary-purple",
    categoryToNavigateTo: "popular"
  },
  popular: {
    header: "No popular tools found",
    subheader: `Check out the "All" section to discover some of the best tools`,
    actionButtonText: "Explore all tools",
    icon: RiErrorWarningLine,
    categoryToNavigateTo: "all"
  }
};
export const defaultContentValues = {
  header: "No tools found",
  subheader: `Check out the "Popular" section to discover some of the best tools`,
  actionButtonText: "Explore popular tools",
  icon: RiErrorWarningLine,
  categoryToNavigateTo: "popular"
};

export const getTabsContent = (tab: string) => {
  return tabsContentDict[tab] || defaultContentValues;
};
