import React from "react";
import useGetHomePageEntities from "../../hooks/useGetHomePageEntities";
import useToolsTableData from "../../hooks/useToolsTableData";
import TableSkeleton from "../../layout/TableSkeleton";
import NoRecentData from "../../NoRecentData";
import ToolsPaginatedTable from "../ToolsPaginatedTable";

const FrequentlyUsedEntitiesDetailedContainer = () => {
  const { frequentlyUsedEntities, isLoading } = useGetHomePageEntities();
  const { headCells, rows } = useToolsTableData(frequentlyUsedEntities);
  if (isLoading) {
    return <TableSkeleton numOfRows={15} className="p-16px" />;
  }
  const isHomePageEntitiesEmpty =
    frequentlyUsedEntities?.length === 0 && !isLoading;
  if (isHomePageEntitiesEmpty) {
    return (
      <NoRecentData
        header="Your recent tools will be stored here"
        subHeader="Ready to start? Easily find your first tool in our marketplace and begin exploring the possibilities."
      />
    );
  }
  return (
    <ToolsPaginatedTable
      rows={rows}
      headCells={headCells}
      initialOrderBy="runCount"
    />
  );
};

export default FrequentlyUsedEntitiesDetailedContainer;
