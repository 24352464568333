import { v4 as uuidv4 } from "uuid";
import {
  firstOutputLabel,
  firstInputName
} from "../../utilities/Inputs/constants";
import type {
  CustomToolOutputField,
  ToolState,
  UniqueNameOutput,
  CustomToolInputField,
  TBlock,
  Edge,
  VisibilityTypes
} from "@sharedTypes";
import { TEXTFIELD } from "../../utilities/Inputs/inputConstants";
import { COPYABLE_LARGE_TEXTFIELD } from "../../utilities/Inputs/outputConstants";

// Edge generation function
function generateGraph() {
  const ids = {
    inputBlockNode: uuidv4(),
    parentBlockNode: uuidv4(),
    outputBlockNode: uuidv4()
  };
  const nodes: TBlock[] = [
    {
      id: ids.inputBlockNode,
      deletable: false,
      type: "inputBlockNode",
      data: { label: "Get Inputs" },
      position: { x: 534, y: 0 },
      hidden: true
    },
    {
      id: ids.parentBlockNode,
      data: { label: "Create Assets" },
      position: { x: 0, y: 125 },
      hidden: true,
      style: {
        width: 1400,
        height: 700
      },
      type: "parentBlockNode",
      draggable: false
    },
    {
      id: ids.outputBlockNode,
      type: "outputBlockNode",
      hidden: true,
      data: { label: "Send Outputs", outputType: "Multi" },
      position: { x: 534, y: 875 }
    }
  ];
  const edges: Edge[] = [
    {
      id: `e${nodes[0].id}-${nodes[1].id}`,
      source: nodes[0].id,
      target: nodes[1].id,
      data: { deletable: false }
    },
    {
      id: `e${nodes[1].id}-${nodes[2].id}`,
      source: nodes[1].id,
      target: nodes[2].id,
      data: { deletable: false }
    }
  ];
  return {
    blocks: nodes,
    edges
  };
}

export const createInitialToolInputFields = (): CustomToolInputField[] => [
  { name: firstInputName, type: TEXTFIELD, id: uuidv4() }
];

export const createInitialToolOutputFields = (): CustomToolOutputField[] => [
  { name: firstOutputLabel, type: COPYABLE_LARGE_TEXTFIELD, id: uuidv4() }
];

export const defaultToolName = "New Tool";

export const createNewToolState = (): ToolState => {
  const { blocks, edges } = generateGraph();
  const originalState = {
    toolName: defaultToolName,
    toolAbout: "",
    toolInputFields: [],
    description: "",
    toolOutputFields: [],
    blocks,
    edges,
    availableFields: {},
    visibility: "public" as VisibilityTypes,
    tag: { categories: [], useCases: [] },
    estimatedCreditCost: 0,
    toolOutputOptions: {
      inputs: [],
      blocks: []
    } as UniqueNameOutput
  };

  return {
    toolLoading: false,
    creator: null,
    toolVersionResponse: null,
    toolVersions: {},
    main: null,
    toolId: "",
    toolVersionId: "",
    openNodeId: "",
    userInput: {},
    currentState: originalState,
    originalState: JSON.parse(JSON.stringify(originalState)) // need to deep copy so that the reference to the object changes in memory for originalState purposes
  };
};
