import { Paper } from "@mui/material";
import React from "react";
import SheetOriginalContainer from "./SheetOriginalContainer";

function SheetPage() {
  return (
    <Paper
      variant="outlined"
      //   className="w-100-percent justify-center align-i-center flex h-100vh flex-column"
    >
      <SheetOriginalContainer />
    </Paper>
  );
}

export default SheetPage;
