import type { EmbeddedStep, RunToolStep } from "@sharedTypes";
import useGetSteps from "./useGetSteps";

const useGetWorkflowStepToolIds = () => {
  return (
    useGetSteps().filter(
      (s: EmbeddedStep) => s.type === "runTool"
    ) as RunToolStep[]
  ).map((step: RunToolStep) => step.settings.toolId);
};

export default useGetWorkflowStepToolIds;
