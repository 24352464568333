import React, { useState, FC } from "react";

import type {
  TSerpSettings,
  CustomToolInputField,
  CustomToolInputFieldTypes
} from "@sharedTypes";
import useUpdateField from "../../useUpdateField";
import AddInputDialog from "../../AddInputDialog";
import useSyncCurrentAndPrevSettings from "../../hooks/useSyncCurrentAndPrevSettings";

import { TAGS_INPUT } from "../../../utilities/Inputs/inputConstants";
import GetSearchQueryFromUser from "./GetSearchQueryFromUser";
import AddQueryKeywordsToolBuilder from "./AddQueryKeywordsToolBuilder";
import { Grid } from "@mui/material";
import AccordionWrapper from "../../../utilities/AccordionWrapper";
import SubSettingsLayout from "../deepgram/SubSettingsLayout";
import useDeleteToolInputField from "../../hooks/useDeleteToolInputField";
import ModelOutputSettings from "./ModelOutputSettings";
import ModelDemographicSettings from "./ModelDemographicSettings";
import ModelPaginationSettings from "./ModelPaginationSettings";

interface ISerpBlockSettingsProps {
  id: string;
  settings: TSerpSettings;
}
const SerpBlockSettings: FC<ISerpBlockSettingsProps> = (props) => {
  const { id, settings } = props;
  const updateField = useUpdateField(id);
  const { deleteToolInputFieldsByIds } = useDeleteToolInputField();
  const [openInputModal, setOpenInputModal] = useState(false);
  const [initialFieldType, setInitialFieldType] =
    useState<CustomToolInputFieldTypes>();

  const handleChangeInitialFieldType = (field: CustomToolInputFieldTypes) => {
    setInitialFieldType(field);
  };
  useSyncCurrentAndPrevSettings(settings);

  const updateFromAdd = (field: CustomToolInputField) => {
    if (field.type === TAGS_INPUT) {
      if (settings.queries.length === 0) {
        updateField(field.name, `${settings.userQueriesFieldKey}`);
      } else {
        updateField("", `${settings.userQueriesFieldKey}`);
        deleteToolInputFieldsByIds();
      }
    }
  };

  const openInput = () => {
    setOpenInputModal(true);
  };

  return (
    <>
      <div className="horizontalPadding16">
        <AddInputDialog
          openModal={openInputModal}
          setOpenModal={setOpenInputModal}
          callback={updateFromAdd}
          hideTypeDropdown
          initialType={initialFieldType}
          addNewFieldCondition={settings.queries.length !== 0}
        />
      </div>
      <AccordionWrapper
        title="Configuration"
        className="marginBottom16"
        startsExpanded
        elevation={0}
      >
        <SubSettingsLayout
          title="Query Keywords"
          subheader="Keywords are used to get results from google search result. Each keyword will be separately searched in parallel"
        >
          <AddQueryKeywordsToolBuilder id={id} tags={settings.queries} />
          <GetSearchQueryFromUser
            id={id}
            openInput={openInput}
            settings={settings}
            updateFieldKey="settings.userQueriesFieldKey"
            handleChangeInitialFieldType={handleChangeInitialFieldType}
          />
        </SubSettingsLayout>
        <SubSettingsLayout title="Model Settings">
          <Grid container spacing={2}>
            <ModelOutputSettings
              id={id}
              allowedOutputs={settings.allowedOutputs}
            />
            <ModelDemographicSettings id={id} settings={settings} />
            <ModelPaginationSettings id={id} settings={settings} />
          </Grid>
        </SubSettingsLayout>
      </AccordionWrapper>
    </>
  );
};

export default SerpBlockSettings;
