import React from "react";
import Grid from "@mui/material/Unstable_Grid2";

import Container from "@mui/material/Container";
import PoweredByToolflow from "../PoweredByToolflow";
import LoginTextLink from "../LoginTextLink";
import LoggedOutLinks from "../../external/LoggedOutLinks";
import { useAuth0Store } from "../../hooks/useAuth0Store";
import styles from "./externalPageWrapper.module.css";

function ExternalPageWrapper({
  children,
  enableFullHeight
}: {
  children: React.ReactNode;
  enableFullHeight?: boolean;
}) {
  const { isAuthenticated, isLoading } = useAuth0Store();
  // we don't want fullHeight when logged out with the workspace,
  // but we do want when it is logged in
  const fullHeight = enableFullHeight && isAuthenticated;
  return (
    <>
      <div
        className="flex flex-column w-100-percent h-100vh oflow-y-flex"
        style={{
          maxHeight: "100vh"
        }}
      >
        <div
          className={`${styles["external-page"]} ${
            fullHeight ? "h-100-percent" : ""
          }`}
        >
          <Container maxWidth="md" className={`h-100-percent`}>
            <Grid container className={fullHeight ? "h-100-percent" : ""}>
              <Grid xs={12}>
                {!isAuthenticated && !isLoading && (
                  <Container
                    maxWidth="xs"
                    className="justify-center flex m-b-8px"
                  >
                    <LoginTextLink className="m-r-8px" />
                    <PoweredByToolflow />
                  </Container>
                )}
                {children}

                {!isAuthenticated && !isLoading && <LoggedOutLinks />}
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ExternalPageWrapper;
