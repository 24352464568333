import { useMemo } from "react";
import useMarketplaceEntities from "../../../features/marketplace/hooks/useMarketplaceEntities";

const useToolIsPopular = (objectId: string) => {
  const { filteredMarketplaceEntities } = useMarketplaceEntities({
    fromSearch: true
  });

  return useMemo(() => {
    console.log(
      "filteredMarketplaceEntities",
      objectId,
      filteredMarketplaceEntities
    );
    return !!filteredMarketplaceEntities.find(
      (entity) => entity.id === objectId && entity.isPopular
    );
  }, [filteredMarketplaceEntities, objectId]);
};

export default useToolIsPopular;
