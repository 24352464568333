import React from "react";
import ExportCSVButton from "../../../../../../../utilities/ExportToCSVButton";
import useHandleExportRows from "./hooks/useHandleExportRows";
import { DateTime } from "luxon";
import { useSheetContext } from "../SheetContext";
import { TMaterialTable } from "../sheetTypes";

const ExportSelectedRowsButton = ({ table }: { table: TMaterialTable }) => {
  const { selectedTool } = useSheetContext();
  const handleExportRows = useHandleExportRows();
  return (
    <ExportCSVButton
      name="Export"
      dynamicData={() => handleExportRows(table.getSelectedRowModel().rows)}
      fileName={`${
        selectedTool?.name || "Tool"
      } - ${DateTime.now().toISODate()}`}
    />
  );
};

export default ExportSelectedRowsButton;
