import type { THomePageEntity } from "@sharedTypes";
import React, { FC } from "react";
import BaseSettingsDropdown from "../../../../utilities/dropdowns/generic/base/BaseSettingsDropdown";
import { Grid, Table, TableBody, TableContainer } from "@mui/material";
import { createToolsData } from "../../utlils/tableUtils";
import ToolBlockEntityTableRow from "./ToolBlockEntityTableRow";

interface IToolsBlockTableProps {
  toolsBlockEntities: THomePageEntity[];
}
const ToolsBlockTable: FC<IToolsBlockTableProps> = (props) => {
  const { toolsBlockEntities } = props;
  if (!toolsBlockEntities?.length) {
    return null;
  }
  const firstHalfEntities = toolsBlockEntities.slice(
    0,
    Math.ceil(toolsBlockEntities.length / 2)
  );
  const secondHalfEntities = toolsBlockEntities.slice(
    Math.ceil(toolsBlockEntities.length / 2)
  );
  return (
    <BaseSettingsDropdown>
      <Grid container columnSpacing={3} className="p-h-16px" rowSpacing={0}>
        <Grid item xs={12} md={6}>
          <TableContainer>
            <Table>
              <TableBody>
                {firstHalfEntities.map((entity) => (
                  <ToolBlockEntityTableRow
                    key={entity._id}
                    entity={createToolsData({ entity })}
                    removeLastRowBorder
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <TableContainer>
            <Table>
              <TableBody>
                {secondHalfEntities.map((entity) => (
                  <ToolBlockEntityTableRow
                    key={entity._id}
                    entity={createToolsData({ entity })}
                    removeLastRowBorder
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </BaseSettingsDropdown>
  );
};

export default ToolsBlockTable;
