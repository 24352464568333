import React from "react";
import ToolflowSkeletonWrapper from "../utilities/ToolflowSkeleton";
import { MenuControlsContainer } from "mui-tiptap";
import WorkspaceSettingsDropdown from "./dropdowns/WorkspaceSettingsDropdown";
import { useParams } from "react-router-dom";
import { useMuiTipTapContext } from "./context/MuiTipTapContext";
import useWorkspaceName from "../features/workstation/hooks/useWorkspaceName";
import useWorkspaceId from "../features/workstation/hooks/useWorkspaceId";
import useWorkspaceVisibility from "../features/workstation/hooks/useWorkspaceVisibility";
import useGetInitialWorkspace from "./hooks/useGetInitialWorkspace";

const WorkspaceRenderControls = () => {
  const { muiTipTapProps } = useMuiTipTapContext();
  const { externalId = "" } = useParams();
  const { isLoading } = useGetInitialWorkspace();
  const name = useWorkspaceName();
  const workspaceId = useWorkspaceId();
  const visibility = useWorkspaceVisibility();
  const { routeType } = muiTipTapProps;
  return (
    <ToolflowSkeletonWrapper
      height={40}
      loading={
        isLoading || !!(!workspaceId && externalId && routeType === "workspace") // this last clause is to disable the flash that happens after the workspace has been loaded, but not set in the reducer
      }
    >
      <MenuControlsContainer className="justify-space-between h-40px">
        <div>
          <WorkspaceSettingsDropdown
            workspaceId={workspaceId}
            workspaceName={name}
            workspaceVisibility={visibility}
          />
        </div>
      </MenuControlsContainer>
    </ToolflowSkeletonWrapper>
  );
};

export default WorkspaceRenderControls;
