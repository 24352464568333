import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardHeader,
  List,
  ListSubheader
} from "@mui/material";
import type { ToolWithinToolBlockData } from "@sharedTypes";

import OutputListItem from "./OutputListItem";
import useCompatibilityMap from "./hooks/useCompatibilityMap";

function OutputMapper({
  data,
  id
}: {
  data: ToolWithinToolBlockData;
  id: string;
}) {
  const { tool, inputMap } = data;

  const compatibilityMap = useCompatibilityMap({ id, tool });

  return (
    <Card>
      <CardHeader title="Map Inputs" />
      <CardContent>
        <Typography>
          This tool uses the following inputs to function. Map these tool inputs
          to available fields from the dropdown menu or create them as new
          fields.
        </Typography>
        <List disablePadding className="m-t-16px">
          <ListSubheader>Inputs</ListSubheader>
          {Object.entries(compatibilityMap).map(([field, value], idx) => (
            <OutputListItem
              field={field}
              value={value}
              key={idx}
              inputMap={inputMap}
              id={id}
            />
          ))}
        </List>
      </CardContent>
    </Card>
  );
}

export default OutputMapper;
