import { Button } from "@mui/material";
import type { RowUserInputDictType } from "@sharedTypes";
import type { MRT_Row } from "material-react-table";
import React from "react";
import { useSheetContext } from "../SheetContext";
import { TMaterialTable } from "../sheetTypes";

const DeleteSelectedRowsButton = ({ table }: { table: TMaterialTable }) => {
  const { sheetData, setSheetData } = useSheetContext();
  const deleteSelectedRows = (rows: MRT_Row<RowUserInputDictType>[]) => {
    const selectedRowIds = rows.map((row) => row.id); // Assuming rows have an 'id' field
    const newData = sheetData.filter(
      (row) => !selectedRowIds.includes(row.componentId)
    );
    setSheetData(newData);
  };
  return (
    <Button
      onClick={() => {
        deleteSelectedRows(table.getSelectedRowModel().rows);
        table.resetRowSelection();
      }}
    >
      Delete
    </Button>
  );
};

export default DeleteSelectedRowsButton;
