import { useState } from "react";
import useToolInputFieldsAndAvailableFields from "../useToolInputFieldsAndAvailableFields.ts";
import type { ValidatedInput } from "@sharedTypes";
import { v4 as uuidv4 } from "uuid";
import { useAbortComponentMutation } from "../../ToolflowAPI/rtkRoutes/toolsApi";

const useInnerBlockState = () => {
  const [componentId, setComponentId] = useState(uuidv4());
  const [abortComponent] = useAbortComponentMutation();
  const abort = () => {
    abortComponent(componentId);
    // if (sendMessage) {
    //   sendMessage(
    //     JSON.stringify({
    //       type: "abortStream",
    //       payload: { componentId }
    //     })
    //   );
    // }
    if (setComponentId) {
      setComponentId(uuidv4());
    }
  };
  const useFields = (input: string, id: string): string[] => {
    const toolInputFields = useToolInputFieldsAndAvailableFields(id);
    // we only want fields that are actually available to the block
    const inputNames = toolInputFields.map((i: ValidatedInput) => i.name);
    const regex = /{{(.*?)}}/g;
    let match;
    const tags: Set<string> = new Set();

    while ((match = regex.exec(input)) !== null) {
      if (inputNames.includes(match[1])) {
        tags.add(match[1]);
      }
    }

    // Convert Set back to array for returning
    return Array.from(tags);
  };
  return {
    useFields,
    componentId,
    abort
  };
};

export default useInnerBlockState;
