import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import useIsUniqueField from "../../useIsUniqueField";

const useChangeInputNameToolbuilder = () => {
  const { state, dispatch } = useToolbuilderContext();
  const { toolOutputOptions } = state.currentState;
  const isUniqueField = useIsUniqueField();
  const handleToolInputFieldChangeName = (value: string, index: number) => {
    if (isUniqueField(value, toolOutputOptions)) {
      dispatch({
        type: "UPDATE_INPUT_FIELD_NAME",
        newName: value,
        index
      });
    }
  };

  return handleToolInputFieldChangeName;
};

export default useChangeInputNameToolbuilder;
