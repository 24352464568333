import React, { createContext } from "react";
import type { ButtonSizeTypes } from "@sharedTypes";
import FavoritedObject from "./FavoritedObject";
import UnfavoritedObject from "./UnfavoritedObject";

interface IFavoriteBase {
  size?: ButtonSizeTypes;
}

export interface IToggleFavoriteTool extends IFavoriteBase {
  id: string;
  type: "tool" | "workflow";
  favorited?: boolean;
  className?: string;
  creatorId: string;
  inMarketplace?: boolean;
}

export const FavoriteContext = createContext<IToggleFavoriteTool | undefined>(
  undefined
);

function ToggleFavorite({
  id,
  type,
  favorited,
  className = "",
  size = "small",
  creatorId,
  inMarketplace
}: IToggleFavoriteTool) {
  return (
    <FavoriteContext.Provider
      value={{
        id,
        type,
        favorited,
        creatorId,
        inMarketplace
      }}
    >
      {favorited ? (
        <FavoritedObject className={className} size={size} />
      ) : (
        <UnfavoritedObject className={className} size={size} />
      )}
    </FavoriteContext.Provider>
  );
}

export default ToggleFavorite;
