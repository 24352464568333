import React from "react";
import Box from "@mui/material/Box";
import PromptTextField from "./PromptTextField";
import PromptMenu from "./PromptMenu";
import { PromptEditorContextComponent } from "./context/PromptEditorContext";
import PromptEditorAddInputDialog from "./PromptEditorAddInputDialog";

const PromptEditorInner = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <PromptTextField />
      <PromptMenu />
      <PromptEditorAddInputDialog />
    </Box>
  );
};

function PromptEditor({
  id,
  prompt,
  helperText,
  maxLength,
  handleUpdatePrompt
}: {
  id: string;
  prompt: string;
  helperText?: string;
  maxLength?: number;
  handleUpdatePrompt: (newPrompt: string) => void;
}) {
  return (
    <PromptEditorContextComponent
      prompt={prompt}
      handleUpdatePrompt={handleUpdatePrompt}
      id={id}
      maxLength={maxLength}
      helperText={helperText}
    >
      <PromptEditorInner />
    </PromptEditorContextComponent>
  );
}

export default PromptEditor;
