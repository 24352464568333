import React from "react";
import { Navigate } from "react-router-dom";
import WorkflowFormProvider from "./WorkflowFormProvider";
import AuthenticationSplitter from "../../../utilities/AuthenticationSplitter";
import WorkflowBuilderPageLoading from "./WorkflowBuilderPageLoading";
import WorkflowNavigationProvider from "./WorkflowNavigationProvider";
import useGetWorkflowByParams from "./hooks/useGetWorkflowByParams";
import useGetWorkflowVersionsByParams from "./hooks/useGetWorkflowVersionsByParams";
import { FieldArrayFormProvider } from "./steps/StepsContext";
import WorkflowSteps from "./steps/WorkflowSteps";
import WorkflowBuilderHeader from "./WorkflowBuilderHeader";

const WorkflowBuilderPageInner = () => {
  return (
    <WorkflowFormProvider>
      <WorkflowNavigationProvider>
        <WorkflowBuilderHeader />
        <FieldArrayFormProvider>
          <WorkflowSteps />
        </FieldArrayFormProvider>
      </WorkflowNavigationProvider>
    </WorkflowFormProvider>
  );
};

const WorkflowBuilderPageContainer = () => {
  const { isLoading, isError } = useGetWorkflowByParams();
  const { isLoading: versionsIsLoading } = useGetWorkflowVersionsByParams();

  if (isLoading || versionsIsLoading) {
    return <WorkflowBuilderPageLoading />;
  }
  if (isError) {
    return <Navigate to="/" replace />;
  }
  return <WorkflowBuilderPageInner />;
};

const WorkflowBuilderPage = () => {
  return (
    <AuthenticationSplitter
      loadingComponent={<WorkflowBuilderPageLoading />}
      authenticatedComponent={<WorkflowBuilderPageContainer />}
      externalComponent={<Navigate to="/" replace />}
    />
  );
};

export default WorkflowBuilderPage;
