import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

function OpenAIBillingLink({ className = "" }: { className?: string }) {
  return (
    <Link
      to="https://platform.openai.com/account/billing/overview"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Button className={`m-r-8px ${className}`}>Set Up Billing</Button>
    </Link>
  );
}

export default OpenAIBillingLink;
