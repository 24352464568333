import React, { FC } from "react";
import { BoldTableCell, StyledTableCell } from "../layout/TableLayouts";
import { getTimeAgoString } from "../../../utilities/formatters/getTimeAgoString";
import RemixWrapperIconWithTheme from "../../layout/icons/RemixWrapperIconWithTheme";
import { RiFocus2Line } from "@remixicon/react";
import CopyLinkMenuItemInHome from "../../../workspace/dropdowns/menuItems/CopyLinkMenuItemInHome";
import NavigateToBuilderMenuItem from "../../../utilities/dropdowns/menuItems/NavigateToBuilderMenuItemInHome";
import HomePageMenuEntityDropdownMenu from "./HomePageMenuEntityDropdownMenu";
import DuplicateHomePageEntityMenuItem from "../../../toolBuilder/dropdowns/menuItems/DuplicateHomePageEntityMenuItem";
import MarketplaceBlockTags from "../../marketplace/card/MarketplaceBlockTags";
import EntityRow from "./EntityRow";
import { Typography } from "@mui/material";
import { ToolsTableData } from "../utlils/tableTypes";
import { convertRowToEntity } from "../utlils/tableUtils";
import { rowWidthDict } from "./rowWidthDict";
import { toolflowPrimaryColor } from "../../../globalTheme/muiUtils/appTheme";

interface IEntityTableRowProps {
  entity: ToolsTableData;
  removeLastRowBorder?: boolean;
}
const EntityTableRow: FC<IEntityTableRowProps> = ({
  entity,
  removeLastRowBorder
}) => {
  const blocks = entity?.blocks || [];
  return (
    <EntityRow
      key={entity.id}
      entityId={entity.id}
      entityType={entity.type}
      removeLastRowBorder={removeLastRowBorder}
    >
      <BoldTableCell sx={{ width: rowWidthDict.name }}>
        {entity.name}
      </BoldTableCell>
      <StyledTableCell sx={{ width: rowWidthDict.updatedAt }}>
        {getTimeAgoString(entity.date)}
      </StyledTableCell>
      <StyledTableCell sx={{ width: rowWidthDict.useCases }}>
        <div className="flex align-i-center">
          <div className="m-t-4px m-r-8px">
            {!!entity.useCases?.length && (
              <RemixWrapperIconWithTheme
                Icon={RiFocus2Line}
                size={18}
                providedColor={toolflowPrimaryColor}
              />
            )}
          </div>
          <div>
            {entity.useCases?.slice(0, 2).map((useCase, index, array) => (
              <Typography
                variant="body2"
                key={useCase}
                className="capitalize"
                sx={{ display: "inline-block" }}
              >
                {useCase}
                {index !== array.length - 1 ? "/" : null}
              </Typography>
            ))}
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell sx={{ width: rowWidthDict.blocks }}>
        <MarketplaceBlockTags
          blockLabels={blocks}
          maxBlocks={3}
          iconOnly={true}
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        <CopyLinkMenuItemInHome objectId={entity.id} type={entity.type} />
        {entity.doesUserOwnEntity ? (
          <NavigateToBuilderMenuItem entityId={entity.id} type={entity.type} />
        ) : (
          <DuplicateHomePageEntityMenuItem
            id={entity.id}
            type={entity.type}
            iconOnly={true}
          />
        )}
        <HomePageMenuEntityDropdownMenu
          homPageEntity={convertRowToEntity(entity)}
        />
      </StyledTableCell>
    </EntityRow>
  );
};

export default EntityTableRow;
