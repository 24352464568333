import React from "react";
import { Icon } from "@mui/material";
import Logo from "../../assets/images/folder.svg";

function FolderCircle({
  className = ""
}: {
  width?: number;
  className?: string;
}) {
  return (
    <div
      className={`flex align-i-center justify-center ${className}`}
      style={{
        background: "transparent",
        borderRadius: 3,
        padding: 1,
        height: 92,
        width: 92
      }}
    >
      <Icon className="flex h-100-percent w-100-percent align-i-center justify-center">
        <img src={Logo} height={92} />
      </Icon>
    </div>
  );
}

export default FolderCircle;
