import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { externalToolMatcher } from "../../../navigation/matchers";
import useSetWorkspaceTab from "./useSetWorkspaceTab";
import { TOOLS_TAB } from "../workspaceConstants";

// When people navigate to tools, we want to set the tab to tools initially
const useSetToolTabToToolsOnToolRoute = () => {
  const location = useLocation();
  const setWorkspaceTab = useSetWorkspaceTab();
  useEffect(() => {
    const toolMatcher = externalToolMatcher(location.pathname);
    if (toolMatcher) {
      setWorkspaceTab(TOOLS_TAB);
    }
  }, []);
};

export default useSetToolTabToToolsOnToolRoute;
