import { TextField } from "@mui/material";
import React from "react";
import DeleteOptionButton from "./DeleteOptionButton";
import useGetCurrentToolInputField from "./hooks/useGetCurrentToolInputField";
import { isInputWithOptions } from "../constants";
import { useToolInputFieldsContext } from "./contexts/ToolInputFieldsContext";
import useGetCurrentToolInputFieldIndex from "./hooks/useGetCurrentToolInputFieldIndex";

function InputOptionNameTextField({
  option,
  optionIndex,
  disabled
}: {
  option: string;
  optionIndex: number;
  disabled?: boolean;
}) {
  const toolInputField = useGetCurrentToolInputField();
  const { changeOptionName } = useToolInputFieldsContext();
  const index = useGetCurrentToolInputFieldIndex();

  if (!isInputWithOptions(toolInputField)) {
    return null;
  }

  return (
    <TextField
      key={optionIndex}
      fullWidth
      margin="dense"
      label="Option"
      disabled={disabled}
      value={option}
      InputProps={{
        endAdornment: toolInputField?.options &&
          toolInputField.options.length !== 1 && (
            <DeleteOptionButton optionIndex={optionIndex} />
          )
      }}
      onChange={(e) =>
        changeOptionName(toolInputField, optionIndex, e.target.value, index)
      }
    />
  );
}

export default InputOptionNameTextField;
