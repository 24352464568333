import { useGetToolQuery } from "../../../ToolflowAPI/rtkRoutes/toolsApi";
import { useAuth0Store } from "../../../hooks/useAuth0Store";

const useGetToolFromQuery = (
  toolId?: string,
  options?: { refetchOnMountOrArgChange?: boolean }
) => {
  const { isLoading } = useAuth0Store();
  const { data } = useGetToolQuery(toolId || "", {
    skip: !toolId || isLoading,
    ...options
  });
  return toolId ? data?.tool : undefined; // data stays the same if toolId is undefined, so
};

export default useGetToolFromQuery;
