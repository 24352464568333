import React from "react";
import DraggableBlockContainer from "../DraggableBlockContainer";
import useParentId from "../hooks/useParentId";

function ConstantBlockDraggable({ open }: { open: boolean }) {
  const parentId = useParentId();
  const title = "Constant";
  return (
    <DraggableBlockContainer
      open={open}
      item={{
        type: "constantBlockNode",
        data: {
          label: "Constant",
          type: "constant",
          constant: ""
        },
        parentNode: parentId
      }}
      title={title}
    />
  );
}
export default ConstantBlockDraggable;
