import React from "react";
import DeleteCurrentAssetButton from "../deleteAssetButtons/DeleteCurrentAssetButton";

const SheetAssetIcons = () => {
  return (
    <div className="flex align-i-center">
      <DeleteCurrentAssetButton />
    </div>
  );
};

export default SheetAssetIcons;
