import type { TWorkspaceDataToSave } from "@sharedTypes";
import { useFormContext } from "react-hook-form";
import { WORKSPACE_CHAT_THREAD_ID_FIELD_LABEL } from "../../../../workspaceConstants";

const useGetCurrentChatThreadId = () => {
  const { watch } = useFormContext<TWorkspaceDataToSave>();
  return watch(WORKSPACE_CHAT_THREAD_ID_FIELD_LABEL);
};

export default useGetCurrentChatThreadId;
