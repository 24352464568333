import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChatGPTBlock from "./blocks/promptBlocks/chatGPT/ChatGPTBlockDraggable";
import DallEBlock from "./blocks/promptBlocks/dalle/DallEBlockDraggable";
import ToolWithinToolBlock from "./blocks/toolWithinTool/ToolWithinToolBlockDraggable";
import LogicDraggableBlock from "./blocks/logic/LogicBlockDraggable";
import ConstantBlock from "./blocks/constant/ConstantBlockDraggable";
import Grid from "@mui/material/Unstable_Grid2";
import {
  FormHelperText,
  ListItemText,
  List,
  ListItemButton,
  ListItemIcon
} from "@mui/material";
import ToolInputDrawer from "./ToolInputDrawer";
import ToolOutputDrawer from "./ToolOutputDrawer";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { SouthEast } from "@mui/icons-material";
import { MoreInfoCard } from "../utilities/ToolInfoDrawer";
import ToolAnalyticsCard from "./ToolAnalyticsCard";
import ToolHistoryCard from "../utilities/ToolHistoryCard";
import DeepgramBlockDraggable from "./blocks/promptBlocks/deepgram/DeepgramBlockDraggable";
import ScraperBlockDraggable from "./blocks/scraperBlock/ScraperBlockDraggable";
import InputOutputMenu from "./dropdowns/InputOutputMenu";
import StyledBuilderDrawer from "./StyledBuilderDrawer";
import { StyledSecondaryBuilderDrawer } from "./SyledSecondaryBuilderDrawer";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import useGetAvailableTools from "../utilities/toolSelector/hooks/useGetAvailableTools";
import SerpBlockDraggable from "./blocks/serpBlock/SerpBlockDraggable";

export default function BuilderDrawer({
  children
}: Readonly<{
  children: React.ReactNode;
}>) {
  const { state: tState, dispatch: tDispatch } = useToolbuilderContext();
  const [open, setOpen] = useState(true);
  const [secondaryType, setSecondaryType] = useState<null | string>(null);
  const tools = useGetAvailableTools();

  const { toolId, showInfoDrawer } = tState;
  const { description, toolName: name } = tState.currentState;
  const filteredTools = tools.filter(
    (t) => t.visibility === "public" && t.id !== toolId
  );

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleClose = () => setSecondaryType("");

  const closeMoreInfo = () => {
    tDispatch({ type: "SET_SHOW_INFO_DRAWER", showInfoDrawer: false });
  };

  const handleToggleInput = () => {
    setSecondaryType("input");
    tDispatch({ type: "SET_SHOW_INFO_DRAWER", showInfoDrawer: false });
    tDispatch({ type: "UPDATE_OPEN_NODE", nodeId: "" });
  };

  const handleToggleOutput = () => {
    setSecondaryType("output");
    tDispatch({ type: "SET_SHOW_INFO_DRAWER", showInfoDrawer: false });
    tDispatch({ type: "UPDATE_OPEN_NODE", nodeId: "" });
  };

  useEffect(() => {
    if (tState.openNodeId || tState.showInfoDrawer) {
      setSecondaryType("");
    }
  }, [tState.openNodeId, tState.showInfoDrawer]);

  return (
    <Box sx={{ display: "flex", position: "relative", left: "1px" }}>
      <StyledBuilderDrawer
        className="customDrawer"
        variant="permanent"
        open={open}
        sx={{
          "& .MuiDrawer-root": {
            position: "absolute"
          },
          "& .MuiPaper-root": {
            position: "absolute",
            padding: "0px"
          },
          "&.customDrawer > .MuiDrawer-paper": {
            borderRadius: 0
          }
        }}
      >
        <div className={`flex ${open ? "justify-flex-end" : "justify-center"}`}>
          <IconButton onClick={toggleDrawer}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        {open ? (
          <List dense disablePadding>
            <ListItemButton onClick={handleToggleInput}>
              <ListItemIcon>
                <SouthEast />
              </ListItemIcon>
              <ListItemText>Inputs</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={handleToggleOutput}>
              <ListItemIcon>
                <ArrowOutwardIcon />
              </ListItemIcon>
              <ListItemText>Outputs</ListItemText>
            </ListItemButton>
          </List>
        ) : (
          <InputOutputMenu
            handleToggleInput={handleToggleInput}
            handleToggleOutput={handleToggleOutput}
          />
        )}
        <Divider />
        <FormHelperText
          className={`m-t-8px ${open ? "m-l-16px" : "text-align-center"}`}
        >
          Models
        </FormHelperText>
        <Box
          sx={{
            p: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& .MuiPaper-root": {
              position: "relative"
            }
          }}
        >
          <Grid container spacing={1} xs={12}>
            <ChatGPTBlock open={open} title="Text" />
            <DallEBlock open={open} title="Image" />
            <DeepgramBlockDraggable open={open} title="Speech-to-Text" />
            <ScraperBlockDraggable open={open} title="Web Scraper" />
            <SerpBlockDraggable open={open} title="Google Search Results" />
          </Grid>
        </Box>
        <Divider />
        <FormHelperText
          className={`m-t-8px ${open ? "m-l-16px" : "text-align-center"}`}
        >
          Logic
        </FormHelperText>
        <Box
          sx={{
            p: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& .MuiPaper-root": {
              position: "relative"
            }
          }}
        >
          <Grid container spacing={1} xs={12}>
            <LogicDraggableBlock open={open} />
            <ConstantBlock open={open} />
          </Grid>
        </Box>
        <Divider />
        <FormHelperText
          className={`m-t-8px ${open ? "m-l-16px" : "text-align-center"}`}
        >
          Tools
        </FormHelperText>
        <Box
          sx={{
            p: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& .MuiPaper-root": {
              position: "relative"
            }
          }}
        >
          <Grid container spacing={1} xs={12}>
            {filteredTools.map((ft) => (
              <ToolWithinToolBlock
                open={open}
                toolId={ft.id}
                key={ft.id}
                toolName={ft.name}
              />
            ))}
          </Grid>
        </Box>
      </StyledBuilderDrawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        {children}
      </Box>
      <StyledSecondaryBuilderDrawer
        open={!!secondaryType || !!tState.openNodeId || tState.showInfoDrawer}
        anchor="right"
        variant="permanent"
        sx={{
          "& .MuiDrawer-root": {
            position: "absolute"
          },
          "& .MuiPaper-root.MuiDrawer-paper": {
            position: "absolute",
            padding: "0px",
            right: 0
          },
          "&.customDrawer > .MuiDrawer-paper": {
            borderRadius: 0
          }
        }}
      >
        {showInfoDrawer ? (
          <MoreInfoCard
            description={description}
            name={name}
            close={closeMoreInfo}
          >
            {toolId && (
              <>
                <ToolAnalyticsCard />
                <ToolHistoryCard />
              </>
            )}
          </MoreInfoCard>
        ) : (
          <>
            {!!secondaryType && (
              <>
                {secondaryType === "input" ? (
                  <ToolInputDrawer handleClose={handleClose} />
                ) : (
                  <ToolOutputDrawer handleClose={handleClose} />
                )}
              </>
            )}
          </>
        )}
      </StyledSecondaryBuilderDrawer>
    </Box>
  );
}
