import React from "react";
import useToolflowNavigate from "../../../utilities/hooks/useToolflowNavigate";
import { PROFILE_NESTED_MAIN_ROUTE_PATH } from "../../../navigation/routePaths";
import SettingsMenuItemWithClose from "../../../utilities/dropdowns/generic/SettingsMenuItemWithClose";
import { RiUser3Line } from "@remixicon/react";

const NavigateToProfileMenuItem = () => {
  const navigate = useToolflowNavigate();

  const navigateToSettings = () => {
    navigate(PROFILE_NESTED_MAIN_ROUTE_PATH);
  };
  return (
    <SettingsMenuItemWithClose
      icon={<RiUser3Line size={18} />}
      text="Profile"
      action={navigateToSettings}
    />
  );
};

export default NavigateToProfileMenuItem;
