import React from "react";
import MarketplaceTabs from "./MarketplaceTabs";
import useMarketplaceCategory from "./hooks/useMarketplaceCategory";

const MarketplaceCategoriesContainer = () => {
  const { handleChangeTabIndex, selectedTabIndex, marketplaceCategoryTabs } =
    useMarketplaceCategory();
  return (
    <MarketplaceTabs
      tabs={marketplaceCategoryTabs}
      selectedTabIndex={selectedTabIndex}
      handleChangeTabIndex={handleChangeTabIndex}
    />
  );
};

export default MarketplaceCategoriesContainer;
