import React from "react";
import { Edit } from "@mui/icons-material";
import SettingsMenuItem from "../generic/SettingsMenuItem";
import useToolflowNavigate from "../../hooks/useToolflowNavigate";
import { TOOLBUILDER_BASE_ROUTE_PATH } from "../../../navigation/routePaths";

function NavigateToToolBuilderMenuItem({ toolId }: { toolId: string }) {
  const navigate = useToolflowNavigate();
  const handleNavigate = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`${TOOLBUILDER_BASE_ROUTE_PATH}${toolId}`, e);
  };

  return (
    <SettingsMenuItem
      action={handleNavigate}
      text="Edit tool"
      hotkey="Meta+E"
      hide={!toolId}
      icon={<Edit fontSize="small" />}
    />
  );
}

export default NavigateToToolBuilderMenuItem;
