import { Button, type SxProps } from "@mui/material";
import React from "react";
import useSetActiveStep from "../hooks/useSetActiveStep";
import useGetWorkflowActiveStep from "../hooks/useGetWorkflowActiveStep";
import useGetWorkflowProgress from "../hooks/useGetWorkflowProgress";
import { useWorkflowStepContext } from "../../../../../utilities/contexts/WorkflowStepContext";
import type { RunToolStep } from "@sharedTypes";

function HandleNextButton({ sx }: { sx?: SxProps }) {
  const setActiveStep = useSetActiveStep();
  const activeStep = useGetWorkflowActiveStep();
  const workflowProgress = useGetWorkflowProgress();
  const { step, index } = useWorkflowStepContext<RunToolStep>();

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  if (!workflowProgress[step.id]?.outputs || activeStep !== index) return null;

  return (
    <Button onClick={handleNext} sx={sx}>
      Next
    </Button>
  );
}

export default HandleNextButton;
