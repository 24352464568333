import type {
  BillingResponseFE,
  CreditsResponse,
  THyperlineComponentResponse,
  THyperlineCustomerPortalResponse,
  THyperlineCustomerType,
  TPostSubscriptionBody
} from "@sharedTypes";
import { authenticatedApi } from "../authenticatedAPI";
import { getHyperlineCheckoutUrl } from "../../settings/BillingCard";
import { userApi } from "./userApi";
import { setSuccessMessage } from "../../stores/actions";
import {
  CREDITS_TAG_TYPE,
  CURRENT_USER_TAG_TYPE,
  SUBSCRIPTION_TAG_TYPE
} from "../tagConstants";

export const billingApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    cancelSubscription: builder.mutation<BillingResponseFE, void>({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: (arg: void) => ({
        url: "/subscription",
        method: "DELETE"
      }),
      invalidatesTags: [
        CURRENT_USER_TAG_TYPE,
        CREDITS_TAG_TYPE,
        SUBSCRIPTION_TAG_TYPE
      ]
    }),
    updateSubscription: builder.mutation<
      BillingResponseFE,
      TPostSubscriptionBody
    >({
      query: (body) => ({
        url: "subscription",
        method: "POST",
        body
      }),
      invalidatesTags: [
        CURRENT_USER_TAG_TYPE,
        CREDITS_TAG_TYPE,
        SUBSCRIPTION_TAG_TYPE
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: resp } = await queryFulfilled;
          const user = userApi.endpoints.getCurrentUser.select()(getState())
            ?.data?.user;
          const redirect = !(
            user?.billing?.hyperlineCustomer &&
            user?.organization?.billing?.hyperlineCustomer
          );

          if (resp.checkoutSessionId && redirect) {
            const redirectUrl = getHyperlineCheckoutUrl(resp.checkoutSessionId);
            if (redirectUrl) {
              window.location.href = redirectUrl;
            }
          } else {
            dispatch(setSuccessMessage("Your plan has been updated"));
          }
        } catch {}
      }
    }),
    getCredits: builder.query<CreditsResponse, void>({
      query: () => ({
        url: "credits",
        method: "GET"
      }),
      providesTags: [CREDITS_TAG_TYPE]
    }),
    getComponentToken: builder.query<THyperlineComponentResponse, void>({
      query: () => ({
        url: "hyperlineComponentToken",
        method: "GET"
      }),
      providesTags: [SUBSCRIPTION_TAG_TYPE]
    }),
    getCustomerPortal: builder.query<
      THyperlineCustomerPortalResponse,
      THyperlineCustomerType
    >({
      query: (customerType) => ({
        url: `/hyperlineCustomerPortal/${customerType}`,
        method: "GET"
      }),
      providesTags: [SUBSCRIPTION_TAG_TYPE]
    })
  })
});

export const billingApiEndpoints = billingApi.endpoints;

export const {
  useCancelSubscriptionMutation,
  useGetComponentTokenQuery,
  useUpdateSubscriptionMutation,
  useGetCreditsQuery,
  useGetCustomerPortalQuery
} = billingApi;
