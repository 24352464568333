import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import type {
  CustomToolInputField,
  TSetFormState,
  UserInputDictType
} from "@sharedTypes";
import React from "react";
import { LabelAndInfo } from "../LabelAndInfo";

export const InputCheckbox = ({
  toolInputField,
  setFormState,
  formState,
  disabled,
  autofocus,
  disableLabelAndInfo,
  useIds
}: {
  toolInputField: CustomToolInputField;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  disabled?: boolean;
  autofocus?: boolean;
  disableLabelAndInfo?: boolean;
  useIds?: boolean;
}) => {
  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    // we want to delete the value from user input if it
    // is false
    const params = { ...formState };
    if (!checked && !!params[e.target.name]) {
      delete params[e.target.name];
      setFormState(params);
    } else {
      setFormState({
        ...params,
        [useIds ? e.target.id : e.target.name]: checked
      });
    }
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            name={toolInputField.name}
            size="small"
            autoFocus={autofocus}
            id={toolInputField.id}
            checked={!!formState[toolInputField.name]}
            onChange={handleCheckboxChange}
            disabled={disabled}
          />
        }
        label={
          !disableLabelAndInfo ? (
            <LabelAndInfo className="" name={toolInputField.name} />
          ) : undefined
        }
        className="w-100-percent m-l-8px"
      />
      {toolInputField.description && !disableLabelAndInfo && (
        <FormHelperText>text={toolInputField.description}</FormHelperText>
      )}
    </>
  );
};
