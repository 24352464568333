import React, { useMemo } from "react";
import { useSettingsDropdownContext } from "../generic/useSettingsDropownContext";
import SettingsMenuItem from "../generic/SettingsMenuItem";
import ToolflowHireSplitter from "../../ToolflowHireGuard";
import { useUpdateMarketplaceFeaturedToolMutation } from "../../../ToolflowAPI/rtkRoutes/marketplaceApi";
import { RiFundsLine } from "@remixicon/react";
import useToolIsPopular from "../hooks/useToolIsPopular";

function TogglePopularObjectMenuItem({
  objectId,
  type
}: {
  objectId: string;
  type: "tool" | "workflow";
}) {
  const [togglePopular] = useUpdateMarketplaceFeaturedToolMutation();
  const { handleClose } = useSettingsDropdownContext();
  const isPopular = useToolIsPopular(objectId);

  const copy = (e: React.MouseEvent) => {
    const newPopularState = !isPopular;
    togglePopular({
      objectId,
      type,
      popular: newPopularState,
      isFeatured: true,
      successMessage: newPopularState
        ? "Made popular in marketplace"
        : "Disabled popular in marketplace"
    });
    handleClose(e);
  };

  const text = useMemo(() => {
    return isPopular
      ? "Disable popularity in marketplace"
      : "Make popular in marketplace";
  }, [isPopular]);

  return (
    <ToolflowHireSplitter
      component={
        <SettingsMenuItem
          action={copy}
          text={text}
          icon={<RiFundsLine size={16} />}
        />
      }
    />
  );
}

export default TogglePopularObjectMenuItem;
