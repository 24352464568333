import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import { useDispatch } from "react-redux";
import { updateWorkflowVersionId } from "./workflowBuilderSlice";
import { useBlockerContext } from "../../../utilities/BlockerContext";

function ResetWorkflowButton() {
  const { continueNavigating } = useBlockerContext();
  const dispatch = useDispatch();
  const handleReset = () => {
    continueNavigating();
    dispatch(updateWorkflowVersionId(undefined));
  };

  return (
    <LoadingButton
      size="small"
      variant="text"
      onClick={handleReset}
      className="m-l-8px"
    >
      Discard changes
    </LoadingButton>
  );
}

export default ResetWorkflowButton;
