import type { ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import { RootState } from "../../../stores/store";
import invalidateTags from "./invalidateTags";
import { WORKFLOW_TAG_TYPE } from "../../tagConstants";

const invalidateWorkflowTags = (
  dispatch: ThunkDispatch<$TSAllowedAny, $TSAllowedAny, UnknownAction>,
  state: RootState
) => {
  invalidateTags(dispatch, state, WORKFLOW_TAG_TYPE);
};

export default invalidateWorkflowTags;
