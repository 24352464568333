import useNavigateToWorkspaceWithDefaultObject from "../utilities/hooks/useNavigateToWorkspaceWithDefaultObject";

const useNavigateToWorkflowById = (workflowId = "") => {
  const navigateToWorkspace = useNavigateToWorkspaceWithDefaultObject({
    type: "workflow",
    id: workflowId
  });
  const handleNavigate = (e: React.MouseEvent) => {
    if (!workflowId) return;
    navigateToWorkspace(e);
  };
  return handleNavigate;
};

export default useNavigateToWorkflowById;
