import React from "react";
import {
  dallEInitialState,
  dallEPromptInitialState
} from "../../../context/initialStates";
import type { PromptBlockData } from "@sharedTypes";
import useParentId from "../../hooks/useParentId";
import DraggableBlockContainer from "../../DraggableBlockContainer";

function DallEBlockDraggable({
  open,
  title = "Image"
}: {
  open: boolean;
  title?: string;
}) {
  const parentId = useParentId();
  return (
    <DraggableBlockContainer
      open={open}
      item={{
        type: "promptBlockNode",
        data: {
          ...dallEInitialState,
          ...dallEPromptInitialState
        } as PromptBlockData,
        parentNode: parentId
      }}
      title={title}
    />
  );
}

export default DallEBlockDraggable;
