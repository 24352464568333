import { useEffect, useRef } from "react";
import { useAuth0Store } from "../../hooks/useAuth0Store";

const useIsLoadingRef = () => {
  const { isLoading } = useAuth0Store();
  // needed to handleDomEvent to disable inserting a "/" when the editor is loading
  const isLoadingRef = useRef<boolean>(isLoading);

  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);
  return isLoadingRef;
};

export default useIsLoadingRef;
