import React from "react";
import type { unstable_Blocker as Blocker } from "react-router-dom";
import SaveVersionDialog from "../SaveVersionDialog";
import ResetToolButton from "../ResetToolButton";
import { ConfirmNavigationDialogBaseWithBlocker } from "../../utilities/ConfirmNavigationDialogBase";

function ConfirmToolNavigationDialog({
  blocker
}: Readonly<{ blocker: Blocker }>) {
  return (
    <ConfirmNavigationDialogBaseWithBlocker blocker={blocker} type="tool">
      {({
        continueNavigating,
        resetBlocker
      }: Readonly<{
        continueNavigating: () => void;
        resetBlocker: () => void;
      }>) => (
        <>
          <ResetToolButton
            resetButtonText="Discard changes"
            continueNavigating={continueNavigating}
          />
          <SaveVersionDialog
            continueNavigating={continueNavigating}
            resetBlocker={resetBlocker}
            text
          />
        </>
      )}
    </ConfirmNavigationDialogBaseWithBlocker>
  );
}

export default ConfirmToolNavigationDialog;
