import type {
  DeleteSecretReponse,
  SaveSecretRequest,
  SaveSecretResponseFE,
  SecretReference,
  SecretResponseFE,
  TSecretDict
} from "@sharedTypes";
import { authenticatedApi } from "../authenticatedAPI";
import { setErrorMessage, setSuccessMessage } from "../../stores/actions";
import { getErrorMessage } from "../../utilities/apiHandlerWithAuth";
import { SECRET_TAG_TYPE } from "../tagConstants";

export const normalizeSecrets = (secrets: SecretReference[]) => {
  return (
    secrets?.reduce(
      (secretsDict, secret) => {
        const secretTypes = Object.keys(secretsDict);
        if (secretTypes.includes(secret.type)) {
          secretsDict[secret.type].push(secret);
        } else {
          secretsDict[secret.type] = [secret];
        }
        return secretsDict;
      },
      { openAI: [], deepgram: [] } as TSecretDict
    ) || ({ openAI: [], deepgram: [] } as TSecretDict)
  );
};

export const secretApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    saveSecret: builder.mutation<SaveSecretResponseFE, SaveSecretRequest>({
      query: (body) => ({
        url: "secret",
        method: "POST",
        body
      }),
      invalidatesTags: [SECRET_TAG_TYPE],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage("Saved API key"));
        } catch (e) {
          dispatch(setErrorMessage(getErrorMessage(e, "Error saving API key")));
        }
      }
    }),
    deleteSecret: builder.mutation<DeleteSecretReponse, string>({
      query: (id: string) => ({
        url: `secret/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: [SECRET_TAG_TYPE],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(setSuccessMessage("Deleted API key"));
        } catch (err) {
          // `onError` side-effect
          dispatch(
            setErrorMessage(getErrorMessage(err, "Error deleting API key"))
          );
        }
      }
    }),
    getSecrets: builder.query<TSecretDict, void>({
      query: () => ({
        url: "secret",
        method: "GET"
      }),
      providesTags: [SECRET_TAG_TYPE],
      transformResponse: (response: SecretResponseFE) => {
        const { secrets } = response;
        return normalizeSecrets(secrets);
      }
    })
  })
});

export const secretApiEndpoints = secretApi.endpoints;

export const {
  useSaveSecretMutation,
  useDeleteSecretMutation,
  useGetSecretsQuery
} = secretApi;
