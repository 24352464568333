import React from "react";
import useGetHomePageEntities from "../../hooks/useGetHomePageEntities";
import ToolsBlockTable from "./ToolsBlockTable";

const FrequentlyUsedEntitiesContainer = () => {
  const { frequentlyUsedEntities } = useGetHomePageEntities(undefined, 6);
  return <ToolsBlockTable toolsBlockEntities={frequentlyUsedEntities} />;
};

export default FrequentlyUsedEntitiesContainer;
