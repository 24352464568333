import React from "react";
import useGetHomePageEntities from "../hooks/useGetHomePageEntities";
import ToolsTable from "./ToolsTable";
import TableSkeleton from "../layout/TableSkeleton";
import NoRecentData from "../NoRecentData";

const ToolsTableContainer = () => {
  const { homePageEntities, isLoading } = useGetHomePageEntities(5);
  if (isLoading) {
    return <TableSkeleton numOfRows={5} className="p-16px" />;
  }
  const isHomePageEntitiesEmpty = homePageEntities?.length === 0 && !isLoading;
  if (isHomePageEntitiesEmpty) {
    return (
      <NoRecentData
        header="Your recent tools will be stored here"
        subHeader="Ready to start? Easily find your first tool in our marketplace and begin exploring the possibilities."
      />
    );
  }
  return <ToolsTable homePageEntities={homePageEntities} />;
};

export default ToolsTableContainer;
