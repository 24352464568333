import React, { FC } from "react";
import { googelSerpLanguages, googleSerpCountries } from "./serpUtils";
import type { TSerpSettings } from "@sharedTypes";
import useUpdateField from "../../useUpdateField";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import type { SelectChangeEvent } from "@mui/material/Select";
interface IModelDemographicSettingsProps {
  settings: TSerpSettings;
  id: string;
}
const ModelDemographicSettings: FC<IModelDemographicSettingsProps> = (
  props
) => {
  const { id, settings } = props;
  const updateField = useUpdateField(id);
  const updateStateValue =
    (kString: keyof TSerpSettings) => (event: SelectChangeEvent<string>) => {
      updateField(event.target.value, `settings.${kString}`);
    };
  return (
    <>
      <Grid item xs={6}>
        <FormControl margin="normal" fullWidth>
          <InputLabel id="country-label">Select Country</InputLabel>
          <Select
            labelId="country-label"
            id="country"
            value={settings.countryCode}
            fullWidth
            label="Select Country"
            onChange={updateStateValue("countryCode")}
            className="capitalize nowheel nodrag nopan"
          >
            {googleSerpCountries.map(
              (option: { value: string; label: string }) => (
                <MenuItem
                  value={option.value}
                  key={option.value}
                  className="capitalize"
                >
                  {option.label}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl margin="normal" fullWidth>
          <InputLabel id="language-label">Select Language</InputLabel>
          <Select
            labelId="language-label"
            id="language"
            value={settings.languageCode}
            fullWidth
            label="Select Language"
            onChange={updateStateValue("languageCode")}
            className="capitalize nowheel nodrag nopan"
          >
            {googelSerpLanguages.map(
              (option: { value: string; label: string }) => (
                <MenuItem
                  value={option.value}
                  key={option.value}
                  className="capitalize"
                >
                  {option.label}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

export default ModelDemographicSettings;
