import React from "react";
import { Button } from "@mui/material";
import useNavigateToWorkflowById from "../../../hooks/useNavigateToWorkflowById";

const NavigateToWorkflowButton = ({
  workflowId,
  className = ""
}: {
  workflowId: string;
  className?: string;
}) => {
  const handleNavigate = useNavigateToWorkflowById(workflowId);
  return (
    <Button
      variant="outlined"
      onClick={handleNavigate}
      className={className}
      disabled={!workflowId}
    >
      Open
    </Button>
  );
};

export default NavigateToWorkflowButton;
