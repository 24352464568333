import _ from "lodash";
import { useEffect } from "react";
import {
  unstable_useBlocker as useBlocker,
  useLocation
} from "react-router-dom";

// Note when getting "A router only supports one blocker at a time"
// it is because of strict mode, so shouldnt happen in prod
// https://github.com/remix-run/react-router/issues/10073
const useToolflowBlocker = (
  isObjectDirty: () => boolean,
  originalState: unknown,
  currentState: unknown,
  resetFunction: () => void
) => {
  const location = useLocation();

  const blockerFunction = () => {
    return isObjectDirty();
  };

  // https://stackblitz.com/github/remix-run/react-router/tree/main/examples/navigation-blocking?file=src%2Fapp.tsx
  const blocker = useBlocker(blockerFunction);

  // this may have unintended consequences, monitor renders
  useEffect(() => {
    if (!_.isEqual(currentState, originalState)) {
      resetFunction();
      if (blocker && blocker.reset) {
        blocker.reset();
      }
    }
  }, [location]);
  return blocker;
};

export default useToolflowBlocker;
