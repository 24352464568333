import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../stores/store";
import { setAssetExpanded } from "../../../../workspaceSlice";

const useAssetDialogOpen = () => {
  const open = useSelector((state: RootState) => state.workspace.assetExpanded);
  const dispatch = useDispatch();

  const setClosed = () => {
    dispatch(setAssetExpanded(false));
  };
  return { open, setClosed };
};

export default useAssetDialogOpen;
