import React from "react";
import MarketplaceUseCases from "./MarketplaceUseCases";
import useMarketplaceUseCases from "./hooks/useMarketplaceUseCases";

const MarketplaceUseCasesContainer = () => {
  const { handleToggleUseCaseSelection, useCases } = useMarketplaceUseCases();
  return (
    <MarketplaceUseCases
      useCases={useCases}
      handleToggleUseCaseSelection={handleToggleUseCaseSelection}
    />
  );
};

export default MarketplaceUseCasesContainer;
