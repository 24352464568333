import { Button, type ButtonProps } from "@mui/material";
import React from "react";
import useToolflowNavigate from "./hooks/useToolflowNavigate";

function VisitMarketplaceButton({ fullWidth }: ButtonProps) {
  const navigate = useToolflowNavigate();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    navigate("/marketplace", e);
  };

  return (
    <Button onClick={handleClick} fullWidth={fullWidth}>
      Visit the marketplace
    </Button>
  );
}

export default VisitMarketplaceButton;
