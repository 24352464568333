import React from "react";
import type { SerpBlockData } from "@sharedTypes";
import {
  serpInitialState,
  serpSettingsInitialState
} from "../../context/initialStates";
import DraggableBlockContainer from "../DraggableBlockContainer";
import useParentId from "../hooks/useParentId";

function SerpBlockDraggable({
  open,
  title = "Serp Data"
}: {
  open: boolean;
  title?: string;
}) {
  const parentId = useParentId();
  return (
    <DraggableBlockContainer
      open={open}
      item={{
        type: "serpBlockNode",
        data: {
          ...serpSettingsInitialState,
          ...serpInitialState
        } as SerpBlockData,
        parentNode: parentId
      }}
      title={title}
    />
  );
}

export default SerpBlockDraggable;
