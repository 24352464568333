import React, { useState } from "react";
import type { VisibilityTypes } from "@sharedTypes";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import SettingsMenuItem from "../generic/SettingsMenuItem";
import { ShareDialogBase } from "../../../toolBuilder/ShareDialog";
import { UpdateVisibilitySelectBase } from "../../../toolBuilder/UpdateVisibilitySelect";
import { useSettingsDropdownContext } from "../generic/useSettingsDropownContext";
import type { SelectChangeEvent } from "@mui/material";
import { useUpdateWorkflowMutation } from "../../../ToolflowAPI/rtkRoutes/workflowApi";

function UpdateWorkflowVisibilityMenuItem({
  id,
  visibility,
  name
}: {
  id: string;
  visibility: VisibilityTypes;
  name: string;
}) {
  const { handleClose } = useSettingsDropdownContext();
  const [updateWorkspace, { isLoading }] = useUpdateWorkflowMutation();

  const handlePrivacy = (e: SelectChangeEvent<VisibilityTypes>) => {
    updateWorkspace({
      body: { visibility: e.target.value as VisibilityTypes },
      workflowId: id
    });
    e.stopPropagation();
    e.preventDefault();
  };

  const [open, setOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const close = (e: React.SyntheticEvent<Element, Event>) => {
    e.stopPropagation();
    handleClose(e);
    setOpen(false);
  };

  return (
    <>
      <SettingsMenuItem
        disabled={isLoading}
        action={handleOpen}
        text={"Share"}
        hotkey="Meta+Shift+V"
        icon={<PersonAddAlt1Icon fontSize="small" />}
        hide={!id}
      />
      <ShareDialogBase
        id={id}
        open={open}
        closeDialog={close}
        loading={isLoading}
        name={name}
        type="workflow"
      >
        <UpdateVisibilitySelectBase
          handleSelectChange={handlePrivacy}
          visibility={visibility}
          formProps={{ fullWidth: true }}
        />
      </ShareDialogBase>
    </>
  );
}

export default UpdateWorkflowVisibilityMenuItem;
