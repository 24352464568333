import { LinearProgress } from "@mui/material";
import React from "react";
import { useEditorToolCardContext } from "./EditorToolCardContext";

const ToolRunningIndicator = () => {
  const { percentCompleted } = useEditorToolCardContext();
  return (
    <div className="flex-grow-1">
      {typeof percentCompleted === "number" && percentCompleted !== 100 && (
        <LinearProgress
          sx={{ height: 12, borderRadius: 8, m: 1 }}
          color="primary"
        />
      )}
    </div>
  );
};

export default ToolRunningIndicator;
