import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import useUpdateIndicator from "../../../utilities/drawers/hooks/useUpdateIndicator";
import useUpdateTabChange from "../../../utilities/drawers/hooks/useUpdateTabChange";
import useGetWorkspaceTab from "../hooks/useGetWorkspaceTab copy";
import WorkstationDrawer from "./WorkstationDrawer";
import workstationTabsDict from "./workstationTabDict";

function WorkstationDrawerContainer() {
  const value = useGetWorkspaceTab();

  const tabValue = workstationTabsDict[value].value;
  const disableTabChange = useSelector(
    (state: RootState) => state.workspace.disableTabChange
  );

  const { actionRef } = useUpdateIndicator();

  const objValues = Object.values(workstationTabsDict);
  const handleChange = useUpdateTabChange(workstationTabsDict);

  return (
    <WorkstationDrawer
      tabValue={tabValue}
      disableTabChange={disableTabChange}
      handleChangeTabs={handleChange}
      objValues={objValues}
      tabsRef={actionRef}
    />
  );
}

export default WorkstationDrawerContainer;
