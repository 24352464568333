import type {
  CustomToolInputField,
  TSetFormState,
  TWordPairsDict,
  UserInputDictType
} from "@sharedTypes";
import React from "react";
import { LabelAndInfo } from "../LabelAndInfo";
import ReplaceWordsForm from "../../toolBuilder/settings/deepgram/ReplaceWordsForm";

export const InputDoubleTextFieldBase = ({
  toolInputField,
  handleChangeWordPairs,
  wordsToReplace = {},
  disableLabelAndInfo
}: {
  toolInputField: CustomToolInputField;
  handleChangeWordPairs: (pairs: TWordPairsDict) => void;
  wordsToReplace?: TWordPairsDict;
  disableLabelAndInfo?: boolean;
}) => {
  return (
    <>
      {!disableLabelAndInfo && (
        <LabelAndInfo
          name={toolInputField.name}
          info={toolInputField.description}
        />
      )}
      <ReplaceWordsForm
        handleChange={handleChangeWordPairs}
        wordsToReplace={wordsToReplace}
        disableHelperText={disableLabelAndInfo}
      />
    </>
  );
};

export const InputDoubleTextField = ({
  toolInputField,
  setFormState,
  formState,
  disableLabelAndInfo,
  useIds
}: {
  toolInputField: CustomToolInputField;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  disableLabelAndInfo?: boolean;
  useIds?: boolean;
}) => {
  const handleChangeWordPairs = (wordPairsToReplace: TWordPairsDict) => {
    setFormState({
      ...formState,
      [useIds ? toolInputField.id : toolInputField.name]: wordPairsToReplace
    });
  };
  return (
    <InputDoubleTextFieldBase
      disableLabelAndInfo={disableLabelAndInfo}
      toolInputField={toolInputField}
      handleChangeWordPairs={handleChangeWordPairs}
      wordsToReplace={formState[toolInputField.name] as TWordPairsDict}
    />
  );
};
