import React, { useEffect } from "react";
import WorkflowDraggableStep from "./WorkflowDraggableStep";
import AddWorklfowStepButton from "./AddWorkflowStepButton";
import WorkflowStepsError from "./WorkflowStepsError";
import StepSidebar from "./StepSidebar";
import StepsWrapper from "./StepsWrapper";
import useGetSteps from "./hooks/useGetSteps";
import useOpenInitialStepOnLoad from "./hooks/useOpenInitialStepOnLoad";
import { usePrefetch } from "../../../../ToolflowAPI/rtkRoutes/toolsApi";
import useGetWorkflowStepToolIds from "./hooks/useGetWorkflowStepToolIds";

function WorkflowDraggableSteps() {
  const steps = useGetSteps();

  return (
    <>
      {steps.map((step, index) => (
        <WorkflowDraggableStep step={step} index={index} key={step.id} />
      ))}
    </>
  );
}

function useKeepCachedData() {
  const prefetchTool = usePrefetch("getTool");
  const ids = useGetWorkflowStepToolIds();

  useEffect(() => {
    ids.map((id: string) => {
      if (id) {
        prefetchTool(id);
      }
    });
  }, [ids]);
}

function WorkflowSteps() {
  useOpenInitialStepOnLoad();
  useKeepCachedData();

  return (
    <>
      <StepsWrapper>
        <WorkflowStepsError className="flex justify-center m-v-16px" />
        <WorkflowDraggableSteps />
        <div className="flex justify-center m-t-16px">
          <AddWorklfowStepButton />
        </div>
      </StepsWrapper>
      <StepSidebar />
    </>
  );
}

export default WorkflowSteps;
