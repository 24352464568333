import type { TInvalidatedTagType } from "@sharedTypes";
import { RootState } from "../../../stores/store";
import { userApi } from "../userApi";

const getInvalidateUserTag = (state: RootState, type: TInvalidatedTagType) => {
  const user = userApi.endpoints.getCurrentUser.select()(state)?.data?.user;
  return { type, id: user?._id || "" };
};

export default getInvalidateUserTag;
