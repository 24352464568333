import React from "react";
import RemixButton from "../features/layout/icons/RemixButton";
import { RiPencilLine } from "@remixicon/react";
import useNavigateToSnippet from "./hooks/useNavigateToSnippet";

function EditSnippetButton({ id }: { id: string }) {
  const navigate = useNavigateToSnippet(id);

  const navigateToSnippet = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(e);
  };

  return (
    <RemixButton text="Edit" icon={RiPencilLine} onClick={navigateToSnippet} />
  );
}

export default EditSnippetButton;
