import type { RowUserInputDictType } from "@sharedTypes";
import { useEffect, useRef } from "react";

const useSheetDataRef = (sheetData: RowUserInputDictType[]) => {
  const sheetDataRef = useRef<RowUserInputDictType[]>(sheetData);
  useEffect(() => {
    sheetDataRef.current = sheetData;
  }, [JSON.stringify(sheetData)]);

  return sheetDataRef;
};

export default useSheetDataRef;
