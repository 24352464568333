import { NavigateNext } from "@mui/icons-material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../stores/store";
import { Button } from "@mui/material";
import useToolflowNavigate from "../utilities/hooks/useToolflowNavigate";
import useHasNoLastPage from "../navigation/useHasNoLastPage";

function LastPageBreadcrumb({ className = "" }: { className?: string }) {
  const previousPath = useSelector(
    (state: RootState) => state.navigation.previousPath
  );
  const navigate = useToolflowNavigate();

  const goBack = (e: React.MouseEvent) => {
    navigate(previousPath.path, e);
  };
  const hasNoLastPage = useHasNoLastPage();

  if (hasNoLastPage) {
    return null;
  }

  return (
    <Button
      className={className}
      onClick={goBack}
      sx={{ color: (theme) => theme.palette.action.disabled }}
      endIcon={
        <NavigateNext
          fontSize="small"
          sx={{ color: (theme) => theme.palette.action.disabled }}
        />
      }
    >
      {previousPath.documentTitle}
    </Button>
  );
}

export default LastPageBreadcrumb;
