import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import type {
  ButtonVariantTypes,
  HyperlinePlans,
  TActiveTier,
  TAvailableTiers,
  TFreePlan,
  THyperlineCustomerType,
  THyperlineOrganizationCustomer,
  THyperlineOrganizationPlanIdDevOrStaging,
  THyperlineOrganizationPlanIdProduction,
  THyperlineProPlanIdDevOrStaging,
  THyperlineProPlanIdProduction,
  THyperlineUserCustomer,
  TOrganizationPlan,
  TProPlan,
  TProductionEnvironment,
  TSubscriptionParams
} from "@sharedTypes";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  useCancelSubscriptionMutation,
  useGetCustomerPortalQuery,
  useUpdateSubscriptionMutation
} from "../ToolflowAPI/rtkRoutes/billingApi";
import { useGetToolflowConstantsQuery } from "../ToolflowAPI/rtkRoutes/toolflowConstantsApi";
import useGetCurrentUser from "../hooks/useGetCurrentUser";
import ToolflowInfo from "../utilities/ToolflowInfo";
import ToolflowSkeletonWrapper from "../utilities/ToolflowSkeleton";
import ConfirmDowngradeDialog from "./ConfirmDowngradeDialog";
import { CreditsUsageCard } from "./CreditsUsageCard";
import SelectSeatsDialog from "./SelectSeatsDialog";

// I don't love redeclaring the types here, but shared can't have consts
// right now. We make sure to declare types here to make sure
// backend and frontend are in sync
export const hyperlineProPlanIdProduction: THyperlineProPlanIdProduction =
  "plan_LUE5hrnk1P5BRJ";
export const hyperlineOrganizationPlanIdProduction: THyperlineOrganizationPlanIdProduction =
  "plan_AeTOJvuLZNsTne";

export const hyperlineProPlanIdDevOrStaging: THyperlineProPlanIdDevOrStaging =
  "plan_Sq95HJqIiF-fua";
export const hyperlineOrganizationPlanIdDevOrStaging: THyperlineOrganizationPlanIdDevOrStaging =
  "plan_zZutDxM35ni71a";

export const ORGANIZATION_PLAN_FE: TOrganizationPlan = "organization";
export const PRO_PLAN_FE: TProPlan = "pro";
export const FREE_PLAN_FE: TFreePlan = "free";

export const HYPERLINE_CUSTOMER_ORGANIZATION: THyperlineOrganizationCustomer =
  "organization";
export const HYPERLINE_CUSTOMER_USER: THyperlineUserCustomer = "user";

export const PRODUCTION_ENVIRONMENT: TProductionEnvironment = "production";
export const HYPERLINE_PRODUCTION = "production";
export const HYPERLINE_SANDBOX = "sandbox";

export const DOWNGRADE = "downgrade";
export const UPGRADE = "upgrade";
export const CURRENT_SUBSCRIPTION = "currentSubscription";
export const PRO_UPGRADE = "proUpgrade";
export const ORGANIZATION_UPGRADE = "organizationUpgrade";

export type TDowngrade = typeof DOWNGRADE;
export type TUpgrade = typeof UPGRADE;
export type TUpgradeToOrganization = typeof ORGANIZATION_UPGRADE;
export type TUpgradeToPro = typeof PRO_UPGRADE;
export type TCurrentSubscription = typeof CURRENT_SUBSCRIPTION;

export const tierStatusParameters: {
  [key in
    | TDowngrade
    | TUpgrade
    | TCurrentSubscription
    | TUpgradeToOrganization
    | TUpgradeToPro]: {
    text: string;
    disabled: boolean;
    variant: ButtonVariantTypes;
  };
} = {
  [DOWNGRADE]: {
    text: "Downgrade",
    disabled: false,
    variant: "outlined"
  },
  [UPGRADE]: {
    text: "Upgrade",
    disabled: false,
    variant: "contained"
  },
  [CURRENT_SUBSCRIPTION]: {
    text: "Current Subscription",
    disabled: true,
    variant: "contained"
  },
  [ORGANIZATION_UPGRADE]: {
    text: "Upgrade To Organization",
    disabled: false,
    variant: "contained"
  },
  [PRO_UPGRADE]: {
    text: "Upgrade To Pro",
    disabled: false,
    variant: "contained"
  }
};

const useSubscriptionDict = () => {
  const { data } = useGetToolflowConstantsQuery();
  const toolflowConstants = data.toolflowConstants;
  const subscriptionDict: {
    [key in TAvailableTiers]: {
      name: string;
      price: string;
      planId?: HyperlinePlans;
      benefits: string[];
      tagline: string;
      ctas: {
        [ctaKey in TAvailableTiers]:
          | TDowngrade
          | TUpgrade
          | TCurrentSubscription
          | TUpgradeToOrganization;
      };
    };
  } = {
    free: {
      name: "Free",
      price: "$0 / month",
      tagline: "Free access for individuals getting started",
      benefits: [
        "Build & use custom tools",
        `${toolflowConstants.freeCredits.toLocaleString()} monthly credits incl`,
        // "5 tool runs",
        "Full access to template library",
        "Community slack support",
        "100MB file uploads"
      ],
      ctas: {
        free: CURRENT_SUBSCRIPTION,
        pro: UPGRADE,
        organization: UPGRADE
      }
    },
    pro: {
      name: "Pro",
      price: `$${toolflowConstants.proPrice} / month`,
      planId:
        process.env.REACT_APP_ENVIRONMENT === PRODUCTION_ENVIRONMENT
          ? hyperlineProPlanIdProduction
          : hyperlineProPlanIdDevOrStaging,
      tagline: "Advanced features & more credits for power users",
      benefits: [
        "Everything in Free +",
        `${toolflowConstants.proCredits.toLocaleString()} total monthly credits incl`,
        // "Unlimited tool runs",
        "Run tools in bulk",
        "Email support",
        "500MB file uploads",
        "Trigger tools via API"
      ],
      ctas: {
        free: DOWNGRADE,
        pro: CURRENT_SUBSCRIPTION,
        organization: ORGANIZATION_UPGRADE
      }
    },
    organization: {
      name: "Team",
      price: `$${toolflowConstants.organizationPrice} / month / user`,
      planId:
        process.env.REACT_APP_ENVIRONMENT === PRODUCTION_ENVIRONMENT
          ? hyperlineOrganizationPlanIdProduction
          : hyperlineOrganizationPlanIdDevOrStaging,
      tagline: "White glove support for teams with custom needs",
      benefits: [
        "Everything in Pro +",
        `${toolflowConstants.organizationCredits.toLocaleString()} total monthly credits incl`,
        "Dedicated prompt engineer support to build custom tools",
        "Use your own API keys for unlimited credits"
      ],
      ctas: {
        free: DOWNGRADE,
        pro: DOWNGRADE,
        organization: CURRENT_SUBSCRIPTION
      }
    }
  };
  return subscriptionDict;
};

export function useSubscriptionDetails() {
  const user = useGetCurrentUser();
  return useMemo(() => {
    let activeTier: TAvailableTiers = "free";
    let activeSubscriptionId: HyperlinePlans | undefined;
    let pendingTier: TActiveTier | undefined;
    let pendingCheckoutSessionId: string | null = null;
    let renewalDate = DateTime.now()
      .plus({ months: 1 })
      .startOf("month")
      .toLocaleString();

    const orgSubscriptions =
      user?.organization?.billing?.hyperlineCustomer.subscriptions || [];
    const userSubscriptions =
      user?.billing?.hyperlineCustomer.subscriptions || [];

    for (const subscription of orgSubscriptions) {
      if (subscription.status === "active") {
        activeTier = ORGANIZATION_PLAN_FE;
        activeSubscriptionId = subscription.plan_id;
        renewalDate = subscription.current_period_ends_at
          ? DateTime.fromISO(
              subscription.current_period_ends_at
            ).toLocaleString()
          : renewalDate;
        break;
      } else if (subscription.status === "pending") {
        pendingTier = ORGANIZATION_PLAN_FE;
        pendingCheckoutSessionId = subscription.checkout_session_id;
      }
    }

    if (activeTier === FREE_PLAN_FE) {
      for (const subscription of userSubscriptions) {
        if (subscription.status === "active") {
          activeTier = PRO_PLAN_FE;
          activeSubscriptionId = subscription.plan_id;
          renewalDate = subscription.current_period_ends_at
            ? DateTime.fromISO(
                subscription.current_period_ends_at
              ).toLocaleString()
            : renewalDate;
          break;
        } else if (subscription.status === "pending") {
          pendingTier = pendingTier || PRO_PLAN_FE;
          pendingCheckoutSessionId = subscription.checkout_session_id;
        }
      }
    }

    return {
      activeTier,
      activeSubscriptionId,
      pendingTier,
      pendingCheckoutSessionId,
      renewalDate
    };
  }, [user]);
}

// all toolflowEmployees are on the top plan
export const useAuthorizedTier = () => {
  const { activeTier } = useSubscriptionDetails();
  const user = useGetCurrentUser();
  if (!!user?.toolflowEmployeeType) {
    return ORGANIZATION_PLAN_FE;
  } else return activeTier;
};

export function getHyperlineCheckoutUrl(checkoutId: string | null) {
  if (checkoutId) {
    return `https://${process.env.REACT_APP_HYPERLINE_CUSTOM_SUBDOMAIN}.toolflow.ai/checkout/${checkoutId}`;
  } else return "";
}

function SubscriptionTierPaper({ tier }: { tier: TAvailableTiers }) {
  const [cancelSubscription, { isLoading: isCanceling }] =
    useCancelSubscriptionMutation();
  const [updateSubscription, { isLoading: isUpdating }] =
    useUpdateSubscriptionMutation();
  const user = useGetCurrentUser();

  const { activeTier } = useSubscriptionDetails();
  const subscriptionDict = useSubscriptionDict();

  const handleClick =
    (planId?: HyperlinePlans) => async (planParams?: TSubscriptionParams) => {
      if (user) {
        // if no planId, that means its the free tier
        // that means we want to cancel the subscription
        if (!planId) {
          cancelSubscription();
        } else {
          // we want to redirect if the user is on the free plan
          updateSubscription({ planId, ...planParams });
        }
      }
    };
  const loading = isUpdating || isCanceling;

  const { pendingTier, pendingCheckoutSessionId } = useSubscriptionDetails();

  const getUpdateSubscriptionButton = () => {
    if (pendingTier === tier) {
      const checkoutUrl = getHyperlineCheckoutUrl(pendingCheckoutSessionId);
      return (
        <Link to={checkoutUrl}>
          <Button variant="contained" fullWidth>
            Continue signing up
          </Button>
        </Link>
      );
    }
    let ctaText = "";
    let ctaButtonVariant: ButtonVariantTypes = "outlined";
    let ctaButtonDisabled = true;
    if (user) {
      ctaText =
        tierStatusParameters[subscriptionDict[activeTier].ctas[tier]].text;
      ctaButtonVariant =
        tierStatusParameters[subscriptionDict[activeTier].ctas[tier]].variant;
      ctaButtonDisabled =
        tierStatusParameters[subscriptionDict[activeTier].ctas[tier]].disabled;

      // With the currently active tier, if the tier of the paper is Downgrade, then show the downgrade dialog
      if (subscriptionDict[activeTier].ctas[tier] === DOWNGRADE) {
        return (
          <ConfirmDowngradeDialog
            action={handleClick(subscriptionDict[tier].planId)}
            loading={loading}
            buttonText={ctaText}
            buttonVariant={ctaButtonVariant}
            buttonDisabled={ctaButtonDisabled}
            isDowngradingFromTeam={activeTier === ORGANIZATION_PLAN_FE}
          />
        );
        // with the currently active tier, if the tier of the paper is upgrade, and the tier of the paper
        // is organization, then show the select seats dialog
      } else if (
        subscriptionDict[activeTier].ctas[tier] === UPGRADE &&
        tier === ORGANIZATION_PLAN_FE
      ) {
        return (
          <SelectSeatsDialog
            action={handleClick(subscriptionDict[tier].planId)}
            loading={loading}
            buttonText={ctaText}
            buttonVariant={ctaButtonVariant}
            buttonDisabled={ctaButtonDisabled}
          />
        );
      } else {
        return (
          <LoadingButton
            loading={loading}
            variant={ctaButtonVariant}
            disabled={ctaButtonDisabled}
            fullWidth
            onClick={() => handleClick(subscriptionDict[tier].planId)()}
          >
            {ctaText}
          </LoadingButton>
        );
      }
    }
  };

  return (
    <Paper
      variant="outlined"
      className="p-16px flex-grow-1 h-100-percent flex justify-space-between flex-column"
    >
      <div>
        <ToolflowSkeletonWrapper loading={!user} height={32}>
          <Typography variant="h6" align="center">
            {subscriptionDict[tier].name}
          </Typography>
        </ToolflowSkeletonWrapper>
        <ToolflowSkeletonWrapper loading={!user} height={28}>
          <Typography variant="subtitle1" align="center">
            {subscriptionDict[tier].price}
          </Typography>
        </ToolflowSkeletonWrapper>
        <ToolflowSkeletonWrapper
          loading={!user}
          height={72}
          sx={{ paddingTop: 2, paddingBottom: 2 }}
        >
          <Typography variant="subtitle2" align="center" className="p-v-16px">
            {subscriptionDict[tier].tagline}
          </Typography>
        </ToolflowSkeletonWrapper>
        {subscriptionDict[tier].benefits.map((benefit, idx) => (
          <ToolflowSkeletonWrapper
            key={idx}
            loading={!user}
            sx={{ paddingTop: 1 }}
          >
            <Typography variant="body2" width="100%" className="p-t-8px">
              - {benefit}
            </Typography>
          </ToolflowSkeletonWrapper>
        ))}
      </div>
      <ToolflowSkeletonWrapper loading={!user} variant="rounded" height={30}>
        {getUpdateSubscriptionButton()}
      </ToolflowSkeletonWrapper>
    </Paper>
  );
}

export const PortalDict: { [key in THyperlineCustomerType]: string } = {
  [HYPERLINE_CUSTOMER_ORGANIZATION]: "Team Billing Details",
  [HYPERLINE_CUSTOMER_USER]: "Personal Billing Details"
};

export function BillingPortalCard({
  customerType
}: {
  customerType: THyperlineCustomerType;
}) {
  const { data, isLoading } = useGetCustomerPortalQuery(customerType);
  const goToPortal = () => {
    if (data?.url) {
      window.location.href = data.url;
    }
  };

  if (!data?.url) {
    return null;
  }
  return (
    <Card variant="outlined" className="m-b-16px">
      <CardHeader
        title={
          <div className="flex align-i-center">
            {PortalDict[customerType]}
            <div className="m-l-8px flex align-i-center">
              <ToolflowInfo
                placement="bottom"
                noCharLimit
                text="Update your address, update payment methods, and view billing history"
              />
            </div>
          </div>
        }
        action={
          <ToolflowSkeletonWrapper
            loading={isLoading}
            variant="rounded"
            width={120}
            height={30}
          >
            <Button onClick={goToPortal} variant="outlined">
              Manage info
            </Button>
          </ToolflowSkeletonWrapper>
        }
      />
    </Card>
  );
}

function BillingCard() {
  const user = useGetCurrentUser();
  const subscriptionDict = useSubscriptionDict();
  return (
    <Box mt={4}>
      <BillingPortalCard customerType={HYPERLINE_CUSTOMER_ORGANIZATION} />
      <BillingPortalCard customerType={HYPERLINE_CUSTOMER_USER} />
      <Card variant="outlined">
        <CardHeader
          title={
            <ToolflowSkeletonWrapper width={40} loading={!user}>
              Subscription
            </ToolflowSkeletonWrapper>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            {(Object.keys(subscriptionDict) as TAvailableTiers[]).map(
              (tier) => (
                <Grid xs={4} key={tier} className="m-b-32px">
                  <SubscriptionTierPaper tier={tier} />
                </Grid>
              )
            )}
          </Grid>
        </CardContent>
      </Card>
      <CreditsUsageCard />
    </Box>
  );
}

export default BillingCard;
