import { useEffect, useMemo } from "react";
import getToolNodes, { getTVRNodes } from "../functions/getToolNodes";
import { useParams } from "react-router-dom";
import useGetCurrentUser from "../../hooks/useGetCurrentUser";
import type { RichTextEditorRef } from "mui-tiptap";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";
import useGetInitialWorkspace from "./useGetInitialWorkspace";
import useConvertTextToTipTap from "../../features/workstation/newDesigns/components/sheetAsset/sheet/utils/hooks/useConvertTextToTipTap";
import type { JSONContent } from "@tiptap/core";
import useValidateObject from "./useValidateObject";

const useGetValidatedInitialContent = ({
  rteRef,
  ref
}: {
  rteRef: React.RefObject<RichTextEditorRef>;
  ref?: React.RefObject<HTMLDivElement>;
}) => {
  const { isLoading } = useGetInitialWorkspace();
  const { muiTipTapProps } = useMuiTipTapContext();
  const { initialContent, routeType, page } = muiTipTapProps;
  const { externalId = "" } = useParams();
  const user = useGetCurrentUser();
  const convertToTipTap = useConvertTextToTipTap();
  const { isLoading: hasValidated } = useValidateObject();
  const validatedInitialContent = useMemo(() => {
    // if the page is still being validated, or the workflow is loading, or the initial state hasn't been fetched return ""
    // this is hacky with the user -> we should refactor
    if ((!hasValidated && page) || isLoading) return "";
    if (externalId && routeType === "tool") {
      return {
        type: "doc",
        content: getToolNodes(externalId)
      };
    }
    if (
      externalId &&
      routeType === "workspace" &&
      typeof initialContent === "string"
    ) {
      return {
        type: "doc",
        content: convertToTipTap(initialContent) as JSONContent
      };
    }
    if (externalId && routeType === "toolVersionResponse") {
      return { type: "doc", content: getTVRNodes(externalId) };
    }
    return initialContent as string;
  }, [externalId, initialContent, hasValidated, isLoading, page, user]);
  const content =
    typeof validatedInitialContent === "string"
      ? validatedInitialContent
      : validatedInitialContent.content;

  // https://github.com/sjdemartini/mui-tiptap/blob/main/README.md#re-rendering-richtexteditor-when-content-changes
  useEffect(() => {
    queueMicrotask(() => {
      rteRef.current?.editor?.commands.setContent(content);
      // we scroll to the bottom of the bounding ref when the content changes
      if (ref?.current) {
        ref.current.scrollTop = ref.current.scrollHeight;
      }
    });
  }, [validatedInitialContent, rteRef.current?.editor]);

  return content;
};

export default useGetValidatedInitialContent;
