import React from "react";
import { securityIsSet } from "../ToolflowAPI/security";
import { useAuth0Store } from "../hooks/useAuth0Store";

const AuthenticationSplitter = ({
  loadingComponent = <></>,
  authenticatedComponent,
  externalComponent,
  loading
}: {
  loadingComponent?: React.ReactNode;
  authenticatedComponent?: React.ReactNode;
  externalComponent: React.ReactNode;
  loading?: boolean;
}) => {
  const { isAuthenticated, isLoading } = useAuth0Store();
  const loadingCondition =
    isLoading || (isAuthenticated && loading) || !securityIsSet();
  if (loadingCondition) {
    return <>{loadingComponent}</>;
  }

  if (isAuthenticated && authenticatedComponent) {
    return <>{authenticatedComponent}</>;
  }
  return <>{externalComponent}</>;
};
export default AuthenticationSplitter;
