import { TextField } from "@mui/material";
import type { WorkflowInput } from "@sharedTypes";
import React from "react";
import useWorkspaceInputById from "../features/workstation/hooks/useWorkspaceInputById";
import useHandleInputNameChange from "./hooks/useHandleInputNameChange";

function UpdateInputNameTextField({ input }: { input: WorkflowInput }) {
  const handleNameChange = useHandleInputNameChange({ input });

  return (
    <TextField
      fullWidth
      autoFocus
      size="small"
      margin="none"
      onChange={handleNameChange}
      value={input.name}
      placeholder="Input name"
      id="dontFocusOnClickId"
    />
  );
}

export function UpdateInputNameTextFieldContainer({
  inputId
}: {
  inputId: string;
}) {
  const input = useWorkspaceInputById({ id: inputId });

  if (!input) return null;

  return <UpdateInputNameTextField input={input} />;
}

export default UpdateInputNameTextField;
