import type { TBlock, Edge, EdgeUpdateFunction } from "@sharedTypes";
import { useCallback } from "react";
import useUpdateAvailableFields from "./useUpdateAvailableFields";
import { Action } from "../toolBuilderReducer";

const useSetEdges = ({
  dispatch,
  blocks,
  edges
}: {
  dispatch: React.Dispatch<Action>;
  blocks: TBlock[];
  edges: Edge[];
}) => {
  const updateAvailableFields = useUpdateAvailableFields(blocks, dispatch);
  // Initialize Graph
  const setEdges = useCallback(
    (
      newState: Edge[] | EdgeUpdateFunction,
      dontUpdateAvailableFields?: boolean
    ) => {
      dispatch({
        type: "SET_STATE",
        key: "edges",
        value: newState
      });

      // custom edge delete for available fields
      // requires onEdgeChange and delete function
      // to not call the update fields
      if (dontUpdateAvailableFields) {
        return;
      }

      // Whenever edges are updated, update available fields
      const updatedEdges = Array.isArray(newState) ? newState : newState(edges);
      updatedEdges.forEach((edge) => {
        if (edge.id.includes("->")) {
          updateAvailableFields(edge.target, updatedEdges);
        }
      });
    },
    [dispatch, blocks, edges] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return setEdges;
};

export default useSetEdges;
