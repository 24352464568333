import React from "react";
import {
  closeWorkflowSaveDialog,
  selectWorkflowSaveDialogOpen
} from "../workflowBuilderSlice";
import { useSelector, useDispatch } from "react-redux";
import SaveWorkflowButton from "./SaveWorkflowButton";
import SaveWorkflowDialogInner from "./SaveWorkflowDialogInner";
import type { ISaveWorkflowButtonProps } from "@sharedTypes";

function SaveWorkflowVersionDialogContainer() {
  const open = useSelector(selectWorkflowSaveDialogOpen);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeWorkflowSaveDialog());
  };

  return <SaveWorkflowDialogInner open={open} handleClose={handleClose} />;
}

function SaveWorkflowVersionDialog(props: Readonly<ISaveWorkflowButtonProps>) {
  return (
    <>
      <SaveWorkflowButton {...props} />
      <SaveWorkflowVersionDialogContainer />
    </>
  );
}

export default SaveWorkflowVersionDialog;
