import { Box, styled, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import CollapsibleAppWarning from "../features/layout/alerts/CollapsibleAppWarning";
import { selectSidebarOpen } from "../features/layout/sidebar/sidebarSlice";
import { toolflowAppBackground } from "../globalTheme/muiUtils/appTheme";
import { SMALL_DRAWER_WIDTH } from "./functions/formatHelpers";

const StyledMainArea = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open"
})<{ open?: boolean }>(({ theme, open }) => ({
  background: toolflowAppBackground,
  minHeight: "400px",
  ...(open
    ? {
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        }),
        overflowX: "hidden",
        width: `calc(100% - ${SMALL_DRAWER_WIDTH}px)`
      }
    : {
        width: "calc(100% - 65px)",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden"
      })
}));

function MainArea() {
  const theme = useTheme();
  const open = useSelector(selectSidebarOpen);

  return (
    <StyledMainArea
      open={open}
      theme={theme}
      className="flex flex-column w-100-percent scrollable-content h-100vh"
    >
      <CollapsibleAppWarning />
      <Outlet />
    </StyledMainArea>
  );
}

export default MainArea;
