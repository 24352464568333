import { MoreHoriz } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import {
  MenuDivider,
  MenuButtonItalic,
  MenuButtonStrikethrough,
  MenuButtonTextColor,
  MenuButtonBulletedList,
  MenuButtonCode,
  MenuSelectFontFamily,
  MenuButtonCodeBlock,
  MenuButtonHighlightColor,
  MenuButtonHorizontalRule,
  MenuButtonAddTable,
  MenuButtonRemoveFormatting,
  MenuButtonSuperscript,
  MenuButtonSubscript,
  MenuButtonBlockquote,
  MenuSelectTextAlign,
  MenuSelectFontSize,
  MenuButton,
  MenuButtonTaskList,
  MenuButtonOrderedList,
  MenuButtonEditLink,
  MenuButtonUnderline,
  MenuButtonBold,
  MenuSelectHeading
} from "mui-tiptap";
import React, { useState } from "react";

function FormattingMenuButtons() {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  return (
    <>
      {/* The flicker happens because of React StrictMode. Should be fine in prod */}
      <MenuSelectHeading />

      <MenuDivider />

      <MenuButtonBold />

      <MenuButtonItalic />

      <MenuButtonUnderline />
      <MenuButtonStrikethrough />
      <MenuButtonEditLink />

      <MenuDivider />

      <MenuButtonTextColor
        PopperProps={{ id: "dontCloseOnClickId" }}
        defaultTextColor={theme.palette.text.primary}
        swatchColors={[
          { value: "#000000", label: "Black" },
          { value: "#ffffff", label: "White" },
          { value: "#888888", label: "Grey" },
          { value: "#ff0000", label: "Red" },
          { value: "#ff9900", label: "Orange" },
          { value: "#ffff00", label: "Yellow" },
          { value: "#00d000", label: "Green" },
          { value: "#0000ff", label: "Blue" }
        ]}
      />

      <MenuDivider />

      <MenuButtonOrderedList />

      <MenuButtonBulletedList />

      <MenuButtonTaskList />

      <MenuDivider />

      <MenuButtonCode />

      <MenuDivider />

      {!open && (
        <MenuButton tooltipLabel="More Options" onClick={() => setOpen(true)}>
          <MoreHoriz />
        </MenuButton>
      )}
      {open && (
        <>
          <MenuSelectFontSize />
          <MenuSelectFontFamily
            options={[
              {
                label: "Comic Sans",
                value: "Comic Sans MS, Comic Sans"
              },
              { label: "Cursive", value: "cursive" },
              { label: "Monospace", value: "monospace" },
              { label: "Serif", value: "serif" }
            ]}
          />
          <MenuDivider />

          <MenuSelectTextAlign />
          <MenuDivider />
          <MenuButtonCodeBlock />
          <MenuButtonBlockquote />
          <MenuDivider />
          <MenuButtonHighlightColor
            swatchColors={[
              { value: "#595959", label: "Dark grey" },
              { value: "#dddddd", label: "Light grey" },
              { value: "#ffa6a6", label: "Light red" },
              { value: "#ffd699", label: "Light orange" },
              // Plain yellow matches the browser default `mark` like when using Cmd+Shift+H
              { value: "#ffff00", label: "Yellow" },
              { value: "#99cc99", label: "Light green" },
              { value: "#90c6ff", label: "Light blue" },
              { value: "#8085e9", label: "Light purple" }
            ]}
          />
          <MenuDivider />
          <MenuButtonSubscript />

          <MenuButtonSuperscript />
          <MenuDivider />

          <MenuButtonHorizontalRule />
          <MenuButtonAddTable />
          <MenuDivider />
          <MenuButtonRemoveFormatting />
        </>
      )}
    </>
  );
}

export default FormattingMenuButtons;
