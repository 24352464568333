import { Typography } from "@mui/material";
import React from "react";
import { useInputContext } from "../../../../../utilities/contexts/InputContext";
import { useToolContext } from "../../../../../utilities/contexts/ToolContext";
import ToolContextComponentContainer from "../../../../../utilities/contexts/ToolContextContainer";
import AssetName from "../asset/AssetName";
import TextAndBadge from "../textAndBadge/TextAndBadge";

function ToolAndAssetName({
  firstText,
  secondText,
  badgeCount
}: {
  firstText: string;
  secondText: string;
  badgeCount?: number;
}) {
  return (
    <div className="inline">
      <TextAndBadge text={firstText} badgeCount={badgeCount} />
      <Typography
        variant="h6"
        component="span"
        className="m-h-8px"
        sx={{ color: (theme) => theme.palette.text.secondary }}
      >
        ·
      </Typography>
      <AssetName text={secondText} />
    </div>
  );
}

function ToolAndAssetNameInnerContainer() {
  const { tool } = useToolContext();
  const { input } = useInputContext();
  return (
    <ToolAndAssetName
      firstText={tool.name}
      secondText={input.name}
      badgeCount={input.data?.toolCount}
    />
  );
}

function ToolAndAssetNameContainer({ toolId }: { toolId?: string }) {
  return (
    <ToolContextComponentContainer toolId={toolId}>
      <ToolAndAssetNameInnerContainer />
    </ToolContextComponentContainer>
  );
}

export default ToolAndAssetNameContainer;
