import type {
  CopyToolResponseFE,
  GetToolResponseFE,
  RunToolBody,
  SaveToolBody,
  SaveToolBodyQuery,
  SaveToolResponseFE,
  ToolResponseFE,
  UpdateToolDataToSend,
  UpdateToolResponseFE
} from "@sharedTypes";
import { authenticatedApi } from "../authenticatedAPI";

import { setErrorMessage, setSuccessMessage } from "../../stores/actions";
import { externalToolAdapter } from "../../stores/entityAdapters";
import { RootState } from "../../stores/store";
import {
  CREDITS_TAG_TYPE,
  TOOL_DETAIL_TAG_TYPE,
  TOOL_TAG_TYPE
} from "../tagConstants";
import invalidateToolTags from "./helpers/invalidateToolTags";

export const toolsApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteTool: builder.mutation({
      query: (toolId: string) => ({
        url: `tool/${toolId}`,
        method: "DELETE"
      }),
      async onQueryStarted(toolId, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          invalidateToolTags(dispatch, getState() as RootState);
          dispatch(setSuccessMessage("Tool deleted"));
        } catch {
          dispatch(setErrorMessage("Error deleting tool"));
        }
      }
    }),
    getTool: builder.query<GetToolResponseFE, string>({
      query: (toolId: string) => ({
        url: `tool/${toolId}`,
        method: "GET"
      }),
      providesTags: (result, error, args) => {
        const toolId = args;
        return [{ type: TOOL_DETAIL_TAG_TYPE, id: toolId }];
      }
    }),
    abortComponent: builder.mutation({
      query: (componentId: string) => ({
        url: `abortComponent/${componentId}`,
        method: "DELETE"
      }),
      async onQueryStarted(componentId, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          dispatch(setErrorMessage("Error aborting"));
        }
      }
    }),
    saveTool: builder.mutation<SaveToolResponseFE, SaveToolBodyQuery>({
      query: ({
        toolData,
        toolId
      }: {
        toolData: SaveToolBody;
        toolId?: string;
      }) => ({
        url: `tool${toolId ? `/${toolId}` : ""}`,
        method: "POST",
        body: toolData
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          invalidateToolTags(dispatch, getState() as RootState);
          dispatch(setSuccessMessage("Tool saved"));
        } catch {
          dispatch(setErrorMessage("Error updating tool"));
        }
      }
    }),
    updateTool: builder.mutation<
      UpdateToolResponseFE,
      {
        toolId: string;
        body: UpdateToolDataToSend;
      }
    >({
      query: ({ toolId, body }) => ({
        url: `tool/${toolId}`,
        method: "PUT",
        body
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          invalidateToolTags(dispatch, getState() as RootState);
          dispatch(setSuccessMessage("Tool saved"));
        } catch {
          dispatch(setErrorMessage("Error updating tool"));
        }
      }
    }),
    copyTool: builder.mutation<CopyToolResponseFE, string>({
      query: (toolId: string) => ({
        url: `/tool/${toolId}/copy`,
        method: "POST"
      }),
      async onQueryStarted(toolId, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          invalidateToolTags(dispatch, getState() as RootState);
          dispatch(setSuccessMessage("Tool copied"));
        } catch {
          dispatch(setErrorMessage("Error copying tool"));
        }
      }
    }),
    getProfileTools: builder.query({
      query: (profileId: string) => ({
        url: `tool/p/${profileId}`,
        method: "GET"
      }),
      transformResponse: (response: ToolResponseFE) => {
        return externalToolAdapter.upsertMany(
          externalToolAdapter.getInitialState(),
          response.tools
        );
      },
      providesTags: (result, error, args) => {
        const profileId = args;
        return [{ type: TOOL_TAG_TYPE, id: profileId }];
      }
    }),
    runTool: builder.mutation({
      query: ({
        toolId,
        toolData
      }: {
        toolId: string;
        toolData: RunToolBody;
      }) => ({
        url: `runTool/${toolId}`,
        method: "POST",
        body: toolData,
        timeout: 30000
        // when using axios we had the following options, check if this works, take out if its fine
        // `maxContentLength` defines the max size of the http response content in bytes allowed in node.js
        // maxContentLength: 500000,

        // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
        // maxBodyLength: 500000
      }),
      invalidatesTags: [CREDITS_TAG_TYPE]
    })
  })
});

export const {
  useSaveToolMutation,
  useRunToolMutation,
  useGetProfileToolsQuery,
  useDeleteToolMutation,
  useCopyToolMutation,
  useGetToolQuery,
  useAbortComponentMutation,
  useUpdateToolMutation,
  usePrefetch,
  useLazyGetToolQuery
} = toolsApi;
