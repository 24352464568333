import type { TWorkspaceDataToSave } from "@sharedTypes";
import { WORKSPACE_WORKFLOW_PROGRESS_FIELD_LABEL } from "../../../workspaceConstants";
import { useFormContext } from "react-hook-form";

const useClearWorkflowProgress = () => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();
  const clearWorkflowProgress = () => {
    setValue(
      WORKSPACE_WORKFLOW_PROGRESS_FIELD_LABEL,
      {},
      {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      }
    );
  };
  return clearWorkflowProgress;
};

export default useClearWorkflowProgress;
