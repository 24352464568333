import React from "react";

import EditorToolCardContent from "./EditorToolCardContent";
import EditorToolCardActions from "./EditorToolCardActions";
import EditorToolCardWrapper from "./EditorToolCardWrapper";

function EditorToolCard() {
  return (
    <EditorToolCardWrapper>
      <EditorToolCardContent />
      <EditorToolCardActions />
    </EditorToolCardWrapper>
  );
}

export default EditorToolCard;
