import React from "react";
import FullWidthCard from "../../layout/cards/FullWidthCard";
import JoinSlackButton from "./JoinSlackButton";
import SlackLogo from "../../../utilities/IconLogos/SlackLogo";

const JoinSlackCard = () => {
  return (
    <FullWidthCard
      className="m-t-16px bg-color-off-white"
      title="Join our Slack community"
      action={<JoinSlackButton />}
      icon={<SlackLogo width={30} />}
      subHeader="Meet your peers, learn tool-building, and upskill for generative work."
    />
  );
};

export default JoinSlackCard;
