import { useEffect } from "react";
import { useDuplicateObject } from "../utilities/hooks/useDuplicateObject";
import { useAuth0Store } from "./useAuth0Store";

const useDuplicateObjectOnAuth = () => {
  const { duplicateObject } = useDuplicateObject();
  const { isAuthenticated } = useAuth0Store();

  useEffect(() => {
    const justAuthenticated =
      localStorage.getItem("justAuthenticated") === "true";
    const storedFunctionDetails = localStorage.getItem("callbackFunction");

    if (isAuthenticated && justAuthenticated && storedFunctionDetails) {
      const functionDetails = JSON.parse(
        localStorage.getItem("callbackFunction") || ""
      );
      if (functionDetails.function === "handleCopy") {
        const id = functionDetails.id;
        const type = functionDetails.type;
        duplicateObject(id, type);
        localStorage.setItem("callbackFunction", "");
      }
      localStorage.setItem("justAuthenticated", "false");
    }
  }, [isAuthenticated]);
};

export default useDuplicateObjectOnAuth;
