import type {
  PrimitiveOrObjectOrArray,
  THomePageEntity,
  VisibilityTypes,
  Workspace
} from "@sharedTypes";
import { WorkspaceTableData, Order, ToolsTableData } from "./tableTypes";
import { getTruncatedWords } from "../../marketplace/utils/getTruncatedWords";

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof $TSAllowedAny>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: PrimitiveOrObjectOrArray },
  b: { [key in Key]: PrimitiveOrObjectOrArray }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function createWorkspaceData(
  id: string,
  name: string,
  date: number,
  visibility: VisibilityTypes,
  actions: string
): WorkspaceTableData {
  return {
    id,
    name,
    date,
    visibility,
    actions
  };
}

export const convertWorkspaceTableData = (
  workspace: Workspace
): WorkspaceTableData => {
  return {
    actions: "Actions",
    date: workspace.updatedAt,
    id: workspace._id,
    name: workspace.name,
    visibility: workspace.visibility
  };
};

export function createToolsData({
  entity
}: {
  entity: THomePageEntity;
}): ToolsTableData {
  const blocks = entity?.tag?.blocks || [];
  const useCases = entity?.tag?.useCases || [];
  const categories = entity?.tag?.categories || [];
  return {
    name: getTruncatedWords(entity.name, 10),
    date: entity.updatedAt,
    useCases,
    categories,
    blocks,
    id: entity._id,
    visibility: entity.visibility,
    type: entity.type,
    doesUserOwnEntity: entity.doesUserOwnEntity,
    mainId: entity.mainId || "",
    actions: "Actions",
    runCount: entity.runCount || 0
  };
}

export function convertRowToEntity(row: ToolsTableData): THomePageEntity {
  return {
    name: row.name,
    updatedAt: row.date,
    tag: {
      blocks: row.blocks || [],
      useCases: row.useCases || [],
      categories: row.categories || []
    },
    _id: row.id,
    visibility: row.visibility,
    type: row.type,
    doesUserOwnEntity: row.doesUserOwnEntity,
    mainId: row.mainId
  };
}
