import React from "react";
import Box from "@mui/material/Box";
import type {
  UserInputDictType,
  CustomToolInputField,
  TSetFormState
} from "@sharedTypes";
import { InputTextField } from "./Inputs/InputTextField";
import { InputCheckbox } from "./Inputs/InputCheckbox";
import { InputSelect } from "./Inputs/InputSelect";
import { InputMultiSelect } from "./Inputs/InputMultiSelect";
import { InputFileUpload } from "./Inputs/InputFileUpload";
import { InputTags } from "./Inputs/InputsTags";
import { InputDoubleTextField } from "./Inputs/InputDoubleTextField";

type TBaseProps = {
  toolInputField: CustomToolInputField;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  useIds?: boolean;
};
type TExtraProps = {
  disabled?: boolean;
  autofocus?: boolean;
  defaultFormState?: UserInputDictType;
  defaultExpanded?: boolean;
};

interface InputComponentConfig {
  component: React.ElementType;
  propFilter?: (
    baseProps: TBaseProps,
    extraProps: TExtraProps
  ) => TBaseProps & Partial<TExtraProps>; // Optional, for additional props
}

// Base props that are common across all components
export const baseProps = ({
  toolInputField,
  setFormState,
  formState,
  useIds
}: TBaseProps) => ({
  toolInputField,
  setFormState,
  formState,
  useIds
});

export const inputComponentMap: { [key: string]: InputComponentConfig } = {
  textField: {
    component: InputTextField,
    propFilter: (bProps, { defaultExpanded, defaultFormState }) => ({
      ...bProps,
      defaultExpanded,
      defaultFormState
    })
  },
  largeTextField: {
    component: InputTextField,
    propFilter: (bProps, { defaultExpanded, defaultFormState }) => ({
      ...bProps,
      defaultExpanded,
      defaultFormState
    })
  },
  checkbox: {
    component: InputCheckbox,
    propFilter: (bProps, { disabled }) => ({ ...bProps, disabled })
  },
  select: {
    component: InputSelect,
    propFilter: (bProps, { disabled, autofocus }) => ({
      ...bProps,
      disabled,
      autofocus
    })
  },
  multiSelect: { component: InputMultiSelect },
  csvFileUpload: {
    component: InputFileUpload,
    propFilter: (bProps, { disabled }) => ({ ...bProps, disabled })
  },
  fileUpload: {
    component: InputFileUpload,
    propFilter: (bProps, { disabled }) => ({ ...bProps, disabled })
  },
  tagsInput: { component: InputTags },
  doubleTextfield: { component: InputDoubleTextField }
};

const WebsiteForm = ({
  formState,
  setFormState,
  disabledInputs,
  toolInputFields,
  defaultFormState,
  autofocus,
  useIds,
  fieldsToHide = []
}: {
  formState: UserInputDictType;
  defaultFormState?: UserInputDictType;
  setFormState: TSetFormState;
  disabledInputs?: boolean;
  toolInputFields: CustomToolInputField[];
  autofocus?: boolean;
  useIds?: boolean;
  fieldsToHide?: string[];
}) => {
  if (toolInputFields.length === 0) return null;

  return (
    <Box className="flex-grow-1">
      {toolInputFields
        .filter((input) => !fieldsToHide.includes(input.id))
        .map((toolInputField, index) => {
          const config = inputComponentMap[toolInputField.type];
          if (!config) return null; // Skip if no matching component is found

          const InputComponent = config.component;
          const commonProps = baseProps({
            toolInputField,
            setFormState,
            formState,
            useIds
          });
          const extraProps = {
            disabled: disabledInputs,
            autofocus: index === 0 && autofocus,
            defaultFormState,
            defaultExpanded: true
          };

          // Apply propFilter if it exists, otherwise pass common props directly
          const props = config.propFilter
            ? config.propFilter(commonProps, extraProps)
            : commonProps;

          return (
            <div key={index} className="p-v-8px">
              <InputComponent {...props} />
            </div>
          );
        })}
    </Box>
  );
};

export default WebsiteForm;
