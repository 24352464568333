import { useContext } from "react";
import { FavoriteContext, IToggleFavoriteTool } from "./ToggleFavorite";

const useFavoriteContext = (): IToggleFavoriteTool => {
  const context = useContext(FavoriteContext);

  if (context === undefined) {
    throw new Error(
      "useFavoriteContext must be used within a FavoriteContext Provider"
    );
  }

  return context;
};

export default useFavoriteContext;
