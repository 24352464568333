import React, { FC, useState } from "react";
import { RiEditLine } from "@remixicon/react";
import { useSettingsDropdownContext } from "../generic/useSettingsDropownContext";
import RenameDialog from "../../../features/home/layout/RenameDialog";
import SettingsMenuItem from "../generic/SettingsMenuItem";
import RenameWorkspaceForm from "../../../features/home/workspace/RenameWorkspaceForm";

interface IRenameWorkspaceMenuItemProps {
  workspaceId: string;
  workspaceName: string;
}
const RenameWorkspaceMenuItem: FC<IRenameWorkspaceMenuItemProps> = ({
  workspaceId,
  workspaceName
}) => {
  const { handleClose } = useSettingsDropdownContext();
  const [open, setOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const close = (e: React.SyntheticEvent<Element, Event>) => {
    e.stopPropagation();
    handleClose(e);
    setOpen(false);
  };
  return (
    <>
      <SettingsMenuItem
        icon={<RiEditLine size={16} />}
        text="Rename"
        action={handleOpen}
      />
      <RenameDialog open={open} closeDialog={close} title={"Rename session"}>
        <RenameWorkspaceForm
          initialName={workspaceName}
          id={workspaceId}
          type="workspace"
          completeAction={close}
        />
      </RenameDialog>
    </>
  );
};

export default RenameWorkspaceMenuItem;
