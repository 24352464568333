import React from "react";
import { useGetInitialStateQuery } from "./ToolflowAPI/rtkRoutes/initialStateApi";
import AlertMessageSnackbar from "./features/layout/alerts/AlertMessageSnackbar";
import SidebarContainer from "./features/layout/sidebar/SidebarContainer";
import { useAuth0Store } from "./hooks/useAuth0Store";
import useDuplicateObjectOnAuth from "./hooks/useDuplicateObjectOnAuth";
import useGetCurrentUser from "./hooks/useGetCurrentUser";
import useUpdateIntercomOnPageChange from "./hooks/useUpdateIntercomOnPageChange";
import RouterListener from "./navigation/RouterListener";
import { useDocumentTitle } from "./navigation/useDocumentTitle";
import LoggedOutWrapper from "./utilities/LoggedOutWrapper";
import ToolInfoDrawer from "./utilities/ToolInfoDrawer";

function AppInner() {
  useDuplicateObjectOnAuth();
  useUpdateIntercomOnPageChange();
  useDocumentTitle();

  return (
    <div className="min-h-100vh flex bg-color-app-background">
      <RouterListener />
      <SidebarContainer />
      <LoggedOutWrapper />
      <AlertMessageSnackbar />
      <ToolInfoDrawer />
    </div>
  );
}

function useRerouteToCalendly() {
  const user = useGetCurrentUser();
  return user?.requiresMeeting;
}

function AuthenticationWrapper({ children }: { children: React.ReactNode }) {
  useGetInitialStateQuery();
  const rerouteToCalendly = useRerouteToCalendly();
  if (rerouteToCalendly) {
    window.location.replace(
      "https://calendly.com/alfie-marsh-toolflow/toolflow-beta-onboarding"
    );
    return <></>;
  }
  return <>{children}</>;
}

function App() {
  const { isAuthenticated } = useAuth0Store();
  if (isAuthenticated) {
    return (
      <AuthenticationWrapper>
        <AppInner />
      </AuthenticationWrapper>
    );
  }
  return <AppInner />;
}

export default App;
