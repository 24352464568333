import React from "react";
import { Box, Divider } from "@mui/material";
import NoWrapCardHeader from "../../../utilities/NoWrapCardHeader";
import ToggleWorkflowNameTextField from "./ToggleWorkflowNameTextField";
import ToggleWorkflowDescriptionTextField from "./ToggleWorkflowDescriptionTextField";
import SaveWorkflowVersionDialog from "./SaveWorkflowVersionDialog/SaveWorkflowVersionDialog";
import LastPageBreadcrumb from "../../../toolBuilder/LastPageBreadcrumb";
import NavigateToWorkflowButtonContainer from "./NavigateToWorkflowButtonContainer";
import WorkflowBuilderHeaderSettingsDropdown from "../../../toolBuilder/dropdowns/WorkflowBuilderHeaderSettingsDropdown";
import { useSelector } from "react-redux";
import { selectSidebarOpen } from "../../layout/sidebar/sidebarSlice";
import ToggleSidebarButton from "../../layout/sidebar/ToggleSidebarButton";

function WorkflowBuilderHeader() {
  const open = useSelector(selectSidebarOpen);
  return (
    <Box
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        position: "relative",
        backgroundColor: "white",
        marginLeft: "1px"
      }}
      className="flex align-i-center"
    >
      {!open && <ToggleSidebarButton className="m-l-8px" />}
      <NoWrapCardHeader
        title={
          <div className="flex align-i-center">
            <LastPageBreadcrumb className="m-r-8px" />
            <ToggleWorkflowNameTextField />
            <ToggleWorkflowDescriptionTextField />
          </div>
        }
        action={
          <>
            <SaveWorkflowVersionDialog />
            <NavigateToWorkflowButtonContainer />
            <WorkflowBuilderHeaderSettingsDropdown />
          </>
        }
        disableTypography
        className="w-100-percent"
      />
      <Divider />
    </Box>
  );
}

export default WorkflowBuilderHeader;
