import React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  type SelectChangeEvent
} from "@mui/material";
import useGetSelectedStepMappedInputsField from "./hooks/useGetSelectedStepMappedInputs";
import { useCustomToolInputFieldContext } from "../../../../../utilities/contexts/CustomToolInputFieldContext";
import parsePreviousField from "./parsePreviousField";
import serializePreviousField from "./serializePreviousField";
import useGetPreviousFieldOptions from "./hooks/useGetPreviousFieldOptions";

const SELECT_LABEL = "Field to map";

const MapToPreviousInputSelect = () => {
  const previousFieldOptions = useGetPreviousFieldOptions();
  const { input } = useCustomToolInputFieldContext();

  const mappedInputsFieldName = useGetSelectedStepMappedInputsField();
  const nameToRegister = `${mappedInputsFieldName}.${input.id}` as const;

  const disabled = !previousFieldOptions || previousFieldOptions.length === 0;

  return (
    <FormControl size="small" fullWidth>
      <InputLabel>{SELECT_LABEL}</InputLabel>
      <Controller
        name={`${nameToRegister}.previousField`}
        render={({ field }) => {
          const setSelection = (e: SelectChangeEvent) => {
            const valToChange = parsePreviousField(e.target.value);
            field.onChange(valToChange);
          };
          return (
            <Select
              {...field}
              value={serializePreviousField(field.value)}
              label={SELECT_LABEL}
              labelId={SELECT_LABEL}
              onChange={setSelection}
              disabled={disabled}
            >
              {previousFieldOptions}
            </Select>
          );
        }}
      />
      {disabled && (
        <FormHelperText error>No previous fields created</FormHelperText>
      )}
    </FormControl>
  );
};

export default MapToPreviousInputSelect;
