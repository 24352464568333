import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import type { SelectChangeEvent } from "@mui/material/Select";
import React, { FC } from "react";
import useUpdateField from "../../useUpdateField";
import isEmpty from "lodash/isEmpty";
import type {
  CustomToolInputField,
  CustomToolInputFieldTypes
} from "@sharedTypes";
import { CREATE_USER_INPUT_LABEL } from "../../../utilities/Inputs/constants";
import { DOUBLE_TEXTFIELD } from "../../../utilities/Inputs/inputConstants";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";

interface IGetReplaceWordsFromUserProps {
  id: string;
  settings: { userWordsToReplaceFieldKey: string };
  openInput: () => void;
  updateFieldKey: string;
  handleChangeInitialFieldType: (field: CustomToolInputFieldTypes) => void;
}

const GetReplaceWordsFromUser: FC<IGetReplaceWordsFromUserProps> = (props) => {
  const {
    id,
    openInput,
    updateFieldKey,
    handleChangeInitialFieldType,
    settings: { userWordsToReplaceFieldKey }
  } = props;
  const getInputReplaceWordsFields = (inputs: CustomToolInputField[]) =>
    inputs.filter((field) => field.type === DOUBLE_TEXTFIELD);
  const updateField = useUpdateField(id);
  const { state } = useToolbuilderContext();
  const { currentState } = state;
  const { toolInputFields } = currentState;
  const inputReplaceWordsFields = getInputReplaceWordsFields(toolInputFields);

  const onFieldTypeChange = () => {
    handleChangeInitialFieldType(DOUBLE_TEXTFIELD);
    openInput();
  };

  const updateUserReplaceWordsState = (event: SelectChangeEvent<string>) => {
    updateField(event.target.value, updateFieldKey);
  };

  const userWordsReplaceValue = inputReplaceWordsFields
    .map((o) => o.name)
    .includes(userWordsToReplaceFieldKey)
    ? userWordsToReplaceFieldKey
    : "";
  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel id="words-to-replace">
        Ask for words to replace from user
      </InputLabel>
      <Select
        labelId="words-to-replace"
        id="words-to-replace"
        fullWidth
        label="Ask for words to replace from user"
        onChange={updateUserReplaceWordsState}
        className="capitalize nowheel nodrag nopan"
        value={userWordsReplaceValue}
      >
        <MenuItem value="" onClick={onFieldTypeChange}>
          {CREATE_USER_INPUT_LABEL}
        </MenuItem>
        {inputReplaceWordsFields.map((option: CustomToolInputField) => (
          <MenuItem value={option.name} key={option.id} className="capitalize">
            {option.name}
          </MenuItem>
        ))}
        {!isEmpty(inputReplaceWordsFields) && (
          <MenuItem
            value=""
            key="unmap-input"
            onClick={() => updateField("", updateFieldKey)}
          >
            Unmap input
          </MenuItem>
        )}
      </Select>
      <FormHelperText>
        On each tool run, the user will have the option to add words to replace.
      </FormHelperText>
    </FormControl>
  );
};

export default GetReplaceWordsFromUser;
