import { useEffect } from "react";
import useGetSelectedStepToolId from "../runTool/hooks/useGetSelectedStepToolId";
import useGetToolFromQuery from "../../../../../utilities/toolSelector/hooks/useGetToolFromQuery";
import useGetSelectedStep from "./useGetSelectedStep";
import { useFormContext } from "react-hook-form";
import type {
  ExternalToolFE,
  RunToolStep,
  TMappedInputs,
  TRequestInputFromUserType,
  TWorkflowBuilderForm
} from "@sharedTypes";
import useGetSelectedStepMappedInputsField from "../runTool/hooks/useGetSelectedStepMappedInputs";

const REQUEST_INPUT_FROM_USER: TRequestInputFromUserType = "requestFromUser";
export const DEFAULT_MAPPED_INPUT = { type: REQUEST_INPUT_FROM_USER };

const getInitialInputs = (tool?: ExternalToolFE) => {
  return (
    tool?.main.toolInputFields.reduce((acc, field) => {
      acc[field.id] = DEFAULT_MAPPED_INPUT;
      return acc;
    }, {} as TMappedInputs) || {}
  );
};

const useSetDefaultInputsOnToolChange = () => {
  const toolId = useGetSelectedStepToolId();
  const tool = useGetToolFromQuery(toolId);
  const step = useGetSelectedStep();
  const { setValue, register, getFieldState, resetField } =
    useFormContext<TWorkflowBuilderForm>();
  const mappedInputs = useGetSelectedStepMappedInputsField();

  useEffect(() => {
    // we need the && step because sometimes the step is undefined which
    // messes things up

    const setInitialFieldValues =
      !getFieldState(mappedInputs).isTouched &&
      step &&
      tool?._id &&
      tool?._id === toolId; // this hook rerenders, and sometimes the tool?._id is the old toolId. We need to wait until the tool is fetched before we can set the initial values

    if (setInitialFieldValues) {
      register(mappedInputs);
      const initialInputs = getInitialInputs(tool);

      setValue(
        mappedInputs,
        Object.keys((step as RunToolStep).settings.mappedInputs).length === 0
          ? initialInputs
          : (step as RunToolStep).settings.mappedInputs,
        {
          shouldDirty: false,
          shouldTouch: false
        }
      );
    }
    if (!tool?._id) {
      resetField(mappedInputs, {
        keepDirty: false,
        keepTouched: false,
        keepError: false
      });
    }
  }, [tool?._id]);
};

export default useSetDefaultInputsOnToolChange;
