import { useFormContext } from "react-hook-form";
import type { TWorkspaceDataToSave } from "@sharedTypes";
import { WORKSPACE_VISIBLE_ASSET_ID } from "../../workspaceConstants";

const useGetAssetId = () => {
  const { watch } = useFormContext<TWorkspaceDataToSave>();
  return watch(WORKSPACE_VISIBLE_ASSET_ID) ?? "";
};

export default useGetAssetId;
