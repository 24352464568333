import { Box, Grid, Typography } from "@mui/material";
import { RiPlayFill, RiPriceTag3Fill } from "@remixicon/react";
import React from "react";
import { toolflowPrimaryColor } from "../../../../globalTheme/muiUtils/appTheme";
import useGetCredits from "../../../../utilities/hooks/useGetCredits";
import FullWidthCard from "../../../layout/cards/FullWidthCard";
import BillingCreditUsageCard from "./BillingCreditUsageCard";
import BillingToolRunsUsageCard from "./BillingToolRunsUsageCard";
const UsageSection = () => {
  const { data } = useGetCredits();
  return (
    <>
      <Typography variant="h7">Usage overview</Typography>
      <Grid container columnSpacing={3} rowSpacing={1} className="m-t-12px">
        <Grid xs={12} md={6} item>
          <FullWidthCard
            icon={<RiPriceTag3Fill color={toolflowPrimaryColor} />}
            title=" "
            key={"creditsUsage"}
          >
            <Box className="p-l-16px m-b-16px">
              <Typography variant="h7">Credit Usage</Typography>
            </Box>
            {data && <BillingCreditUsageCard credits={data.credits} />}
          </FullWidthCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <FullWidthCard
            icon={<RiPlayFill color={toolflowPrimaryColor} />}
            title=" "
            key={"toolsRunsUsage"}
          >
            <Box className="p-l-16px m-b-16px">
              <Typography variant="h7">Tool runs Usage</Typography>
            </Box>
            {data && <BillingToolRunsUsageCard credits={data.credits} />}
          </FullWidthCard>
        </Grid>
      </Grid>
    </>
  );
};

export default UsageSection;
