import { DragIndicator } from "@mui/icons-material";
import React from "react";
import { useUtilityDraggableContext } from "./UtilityDraggableContext";
import { Box, type SxProps } from "@mui/material";
import useIsDragging from "./hooks/useIsDragging";

function UtilityDragHandle({
  className,
  disabled,
  sx
}: {
  className?: string;
  sx?: SxProps;
  disabled?: boolean;
}) {
  const isDragging = useIsDragging();
  const { dragHandleRef } = useUtilityDraggableContext();
  // const { provided } = useUtilityDraggableContext();
  return (
    <Box
      ref={dragHandleRef}
      // {...provided.dragHandleProps}
      className={`flex align-i-center ${className}`}
      sx={sx}
    >
      <DragIndicator
        sx={
          disabled || isDragging
            ? {
                color: (theme) => theme.palette.action.disabled,
                cursor: "auto"
              }
            : { cursor: "grab" }
        }
      />
    </Box>
  );
}

export default UtilityDragHandle;
