import React, { FC } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GenerateOpenAIAPIKey from "../GenerateOpenAIAPIKey";
import OpenAIBillingLink from "../OpenAIBillingLink";
import OpenAIPricingLink from "../OpenAIPricingLink";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import type { TSecretType } from "@sharedTypes";
import { Link } from "react-router-dom";
interface IAddNewOpenAIKeysInstructionsProps {
  handleChange: (value: string) => void;
  handleSaveSecret: (secretType: TSecretType) => void;
  val: string;
  isDisabled: boolean;
  loading: boolean;
}

const AddNewOpenAIKeysInstructions: FC<IAddNewOpenAIKeysInstructionsProps> = (
  props
) => {
  const { isDisabled, loading, handleChange, handleSaveSecret, val } = props;
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>
          Step by step instructions to connect with OpenAI
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="subtitle1" className="m-t-16px">
          1. Create an account or sign in if you have one
          <Link
            to="https://chat.openai.com/auth/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className={`m-l-8px`}>Create account</Button>
          </Link>
        </Typography>
        <Typography variant="subtitle1" className="m-t-16px">
          2. Set Up Billing <OpenAIBillingLink />
        </Typography>
        <Typography variant="caption" className="m-b-8px">
          You wont be charged until you use your plan. Pricing varies by model
          used.
        </Typography>
        <div className="flex align-i-center m-t-16px">
          <Typography variant="subtitle1">2. Generate API Key</Typography>
          <GenerateOpenAIAPIKey className="m-l-8px" />
        </div>
        <Typography variant="caption" className="m-b-8px">
          API keys allow you to connect with other applications on the internet.
          If you have a free API key, you need to set up billing and then
          generate a new key.
        </Typography>
        <OpenAIPricingLink />
        <div className="flex align-i-center m-t-16px">
          <FormControl className="m-r-16px">
            <InputLabel id="prompt-apiKey-label">Integration</InputLabel>
            <Select
              labelId="apiKey-label"
              id="apiKey"
              value="openAI"
              label="Integration"
              size="small"
              notched
              disabled
            >
              <MenuItem value="openAI">OpenAI</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="API Key"
            value={val}
            size="small"
            onChange={(e) => handleChange(e.target.value)}
            className="m-r-8px"
            disabled={isDisabled}
          />
          <LoadingButton
            size="small"
            loading={loading}
            onClick={() => handleSaveSecret("openAI")}
          >
            Save
          </LoadingButton>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AddNewOpenAIKeysInstructions;
