import React from "react";
import type { ConstantBlockData } from "@sharedTypes";
import useUpdateField from "../../useUpdateField";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import PromptEditor from "../../promptEditor/PromptEditor";

function ConstantBlockInner({
  id,
  data
}: {
  id: string;
  data: ConstantBlockData;
}) {
  const { constant } = data;
  const { state } = useToolbuilderContext();
  const updateField = useUpdateField(id);
  const { currentState } = state;

  const updateConstant = (value: string) => {
    if (currentState.toolOutputOptions) {
      updateField(value, "constant");
    }
  };

  return (
    <div className="flex flex-column m-v-16px">
      <PromptEditor
        id={id}
        prompt={constant}
        handleUpdatePrompt={updateConstant}
      />
    </div>
  );
}
export default ConstantBlockInner;
