import React from "react";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "../../../../../layout/alerts/alertMessageSlice";
import useGetCurrentAsset from "../../../hooks/useGetCurrentAsset";
import { getPlainTextFromHtml } from "../../../../../../utilities/functions/htmlHelpers";
import useGetInputHtml from "../../../../../../workspace/hooks/useGetInputHtml";
import RemixButton from "../../../../../layout/icons/RemixButton";
import { CopyIcon } from "../../../icons/icons";

// This doesn't copy tools, tvrs, need to update parseHtml, renderHtml in extensions
const CopyInputText = () => {
  const reduxDispatch = useDispatch();
  const input = useGetCurrentAsset();
  const getInputHtml = useGetInputHtml();

  const handleCopyClick = () => {
    if (input?.valueType === "string") {
      const html = getInputHtml(input);
      navigator.clipboard.write([
        new ClipboardItem({
          "text/plain": new Blob([getPlainTextFromHtml(html)], {
            type: "text/plain"
          }),
          "text/html": new Blob([html], { type: "text/html" })
        })
      ]);
      reduxDispatch(setSuccessMessage("Copied"));
    }
  };

  return <RemixButton text="Copy" icon={CopyIcon} onClick={handleCopyClick} />;
};

export default CopyInputText;
