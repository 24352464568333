import { useState, useEffect } from "react";
import useGetCurrentUser from "../../../hooks/useGetCurrentUser";

const useGreeting = (): string => {
  const [greeting, setGreeting] = useState<string>("");
  const currentUser = useGetCurrentUser();
  const userName = currentUser?.firstName || "";
  useEffect(() => {
    const getCurrentGreeting = (): string => {
      const currentHour = new Date().getHours();
      if (currentHour < 12) {
        return `Good morning ${userName} ☀️`;
      } else if (currentHour < 17) {
        return `Good afternoon ${userName} ☀️`;
      } else if (currentHour < 21) {
        return `Good evening ${userName} 🌙`;
      } else {
        return `Good night ${userName} 🌙`;
      }
    };

    setGreeting(getCurrentGreeting());

    const intervalId = setInterval(() => {
      setGreeting(getCurrentGreeting());
    }, 60000); // Check every minute

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return `${greeting} `;
};

export default useGreeting;
