import React from "react";
import type {
  MarketplaceEntityAutocomplete,
  TMarketplaceEntityOptionTypeWithNull
} from "@sharedTypes";
import { Autocomplete, FormControl } from "@mui/material";
import ToolSelectorInput from "./ToolSelectorInput";
import OptionSplitter from "./OptionSplitter";
import getFilterToolOptions from "./hooks/getFilterToolOptions";
import { RiCloseLine } from "@remixicon/react";
import EntityNotFoundBase from "./EntityNotFoundBase";
import FolderCircle from "../IconLogos/FolderCircle";
import useMarketplaceSearch from "./hooks/useMarketplaceSearch";
import useGetFilteredOptionsForAutocomplete from "./hooks/useGetFilteredOptionsForAutocomplete";

// see index.css for some MuiAutocomplete styling
function MarketplaceToolSelector({
  handleNavigateMarketplaceEntity
}: Readonly<{
  handleNavigateMarketplaceEntity: (
    entity: TMarketplaceEntityOptionTypeWithNull
  ) => void;
}>) {
  const filteredOptions = useGetFilteredOptionsForAutocomplete();

  const {
    open,
    handleClearSearch,
    userSearchValue,
    setOpen,
    userSelectedValue,
    handleChangeUserSelectedValue,
    setUserSearchValue
  } = useMarketplaceSearch({
    handleNavigateMarketplaceEntity
  });

  return (
    <FormControl margin={"none"} fullWidth>
      <Autocomplete
        autoHighlight
        autoComplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        inputValue={userSearchValue}
        className={`max-w-530px`}
        size="small"
        value={userSelectedValue}
        id="searchMarketplaceEntities-id"
        options={filteredOptions}
        groupBy={(option) => option.group}
        filterOptions={(options, params) => {
          return getFilterToolOptions(options, params);
        }}
        onChange={handleChangeUserSelectedValue}
        onInputChange={(_, value) => {
          setUserSearchValue(value);
        }}
        getOptionLabel={(option: MarketplaceEntityAutocomplete) =>
          option.primary
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { inputValue }) => (
          <OptionSplitter
            props={props}
            option={option}
            key={option.id}
            inputValue={inputValue}
          />
        )}
        noOptionsText={
          <EntityNotFoundBase
            className="flex align-i-center justify-center flex-column h-450px m-h-auto"
            icon={<FolderCircle />}
            header="Unfortunately, there are no results"
            subHeader="Please try another phrase"
          />
        }
        componentsProps={{
          clearIndicator: <RiCloseLine fontSize={"small"} />
        }}
        fullWidth
        renderInput={(params) => {
          return (
            <ToolSelectorInput
              params={{
                ...params
              }}
              placeholder="Search for tools or use cases"
              onClear={handleClearSearch}
            />
          );
        }}
      />
    </FormControl>
  );
}

export default MarketplaceToolSelector;
