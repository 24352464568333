// THESE ARE DUPLICATED ON THE BACKEND
// we need to escape reg expression characters because

import type {
  TContractor,
  TEmployee,
  TUser,
  UserInputDictType
} from "@sharedTypes";

// otherwise parenthesis etc cant be used in inputs
export const escapeRegExp = (str: string) => {
  return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

export const EMPLOYEE_FE: TEmployee = "employee";
export const CONTRACTOR_FE: TContractor = "contractor";

export function isToolflowHireFE(user?: TUser | null) {
  return (
    user?.toolflowEmployeeType === EMPLOYEE_FE ||
    user?.toolflowEmployeeType === CONTRACTOR_FE
  );
}

export const getPromptWithUserInput = (
  p: string,
  userInput: UserInputDictType
) =>
  Object.entries(userInput || {}).reduce((acc, [key, value]) => {
    const escapedKey = escapeRegExp(key);
    const regex = new RegExp(`{{${escapedKey}}}`, "g");
    // if (typeof value === "boolean") {
    //   return acc.replace(regex, value.toString());
    // }
    if (typeof value === "string") {
      return acc.replace(regex, value);
    }
    // if (isFileData(value)) {
    //   return acc.replace(regex, value.fileName);
    // }
    // if (Array.isArray(value) && value.length > 0) {
    //   // Assuming you would want to handle DallEOutputType in a certain way
    //   // Update logic as per your requirement
    //   return acc.replace(regex, JSON.stringify(value));
    // }
    return acc;
  }, p);
