import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";
import { TOnDrop } from "../../../utilities/draggables/draggableConstants";

const useOutputDrop = () => {
  const { state, dispatch } = useToolbuilderContext();
  const { toolOutputFields } = state.currentState;

  const handleDragEnd: TOnDrop = ({ source, location }) => {
    const target = location.current.dropTargets[0];
    if (!target) {
      return;
    }
    const updatedToolOutputFields = [...toolOutputFields];
    const sourceData = source.data;
    const startIndex = sourceData.index as number;
    const targetData = target.data;
    const indexOfTarget = targetData.index as number;
    const closestEdgeOfTarget = extractClosestEdge(targetData);
    const finishIndex = getReorderDestinationIndex({
      startIndex,
      closestEdgeOfTarget,
      indexOfTarget,
      axis: "vertical"
    });
    const [removed] = updatedToolOutputFields.splice(startIndex, 1);
    updatedToolOutputFields.splice(finishIndex, 0, removed);
    dispatch({
      type: "SET_TOOL_OUTPUT_FIELDS",
      toolOutputFields: updatedToolOutputFields
    });
  };

  return handleDragEnd;
};

export default useOutputDrop;
