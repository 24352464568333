import type { TWorkspaceDataToSave } from "@sharedTypes";
import { useFormContext } from "react-hook-form";
import { setDisableTabChange } from "../../features/workstation/workspaceSlice";
import { useDispatch } from "react-redux";
import {
  TOOL_TAB_COMPONENT_ID_FIELD_LABEL,
  TOOL_TAB_TOOL_ID_FIELD_LABEL
} from "../../features/workstation/workspaceConstants";

const useHandleCloseEditorToolCardWorkspace = () => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setDisableTabChange(false));
    setValue(TOOL_TAB_TOOL_ID_FIELD_LABEL, "", {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    });
    setValue(TOOL_TAB_COMPONENT_ID_FIELD_LABEL, "", {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    });
  };

  return handleClose;
};

export default useHandleCloseEditorToolCardWorkspace;
