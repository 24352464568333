import React from "react";
import { useRichTextEditorContext } from "mui-tiptap";
import { IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { getLocationTrackProperties } from "./functions/getLocationTrackProperties";
import { getHtmlFromText } from "./functions/htmlHelpers";
import { useTrackEventMutation } from "../ToolflowAPI/rtkRoutes/trackEventApi";

function AddTextToWorkspace({
  text,
  tvrId = ""
}: Readonly<{
  text: string;
  tvrId?: string;
}>) {
  const editor = useRichTextEditorContext();
  const location = useLocation();
  const [trackEvent] = useTrackEventMutation();

  const handleAddToWorkspace = () => {
    if (editor) {
      const { to } = editor.state.selection;
      const html = getHtmlFromText(text);
      editor.commands.insertContentAt(to, html, {
        parseOptions: {
          preserveWhitespace: true
        }
      });
      trackEvent({
        eventString: "Used output",
        properties: {
          ...getLocationTrackProperties(location.pathname),
          output_type: "text",
          action_outcome_type: "add_tool_output_to_workspace"
        },
        toolVersionResponseId: tvrId
      });
    }
  };
  return (
    <IconButton onClick={handleAddToWorkspace}>
      <Add />
    </IconButton>
  );
}

export default AddTextToWorkspace;
