import React from "react";
import { useGetSecretsQuery } from "../../../../ToolflowAPI/rtkRoutes/secretApi";
import getBlockIcon from "../../../marketplace/utils/blockLabelAndIconsDict";
import type { TSecretType } from "@sharedTypes";

export type TLLMModelRow = {
  id: string;
  apiKey: string;
  name: string;
  hasValue: boolean;
  type: TSecretType;
  logo: React.ReactNode;
};
const useGetLLMTableData = () => {
  const { data = { openAI: [], deepgram: [], anthropic: [] } } =
    useGetSecretsQuery();
  const openAIRows: TLLMModelRow[] = data?.openAI?.map((secret) => ({
    id: secret._id,
    apiKey: "sk-...".concat(secret.keyRef),
    name: "OpenAI",
    hasValue: true,
    type: "openAI",
    logo: getBlockIcon("OpenAI")
  })) || [
    {
      id: "openAI",
      apiKey: "Not added",
      name: "OpenAI",
      hasValue: false,
      type: "openAI",
      logo: getBlockIcon("OpenAI")
    }
  ];

  const deepgramRows: TLLMModelRow[] = data?.deepgram?.map((secret) => ({
    id: secret._id,
    apiKey: "...".concat(secret.keyRef),
    name: "Deepgram",
    hasValue: true,
    type: "deepgram",
    logo: getBlockIcon("Deepgram")
  })) || [
    {
      id: "deepgram",
      apiKey: "Not added",
      name: "Deepgram",
      hasValue: false,
      type: "deepgram",
      logo: getBlockIcon("Deepgram")
    }
  ];

  // const anthropicRows: TLLMModelRow[] = data?.anthropic?.map((secret) => ({
  //   id: secret._id,
  //   apiKey: "...".concat(secret.keyRef),
  //   name: "Anthropic",
  //   hasValue: false,
  //   type: "anthropic",
  //   logo: getBlockIcon("Anthropic")
  // })) || [
  //   {
  //     id: "anthropic",
  //     apiKey: "Not added",
  //     name: "Anthropic",
  //     hasValue: false,
  //     type: "anthropic",
  //     logo: getBlockIcon("Anthropic")
  //   }
  // ];
  const tableRows = [...openAIRows, ...deepgramRows];
  return {
    tableRows
  };
};

export default useGetLLMTableData;
