import type { DallE3Model, DallE2Model } from "@sharedTypes";
import { blockDictConstants } from "../../blocks/types/blockDictTypes";
import { anthropicInitialOptimizations } from "./anthropicInitialState";

export const DALL_E_2_MODEL: DallE2Model = "dall-e-2";
export const DALL_E_3_MODEL: DallE3Model = "dall-e-3";

export const promptInitialState = {
  prompt: "",
  optimizations: {
    optimizeTokens: false,
    useChainOfThought: false,
    llmRole: "No Role",
    stripPII: false,
    responseLength: "normal",
    responseLengthType: "sentence",
    responseLengthCount: 3,
    outputType: "Text",
    llmModel: "gpt-3.5-turbo",
    temperature: 50
  },
  anthropicOptimizations: anthropicInitialOptimizations
};

export const dallEInitialOptimizations = {
  size: "512x512",
  model: DALL_E_2_MODEL,
  n: 1
};
export const dallE2InitialOptimizations = {
  size: "512x512",
  model: DALL_E_2_MODEL,
  n: 1
};

export const dallE3InitialOptimizations = {
  size: "1024x1024",
  model: DALL_E_3_MODEL,
  n: 1,
  style: "vivid",
  quality: "standard"
};

export const dallEPromptInitialState = {
  prompt: "",
  optimizations: dallEInitialOptimizations
};

export const dallE3PromptInitialState = {
  prompt: "",
  optimizations: dallE3InitialOptimizations
};

export const chatGPTInitialState = {
  type: blockDictConstants.ChatGPT.type,
  label: blockDictConstants.ChatGPT.label
};

export const firstBlockInitialState = {
  ...chatGPTInitialState
};

export const dallEInitialState = {
  type: blockDictConstants.DallE.type,
  label: blockDictConstants.DallE.label
};
