import {
  EXTERNAL_TOOL_ROUTE_PATH,
  EXTERNAL_TOOL_VERSION_RESPONSE_ROUTE_PATH,
  EXTERNAL_WORKFLOW_ROUTE_PATH,
  EXTERNAL_WORKSPACE_ROUTE_PATH,
  KNOWLEDGE_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  MARKETPLACE_ROUTE_PATH,
  SELECTBUILDER_ROUTE_PATH,
  SETTINGS_ROUTE_PATH,
  PROFILE_ROUTE_PATH,
  SIGNUP_ROUTE_PATH,
  SNIPPET_ROUTE_PATH,
  TOOLBUILDER_ROUTE_PATH,
  WORKFLOWBUILDER_ROUTE_PATH,
  WORKSPACE_ROUTE_PATH,
  HOME_ROUTE_PATH_DEV
} from "./routePaths";
import routeTitleDict from "./routeTitleDict";
import routeElementDict from "./routeElementDict";

type TSnippetRoutePath = typeof SNIPPET_ROUTE_PATH;
type TKnowledgeRoutePath = typeof KNOWLEDGE_ROUTE_PATH;
type TToolBuilderRoutePath = typeof TOOLBUILDER_ROUTE_PATH;
type THomeDevRoutePath = typeof HOME_ROUTE_PATH_DEV;
type TSelectBuilderRoutePath = typeof SELECTBUILDER_ROUTE_PATH;
type TWorkflowBuilderRoutePath = typeof WORKFLOWBUILDER_ROUTE_PATH;
type TLoginRoutePath = typeof LOGIN_ROUTE_PATH;
type TSignupRoutePath = typeof SIGNUP_ROUTE_PATH;
type TSettingsRoutePath = typeof SETTINGS_ROUTE_PATH;
type TProfileRoutePath = typeof PROFILE_ROUTE_PATH;
type TMarketplaceRoutePath = typeof MARKETPLACE_ROUTE_PATH;
type TWorkspaceRoutePath = typeof WORKSPACE_ROUTE_PATH;
type TExternalToolRoutePath = typeof EXTERNAL_TOOL_ROUTE_PATH;
type TExternalWorkflowRoutePath = typeof EXTERNAL_WORKFLOW_ROUTE_PATH;
type TExternalWorkspaceRoutePath = typeof EXTERNAL_WORKSPACE_ROUTE_PATH;
type TExternalToolVersionResponseRoutePath =
  typeof EXTERNAL_TOOL_VERSION_RESPONSE_ROUTE_PATH;

type TRoutePath =
  | TSnippetRoutePath
  | TExternalWorkflowRoutePath
  | TToolBuilderRoutePath
  | TSelectBuilderRoutePath
  | TWorkflowBuilderRoutePath
  | TLoginRoutePath
  | TSignupRoutePath
  | TSettingsRoutePath
  | TMarketplaceRoutePath
  | TWorkspaceRoutePath
  | TExternalToolRoutePath
  | TExternalWorkspaceRoutePath
  | TKnowledgeRoutePath
  | TExternalToolVersionResponseRoutePath
  | THomeDevRoutePath
  | TProfileRoutePath;

const orderedRoutesArray: TRoutePath[] = [
  TOOLBUILDER_ROUTE_PATH,
  SELECTBUILDER_ROUTE_PATH,
  WORKFLOWBUILDER_ROUTE_PATH,
  SNIPPET_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  SIGNUP_ROUTE_PATH,
  SETTINGS_ROUTE_PATH,
  MARKETPLACE_ROUTE_PATH,
  HOME_ROUTE_PATH_DEV,
  WORKSPACE_ROUTE_PATH,
  KNOWLEDGE_ROUTE_PATH,
  EXTERNAL_TOOL_ROUTE_PATH,
  EXTERNAL_WORKSPACE_ROUTE_PATH,
  EXTERNAL_WORKFLOW_ROUTE_PATH,
  EXTERNAL_TOOL_VERSION_RESPONSE_ROUTE_PATH,
  PROFILE_ROUTE_PATH
];

const populatedRouteArray = orderedRoutesArray.map((path) => {
  return { path, element: routeElementDict[path], title: routeTitleDict[path] };
});

export default populatedRouteArray;
