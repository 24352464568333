import React from "react";

import { v4 } from "uuid";
import type { TUseCase } from "@sharedTypes";
import AssignUseCases from "./AssignUseCases";
import {
  useGetWorkflowQuery,
  useUpdateWorkflowMutation
} from "../../ToolflowAPI/rtkRoutes/workflowApi";

const AssignUseCasesWorkflowContainer = ({
  workflowId,
  className = ""
}: {
  workflowId: string;
  className?: string;
}) => {
  const { data, isLoading } = useGetWorkflowQuery(workflowId, {
    refetchOnMountOrArgChange: true
  });
  const [updateWorkflow] = useUpdateWorkflowMutation();
  const fetchedUseCasesTags =
    data?.workflow?.tag?.useCases?.map((category) => ({
      id: v4(),
      selected: true,
      value: category
    })) || [];

  const handleSaveWorkflow = (useCases: TUseCase[]) => {
    updateWorkflow({
      workflowId,
      body: {
        tag: { useCases, categories: data?.workflow?.tag?.categories || [] }
      }
    });
  };

  return (
    <AssignUseCases
      disabled={isLoading}
      handleSave={handleSaveWorkflow}
      fetchedUseCasesTags={fetchedUseCasesTags}
      type="workflow"
      className={className}
    />
  );
};

export default AssignUseCasesWorkflowContainer;
