import { createTheme } from "@mui/material";
import utilityTheme from "../../../globalTheme/muiUtils/utilityTheme";

const knowledgeTheme = createTheme(utilityTheme, {
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          background: "white"
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          alignSelf: "center",
          marginRight: 0
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px"
        }
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            padding: "4px 10px"
          }
        },
        {
          props: { size: "medium" },
          style: {
            padding: "6px 16px",
            lineHeight: "24.5px",
            display: "flex",
            width: 111
          }
        }
      ]
    }
  }
});

export default knowledgeTheme;
