import React from "react";
import AuthenticationSplitter from "../../utilities/AuthenticationSplitter";
// import HomePageLoading from "./HomePageLoading";
import { Navigate } from "react-router-dom";
import KnowledgePageInner from "./KnowledgePageInner";
import { ThemeProvider } from "@emotion/react";
import knowledgeTheme from "./theme/knowledgeTheme";

const KnowledgePage = () => {
  return (
    <ThemeProvider theme={knowledgeTheme}>
      <AuthenticationSplitter
        loadingComponent={null}
        authenticatedComponent={<KnowledgePageInner />}
        externalComponent={<Navigate to="/" replace />}
      />
    </ThemeProvider>
  );
};

export default KnowledgePage;
