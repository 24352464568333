import React from "react";
import { useWorkflowContext } from "../../../../utilities/contexts/WorkflowContext";
import RunWorkflowStep from "./RunWorkflowStep";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import RunWorkflowFinish from "./RunWorkflowFinish";
import useGetWorkflowActiveStep from "./hooks/useGetWorkflowActiveStep";

function RunWorkflowCardInner() {
  const { workflowVersion } = useWorkflowContext();
  const activeStep = useGetWorkflowActiveStep();

  return (
    <Box className="p-h-16px">
      <Stepper activeStep={activeStep} orientation="vertical">
        {workflowVersion.steps.map((step, index) => (
          <RunWorkflowStep
            step={step}
            index={index}
            lastStep={index === workflowVersion.steps.length - 1}
            key={index}
          />
        ))}
      </Stepper>
      {activeStep === workflowVersion.steps.length && <RunWorkflowFinish />}
    </Box>
  );
}

export default RunWorkflowCardInner;
