import type { VisibilityTypes } from "@sharedTypes";
import React, { FC } from "react";
import SettingsDropdownMenuButton from "../../../utilities/dropdowns/generic/SettingsDropdownMenuButton";
import UpdateWorkspaceVisibilityMenuItem from "../../../utilities/dropdowns/menuItems/UpdateWorkspaceVisibilityMenuItem";
import DeleteMenuItem from "../../../utilities/dropdowns/menuItems/DeleteMenuItem";
import { RiMore2Fill } from "@remixicon/react";
import { Divider } from "@mui/material";
import RenameWorkspaceMenuItem from "../../../utilities/dropdowns/menuItems/RenameWorkspaceMenuItem";
import { toolflowTextDisabledColor } from "../../../globalTheme/muiUtils/appTheme";

interface IWorkspaceDropdownMenuProps {
  workspaceId: string;
  workspaceVisibility: VisibilityTypes;
  workspaceName: string;
}
const WorkspaceDropdownMenu: FC<IWorkspaceDropdownMenuProps> = ({
  workspaceId,
  workspaceVisibility,
  workspaceName
}) => {
  const menuItems = [
    <RenameWorkspaceMenuItem
      key={"rename workspace"}
      workspaceId={workspaceId}
      workspaceName={workspaceName}
    />,
    <UpdateWorkspaceVisibilityMenuItem
      visibility={workspaceVisibility}
      id={workspaceId}
      name={workspaceName}
      key="updateVisibility"
    />,
    <Divider key={"divider"} />,
    <DeleteMenuItem
      id={workspaceId}
      setDisableCard={() => {}}
      type="workspace"
      key="deleteMenuItem"
      name={workspaceName}
    />
  ];
  return (
    <SettingsDropdownMenuButton
      menuItems={menuItems}
      icon={<RiMore2Fill size={16} color={toolflowTextDisabledColor} />}
    />
  );
};

export default WorkspaceDropdownMenu;
