import React from "react";
import {
  Drawer as MuiDrawer,
  styled,
  type Theme,
  type CSSObject,
  CardHeader,
  IconButton,
  CardContent,
  Container
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { LARGE_DRAWER_WIDTH } from "./functions/formatHelpers";
import { GenericRichHtml } from "./GenericRichText";
import { toolBuilderMatcher } from "../navigation/matchers";
import DescriptionEditor from "../toolBuilder/DescriptionEditor";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../stores/store";
import { updateToolInInfoDrawer } from "./toolInfoDrawerSlice";

export function MoreInfoCard({
  description,
  name,
  close,
  children
}: Readonly<{
  description: string;
  name: string;
  close?: () => void;
  children?: React.ReactNode;
}>) {
  const isMatch = toolBuilderMatcher(location.pathname);
  return (
    <Container maxWidth={"md"} disableGutters>
      <CardHeader
        title={name}
        action={
          close && (
            <IconButton onClick={close}>
              <Close />
            </IconButton>
          )
        }
      />
      <CardContent>
        {isMatch ? (
          <DescriptionEditor />
        ) : (
          <GenericRichHtml html={description} className="p-8px" />
        )}
        {children}
      </CardContent>
    </Container>
  );
}

const openedMixin = (theme: Theme, expanded?: boolean): CSSObject => ({
  width: expanded ? "100%" : LARGE_DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

interface DrawerProps {
  open: boolean;
  expanded?: boolean;
}

const Drawer = styled(MuiDrawer)<DrawerProps>(({ theme, open, expanded }) => ({
  width: LARGE_DRAWER_WIDTH,
  color: "red",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme, expanded),
    "& .MuiDrawer-paper": openedMixin(theme, expanded)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

function ToolInfoDrawer() {
  const toolInInfoDrawer = useSelector(
    (state: RootState) => state.toolInfoDrawer.tool
  );
  const dispatch = useDispatch();
  if (!toolInInfoDrawer) return null;
  const { main, name } = toolInInfoDrawer;
  const { description } = main;

  const close = () => {
    dispatch(updateToolInInfoDrawer(null));
  };

  return (
    <Drawer open={!!toolInInfoDrawer} onClose={close} anchor="right">
      <MoreInfoCard description={description} name={name} close={close} />
    </Drawer>
  );
}

export default ToolInfoDrawer;
