import React, { useState } from "react";
import type { SelectChangeEvent } from "@mui/material";
import {
  Select,
  MenuItem,
  TextField,
  Grid,
  Typography,
  IconButton
} from "@mui/material";
import type {
  LogicType,
  LogicItem,
  LogicTypePartial,
  UpdateArrayItem,
  CustomToolInputField
} from "@sharedTypes";
import {
  logicMap,
  getDefaultParameterValue
} from "../../helpers/logicBlockHelpers";
import useToolInputFieldsAndAvailableFields from "../../useToolInputFieldsAndAvailableFields.ts";
import useUpdateField, { useBatchUpdateField } from "../../useUpdateField";
import Delete from "@mui/icons-material/Delete";
import useShouldWrap from "../../useShouldWrap";
import AddInputDialog from "../../AddInputDialog";
import { CREATE_USER_INPUT_LABEL } from "../../../utilities/Inputs/constants";
import { FILE_UPLOAD } from "../../../utilities/Inputs/inputConstants";

interface LogicRowProps {
  item: LogicItem;
  id: string;
  idx: number;
  last: boolean;
  deleteRow: (i: number) => void;
}

const logicSelectDict = {
  is: {
    true: "is",
    false: "is not"
  },
  contains: {
    true: "contains",
    false: "doesn't contain"
  },
  checked: {
    true: "is checked",
    false: "is not checked"
  }
};

export function LogicRow({ item, id, idx, deleteRow, last }: LogicRowProps) {
  const toolInputFields = useToolInputFieldsAndAvailableFields(id);
  const updateField = useUpdateField(id);
  const batchUpdateField = useBatchUpdateField(id);
  const [shouldWrap, wrapperRef] = useShouldWrap();
  const inputToUse = toolInputFields.filter((f) => f.name === item.input)[0];

  const handleInputChange = (
    newInput: string,
    inputVal?: CustomToolInputField
  ) => {
    let input = toolInputFields.filter((t) => t?.name === newInput)[0];
    if (inputVal) {
      input = inputVal;
    }
    const inputLogicType = logicMap[input.type];
    if (inputLogicType) {
      batchUpdateField([
        {
          updateValue: newInput,
          dataProperty: "logicArray",
          nestedArrayIndex: idx,
          nestedArrayProp: "input"
        },
        {
          updateValue: inputLogicType,
          dataProperty: "logicArray",
          nestedArrayIndex: idx,
          nestedArrayProp: "inputLogicType"
        },
        {
          updateValue: getDefaultParameterValue(inputLogicType, input),
          dataProperty: "logicArray",
          nestedArrayIndex: idx,
          nestedArrayProp: "parameterValue"
        }
      ]);
    }
  };
  const handleInput = (newInput: CustomToolInputField) => {
    handleInputChange(newInput.name, newInput);
  };

  const handleLogicValueChange = (newLogicValue: {
    type: LogicType;
    value: boolean;
  }) => {
    // if newLogicValue.type is different, reset ParameterValue
    let updateFields: UpdateArrayItem[] = [
      {
        updateValue: newLogicValue.value,
        dataProperty: "logicArray",
        nestedArrayIndex: idx,
        nestedArrayProp: "logicValue"
      },
      {
        updateValue: newLogicValue.type,
        dataProperty: "logicArray",
        nestedArrayIndex: idx,
        nestedArrayProp: "inputLogicType"
      }
    ];
    if (newLogicValue.type !== item.inputLogicType) {
      const updateValue = getDefaultParameterValue(
        newLogicValue.type,
        inputToUse
      );
      updateFields = updateFields.concat([
        {
          updateValue,
          dataProperty: "logicArray",
          nestedArrayIndex: idx,
          nestedArrayProp: "parameterValue"
        }
      ]);
    }
    batchUpdateField(updateFields);
    // Placeholder for logicValue change handler
  };

  const handleParameterValueChange = (newParameterValue: string) => {
    updateField(newParameterValue, "logicArray", idx, "parameterValue");
  };

  const [openInputModal, setOpenInputModal] = useState(false);

  const openInput = () => {
    setOpenInputModal(true);
  };

  const onChange = (e: SelectChangeEvent<string>) => {
    if (e.target.value !== "") {
      handleInputChange(e.target.value);
    }
  };

  if (
    !(
      toolInputFields.some((field) => field.name === item.input) ||
      item.input === ""
    )
  )
    return null;

  return (
    <div ref={wrapperRef}>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={shouldWrap ? (item.parameterValue === undefined ? 6 : 2) : 7}
            >
              <Select fullWidth value={item.input} onChange={onChange}>
                <MenuItem value="" onClick={openInput}>
                  {CREATE_USER_INPUT_LABEL}
                </MenuItem>
                {toolInputFields.map((option, idKey) => (
                  <MenuItem key={idKey} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              <AddInputDialog
                openModal={openInputModal}
                setOpenModal={setOpenInputModal}
                callback={handleInput}
              />
            </Grid>
            <Grid
              item
              xs={shouldWrap ? (item.parameterValue === undefined ? 6 : 2) : 5}
            >
              <Select
                fullWidth
                value={JSON.stringify({
                  type: item.inputLogicType,
                  value: item.logicValue
                })}
                onChange={(event) =>
                  handleLogicValueChange(JSON.parse(event.target.value))
                }
              >
                <MenuItem
                  value={JSON.stringify({ type: "exists", value: "true" })}
                >
                  exists
                </MenuItem>
                <MenuItem
                  value={JSON.stringify({ type: "exists", value: "false" })}
                >
                  doesn&apos;t exist
                </MenuItem>
                {inputToUse &&
                  inputToUse.type !== FILE_UPLOAD &&
                  inputToUse.type in logicMap && (
                    <MenuItem
                      value={JSON.stringify({
                        type: logicMap[inputToUse.type],
                        value: "true"
                      })}
                    >
                      {
                        logicSelectDict[
                          logicMap[inputToUse.type] as LogicTypePartial
                        ]?.true
                      }
                    </MenuItem>
                  )}
                {inputToUse &&
                  inputToUse.type !== FILE_UPLOAD &&
                  inputToUse.type in logicMap && (
                    <MenuItem
                      value={JSON.stringify({
                        type: logicMap[inputToUse.type],
                        value: "false"
                      })}
                    >
                      {
                        logicSelectDict[
                          logicMap[inputToUse.type] as LogicTypePartial
                        ]?.false
                      }
                    </MenuItem>
                  )}
              </Select>
            </Grid>
            {item.parameterValue !== undefined && (
              <Grid item xs={shouldWrap ? 8 : 12}>
                {item.inputLogicType === "is" &&
                inputToUse &&
                inputToUse?.options &&
                inputToUse.options.length > 0 ? (
                  <Select
                    fullWidth
                    value={item.parameterValue}
                    onChange={(event) =>
                      handleParameterValueChange(event.target.value)
                    }
                  >
                    {inputToUse &&
                      inputToUse.options.map((o, idKey) => (
                        <MenuItem value={o} key={idKey}>
                          {o}
                        </MenuItem>
                      ))}
                  </Select>
                ) : (
                  <TextField
                    fullWidth
                    helperText={
                      item.inputLogicType === "contains"
                        ? "Text is not case sensitive"
                        : undefined
                    }
                    value={item.parameterValue}
                    onChange={(event) =>
                      handleParameterValueChange(event.target.value)
                    }
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={1} className="flex align-i-center justify-flex-end">
          <IconButton onClick={() => deleteRow(idx)}>
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
      {!last && (
        <Typography variant="caption" className="m-v-16px flex justify-center">
          and
        </Typography>
      )}
    </div>
  );
}
