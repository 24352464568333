import type { RunBlockToolData } from "@sharedTypes";
import { authenticatedApi } from "../authenticatedAPI";
import { CREDITS_TAG_TYPE } from "../tagConstants";

const blocksApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    runBlock: builder.mutation({
      query: (toolData: RunBlockToolData) => ({
        url: `block`,
        method: "POST",
        body: toolData,
        timeout: 30000
        // when using axios we had the following options, check if this works, take out if its fine
        // `maxContentLength` defines the max size of the http response content in bytes allowed in node.js
        // maxContentLength: 500000,

        // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
        // maxBodyLength: 500000
      }),
      invalidatesTags: [CREDITS_TAG_TYPE]
    })
  })
});

export const { useRunBlockMutation } = blocksApi;
