import React from "react";
import type { CustomToolOutputField, UserInputDictType } from "@sharedTypes";
import { isLogicGate } from "./typeCheckers";
import { Grid, LinearProgress, Box, Typography } from "@mui/material";
import OutputField from "./OutputField";
import type { LinearProgressProps } from "@mui/material/LinearProgress";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  const num = Math.round(props.value);
  return (
    <Box sx={{ display: "flex", alignItems: "center", pt: 2, pb: 2 }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="buffer" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        {num !== 0 && (
          <Typography
            variant="body2"
            color="text.secondary"
          >{`${num}%`}</Typography>
        )}
      </Box>
    </Box>
  );
}

const WebsiteOutput = ({
  showWithoutResponse,
  toolOutputFields,
  response,
  toolPercentCompleted,
  tvrId,
  noEditor
}: {
  showWithoutResponse?: boolean;
  toolOutputFields: CustomToolOutputField[];
  response: UserInputDictType | null;
  toolPercentCompleted?: number;
  tvrId?: string;
  noEditor?: boolean;
}) => {
  if (!response && !showWithoutResponse) {
    return null;
  }

  return (
    <>
      <div>
        {typeof toolPercentCompleted === "number" &&
          toolPercentCompleted !== 100 &&
          !showWithoutResponse && (
            <LinearProgressWithLabel
              value={toolPercentCompleted}
              valueBuffer={toolPercentCompleted}
            />
          )}
        {typeof toolPercentCompleted === "number" &&
          toolPercentCompleted === 100 &&
          Object.entries(response || {}).filter(
            ([k, v]) =>
              toolOutputFields.some((t) => t.name === k) && isLogicGate(v)
          ).length === toolOutputFields.length && (
            <Typography>Tool completed, no output to display</Typography>
          )}
      </div>
      {toolOutputFields.length > 0 && (
        <Grid container spacing={2}>
          {toolOutputFields.map(
            (toolOutputField: CustomToolOutputField, index: number) => {
              const showLoading = !(
                showWithoutResponse ||
                (response && toolOutputField.name in response)
              );
              if (showLoading) return null;
              return (
                <Grid item xs={12} key={index}>
                  <OutputField
                    toolOutput={response}
                    toolOutputField={toolOutputField}
                    showLoading={showLoading}
                    showWithoutResponse={showWithoutResponse}
                    tvrId={tvrId}
                    noEditor={noEditor}
                  />
                </Grid>
              );
            }
          )}
        </Grid>
      )}
    </>
  );
};

export default WebsiteOutput;
