import type { RowUserInputDictType, TSelectedTool } from "@sharedTypes";
import getDefaultRow from "./getDefaultRow";

const getData = (
  numRows: number,
  selectedTool: TSelectedTool
): RowUserInputDictType[] => {
  return Array.from({ length: numRows }, () => {
    return getDefaultRow(selectedTool);
  });
};

export default getData;
