import { IconButton, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import useToolflowNavigateBack from "../../../utilities/hooks/useToolflowNavigateBack";
import { RiArrowLeftSLine } from "@remixicon/react";

interface IHeadingWithNavigationProps {
  text: string;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  className?: string;
}
const HeadingWithNavigation: FC<IHeadingWithNavigationProps> = ({
  text,
  variant = "h5",
  className
}) => {
  const { navigateBack } = useToolflowNavigateBack();

  const handleChangeNavigation = () => {
    navigateBack();
  };
  return (
    <Stack className={className} direction={"row"} spacing={3}>
      <IconButton onClick={handleChangeNavigation} className="marginBottom5 ">
        <RiArrowLeftSLine size={24} />
      </IconButton>
      <Typography sx={{ fontWeight: 600 }} variant={variant}>
        {text}
      </Typography>
    </Stack>
  );
};

export default HeadingWithNavigation;
