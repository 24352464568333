import React from "react";
import Build from "@mui/icons-material/Build";
import SettingsMenuItem from "../generic/SettingsMenuItem";
import useEditorInsertion from "../../../workspace/hooks/richTextEditorHooks/useEditorInsertion";
import useNavigateToToolById from "../../../hooks/useNavigateToToolById";

function OpenToolToUseMenuItemBase({
  action
}: {
  action: (e: React.MouseEvent) => void;
}) {
  return (
    <SettingsMenuItem
      action={action}
      text="Use tool"
      icon={<Build fontSize="small" />}
    />
  );
}

function OpenToolToUseMenuItemWithEditor({ id }: { id: string }) {
  const insert = useEditorInsertion();

  const handleClick = () => {
    insert(id, "tool", false);
  };

  return <OpenToolToUseMenuItemBase action={handleClick} />;
}

function OpenToolToUseMenuItem({
  id,
  hasEditor
}: {
  id: string;
  hasEditor?: boolean;
}) {
  const handleNavigate = useNavigateToToolById(id);

  if (!id) return null;

  if (!hasEditor) {
    return <OpenToolToUseMenuItemBase action={handleNavigate} />;
  }

  return <OpenToolToUseMenuItemWithEditor id={id} />;
}

export default OpenToolToUseMenuItem;
