import { authenticatedApi } from "../authenticatedAPI";

import { WORKFLOW_VERSION_TAG_TYPE } from "../tagConstants";

export const workflowsApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkflowVersion: builder.query({
      query: ({
        workflowVersionId
      }: {
        workflowVersionId: string;
        workflowId: string;
      }) => ({
        url: `workflowVersion/${workflowVersionId}`,
        method: "GET"
      }),
      providesTags: (result, error, { workflowId }) => {
        return [{ type: WORKFLOW_VERSION_TAG_TYPE, id: workflowId }];
      }
    })
  })
});

export const { useGetWorkflowVersionQuery } = workflowsApi;
