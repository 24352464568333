import React from "react";
import { Icon } from "@mui/material";

function IconWithCircle({
  className = "",
  icon,
  width = 92
}: {
  width?: number;
  className?: string;
  icon: React.ReactNode;
}) {
  return (
    <div
      className={`flex align-i-center justify-center ${className}`}
      style={{
        backgroundColor: "transparent",
        border: "1px solid #EEE9E4",
        borderRadius: "50%",
        padding: 1,
        height: width,
        width: width
      }}
    >
      <Icon className="flex h-100-percent w-100-percent align-i-center justify-center">
        {icon}
      </Icon>
    </div>
  );
}

export default IconWithCircle;
