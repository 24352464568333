import type { TabsActions } from "@mui/material";
import { useRef } from "react";

const useUpdateIndicator = () => {
  const actionRef = useRef<TabsActions>(null);
  const updateIndicator = () => {
    if (actionRef.current) {
      actionRef.current.updateIndicator();
    }
  };
  return { actionRef, updateIndicator };
};

export default useUpdateIndicator;
