import React from "react";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { useDeleteFileMutation } from "../ToolflowAPI/rtkRoutes/fileApi";

function DeleteFileButton({ id }: { id: string }) {
  const [deleteFile, { isLoading }] = useDeleteFileMutation();

  const handleDeleteFile = () => {
    deleteFile(id);
  };
  return (
    <LoadingButton size="small" loading={isLoading} onClick={handleDeleteFile}>
      Delete
    </LoadingButton>
  );
}

export default DeleteFileButton;
