import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BaseSettingsDropdown from "./base/BaseSettingsDropdown";
import SettingsButtonComponent from "./base/SettingsButtonComponent";
import type { SxProps, Theme, TooltipProps } from "@mui/material";

function SettingsDropdown({
  menuItems,
  icon = <MoreVertIcon />,
  IconButtonProps,
  TooltipProps
}: {
  children?: React.ReactNode;
  menuItems: React.ReactNode[];
  icon?: React.ReactNode;
  IconButtonProps?: {
    disabled?: boolean;
    draggable?: boolean;
    "data-drag-handle"?: boolean;
    sx?: SxProps<Theme>;
    className?: string;
  };
  TooltipProps?: Omit<TooltipProps, "children">;
}) {
  return (
    <BaseSettingsDropdown menuItems={menuItems}>
      <SettingsButtonComponent
        icon={icon}
        IconButtonProps={IconButtonProps}
        TooltipProps={TooltipProps}
      />
    </BaseSettingsDropdown>
  );
}

export default SettingsDropdown;
