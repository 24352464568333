import React from "react";
import { useAuth0Store } from "../../../hooks/useAuth0Store";
import { useNodeViewContext } from "../../../utilities/contexts/NodeViewContext";
import { useGetToolQuery } from "../../../ToolflowAPI/rtkRoutes/toolsApi";
import ToolflowSkeletonWrapper from "../../../utilities/ToolflowSkeleton";
import { ToolContextComponent } from "../../../utilities/contexts/ToolContext";
import ExternalToolCardContainer from "../../ExternalToolCardContainer";

function SearchedToolContainer({ index }: { index: number }) {
  const { isLoading } = useAuth0Store();
  const { node } = useNodeViewContext<{ toolIds: string[] }>();

  const { data } = useGetToolQuery(node.attrs.toolIds[index], {
    skip: isLoading || !node.attrs.toolIds[index]
  });

  return (
    <ToolflowSkeletonWrapper loading={!data?.tool} width="100%">
      <ToolContextComponent tool={data?.tool}>
        <ExternalToolCardContainer />
      </ToolContextComponent>
    </ToolflowSkeletonWrapper>
  );
}

export default SearchedToolContainer;
