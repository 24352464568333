import { useGetToolflowConstantsQuery } from "../../../ToolflowAPI/rtkRoutes/toolflowConstantsApi";
import type { HyperlinePlans, TAvailableTiers } from "@sharedTypes";
import {
  CURRENT_SUBSCRIPTION,
  DOWNGRADE,
  hyperlineOrganizationPlanIdDevOrStaging,
  hyperlineOrganizationPlanIdProduction,
  hyperlineProPlanIdDevOrStaging,
  hyperlineProPlanIdProduction,
  ORGANIZATION_UPGRADE,
  PRO_UPGRADE,
  PRODUCTION_ENVIRONMENT,
  TCurrentSubscription,
  TDowngrade,
  TUpgrade,
  TUpgradeToOrganization,
  TUpgradeToPro
} from "../../../settings/BillingCard";

const useSubscriptionDict = () => {
  const { data } = useGetToolflowConstantsQuery();
  const toolflowConstants = data.toolflowConstants;
  const subscriptionDict: {
    [key in TAvailableTiers]: {
      name: string;
      price: string;
      priceAmountString: string;
      priceDurationString: string;
      planId?: HyperlinePlans;
      benefits: string[];
      tagline: string;
      ctas: {
        [ctaKey in TAvailableTiers]:
          | TDowngrade
          | TUpgrade
          | TCurrentSubscription
          | TUpgradeToOrganization
          | TUpgradeToPro;
      };
    };
  } = {
    free: {
      name: "Free",
      price: "$0 / month",
      priceAmountString: "$0",
      priceDurationString: "/ month",
      tagline: "Great for individuals getting started",
      benefits: [
        "1 user",
        "100 monthly credits included",
        "Marketplace templates",
        "No-code tool builder",
        "Full access to the latest LLMs and web scraping",
        "Bulk runs",
        "Slack community support"
      ],
      ctas: {
        free: CURRENT_SUBSCRIPTION,
        pro: PRO_UPGRADE,
        organization: ORGANIZATION_UPGRADE
      }
    },
    pro: {
      name: "Pro",
      price: `$${toolflowConstants.proPrice} / month`,
      priceAmountString: `$${toolflowConstants.proPrice}`,
      priceDurationString: "/ month",
      planId:
        process.env.REACT_APP_ENVIRONMENT === PRODUCTION_ENVIRONMENT
          ? hyperlineProPlanIdProduction
          : hyperlineProPlanIdDevOrStaging,
      tagline: "Everything in free plus more credits for power users",
      benefits: [
        "1 user",
        "1,500 monthly credits included",
        "Email support + slack community"
      ],
      ctas: {
        free: DOWNGRADE,
        pro: CURRENT_SUBSCRIPTION,
        organization: ORGANIZATION_UPGRADE
      }
    },
    organization: {
      name: "Team",
      price: `Custom plan`,
      priceAmountString: "Custom plan",
      priceDurationString: "",
      planId:
        process.env.REACT_APP_ENVIRONMENT === PRODUCTION_ENVIRONMENT
          ? hyperlineOrganizationPlanIdProduction
          : hyperlineOrganizationPlanIdDevOrStaging,
      tagline: "Flexible plans for companies and dedicated support",
      benefits: [
        "unlimited users",
        "unlimited credits",
        "Dedicated account manager",
        "We build custom tools for you"
      ],
      ctas: {
        free: DOWNGRADE,
        pro: DOWNGRADE,
        organization: CURRENT_SUBSCRIPTION
      }
    }
  };
  return subscriptionDict;
};

export default useSubscriptionDict;
