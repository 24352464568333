import React from "react";
import useMonitor from "../../draggables/useMonitor";
import useInputDrop from "./hooks/useInputDrop";

const DragToolInputMonitor = ({ children }: { children: React.ReactNode }) => {
  const onDrop = useInputDrop();
  useMonitor({ onDrop });

  return <>{children}</>;
};

export default DragToolInputMonitor;
