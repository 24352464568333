import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import type { UpdateSnippet } from "@sharedTypes";

import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  SNIPPET_NAME_FIELD_LABEL,
  SNIPPET_VALUE_FIELD_LABEL
} from "./snippetFields";
import useDefaultSnippetFormValues from "./hooks/useDefaultSnippetFormValues";
import useResetDefaultValuesAndRerender from "./hooks/useResetDefaultValuesAndRerender";

export const SNIPPET_NAME_REQUIRED_ERROR = "Snippet name is required";
export const SNIPPET_VALUE_REQUIRED_ERROR = "Snippet value is required";

const schema = z.object({
  [SNIPPET_NAME_FIELD_LABEL]: z
    .string()
    .min(1, { message: SNIPPET_NAME_REQUIRED_ERROR }),
  [SNIPPET_VALUE_FIELD_LABEL]: z
    .string()
    .min(1, { message: SNIPPET_VALUE_REQUIRED_ERROR })
});

const SnippetFormProvider = ({ children }: { children: React.ReactNode }) => {
  const defaultValues = useDefaultSnippetFormValues();

  const methods = useForm<UpdateSnippet>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
    mode: "onChange"
  });

  useResetDefaultValuesAndRerender(defaultValues, methods);

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default SnippetFormProvider;
