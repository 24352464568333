import type { CustomToolInputField } from "@sharedTypes";
import { isInputWithOptions } from "../../../utilities/Inputs/constants";
import { getToolInputFieldIndexById } from "../../../utilities/Inputs/inputBuilders/hooks/useGetToolInputFieldIndexById";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";

const useDeleteOptionToolbuilder = () => {
  const { dispatch, state } = useToolbuilderContext();
  const { toolInputFields } = state.currentState;

  const handleDeleteOption = (
    toolInputField: CustomToolInputField | null,
    optionIndex: number
  ) => {
    if (!isInputWithOptions(toolInputField)) {
      console.log("Input field does not support options.");
      return;
    }
    const index = getToolInputFieldIndexById(
      toolInputFields,
      toolInputField.id
    );
    const options = toolInputField.options || [];
    const newOptions = [
      ...options.slice(0, optionIndex),
      ...options.slice(optionIndex + 1)
    ];
    const parameterValue = options[optionIndex];
    const updatedToolInputField = {
      ...toolInputField,
      options: newOptions
    };
    const updatedToolInputFields = [...toolInputFields];
    updatedToolInputFields[index] = updatedToolInputField;
    dispatch({
      type: "DELETE_LOGIC_OPTION",
      input: updatedToolInputFields[index]?.name || "",
      parameterValue
    });
    dispatch({
      type: "SET_TOOL_INPUT_FIELDS",
      toolInputFields: updatedToolInputFields
    });
  };
  return handleDeleteOption;
};

export default useDeleteOptionToolbuilder;
