import useGetMarketplaceSelectorOptions from "../../marketplaceToolSelector/hooks/useGetMarketplaceSelectorOptions";

const useGetUseCaseOptions = () => {
  const marketplaceSelectorOptions = useGetMarketplaceSelectorOptions();
  const useCasesSelectorOptions = [
    ...new Set(
      marketplaceSelectorOptions
        .map((option) => option?.tag?.useCases)
        .filter(Boolean)
        .flat()
    )
  ].map((useCase) => ({
    name: useCase,
    id: useCase,
    description: ""
  })) as { name: string; id: string; description: string }[];
  return useCasesSelectorOptions;
};

export default useGetUseCaseOptions;
