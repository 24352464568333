import React from "react";
import DraggableToolOutputFieldWrapper from "./DraggableToolOutputFieldWrapper";
import type { CustomToolOutputField } from "@sharedTypes";
import ToolOutputFieldDragHandle from "./ToolOutputFieldDragHandle";
import { Chip, Grid } from "@mui/material";
import DeleteToolOutputFieldButton from "./DeleteToolOutputFieldButton";
import ChangeToolOutputNameSelect from "./ChangeToolOutputNameSelect";
import { useToolbuilderOutputFieldContext } from "./ToolbuilderOutputFieldContext";
import useIsDragging from "../../utilities/draggables/hooks/useIsDragging";
import DraggingWrapper from "../../utilities/draggables/DraggingWrapper";

function DraggableToolOutputFieldInner() {
  const { toolOutputField } = useToolbuilderOutputFieldContext();
  const isDragging = useIsDragging();
  return (
    <DraggingWrapper
      preview={
        <Chip
          label={toolOutputField.name || "Output"}
          variant="outlined"
          size="small"
        />
      }
      gap="16px"
    >
      <Grid container spacing={1} alignItems="center" className="m-v-16px">
        <Grid item xs={1}>
          <ToolOutputFieldDragHandle />
        </Grid>
        <Grid item xs={10}>
          <ChangeToolOutputNameSelect disabled={isDragging} />
        </Grid>
        <Grid item xs={1} className="justify-flex-end flex">
          <DeleteToolOutputFieldButton disabled={isDragging} />
        </Grid>
      </Grid>
    </DraggingWrapper>
  );
}

function DraggableToolOutputField({
  toolOutputField,
  index
}: {
  toolOutputField: CustomToolOutputField;
  index: number;
}) {
  return (
    <DraggableToolOutputFieldWrapper
      index={index}
      toolOutputField={toolOutputField}
    >
      <DraggableToolOutputFieldInner />
    </DraggableToolOutputFieldWrapper>
  );
}

export default DraggableToolOutputField;
