import React, { FC } from "react";
import useToolflowNavigate from "../../hooks/useToolflowNavigate";
import {
  TOOLBUILDER_BASE_ROUTE_PATH,
  WORKFLOWBUILDER_BASE_ROUTE_PATH
} from "../../../navigation/routePaths";
import { RiEditLine } from "@remixicon/react";
import RemixButton from "../../../features/layout/icons/RemixButton";

interface INavigateToBuilderMenuItemProps {
  entityId: string;
  type: "workflow" | "tool";
}
const NavigateToBuilderMenuItem: FC<INavigateToBuilderMenuItemProps> = ({
  entityId,
  type
}) => {
  const navigate = useToolflowNavigate();
  const handleNavigate = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (type === "workflow") {
      navigate(`${WORKFLOWBUILDER_BASE_ROUTE_PATH}${entityId}`, e);
    }
    if (type === "tool") {
      navigate(`${TOOLBUILDER_BASE_ROUTE_PATH}${entityId}`, e);
    }
  };

  return (
    <RemixButton
      text="Edit"
      icon={RiEditLine}
      onClick={handleNavigate}
      disabled={!entityId}
    />
  );
};

export default NavigateToBuilderMenuItem;
