import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import {
  CardHeader,
  Typography,
  CircularProgress,
  Button,
  CardActions,
  IconButton
} from "@mui/material";
import ToolVersionResponseListButton from "./ToolVersionResponseListButton";
import { useParams } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useGetToolHistoryQuery } from "../ToolflowAPI/rtkRoutes/toolVersionResponseApi";

function ToolHistoryCard() {
  const [currentPage, setCurrentPage] = useState(1);
  const { toolId } = useParams();
  const { data, isFetching, refetch } = useGetToolHistoryQuery({
    page: currentPage,
    limit: 10,
    toolId
  });
  const { toolHistory: history, page, hasNextPage } = data;

  return (
    <Box mt={4}>
      <Card className="m-b-32px" variant="outlined">
        <CardHeader
          title="Tool History"
          action={
            <Button
              variant="outlined"
              onClick={() => refetch()}
              disabled={isFetching}
            >
              Refresh
            </Button>
          }
        />
        {isFetching ? (
          <CardContent className="flex align-i-center justify-center">
            <CircularProgress />
          </CardContent>
        ) : (
          <CardContent>
            {history.length > 0 ? (
              history.map((tvr) => (
                <ToolVersionResponseListButton key={tvr._id} tvr={tvr} />
              ))
            ) : (
              <Typography>
                {!!toolId ? "Tool not yet run" : "No tools run yet"}
              </Typography>
            )}
          </CardContent>
        )}
        <CardActions>
          <IconButton
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={isFetching || currentPage === 1}
          >
            <KeyboardArrowLeftIcon fontSize="small" />
          </IconButton>
          <Typography variant="caption" className="m-l-8px">
            Page {page}
          </Typography>
          <IconButton
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={isFetching || !hasNextPage}
          >
            <KeyboardArrowRightIcon fontSize="small" />
          </IconButton>
        </CardActions>
      </Card>
    </Box>
  );
}

export default ToolHistoryCard;
