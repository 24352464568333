import React from "react";
import EntityNotFoundBase from "../../utilities/marketplaceToolSelector/EntityNotFoundBase";
import { Button } from "@mui/material";
import IconWithCircle from "../../utilities/IconLogos/IconWithCircle";
import RemixWrapperIconWithTheme from "../layout/icons/RemixWrapperIconWithTheme";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../stores/store";
import {
  defaultContentValues,
  tabsContentDict
} from "./utils/blockLabelAndIconsDict";
import { setCategory, setUseCases } from "./slice/marketplaceSlice";

const EntityNotFound = () => {
  const reduxCategory = useSelector(
    (state: RootState) => state.marketplace.category
  );
  const dispatch = useDispatch();

  const content = tabsContentDict[reduxCategory] || defaultContentValues;

  const handleClick = () => {
    dispatch(setCategory(content.categoryToNavigateTo));
    dispatch(setUseCases([]));
  };

  return (
    <EntityNotFoundBase
      className="flex align-i-center justify-center flex-column h-450px"
      header={content.header}
      actionButton={
        content.actionButtonText && (
          <Button
            variant="contained"
            className="border-radius-8px text-transform-none"
            onClick={handleClick}
          >
            {content.actionButtonText}
          </Button>
        )
      }
      subHeader={content.subheader}
      icon={
        content.icon && (
          <IconWithCircle
            icon={
              <RemixWrapperIconWithTheme
                Icon={content.icon}
                className={content.className}
                size={40}
              />
            }
          />
        )
      }
    />
  );
};

export default EntityNotFound;
