import { IconButton } from "@mui/material";
import type { ButtonSizeTypes } from "@sharedTypes";
import React, { FC } from "react";
import styles from "./iconButtonWithBorder.module.css";

interface IIconButtonWithBorderProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  size: ButtonSizeTypes;
}
const IconButtonWithBorder: FC<IIconButtonWithBorderProps> = ({
  children,
  onClick,
  className = "",
  size
}) => {
  return (
    <IconButton
      className={`${className} ${styles["icon-button-border"]}`}
      onClick={onClick}
      size={size}
    >
      {children}
    </IconButton>
  );
};

export default IconButtonWithBorder;
