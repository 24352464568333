import React from "react";
import useSetToolTabToToolsOnToolRoute from "./hooks/useSetToolTabToToolsOnToolRoute";

const WorkspaceSetToolsProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  useSetToolTabToToolsOnToolRoute();
  return <>{children}</>;
};

export default WorkspaceSetToolsProvider;
