import { useEffect, useRef } from "react";
import useWorkspaceInputs from "../../../features/workstation/hooks/useWorkspaceInputs";

const useCurrentInputsRef = () => {
  const inputs = useWorkspaceInputs();
  const currentInputs = useRef(inputs);

  useEffect(() => {
    currentInputs.current = inputs;
  }, [inputs]);

  return currentInputs;
};

export default useCurrentInputsRef;
