import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import React, { FC } from "react";
import { ReadyState } from "react-use-websocket";
import { useWebsocketContext } from "../../contexts/useWebsocketContext";
import type {
  DeepgramBlockData,
  PromptBlockData,
  ScraperBlockData,
  SerpBlockData,
  UserInputDictType
} from "@sharedTypes";
// import useBlockCost from "../blocks/useBlockCost";
// import CreditCostDetails from "../../utilities/CreditCostDetails";
import ButtonLinkToBilling from "./ButtonLinkToBilling";

interface ITestToolRunProps {
  loading: boolean;
  abort: () => void;
  handleRunBlock: () => void;
  blockForm: UserInputDictType;
  blockData:
    | DeepgramBlockData
    | PromptBlockData
    | ScraperBlockData
    | SerpBlockData;
  showUpgrade?: boolean;
  disabled?: boolean;
}

const TestToolRun: FC<ITestToolRunProps> = (props) => {
  const { resetButton, readyState } = useWebsocketContext();
  const {
    loading,
    abort,
    handleRunBlock,
    // blockForm,
    // blockData,
    showUpgrade,
    disabled
  } = props;
  // const { costExplanation, cost } = useBlockCost(blockData, blockForm);
  return (
    <div className="flex align-i-center m-l-auto m-t-8px">
      {loading ? (
        <Button variant="outlined" onClick={abort}>
          Stop generating
        </Button>
      ) : (
        <div className="flex align-i-center m-l-auto">
          {showUpgrade && <ButtonLinkToBilling />}
          {/* {costExplanation && (
            <CreditCostDetails
              estimatedCreditCost={cost}
              className="m-l-8px"
              toolTipText={`Test cost: ${costExplanation}`}
            />
          )} */}
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleRunBlock}
            disabled={readyState !== ReadyState.OPEN || disabled}
            className="m-l-8px"
          >
            Test
          </LoadingButton>
        </div>
      )}
      {resetButton}
    </div>
  );
};

export default TestToolRun;
