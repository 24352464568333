import { useState, useCallback } from "react";
import type { UserInputDictType, CustomToolOutputField } from "@sharedTypes";
import { getLocationTrackProperties } from "../functions/getLocationTrackProperties";
import { useLocation } from "react-router-dom";
import useToolflowAPI from "../../ToolflowAPI/useToolflowAPI";
import { useTrackEventMutation } from "../../ToolflowAPI/rtkRoutes/trackEventApi";

type Options = {
  toolOutput: UserInputDictType | null;
  outputFields: CustomToolOutputField[];
  name: string;
  tvrId?: string;
};

function filterObjectByNames(
  obj: UserInputDictType,
  names: CustomToolOutputField[]
): UserInputDictType {
  const nameArray = names.map((nameObject) => nameObject.name); // convert NameObject[] to string[]
  const filteredObj: UserInputDictType = {};
  for (const key in obj) {
    if (nameArray.includes(key)) {
      filteredObj[key] = obj[key];
    }
  }
  return filteredObj;
}

const useDownloadZip = ({ toolOutput, outputFields, name, tvrId }: Options) => {
  const [loading, setLoading] = useState(false);
  const { generateZip } = useToolflowAPI();
  const [trackEvent] = useTrackEventMutation();
  const location = useLocation();

  const downloadZip = useCallback(async () => {
    setLoading(true);

    const parseResponse = (blob: Blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.zip`);
      document.body.appendChild(link);

      link.click();
      setLoading(false);
      trackEvent({
        eventString: "Used output",
        properties: {
          ...getLocationTrackProperties(location.pathname),
          action_outcome_type: "generate_zip"
        },
        toolVersionResponseId: tvrId || ""
      });
    };

    if (toolOutput) {
      generateZip(
        filterObjectByNames(toolOutput, outputFields),
        () => setLoading(false),
        parseResponse
      );
    }
  }, [generateZip, toolOutput, outputFields, name]);

  return { downloadZip, loading };
};

export default useDownloadZip;
