import React from "react";
import type { MarketplaceEntityAutocomplete } from "@sharedTypes";
import ToolSelectorOption from "./ToolSelectorOption";

export interface IToolSelectorOption {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: MarketplaceEntityAutocomplete;
  inputValue: string;
}

function OptionSplitter({ props, option, inputValue }: IToolSelectorOption) {
  return (
    <ToolSelectorOption props={props} option={option} inputValue={inputValue} />
  );
}

export default OptionSplitter;
