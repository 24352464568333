import React, { useMemo } from "react";
import {
  Box,
  Button,
  Step,
  StepContent,
  StepLabel,
  Typography
} from "@mui/material";
import type { EmbeddedStep } from "@sharedTypes";
import RunWorkflowStepContent from "./RunWorkflowStepContent";
import useGetWorkflowActiveStep from "./hooks/useGetWorkflowActiveStep";
import useSetActiveStep from "./hooks/useSetActiveStep";
import HandlePreviousButton from "./steps/HandlePreviousButton";
import { WorkflowStepContextComponent } from "../../../../utilities/contexts/WorkflowStepContext";

function RunWorkflowStep({
  step,
  index,
  lastStep
}: {
  step: EmbeddedStep;
  index: number;
  lastStep?: boolean;
}) {
  const setActiveStep = useSetActiveStep();
  const activeStep = useGetWorkflowActiveStep();

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const isActiveStep = useMemo(() => index === activeStep, [index, activeStep]);

  return (
    <Step key={step.id} index={index} active={isActiveStep}>
      <WorkflowStepContextComponent step={step} index={index}>
        <StepLabel>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: isActiveStep ? 600 : undefined }}
          >
            {step.name}
          </Typography>
        </StepLabel>
        <StepContent>
          <Typography variant="body2">{step.description}</Typography>
          <RunWorkflowStepContent />
          {step.type !== "runTool" && (
            <Box sx={{ mb: 2 }} className="flex justify-space-between">
              <HandlePreviousButton sx={{ mt: 1, mr: 1 }} />
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{ mt: 1, mr: 1 }}
                className="m-l-auto"
              >
                {lastStep ? "Finish" : "Continue"}
              </Button>
            </Box>
          )}
        </StepContent>
      </WorkflowStepContextComponent>
    </Step>
  );
}

export default RunWorkflowStep;
