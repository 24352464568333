import type {
  TSerpBlockAllowedOptionsValues,
  TSerpResultOptions
} from "@sharedTypes";
export const serpAllowedOutputLabelsAndValues: Record<
  TSerpBlockAllowedOptionsValues,
  { label: string; checked: boolean }
> = {
  organicResults: { checked: true, label: "Organic results" },
  paidResults: { checked: true, label: "Paid results" },
  peopleAlsoAsk: { checked: true, label: "People also ask" },
  relatedQueries: { checked: true, label: "Related queries" },
  resultOptions: { checked: true, label: "Result options" }
};

export const resultOptionsLabelsAndValues: Record<
  keyof TSerpResultOptions,
  { label: string; checked: boolean }
> = {
  description: { checked: true, label: "Description" },
  displayedUrl: { checked: true, label: "Displayed URL" },
  emphasizedKeywords: { checked: true, label: "Emphasized keywords" },
  siteLinks: { checked: true, label: "Site links" },
  title: { checked: true, label: "Title" },
  url: { checked: true, label: "URL" }
};
