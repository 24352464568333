import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import SnippetViewerComponent from "./SnippetViewerComponent";
import { getPlainTextFromHtml } from "../../utilities/functions/htmlHelpers";

export default Node.create({
  name: "snippetChipComponent",

  // group: "block",
  group: "inline",
  content: "inline*",
  inline: true,
  draggable: true,
  selectable: true, // needed so that we don't delete it when typing things if you click the tool

  atom: true,

  addAttributes() {
    return {
      snippetId: {
        default: 0
      },
      textValue: {
        default: ""
      },
      name: {
        default: ""
      }
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "tip-tap-snippet-chip-component",
      mergeAttributes(HTMLAttributes, { "data-type": "draggable-item" })
    ];
  },
  renderText({ node }) {
    return getPlainTextFromHtml(node.attrs.textValue || "") || "";
  },

  addNodeView() {
    return ReactNodeViewRenderer(SnippetViewerComponent);
  }
});
