import { useEffect } from "react";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";

const useOpenOrCloseToolMenu = ({
  slashHasRenderedRef,
  setSlashHasRendered
}: {
  slashHasRenderedRef: React.MutableRefObject<boolean>;
  setSlashHasRendered: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { setShouldRenderBubble } = useMuiTipTapContext();
  const registerGlobalKeyListener = (handler: (e: KeyboardEvent) => void) => {
    document.addEventListener("keydown", handler);
  };

  const deregisterGlobalKeyListener = (handler: (e: KeyboardEvent) => void) => {
    document.removeEventListener("keydown", handler);
  };

  const onKeyDown = (e: KeyboardEvent) => {
    const charAtCursor = e.key;
    if (charAtCursor === "Escape") {
      setShouldRenderBubble(false);
    } else if (charAtCursor === "/" && !slashHasRenderedRef.current) {
      setSlashHasRendered(true);
      e.preventDefault();
      return true;
    }
  };
  useEffect(() => {
    registerGlobalKeyListener(onKeyDown);

    return () => {
      deregisterGlobalKeyListener(onKeyDown);
    };
  }, []);
};

export default useOpenOrCloseToolMenu;
