import React from "react";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SettingsMenuItemWithClose from "../../../utilities/dropdowns/generic/SettingsMenuItemWithClose";
import useDuplicateObjectToolbuilderToolbuilder from "../../../utilities/hooks/useDuplicateObjectToolbuilder";

// Note - only use this for personal tools
// because we are passing the isPersonal flag as true
// to useDuplicateObject so that you can copy private
// tools
function DuplicateToolMenuItemToolbuilder({
  id,
  useLastSaveText
}: {
  id: string;
  useLastSaveText?: boolean;
}) {
  const { loading, duplicate } = useDuplicateObjectToolbuilderToolbuilder(id);

  return (
    <SettingsMenuItemWithClose
      disabled={loading}
      hide={!id}
      action={duplicate}
      hotkey="Meta+D"
      text={`Duplicate tool${useLastSaveText ? " last save" : ""}`}
      icon={<FileCopyIcon fontSize="small" />}
    />
  );
}

export default DuplicateToolMenuItemToolbuilder;
