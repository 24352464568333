import type { TWorkspaceDataToSave } from "@sharedTypes";
import { DEFAULT_WORKSPACE } from "../workspaceConstants";

const getDefaultValues = (workspace: TWorkspaceDataToSave) => {
  // there may be more fields on the workspace that we dont want to store in the form (like creator)
  const workspaceProperties = Object.fromEntries(
    Object.entries(workspace).filter(([k]) => k in DEFAULT_WORKSPACE)
  );
  return {
    ...DEFAULT_WORKSPACE,
    ...workspaceProperties
  };
};

export default getDefaultValues;
