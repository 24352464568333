import React from "react";
import useGetCurrentUser from "../../../hooks/useGetCurrentUser";
import { selectLimitedProfileWorkspaces } from "../../../ToolflowAPI/rtkRoutes/selectors/workspaceSelectors";
import { useSelector } from "react-redux";
import { useGetProfileWorkspacesQuery } from "../../../ToolflowAPI/rtkRoutes/workspaceApi";
import WorkspaceTable from "./WorkspaceTable";
import TableSkeleton from "../layout/TableSkeleton";
import NoRecentData from "../NoRecentData";

const WorkspaceTableContainer = () => {
  const user = useGetCurrentUser();
  const profileId = user?._id || "";
  const workspaces = useSelector(selectLimitedProfileWorkspaces(profileId, 5));
  const { isFetching, isLoading } = useGetProfileWorkspacesQuery(profileId, {
    skip: !profileId
  });
  if (isFetching || isLoading) {
    return <TableSkeleton numOfRows={5} className="p-16px" />;
  }

  const isWorkspaceDataEmpty =
    workspaces?.length === 0 && !(isFetching || isLoading);

  if (isWorkspaceDataEmpty) {
    return (
      <NoRecentData
        header="Your recent work sessions will be stored here"
        subHeader="Ready to start? Easily find your first tool in our marketplace and begin exploring the possibilities."
      />
    );
  }
  return <WorkspaceTable workspaces={workspaces} />;
};

export default WorkspaceTableContainer;
