import React from "react";
import useOutputDrop from "./hooks/useOutputDrop";
import useMonitor from "../../utilities/draggables/useMonitor";

const DragToolOutputMonitor = ({ children }: { children: React.ReactNode }) => {
  const onDrop = useOutputDrop();
  useMonitor({ onDrop });
  return <>{children}</>;
};

export default DragToolOutputMonitor;
