import {
  externalToolMatcher,
  tvrMatcher,
  toolBuilderMatcher,
  workspaceMatcher
} from "../../navigation/matchers";
import type { LocationTrackProperties } from "@sharedTypes";

export const getLocationTrackProperties = (
  pathname: string
): LocationTrackProperties => {
  const isMatch = externalToolMatcher(pathname);
  const isToolMatch = toolBuilderMatcher(pathname);
  const isTVRMatch = tvrMatcher(pathname);
  const isWorkspaceMatch = workspaceMatcher(pathname);

  let page = pathname;
  if (isMatch) {
    page = "use tool page";
  } else if (isToolMatch) {
    page = "tool builder page";
  } else if (isTVRMatch) {
    page = "tool version response page";
  } else if (isWorkspaceMatch) {
    page = "workspace";
  }

  return {
    page_name: page,
    pathname
  };
};
