import type { TWorkspaceDataToSave } from "@sharedTypes";
import { WORKSPACE_WORKFLOW_ACTIVE_STEP_FIELD_LABEL } from "../../../workspaceConstants";
import { useFormContext } from "react-hook-form";

const useGetWorkflowActiveStep = () => {
  const { watch } = useFormContext<TWorkspaceDataToSave>();
  return watch(WORKSPACE_WORKFLOW_ACTIVE_STEP_FIELD_LABEL);
};

export default useGetWorkflowActiveStep;
