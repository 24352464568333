import type {
  CustomToolInputFieldTypes,
  CustomToolOutputFieldTypes
} from "@sharedTypes";
import { LARGE_TEXTFIELD, TEXTFIELD } from "./Inputs/inputConstants";
import { COPYABLE_LARGE_TEXTFIELD } from "./Inputs/outputConstants";

export const textFieldTypeArray: Array<
  CustomToolInputFieldTypes | CustomToolOutputFieldTypes
> = [LARGE_TEXTFIELD, TEXTFIELD, COPYABLE_LARGE_TEXTFIELD];
