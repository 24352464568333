import React from "react";

import VisitSiteButton from "../utilities/VisitSiteButton";
import CurrentToolVersion from "./CurrentToolVersion";
import NoWrapCardHeader from "../utilities/NoWrapCardHeader";
import SaveVersionDialog from "./SaveVersionDialog";
import useHideResetTool from "../utilities/hooks/useHideResetTool";
import { ToggleToolAboutTextField } from "./UpdateToolAboutTextField";
// import CreditCostDetails from "../utilities/CreditCostDetails";
import ToolBuilderHeaderSettingsDropdown from "./dropdowns/ToolBuilderHeaderSettingsDropdown";
import ToggleToolNameTextField from "./ToggleToolNameTextField";
import LastPageBreadcrumb from "./LastPageBreadcrumb";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import ToggleSidebarButton from "../features/layout/sidebar/ToggleSidebarButton";
import { useSelector } from "react-redux";
import { selectSidebarOpen } from "../features/layout/sidebar/sidebarSlice";
import { Box } from "@mui/material";

function Header() {
  const { state } = useToolbuilderContext();
  const { toolId } = state;
  // const { estimatedCreditCost } = currentState;
  const hideReset = useHideResetTool();
  const open = useSelector(selectSidebarOpen);

  return (
    <Box className="flex align-i-center bg-color-white ">
      {!open && <ToggleSidebarButton className="m-l-8px" />}
      <NoWrapCardHeader
        title={
          <div className="flex align-i-center">
            <LastPageBreadcrumb className="m-r-8px" />
            <ToggleToolNameTextField />
            <ToggleToolAboutTextField />
          </div>
        }
        className="w-100-percent"
        disableTypography
        action={
          <div className="flex align-i-center">
            {state.toolVersionId !== state.main?._id && (
              <CurrentToolVersion className="m-r-8px" />
            )}
            {/* {estimatedCreditCost !== 0 && (
              <CreditCostDetails
                estimatedCreditCost={estimatedCreditCost}
                className="m-l-8px"
              />
            )} */}
            <SaveVersionDialog text className="m-r-8px" />
            <VisitSiteButton
              toolId={toolId}
              className="m-r-8px"
              size="small"
              variant={hideReset ? "contained" : "outlined"}
            />
            <ToolBuilderHeaderSettingsDropdown />
          </div>
        }
      />
    </Box>
  );
}

export default Header;
