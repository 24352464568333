import useWorkspaceInputs from "./useWorkspaceInputs";

const useGetWorkspaceInputIndexById = () => {
  const inputs = useWorkspaceInputs();

  const getWorkspaceInputIndexById = (id: string) => {
    return inputs.findIndex((input) => input.id === id);
  };
  return getWorkspaceInputIndexById;
};

export default useGetWorkspaceInputIndexById;
