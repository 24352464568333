import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import React from "react";
import UpdateWorkflowNameTextField from "../UpdateWorkflowNameTextfield";
import UpdateWorkflowDescriptionTextField from "../UpdateWorkflowDescriptionTextfield";
import SaveWorkflowButtonFromDialog from "./SaveWorkflowButtonFromDialog";
import type { SaveWorkflowDialogInnerProps } from "@sharedTypes";
import UpdateVisibilitySelectForm from "../../../../utilities/UpdateVisibilitySelectForm";
import WorkflowStepsError from "../steps/WorkflowStepsError";
import AssignCategoriesFromWorkflowBuilder from "./AssignCategoriesFromWorkflowBuilder";
import AssignUseCasesFromWorkflowBuilder from "./AssignUseCasesFromWorkflowBuilder";

function SaveWorkflowDialogInner({
  open,
  handleClose
}: Readonly<SaveWorkflowDialogInnerProps>) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Save Workflow</DialogTitle>
      <DialogContent className="flex flex-column">
        <UpdateWorkflowNameTextField
          margin="normal"
          label="Workflow name"
          showErrors
        />
        <UpdateWorkflowDescriptionTextField
          margin="normal"
          label="Workflow description"
          placeholder="What does your workflow do..."
        />
        <UpdateVisibilitySelectForm margin="normal" />
        <WorkflowStepsError />
        <AssignCategoriesFromWorkflowBuilder />
        <AssignUseCasesFromWorkflowBuilder />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <SaveWorkflowButtonFromDialog />
      </DialogActions>
    </Dialog>
  );
}

export default SaveWorkflowDialogInner;
