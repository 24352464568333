import type { TSetEdges, TSetNodes } from "@sharedTypes";

const useHandleNodeDelete = ({
  setNodes,
  setEdges
}: {
  setNodes: TSetNodes;
  setEdges: TSetEdges;
}) => {
  const handleNodeDelete = (id: string) => {
    // Remove the block with the given id
    setNodes(
      (currentNodes) => currentNodes.filter((block) => block.id !== id),
      true
    );

    // Also remove any edges associated with this block
    setEdges((currentEdges) =>
      currentEdges.filter((edge) => edge.source !== id && edge.target !== id)
    );
  };
  return handleNodeDelete;
};

export default useHandleNodeDelete;
