import { IconButton } from "@mui/material";
import { RiLinkM } from "@remixicon/react";
import React from "react";
import { useHandleCopy } from "../../utilities/hooks/useHandleCopy";
import type { ValidateType } from "@sharedTypes";

const CopyMarketplaceEntityLink = ({
  entityId,
  entityType
}: {
  entityId: string;
  entityType: ValidateType;
}) => {
  const handleCopyToolLink = useHandleCopy(entityId, entityType);
  const copy = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleCopyToolLink();
  };
  return (
    <IconButton onClick={copy}>
      <RiLinkM size={16} />
    </IconButton>
  );
};

export default CopyMarketplaceEntityLink;
