import React from "react";
import Workspace from "../../workspace/Workspace";
import { type JSONContent } from "@tiptap/core";
import { Paper } from "@mui/material";
import type { InputNodeTypes, SaveWorkspaceType } from "@sharedTypes";
import { Editor } from "@tiptap/react";
import styles from "./tipTapTextField.module.css";

interface ITipTapTextField {
  container?: React.RefObject<HTMLDivElement>;
  initialValue?: string;
  onChange: (s: string | JSONContent) => void;
  helperText?: string;
  autoFocus?: boolean;
  id?: string;
  disableInputs?: boolean;
  disableFormatting?: boolean;
  label?: string;
  verticalLabel?: boolean;
  variant?: "standard" | "outlined";
  richTextFieldClassName?: string;
  inputType?: InputNodeTypes;
  saveWorkspaceType?: SaveWorkspaceType;
  className?: string;
  disableWhite?: boolean;
  disableTools?: boolean;
  maxHeight?: string | number;
  preventDefaultEnter?: boolean;
  enableHover?: boolean;
  overrideKeydown?: (event: KeyboardEvent, e?: Editor) => void;
}

const TipTapTextField = ({
  container,
  initialValue = "", // tiptap can't be controlled, so we just initialize it and use onUpdate to track the changes elsewhere
  onChange,
  autoFocus,
  id = "",
  disableInputs,
  disableFormatting,
  label,
  variant = "outlined",
  richTextFieldClassName = "tiptap-textfield-p",
  inputType = "chip",
  saveWorkspaceType = "text",
  className = "",
  disableWhite,
  disableTools,
  maxHeight,
  preventDefaultEnter,
  overrideKeydown,
  enableHover = true
}: ITipTapTextField) => {
  return (
    <div
      className={styles["tiptap-textfield-scroll"]}
      id={id}
      style={maxHeight ? { maxHeight } : undefined}
    >
      <Workspace
        className={className}
        container={container}
        disableAutofocus={!autoFocus}
        disableFormatting={disableFormatting}
        disableInputs={disableInputs}
        disableTools={disableTools}
        disableWhite={disableWhite}
        enableHover={enableHover}
        hideTopMenu
        preventDefaultEnter={preventDefaultEnter}
        overrideKeydown={overrideKeydown}
        initialContent={initialValue}
        inputType={inputType}
        onUpdate={onChange}
        placeholder={label}
        richTextFieldClassName={richTextFieldClassName}
        saveWorkspaceType={saveWorkspaceType}
        variant={variant}
      />
    </div>
  );
};

export const ExpandableTipTapTextField = (
  props: ITipTapTextField & { defaultExpanded?: boolean }
) => {
  return (
    <Paper
      variant="outlined"
      className={`flex align-i-flex-start
     w-100-percent oflow-hidden`}
    >
      <TipTapTextField {...props} variant="standard" />
    </Paper>
  );
};

export default TipTapTextField;
