import React from "react";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Unstable_Grid2";
import { CardHeader } from "@mui/material";

function DraggableBlock({
  title,
  open,
  onDragStart,
  icon
}: {
  title: string;
  open: boolean;
  onDragStart?: React.DragEventHandler<HTMLDivElement>;
  icon: React.ReactNode;
}) {
  return (
    <Grid xs={12}>
      {open ? (
        <Card
          variant="outlined"
          style={{ cursor: "grab" }}
          onDragStart={onDragStart}
          draggable
        >
          <CardHeader
            titleTypographyProps={{ variant: "body2" }}
            title={title}
            avatar={icon}
            className="text-wrap"
          />
        </Card>
      ) : (
        <Avatar
          variant="rounded"
          style={{ cursor: "grab" }}
          onDragStart={onDragStart}
          draggable
        >
          {icon}
        </Avatar>
      )}
    </Grid>
  );
}
export default DraggableBlock;
