import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import LastPageBreadcrumb from "../../toolBuilder/LastPageBreadcrumb";
import AuthenticationSplitter from "../../utilities/AuthenticationSplitter";
import LoggedOutCTA from "../../utilities/LoggedOutCTA";
import NoWrapCardHeader from "../../utilities/NoWrapCardHeader";
import ToggleSidebarButton from "../layout/sidebar/ToggleSidebarButton";
import { selectSidebarOpen } from "../layout/sidebar/sidebarSlice";
import ProfileDropdownMenu from "../profile/dropdown/ProfileDropdownMenu";
import ToggleWorkspaceName from "./ToggleWorkspaceName";

function WorkspaceHeader() {
  const open = useSelector(selectSidebarOpen);
  return (
    <Box
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        position: "relative",
        marginLeft: "1px"
      }}
      className="flex align-i-center p-h-24px"
    >
      {!open && <ToggleSidebarButton />}
      <NoWrapCardHeader
        title={
          <div className="flex align-i-center">
            <LastPageBreadcrumb className="m-r-8px" />
            <ToggleWorkspaceName />
          </div>
        }
        action={
          <AuthenticationSplitter
            externalComponent={<LoggedOutCTA />}
            authenticatedComponent={<ProfileDropdownMenu />}
          />
        }
        disableTypography
        className="w-100-percent"
      />
    </Box>
  );
}

export default WorkspaceHeader;
