import React from "react";
import SettingsDropdownMenuButton from "../../utilities/dropdowns/generic/SettingsDropdownMenuButton";
import MenuItemWithClose from "../../utilities/dropdowns/generic/MenuItemWithClose";

function InputOutputMenu({
  handleToggleInput,
  handleToggleOutput
}: {
  handleToggleInput: () => void;
  handleToggleOutput: () => void;
}) {
  const menuItems = [
    <MenuItemWithClose
      onClick={handleToggleInput}
      text="View Inputs"
      key="toggleInput"
    />,
    <MenuItemWithClose
      onClick={handleToggleOutput}
      text="View Output"
      key="toggleOutput"
    />
  ];
  return (
    <div className="flex align-i-center justify-center">
      <SettingsDropdownMenuButton menuItems={menuItems} />
    </div>
  );
}

export default InputOutputMenu;
