import { TextSelection } from "@tiptap/pm/state";
import { useRichTextEditorContext } from "mui-tiptap";
import type { Content } from "@tiptap/core";

const useInsertText = (preserveWhitespace = false) => {
  const editor = useRichTextEditorContext();
  return (
    newContent: Content | string,
    deleteSlash?: boolean,
    focus = false
  ) => {
    if (!editor) return;

    // delete the slash when inserting text
    if (deleteSlash) {
      editor.commands.deleteRange({
        from: editor.view.state.selection.to - 1,
        to: editor.view.state.selection.to
      });
    }
    editor.commands.insertContentAt(
      editor.view.state.selection.to,
      newContent,
      {
        parseOptions: {
          preserveWhitespace
        }
      }
    );

    const updatedState = editor.state;
    const newParagraphPos = updatedState.doc.content.size - 1;

    if (newParagraphPos <= updatedState.doc.content.size) {
      const tr = updatedState.tr;
      tr.setSelection(TextSelection.create(updatedState.doc, newParagraphPos));
      editor.view.dispatch(tr);
      if (focus) {
        editor.commands.focus();
      }
    } else {
      console.error("New position out of range");
    }
  };
};

export default useInsertText;
