import type { TAnthropicOptimizationsType } from "@sharedTypes";

export const anthropicInitialOptimizations: TAnthropicOptimizationsType = {
  optimizeTokens: false,
  useChainOfThought: false,
  llmRole: "No Role",
  stripPII: false,
  responseLength: "normal",
  responseLengthType: "sentence",
  responseLengthCount: 3,
  outputType: "Text",
  llmModel: "claude-3-opus-20240229",
  temperature: 50
};
