import React from "react";
import OpenAssetGroup from "./OpenAssetGroupButton";

import { parsedClassNames } from "../../../../utils/parsedClassNames";
import TextAndBadge from "../../../textAndBadge/TextAndBadge";
import AssetIterator from "./AssetIterator";
import styles from "./assetGroupWrapper.module.css";
import useAssetGroup from "./hooks/useAssetGroup";

const cx = parsedClassNames.bind(styles);

interface IAssetGroupWrapperProps {
  assets: string[];
  text: string;
  badgeCount?: number;
}

const AssetGroupWrapper = ({
  assets,
  text,
  badgeCount
}: IAssetGroupWrapperProps) => {
  const { isOpen, onClick } = useAssetGroup({ assets });
  return (
    <div
      className={cx(
        "m-b-8px pos-relative parent-hover-1",
        styles["asset-group-wrapper"]
      )}
    >
      <TextAndBadge
        text={text}
        badgeCount={badgeCount}
        typographyVariant="subtitle2"
        bgBadgeColorWhite
      />
      {isOpen && <AssetIterator assets={assets} />}
      <div
        className={cx(
          "pos-absolute bg-color-white show-on-hover-1",
          styles["icon-button-wrapper"]
        )}
      >
        <OpenAssetGroup onClick={onClick} open={isOpen} />
      </div>
    </div>
  );
};

export default AssetGroupWrapper;
