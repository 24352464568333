import {
  secondaryClosedMixin,
  secondaryOpenedMixin
} from "./secondaryBuilderDrawerStyles";
import { Drawer, styled } from "@mui/material";

export const StyledSecondaryBuilderDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...secondaryOpenedMixin(theme, open),
    "& .MuiDrawer-paper": secondaryOpenedMixin(theme, open)
  }),
  ...(!open && {
    ...secondaryClosedMixin(theme, !!open),
    "& .MuiDrawer-paper": secondaryClosedMixin(theme, !!open)
  })
}));
