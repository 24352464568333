import React from "react";
import Container from "@mui/material/Container";
import FilesCard from "./FilesCard";

function FilesPage() {
  return (
    <Container maxWidth="md">
      <FilesCard />
    </Container>
  );
}
export default FilesPage;
