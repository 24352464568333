import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import useSetSelectedWorkspaceTool from "./hooks/useSetSelectedTool";

function DeselectWorkspaceToolButton({ className }: { className?: string }) {
  const setSelectedTool = useSetSelectedWorkspaceTool();
  const deselect = () => {
    setSelectedTool(null);
  };

  return (
    <IconButton size="small" onClick={deselect} className={className}>
      <Close fontSize="small" />
    </IconButton>
  );
}

export default DeselectWorkspaceToolButton;
