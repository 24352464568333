import React from "react";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useDuplicateObject } from "../../../utilities/hooks/useDuplicateObject";
import { TOOL } from "../../../utilities/constants/constants";
import SettingsMenuItemWithClose from "../../../utilities/dropdowns/generic/SettingsMenuItemWithClose";

function DuplicateToolMenuItem({ id }: { id: string }) {
  const { loading, duplicateObject } = useDuplicateObject();

  const duplicate = () => {
    duplicateObject(id, TOOL);
  };

  return (
    <SettingsMenuItemWithClose
      disabled={loading}
      hide={!id}
      action={duplicate}
      hotkey="Meta+D"
      text={"Duplicate tool"}
      icon={<FileCopyIcon fontSize="small" />}
    />
  );
}

export default DuplicateToolMenuItem;
