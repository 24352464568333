import React from "react";
import ToolSelectorForWorkspace from "./ToolSelectorForWorkspace";
import RunToolCard from "./RunToolCard";
import { Box } from "@mui/material";
import RunWorkflowCard from "./RunWorkflowCard";

function ToolsTab() {
  return (
    <Box className="flex justify-space-between flex-column h-100-percent">
      <div className="flex-grow-1">
        <ToolSelectorForWorkspace />
        <RunToolCard />
        <RunWorkflowCard />
      </div>
    </Box>
  );
}

export default ToolsTab;
