import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import type { SelectChangeEvent } from "@mui/material/Select";
import type { VisibilityTypes } from "@sharedTypes";
import type { FormControlProps } from "@mui/material/FormControl";
import { useUpdateToolMutation } from "../ToolflowAPI/rtkRoutes/toolsApi";
import visibilityMenuItemsArray from "../utilities/visibilityMenuItemsArray";

export function UpdateVisibilitySelectBase({
  noMargin,
  formProps,
  disabled,
  handleSelectChange,
  visibility
}: {
  noMargin?: boolean;
  formProps?: FormControlProps;
  disabled?: boolean;
  handleSelectChange: (event: SelectChangeEvent<VisibilityTypes>) => void;
  visibility: VisibilityTypes;
}) {
  return (
    <FormControl
      margin={noMargin ? "none" : "normal"}
      size="small"
      {...formProps}
    >
      <InputLabel>Privacy</InputLabel>
      <Select
        name="Privacy"
        label="Privacy"
        id="Privacy"
        value={visibility}
        onChange={handleSelectChange}
        onClick={(e) => {
          e.stopPropagation();
        }}
        disabled={disabled}
      >
        {visibilityMenuItemsArray}
      </Select>
    </FormControl>
  );
}
function UpdateVisibilitySelect({
  noMargin,
  toolId,
  formProps
}: {
  noMargin?: boolean;
  toolId: string;
  formProps?: FormControlProps;
}) {
  const { dispatch, state } = useToolbuilderContext();
  const [updateTool, isLoading] = useUpdateToolMutation();
  const { visibility } = state.currentState;

  const handleSelectChange = async (
    event: SelectChangeEvent<VisibilityTypes>
  ) => {
    await updateTool({
      body: { visibility: event.target.value as VisibilityTypes },
      toolId
    })
      .unwrap()
      .then((resp) => {
        dispatch({
          type: "SAVE_VISIBILITY",
          visibility: resp.tool.visibility
        });
      });
  };

  return (
    <UpdateVisibilitySelectBase
      noMargin={noMargin}
      formProps={formProps}
      disabled={!!isLoading}
      handleSelectChange={handleSelectChange}
      visibility={visibility}
    />
  );
}

export default UpdateVisibilitySelect;
