import { StyledEngineProvider } from "@mui/material";
import React from "react";
import InitializedAuth0Provider from "./InitializedAuth0Provider";
import { Providers } from "./Providers";

const RootComponent = () => {
  return (
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <InitializedAuth0Provider>
          <Providers />
        </InitializedAuth0Provider>
      </StyledEngineProvider>
    </React.StrictMode>
  );
};

export default RootComponent;
