import React, { FC } from "react";
import type { Content } from "@tiptap/core";
import { GenericRichContent } from "../../../../../utilities/GenericRichContent";
interface IUserMessageProps {
  messageContent: Content;
}
const UserMessage: FC<IUserMessageProps> = (props) => {
  const { messageContent } = props;
  return (
    <GenericRichContent
      content={messageContent}
      className="text-align-right m-h-16px"
      rerender={true}
      key="user-message"
    />
  );
};
export default UserMessage;
