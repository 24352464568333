import React from "react";
import LoginButton from "../../utilities/LoginButton";
import AuthenticationSplitter from "../../utilities/AuthenticationSplitter";
import { ThemeProvider } from "@mui/material/styles";
import MarketplaceLoading from "./MarketplaceLoading";
import MarketplacePageInner from "./MarketplacePageInner";
import marketplaceTheme from "./theme/marketplaceTheme";

const MarketplacePage = () => {
  return (
    <ThemeProvider theme={marketplaceTheme}>
      <AuthenticationSplitter
        loadingComponent={<MarketplaceLoading />}
        authenticatedComponent={<MarketplacePageInner />}
        externalComponent={
          <MarketplacePageInner
            action={
              <div className="flex align-i-center">
                <LoginButton variant="contained" />
              </div>
            }
          />
        }
      />
    </ThemeProvider>
  );
};

export default MarketplacePage;
