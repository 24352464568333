import type {
  APIKeyResponseFE,
  CreateAPIBody,
  CreateAPIKeyResponseFE
} from "@sharedTypes";
import { authenticatedApi } from "../authenticatedAPI";
import { setErrorMessage, setSuccessMessage } from "../../stores/actions";
import { getErrorMessage } from "../../utilities/apiHandlerWithAuth";
import { API_KEY_TAG_TYPE } from "../tagConstants";

export const apiKeyApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    createApiKey: builder.mutation<CreateAPIKeyResponseFE, CreateAPIBody>({
      query: (body) => ({
        url: "apiKey",
        method: "POST",
        body
      }),
      invalidatesTags: [API_KEY_TAG_TYPE],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage("Saved API key"));
        } catch {}
      }
    }),
    deleteApiKey: builder.mutation({
      query: (id: string) => ({
        url: `apiKey/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: [API_KEY_TAG_TYPE],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(setSuccessMessage("Deleted API key"));
        } catch (err) {
          // `onError` side-effect
          dispatch(
            setErrorMessage(getErrorMessage(err, "Error deleting API key"))
          );
        }
      }
    }),
    getApiKeys: builder.query<APIKeyResponseFE, void>({
      query: () => ({
        url: "apiKey",
        method: "GET"
      }),
      providesTags: [API_KEY_TAG_TYPE]
    })
  })
});

export const apiKeyApiEndpoints = apiKeyApi.endpoints;

export const {
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
  useGetApiKeysQuery
} = apiKeyApi;
