import React from "react";
import WorkspaceToolSelectorContainer from "./WorkspaceToolSelectorContainer";
import WorkspaceToolInfoCard from "../../WorkspaceToolInfoCard";
import useGetSelectedWorkspaceTool from "../../hooks/useGetSelectedWorkspaceTool";
import useGetSelectedWorkspaceWorkflow from "../../hooks/useGetSelectedWorkspaceWorkflow";
import WorkspaceWorkflowInfoCard from "../../WorkspaceWorkflowInfoCard";

function WorkspaceToolSelectorSplitter() {
  const selectedTool = useGetSelectedWorkspaceTool(true); // we want to refetch when this mounts
  const selectedWorkflow = useGetSelectedWorkspaceWorkflow();

  if (selectedTool) {
    return <WorkspaceToolInfoCard />;
  }
  if (selectedWorkflow) {
    return <WorkspaceWorkflowInfoCard />;
  }
  return <WorkspaceToolSelectorContainer />;
}

export default WorkspaceToolSelectorSplitter;
