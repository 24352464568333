import type {
  CustomToolInputField,
  ExternalToolFE,
  MapperObject
} from "@sharedTypes";

const findOldFieldName = (
  oldTool: ExternalToolFE,
  currentField: CustomToolInputField
) => {
  const oldToolField = oldTool.main.toolInputFields.find(
    (inputField) =>
      inputField.id === currentField.id && inputField.type === currentField.type // if the id and type are the same, then we reuse the mapped types. We could in the future allow compatible types, but not right now (e.g textfield > select retains the same)
  );
  return oldToolField?.name || "";
};

const replaceInitialInputMap = (
  currentTool: ExternalToolFE,
  oldTool: ExternalToolFE,
  existingMap: MapperObject
) => {
  return currentTool.main.toolInputFields.reduce<MapperObject>(
    (accumulator, currentField) => {
      const oldFieldName = findOldFieldName(oldTool, currentField);
      accumulator[currentField.name] = existingMap[oldFieldName] || "";
      return accumulator;
    },
    {}
  );
};

export default replaceInitialInputMap;
