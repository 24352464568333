import React, { createContext, useContext, ReactNode } from "react";
import type { EmbeddedStep } from "@sharedTypes";

interface WorkflowStepContextType<T extends EmbeddedStep> {
  step: T;
  index: number;
}

const WorkflowStepContext = createContext<
  WorkflowStepContextType<EmbeddedStep> | undefined
>(undefined);

interface WorkflowStepContextComponentProps<T extends EmbeddedStep> {
  children: ReactNode;
  step?: T;
  index: number;
}

export function WorkflowStepContextComponent<T extends EmbeddedStep>({
  children,
  step,
  index
}: Readonly<WorkflowStepContextComponentProps<T>>) {
  if (!step) {
    return null;
  }
  return (
    <WorkflowStepContext.Provider value={{ step, index }}>
      {children}
    </WorkflowStepContext.Provider>
  );
}

export const useWorkflowStepContext = <T extends EmbeddedStep>() => {
  const context = useContext(WorkflowStepContext) as
    | WorkflowStepContextType<T>
    | undefined;
  if (context === undefined) {
    throw new Error(
      "useWorkflowStepContext must be used within a WorkflowStepContextComponent"
    );
  }
  return context;
};

export default WorkflowStepContext;
