import { Button } from "@mui/material";
import React, { ReactNode } from "react";
import { Handle, Position } from "reactflow";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import styles from "./inOutNodeHandle.module.css";

const InOutNodeHandle = ({
  handleType,
  icon,
  id
}: {
  handleType: "source" | "target";
  icon: ReactNode;
  id: string;
}) => {
  const { reactflowUtility, state } = useToolbuilderContext();
  const { dragParams } = reactflowUtility;
  const disabledHandleType = handleType === "source" ? "target" : "source";
  const position = handleType === "source" ? Position.Bottom : Position.Top;
  const idType = handleType === "source" ? "a" : "b";
  const { openNodeId } = state;

  return (
    <Button
      fullWidth
      className={`${
        (dragParams?.handleType === disabledHandleType &&
          dragParams.nodeId !== id) ||
        openNodeId === id
          ? ""
          : styles.hover
      } noDrag cursor-crosshair`}
      variant="outlined"
    >
      {icon}
      <Handle
        type={handleType}
        className="custom"
        position={position}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          opacity: 0,
          zIndex: 1000
        }}
        id={idType}
      />
    </Button>
  );
};

export default InOutNodeHandle;
