import type { TWorkflowBuilderForm } from "@sharedTypes";
import React, { createContext, useContext } from "react";
import {
  useFormContext,
  type UseFieldArrayReturn,
  useFieldArray
} from "react-hook-form";
import {
  WORKFLOW_INPUTS_TO_COLLECT_FIELD_LABEL,
  WORKFLOW_STEPS_FIELD_LABEL
} from "../../WorkflowBuilderConstants";
import useGetSelectedStepInputsToCollectFieldLabel from "./hooks/useGetSelectedStepInputsToCollectFieldLabel";

// RHF create their own ids for each field in the array,
// this makes using ids from fields not intuitive because
// we have our own ids. so we omit fields to minimze bugs
type TInputsToCollectContext = Omit<
  UseFieldArrayReturn<
    TWorkflowBuilderForm,
    `${typeof WORKFLOW_STEPS_FIELD_LABEL}.${number}.${typeof WORKFLOW_INPUTS_TO_COLLECT_FIELD_LABEL}`,
    "id"
  >,
  "fields"
>;

const InputsToCollectContext = createContext<
  TInputsToCollectContext | undefined
>(undefined);

export const useInputsToCollectFieldArrayContext = () => {
  const context = useContext(InputsToCollectContext);
  if (context === undefined) {
    throw new Error(
      "useInputsToCollectFieldArrayContext must be used within a InputsToCollectFieldArrayContext"
    );
  }
  return context;
};

export const InputsToCollectFormProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const { control } = useFormContext<TWorkflowBuilderForm>();
  const fieldLabel = useGetSelectedStepInputsToCollectFieldLabel();
  const { fields, ...fieldArrayMethods } = useFieldArray({
    control,
    name: fieldLabel
  });
  return (
    <InputsToCollectContext.Provider value={fieldArrayMethods}>
      {children}
    </InputsToCollectContext.Provider>
  );
};
