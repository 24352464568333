// DownloadButton.tsx
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import type { UserInputDictType, CustomToolOutputField } from "@sharedTypes";
import useDownloadZip from "./hooks/useDownloadZip";
import { CircularProgress, IconButton } from "@mui/material";
import SettingsMenuItemWithClose from "./dropdowns/generic/SettingsMenuItemWithClose";

const DownloadToolButton = ({
  disabled,
  toolOutput,
  name,
  outputFields,
  className = "",
  tvrId,
  menuItem
}: {
  disabled?: boolean;
  name: string;
  toolOutput: UserInputDictType | null;
  outputFields: CustomToolOutputField[];
  className?: string;
  tvrId?: string;
  menuItem?: boolean;
}) => {
  const { downloadZip, loading } = useDownloadZip({
    toolOutput,
    outputFields,
    name,
    tvrId
  });

  if (!toolOutput) {
    return null;
  }

  if (menuItem) {
    return (
      <SettingsMenuItemWithClose
        action={downloadZip}
        disabled={loading}
        text="Download response"
        icon={<DownloadIcon fontSize="small" />}
      />
    );
  }
  if (loading) {
    return <CircularProgress size={20} className={className} />;
  }

  return (
    <IconButton onClick={downloadZip} disabled={disabled} className={className}>
      <DownloadIcon fontSize="small" />
    </IconButton>
  );
};

export default DownloadToolButton;
