import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setUseCases as setReduxUseCases } from "../../slice/marketplaceSlice";
import { RootState } from "../../../../stores/store";
import { useCaseTags } from "../../../../utilities/toolCategorization/constants";
import { TUseCaseTag } from "../../../../utilities/toolCategorization/hooks/useCategoryTags";

const useMarketplaceUseCases = () => {
  const reduxUseCases = useSelector(
    (state: RootState) => state.marketplace.useCases
  );

  const currentUseCases = useCaseTags.map((useCase) =>
    reduxUseCases.toString().includes(useCase.value.toString())
      ? { ...useCase, selected: true }
      : { ...useCase, selected: false }
  );
  const [useCases, setUseCases] = useState<TUseCaseTag[]>(currentUseCases);

  useEffect(() => {
    const updatedUseCases = useCases.map((useCase) => {
      return {
        ...useCase,
        selected: [reduxUseCases].toString().includes(useCase.value)
      };
    });
    setUseCases(updatedUseCases);
  }, [reduxUseCases]);

  const dispatch = useDispatch();
  const handleToggleUseCaseSelection = (chip: TUseCaseTag) => {
    const updatedUseCases = useCases.map((useCase) => {
      if (useCase.id === chip.id) {
        return { ...useCase, selected: !useCase.selected };
      }
      return useCase;
    });
    const selectedUseCasesValues = updatedUseCases
      .filter((useCase) => useCase.selected)
      .map((useCase) => useCase.value);

    dispatch(setReduxUseCases(selectedUseCasesValues));
  };
  return {
    handleToggleUseCaseSelection,
    useCases
  };
};

export default useMarketplaceUseCases;
