import React from "react";
import AssignCategoriesNoSaveBase from "../../../../utilities/toolCategorization/AssignCategoriesNoSaveBase";
import useGetWorkflowCategories from "../hooks/useGetWorkflowCategories";
import useSetWorkflowCategories from "../hooks/useSetWorkflowCategories";

const AssignCategoriesFromWorkflowBuilder = () => {
  const initialSelectedTags = useGetWorkflowCategories();
  const setWorkflowCategories = useSetWorkflowCategories();

  return (
    <AssignCategoriesNoSaveBase
      handleUpdate={setWorkflowCategories}
      initialSelectedTags={initialSelectedTags}
    />
  );
};

export default AssignCategoriesFromWorkflowBuilder;
