import React from "react";
import { IconButton } from "@mui/material";
import { RestartAlt } from "@mui/icons-material";
import useResetThread from "./hooks/useResetThread";

function ResetThreadButton() {
  const resetThread = useResetThread();

  return (
    <IconButton onClick={resetThread} size="small" className="m-l-auto">
      <RestartAlt fontSize="small" />
    </IconButton>
  );
}

export default ResetThreadButton;
