import { useEffect, useMemo } from "react";
import useCurrentInputsRef from "../../../utilities/editorToolCard/hooks/useCurrentInputsRef";
import useToolCompleted from "../../../utilities/editorToolCard/hooks/useToolCompleted";
import { useNodeViewContext } from "../../../utilities/contexts/NodeViewContext";

const useDeleteNodeWhenToolCompleted = () => {
  const currentInputs = useCurrentInputsRef();
  const toolCompleted = useToolCompleted();
  const { deleteNode } = useNodeViewContext();
  const allLoadingFalse = useMemo(() => {
    return currentInputs.current.filter((i) => i.loading).length === 0;
  }, [currentInputs.current]);

  useEffect(() => {
    if (allLoadingFalse && toolCompleted) {
      queueMicrotask(() => {
        deleteNode();
      });
    }
  }, [allLoadingFalse, toolCompleted]);
};

export default useDeleteNodeWhenToolCompleted;
