import React from "react";
import LinkTextButton from "./LinkTextButton";

function Guidelines({
  typographyClassName = ""
}: {
  typographyClassName?: string;
}) {
  return (
    <LinkTextButton
      to="https://sour-track-509.notion.site/User-Guidelines-46ba122a4dbe40b1847f8fb9cac15c3f"
      text="Guidelines"
      typographyClassName={typographyClassName}
    />
  );
}

export default Guidelines;
