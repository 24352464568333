import { type AutocompleteRenderInputParams, TextField } from "@mui/material";
import React from "react";

function ToolSelectorInput({
  params,
  placeholder,
  label = "Tool"
}: {
  params: AutocompleteRenderInputParams;
  placeholder: string;
  label?: string;
}) {
  return (
    <TextField autoFocus {...params} label={label} placeholder={placeholder} />
  );
}

export default ToolSelectorInput;
