import React, { useEffect, useRef, useState } from "react";

import { Box, Typography } from "@mui/material";
import { useRichTextEditorContext } from "mui-tiptap";

// When changing the layout,
// update DesignToolCard too
// It's easier to split them -> We are going to migrate Design Card
function ToolflowNodeWrapper({
  contentEditable = "false",
  title,
  settings,
  children,
  titleClassName = "",
  alwaysShowTitle,
  hideOutline,
  alwaysShowOutline
}: {
  contentEditable?: "true" | "false";
  title?: string | React.ReactNode;
  settings: React.ReactNode;
  children: React.ReactNode;
  titleClassName?: string;
  alwaysShowTitle?: boolean;
  hideOutline?: boolean;
  alwaysShowOutline?: boolean;
}) {
  const editor = useRichTextEditorContext();
  const [hover, setHover] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const checkActiveChild = () => {
    return (
      document.activeElement &&
      wrapperRef.current?.contains(document.activeElement)
    );
  };

  const handleMouseLeave = () => {
    if (!checkActiveChild()) {
      setHover(false);
    }
  };

  useEffect(() => {
    if (editor?.isFocused || editor?.isEditable) {
      setHover(false);
    }
  }, [editor?.isFocused, editor?.isEditable]);

  useEffect(() => {
    if (checkActiveChild()) {
      setHover(true);
    } else {
      setHover(false);
    }
  }, [document.activeElement]);

  return (
    <div
      ref={wrapperRef}
      className="m-b-8px w-100-percent"
      contentEditable={contentEditable}
      suppressContentEditableWarning={true}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={handleMouseLeave}
    >
      {title && (
        <>
          {typeof title === "string" ? (
            <Typography
              variant="caption"
              color="GrayText"
              className={titleClassName}
              sx={hover || alwaysShowTitle ? {} : { opacity: 0 }}
            >
              {title}
            </Typography>
          ) : (
            <div
              className={titleClassName}
              style={hover || alwaysShowTitle ? {} : { opacity: 0 }}
            >
              {title}
            </div>
          )}
        </>
      )}

      <div className="flex">
        <div
          className="m-l-neg-32px"
          style={hover || alwaysShowOutline ? {} : { opacity: 0 }}
        >
          {settings}
        </div>
        <Box
          className="w-100-percent"
          sx={{
            borderRadius: "4px",
            border: `1px solid ${
              alwaysShowOutline || (hover && !hideOutline)
                ? "rgba(0, 0, 0, 0.27)"
                : "rgba(0,0,0,0)"
            }`,
            padding: "4px"
          }}
        >
          {children}
        </Box>
      </div>
    </div>
  );
}

export default ToolflowNodeWrapper;
