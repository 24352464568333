import { useMemo } from "react";
import useMarketplaceEntities from "../../../features/marketplace/hooks/useMarketplaceEntities";

const useToolIsFeatured = (objectId: string) => {
  const { filteredMarketplaceEntities } = useMarketplaceEntities({
    fromSearch: true
  });
  return useMemo(() => {
    console.log(
      "filteredMarketplaceEntities",
      objectId,
      filteredMarketplaceEntities
    );
    return !!filteredMarketplaceEntities.find(
      (entity) => entity.id === objectId
    );
  }, [filteredMarketplaceEntities, objectId]);
};

export default useToolIsFeatured;
