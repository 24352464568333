import React from "react";
import { toolflowTextDisabledColor } from "../../../../../../globalTheme/muiUtils/appTheme";
import SettingsDropdownMenuButton from "../../../../../../utilities/dropdowns/generic/SettingsDropdownMenuButton";
import BaseSettingsDropdown from "../../../../../../utilities/dropdowns/generic/base/BaseSettingsDropdown";
import RemixWrapperIconWithTheme from "../../../../../layout/icons/RemixWrapperIconWithTheme";
import { MoreIcon } from "../../../icons/icons";
import WordCountMenuItem from "./WordCountMenuItem";

function TextSettingsDropdown() {
  const menuItems = [<WordCountMenuItem key="wordCount" />];

  return (
    <BaseSettingsDropdown>
      <SettingsDropdownMenuButton
        menuItems={menuItems}
        icon={
          <RemixWrapperIconWithTheme
            Icon={MoreIcon}
            size={16}
            providedColor={toolflowTextDisabledColor}
          />
        }
        MenuButtonProps={{
          tooltipLabel: "More options"
        }}
      />
    </BaseSettingsDropdown>
  );
}

export default TextSettingsDropdown;
