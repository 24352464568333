import type { AttributesType } from "@sharedTypes";
import { useNodeViewContext } from "../../../utilities/contexts/NodeViewContext";

const useSetAttribute = () => {
  const { updateAttributes } = useNodeViewContext();
  // we can't use state, have to use attributes so that
  // rerenders work correctly
  const setAttribute = <K extends keyof AttributesType>(
    key: K,
    value: AttributesType[K]
  ) => {
    queueMicrotask(() => {
      try {
        updateAttributes({ [key]: value });
      } catch (e) {
        console.error(
          `error updating attributes with key:${key}, value:${JSON.stringify(
            value
          )}`,
          e
        );
      }
    });
  };
  return setAttribute;
};

export default useSetAttribute;
