import React from "react";
import ReactDOMClient from "react-dom/client";
import "./globalTheme/index.css";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import {
  createRoutesFromElements,
  useLocation,
  useNavigationType,
  matchRoutes
} from "react-router-dom";
import RootComponent from "./providers/RootComponent";

if (
  ["development", "staging"].includes(process.env.REACT_APP_ENVIRONMENT || "")
) {
  window.reducerStates = {};
}

if (process.env.REACT_APP_ENVIRONMENT !== "development") {
  // after calling LogRocket.init()
  setupLogRocketReact(LogRocket);

  LogRocket.init("oxub8k/toolflow-production", {
    release: process.env.REACT_APP_ENVIRONMENT
  });
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });

  Sentry.init({
    dsn: "https://6447dc210f4f46b19d20d95c9d6305e6@o4505234553765888.ingest.sentry.io/4505235356057600",
    release: "FE Version 1",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromElements,
          matchRoutes
        )
      }),
      new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const container = document.getElementById("root");
// createRoot(container!) if you use TypeScript
const root = ReactDOMClient.createRoot(container!); //eslint-disable-line @typescript-eslint/no-non-null-assertion
root.render(<RootComponent />);
