import type { TMarketplaceFilterByCategoriesFE } from "@sharedTypes";
import { marketplaceCategoryTabs } from "../categories/constants";

export const getCategoryTitle = (
  category: TMarketplaceFilterByCategoriesFE
) => {
  const categoryDict = marketplaceCategoryTabs.find(
    (tab) => tab.value === category
  );
  if (!categoryDict) {
    return "";
  }
  return categoryDict.title;
};
