import React from "react";
import MarketplacePageBase from "./MarketplacePageBase";
import useMarketplaceEntities from "./hooks/useMarketplaceEntities";
import { useGetMarketplaceEntitiesQuery } from "../../ToolflowAPI/rtkRoutes/marketplaceApi";

const MarketplacePageInner = ({ action }: { action?: React.ReactNode }) => {
  const { data } = useGetMarketplaceEntitiesQuery();
  const { filteredMarketplaceEntities } = useMarketplaceEntities({
    fromSearch: false
  });
  return (
    <MarketplacePageBase
      loading={!data}
      marketplaceEntities={filteredMarketplaceEntities}
      action={action}
    />
  );
};

export default MarketplacePageInner;
