import React from "react";
import DeleteSnippetButton from "../../../utilities/DeleteSnippetButton";
import CopySnippetButton from "../../../utilities/CopySnippetButton";
import EditSnippetButton from "../../../utilities/EditSnippetButton";

function SnippetRowActions({ id }: { id: string }) {
  return (
    <>
      <CopySnippetButton id={id} />
      <EditSnippetButton id={id} />
      <DeleteSnippetButton id={id} />
    </>
  );
}

export default SnippetRowActions;
