import type {
  LogicType,
  ValidatedInput,
  LogicItem,
  CustomToolInputField,
  ValidatedInputTypes
} from "@sharedTypes";
import { isInputWithOptions } from "../../utilities/Inputs/constants";

export const logicMap: {
  [key in ValidatedInputTypes]?: LogicType;
} = {
  select: "is",
  checkbox: "checked",
  textField: "contains",
  largeTextField: "contains",
  blockOutput: "contains",
  fileUpload: "exists"
};

export const getDefaultParameterValue = (
  inputLogicType: LogicType,
  toolInputField: CustomToolInputField | ValidatedInput
) => {
  let parameterValue;
  if (inputLogicType === "is") {
    parameterValue = isInputWithOptions(toolInputField)
      ? toolInputField.options[0]
      : "";
  } else if (inputLogicType === "contains") {
    parameterValue = "";
  }
  return parameterValue;
};

export const generateInitialLogicFromToolInput = (
  toolInputField: CustomToolInputField | ValidatedInput
): LogicItem => {
  if (toolInputField) {
    const inputLogicType = logicMap[toolInputField.type];
    if (inputLogicType) {
      return {
        input: toolInputField.name,
        inputLogicType: "exists",
        logicValue: "true"
      };
    }
  }
  return {
    input: "",
    inputLogicType: "exists",
    logicValue: "true"
  };
};
