import { useFormContext } from "react-hook-form";
import useWorkflowResetDefaultNeedsReset from "./useWorkflowResetDefaultNeedsReset";
import type { TWorkflowBuilderForm } from "@sharedTypes";

const useWorkflowReset = () => {
  const { reset } = useFormContext<TWorkflowBuilderForm>();

  return useWorkflowResetDefaultNeedsReset(reset);
};

export default useWorkflowReset;
