import type {
  CurrentUserResponseFE,
  ProfileResponseFE,
  UpdateUserBody,
  UpdateUserResponseFE,
  UserDetailsResponse
} from "@sharedTypes";
import { authenticatedApi } from "../authenticatedAPI";
import { getErrorMessage } from "../../utilities/apiHandlerWithAuth";
import { setErrorMessage, setSuccessMessage } from "../../stores/actions";
import { toolsApi } from "./toolsApi";
import {
  externalToolAdapter,
  workspaceAdapter
} from "../../stores/entityAdapters";
import { favoritesApi } from "./favoritesApi";
import { workspaceApi } from "./workspaceApi";
import { CURRENT_USER_TAG_TYPE, PROFILE_TAG_TYPE } from "../tagConstants";

export const userApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfileDetails: builder.query<UserDetailsResponse, string>({
      query: (profileId) => ({
        url: `userDetails/p/${profileId}`,
        method: "GET"
      })
    }),
    // to type the response, we need to use this syntax
    getProfile: builder.query<ProfileResponseFE, string>({
      query: (profileId) => ({
        url: `p/${profileId}`,
        method: "GET"
      }),
      async onQueryStarted(profileId, { dispatch, queryFulfilled }) {
        // // `onStart` sprofileIde-effect
        // dispatch(messageCreated("Fetching post..."));
        try {
          const { data } = await queryFulfilled;
          dispatch(
            userApi.util.upsertQueryData("getProfileDetails", profileId, {
              userDetails: data.profile.userDetails
            })
          );
          dispatch(
            toolsApi.util.upsertQueryData(
              "getProfileTools",
              profileId,
              externalToolAdapter.upsertMany(
                externalToolAdapter.getInitialState(),
                data.profile.tools || []
              )
            )
          );
          dispatch(
            favoritesApi.util.upsertQueryData(
              "getProfileFavoriteTools",
              profileId,
              externalToolAdapter.upsertMany(
                externalToolAdapter.getInitialState(),
                data.profile.favoriteTools || []
              )
            )
          );
          dispatch(
            workspaceApi.util.upsertQueryData(
              "getProfileWorkspaces",
              profileId,
              workspaceAdapter.upsertMany(
                workspaceAdapter.getInitialState(),
                data.profile.workspaces || []
              )
            )
          );
        } catch (err) {
          // `onError` side-effect
          dispatch(setErrorMessage(getErrorMessage(err, "Invalid profile")));
        }
      },
      providesTags: (result) =>
        result
          ? [{ type: PROFILE_TAG_TYPE, id: result.profile.userDetails._id }]
          : [PROFILE_TAG_TYPE]
    }),
    getCurrentUser: builder.query<CurrentUserResponseFE, void>({
      query: () => ({
        url: `user`,
        method: "GET"
      }),
      providesTags: [CURRENT_USER_TAG_TYPE]
    }),
    updateCurrentUser: builder.mutation<
      UpdateUserResponseFE,
      { updateFields: UpdateUserBody; successMessage: string }
    >({
      query: ({ updateFields }) => ({
        url: "user",
        method: "PATCH",
        body: updateFields
      }),
      async onQueryStarted({ successMessage }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage(successMessage));
        } catch {}
      },
      invalidatesTags: [CURRENT_USER_TAG_TYPE]
    })
  })
});

export const userApiEndpoints = userApi.endpoints;

export const {
  useGetProfileQuery,
  useUpdateCurrentUserMutation,
  useGetCurrentUserQuery,
  useGetProfileDetailsQuery
} = userApi;
