import type { RowUserInputDictType } from "@sharedTypes";
import useGetValidatedUserInputFromRow from "./useGetValidatedUserInputFromRow";
import type { MRT_Row } from "material-react-table";

const useHandleExportRows = () => {
  const getValidatedUserInputFromRow = useGetValidatedUserInputFromRow();
  const handleExportRows = (rows: MRT_Row<RowUserInputDictType>[]) => {
    return rows.map(({ original }) => {
      const { newUserInput } = getValidatedUserInputFromRow(original);
      return newUserInput;
    });
  };
  return handleExportRows;
};

export default useHandleExportRows;
