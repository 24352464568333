import type { CSSObject } from "@emotion/react";
import sidebarTheme, {
  closedLargeSidebarWidthInPx,
  closedSmallSidebarWidthInPx
} from "../../../globalTheme/sidebarTheme";
import { SMALL_DRAWER_WIDTH } from "../../../utilities/functions/formatHelpers";

export const easingAndDurationWhenOpening = {
  easing: sidebarTheme.transitions.easing.sharp,
  duration: sidebarTheme.transitions.duration.enteringScreen
};

export const easingAndDurationWhenClosing = {
  easing: sidebarTheme.transitions.easing.sharp,
  duration: sidebarTheme.transitions.duration.leavingScreen
};

export const sidebarOpenedMixin = (): CSSObject => ({
  width: SMALL_DRAWER_WIDTH,
  transition: sidebarTheme.transitions.create(
    "width",
    easingAndDurationWhenOpening
  ),
  overflowX: "hidden"
});

export const sidebarClosedMixin = (): CSSObject => ({
  transition: sidebarTheme.transitions.create(
    "width",
    easingAndDurationWhenClosing
  ),
  overflowX: "hidden",
  width: closedSmallSidebarWidthInPx,
  [sidebarTheme.breakpoints.up("sm")]: {
    width: closedLargeSidebarWidthInPx
  }
});
