import React, { createContext, useContext } from "react";
import useDraggable from "./useDraggable";
import { Edge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/dist/types/types";
import { DraggableState } from "./draggableConstants";
import type { TOrientation } from "@sharedTypes";

type TDraggableContexts = {
  dragHandleRef: React.RefObject<Element>;
  closestEdge: Edge | null;
  draggableState: DraggableState;
  ref: React.RefObject<HTMLDivElement>;
};

const UtilityDraggableContext = createContext<TDraggableContexts | undefined>(
  undefined
);

export const useUtilityDraggableContext = () => {
  const context = useContext(UtilityDraggableContext);
  if (context === undefined) {
    throw new Error(
      "useUtilityDraggableContext must be used within a UtilityDraggableContext"
    );
  }
  return context;
};

export const UtilityDraggableContextProvider = ({
  children,
  type,
  id,
  disabledDrag,
  index,
  disableDragHandle,
  orientation,
  data
}: {
  children: React.ReactNode;
  type: string;
  id: string;
  disabledDrag?: boolean;
  index: number;
  disableDragHandle?: boolean;
  orientation?: TOrientation;
  data?: Record<string, $TSAllowedAny>;
}) => {
  const { ref, dragHandleRef, closestEdge, draggableState } = useDraggable({
    disabledDrag,
    type,
    id,
    index,
    disableDragHandle,
    orientation,
    data
  });
  return (
    <UtilityDraggableContext.Provider
      value={{
        dragHandleRef,
        closestEdge,
        draggableState,
        ref
      }}
    >
      {children}
    </UtilityDraggableContext.Provider>
  );
};
