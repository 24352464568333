// FileUploadButton.tsx

import React, { useCallback, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import type { CircularProgressProps } from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useDropzone } from "react-dropzone";
import { Link, CircularProgress, FormControl, useTheme } from "@mui/material";
import type {
  UserInputDictType,
  UploadFileResponseFE,
  ButtonSizeTypes,
  TSetFormState,
  LeanFileDocument
} from "@sharedTypes";
// import Papa from "papaparse";
// import type { ParseResult, ParseError } from "papaparse";
import useMediaQuery from "@mui/material/useMediaQuery";
import FileDropdown from "./FileDropdown";
import type { AxiosProgressEvent } from "axios";
import { LabelAndInfo } from "./LabelAndInfo";
import { FREE_PLAN_FE, useAuthorizedTier } from "../settings/BillingCard";
import useToolflowAPI from "../ToolflowAPI/useToolflowAPI";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../features/layout/alerts/alertMessageSlice";
import { useAuth0Store } from "../hooks/useAuth0Store";
import {
  useDeleteFileMutation,
  useGetFilesQuery
} from "../ToolflowAPI/rtkRoutes/fileApi";
import { authenticatedApi } from "../ToolflowAPI/authenticatedAPI";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  const percentageValue = Math.round(props.value);
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <div className="flex flex-column align-i-center">
        <CircularProgress
          variant={percentageValue === 100 ? "indeterminate" : "determinate"}
          value={percentageValue}
        />
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          className="m-t-8px"
        >
          {percentageValue === 100 ? "Finalizing" : `${percentageValue}%`}
        </Typography>
      </div>
    </Box>
  );
}

const FileUploadButton = ({
  name,
  setFormState,
  formState,
  disabled,
  size = "small",
  fullWidth,
  description,
  disableLabelAndInfo,
  useIds,
  id = ""
}: {
  name: string;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  disabled?: boolean;
  size?: ButtonSizeTypes;
  fullWidth?: boolean;
  description?: string;
  disableLabelAndInfo?: boolean;
  useIds?: boolean;
  id?: string;
}) => {
  useGetFilesQuery();
  const fileData = formState[useIds ? id : name] as LeanFileDocument;
  const { uploadFile, cancelUpload } = useToolflowAPI();
  const [dialogOpen, setDialogOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [compressFileCTA, setCompressFileCTA] = useState(false);
  const [currentFile, setCurrentFile] = useState<LeanFileDocument | null>(
    fileData
  );
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const { isAuthenticated } = useAuth0Store();
  const authorizedTier = useAuthorizedTier();
  const reduxDispatch = useDispatch();
  const [deleteFile, { isLoading: deleteLoading }] = useDeleteFileMutation();

  const handleDeleteFile = async () => {
    if (currentFile?._id) {
      try {
        await deleteFile(currentFile._id)
          .unwrap()
          .then(() => {
            setCurrentFile(null);
          });
      } catch {
        reduxDispatch(setErrorMessage("Error deleting file"));
      }
    }
  };

  const deleteData = () => {
    const newFormState = { ...formState };
    delete newFormState[useIds ? id : name];
    setFormState(newFormState);
  };

  const handleFileSelect = useCallback(
    async (files: File[] | null) => {
      if (files && files.length > 0) {
        const file = files[0];
        const fileType = file.type;
        let audioDuration = 0; // in seconds
        setCompressFileCTA(false);
        if (
          !["mp3", "mp4", "mpeg", "mpga", "m4a", "wav", "webm"].some(
            (extension) => fileType.includes(extension)
          )
        ) {
          setProgress(0);
          reduxDispatch(setErrorMessage("Unsupported file type"));
          return;
        }
        const audio = document.createElement("audio");
        audio.src = URL.createObjectURL(file);

        // Wrap the duration load in a promise
        const loadAudioDuration = new Promise<void>((resolve) => {
          audio.addEventListener("loadedmetadata", () => {
            audioDuration = audio.duration;
            resolve(); // Resolve the promise once duration is loaded
          });
        });

        // Wait for audio duration to be loaded
        await loadAudioDuration;
        const mbLimit = authorizedTier === FREE_PLAN_FE ? 100 : 500;
        if (file.size > mbLimit * 1024 * 1024) {
          setProgress(0);
          setCompressFileCTA(true);
          reduxDispatch(
            setErrorMessage(
              `File too big. Please keep files below ${mbLimit}MB.${
                authorizedTier === FREE_PLAN_FE
                  ? " Upgrade to a paid plan for larger file sizes."
                  : ""
              }`
            )
          );
          return;
        }

        setLoading(true);
        const parseResponse = (resp: UploadFileResponseFE) => {
          setLoading(false);
          reduxDispatch(authenticatedApi.util.invalidateTags(["File"]));
          setCurrentFile(resp.savedFile);
          setProgress(0);
        };
        const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
          try {
            if (progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percentCompleted);
            }
          } catch (error) {
            console.error("Error in onUploadProgress:", error);
            setProgress(0);
            reduxDispatch(setErrorMessage("Error on upload"));
          }
        };
        await uploadFile(
          { file, duration: audioDuration },
          parseResponse,
          () => setLoading(false),
          onUploadProgress
        );
      }
    },
    [setCurrentFile, name]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: handleFileSelect
  });

  const reset = () => {
    cancelUpload();
    setDialogOpen(false);
    deleteData();
    setTimeout(() => setCurrentFile(null), 300);
  };

  const save = () => {
    // save is disabled if currentFile doesn't exist
    if (currentFile) {
      setFormState({ ...formState, [useIds ? id : name]: { ...currentFile } });
    }
    setDialogOpen(false);
  };

  return (
    <>
      <Dialog open={dialogOpen} fullScreen={fullScreen} fullWidth maxWidth="sm">
        <DialogTitle>
          <span className="flex align-i-center justify-space-between">
            <span>{name}</span>
            <div className="flex align-i-center">
              <Button
                style={{ zIndex: 1302 }}
                className="m-r-8px"
                onClick={reset}
              >
                {/* need zIndex for trashcan in utilitymapper */}
                Cancel
              </Button>
              <Button
                style={{ zIndex: 1302 }}
                onClick={save}
                variant="contained"
                disabled={!currentFile}
              >
                Select
              </Button>
            </div>
          </span>
        </DialogTitle>
        <DialogContent>
          {loading || deleteLoading ? (
            <div className="flex align-i-center justify-center">
              <CircularProgressWithLabel value={progress} />
            </div>
          ) : (
            <>
              <div className="flex align-i-center justify-space-between">
                <FileDropdown
                  action={setCurrentFile}
                  currentFile={currentFile}
                />
                {!!currentFile && (
                  <FormControl margin="normal">
                    <Button
                      onClick={handleDeleteFile}
                      style={{ zIndex: 1302 }}
                      color="error"
                      className="m-l-8px"
                    >
                      Delete from system
                    </Button>
                  </FormControl>
                )}
              </div>
              {!!currentFile ? (
                <div>
                  {/* {showMapper && (
                <UtilityMapper
                draggableOptions={options}
                draggableOptionsName="Inputs"
                setCurrentFile={setCurrentFile}
                file={currentFile as FileData}
                />
              )} */}
                </div>
              ) : (
                <>
                  <div
                    {...getRootProps()}
                    style={{
                      border: "2px dashed #888",
                      padding: "32px",
                      borderRadius: "8px",
                      marginBottom: 8,
                      cursor: "pointer"
                    }}
                    onClick={open}
                  >
                    <input {...getInputProps()} />
                    <Typography className="m-r-16px" align="center">
                      Drag &apos;n&apos; drop a file here, or click to select a
                      local file
                    </Typography>
                  </div>
                </>
              )}
              {compressFileCTA && (
                <Typography variant="caption">
                  File too big? Use a tool like{" "}
                  <Link href="https://www.freeconvert.com/" target="_blank">
                    FreeConvert
                  </Link>
                  . Toolflow does not control this tool, so please do your
                  research first.
                </Typography>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
      {!disableLabelAndInfo && <LabelAndInfo name={name} info={description} />}
      <Button
        fullWidth={fullWidth}
        variant="outlined"
        size={size}
        onClick={() => setDialogOpen(true)}
        disabled={disabled || !isAuthenticated}
      >
        {currentFile ? `Change ${currentFile.fileName}` : `Upload`}
      </Button>
    </>
  );
};

export default FileUploadButton;
