import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import SearchToolResponseComponent from "./SearchToolResponseComponent";

export default Node.create({
  name: "searchToolResponseNode",

  group: "block",
  content: "inline*",
  draggable: true,
  selectable: false, // needed so that we don't delete it when typing things if you click the tool

  atom: true,

  addAttributes() {
    return {
      toolIds: {
        default: []
      }
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "tip-tap-search-tool-response-component",
      mergeAttributes(HTMLAttributes, { "data-type": "draggable-item" })
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SearchToolResponseComponent);
  }
});
