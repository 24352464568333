import type { CustomToolInputFieldTypes } from "@sharedTypes";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";

const useChangeInputTypeToolbuilder = () => {
  const { dispatch } = useToolbuilderContext();
  const changeInputType = (type: CustomToolInputFieldTypes, index: number) => {
    dispatch({
      type: "UPDATE_TOOL_INPUT_FIELD_TYPE",
      index,
      value: type
    });
  };

  return changeInputType;
};

export default useChangeInputTypeToolbuilder;
