import React from "react";
import { CardContent, CardHeader, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomToolInputFieldToolbuilderProvider from "./customToolInputFields/CustomToolInputFieldToolbuilderProvider";

function ToolInputDrawer({ handleClose }: { handleClose: () => void }) {
  return (
    <>
      <CardHeader
        title="User Inputs"
        action={
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <CustomToolInputFieldToolbuilderProvider />
      </CardContent>
    </>
  );
}

export default ToolInputDrawer;
