import { useEffect } from "react";

const useUpdateIntercomOnPageChange = () => {
  // we need to update Intercom every time the page changes
  // if no user data changes, then send an updated last request at
  useEffect(() => {
    window.Intercom("update", {
      api_base: process.env.REACT_APP_INTERCOM_API_BASE,
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      last_request_at: new Date().getTime() / 1000
    });
  }, [location.pathname]);
};

export default useUpdateIntercomOnPageChange;
