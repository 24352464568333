import React from "react";
import { useSelector } from "react-redux";
import { selectAllSnippets } from "../../ToolflowAPI/rtkRoutes/selectors/snippetsSelectors";
import {
  List,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem
} from "@mui/material";
import iconDict from "../../utilities/constants/iconDict";
import { CREATE_USER_INPUT_LABEL } from "../../utilities/Inputs/constants";
import { Add } from "@mui/icons-material";
import { usePromptEditorContext } from "./context/PromptEditorContext";

const PromptMenu = () => {
  const snippets = useSelector(selectAllSnippets);

  const {
    contextMenu,
    setContextMenu,
    handleToolInputFieldClick,
    menuIndex,
    setOpenInputModal,
    typedChars,
    handleMenuClick,
    menuFilteredFields
  } = usePromptEditorContext();

  const openInput = () => {
    setOpenInputModal(true);
  };

  return (
    <Menu
      variant="menu"
      disableEnforceFocus
      disableRestoreFocus
      open={contextMenu !== null}
      onClose={() => setContextMenu(null)}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
    >
      <List disablePadding dense>
        {menuFilteredFields.length > 0 && (
          <ListSubheader>Add inputs</ListSubheader>
        )}
        {menuFilteredFields.map((f, k) => (
          <MenuItem
            onClick={() => handleToolInputFieldClick(f.name)}
            key={k}
            selected={k === menuIndex}
            dense
          >
            <ListItemIcon>{iconDict[f.type]}</ListItemIcon>
            <ListItemText>{f.name}</ListItemText>
          </MenuItem>
        ))}
        <ListSubheader>Actions</ListSubheader>
        <MenuItem
          onClick={openInput}
          selected={menuIndex === menuFilteredFields.length}
          dense
        >
          <ListItemIcon>{<Add />}</ListItemIcon>
          <ListItemText>
            {typedChars.length > 0
              ? `${CREATE_USER_INPUT_LABEL}: ${typedChars}`
              : CREATE_USER_INPUT_LABEL}
          </ListItemText>
        </MenuItem>
        {snippets.length > 0 && (
          <>
            <ListSubheader>Snippets</ListSubheader>
            {snippets.map((snippet, idx) => (
              <MenuItem
                onClick={() => handleMenuClick(snippet.snippetValue)}
                key={snippet._id}
                selected={menuIndex === menuFilteredFields.length + idx + 1}
                dense
              >
                {/* <ListItemIcon>{iconDict.text}</ListItemIcon> */}
                <ListItemText>{snippet.name}</ListItemText>
              </MenuItem>
            ))}
          </>
        )}
      </List>

      {/* ... other menu items ... */}
    </Menu>
  );
};

export default PromptMenu;
