import { NodeViewWrapper, type NodeViewProps } from "@tiptap/react";
import React, { useEffect, useMemo } from "react";
import { Chip } from "@mui/material";
import useWorkspaceInputs from "../../features/workstation/hooks/useWorkspaceInputs";

const InputViewerComponent = (props: NodeViewProps) => {
  const inputs = useWorkspaceInputs();

  const input = useMemo(() => {
    return inputs.filter((i) => i.id === props.node.attrs.inputId)[0];
  }, [props.node.attrs.inputId, inputs]);

  useEffect(() => {
    // switched from timer to microtask because it is cleaner
    // added an empty catch block because we get an out of range error in strict mode
    queueMicrotask(() => {
      try {
        if (!!input) {
          props.updateAttributes({
            textValue: input.value,
            name: input.name
          });
        } else {
          props.deleteNode(); // when an input gets deleted (like if the tool was deleted), we want to delete the inputs
        }
      } catch {}
    });
  }, [input?.name, input?.value]);

  return (
    <NodeViewWrapper className="inline-flex tip-tap-input-component dontTriggerBubble">
      {input && (
        <Chip
          label={input.name}
          contentEditable={false}
          onDelete={props.deleteNode}
          color="success"
          className="m-v-8px negativeTabIndex"
          size="small"
          tabIndex={-1} // we do this so that you can tab between fields more easily
        />
      )}
    </NodeViewWrapper>
  );
};

export default InputViewerComponent;
