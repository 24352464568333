import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import ToolViewerComponent from "./ToolViewerComponent";

export default Node.create({
  name: "toolComponent",

  group: "block",
  content: "inline*",
  draggable: true,
  selectable: false, // needed so that we don't delete it when typing things if you click the tool

  atom: true,

  addAttributes() {
    return {
      hasLoaded: {
        default: false // we need to set a has loaded, so that we autofocus on load, but not subsequent rerenders
      },
      toolId: {
        default: 0
      },
      userInput: {
        default: {}
      },
      componentId: {
        default: ""
      },
      highlightedText: {
        default: ""
      },
      selectTo: {
        default: 0
      },
      selectFrom: {
        default: 0
      },
      tvrId: {
        default: ""
      },
      toolOutput: {
        default: null
      },
      percentCompleted: {
        default: 0
      }
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "tip-tap-tool-component",
      mergeAttributes(HTMLAttributes, { "data-type": "draggable-item" })
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ToolViewerComponent);
  }
});
