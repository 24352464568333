import React from "react";
import { Typography, Paper } from "@mui/material";
import blockHeaderIconDict from "./blockHeaderIconDict";
import { useBlockContext } from "../BlockContext";
import BlockAlert from "./BlockAlert";
import useSetBlockMessage from "./useSetBlockMessage";
import DeleteNodeButton from "./DeleteNodeButton";

function BlockPaperHeader() {
  const { blockData: data } = useBlockContext();

  const { type, label } = data;

  useSetBlockMessage();

  return (
    <Paper>
      <div className="flex align-i-center justify-space-between p-16px min-w-300px">
        <div
          className="align-i-center flex w-100-percent"
          style={{ maxWidth: "88%" }}
        >
          {blockHeaderIconDict[type]}
          <Typography variant="h6" className="m-l-16px" noWrap>
            {label}
          </Typography>
        </div>
        <DeleteNodeButton />
      </div>
      <BlockAlert />
    </Paper>
  );
}

export default BlockPaperHeader;
