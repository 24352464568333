import type { RowUserInputDictType, TSelectedTool } from "@sharedTypes";
import getDefaultRowData from "./getDefaultRowData";

const getDefaultRow = (selectedTool: TSelectedTool) => {
  const dataToReturn: RowUserInputDictType = getDefaultRowData();
  (selectedTool?.main.toolInputFields || []).forEach((c) => {
    dataToReturn[c.name] = "";
  });
  (selectedTool?.main.toolOutputFields || []).forEach((c) => {
    dataToReturn[c.name] = "";
  });
  return dataToReturn;
};

export default getDefaultRow;
