import { useEffect, useRef } from "react";
import { useViewport, type ReactFlowInstance } from "reactflow";
import { useToolbuilderContext } from "../context/ToolBuilderContext";

const useViewports = (reactFlowInstance: ReactFlowInstance) => {
  const { dispatch, state } = useToolbuilderContext();
  const { tempViewport } = state;
  const { setViewport } = reactFlowInstance;
  const viewport = useViewport();
  const latestViewport = useRef(viewport);

  useEffect(() => {
    latestViewport.current = viewport;
  }, [viewport]);

  // we do this so that we can save and stay at the same zoom level
  // also when we switch tabs in the same tool
  useEffect(() => {
    return () => {
      if (!tempViewport) {
        dispatch({
          type: "SET_TEMP_VIEWPORT",
          viewport: latestViewport.current
        });
      }
    };
  }, []);

  // we do this because on RECEIVE_TOOL_VERSIONS
  // we reset tempViewport to null.
  // This is the default view
  // Useful for switching between tools with different viewports
  useEffect(() => {
    if (!tempViewport) {
      setViewport({ x: 0, y: 0, zoom: 0.75 });
    }
  }, [tempViewport]);
};

export default useViewports;
