import type { TMarketplaceEntity } from "@sharedTypes";
import React, { FC } from "react";
import MarketplaceCard from "./MarketplaceCard";
import useNavigateToToolById from "../../../hooks/useNavigateToToolById";
import useNavigateToWorkflowById from "../../../hooks/useNavigateToWorkflowById";

interface IMarketplaceCardProps {
  entity: TMarketplaceEntity;
}
const MarketplaceCardContainer: FC<IMarketplaceCardProps> = ({ entity }) => {
  const toolClick = useNavigateToToolById(entity.id);
  const workflowClick = useNavigateToWorkflowById(entity.id);
  return (
    <MarketplaceCard
      entity={entity}
      onClick={entity.type === "tool" ? toolClick : workflowClick}
    />
  );
};

export default MarketplaceCardContainer;
