import { Typography } from "@mui/material";
import React from "react";
import type { TMarketplaceEntity } from "@sharedTypes";
import ToggleFavorite from "../../../utilities/favorites/ToggleFavorite";

const MarketplaceCardHeader = ({ entity }: { entity: TMarketplaceEntity }) => {
  return (
    <div className="flex justify-space-between align-i-center">
      <Typography variant="h6">{entity.name}</Typography>
      <ToggleFavorite
        id={entity.id}
        type={entity.type}
        favorited={entity.userFavorited}
        creatorId={entity.userId}
        inMarketplace={entity.isFeatured}
      />
    </div>
  );
};

export default MarketplaceCardHeader;
