import React, { useState } from "react";
import { DateTime } from "luxon";
import {
  Collapse,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText
} from "@mui/material";
import { getToolVersionNameWithError } from "./functions/formatHelpers";
import ToolVersionResponseCard from "./ToolVersionResponseCard";
import CreditCostDetails from "./CreditCostDetails";
import type { PopulatedToolVersionResponseFE } from "@sharedTypes";

function ToolVersionResponseListButton({
  tvr,
  noDelete
}: Readonly<{
  tvr: PopulatedToolVersionResponseFE;
  noDelete?: boolean;
}>) {
  const [open, setOpen] = useState(false);
  try {
    const toolName = getToolVersionNameWithError(tvr);
    return (
      <>
        <Collapse in={!open}>
          <ListItemButton onClick={() => setOpen(!open)}>
            <ListItemText
              primary={toolName}
              secondary={DateTime.fromSeconds(tvr.updatedAt).toLocaleString(
                DateTime.DATETIME_MED
              )}
            />
            <ListItemSecondaryAction>
              <CreditCostDetails
                estimatedCreditCost={tvr.creditCost}
                className="m-l-8px"
              />
            </ListItemSecondaryAction>
          </ListItemButton>
        </Collapse>
        <Collapse in={open}>
          <ToolVersionResponseCard
            tvr={tvr}
            noDelete={noDelete}
            handleClose={() => setOpen(false)}
            noEditor
          />
        </Collapse>
      </>
    );
  } catch {
    return null;
  }
}

export default ToolVersionResponseListButton;
