import React from "react";
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from "reactflow";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import type { EdgeProps } from "reactflow";

// import "./buttonedge.css";

function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = { color: "#673AB6" },
  markerEnd,
  data
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY: sourceY - 32,
    sourcePosition,
    targetX,
    targetY: targetY + 28,
    targetPosition
  });
  const { reactflowUtility } = useToolbuilderContext();
  const { deletable } = data;

  const { handleEdgeDelete: onDelete } = reactflowUtility;

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      {!!deletable && (
        <EdgeLabelRenderer>
          <IconButton
            style={{
              backgroundColor: "white",
              border: "1px solid grey",
              zIndex: 1301, // may want to figure out the zIndex situation at some point
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              // everything inside EdgeLabelRenderer has no pointer events by default
              // if you have an interactive element, set pointer-events: all
              pointerEvents: "all"
            }}
            className="nodrag nopan"
            onClick={() => onDelete(id)}
          >
            <Close />
          </IconButton>
        </EdgeLabelRenderer>
      )}
    </>
  );
}

export default CustomEdge;
