import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import React, { FC, useState } from "react";
import { useSaveSecretMutation } from "../../../../ToolflowAPI/rtkRoutes/secretApi";
import type { TSecretType } from "@sharedTypes";
import LoadingButton from "@mui/lab/LoadingButton";
import { Close } from "@mui/icons-material";

interface IAddLLMApiKeyDialogBaseProps {
  open: boolean;
  closeDialog: () => void;
  secretType: TSecretType;
  secretName: string;
}

interface IAddLLMApiKeyDialogButtonProps {
  secretType: TSecretType;
  secretName: string;
}
const AddLLMApiKeyDialogBase: FC<IAddLLMApiKeyDialogBaseProps> = ({
  open,
  closeDialog,
  secretType,
  secretName
}) => {
  const [val, setVal] = useState("");
  const [saveSecret, { isLoading: saveLoading }] = useSaveSecretMutation();
  const handleClose = () => {
    closeDialog();
  };
  const handleSaveSecret = async () => {
    await saveSecret({ secretValue: val, type: secretType })
      .unwrap()
      .then(() => {
        handleClose();
      });
  };

  const createApiKeyString = `Create ${secretName} API Key`;
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <div className="p-16px">
        <DialogTitle className="flex align-i-center justify-space-between">
          <Typography variant="h7" className="flex-grow-1" noWrap>
            {createApiKeyString}
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth className="m-t-16px">
            <TextField
              label={"API Key"}
              value={val}
              size="medium"
              onChange={(e) => setVal(e.target.value)}
              className="m-r-8px"
              disabled={saveLoading}
            />
          </FormControl>
        </DialogContent>
        <DialogActions className="p-h-32px">
          <LoadingButton
            size="large"
            variant="contained"
            loading={saveLoading}
            disabled={!val || saveLoading}
            onClick={() => handleSaveSecret()}
          >
            Save API key
          </LoadingButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};

const AddLLMApiKeyDialogButton: FC<IAddLLMApiKeyDialogButtonProps> = ({
  secretType,
  secretName
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <AddLLMApiKeyDialogBase
        open={open}
        closeDialog={() => setOpen(false)}
        secretType={secretType}
        secretName={secretName}
      />

      <LoadingButton onClick={() => setOpen(true)} variant="text">
        Add Api key
      </LoadingButton>
    </>
  );
};
export default AddLLMApiKeyDialogButton;
