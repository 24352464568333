import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import { CardHeader } from "@mui/material";
import ManageFiles from "./ManageFiles";

function FilesCard() {
  return (
    <Box mt={4}>
      <Card variant="outlined">
        <CardHeader title="Files" />
        <CardContent>
          <ManageFiles />
        </CardContent>
      </Card>
    </Box>
  );
}

export default FilesCard;
