import type { UserInputResponseWithHandledError } from "@sharedTypes";
import updateSheetDataWithSingleValue from "./updateSheetDataWithSingleValue";
import { TSetSheetData, TSheetDataRef } from "../../sheetTypes";

const setFormState = (
  componentId: string,
  fieldName: string,
  newValue: UserInputResponseWithHandledError,
  sheetDataRef: TSheetDataRef,
  setSheetData: TSetSheetData
) => {
  const rowIndex = sheetDataRef.current.findIndex(
    (rowData) => rowData.componentId === componentId
  );
  if (rowIndex > -1) {
    updateSheetDataWithSingleValue(
      rowIndex,
      fieldName,
      newValue,
      sheetDataRef,
      setSheetData
    );
  }
};

export default setFormState;
