import { useEffect, useState } from "react";
import type { RowUserInputDictType } from "@sharedTypes";

const useRowsRunning = ({
  sheetData
}: {
  sheetData: RowUserInputDictType[];
}) => {
  const [rowsRunning, setRowsRunning] = useState(false);

  useEffect(() => {
    const anyLoading = sheetData.some((row) => row.rowLoading);
    if (rowsRunning && !anyLoading) {
      setRowsRunning(false);
    }
  }, [JSON.stringify(sheetData)]);

  return { rowsRunning, setRowsRunning };
};

export default useRowsRunning;
