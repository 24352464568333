import type { ValidatedInput, ValidatedInputTypes } from "@sharedTypes";
import {
  CHECKBOX,
  FILE_UPLOAD
} from "../../../utilities/Inputs/inputConstants";

const getFilteredFields = (toolInputFields: ValidatedInput[]) => {
  return toolInputFields.filter(
    (i) => !([FILE_UPLOAD, CHECKBOX] as ValidatedInputTypes[]).includes(i.type)
  );
};

export default getFilteredFields;
