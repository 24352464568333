import { ThemeProvider } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";
import SendMessageOnDirtyProvider from "../../../SendMessageOnDirtyProvider";
import WorkspaceFormProvider from "../../../WorkspaceFormProvider";
import WorkspaceHeader from "../../../WorkspaceHeader";
import WorkspaceSetToolsProvider from "../../../WorkspaceSetToolsProvider";
import WorkstationDrawerContainer from "../../../leftSideDrawer/WorkstationDrawerContainer";
import workspaceTheme from "../../../theme/workspaceTheme";
import Asset from "../asset/Asset";

import { parsedClassNames } from "../../utils/parsedClassNames";
import styles from "./newWorkspacePage.module.css";

const cx = parsedClassNames.bind(styles);

function NewWorkspacePage() {
  return (
    <ThemeProvider theme={workspaceTheme}>
      <WorkspaceFormProvider>
        <WorkspaceSetToolsProvider>
          <SendMessageOnDirtyProvider>
            <WorkspaceHeader />
            <Grid2
              container
              xs={12}
              spacing={"16px"}
              disableEqualOverflow
              sx={{
                minWidth: { xs: "100%", md: 900 }
              }}
              className={cx(
                "oflow-hidden h-100-percent",
                styles["grid-wrapper"]
              )}
            >
              <Grid2 xs={6} lg={5} xl={4} className="h-100-percent">
                <WorkstationDrawerContainer />
              </Grid2>
              <Grid2 xs={6} lg={7} xl={8} className="h-100-percent">
                <Asset />
              </Grid2>
            </Grid2>
          </SendMessageOnDirtyProvider>
        </WorkspaceSetToolsProvider>
      </WorkspaceFormProvider>
    </ThemeProvider>
  );
}

export default NewWorkspacePage;
