import type { CustomToolInputField } from "@sharedTypes";
import useGetSelectedStepInputsToCollect from "./useGetSelectedStepInputToCollect";
import useSetToolInputFieldsWorkflow from "./useSetToolInputFieldsWorkflow";
import { isInputWithOptions } from "../../../../../../utilities/Inputs/constants";
import { getToolInputFieldIndexById } from "../../../../../../utilities/Inputs/inputBuilders/hooks/useGetToolInputFieldIndexById";

const useDeleteOptionWorkflow = () => {
  const inputsToCollect = useGetSelectedStepInputsToCollect();
  const setToolInputFields = useSetToolInputFieldsWorkflow();

  const handleDeleteOption = (
    toolInputField: CustomToolInputField | null,
    optionIndex: number
  ) => {
    if (!isInputWithOptions(toolInputField)) {
      console.log("Input field does not support options.");
      return;
    }
    const index = getToolInputFieldIndexById(
      inputsToCollect,
      toolInputField.id
    );
    const options = toolInputField.options || [];
    const newOptions = [
      ...options.slice(0, optionIndex),
      ...options.slice(optionIndex + 1)
    ];
    const updatedToolInputField = {
      ...toolInputField,
      options: newOptions
    };
    const updatedToolInputFields = [...inputsToCollect];
    updatedToolInputFields[index] = updatedToolInputField;
    setToolInputFields(updatedToolInputFields);
  };
  return handleDeleteOption;
};

export default useDeleteOptionWorkflow;
