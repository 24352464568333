import {
  setInitialObject,
  setWorkspaceId
} from "../../features/workstation/workspaceSlice";
import { useDispatch } from "react-redux";
import useToolflowNavigate from "./useToolflowNavigate";
import type { ValidateType } from "@sharedTypes";
import {
  getTVRLink,
  getToolLink,
  getWorkflowLink,
  getWorkspaceLink
} from "../functions/formatHelpers";
// import { getPublicToolUrl, getPublicWorkspaceUrl, getTVRUrl } fro

const alternativeUrlDict = {
  workspace: getWorkspaceLink,
  tool: getToolLink,
  workflow: getWorkflowLink,
  toolVersionResponse: getTVRLink
};

const useNavigateToWorkspaceWithDefaultObject = ({
  id,
  type
}: {
  id: string;
  type: ValidateType;
}) => {
  const navigate = useToolflowNavigate();
  const dispatch = useDispatch();

  const navigateToWorkspace = (e?: React.SyntheticEvent<Element, Event>) => {
    if (type === "workspace") {
      dispatch(setWorkspaceId(id));
    } else {
      dispatch(setWorkspaceId(""));
      dispatch(setInitialObject({ type, id }));
    }
    navigate("/", e, {
      replace: true,
      alternativeUrl: alternativeUrlDict[type](id)
    });
  };

  return navigateToWorkspace;
};

export default useNavigateToWorkspaceWithDefaultObject;
