import React from "react";
import { useCopySnippetMutation } from "../ToolflowAPI/rtkRoutes/snippetsApi";
import RemixButton from "../features/layout/icons/RemixButton";
import { RiGitForkLine } from "@remixicon/react";

function CopySnippetButton({ id }: { id: string }) {
  const [copySnippet, { isLoading }] = useCopySnippetMutation();

  const handleCopySnippet = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    copySnippet(id);
  };
  return (
    <RemixButton
      text="Duplicate"
      icon={RiGitForkLine}
      onClick={handleCopySnippet}
      disabled={isLoading}
    />
  );
}

export default CopySnippetButton;
