import RemixButton from "../../../../../layout/icons/RemixButton";
import useWorkspaceInputs from "../../../../hooks/useWorkspaceInputs";
import useSetVisibleAsset from "../../../../leftSideDrawer/assetsTab/hooks/useSetVisibleAsset";
import useGetAssetId from "../../../hooks/useGetAssetId";
import { ArrowUpIcon } from "../../../icons/icons";
import useGetCurrentAssetIndex from "../hooks/useGetAssetIndex";
import React from "react";

function MoveUpAnAssetButton() {
  const inputs = useWorkspaceInputs();
  const selectedAsset = useGetAssetId();
  const assetIndex = useGetCurrentAssetIndex();

  const isFirstAsset = inputs[0].id === selectedAsset;

  const setSelectedAsset = useSetVisibleAsset();

  const setSelectedAssetUp = () => {
    setSelectedAsset(inputs[assetIndex - 1].id);
  };

  return (
    <RemixButton
      icon={ArrowUpIcon}
      onClick={setSelectedAssetUp}
      disabled={isFirstAsset}
      providedColor={!isFirstAsset ? "black" : undefined}
    />
  );
}

export default MoveUpAnAssetButton;
