import React, { FC, useState } from "react";
import type { ValidateType } from "@sharedTypes";
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { RiCloseLine } from "@remixicon/react";
import { useUpdateToolMutation } from "../../../ToolflowAPI/rtkRoutes/toolsApi";
import { useUpdateWorkflowMutation } from "../../../ToolflowAPI/rtkRoutes/workflowApi";

interface IRenameHomePageEntityFormProps {
  initialName: string;
  id: string;
  entityType: ValidateType;
  completeAction: (e: React.SyntheticEvent<Element, Event>) => void;
  mainId?: string;
}
const RenameHomePageEntityForm: FC<IRenameHomePageEntityFormProps> = ({
  initialName,
  id,
  entityType,
  completeAction,
  mainId
}) => {
  const [updateTool, { isLoading: isToolLoading }] = useUpdateToolMutation();
  const [updateWorkflow, { isLoading: isWorkflowLoading }] =
    useUpdateWorkflowMutation();
  const [name, setName] = useState(initialName);
  const handleResetName = (e: React.MouseEvent) => {
    e.stopPropagation();
    setName(initialName);
    completeAction(e);
  };

  const updateName = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      if (entityType === "tool") {
        await updateTool({ body: { name }, toolId: id })
          .unwrap()
          .then(() => {
            completeAction(e);
          });
      }
      if (entityType === "workflow") {
        await updateWorkflow({ body: { name, main: mainId }, workflowId: id })
          .unwrap()
          .then(() => {
            completeAction(e);
          });
      }
    } catch {
      completeAction(e);
    }
  };

  const isLoading = isToolLoading || isWorkflowLoading;
  return (
    <>
      <FormControl
        fullWidth
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <TextField
          onClick={(e) => {
            e.stopPropagation();
          }}
          hiddenLabel
          variant="outlined"
          size="medium"
          value={name}
          margin="dense"
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            event.stopPropagation();
            setName(event.target.value);
          }}
          InputProps={{
            endAdornment: name ? (
              <InputAdornment position="end">
                <IconButton onClick={() => setName("")}>
                  <RiCloseLine size={20} />
                </IconButton>
              </InputAdornment>
            ) : null
          }}
        />
      </FormControl>
      <FormControl
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="flex flex-row m-t-32px"
        fullWidth
      >
        <LoadingButton
          aria-label="edit-name"
          onClick={handleResetName}
          size="large"
          variant="text"
          className="m-l-auto"
          fullWidth={false}
          disabled={isLoading}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          aria-label="edit-name"
          onClick={updateName}
          size="large"
          className="m-l-8px"
          variant="contained"
          loading={isLoading}
          fullWidth={false}
        >
          Save
        </LoadingButton>
      </FormControl>
    </>
  );
};
export default RenameHomePageEntityForm;
