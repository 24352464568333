import React from "react";
import UpdateTextFieldBase from "../../../utilities/UpdateTextFieldBase";
import { SNIPPET_NAME_FIELD_LABEL } from "./snippetFields";
import { SNIPPET_NAME_REQUIRED_ERROR } from "./SnippetFormProvider";

const SnippetNameTextField = () => {
  return (
    <UpdateTextFieldBase
      fullWidth
      size="medium"
      className="m-t-16px max-w-640px"
      fieldLabel={SNIPPET_NAME_FIELD_LABEL}
      registerOptions={{ required: SNIPPET_NAME_REQUIRED_ERROR }}
      showErrors
    />
  );
};

export default SnippetNameTextField;
