import React, { useEffect } from "react";
import type { TUseCase } from "@sharedTypes";
import useCategoryTags, { THandleChangeTags } from "./hooks/useCategoryTags";
import { useCaseTags } from "./constants";
import AssignTags from "./AssignTags";

const AssignUseCasesNoSaveBase = ({
  initialSelectedTags,
  handleUpdate
}: {
  initialSelectedTags: string[];
  handleUpdate: (s: TUseCase[]) => void;
}) => {
  const { handleChange, selectedTags, chipTags, handleRemoveSelectedTag } =
    useCategoryTags({
      initialTags: useCaseTags,
      initialSelectedTags
    });

  const handleChangeTags: THandleChangeTags = ({ event, tagToBeRemoved }) => {
    if (tagToBeRemoved) {
      handleRemoveSelectedTag(tagToBeRemoved);
    } else if (event) {
      handleChange(event);
    }
  };
  useEffect(() => {
    handleUpdate(selectedTags as TUseCase[]);
  }, [selectedTags]);
  return (
    <AssignTags
      initialTags={useCaseTags}
      selectedTags={selectedTags}
      chipTags={chipTags}
      handleChangeTags={handleChangeTags}
      disableHelperText={false}
      helperText="These use cases will help users find your tool in marketplace."
      label="Add Use Cases to Tool"
      placeholder="Select Use Cases"
    />
  );
};

export default AssignUseCasesNoSaveBase;
