import useGetToolSelectorOptions from "./useGetToolSelectorOptions";
import useGetWorkflowSelectorOptions from "./useGetWorkflowSelectorOptions";

const useGetAllEntityOptions = ({
  disableWorkflowsOptions
}: {
  disableWorkflowsOptions?: boolean;
}) => {
  const toolOptions = useGetToolSelectorOptions();
  const workflowOptions = useGetWorkflowSelectorOptions();

  if (disableWorkflowsOptions) {
    return toolOptions;
  }

  return [...toolOptions, ...workflowOptions];
};

export default useGetAllEntityOptions;
