import type {
  ToolflowConstantDict,
  ToolflowConstantResponse
} from "@sharedTypes";
import { authenticatedApi } from "../authenticatedAPI";
import { openAppWarning } from "../../features/layout/sidebar/appWarningSlice";
import { useAuth0Store } from "../../hooks/useAuth0Store";

export const DEFAULT_TOOLFLOW_CONSTANTS_FE: ToolflowConstantDict = {
  initialWorkspaceContent: "",
  appWarning: "",
  freeCredits: 100,
  proCredits: 1500,
  organizationCredits: 5000,
  proPrice: 99,
  organizationPrice: 349,
  maximumAvailableToolRunsForFreeTier: 5,
  maxAssistantRunsForFreeTier: 10
};

export const toolflowConstantApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getToolflowConstants: builder.query<ToolflowConstantResponse, void>({
      query: () => ({
        url: `toolflowConstant`,
        method: "GET"
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data?.toolflowConstants.appWarning) {
            dispatch(openAppWarning());
          }
        } catch {}
      }
    })
  })
});

export const apiKeyApiEndpoints = toolflowConstantApi.endpoints;

const { useGetToolflowConstantsQuery: useGetToolflowConstantsQueryInner } =
  toolflowConstantApi;

export const useGetToolflowConstantsQuery = () => {
  const { isLoading } = useAuth0Store();
  const {
    data = { toolflowConstants: DEFAULT_TOOLFLOW_CONSTANTS_FE },
    ...rest
  } = useGetToolflowConstantsQueryInner(undefined, { skip: isLoading });
  return { data, ...rest };
};
