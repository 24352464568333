import { useNavigate } from "react-router-dom";
import { useLoginBackToPage } from "../authHelpers";
import { TOOL, WORKSPACE } from "../constants/constants";
import { useAuth0Store } from "../../hooks/useAuth0Store";
import { useCopyWorkspaceMutation } from "../../ToolflowAPI/rtkRoutes/workspaceApi";
import { useCopyToolMutation } from "../../ToolflowAPI/rtkRoutes/toolsApi";
import {
  EXTERNAL_WORKSPACE_BASE_ROUTE_PATH,
  TOOLBUILDER_BASE_ROUTE_PATH
} from "../../navigation/routePaths";

// Custom Hook
export const useDuplicateObject = () => {
  const { loginBackToPage } = useLoginBackToPage();
  const { isAuthenticated } = useAuth0Store();
  const navigate = useNavigate();
  const [copyTool, { isLoading }] = useCopyToolMutation();
  const [copyWorkspace, { isLoading: workspaceLoading }] =
    useCopyWorkspaceMutation();
  const loading = isLoading || workspaceLoading;

  const executeCopyWithIdAndType = async (copyId: string, copyType: string) => {
    if (copyId) {
      if (copyType === TOOL) {
        await copyTool(copyId)
          .unwrap()
          .then((resp) => {
            navigate(`${TOOLBUILDER_BASE_ROUTE_PATH}${resp.toolId}`);
          });
      } else if (copyType === WORKSPACE) {
        await copyWorkspace(copyId)
          .unwrap()
          .then((resp) => {
            navigate(
              `${EXTERNAL_WORKSPACE_BASE_ROUTE_PATH}${resp.workspaceId}`
            );
          });
      }
    }
  };

  // gets called in App
  // may want to check security of this at some point
  const duplicateObject = (id: string, type: string) => {
    if (!isAuthenticated) {
      localStorage.setItem("justAuthenticated", "true");
      localStorage.setItem(
        "callbackFunction",
        JSON.stringify({ function: "handleCopy", id, type })
      );
      loginBackToPage();
    } else {
      executeCopyWithIdAndType(id, type);
    }
  };

  return { duplicateObject, loading };
};
