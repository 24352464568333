import { useMemo } from "react";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";
import useGetCurrentUser from "../../hooks/useGetCurrentUser";
import { useSelector } from "react-redux";
import { selectAllProfileFavoriteTools } from "../../ToolflowAPI/rtkRoutes/selectors/favoriteToolSelectors";
import formatToolsForAutocomplete from "../functions/formatToolsForAutocomplete";
import { selectCurrentUserProfileTools } from "../../ToolflowAPI/rtkRoutes/selectors/dynamicSelectors";

const useFilteredToolsForAutocomplete = () => {
  const { muiTipTapProps } = useMuiTipTapContext();
  const { disableTools } = muiTipTapProps;
  const user = useGetCurrentUser();
  const userId = user?._id || "";
  const favoriteTools = useSelector(selectAllProfileFavoriteTools(userId));
  const { tools: stateTools } = useSelector(selectCurrentUserProfileTools);
  return useMemo(() => {
    if (!disableTools) {
      return formatToolsForAutocomplete(favoriteTools).concat(
        formatToolsForAutocomplete(stateTools, true)
      );
    } else {
      return [];
    }
  }, [stateTools, favoriteTools]);
};

export default useFilteredToolsForAutocomplete;
