import { useCallback } from "react";
import { type NavigateOptions, useNavigate } from "react-router-dom";

const useToolflowNavigate = () => {
  const navigate = useNavigate();
  const handleNavigate = useCallback(
    (
      route: string,
      event?: React.SyntheticEvent,
      options?: NavigateOptions & { alternativeUrl?: string }
    ) => {
      const { alternativeUrl, ...rest } = options || {};
      if (event) {
        const nativeEvent = event.nativeEvent as MouseEvent;
        try {
          // this is a little hacky to put it in a try / catch block
          // we do this for keyboard shortcuts on menus
          if (nativeEvent.metaKey || nativeEvent.ctrlKey) {
            // we only want to navigate to the alternative url if a new screen
            // is being added. For instance, this will send to a url where people
            // can preset the default object without passing redux
            window.open(alternativeUrl ? alternativeUrl : route, "_blank");
          } else {
            navigate(route, rest);
          }
        } catch {
          navigate(route, rest);
        }
      } else {
        navigate(route, rest);
      }
    },
    [navigate]
  );

  return handleNavigate;
};

export default useToolflowNavigate;
