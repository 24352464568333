import React from "react";
import EditorToolCard from "../utilities/editorToolCard/EditorToolCard";
import { EditorToolCardContextComponent } from "../utilities/editorToolCard/EditorToolCardContext";
import type {
  AttributesType,
  TToolOutput,
  UserInputDictType
} from "@sharedTypes";
import { useNodeViewContext } from "../utilities/contexts/NodeViewContext";
import EditorToolCardNodeWrapper from "./EditorToolCardNodeWrapper";
import useHandleCloseNode from "./hooks/editorToolCardHooks/useHandleCloseNode";
import useSetAttribute from "./hooks/editorToolCardHooks/useSetAttribute";
import useSetToolOutput from "./hooks/editorToolCardHooks/useSetToolOutput";
import useCurrentInputsRef from "../utilities/editorToolCard/hooks/useCurrentInputsRef";
import useSetInputInWorkspace from "./hooks/editorToolCardHooks/useInsertContentBeforeNode";

function EditorToolCardContainer() {
  const { node } = useNodeViewContext<AttributesType>();
  const {
    userInput,
    highlightedText,
    componentId,
    tvrId,
    percentCompleted,
    toolOutput,
    hasLoaded
  } = node.attrs;
  const handleClose = useHandleCloseNode();
  const setAttribute = useSetAttribute();
  const currentInputs = useCurrentInputsRef();
  const setToolOutputBase = (output: TToolOutput) =>
    setAttribute("toolOutput", output);
  const setToolOutput = useSetToolOutput({
    currentInputs,
    userInput,
    componentId,
    tvrId,
    setToolOutputBase
  });
  const setInputInWorkspace = useSetInputInWorkspace({
    componentId,
    currentInputs
  });

  const setToolOutputWithAddInput = (output: TToolOutput) => {
    setToolOutput(output);
    setInputInWorkspace(output);
  };

  const setPercentCompleted = (percent: number) => {
    setAttribute("percentCompleted", percent);
  };
  const setHighlightedText = (s: string) => setAttribute("highlightedText", s);
  const setHasLoaded = (v: boolean) => setAttribute("hasLoaded", v);
  const setUserInput = (uI: UserInputDictType) => setAttribute("userInput", uI);
  const setTVRId = (id: string) => setAttribute("tvrId", id);

  return (
    <EditorToolCardContextComponent
      userInput={userInput}
      highlightedText={highlightedText}
      componentId={componentId}
      tvrId={tvrId}
      percentCompleted={percentCompleted}
      toolOutput={toolOutput}
      hasLoaded={hasLoaded}
      handleClose={handleClose}
      setToolOutput={setToolOutputWithAddInput}
      setPercentCompleted={setPercentCompleted}
      setHighlightedText={setHighlightedText}
      setHasLoaded={setHasLoaded}
      setUserInput={setUserInput}
      setTVRId={setTVRId}
    >
      <EditorToolCardNodeWrapper>
        <EditorToolCard />
      </EditorToolCardNodeWrapper>
    </EditorToolCardContextComponent>
  );
}

export default EditorToolCardContainer;
