import type { DallEOutputType } from "@sharedTypes";
import type { Content } from "@tiptap/core";
import { v4 as uuidv4 } from "uuid";

export function getSearchResponseNode(toolIds: string[]) {
  return {
    type: "searchToolResponseNode",
    attrs: {
      toolIds
    }
  };
}
export default function getToolNodes(
  toolId: string,
  highlightedText = "",
  noParagraph?: boolean,
  userInput = {},
  componentId = uuidv4()
) {
  // TODO fix this
  let base: Content = [
    {
      type: "toolComponent",
      attrs: {
        highlightedText,
        toolId,
        toolOutput: null,
        tvrId: "",
        userInput,
        componentId
      }
    }
  ];
  if (!noParagraph) {
    base = base.concat({ type: "paragraph", attrs: { textAlign: "left" } });
  }
  return base;
}

export function getInputNodes(inputId: string) {
  return [
    {
      type: "inputComponent",
      attrs: {
        inputId
      }
    },
    {
      type: "text",
      text: " "
    }
  ];
}

export function getSnippetNodes(snippetId: string) {
  return [
    {
      type: "snippetChipComponent",
      attrs: {
        snippetId
      }
    },
    {
      type: "text",
      text: " "
    }
  ];
}

export function getOutputNodes(inputId: string) {
  return [
    {
      type: "textOutputComponent",
      attrs: {
        inputId
      }
    }
  ];
}

export function getTVRNodes(tvrId: string, noParagraph?: boolean) {
  // TODO fix this
  let base: Content = [
    { type: "toolResponseComponent", attrs: { tvrId, componentId: uuidv4() } }
  ];
  if (!noParagraph) {
    base = base.concat({ type: "paragraph", attrs: { textAlign: "left" } });
  }
  return base;
}

export function getImageNodes(images: DallEOutputType) {
  // TODO fix this
  const base: Content = images.map((i) => {
    return {
      type: "image",
      attrs: {
        src: i.url,
        aspectRatio: 1,
        alt: null,
        textAlign: "left",
        title: null,
        width: 300
      }
    };
  });
  return base;
}
