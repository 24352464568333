import { useMemo } from "react";
import useGetAllEntityOptions from "../../toolSelector/hooks/useGetAllEntityOptions";
import useGetUseCaseOptions from "../../toolSelector/hooks/useGetUseCaseOptions";
import {
  formatMarketplaceEntitiesForAutocomplete,
  formatUseCasesForAutocomplete
} from "../../../workspace/functions/formatEntitiesForAutocomplete";

const useGetFilteredOptionsForAutocomplete = () => {
  const allEntityOptions = useGetAllEntityOptions({});
  const useCaseOptions = useGetUseCaseOptions();
  const formattedOptions = useMemo(
    () => [
      ...formatMarketplaceEntitiesForAutocomplete(allEntityOptions),
      ...formatUseCasesForAutocomplete(useCaseOptions)
    ],
    [allEntityOptions, useCaseOptions]
  );

  return formattedOptions;
};

export default useGetFilteredOptionsForAutocomplete;
