import React from "react";
import { Add } from "@mui/icons-material";
import { Fab } from "@mui/material";
import useAddStep from "./hooks/useAddStep";

function AddWorklfowStepButton() {
  const addStep = useAddStep();

  return (
    <Fab onClick={addStep} color="primary" size="small">
      <Add />
    </Fab>
  );
}

export default AddWorklfowStepButton;
