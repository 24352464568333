import type { TWorkspaceDataToSave, TWorkstationTab } from "@sharedTypes";
import { useFormContext } from "react-hook-form";
import { WORKSPACE_LEFT_TAB } from "../workspaceConstants";

const useSetWorkspaceTab = () => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();
  const setWorkspaceTab = (tab: TWorkstationTab) => {
    setValue(WORKSPACE_LEFT_TAB, tab, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };
  return setWorkspaceTab;
};

export default useSetWorkspaceTab;
