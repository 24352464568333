import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import useToolflowNavigate from "../../../utilities/hooks/useToolflowNavigate"; // Adjust import path as necessary

export const useNavigateViaSidebar = (
  route?: string,
  externalLink?: boolean
) => {
  const navigate = useToolflowNavigate();
  const location = useLocation();

  const isSelected =
    route &&
    (route !== "/"
      ? location.pathname.includes(route)
      : location.pathname === route);

  const navigateTo = useCallback(
    (e: React.MouseEvent) => {
      if (externalLink && route) {
        window.open(route, "_blank");
      } else if (route) {
        navigate(route, e);
      }
    },
    [externalLink, navigate, route]
  );

  return { navigateTo, isSelected };
};
