import type { TWorkspaceDataToSave } from "@sharedTypes";
import { WORKSPACE_WORKFLOW_PROGRESS_FIELD_LABEL } from "../../../workspaceConstants";
import { useFormContext } from "react-hook-form";

const useGetWorkflowProgress = () => {
  const { watch } = useFormContext<TWorkspaceDataToSave>();
  return watch(WORKSPACE_WORKFLOW_PROGRESS_FIELD_LABEL);
};

export default useGetWorkflowProgress;
