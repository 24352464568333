import type { RichTextEditorRef } from "mui-tiptap";
import { useEffect } from "react";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";

const useFocusEditorOnMount = ({
  rteRef
}: {
  rteRef: React.RefObject<RichTextEditorRef>;
}) => {
  const { muiTipTapProps } = useMuiTipTapContext();
  const { focusOnMount } = muiTipTapProps;
  useEffect(() => {
    const editor = rteRef.current?.editor;
    if (editor && focusOnMount) {
      editor.commands.focus();
    }
  }, [rteRef.current?.editor]);
};

export default useFocusEditorOnMount;
