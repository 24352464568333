import React, { useMemo } from "react";
import type {
  TMarginTypes,
  TSetSelectedTool,
  TToolOptionTypeWithNull,
  ToolAutocomplete
} from "@sharedTypes";
import { Autocomplete, FormControl } from "@mui/material";
import useToolSelectorHighlight from "./hooks/useToolSelectorHighlight";
import ToolSelectorInput from "./ToolSelectorInput";
import OptionSplitter from "./OptionSplitter";
import useHandleOptionClick from "./hooks/useHandleOptionClick";
import addNewToolToOptions from "./hooks/addNewToolToOptions";
import formatEntitiesForAutocomplete from "../../workspace/functions/formatEntitiesForAutocomplete";
import useGetAllEntityOptions from "./hooks/useGetAllEntityOptions";

function ToolSelector({
  setSelectedTool,
  setSelectedWorkflow,
  className = "",
  autocompleteClassName = "",
  margin = "none",
  placeholder = "Search for a tool",
  disableWorkflowsOptions,
  label = "Tool"
}: Readonly<{
  setSelectedTool: TSetSelectedTool;
  autocompleteClassName?: string;
  className?: string;
  margin?: TMarginTypes;
  placeholder?: string;
  setSelectedWorkflow?: TSetSelectedTool;
  disableWorkflowsOptions?: boolean;
  label?: string;
}>) {
  const filteredOptions = useGetAllEntityOptions({
    disableWorkflowsOptions
  });

  const formattedOptions = useMemo(() => {
    return formatEntitiesForAutocomplete(filteredOptions);
  }, [JSON.stringify(filteredOptions)]);

  const { highlighted, handleHighlightChange } = useToolSelectorHighlight();

  const handleClick = useHandleOptionClick({
    setSelectedTool,
    setSelectedWorkflow
  });

  return (
    <FormControl margin={margin} fullWidth>
      <Autocomplete
        autoHighlight
        autoComplete
        onHighlightChange={handleHighlightChange}
        className={`${className} ${autocompleteClassName}`}
        size="small"
        id="searchTool-id"
        options={formattedOptions}
        groupBy={(option) => option.group}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if ((e.key === "Tab" || e.key === "Enter") && highlighted) {
            e.stopPropagation();
            e.preventDefault();
            handleClick(highlighted);
          }
          return false;
        }}
        filterOptions={(options, params) => {
          return addNewToolToOptions(options, params);
        }}
        onChange={(
          event: React.SyntheticEvent,
          value: TToolOptionTypeWithNull,
          reason: string
        ) => {
          if (reason === "selectOption" && value) {
            handleClick(value);
          }
        }}
        getOptionLabel={(option: ToolAutocomplete) => option.primary}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { inputValue }) => (
          <OptionSplitter
            props={props}
            option={option}
            key={option.id}
            inputValue={inputValue}
          />
        )}
        fullWidth
        renderInput={(params) => (
          <ToolSelectorInput
            params={{ ...params }}
            placeholder={placeholder}
            label={label}
          />
        )}
      />
    </FormControl>
  );
}

export default ToolSelector;
