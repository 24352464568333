import React from "react";
import RedoIcon from "@mui/icons-material/Redo";
import { useRichTextEditorContext } from "mui-tiptap";
import SettingsMenuItemWithClose from "../utilities/dropdowns/generic/SettingsMenuItemWithClose";

export const useHideRedo = () => {
  const editor = useRichTextEditorContext();
  return !editor?.isEditable || !editor.can().redo();
};

export default function RedoWorkspaceMenuItem() {
  const editor = useRichTextEditorContext();
  const hide = useHideRedo();
  return (
    <SettingsMenuItemWithClose
      hide={hide}
      text="Redo"
      action={() => editor?.chain().focus().redo().run()}
      icon={<RedoIcon fontSize="small" />}
    />
  );
}
