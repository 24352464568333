import React from "react";
import AssignUseCasesNoSaveBase from "../../../../utilities/toolCategorization/AssignUseCasesNoSaveBase";
import useGetWorkflowUseCases from "../hooks/useGetWorkflowUseCases";
import useSetWorkflowUseCases from "../hooks/useSetWorkflowUseCases";

const AssignUseCasesFromWorkflowBuilder = () => {
  const initialSelectedTags = useGetWorkflowUseCases();
  const setWorkflowUseCases = useSetWorkflowUseCases();

  return (
    <AssignUseCasesNoSaveBase
      handleUpdate={setWorkflowUseCases}
      initialSelectedTags={initialSelectedTags}
    />
  );
};

export default AssignUseCasesFromWorkflowBuilder;
