import {
  FormHelperText,
  Typography,
  type TypographyProps
} from "@mui/material";
import React from "react";

export const LabelAndInfo = ({
  name,
  info,
  variant = "subtitle2",
  className = "m-b-8px"
}: {
  name: string;
  info?: string;
  variant?: TypographyProps["variant"];
  className?: string;
}) => {
  return (
    <div className={`${className}`}>
      <Typography variant={variant} className="m-r-8px">
        {name}
      </Typography>
      {info && <FormHelperText>{info}</FormHelperText>}
    </div>
  );
};
