import React from "react";
import SettingsDropdown from "../../utilities/dropdowns/generic/SettingsDropdown";
import { DragIndicator } from "@mui/icons-material";
import useToolStillLoading from "../../utilities/editorToolCard/hooks/useToolStillLoading";
import DragToMoveTooltip from "../../utilities/DragToMoveTooltip";

function EditorToolSettingsWrapper({
  menuItems
}: {
  menuItems: React.ReactNode[];
}) {
  const toolStillLoading = useToolStillLoading();
  return (
    <SettingsDropdown
      icon={<DragIndicator />}
      IconButtonProps={{
        draggable: !toolStillLoading,
        "data-drag-handle": true,
        sx: { color: (theme) => theme.palette.grey["400"] },
        className: toolStillLoading ? "" : "cursor-grab"
      }}
      TooltipProps={{
        title: <DragToMoveTooltip toolStillLoading={toolStillLoading} />,
        arrow: true
      }}
      menuItems={menuItems}
    />
  );
}

export default EditorToolSettingsWrapper;
