import React from "react";

type InputBlockProps = {
  type: string;
  selected: boolean;
  id: string;
};

// going to hide this for now,
// we may want them back in the future
// don't want to kill it totally
const InputBlock: React.FC<InputBlockProps> = () => {
  return (
    <>
      {/* <NodeResizer
        color="#673AB6"
        minWidth={50}
        minHeight={20}
        isVisible={selected}
      />
      <Box>
        <Paper className="blockWidth p-16px">
          <BlockPaperHeader type={type} label={"Inputs"} />
          <BlockDrawer type={type} label="Inputs" id={id}>
            <CustomToolInputFields className="m-t-16px" />
          </BlockDrawer>
          <Handle type="source" position={Position.Bottom} id="a" />
        </Paper>
      </Box> */}
    </>
  );
};

export default InputBlock;
