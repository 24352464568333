import React from "react";
import useGetCurrentUser from "../../../hooks/useGetCurrentUser";
import { useSelector } from "react-redux";
import { selectAllProfileWorkspaces } from "../../../ToolflowAPI/rtkRoutes/selectors/workspaceSelectors";
import { useGetProfileWorkspacesQuery } from "../../../ToolflowAPI/rtkRoutes/workspaceApi";
import WorkspacePaginatedTable from "./WorkspacePaginatedTable";
import useWorkspaceTableData from "../hooks/useWorkspaceTableData";
import TableSkeleton from "../layout/TableSkeleton";
import NoRecentData from "../NoRecentData";

const WorkspaceDetailedTableContainer = () => {
  const user = useGetCurrentUser();
  const profileId = user?._id || "";
  const workspaces = useSelector(selectAllProfileWorkspaces(profileId));
  const { isLoading, isFetching } = useGetProfileWorkspacesQuery(profileId, {
    skip: !profileId
  });
  const { headCells, rows } = useWorkspaceTableData(workspaces);
  if (isLoading || isFetching) {
    return <TableSkeleton numOfRows={15} className="p-16px" />;
  }
  const isWorkspaceDataEmpty =
    workspaces?.length === 0 && !(isFetching || isLoading);

  if (isWorkspaceDataEmpty) {
    return (
      <NoRecentData
        header="Your recent work sessions will be stored here"
        subHeader="Ready to start? Easily find your first tool in our marketplace and begin exploring the possibilities."
      />
    );
  }
  return <WorkspacePaginatedTable rows={rows} headCells={headCells} />;
};

export default WorkspaceDetailedTableContainer;
