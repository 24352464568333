import { useEffect } from "react";
import type { unstable_Blocker as Blocker } from "react-router-dom";

function useBlockerFunctions({
  setOpen,
  setClose,
  blocker
}: {
  setOpen: () => void;
  setClose: () => void;
  blocker: Blocker;
}) {
  useEffect(() => {
    if (blocker.state === "blocked") {
      setOpen();
    } else {
      setClose();
    }
  }, [blocker.state]);
  const resetBlocker = () => {
    blocker.reset?.();
    setClose();
  };

  const continueNavigating = () => {
    if (blocker.state === "blocked") {
      blocker.proceed?.();
    }
    setClose();
  };
  return { continueNavigating, resetBlocker };
}

export default useBlockerFunctions;
