import type { CustomToolOutputField, ExternalToolFE } from "@sharedTypes";

export type LabelComparison = {
  oldLabel: string;
  newLabel: string;
};

const seperateOutputChanges = (
  currentTool: ExternalToolFE,
  tool: ExternalToolFE
): LabelComparison[] => {
  const result: LabelComparison[] = [];
  const newOutput = currentTool.main.toolOutputFields;
  const oldOutput = tool.main.toolOutputFields;

  const newOutputMap = new Map<string, CustomToolOutputField>();
  newOutput.forEach((field) => {
    if (field.id) {
      newOutputMap.set(field.id, field);
    }
  });

  oldOutput.forEach((oldField) => {
    const newField = oldField.id ? newOutputMap.get(oldField.id) : null;
    result.push({
      oldLabel: `${tool.name} - ${oldField.name}`,
      newLabel: newField ? `${currentTool.name} - ${newField.name}` : ""
    });
  });

  return result;
};
export default seperateOutputChanges;
