import type { CustomToolInputFieldTypes } from "@sharedTypes";
import { useInputsToCollectFieldArrayContext } from "../InputsToCollectContext";
import useGetSelectedStepInputsToCollect from "./useGetSelectedStepInputToCollect";
import { updateInputType } from "../../../../../../utilities/Inputs/constants";

const useChangeInputTypeWorkflow = () => {
  const { update } = useInputsToCollectFieldArrayContext();
  const inputsToCollect = useGetSelectedStepInputsToCollect();
  const changeInputType = (type: CustomToolInputFieldTypes, index: number) => {
    const newInput = updateInputType(type, inputsToCollect[index]);
    update(index, newInput);
  };

  return changeInputType;
};

export default useChangeInputTypeWorkflow;
