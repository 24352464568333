import React from "react";
import UpdateTextFieldBase from "../../../../utilities/UpdateTextFieldBase";
import type { IUpdateTextFieldContainerProps } from "@sharedTypes";
import useGetSelectedStep from "./hooks/useGetSelectedStep";
import useGetWorkflowStepNameFieldById from "./hooks/useGetWorkflowStepNameFieldById";

const UpdateSelectedStepNameTextField = ({
  margin = "none",
  size = "small",
  label,
  placeholder,
  showErrors
}: IUpdateTextFieldContainerProps) => {
  const step = useGetSelectedStep();
  const fieldLabel = useGetWorkflowStepNameFieldById(step?.id || "");
  return (
    <UpdateTextFieldBase
      size={size}
      label={label}
      margin={margin}
      placeholder={placeholder}
      fieldLabel={fieldLabel}
      showErrors={showErrors}
    />
  );
};

export default UpdateSelectedStepNameTextField;
