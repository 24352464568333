import type {
  GetUserThreadsResponse,
  TGetThreadMessagesResponse,
  TResetThreadResponse,
  TThreadReference
} from "@sharedTypes";
import { setErrorMessage } from "../../stores/actions";
import { getErrorMessage } from "../../utilities/apiHandlerWithAuth";
import { authenticatedApi } from "../authenticatedAPI";
import { THREADS_TAG_TYPE, THREAD_TAG_TYPE } from "../tagConstants";

import { threadsAdapter } from "../../stores/entityAdapters";
import type { EntityState } from "@reduxjs/toolkit";
import {
  setMessages,
  setThreadMessagesLoading
} from "../../features/workstation/leftSideDrawer/chatTab/chatbox/chatSlice";
import { RootState } from "../../stores/store";

export const threadsApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getThreadById: builder.query<TGetThreadMessagesResponse, string>({
      query: (threadId) => ({
        url: `thread/${threadId}`,
        method: "GET"
      }),
      providesTags: (result, error, threadId) => [
        { type: THREAD_TAG_TYPE, id: threadId }
      ],
      async onQueryStarted(threadId, { dispatch, queryFulfilled, getState }) {
        dispatch(setThreadMessagesLoading(true));
        dispatch(setThreadMessagesLoading(false));
        const response = await queryFulfilled;
        if (response.data.error) {
          dispatch(setErrorMessage(response.data.error));
        } else {
          const state = getState() as RootState;
          const chatMessages = state.chat.messages.filter(
            (message) => !message.isLastMessage && message.message
          );
          if (chatMessages.length !== response.data.result.messages.length) {
            dispatch(setMessages(response.data.result.messages));
          }
          dispatch(setThreadMessagesLoading(false));
        }
      }
    }),
    resetThread: builder.mutation<TResetThreadResponse, void>({
      query: () => ({
        url: "resetThread",
        method: "POST"
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          if (response.data.error) {
            dispatch(setErrorMessage(response.data.error));
          }
        } catch (e) {
          dispatch(
            setErrorMessage(getErrorMessage(e, "Error resetting thread"))
          );
        }
      },
      invalidatesTags: [THREADS_TAG_TYPE]
    }),
    getUserThreads: builder.query<EntityState<TThreadReference, string>, void>({
      query: () => ({
        url: "userThreads",
        method: "GET"
      }),
      transformResponse: (response: GetUserThreadsResponse) => {
        return threadsAdapter.upsertMany(
          threadsAdapter.getInitialState(),
          response.threads
        );
      },
      providesTags: [THREADS_TAG_TYPE]
    }),
    abortThread: builder.mutation<TResetThreadResponse, string>({
      query: (threadId) => ({
        url: `abortThread/${threadId}`,
        method: "DELETE"
      }),
      async onQueryStarted(componentId, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          if (response.data.error) {
            dispatch(setErrorMessage(response.data.error));
          }
        } catch {
          dispatch(setErrorMessage("Error aborting"));
        }
      }
    })
  })
});

export const {
  useGetThreadByIdQuery,
  useResetThreadMutation,
  useGetUserThreadsQuery,
  useAbortThreadMutation
} = threadsApi;
