import React from "react";
import RowWrapper from "./RowWrapper";
import { UtiltityTooltip } from "../../../../../../../utilities/ToolflowInfo";
import { IconButton } from "@mui/material";
import {
  FREE_PLAN_FE,
  useAuthorizedTier
} from "../../../../../../../settings/BillingCard";
import { PLEASE_UPGRADE_TEXT } from "./sheetConstants";
import { PlayCircle } from "@mui/icons-material";
import ButtonLinkToBilling from "../../../../../../../toolBuilder/common/ButtonLinkToBilling";
import { TMaterialRow } from "../sheetTypes";

const RunSingleRowButton = ({
  runSingleTool,
  row
}: {
  row: TMaterialRow;
  runSingleTool: (row: TMaterialRow) => Promise<void>;
}) => {
  const authorizedTier = useAuthorizedTier();
  return (
    <RowWrapper row={row}>
      <UtiltityTooltip
        title={authorizedTier === FREE_PLAN_FE ? PLEASE_UPGRADE_TEXT : ""}
      >
        <div>
          <IconButton
            color="primary"
            size="small"
            className="m-r-8px"
            onClick={() => runSingleTool(row)}
            disabled={authorizedTier === FREE_PLAN_FE}
          >
            <PlayCircle fontSize="small" />
          </IconButton>
        </div>
      </UtiltityTooltip>
      {authorizedTier === FREE_PLAN_FE && (
        <ButtonLinkToBilling className="m-h-8px" />
      )}
    </RowWrapper>
  );
};

export default RunSingleRowButton;
