import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import type {
  TCategory,
  TUseCase,
  TWorkflowVersionDataToSave
} from "@sharedTypes";
import useDefaultWorkflowFormValues from "./SaveWorkflowVersionDialog/hooks/useDefaultWorkflowFormValues";

import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  HARDCODE_INPUT_TYPE,
  MAPPED_INPUTS_FIELD,
  MAP_TO_PREVIOUS_INPUT_TYPE,
  REQUEST_INPUT_TYPE,
  TOOL_ID_FIELD,
  WORKFLOW_ARRAY_TYPES,
  WORKFLOW_NAME_REQUIRED_ERROR
} from "./WorkflowBuilderConstants";
import {
  CHECKBOX,
  CSV_FILE_UPLOAD,
  DOUBLE_TEXTFIELD,
  FILE_UPLOAD,
  MULTI_SELECT,
  SELECT,
  TAGS_INPUT,
  TEXTFIELD
} from "../../../utilities/Inputs/inputConstants";

const TPreviousField = z.object({
  stepId: z.string().min(1, { message: "Step id is required" }),
  fieldId: z.string().min(1, { message: "Field id is required" }),
  type: z.enum(["input", "output"])
});

const TMapToPrevious = z.object({
  type: z.literal(MAP_TO_PREVIOUS_INPUT_TYPE),
  previousField: TPreviousField.optional()
});

const THardCodeInput = z.object({
  type: z.literal(HARDCODE_INPUT_TYPE),
  partialFormState: z.any().optional()
});

const TRequestInputFromUser = z.object({
  type: z.literal(REQUEST_INPUT_TYPE)
});

const THandleInputType = z.union([
  TMapToPrevious,
  THardCodeInput,
  TRequestInputFromUser
]);

const RunToolSettings = z.object({
  [TOOL_ID_FIELD]: z.string(),
  [MAPPED_INPUTS_FIELD]: z.record(z.string(), THandleInputType)
});

const TextSettings = z.optional(z.record(z.string(), z.never()));

const CustomToolInputFieldBaseZodSchema = z.object({
  id: z.string().min(1, { message: "Field id is required" }),
  name: z.string().min(1, { message: "Field name is required" }),
  description: z.string().optional()
});

const CustomToolInputFieldBaseZodSchemaWithOptions =
  CustomToolInputFieldBaseZodSchema.extend({
    options: z.array(z.string())
  });

const TextField = CustomToolInputFieldBaseZodSchema.extend({
  type: z.literal(TEXTFIELD)
});

const CheckBox = CustomToolInputFieldBaseZodSchemaWithOptions.extend({
  type: z.literal(CHECKBOX)
});

const CsvFileUpload = CustomToolInputFieldBaseZodSchema.extend({
  type: z.literal(CSV_FILE_UPLOAD)
});

const DoubleTextField = CustomToolInputFieldBaseZodSchema.extend({
  type: z.literal(DOUBLE_TEXTFIELD)
});

const FileUpload = CustomToolInputFieldBaseZodSchema.extend({
  type: z.literal(FILE_UPLOAD)
});

const MultiSelect = CustomToolInputFieldBaseZodSchemaWithOptions.extend({
  type: z.literal(MULTI_SELECT)
});

const Select = CustomToolInputFieldBaseZodSchemaWithOptions.extend({
  type: z.literal(SELECT)
});

const Tags = CustomToolInputFieldBaseZodSchema.extend({
  type: z.literal(TAGS_INPUT),
  settings: z.object({ tags: z.array(z.string()) })
});

const CustomToolInputFieldZodSchema = z.union([
  TextField,
  CheckBox,
  CsvFileUpload,
  DoubleTextField,
  FileUpload,
  MultiSelect,
  Select,
  Tags
]);

const GetAssetsSettings = z.object({
  inputsToCollect: z.array(CustomToolInputFieldZodSchema)
});

const Settings = z.union([RunToolSettings, TextSettings, GetAssetsSettings]);
const CATEGORIES_ENUM = ["sales", "marketing", "growth", "utilities"] as const;
const USE_CASES_ENUM = [
  "prospecting",
  "content creation",
  "call notes",
  "research",
  "web scraping",
  "other"
] as const;
// we have this to ensure that the enums stay in sync
const THelper: readonly TUseCase[] = USE_CASES_ENUM; // eslint-disable-line @typescript-eslint/no-unused-vars
const TCategoryHelper: readonly TCategory[] = CATEGORIES_ENUM; // eslint-disable-line @typescript-eslint/no-unused-vars

const schema = z.object({
  name: z.string().min(1, { message: WORKFLOW_NAME_REQUIRED_ERROR }),
  description: z.string().min(0),
  visibility: z.enum(["public", "private", "restricted"]),
  tag: z.object({
    categories: z.array(z.enum(CATEGORIES_ENUM)),
    useCases: z.array(z.enum(USE_CASES_ENUM))
  }),
  steps: z
    .array(
      z.object({
        id: z.string(),
        name: z.string().min(1, { message: "Step name is required" }),
        description: z.string(),
        type: z.enum(WORKFLOW_ARRAY_TYPES),
        settings: Settings
      })
    )
    .nonempty({ message: "Workflow must have at least one step" })
});

const WorkflowFormProvider = ({ children }: { children: React.ReactNode }) => {
  const defaultValues = useDefaultWorkflowFormValues();

  const methods = useForm<TWorkflowVersionDataToSave>({
    defaultValues: defaultValues,
    resolver: zodResolver(schema),
    mode: "onChange"
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default WorkflowFormProvider;
