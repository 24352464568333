import React from "react";
import { Avatar } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import MicIcon from "@mui/icons-material/Mic";
import { pink, deepPurple, blueGrey, orange } from "@mui/material/colors";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import ToolflowLogo from "../../utilities/ToolflowLogo";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { blockDictConstants } from "./types/blockDictTypes";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { RiGoogleFill } from "@remixicon/react";

const DeepgramAvatar = (
  <Avatar variant="rounded" sx={{ bgcolor: deepPurple[500] }}>
    <MicIcon />
  </Avatar>
);
const SerpAvatar = (
  <Avatar variant="rounded" sx={{ bgcolor: deepPurple[500] }}>
    <RiGoogleFill />
  </Avatar>
);

const WebsiteContentCrawlerAvatar = (
  <Avatar variant="rounded" sx={{ bgcolor: deepPurple[500] }}>
    <FindInPageIcon />
  </Avatar>
);
const blockHeaderIconDict = {
  [blockDictConstants.ChatGPT.type]: (
    <Avatar variant="rounded" className="text-generation-bg-color">
      <TextFieldsIcon />
    </Avatar>
  ),
  [blockDictConstants.Anthropic.type]: (
    <Avatar variant="rounded" className="text-generation-bg-color">
      <TextFieldsIcon />
    </Avatar>
  ),
  [blockDictConstants.toolWithinTool.type]: (
    <Avatar variant="rounded" sx={{ bgcolor: "#FFF" }}>
      <ToolflowLogo />
    </Avatar>
  ),
  [blockDictConstants.DallE.type]: (
    <Avatar variant="rounded" sx={{ bgcolor: pink[500] }}>
      <ImageIcon />
    </Avatar>
  ),
  [blockDictConstants.logic.type]: (
    <Avatar
      variant="rounded"
      sx={{ bgcolor: blueGrey[500], transform: "rotate(180deg)" }}
    >
      <CallSplitIcon />
    </Avatar>
  ),
  [blockDictConstants.logic2.type]: (
    <Avatar
      variant="rounded"
      sx={{ bgcolor: blueGrey[500], transform: "rotate(180deg)" }}
    >
      <CallSplitIcon />
    </Avatar>
  ),
  [blockDictConstants.constant.type]: (
    <Avatar variant="rounded" sx={{ bgcolor: orange[500] }}>
      <TextFieldsIcon />
    </Avatar>
  ),
  [blockDictConstants.DeepgramTranscribe.type]: DeepgramAvatar,
  [blockDictConstants.WebScraper.type]: WebsiteContentCrawlerAvatar,
  [blockDictConstants.Serp.type]: SerpAvatar
};

export default blockHeaderIconDict;
