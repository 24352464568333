import { Box, useMediaQuery, type Theme } from "@mui/material";
import type { WorkflowInputSheet } from "@sharedTypes";
import React, { useMemo } from "react";
import { useGetToolQuery } from "../../../../../../ToolflowAPI/rtkRoutes/toolsApi";
import { useInputContext } from "../../../../../../utilities/contexts/InputContext";
import { useWorkspaceInputsFieldArrayContext } from "../../../../WorkspaceInputsContext";
import useGetWorkspaceInputIndexById from "../../../../hooks/useGetWorkspaceInputIndexById";
import { classNames } from "../../../utils/parsedClassNames";
import AssetTableOfContents from "../../assetTableOfContents/AssetTableOfContents";
import SheetAssetHeader from "./SheetAssetHeader";
import SheetContainer from "./SheetContainer";
import useUpdateFormWithSheetData from "./utils/hooks/useUpdateFormWithSheetData";
import useSheetData from "./utils/hooksWithoutContext/useSheetData";

const SheetWorkspaceContainer = () => {
  const { input } = useInputContext<WorkflowInputSheet>();
  const { data } = useGetToolQuery(input.value.selectedToolId, {
    skip: !input.value.selectedToolId
  });

  const tool = useMemo(() => {
    if (input.value.selectedToolId) {
      return data?.tool || null;
    } else {
      return null;
    }
  }, [input.value.selectedToolId, data?.tool]);

  const { update } = useWorkspaceInputsFieldArrayContext();
  const getInputIndex = useGetWorkspaceInputIndexById();
  const setTool = (t: string | null) => {
    const newInput = {
      ...input,
      value: {
        ...input.value,
        selectedToolId: t ?? ""
      }
    };
    update(getInputIndex(input.id), newInput);
  };

  const { sheetData, setSheetData, sheetDataRef } = useSheetData(
    tool,
    input.value.sheetData
  );
  useUpdateFormWithSheetData({ sheetData });
  const isMdDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Box className="flex w-100-percent">
      <Box
        className={classNames("parent-hover w-100-percent", {
          "p-r-70px": isMdDown,
          "p-r-16px": !isMdDown
        })}
        sx={{ maxWidth: "680px" }}
      >
        <SheetAssetHeader />
        <SheetContainer
          selectedTool={tool}
          setSelectedTool={setTool}
          sheetData={sheetData}
          setSheetData={setSheetData}
          sheetDataRef={sheetDataRef}
        />
      </Box>
      <Box>
        <AssetTableOfContents />
      </Box>
    </Box>
  );
};

export default SheetWorkspaceContainer;
