import useWorkspaceInputs from "../../features/workstation/hooks/useWorkspaceInputs";
import { useMemo } from "react";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";
import formatInputsForAutocomplete from "../functions/formatInputsForAutocomplete";

const useFilteredInputsForAutocomplete = () => {
  const inputs = useWorkspaceInputs();
  const { muiTipTapProps } = useMuiTipTapContext();
  const { disableInputs, inputType = "node", id } = muiTipTapProps;
  return useMemo(() => {
    if (disableInputs) return [];
    return formatInputsForAutocomplete(inputs, inputType, id);
  }, [inputs, disableInputs]);
};

export default useFilteredInputsForAutocomplete;
