// ToolEntityDetailsButton.tsx
import React from "react";
import type { TMarketplaceEntity } from "@sharedTypes"; // Adjust import path as necessary
import ToolDetailsButtonInner from "./ToolDetailsButtonInner";

interface ToolEntityDetailsButtonProps {
  entity: TMarketplaceEntity;
  secondaryAction?: React.ReactNode;
  disabled?: boolean;
  icon?: React.ReactNode;
  avatar?: React.ReactNode;
  onClickHandler?: () => void;
  disableMaxWidth?: boolean;
  disableInfoTags?: boolean;
}

const ToolEntityDetailsButton: React.FC<ToolEntityDetailsButtonProps> = (
  props
) => {
  const { entity, ...rest } = props;

  return (
    <ToolDetailsButtonInner
      name={entity.name}
      about={entity.description}
      visibility={entity.visibility}
      id={entity.id}
      runCount={entity.runCount}
      estimatedCreditCost={entity.estimatedCreditCost}
      {...rest}
    />
  );
};

export default ToolEntityDetailsButton;
