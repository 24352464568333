import ToolsTab from "./toolsTab/ToolsTab";
import ChatTab from "./chatTab/ChatTab";
import { TWorkstationTabObject } from "../workstationTypes";

const workstationTabsDict: TWorkstationTabObject = {
  tools: {
    label: "Tools",
    value: 0,
    component: ToolsTab
  },
  chat: {
    label: "Chat",
    value: 1,
    component: ChatTab
  }
};

export default workstationTabsDict;
