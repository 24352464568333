import React from "react";
import type { VisibilityTypes } from "@sharedTypes";
import RedoWorkspaceMenuItem from "../RedoWorkspaceMenuItem";
import UndoWorkspaceMenuItem from "../UndoWorkspaceMenuItem";
import SettingsDropdownMenuButton from "../../utilities/dropdowns/generic/SettingsDropdownMenuButton";
import DuplicateWorkspaceMenuItem from "./menuItems/DuplicateWorkspaceMenuItem";
import { WORKSPACE } from "../../utilities/constants/constants";
import UpdateWorkspaceVisibilityMenuItem from "../../utilities/dropdowns/menuItems/UpdateWorkspaceVisibilityMenuItem";
import DeleteMenuItem from "../../utilities/dropdowns/menuItems/DeleteMenuItem";

function WorkspaceSettingsDropdown({
  workspaceId,
  workspaceVisibility,
  workspaceName
}: {
  workspaceId: string;
  workspaceVisibility: VisibilityTypes;
  workspaceName: string;
}) {
  const menuItems = [
    <RedoWorkspaceMenuItem key="redo" />,
    <UndoWorkspaceMenuItem key="undo" />,

    <DuplicateWorkspaceMenuItem
      key="duplicate"
      id={workspaceId}
      useLastSaveText
    />,
    <UpdateWorkspaceVisibilityMenuItem
      visibility={workspaceVisibility}
      id={workspaceId}
      name={workspaceName}
      key="updateVisibility"
    />,
    <DeleteMenuItem
      key="delete"
      id={workspaceId}
      shouldNavigateHome
      type={WORKSPACE}
    />
  ];

  return <SettingsDropdownMenuButton menuItems={menuItems} />;
}

export default WorkspaceSettingsDropdown;
