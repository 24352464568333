import { IconButton } from "@mui/material";
import React from "react";
import { toolflowTextDisabledColor } from "../../../../../../../globalTheme/muiUtils/appTheme";
import RemixWrapperIconWithTheme from "../../../../../../layout/icons/RemixWrapperIconWithTheme";
import { ArrowDownSmallIcon, ArrowUpSmallIcon } from "../../../../icons/icons";
import styles from "./openAssetGroup.module.css";

const OpenAssetGroup = ({
  onClick,
  open
}: {
  open: boolean;
  onClick: () => void;
}) => {
  return (
    <IconButton onClick={onClick} className={styles["icon-button"]}>
      <RemixWrapperIconWithTheme
        Icon={open ? ArrowUpSmallIcon : ArrowDownSmallIcon}
        size={16}
        providedColor={toolflowTextDisabledColor}
      />
    </IconButton>
  );
};

export default OpenAssetGroup;
