// import type { Auth0Client } from "@auth0/auth0-spa-js";
import { useAuth0 } from "@auth0/auth0-react";
import { useRef } from "react";

import axios, { isAxiosError, isCancel } from "axios";
import type {
  AxiosResponse,
  AxiosProgressEvent,
  AxiosRequestConfig
} from "axios";
import {
  isLoginOrConsentRequiredError,
  useLoginBackToPage
} from "./authHelpers";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage } from "../features/layout/alerts/alertMessageSlice";
import { RootState } from "../stores/store";
import { type FetchBaseQueryError } from "@reduxjs/toolkit/query";
import type { TBadRequest } from "@sharedTypes";

export const getIsOutOfCreditsError = (error: unknown) => {
  return (
    isAxiosError(error) && error?.response?.data?.contextFields?.isOutOfCredits
  );
};
export const getIsOutOfToolRunsError = (error: unknown) => {
  return (
    isAxiosError(error) && error?.response?.data?.contextFields?.isOutOfToolRuns
  );
};

export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error;
}

export function isErrorObject<T extends { error?: unknown }>(
  error: unknown
): error is T {
  return (
    typeof error === "object" &&
    error !== null &&
    "error" in error &&
    typeof (error as T).error === "string"
  );
}

export function isErrorWithMessage(
  error: unknown
): error is { message: string } {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as { message: unknown }).message === "string"
  );
}

export function isErrorWithData(error: unknown): error is { data: unknown } {
  return typeof error === "object" && error != null && "data" in error;
}
export function isErrorWithError(error: unknown): error is { error: unknown } {
  return typeof error === "object" && error != null && "error" in error;
}

const BAD_REQUEST_STRING_FE: TBadRequest = "Bad request";

export const getErrorMessage = (
  error: unknown,
  defaultErrorMessage = "An unknown error occurred"
): string => {
  let errorMessage = defaultErrorMessage;
  // Specifically handle Axios errors
  if (isAxiosError(error)) {
    errorMessage = error?.response?.data?.message || defaultErrorMessage;

    // if generic error thrown, use default error message
    if (error?.response?.data?.message === BAD_REQUEST_STRING_FE) {
      errorMessage = defaultErrorMessage;
    }
  } else if (isErrorWithError(error)) {
    return getErrorMessage(error.error, defaultErrorMessage);
  } else if (isErrorWithData(error)) {
    return getErrorMessage(error.data, defaultErrorMessage);
  } else if (isErrorWithMessage(error)) {
    errorMessage = error.message;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === "string") {
    errorMessage = error;
  } else {
    errorMessage = defaultErrorMessage;
  }
  return errorMessage;
};

type AxiosOptions = Record<string, string | string[] | number>;

export const useCancelableApiHandler = () => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const reduxDispatch = useDispatch();

  const apiHandler = async ({
    endpoint,
    method = "GET",
    data = null,
    headers,
    errorCallback,
    baseUrl,
    onUploadProgress,
    otherOptions = {}
  }: {
    endpoint: string;
    method: "GET" | "PUT" | "POST" | "DELETE" | "PATCH";
    data?: $TSAllowedAny;
    headers?: Record<string, string | string[] | number>;
    errorCallback?: (e?: unknown) => void;
    baseUrl?: string;
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
    otherOptions?: AxiosOptions;
  }) => {
    abortControllerRef.current = new AbortController();
    const axiosOptions: AxiosRequestConfig = {
      url: `${
        baseUrl || process.env.REACT_APP_API_URL || "https://api.toolflow.ai/"
      }${endpoint}`,
      method,
      data,
      headers,
      signal: abortControllerRef.current.signal,
      ...otherOptions
    };

    if (onUploadProgress) {
      axiosOptions.onUploadProgress = onUploadProgress;
    }

    try {
      let maxSize = 100 * 1024;
      // if a route has a larger maxBodyLength,
      // use that as the maxSize.
      // make sure the route in initParser has the appropriate size limit
      if (
        otherOptions?.maxBodyLength &&
        typeof otherOptions.maxBodyLength === "number"
      ) {
        maxSize = otherOptions.maxBodyLength;
      }

      function payloadSizeUnderMaxSize(payload: unknown) {
        const payloadSize = new Blob([JSON.stringify(payload)]).size;
        const payloadUnderMaxSize = payloadSize <= maxSize;
        if (!payloadUnderMaxSize) {
          console.log("payloadSize", payloadSize);
          throw new Error(
            "Payload too large, please reduce the size of your document"
          );
        }
      }
      payloadSizeUnderMaxSize(data);

      const response: AxiosResponse = await axios(axiosOptions);
      if (response.status === 204) {
        return null;
      }

      return response.data;
    } catch (error: unknown) {
      if (isCancel(error)) {
      } else {
        console.error("API request error:", error);
        const errorMessage = getErrorMessage(error);
        if (errorCallback) {
          errorCallback(error);
        } else {
          reduxDispatch(setErrorMessage(errorMessage));
        }
      }

      throw error;
    }
  };

  const cancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  return { apiHandler, cancel };
};

const useApiHandlerWithAuth = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { loginBackToPage } = useLoginBackToPage();
  const { apiHandler, cancel } = useCancelableApiHandler();
  const clientId = useSelector((state: RootState) => state.auth.clientId);

  const apiHandlerWithAuth = async ({
    endpoint,
    method = "GET",
    data = null,
    errorCallback,
    onUploadProgress,
    axiosOptions
  }: {
    endpoint: string;
    method: "GET" | "PUT" | "POST" | "DELETE" | "PATCH";
    data?: $TSAllowedAny;
    errorCallback?: (e?: unknown) => void;
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
    axiosOptions?: AxiosOptions;
  }) => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        }
      });

      const authHeaders: Record<string, string> = {
        Authorization: `Bearer ${token}`
      };
      const allHeaders: Record<string, string> = {
        ...authHeaders,
        "x-client-id": clientId
      };

      const response = await apiHandler({
        endpoint,
        method,
        data,
        headers: allHeaders,
        errorCallback,
        onUploadProgress,
        otherOptions: axiosOptions
      });
      return response;
    } catch (error) {
      if (isLoginOrConsentRequiredError(error)) {
        loginBackToPage();
      } else {
        console.error("API request error:", error);
        throw error;
      }
    }
  };

  return { apiHandlerWithAuth, cancel };
};

export default useApiHandlerWithAuth;
