import React from "react";
import type { ButtonSizeTypes } from "@sharedTypes";
import useFavoriteObject from "./useFavoriteObject";
import IconButtonWithBorder from "../../features/marketplace/card/IconButtonWithBorder";
import { RiStarLine } from "@remixicon/react";

function UnfavoritedObject({
  className = "",
  size = "medium"
}: {
  className?: string;
  size?: ButtonSizeTypes;
}) {
  const favorite = useFavoriteObject();

  return (
    <IconButtonWithBorder onClick={favorite} className={className} size={size}>
      <RiStarLine size={20} color="#12172961" />
    </IconButtonWithBorder>
  );
}

export default UnfavoritedObject;
