import React from "react";
import UpdateTextFieldBase from "../../../../utilities/UpdateTextFieldBase";
import type { IUpdateTextFieldArrayProps } from "@sharedTypes";
import { WORKFLOW_STEP_NAME_REQUIRED_ERROR } from "../WorkflowBuilderConstants";

const UpdateStepNameTextField = ({
  margin = "none",
  size = "small",
  label,
  placeholder,
  showErrors,
  fieldLabel
}: IUpdateTextFieldArrayProps) => {
  return (
    <UpdateTextFieldBase
      size={size}
      label={label}
      margin={margin}
      placeholder={placeholder}
      fieldLabel={fieldLabel}
      registerOptions={{ required: WORKFLOW_STEP_NAME_REQUIRED_ERROR }}
      showErrors={showErrors}
    />
  );
};

export default UpdateStepNameTextField;
