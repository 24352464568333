import type { TThreadMessageType } from "@sharedTypes";
import type { Content } from "@tiptap/core";
import { getHtmlFromText } from "../../../../../utilities/functions/htmlHelpers";
import { getSearchResponseNode } from "../../../../../workspace/functions/getToolNodes";

const extractMongoIds = (valueWithToolId: string) => {
  // Define the regex pattern for a MongoDB ObjectId
  const objectIdRegex = /[0-9a-fA-F]{24}/g;

  // Use match() to find all occurrences of the ObjectId pattern
  const toolIds = valueWithToolId.match(objectIdRegex) || [];

  return toolIds;
};

export const messageTypeDict: {
  [key in TThreadMessageType]: (value: string) => Content;
} = {
  NORMAL_MESSAGE: (value: string) => getHtmlFromText(value),
  SEARCH_TOOLS_RESPONSE_MESSAGE: (valueWithToolId: string) => {
    const toolIds = extractMongoIds(valueWithToolId);
    const assistantMessageContent: Content = {
      type: "doc",
      content: [getSearchResponseNode(toolIds)]
    };
    return assistantMessageContent;
  }
};
