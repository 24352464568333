import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { toolflowTextDisabledColor } from "../../../../../../../globalTheme/muiUtils/appTheme";
import { UpdateInputNameTextFieldContainer } from "../../../../../../../workspace/UpdateInputNameTextfield";
import RenameDialog from "../../../../../../home/layout/RenameDialog";
import RemixWrapperIconWithTheme from "../../../../../../layout/icons/RemixWrapperIconWithTheme";
import { PencilIcon } from "../../../../icons/icons";
import { classNames } from "../../../../utils/parsedClassNames";

const EditAssetNameButton = ({
  id,
  className = ""
}: {
  id: string;
  className?: string;
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const close = (e: React.SyntheticEvent<Element, Event>) => {
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        className={classNames("asset-button", className)}
        disableTouchRipple
      >
        <RemixWrapperIconWithTheme
          Icon={PencilIcon}
          size={16}
          providedColor={toolflowTextDisabledColor}
        />
      </IconButton>
      <RenameDialog open={open} closeDialog={close} title="Rename asset">
        <UpdateInputNameTextFieldContainer inputId={id} />
      </RenameDialog>
    </>
  );
};

export default EditAssetNameButton;
