import React from "react";
import { useCustomToolInputFieldContext } from "../../../../../utilities/contexts/CustomToolInputFieldContext";
import {
  baseProps,
  inputComponentMap
} from "../../../../../utilities/WebsiteForm";
import { Controller, useFormContext } from "react-hook-form";
import type {
  THardCodeInput,
  TWorkflowBuilderForm,
  UserInputDictType
} from "@sharedTypes";
import useGetSelectedStepMappedInputsField from "./hooks/useGetSelectedStepMappedInputs";

const HardCodeInputField = () => {
  const { input } = useCustomToolInputFieldContext();
  const InputComponent = inputComponentMap[input.type].component;
  const { setValue, watch } = useFormContext<TWorkflowBuilderForm>();
  const mappedInputsFieldName = useGetSelectedStepMappedInputsField();
  const nameToRegister = `${mappedInputsFieldName}.${input.id}` as const;

  // we don't get the actual formState directly because react hook form has a problem with discriminated unions
  const formState = watch(nameToRegister) as THardCodeInput;
  const partialFormState = formState.partialFormState || {};
  const basePropsObj = baseProps({
    toolInputField: input,
    setFormState: (e: UserInputDictType) => {
      setValue(
        nameToRegister,
        { ...formState, partialFormState: e },
        {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true
        }
      );
    },
    formState: partialFormState
  });

  return (
    <div>
      <Controller
        name={nameToRegister}
        render={() => {
          return (
            <InputComponent
              {...basePropsObj}
              disableLabelAndInfo
              disableTools
              defaultFormState={partialFormState}
            />
          );
        }}
      />
    </div>
  );
};

export default HardCodeInputField;
