import React from "react";
import ToolSelectedText from "../../features/workstation/newDesigns/components/sheetAsset/sheet/utils/ToolSelectedText";
import ToolSelector from "./ToolSelector";
import type {
  TMarginTypes,
  TSelectedTool,
  TSetSelectedTool
} from "@sharedTypes";

function ToolSelectorWithText({
  setSelectedTool,
  selectedTool,
  className = "",
  autocompleteClassName = "",
  margin = "none",
  placeholder
}: Readonly<{
  setSelectedTool: TSetSelectedTool;
  selectedTool?: TSelectedTool;
  autocompleteClassName?: string;
  className?: string;
  margin?: TMarginTypes;
  placeholder?: string;
}>) {
  if (selectedTool) {
    return (
      <ToolSelectedText
        selectedTool={selectedTool}
        setSelectedTool={setSelectedTool}
        className={className}
        margin={margin}
      />
    );
  }
  return (
    <ToolSelector
      margin={margin}
      setSelectedTool={setSelectedTool}
      className={className}
      autocompleteClassName={autocompleteClassName}
      placeholder={placeholder}
    />
  );
}

export default ToolSelectorWithText;
