import { Button, Divider, Stack, Typography } from "@mui/material";
import { RiFileTextLine } from "@remixicon/react";
import React from "react";
import CreateAPIKeyButton from "../../../../settings/CreateAPIKeyButton";
import ManageAPIKeys from "../../../../settings/ManageAPIKeys";

const ToolflowApiKeys = () => {
  const navigateToReadDocumentation = () => {
    window.open("https://toolflow.readme.io/");
  };

  return (
    <div className="m-t-32px">
      <Typography variant="h7">API Keys</Typography>
      <Typography
        variant="body1"
        className="m-t-12px"
        sx={{ color: (theme) => theme.palette.text.disabled }}
      >
        Get your API keys to integrate Toolflow with external services
      </Typography>
      <ManageAPIKeys />
      <Divider className="m-v-24px" />
      <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
        <Button
          startIcon={<RiFileTextLine size={24} />}
          variant="outlined"
          onClick={navigateToReadDocumentation}
        >
          Read Documentation
        </Button>
        <CreateAPIKeyButton />
      </Stack>
    </div>
  );
};

export default ToolflowApiKeys;
