// useSettingsDropdown.ts
import type { SelectChangeEvent } from "@mui/material";
import type { VisibilityTypes } from "@sharedTypes";
import React from "react";

const useSettingsDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLElement>
  ) => {
    event.stopPropagation();
    setAnchorEl({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
  };
  const handleClose = (
    e: SelectChangeEvent<VisibilityTypes> | React.SyntheticEvent<Element, Event>
  ) => {
    if ("stopPropagation" in e) {
      e.stopPropagation();
    }
    setAnchorEl(null);
  };

  return { anchorEl, open, handleClick, handleClose };
};

export default useSettingsDropdown;
