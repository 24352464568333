import { createEntityAdapter } from "@reduxjs/toolkit";
import type {
  ExternalToolFE,
  Snippet,
  Workspace,
  WorkflowVersion,
  ProfileWorkflow,
  TThreadReference,
  TMarketplaceEntity,
  LeanFileDocument
} from "@sharedTypes";

// this normalizes data and has a number of built in functions
// ids: [], entities: {} for faster lookup
// we can reuse adapters as long as they have the same shape

// https://redux-toolkit.js.org/api/createEntityAdapter
export const externalToolAdapter = createEntityAdapter({
  selectId: (tool: ExternalToolFE) => tool._id
});

export const externalToolsInitialState = externalToolAdapter.getInitialState();

export const filesAdapter = createEntityAdapter({
  selectId: (file: LeanFileDocument) => file._id
});

export const filesInitialState = filesAdapter.getInitialState();

export const snippetAdapter = createEntityAdapter({
  selectId: (snippet: Snippet) => snippet._id
});

export const snippetInitialState = snippetAdapter.getInitialState();

export const workspaceAdapter = createEntityAdapter({
  selectId: (workspace: Workspace) => workspace._id
});

export const workspaceInitialState = workspaceAdapter.getInitialState();

export const workflowAdapter = createEntityAdapter({
  selectId: (workflow: ProfileWorkflow) => workflow.id
});
export const workflowInitialState = workflowAdapter.getInitialState();

export const workflowVersionAdapter = createEntityAdapter({
  selectId: (workflow: WorkflowVersion) => workflow._id
});

export const workflowVersionInitialState =
  workflowVersionAdapter.getInitialState();

export const threadsAdapter = createEntityAdapter({
  selectId: (thread: TThreadReference) => thread._id
});
export const threadsInitialState = threadsAdapter.getInitialState();

export const marketplaceEntityAdapter = createEntityAdapter({
  selectId: (marketplaceEntity: TMarketplaceEntity) => marketplaceEntity.id
});

export const marketplaceEntityAdapterInitialState =
  marketplaceEntityAdapter.getInitialState();
