import React from "react";

import RemixButton from "../../../../layout/icons/RemixButton";
import { useDispatch, useSelector } from "react-redux";
import { setAssetExpanded } from "../../../workspaceSlice";
import { RootState } from "../../../../../stores/store";
import { ExpandIcon } from "../../icons/icons";

const OpenAssetDialogButton = () => {
  const open = useSelector((state: RootState) => state.workspace.assetExpanded);
  const dispatch = useDispatch();

  const setOpen = () => {
    dispatch(setAssetExpanded(true));
  };

  if (open) return null;

  return <RemixButton text="Expand" icon={ExpandIcon} onClick={setOpen} />;
};

export default OpenAssetDialogButton;
