import React from "react";
import Container from "@mui/material/Container";
import SecretsCard from "./SecretsCard";

function IntegrationsPage() {
  return (
    <Container maxWidth="md">
      <SecretsCard />
    </Container>
  );
}
export default IntegrationsPage;
