import React from "react";
import { useDuplicateObject } from "../../../utilities/hooks/useDuplicateObject";
import { TOOL, WORKFLOW } from "../../../utilities/constants/constants";
import SettingsMenuItemWithClose from "../../../utilities/dropdowns/generic/SettingsMenuItemWithClose";
import { RiFileCopyLine, RiGitForkLine } from "@remixicon/react";
import RemixButton from "../../../features/layout/icons/RemixButton";

function DuplicateHomePageEntityMenuItem({
  id,
  type,
  iconOnly = false
}: {
  id: string;
  type: "tool" | "workflow";
  iconOnly?: boolean;
}) {
  const { loading, duplicateObject } = useDuplicateObject();

  const duplicate = () => {
    if (type === "workflow") {
      duplicateObject(id, WORKFLOW);
    }
    if (type === "tool") {
      duplicateObject(id, TOOL);
    }
  };

  return !iconOnly ? (
    <SettingsMenuItemWithClose
      disabled={loading}
      hide={!id}
      action={duplicate}
      text={`Duplicate`}
      icon={<RiFileCopyLine size={16} />}
    />
  ) : (
    <RemixButton text="Duplicate" icon={RiGitForkLine} onClick={duplicate} />
  );
}

export default DuplicateHomePageEntityMenuItem;
