import { useSelector } from "react-redux";
import { useGetThreadByIdQuery } from "../../../../../../ToolflowAPI/rtkRoutes/threadsApi";
import { RootState } from "../../../../../../stores/store";
import useGetCurrentChatThreadId from "./useGetCurrentChatThreadId";

const useThreadMessages = () => {
  const currentThreadId = useGetCurrentChatThreadId();
  const messages = useSelector((store: RootState) => store.chat.messages);
  useGetThreadByIdQuery(currentThreadId, {
    skip: !currentThreadId,
    refetchOnMountOrArgChange: true,
    skipPollingIfUnfocused: true
  });
  return messages || [];
};

export default useThreadMessages;
