import React from "react";
import { useMediaQuery } from "@mui/material";
import { useSubscriptionDetails } from "../../../settings/BillingCard";
import sidebarTheme from "../../../globalTheme/sidebarTheme";
import useLogRocket from "../../../hooks/useLogRocket";
import useIntercom from "../../../hooks/useIntercom";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { selectSidebarOpen } from "./sidebarSlice";
import useGetCurrentUser from "../../../hooks/useGetCurrentUser";
import { useAuth0Store } from "../../../hooks/useAuth0Store";

function SidebarContainer() {
  const isMediumScreen = useMediaQuery(sidebarTheme.breakpoints.down("md"));
  const { isAuthenticated } = useAuth0Store();
  const user = useGetCurrentUser();
  const { activeTier } = useSubscriptionDetails();

  const open = useSelector(selectSidebarOpen);

  useLogRocket(user);
  useIntercom(user, isAuthenticated, activeTier);

  return (
    <Sidebar
      isMediumScreen={isMediumScreen}
      open={open}
      isAuthenticated={isAuthenticated}
    />
  );
}

export default SidebarContainer;
