import React from "react";
import { useToolbuilderContext } from "../context/ToolBuilderContext";
import type { TUseCase } from "@sharedTypes";
import AssignUseCasesNoSaveBase from "../../utilities/toolCategorization/AssignUseCasesNoSaveBase";

const AssignUseCasesFromToolBuilder = () => {
  const { dispatch, state } = useToolbuilderContext();

  const initialUseCaseTagsInState = state.currentState.tag?.useCases || [];

  const handleUpdate = (s: TUseCase[]) => {
    dispatch({
      type: "SAVE_TOOL_USE_CASES",
      useCases: s
    });
  };
  return (
    <AssignUseCasesNoSaveBase
      handleUpdate={handleUpdate}
      initialSelectedTags={initialUseCaseTagsInState}
    />
  );
};

export default AssignUseCasesFromToolBuilder;
