import React from "react";
import useWorkspaceInputs from "../../../../hooks/useWorkspaceInputs";
import Dot from "./Dot";

function Dots() {
  const inputs = useWorkspaceInputs();
  return (
    <div className="flex align-i-center flex-column justify-center">
      {inputs.map((input) => {
        return <Dot key={input.id} assetId={input.id} />;
      })}
    </div>
  );
}

export default Dots;
