import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Container, Typography } from "@mui/material";
import BuilderCard from "./BuilderCardBase";
import {
  TOOLBUILDER_BASE_ROUTE_PATH,
  WORKFLOWBUILDER_BASE_ROUTE_PATH
} from "../../navigation/routePaths";
import { Construction, FormatListNumbered } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { selectSidebarOpen } from "../layout/sidebar/sidebarSlice";
import ToggleSidebarButton from "../layout/sidebar/ToggleSidebarButton";

function SelectBuilder() {
  const open = useSelector(selectSidebarOpen);
  return (
    <>
      <Box>{!open && <ToggleSidebarButton />}</Box>
      <Container
        maxWidth="lg"
        className="justify-center align-i-center flex h-100vh flex-column"
      >
        <Typography className="m-b-8px" color="GrayText">
          Choose something to build
        </Typography>
        <Grid
          container
          xs={12}
          spacing={2}
          sx={{ marginLeft: 0 }}
          justifyContent={"center"}
        >
          <Grid xs={12} sm={6}>
            <BuilderCard
              title="Tool"
              route={TOOLBUILDER_BASE_ROUTE_PATH}
              Icon={Construction}
              description="Tools are automations that have no human intervention"
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <BuilderCard
              title="Workflow"
              route={WORKFLOWBUILDER_BASE_ROUTE_PATH}
              Icon={FormatListNumbered}
              description="Workflows are step by step processes that require human intervention"
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default SelectBuilder;
