import type { TWorkspaceDataToSave } from "@sharedTypes";
import React, { createContext, useContext } from "react";
import {
  useFormContext,
  type UseFieldArrayReturn,
  useFieldArray
} from "react-hook-form";
import { WORKSPACE_INPUTS_FIELD_LABEL } from "./workspaceConstants";

// RHF create their own ids for each field in the array,
// this makes using ids from fields not intuitive because
// we have our own ids. so we omit fields to minimze bugs
type TWorkpsaceInputsContext = Omit<
  UseFieldArrayReturn<
    TWorkspaceDataToSave,
    `${typeof WORKSPACE_INPUTS_FIELD_LABEL}`,
    "id"
  >,
  "fields"
>;

const WorkpsaceInputsContext = createContext<
  TWorkpsaceInputsContext | undefined
>(undefined);

export const useWorkspaceInputsFieldArrayContext = () => {
  const context = useContext(WorkpsaceInputsContext);
  if (context === undefined) {
    throw new Error(
      "useWorkspaceInputsFieldArrayContext must be used within a WorkspaceInputsFormProvider"
    );
  }
  return context;
};

export const WorkspaceInputsFormProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const { control } = useFormContext<TWorkspaceDataToSave>();
  const { fields, ...fieldArrayMethods } = useFieldArray({
    control,
    name: WORKSPACE_INPUTS_FIELD_LABEL
  });
  return (
    <WorkpsaceInputsContext.Provider value={fieldArrayMethods}>
      {children}
    </WorkpsaceInputsContext.Provider>
  );
};
