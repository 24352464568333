import { Typography } from "@mui/material";
import React, { useMemo } from "react";
import { RootState } from "../../stores/store";
import { useSelector } from "react-redux";
import { getCategoryTitle } from "./utils/getCategoryTitle";

function MarketplaceUseCaseTitle() {
  const category = useSelector(
    (state: RootState) => state.marketplace.category
  );
  const title = useMemo(() => {
    return getCategoryTitle(category);
  }, [category]);
  return (
    <Typography className="m-t-16px" variant="h6">
      {title}
    </Typography>
  );
}

export default MarketplaceUseCaseTitle;
