import type { CSSObject, Theme } from "@mui/material";
import {
  LARGE_DRAWER_WIDTH,
  SMALL_DRAWER_WIDTH
} from "../utilities/functions/formatHelpers";

const calculateDrawerLeftValue = (
  theme: Theme,
  open: boolean
): string | number => {
  if (open) {
    return SMALL_DRAWER_WIDTH;
  } else {
    return theme.breakpoints.up("sm")
      ? `calc(${theme.spacing(8)} + 1px)`
      : `calc(${theme.spacing(7)} + 1px)`;
  }
};

export const secondaryClosedMixin = (
  theme: Theme,
  open: boolean
): CSSObject => ({
  transition: theme.transitions.create(["width", "right"], {
    // include "left" here
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  width: 0,
  right: calculateDrawerLeftValue(theme, open)
});

export const secondaryOpenedMixin = (
  theme: Theme,
  open: boolean
): CSSObject => {
  return {
    width: LARGE_DRAWER_WIDTH,
    transition: theme.transitions.create(["width", "right"], {
      // include "left" here
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    right: calculateDrawerLeftValue(theme, open)
  };
};
