import { useCallback, useRef } from "react";
import type { ReactFlowInstance } from "reactflow";
import { useToolbuilderContext } from "../context/ToolBuilderContext";
import { v4 as uuidv4 } from "uuid";
import type { TBlock } from "@sharedTypes";

const useOnDrop = (
  reactFlowInstance: ReactFlowInstance,
  reactFlowWrapper: React.RefObject<HTMLDivElement>
) => {
  const { reactflowUtility, dispatch, state } = useToolbuilderContext();
  const { setNodes } = reactflowUtility;
  const { blocks: nodes } = state.currentState;

  //  The useDrop hook from react-dnd uses closure scope, which means it captures
  // the state (in this case, nodes) at the time of its creation and doesn't
  // automatically update to reflect state changes. As a result, when your drop
  // function is invoked, it has access to the initial nodes state, not the most recent one.
  // One way to solve this is to use a mutable ref to always have the latest state:
  const nodesRef = useRef(nodes);
  nodesRef.current = nodes;

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (!reactFlowWrapper.current || !reactFlowInstance) {
        return; // Or handle it in some other way
      }

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      const item = JSON.parse(type);

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      // note, this calculates the zoom
      // to change things after the zoom, use position.x, position.y
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
      });

      const nodeId = uuidv4();
      const newNode: TBlock = {
        id: nodeId,
        type: item.type,
        position: {
          x: position.x - 180,
          y: position.y - 160
        },
        data: { ...item.data }
      };

      const isLabelUnique = (label: string) =>
        !nodesRef.current.some((node) => node.data?.label === label);

      if (newNode.data?.label) {
        let newOutputCount = 0;
        let newLabel;

        do {
          if (newOutputCount === 0) {
            newLabel = `${newNode.data.label}`;
          } else {
            newLabel = `${newNode.data.label} ${newOutputCount}`;
          }
          newOutputCount += 1;
        } while (!isLabelUnique(newLabel));

        newNode.data.label = newLabel;
      }

      if (!!item?.parentNode) {
        newNode.parentNode = nodesRef.current.filter(
          (block) => block.type === "parentBlockNode"
        )[0]?.id;
        // allow blocks to be whereever
        // newNode.extent = "parent";
      }
      dispatch({
        type: "UPDATE_OPEN_NODE",
        nodeId: nodeId
      });

      setNodes((nds) => nds.concat(newNode), newNode);
    },
    [reactFlowInstance] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return onDrop;
};

export default useOnDrop;
