import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import usePersonalInformation from "../../hooks/usePersonalInformation";
import PersonalInformationForm from "./PersonalInformationForm";
import CompanyInformationForm from "./CompanyInformationForm";
import RoleInformationForm from "./RoleInformationForm";
import SaveProfile from "./SaveProfile";

interface IProfileInformationFormProps {
  className?: string;
}
const ProfileInformationForm: FC<IProfileInformationFormProps> = ({
  className
}) => {
  const {
    updateFields,
    setVal,
    setSelectVal,
    handleSave,
    isFormSubmissionDisabled,
    isLoading
  } = usePersonalInformation();
  return (
    <>
      <Typography variant="h7">Personal Information</Typography>
      <Grid
        container
        xs={12}
        spacing={2}
        justifyContent="center"
        display="flex"
        width="100%"
        className={className}
      >
        <PersonalInformationForm
          firstName={updateFields.firstName}
          lastName={updateFields.lastName}
          changeField={setVal}
        />

        <CompanyInformationForm
          companyName={updateFields.company?.name}
          employeCount={updateFields.company?.employee_count}
          changeField={setVal}
          changeSelectField={setSelectVal}
        />

        <RoleInformationForm
          changeSelectField={setSelectVal}
          employeeFunction={updateFields.employeeFunction}
          employeeResponsibility={updateFields.employeeResponsibility}
        />
      </Grid>
      <div className="flex m-t-24px p-r-16px">
        <SaveProfile
          handleSubmit={handleSave}
          isDisabled={isFormSubmissionDisabled}
          isLoading={isLoading}
          className="m-l-auto"
        />
      </div>
    </>
  );
};

export default ProfileInformationForm;
