import React from "react";
import { LabelAndInfo } from "../LabelAndInfo";
import type {
  CustomToolInputField,
  TSetFormState,
  UserInputDictType
} from "@sharedTypes";
import type { JSONContent } from "@tiptap/core";
import { ExpandableTipTapTextField } from "../../toolBuilder/tipTapTextField/TipTapTextField";

export const InputTextField = ({
  toolInputField,
  setFormState,
  formState,
  autofocus,
  defaultFormState,
  disableLabelAndInfo,
  useIds,
  defaultExpanded
}: {
  toolInputField: CustomToolInputField;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  autofocus?: boolean;
  defaultFormState?: UserInputDictType;
  disableLabelAndInfo?: boolean;
  defaultExpanded?: boolean;
  useIds?: boolean;
}) => {
  const handleTipTapChange = (val: string | JSONContent) => {
    setFormState({
      ...formState,
      [useIds ? toolInputField.id : toolInputField.name]: val
    });
  };

  return (
    <>
      {!disableLabelAndInfo && (
        <LabelAndInfo
          name={toolInputField.name}
          info={toolInputField.description}
        />
      )}

      <ExpandableTipTapTextField
        autoFocus={autofocus}
        id={toolInputField.id}
        saveWorkspaceType="json"
        defaultExpanded={defaultExpanded}
        initialValue={
          (defaultFormState &&
            (defaultFormState[
              useIds ? toolInputField.id : toolInputField.name
            ] as string)) ||
          ""
        } // we just want the initialValue, not the current value because tiptap can't be controlled
        onChange={handleTipTapChange}
        disableWhite={disableLabelAndInfo}
        disableTools
        // disableInputs
        disableFormatting // we need this because otherwise html gets sent to the server
        className={disableLabelAndInfo ? "min-w-300px" : ""}
      />
    </>
  );
};
