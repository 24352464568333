import type { TCategory, TWorkflowBuilderForm } from "@sharedTypes";
import { useFormContext } from "react-hook-form";
import { WORKFLOW_TAG_CATEGORIES_FIELD_LABEL } from "../WorkflowBuilderConstants";

const useSetWorkflowCategories = () => {
  const { setValue, register } = useFormContext<TWorkflowBuilderForm>();
  const setWorkflowCategories = (categories: TCategory[]) => {
    register(WORKFLOW_TAG_CATEGORIES_FIELD_LABEL);
    setValue(WORKFLOW_TAG_CATEGORIES_FIELD_LABEL, categories, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    });
  };
  return setWorkflowCategories;
};

export default useSetWorkflowCategories;
