import React from "react";
import useSetSelectedWorkspaceTool from "../../hooks/useSetSelectedTool";
import type { TMarketplaceEntity } from "@sharedTypes";
import ToolEntityDetailsButton from "../../../../utilities/ToolEntityDetailsButton";

function SuggestedTool({ entity }: { entity: TMarketplaceEntity }) {
  const setSelectedTool = useSetSelectedWorkspaceTool();
  return (
    <ToolEntityDetailsButton
      entity={entity}
      disableMaxWidth
      disableInfoTags
      onClickHandler={() => setSelectedTool(entity.id)}
    />
  );
}

export default SuggestedTool;
