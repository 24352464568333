import React from "react";
import useCategoryTags, { THandleChangeTags } from "./hooks/useCategoryTags";
import AssignTags from "./AssignTags";

import type { TCategory } from "@sharedTypes";
import { categoriesTags } from "./constants";

const AssignCategories = ({
  handleSave,
  disabled,
  fetchedCategoriesTags,
  type,
  className = ""
}: {
  handleSave: (useCases: TCategory[]) => void;
  disabled?: boolean;
  type: "workflow" | "tool";
  className?: string;
  fetchedCategoriesTags: {
    id: string;
    selected: boolean;
    value: TCategory;
  }[];
}) => {
  const initialSelectedTags = fetchedCategoriesTags.map((tag) => tag.value);

  const {
    handleChange,
    selectedTags,
    chipTags,
    handleRemoveSelectedTag,
    initialTags
  } = useCategoryTags({
    initialTags: categoriesTags,
    initialSelectedTags
  });

  const handleChangeTags: THandleChangeTags = ({ event, tagToBeRemoved }) => {
    if (tagToBeRemoved) {
      handleRemoveSelectedTag(tagToBeRemoved);
      const tagsToBeSaved = chipTags
        .filter((tag) => tag.id !== tagToBeRemoved.id)
        .map((tag) => tag.value) as TCategory[];
      handleSave(tagsToBeSaved);
    } else if (event) {
      handleChange(event);
      const tagsToBeSaved = event.target.value as TCategory[];
      handleSave(tagsToBeSaved);
    }
  };

  return (
    <AssignTags
      initialTags={initialTags}
      selectedTags={selectedTags}
      chipTags={chipTags}
      handleChangeTags={handleChangeTags}
      disableHelperText={false}
      helperText={`These categories will help users find your ${type} in marketplace.`}
      label={`Add categories to ${type}`}
      placeholder="Select categories"
      disabled={disabled}
      className={className}
    />
  );
};

export default AssignCategories;
