import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import { useSheetContext } from "../SheetContext";
import { TMaterialRow } from "../sheetTypes";

const DeleteRowButton = ({ row }: { row: TMaterialRow }) => {
  const { sheetData, setSheetData } = useSheetContext();
  const deleteRowById = (id: string) => {
    const newData = sheetData.filter((r) => r.componentId !== id);
    setSheetData(newData);
  };
  return (
    <IconButton onClick={() => deleteRowById(row.original.componentId)}>
      <Delete fontSize="small" />
    </IconButton>
  );
};

export default DeleteRowButton;
