// authSlice.ts
import { User } from "@auth0/auth0-spa-js";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "../../stores/store";

export type TSerializedError = {
  message: string;
  stack: string | undefined;
};

interface IAuth0Content {
  error: TSerializedError | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User | undefined;
}

interface AuthState {
  clientId: string;
  auth0Content: IAuth0Content;
}

const initialState: AuthState = {
  clientId: uuidv4(),
  auth0Content: {
    error: undefined,
    isAuthenticated: false,
    isLoading: true,
    user: undefined
  }
};

export type TAuthReducerName = "auth";
export const AUTH_REDUCER_NAME: TAuthReducerName = "auth";

const authSlice = createSlice({
  name: AUTH_REDUCER_NAME,
  initialState,
  reducers: {
    updateClientId(state, action: PayloadAction<string>) {
      state.clientId = action.payload;
    },
    setAuthContext: (state, action: PayloadAction<IAuth0Content>) => {
      state.auth0Content = action.payload;
    }
  }
});

export const selectAuth0Content = (state: RootState) => state.auth.auth0Content;

export const { updateClientId, setAuthContext } = authSlice.actions;

export default authSlice.reducer;
