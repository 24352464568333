import React from "react";
import UpdateToolNameTextField from "./UpdateToolNameTextField";
import TogglePrimaryHeaderTextFieldBase from "../utilities/TogglePrimaryHeaderTextFieldBase";
import { useToolbuilderContext } from "./context/ToolBuilderContext";

function ToggleToolNameTextField() {
  const { state } = useToolbuilderContext();
  const { currentState } = state;
  return (
    <TogglePrimaryHeaderTextFieldBase value={currentState.toolName}>
      <UpdateToolNameTextField size="small" margin="none" label="" />
    </TogglePrimaryHeaderTextFieldBase>
  );
}

export default ToggleToolNameTextField;
